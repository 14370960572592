import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import AlertModalComponent from '../../../../components/AlertModal'
import DateSelectorComponent from '../../../../components/DateSelector'
import FormComponent from '../../../../components/Form'
import ModalComponent from '../../../../components/Modal'
import SelectPaginatedComponent from '../../../../components/SelectPaginated'
import TwoSidedMultiselectPaginated from '../../../../components/TwoSidedMultiselectPaginated'

import UtilsHelper from '../../../../helpers/utils'

import useAppContext from '../../../../hooks/useAppContext'
import useDebounce from '../../../../helpers/debounce'
import useFetch from '../../../../hooks/useFetch'
import useNotices from '../../../../hooks/useNotices'
import useTabs from '../../../../hooks/useTabs'

import { MONTHS } from '../../../../sys/constants/Months'

import { Calendar } from 'antd'
import dayjs from 'dayjs'

import './index.css'

const SchedulingPartial = ({ entityData, onTabChange, onChange=()=>{} }) => {
    const [ac] = useAppContext()

    const { FetchHelper } = useFetch()

    const [tabs, tab, setTab, TabsComponent] = useTabs('Calendar', ['Calendar', 'Verbage', 'Options', 'Copy'])

    const [NoticeComponent, notices] = useNotices('Markets/Markets/Edit')

    const [errors, setErrors] = useState()
    const [data, setData] = useState()
    const [dataCells, setDataCells] = useState()
    const [dataMain, setDataMain] = useState({
        use_scheduling: entityData.options?.use_scheduling
    })
    const [dataVerbage, setDataVerbage] = useState({
        scheduling_extra_text: entityData.scheduling_extra_text,
        scheduling_policy: entityData.scheduling_policy
    })
    const [dataOptions, setDataOptions] = useState({
        scheduling_start_on_current_month: entityData.scheduling_start_on_current_month,
        scheduling_rescheduling_disabled: entityData.scheduling_rescheduling_disabled,
        scheduling_use_legacy_timeslot_display: entityData.options?.scheduling_use_legacy_timeslot_display
    })
    const [dataOptionsReservation, setDataOptionsReservation] = useState({
        walkup_time_limit: entityData.options.walkup_time_limit,
        reservation_time_limit: entityData.options.reservation_time_limit
    })
    const [dataAppointmentProfiles, setDataAppointmentProfiles] = useState()
    const [dataPackagePlans, setDataPackagePlans] = useState()

    // Data Batch Processor
    const [dataBatchOption, setDataBatchOption] = useState('this_month')
    const [dataBatchAppointmentProfile, setDataBatchAppointmentProfile] = useState('')

    const [optionsMarkets, setOptionsMarkets] = useState()
    const [optionsMarketGroups, setOptionsMarketGroups] = useState()

    const [date, setDate] = useState(dayjs())

    const [isLoading, setIsLoading] = useState(false)
    const [isBatchProcessed, setIsBatchProcessed] = useState(false)
    const [progressBarWidth, setProgressBarWidth] = useState(0)

    let batchPromises = null

    // Copy Tab
    const [dataCopyErrors, setDataCopyErrors] = useState([])
    const [dataCopyFormMonthFrom, setDataCopyFormMonthFrom] = useState(dayjs().month() + 1)
    const [dataCopyFormDayFrom, setDataCopyFormDayFrom] = useState(dayjs().day())
    const [dataCopyFormYearFrom, setDataCopyFormYearFrom] = useState(dayjs().year())
    const [dataCopyFormMonthTo, setDataCopyFormMonthTo] = useState(dayjs().month() + 1)
    const [dataCopyFormDayTo, setDataCopyFormDayTo] = useState(dayjs().day())
    const [dataCopyFormYearTo, setDataCopyFormYearTo] = useState(dayjs().year())
    const [dataSelectedMarkets, setDataSelectedMarkets] = useState([])
    const [dataFromDate, setDataFromDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [dataToDate, setDataToDate] =  useState(dayjs().add(1, 'month').format('YYYY-MM-DD'))
    const [showCopyCompleteDialog, setShowCopyCompleteDialog] = useState(false)

    // Timers
    const [timerExtraFee, setTimerExtraFree] = useState()

    useEffect(() => {
        fetchScheduling()
    }, [date])

    useEffect(() => {
        onTabChange(tab)
    }, [tab])

    useEffect(() => {
        let dataCombined = {
            ...dataMain,
            ...dataVerbage,
            ...dataOptions,
            ...dataOptionsReservation
        }

        onChange(dataCombined)
    }, [dataMain, dataVerbage, dataOptions])

    useEffect(() => {
        fetchScheduling()

        FetchHelper({
            url: `/api/v2/admin/appointment-profiles`
        }, (res) => {
            setDataAppointmentProfiles(res.body.data)
        }, (res) => {
        })

        FetchHelper({
            url: `/api/v2/admin/package-plans`
        }, (res) => {
            setDataPackagePlans(res.body.data)
        }, (res) => {
        })
    }, [])

    const fetchBatchProcess = () => {
        if (batchPromises || date.isBefore(dayjs(), 'day') || dataBatchAppointmentProfile === '')
            return

        setIsBatchProcessed(true)

        batchPromises = []

        for (let i=1, l=date.daysInMonth(); i < l + 1; i++) {
            let valid = false
            let dataCellDate = dayjs(date).date(i)
            let dataCell = {}

            dataCells.forEach((item, index) => {
                if (dayjs(item.date).format('YYYY-MM-DD') === dataCellDate.format('YYYY-MM-DD')) {
                    dataCell = item
                }
            })

            const isWeekday = /1|2|3|4|5/.test(dataCellDate.day())
            const isWeekend = /0|6/.test(dataCellDate.day())
            
            if (dataBatchOption === 'weekdays' && isWeekday) {
                valid = true
            } else if (dataBatchOption === 'weekends' && isWeekend) {
                valid = true
            } else if (dataBatchOption === 'this_month') {
                valid = true
            }

            if (valid) {
                batchPromises.push(
                    new Promise((resolve, reject) => {
                        fetchCell(dataCell, dataCellDate, 'appointment-profile', dataBatchAppointmentProfile)
                            .then(() => {
                                setProgressWidth()
                                resolve()
                            })
                    })
                )
            }
        }

        Promise.all(batchPromises).then(() => {
            setIsBatchProcessed(false)
            setProgressBarWidth(0)
            batchPromises = null
            fetchScheduling()
        })
    }

    const fetchCell = (item, calendarDate, type, fieldValue) => {
        ac.showSpinner(true)

        let body = {}

        switch (type) {
            case 'appointment-profile':
                body = {
                    appointment_profile_id: fieldValue
                }
                break
            case 'extra-fee':
                body = {
                    extra_fee: fieldValue
                }
                break
            case 'package-plan':
                body = {
                    surge_package_plan_id: fieldValue === '' ? null : fieldValue
                }
                break
        }

        if (!item.appointment_profile?.id) {
            body.date = calendarDate.format('YYYY-MM-DD')
        }

        return FetchHelper({
            url: item.appointment_profile?.id ? `/api/v2/admin/markets/schedules/${item.id}` : `/api/v2/admin/markets/${entityData.id}/schedules`,
            method: item.appointment_profile?.id ? 'put' : 'post',
            body: body
        }, (res) => {
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const setProgressWidth = () => {
        if (!batchPromises)
            return

        let totalResolved = 0

        batchPromises.forEach((item, index) => {
            if (item.then()) {
                totalResolved++
            }
        })

        if (totalResolved === 0) {
            setProgressBarWidth(0)
        } else {
            setProgressBarWidth(totalResolved / batchPromises.length * 100)
        }
    }

    const fetchScheduling = () => {
        ac.showSpinner(true)

        handleSetIsLoading()

        const newDate = dayjs(date)
        const startDate = newDate.startOf('month').format('YYYY-MM-DD')
        const endDate = newDate.endOf('month').format('YYYY-MM-DD')

        FetchHelper({
            url: `/api/v2/admin/markets/${entityData.id}/schedules?start_date=${startDate}&end_date=${endDate}`
        }, (res) => {
            ac.showSpinner(false)
            setDataCells(res.body.data)
            handleClearIsLoading()
        }, (res) => {
            ac.showSpinner(false)
            handleClearIsLoading()
        })
    }

    const handleSetIsLoading = () => {
        setIsLoading(true)
    }

    const handleClearIsLoading = () => {
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }

    const onPanelChange = (value, mode) => {
        setDate(value)
    }

    const handleSavePolicy = () => {

    }

    const handleGoToToday = () => {
        const newDate = dayjs()
        
        setDate(newDate)
    }

    const handleGoToPreviousYear = () => {
        const newDate = date.subtract(1, 'year');
        
        setDate(newDate)
    }

    const handleGoToPreviousMonth = () => {
        const newDate = date.subtract(1, 'month');
        
        setDate(newDate)
    }

    const handleGoToNextYear = () => {
        const newDate = date.add(1, 'year');
        
        setDate(newDate)
    }

    const handleGoToNextMonth = () => {
        const newDate = date.add(1, 'month');
        
        setDate(newDate)
    }

    const handleCopyScheduling = () => {
        setDataCopyErrors([])

        if (!dataSelectedMarkets.length) {
            return
        }

        if (dayjs(dataToDate).isBefore(dayjs(dataFromDate))) {
            setDataCopyErrors(['End date cannot be before start date'])
            return
        }

        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/markets/${entityData.id}/schedules/copy`,
            method: 'post',
            body: {
                copy: {
                    market_ids: dataSelectedMarkets,
                    start_date: dataFromDate,
                    end_date: dataToDate
                }
            }
        }, (res) => {
            ac.showSpinner(false)
            setShowCopyCompleteDialog(true)
        }, (res) => {
            ac.showSpinner(false)
            setDataCopyErrors(['An error occured. Please try again.'])
        })
    }

    const getDataCell = (cellValue) => {
        let dataCell = {}

        dataCells?.forEach((item, index) => {
            const itemDate = dayjs(item.date)

            if (itemDate.format('YYYY-MM-DD') === cellValue.format('YYYY-MM-DD')) {
                dataCell = item
            }
        })

        return dataCell
    }

    const renderDateCell = (calendarDate) => {
        if (isLoading) {
            return <div className="calendar-date-cell-spinner"></div>
        } 

        const isThisMonth = calendarDate.month() === date.month()
        
        if (!isThisMonth) {
            return (
                <div className='calendar-date-cell-container'>
                    <div className='date-value'>{String(calendarDate.date()).padStart(2, '0')}</div>
                    <div className="calendar-date-cell out-of-range">
                        Navigate to <strong>{MONTHS[calendarDate.month()]}</strong> to modify date
                    </div>
                </div>
            )
        }

        const dataCell = getDataCell(calendarDate)

        let typeClassName = 'full'

        if (dataCell.total > 0) {
            if (dataCell.used > 0) {
                typeClassName = 'has-appointments'
            } else {
                typeClassName = 'empty-with-availability'
            }
        } else {
            typeClassName = 'empty-without-availability'
        }

        let initialValue = null

        if (dataCell.appointment_profile) {
            initialValue = {
                value: dataCell.appointment_profile.id,
                title: dataCell.appointment_profile.name
            }
        }

        return (
            <div className={`calendar-date-cell-container ${calendarDate.isSame(dayjs(), 'date') ? 'today' : ''}`}>
                <div className='date-value'>{String(calendarDate.date()).padStart(2, '0')}</div>
                <div className={`calendar-date-cell ${typeClassName}`}>
                    <ul className="form">
                        <li>
                            <SelectPaginatedComponent initialValue={initialValue} apiUrl={`/api/v2/admin/appointment-profiles`} onSelect={(selectedOption) => {
                                fetchCell(dataCell, calendarDate, 'appointment-profile', selectedOption.value)
                            }} />
                        </li>
                        <li>
                            <div className="input-money">
                                <span className="input-span">$</span>
                                <input className="input-field" type="number" value={dataCell.extra_fee} placeholder={0.0} onChange={(e) => {
                                    dataCell.extra_fee = e.target.value
                                    clearTimeout(timerExtraFee)
                                    setTimerExtraFree(setTimeout(() => {
                                        fetchCell(dataCell, calendarDate, 'extra-fee', e.target.value)
                                    }, 250))
                                }}  />
                            </div>
                        </li>
                        <li>
                            <select value={dataCell.surge_package_plan?.id} onChange={(e) => {
                                fetchCell(dataCell, calendarDate, 'package-plan', e.target.value)
                            }}>
                                <option value="">No Surge</option>
                                {dataPackagePlans ? dataPackagePlans.map((item, index) => {
                                    return (
                                        <option key={`calendar-date-cell-package-plan-${item.id}-${index}`} value={item.id || ''}>{item.name}</option>
                                    )
                                }) : null}
                            </select>
                        </li>
                        <li className="date-cell-controls">
                            {!dataCell.total ? '' : `Slots Taken: ${dataCell.used}/${dataCell.total}`}
                        </li>
                        <li className="icons">
                            {dataCell.appointment_profile?.id ? (<Link to={`/appointment-profiles/${dataCell.appointment_profile.id}/edit`}><i className="bi bi-calendar2" title="Appointment Profile"></i></Link>) : null}
                            {dataCell.surge_package_plan?.id ? (<Link to={`/package-plans/${dataCell.surge_package_plan?.id}/edit`}><i className="bi bi-boxes" title="Package Plan"></i></Link>) : null}
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className={`scheduling-partial-container ${ac.isLoading ? 'disabled' : ''}`}>
            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} />

            {isBatchProcessed ? (
                <ModalComponent disableClose={true}>
                    <div className="global-modal">
                        <div className="title">Processing dates</div>
                        <div className="scheduling-appointment-profiles-progress">
                            <div className="progress-bar">
                                <div className="progress" style={{
                                    width: `${progressBarWidth}%`
                                }}></div>
                            </div>
                        </div>
                    </div>
                </ModalComponent> 
            ) : null}

            {/* Notice component */}
            {tab === 'Calendar' || tab === 'Verbage' || tab === 'Options' || tab === 'Copy' ? 
                <NoticeComponent location={`Tab/Scheduling/${tab}`} notices={notices} />
            : null}

            {/* Use scheduling toggle */}
            <FormComponent formData={dataMain} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[
                {
                    type: 'switch',
                    label: 'Use Scheduling?',
                    name: 'use_scheduling',
                }
            ]} />

            {!dataMain.use_scheduling && <div className="ui-help-snippet--container">
               This market does not have scheduling enabled.
            </div>}

            {/* Tab content */}
            {(dataMain.use_scheduling && tab === 'Calendar') ? (
                <div className={isBatchProcessed ? 'disabled' : ''}>
                    <div className="scheduling-appointment-profiles section batch">
                        <span>Apply</span>
                        <SelectPaginatedComponent apiUrl={`/api/v2/admin/appointment-profiles`} onSelect={(selectedOption) => {
                            setDataBatchAppointmentProfile(selectedOption.value)
                        }} />
                        <span>to</span>
                        <select value={dataBatchOption} onChange={(e) => setDataBatchOption(e.target.value)}>
                            <option value="this_month">This Month</option>
                            <option value="weekdays">Weekdays</option>
                            <option value="weekends">Weekends</option>
                        </select>
                        <button className="batch-button" onClick={fetchBatchProcess}>Go</button>
                    </div>

                    <div className="scheduling-calendar section">
                        <div className="scheduling-calendar-controls">
                            <div className="controls-left">
                                <button onClick={handleGoToPreviousYear}><i className="bi bi-chevron-double-left"></i></button>
                                <button onClick={handleGoToPreviousMonth}><i className="bi bi-chevron-left"></i></button>
                            </div>
                            <div className="title">{MONTHS[date?.month()]}, {date?.year()}</div>
                            <div className="controls-right">
                                <button onClick={handleGoToNextMonth}><i className="bi bi-chevron-right"></i></button>
                                <button onClick={handleGoToNextYear}><i className="bi bi-chevron-double-right"></i></button>
                            </div>
                        </div>

                        <div className="controls-secondary">
                            <button onClick={handleGoToToday}>Today</button>
                        </div>

                        <div className="controls-legend">
                            <ul>
                                <li className="legend-item full">Full/Overbooked</li>
                                <li className="legend-item empty-with-availability">Empty with Availablility</li>
                                <li className="legend-item has-appointments">Has Appointments</li>
                                <li className="legend-item empty-without-availability">Empty without Availability</li>
                            </ul>
                        </div>

                        <div className="calendar-component-container">
                            <div className="calendar-component-container-inner">
                                <Calendar value={date} dateFullCellRender={renderDateCell} onPanelChange={onPanelChange} disabledDate={(value) => {
                                    const cellDate = dayjs()

                                    if (value.month() > date.month() || value.month() < date.month() || value.isBefore(cellDate, 'day')) {
                                        return true
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {(dataMain.use_scheduling && tab === 'Verbage') ? (
                <>
                    <FormComponent formData={dataVerbage} formErrors={errors} onChange={setDataVerbage} displayErrorBanner={true} fields={[
                        {
                            type: 'editor',
                            label: 'Extra Text',
                            label_info: 'This will only appear on the page where customers select their reservation date/times.',
                            name: 'scheduling_extra_text',
                        },
                        {
                            type: 'editor',
                            label: 'Policy',
                            label_info: 'Please constrain your content to 600x100, anything outside those constraints will hidden on the appointment confirmation.',
                            name: 'scheduling_policy',
                        }]} />
                </>
            ) : null}

            {(dataMain.use_scheduling && tab === 'Options') ? (
                <>
                    <div className="scheduling-time-limits section">
                        <div className="title">These settings override your studio's current default time limits.</div>
                        <div className="time-limits">
                            <div className="time-limit-reservation">
                                <div className="title">Reservation Time Limit (seconds)</div>
                                <input type="number" value={dataOptionsReservation.reservation_time_limit || ''} min="0" onChange={(e) => setDataOptionsReservation({ ...dataOptionsReservation, reservation_time_limit: e.target.value})} />
                            </div>
                            <div className="time-limit-guided-checkout">
                                <div className="title">Guided Checkout Time Limit (seconds)</div>
                                <input type="number" value={dataOptionsReservation.walkup_time_limit || ''} min="0" onChange={(e) => setDataOptionsReservation({ ...dataOptionsReservation, walkup_time_limit: e.target.value})} />
                            </div>
                        </div>
                    </div>

                    <FormComponent formData={dataOptions} formErrors={errors} onChange={setDataOptions} displayErrorBanner={true} fields={[
                        {
                            type: 'switch',
                            label: 'Start on Current Month?',
                            label_info: 'By default, customers will be presented with the calender starting on the month with the closest availability. Enable this flag to start on the current month of the year instead.',
                            name: 'scheduling_start_on_current_month',
                        },{
                            type: 'switch',
                            label: 'Disable Customer Rescheduling?',
                            label_info: 'Managers/Employees will still be able to reschedule reservations. This will NOT remove references to rescheduling in any custom templates being used, however the Reschedule URL text substitution value will be blank.',
                            name: 'scheduling_rescheduling_disabled',
                        },{
                            type: 'switch',
                            label: 'Use DROPDOWN timeslot display?',
                            name: 'scheduling_use_legacy_timeslot_display',
                        }]} />
                </>
                
            ) : null}

            {(dataMain.use_scheduling && tab === 'Copy') ? (
                <>
                    {showCopyCompleteDialog ? <AlertModalComponent title="Copy scheduling complete" body={`Successfully copied scheduling to ${dataSelectedMarkets.length} markets`} onClose={() => {
                        setTab('Calendar')
                        setShowCopyCompleteDialog(false)
                    }} /> : null}

                    <div className="scheduling-copy-section">
                        <div className="row-section">
                            <label>From:</label>
                            <DateSelectorComponent date={dataFromDate} onChange={setDataFromDate} />
                        </div>
                        <div className="row-section">
                            <label>To:</label>
                            <DateSelectorComponent date={dataToDate} onChange={setDataToDate} />
                        </div>
                        <div className="row-section">
                            <label>Market(s):</label>
                            <TwoSidedMultiselectPaginated apiUrl="/api/v2/admin/markets" apiUrlGroups={"/api/v2/admin/market-groups"} renderTitle={(item) => `${item.name} — ${item.type}`} tabOptions={['Markets', 'Market Groups']} onChange={(items) => {
                                let marketIds = items.map((item, index) => {
                                    return item.id
                                })

                                setDataSelectedMarkets(marketIds)
                            }} />
                            {dataCopyErrors.length ? (
                                <ul className="errors">
                                    {dataCopyErrors.map((item, index) => {
                                        return <li key={`tab-scheduling-copy-errors-${index}`}>{item}</li>
                                    })}
                                </ul>
                            ) : null}
                        </div>
                        <div className="row-section">
                            <button onClick={handleCopyScheduling}>Copy Scheduling</button>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default SchedulingPartial