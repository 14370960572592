import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import CopyButton from '../../components/CopyButton'
import PageHeaderComponent from '../../components/PageHeader'
import FetchHelper from '../../helpers/fetch'
import FieldSelectorComponent from '../../components/FieldSelector'
import FormComponent from '../../components/Form'
import ImportantDates from '../../components/ImportantDates'

import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './edit.css'
import { getGeneralFormFieldsNew, getLateOrdersFormFields, getMarketingFormFields, getOptionsFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const MarketsEdit = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/Markets/New')

    const [errors, setErrors] = useState()

    const [data, setData, clearData, dataDefault] = useDraft('Markets:Markets:Data')
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('Markets:Markets:DataMain')
    const [dataImportantDates, setDataImportantDates, clearImportantDates, dataImportantDatesDefault] = useDraft('Markets:Markets:DataImportantDates', {})
    const [dataCouponCodes, setDataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault] = useDraft('Markets:Markets:DataCouponCodes', [])
    const [dataLateOrders, setDataLateOrders, clearDataLateOrders, dataLateOrdersDefault] = useDraft('Markets:Markets:DataLateOrders', {})
    const [dataCustomFields, setDataCustomFields, clearDataCustomFields, dataCustomFieldsDefault] = useDraft('Markets:Markets:DataCustomFields', [])
    const [dataMarketing, setDataMarketing, clearDataMarketing, dataMarketingDefault] = useDraft('Markets:Markets:DataMarketing', {})
    const [dataOptions, setDataOptions, clearDataOptions, dataOptionsDefault] = useDraft('Markets:Markets:DataOptions', {})

    const [suggestionsTags, setSuggestionsTags] = useState([])
    const [suggestionsLocationGroups, setSuggestionsLocationGroups] = useState([])
    const [suggestionsMarketGroups, setSuggestionsMarketGroups] = useState([])

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('General', ['General', 'Options', 'Important Dates', 'Late Orders', 'Coupon Codes', 'Fields', 'Marketing'])

    let [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFieldsNew())
    let [lateOrdersFormFields] = useState(getLateOrdersFormFields())
    let [marketingFormFields] = useState(getMarketingFormFields())
    let [optionsFormFields] = useState(getOptionsFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getGeneralFormFieldsNew(
            suggestionsTags,
            ac.AuthHelper?.settings?.third_party?.centrics_service_enabled,
            ac.AuthHelper?.settings?.sys?.currencies,
            ac.AuthHelper?.settings?.sys?.timezones,
            dataMain
        )
        setGeneralFormFields(fields)
    }, [suggestionsTags, ac.AuthHelper?.settings?.sys, ac.AuthHelper?.settings?.third_party?.centrics_service_enabled, dataMain])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'General',
                forms: [{ id: 'Main', fields: generalFormFields }]
            },
            {
                tab: 'Late Orders',
                forms: [{ id: 'Main', fields: lateOrdersFormFields }]
            },
            {
                tab: 'Marketing',
                forms: [{ id: 'Main', fields: marketingFormFields }]
            },
            {
                tab: 'Options',
                forms: [{ id: 'Main', fields: optionsFormFields }]
            }
        ];
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/markets/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/location-groups'
        }, (res) => {
            setSuggestionsLocationGroups(res.body.data.map((item) => {
                return {
                    value: item.name,
                    title: item.name,
                    item: item
                }
            }))
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/market-groups'
        }, (res) => {
            setSuggestionsMarketGroups(res.body.data.map((item) => {
                return {
                    title: item.name,
                    value: item.name,
                    item: item
                }
            }))
        }, (res) => {

        })
    }, [])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = dataMain

            let data = {
                name: body.name,
                tag_names: body.tags,
                external_id: body.external_id,
                centrics_partner_id: body.centrics_partner_id,
                location_group_id: body.location_group_id,
                market_group_id: body.market_group_id,
                type: body.type,
                purchase_format: body.purchase_format,
                fee_format: body.fee_format,
                contact_email: UtilsHelper.stringifyEmails(body.contact_email),
                tax: body.tax,
                snh: body.snh,
                snh_taxable: body.snh_taxable,
                fee_adjustment: body.fee_adjustment,
                fee_taxable: body.fee_taxable,
                address_attributes: {
                    address1: body.address1 || "",
                    address2: body.address2 || "",
                    city: body.city || "",
                    state_province: body.state_province || "",
                    country: body.country || "",
                    postal_code: body.postal_code || "",
                },
                currency_code: body.currency_code,
                special_instructions: body.special_instructions,
                starts_at: body.starts_at,
                ends_at: body.ends_at,
                timezone: body.timezone,
                phone_number: body.phone_number,
                website: body.website,
                package_plan_id: body.package_plan_id,
                marketing_plan_id: body.marketing_plan_id,
                color_scheme_id: body.color_scheme_id,
                payment_gateway_id: body.payment_gateway_id,
                vanity_domain_id: body.vanity_domain_id,
                user_ids: body.user_ids,
                late_order_date: dataLateOrders.late_order_date,
                late_order_package_plan_id: dataLateOrders.late_order_package_plan_id,
                late_order_marketing_plan_id: dataLateOrders.late_order_marketing_plan_id,
                late_order_shipping: dataLateOrders.late_order_shipping,
                late_order_fee: dataLateOrders.late_order_fee,
                notify_on_late_order: dataLateOrders.notify_on_late_order,
                customer_service_email: UtilsHelper.stringifyEmails(dataLateOrders.customer_service_email),
                options_attributes: {
                    facebook_enabled: dataMarketing.facebook_enabled || false,
                    block_mobile: dataOptions.block_mobile || false,
                    hide_from_listing: dataOptions.hide_from_listing || false,
                    hide_from_postal_code_search: dataOptions.hide_from_postal_code_search || false,
                    display_name_during_customer_experience: dataOptions.display_name_during_customer_experience,
                    collect_shipping: dataOptions.collect_shipping,
                    email_collection_method: dataOptions.email_collection_method,
                    email_collection_default: dataOptions.email_collection_default,
                    email_collection_message: dataOptions.email_collection_message,
                    email_friendly_from: dataOptions.email_friendly_from,
                    self_service_enabled: dataOptions.self_service_enabled,
                    show_important_dates: dataImportantDates?.showImportantDates
                },
                secret_code: dataOptions.secret_code,
                market_confirmation_message: dataOptions.market_confirmation_message,
                ga_tracking_id: dataMarketing.ga_tracking_id,
                gtm_container_id: dataMarketing.gtm_container_id,
                facebook_pixel_id: dataMarketing.facebook_pixel_id,
                tiktok_pixel_id: dataMarketing.tiktok_pixel_id
            }

            if(dataImportantDates && dataImportantDates.importantDates) {
                data.important_dates_attributes = dataImportantDates.importantDates
                    .filter(item => item.name && item.date)
                    .map(item => {
                        let date = item.date.split('T')[0].split('-')
                        date.push(date.shift())

                        return {
                            name: item.name,
                            the_date: date.join('/'),
                            the_time: item.time_applicable ? UtilsHelper.format12HourTime(item.date) : undefined,
                            time_applicable: item.time_applicable
                        }
                    })
            }

            if (body.favicon?.value) {
                data.favicon_base64 = body.favicon.value
            }

            if (body.location_group_image?.value) {
                data.location_group_image_attributes = {
                    data_base64: body.location_group_image.value
                }
            }

            if (body.logo?.value) {
                data.logo_attributes = {
                    data_base64: body.logo.value
                }
            }

            [
                { data: dataCouponCodes, attribute: 'item_coupon_codes_attributes', prop: 'coupon_code_id' },
                { data: dataCustomFields, attribute: 'custom_fields_attributes', prop: 'field_id' }
            ].forEach(config => {
                if (!config.data.length)
                    return

                data[config.attribute] = config.data.filter((item) => {
                    return item.item.id
                }).map((item, index) => {
                    return { [config.prop]: item.item.id, position: index }
                })
            })

            FetchHelper({
                url: `/api/v2/admin/markets`,
                method: 'post',
                body: {
                    market: data
                }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    let errors = UtilsHelper.preProcess422Errors(
                        [...generalFormFields, ...lateOrdersFormFields, ...marketingFormFields, ...optionsFormFields],
                        res.body
                    )
                    setErrors(errors)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className="markets-edit-page-container">
            <PageHeaderComponent title="New Market" />
            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            {tab === 'General' ?
                <>
                    <NoticeComponent location="Tab/General" notices={notices} />
                    <FormComponent formData={dataMain} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={generalFormFields} />
                </>
                : null}
            {tab === 'Important Dates' ?
                <>
                    <NoticeComponent location="Tab/ImportantDates" notices={notices} />
                    <ImportantDates defaults={dataImportantDates} onChange={(data) => setDataImportantDates(data)} />
                </>
                : null}
            {tab === 'Late Orders' ?
                <>
                    <NoticeComponent location="Tab/LateOrders" notices={notices} />
                    <FormComponent formData={dataLateOrders} formErrors={errors} setFormErrors={setErrors} onChange={setDataLateOrders} displayErrorBanner={true} fields={lateOrdersFormFields} />
                </>
                : null}
            {tab === 'Coupon Codes' ?
                <>
                    <NoticeComponent location="Tab/CouponCodes" notices={notices} />
                    <FieldSelectorComponent apiPrefix="coupon_code" buttonTitle="Coupon Code" data={dataCouponCodes} apiURL="/api/v2/admin/coupon-codes" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} />
                </>
                : null}
            {tab === 'Fields' ?
                <>
                    <NoticeComponent location="Tab/Fields" notices={notices} />
                    <FieldSelectorComponent apiPrefix="field" buttonTitle="Field" data={dataCustomFields} apiURL="/api/v2/admin/fields" editUrl={(id) => `/fields/${id}/edit`} onChange={(data) => setDataCustomFields(data)} />
                </>
                : null}
            {tab === 'Marketing' ? 
                <>
                    <NoticeComponent location="Tab/Marketing" notices={notices} />
                    <FormComponent formData={dataMarketing} formErrors={errors} setFormErrors={setErrors} onChange={setDataMarketing} displayErrorBanner={true} fields={marketingFormFields}/>
                </>
                : null}
            {tab === 'Options' ?
                <>
                    <NoticeComponent location="Tab/Options" notices={notices} />
                    <FormComponent formData={dataOptions} formErrors={errors} setFormErrors={setErrors} onChange={setDataOptions} displayErrorBanner={true} fields={optionsFormFields}/>
                </>
                : null}
            <ControlBar handleSubmit={handleSubmit} cancelUrl="/markets" handleAfterSubmit={() => {
                RouteHelper.redirect('/markets')
            }} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault],
                [dataMain, clearDataMain, dataMainDefault],
                [dataImportantDates, clearImportantDates, dataImportantDatesDefault],
                [dataLateOrders, clearDataLateOrders, dataLateOrdersDefault],
                [dataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault],
                [dataCustomFields, clearDataCustomFields, dataCustomFieldsDefault],
                [dataOptions, clearDataOptions, dataOptionsDefault],
                [dataMarketing, clearDataMarketing, dataMarketingDefault]
            ]} />
        </div>
    )
}

export default MarketsEdit
