import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ControlBar, Form, PageHeader } from '../../../components';
import { Utils } from '../../../helpers';
import RouteHelper from '../../../helpers/route';
import { useAppContext, useFetch, useNotices } from '../../../hooks';
import { getGeneralFormFields } from '../providers/form-fields';
import './index.css';

const EnhancementsEdit = () => {
  const [ac] = useAppContext();
  const params = useParams();
  const [NoticeComponent, notices] = useNotices('PSM/Enhancements/Edit');
  const { FetchHelper } = useFetch();

  const [deleteImage, setDeleteImage] = useState(false);

  const [data, setData] = useState();
  const [selectedProviderEnhancement, setSelectedProviderEnhancement] =
    useState();

  const providerEnhancementChangeFilter = useCallback((value) => {
    setSelectedProviderEnhancement(value);
    return value;
  }, []);

  const onDeleteChange = (e) => {
    setDeleteImage(e.target.checked);
  }

  const [generalFormFields, setGeneralFormFields] = useState(
    getGeneralFormFields(
      undefined,
      selectedProviderEnhancement,
      providerEnhancementChangeFilter,
      deleteImage,
      onDeleteChange
    )
  );

  const [errors, setErrors] = useState();

  useEffect(() => {
    let fields = getGeneralFormFields(
      data,
      selectedProviderEnhancement,
      providerEnhancementChangeFilter,
      deleteImage,
      onDeleteChange
    );
    setGeneralFormFields(
      fields,
      selectedProviderEnhancement,
      providerEnhancementChangeFilter
    );
  }, [
    data?.image,
    selectedProviderEnhancement,
    providerEnhancementChangeFilter
  ]);

  useEffect(() => {
    const fetchData = async () => {
      ac.showSpinner(true);
      const [res, err] = await Utils.executePromise(
        FetchHelper({
          url: `/api/v2/admin/enhancements/${params.id}`
        })
      );
      ac.showSpinner(false);

      if (err) return;

      setData({ ...res.body, image_base64: res.body?.image?.preview_url });
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    let body = { ...data };
    delete body.image;
    if (body.image_base64) body.image_base64 = body.image_base64.value;
    if (deleteImage) {
      delete body.image_base64
      body.delete_images = ['image'];
    }

    ac.showSpinner(true);
    let [res, err] = await Utils.executePromise(
      FetchHelper({
        url: `/api/v2/admin/enhancements/${params.id}`,
        method: 'PUT',
        body: { enhancement: body }
      })
    );
    ac.showSpinner(false);

    // --- error handling
    if (err) throw err;
    if (res?.status === 422) {
      setErrors(res.body);
      Utils.scrollTop();
      throw res;
    }
  };

  return (
    <div>
      <PageHeader title={`Edit Enhancement - ${data?.name}`} />
      <NoticeComponent location="Main" notices={notices} />

      {data && (
        <Form
          formData={data}
          formErrors={errors}
          onChange={setData}
          displayErrorBanner={true}
          fields={generalFormFields}
        />
      )}

      <ControlBar
        handleSubmit={handleSubmit}
        cancelUrl="/enhancements"
        handleAfterSubmit={() => RouteHelper.redirect('/enhancements')}
        data={data}
      />
    </div>
  );
};

export default EnhancementsEdit;
