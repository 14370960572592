import { useState, useEffect } from 'react'

import FetchHelper from '../../helpers/fetch'
import { ModalComponent } from '../../componentsV2/Complex'
import PickerDateTime from '../PickerDateTime'
import Switch from '../Switch'
import Errors from '../Errors'
import lodash from 'lodash'

import { useAppContext, useDynamicState } from '../../hooks'

import { $ } from '../../packages'

import './index.css'
import { Utils } from '../../helpers'

const FieldUpdater = ({ field, fieldIndex = 0, callback, apiType, studioId, editable=true }) => {
    const [ac] = useAppContext()

    const { _get, _set, _mounted } = useDynamicState({
        data: {
            field: {
                value: field.value,
                newValue: (field.type !== 'children' || lodash.isString(field.value))
                    ? field.value 
                    : lodash.map(field.value, item => {
                        if (!item.uid) item.uid = Utils.generateUUID()
                        return item
                    })
            }
        },
        toggles: {
            modal: {
                open: false
            },
            fetching: {
                field: false
            }
        },
        errors: {
            field: {
                modal: []
            }
        }
    })

    // const [errorMessages, setErrorMessages] = useState()

    const id = field.id
    const type = field.type
    const label = field.ask

    // useEffect(() => {
    //   FetchHelper({
    //       url: field.url
    //   }, (res) => {
    //       // setData(res.body)
    //   }, (res) => {
    //       // show error
    //   })
    // }, [])

    const handleSubmitValue = () => {
        ac.showSpinner(true)
        
        _set('toggles.fetching', { field: true })

        const newValue = _get('data.field').newValue
        if (type === 'children') {
            lodash.each(newValue, child => {
                delete child.uid
            })
        }
        
        clearErrors()

        let body = {
            field: {
                value: newValue
            }
        }

        if (apiType === 'customer') {
            body = {
                customer: {
                    [field.name]: newValue
                }
            }
        }

        FetchHelper({
            method: 'put',
            url: field.url,
            headers: {
                'x-studio-id': studioId
            },
            body
        }, (res) => {
            ac.showSpinner(false)

            _set('data.field', { value: newValue }, () => {
                _set('toggles.modal', { open: false }, () => {
                    _set('toggles.fetching', { field: false })
                })
            })
        }, (res) => {
            ac.showSpinner(false)

            _set('toggles.fetching', { field: false }, () => {
                _set('errors.field', { modal: res.body })
            })
        })
    }

    const clearErrors = () => {
        _set('errors.field', { modal: [] })
    }

    const renderField = () => {
        const newValue = _get('data.field').newValue

        if (/single_line|email|phone|number/.test(type)) {
            return (
                <input type="text" defaultValue={newValue} onChange={(e) => _set('data.field', { newValue: e.target.value })} />
            )
        }
        else if (/text/.test(type)) {
            return (
                <textarea value={newValue} onChange={(e) => _set('data.field', { newValue: e.target.value })}></textarea>
            )
        }
        else if (type === 'dropdown') {
            return (
                <select value={newValue} onChange={(e) => _set('data.field', { newValue: e.target.value })}>
                    {field.options.map((e, i) => {
                        return (
                            <option value={e} key={`field-updater-${id}-${i}`}>{e}</option>
                        )
                    })}
                </select>
            )
        }
        else if (type === 'date') {
            return (
                <PickerDateTime initialValue={newValue} onChange={(value) => _set('data.field', { newValue: value })} dateFormat="YYYY-MM-DDTHH:mm" />
            )
        }
        else if (type === 'checkbox') {
            return (
                <Switch value={newValue} onChange={(value) => _set('data.field', { newValue: value })} />
            )
        }
        else if (type === 'children') {
            return newValue.map((child, i) => {
                return (
                    <div key={`field-updater-${child.uid}`} className='flex flex-col gap-2 border border-solid border-gray-300 rounded p-4 mt-2'>
                        <div className='text-end cursor-pointer' onClick={() => {
                                let children = newValue
                                children.splice(i, 1)
                                _set('data.field', { newValue: children })
                            }}>
                            <span className='bi bi-x'></span>
                        </div>

                        <input
                            type="text"
                            defaultValue={child.first_name}
                            placeholder="First name"
                            onChange={(e) => {
                                let children = newValue
                                children[i].first_name = e.target.value
                                _set('data.field', { newValue: children })
                            }} />

                        <input
                            type="text"
                            defaultValue={child.last_name}
                            placeholder="Last name"
                            onChange={(e) => {
                                let children = newValue
                                children[i].last_name = e.target.value
                                _set('data.field', { newValue: children })
                            }} />

                        <input
                            type="number"
                            min="1"
                            max="100"
                            defaultValue={child.age}
                            placeholder="Age"
                            onChange={(e) => {
                                let children = newValue
                                children[i].age = e.target.value
                                _set('data.field', { newValue: children })
                            }} />
                    </div>
                )
            })
        }
        else if (type === 'address') {
            return (
                <>
                    <input
                        type="text"
                        defaultValue={newValue.address1}
                        placeholder="Address Line 1"
                        onChange={(e) => _set('data.field', { newValue: {...newValue, address1: e.target.value }})} />

                    <input
                        type="text"
                        defaultValue={newValue.address2}
                        placeholder="Address Line 2"
                        onChange={(e) => _set('data.field', { newValue: {...newValue, address2: e.target.value }})} />

                    <input
                        type="text"
                        defaultValue={newValue.city}
                        placeholder="City"
                        onChange={(e) => _set('data.field', { newValue: {...newValue, city: e.target.value }})} />

                    <input
                        type="text"
                        defaultValue={newValue.state_province}
                        placeholder="State"
                        onChange={(e) => _set('data.field', { newValue: {...newValue, state_province: e.target.value }})} />

                    <input
                        type="text"
                        defaultValue={newValue.postal_code}
                        placeholder="Postal Code"
                        onChange={(e) => _set('data.field', { newValue: {...newValue, postal_code: e.target.value }})} />

                    <input
                        type="text" 
                        defaultValue={newValue.country}
                        placeholder="Country"
                        onChange={(e) => _set('data.field', { newValue: {...newValue, country: e.target.value }})} />
                </>
            )
        }
    }

    const renderOptions = () => {
        if (type === 'children') {
            return (
                <div className='text-end'>
                    <button onClick={() => {
                        let children = _get('data.field').newValue
                        children.push({ uid: Utils.generateUUID(), first_name: '', last_name: '', age: '' })
                        _set('data.field', { newValue: children })
                        }}>Add</button>
                </div>
            )
        }

        return null;
    }

    const renderFieldValue = (value) => {
        if (/single_line|text|email|phone|number/.test(type)) {
            return value || 'N/A'
        }
        else if (type === 'dropdown') {
            return value || 'N/A'
        }
        else if (type === 'date') {
            return $.dayjs(value).format('YYYY/MM/DD hh:mm A') || 'N/A'
        }
        else if (type === 'checkbox') {
            return /^(true|false)$/.test(value) && (value === true ? 'Yes' : 'No') || 'N/A'
        }
        else if (type === 'children') {
            if (lodash.isString(value))
                return value

            return (
                <ul>
                    {value.map((child, i) => {
                        let v = `${child.first_name || ''} ${child.last_name || ''}`
                        if (child.age !== undefined && child.age !== null) {
                            if (v !== '') v += ', '
                            v += `Age ${child.age}`
                        }
                        return <li key={`field-updater-${id}-${i}`}>{v}</li>
                    })}
                </ul>
            )
        }
        else if (type === 'address') {
            if (lodash.isObject(value))
                return (
                    <ul>
                        <li>{value.address1}</li>
                        <li>{value.address2}</li>
                        <li>{value.city}, {value.state_province}, {value.postal_code}</li>
                        <li>{value.country}</li>
                    </ul>
                )
            return value
        }

    }

    const handleClick = (e, item) => {
        _set('toggles.modal', { open: true })
    }

    const handleClose = () => {
        _set('data.field', { newValue: _get('data.field').value }, () => {
            _set('toggles.modal', { open: false }, () => {
                _set('toggles.fetching', { field: false }, () => {
                    clearErrors()
                })
            })
        })
    }

    if (!_mounted)
        return null

    return (
        <div className={`field-updater-container ${!editable ? 'field-updater-container-disabled' : ''}`}>
            {label ? <p className='field-label'>{label}{field.required && <span><sup title={'Required Field'} className={'asterisk'}>*</sup></span>}</p> : null}

            <div className="field-updater-container-closed">
                <div className="icon-field" onClick={(e) => editable && handleClick(e)}>
                    <i className="bi bi-pencil"></i> <span className="field-value">{renderFieldValue(_get('data.field').value)}</span>
                </div>
            </div>

            {_get('toggles.modal').open && (
                <ModalComponent events={{ handleClose: handleClose }}>
                    <div className={`field-updater-container-modal ${_get('toggles.fetching').field ? 'fetching' : ''}`}>
                        {label && (
                            <div className="field-info">
                                <div className="field-label">{label}{field.required && <span><sup title={'Required Field'} className={'asterisk'}>*</sup></span>}</div>
                            </div>
                        )}

                        <div className="field-wrapper">
                            {renderField()}
                        </div>

                        {renderOptions()}

                        <div className="ui--controls">
                            <button onClick={handleSubmitValue}>Submit</button> <button onClick={handleClose} className="secondary">Cancel</button>
                        </div>

                        {Object.keys(_get('errors.field').modal).length ? (
                            <div className="field-errors">
                                <Errors errors={_get('errors.field').modal} />
                            </div>
                        ) : null}
                    </div>
                </ModalComponent>
            )}
        </div>
    )
}

export default FieldUpdater
