import {useState, useEffect, useRef} from 'react'

import FetchHelper from '../../../helpers/fetch'
import UtilsHelper from '../../../helpers/utils'
import { ModalComponent } from '../../../componentsV2/Complex'
import FormComponent from '../../../components/Form'
import ErrorsComponent from '../../../components/Errors'

import './RefundModal.css'

const RefundModal = ({ onClose, order }) => {
  const [errors, setErrors] = useState()
  const [dataNote, setDataNote] = useState('')
  const [refundType, setRefundType] = useState()
  const [markRefunded, setMarkRefunded] = useState(false)
  const [amount, setAmount] = useState()
  const [fetching, setFetching] = useState(false)

  const rescheduleModalRef = useRef(null)

  useEffect(() => {
    if (!refundType) {
      setRefundType('refund')
    }

    setErrors(null)
  }, [refundType])

  useEffect(() => {
    if (errors && rescheduleModalRef.current) {
      rescheduleModalRef.current.scrollTop = rescheduleModalRef.current.scrollHeight
    }
  }, [errors, rescheduleModalRef.current])

  const handleSubmit = () => {
    setFetching(true)

    const body = {
      transaction: {
        type: refundType,
        note: dataNote
      }
    }

    if (refundType === 'partial_refund') {
      body.transaction.amount = amount
      body.transaction.mark_as_refunded = markRefunded
    }

    FetchHelper({
        url: `/api/v2/admin/orders/${order.id}/transactions`,
        method: 'delete',
        body: body
      }, (res) => {
        setFetching(false)
        UtilsHelper.refreshPage()
      }, (res) => {
        setFetching(false)

        if (res.status === 422) {
          setErrors([res.body.message])
        } else {
          setErrors(res.body)
        }
      })
  }

  return <ModalComponent events={{ handleClose: onClose }} callbacks={{ onMount: ({ ref }) => rescheduleModalRef.current = ref.current }}>
      <div className={`reschedule-modal-component-container ${fetching ? 'disabled' : ''}`}>
        <div className="title">
          <em>Refund</em>
        </div>

        <div className="row controls">
          <div>
            <select value={refundType} onChange={(e) => setRefundType(e.target.value)}>
              <option value="refund">Full</option>
              <option value="partial_refund">Partial</option>
              {order.appointment_aware_refundable && (<option value="appointment_aware_refund">Appointment Aware</option>) || null}
            </select>
          </div>

          <div className="amount">
            {refundType === 'refund' && `$${order.grand_total}` || null}
            {refundType === 'partial_refund' && (<input type="text" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />)}
            {refundType === 'appointment_aware_refund' && `$${order.appointment_aware_refund_amount}` || null}
          </div>
        </div>

        <div className="row conditionals">
          {refundType === 'partial_refund' && (<div><input type="checkbox" value={markRefunded} onChange={(e) => setMarkRefunded(e.target.value)} /> Marked Refunded</div>) || null}
          {refundType === 'appointment_aware_refund' && (<div>Amount may be different if window has changed by the time of button press.</div>) || null}
        </div>

        <div className="row form">
          <FormComponent type="stacked" formErrors={errors} onChange={setDataNote} displayErrorBanner={false} fields={[{
                  type: 'editor',
                  name: 'data_note',
                  label: 'Note',
              }]} />
        </div>

        {errors && (<div className="row">
          <ErrorsComponent errors={errors} />
        </div>)}

        <div className="ui--controls">
          <button onClick={handleSubmit}>Refund</button> <button className="secondary" onClick={() => onClose()}>Cancel</button>
        </div>
      </div>
    </ModalComponent>
}

export default RefundModal
