import { Progress } from 'antd';
import './index.css';

const ProgressBar = ({ processed, total }) => {
  let percentage = Math.round((processed / total) * 100);
  return (
    <div className="progress-bar-page-container">
      <div className="progress-bar-container">
        <Progress
          percent={percentage}
          status="active"
          size={{ height: 20 }}
          strokeColor={'#174eb7'}
          trailColor={'#e6f0ff'}
          className={'progress-bar'}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
