import {useEffect, useState} from 'react'

import FetchHelper from '../../helpers/fetch'
import PickerDateTime from '../PickerDateTime'
import UtilsHelper from '../../helpers/utils'

import useDebounce from '../../helpers/debounce'
import useAppContext from '../../hooks/useAppContext'

import './index.css'
import { CheckboxComponent } from '../../componentsV2/Primitive'
import SwitchComponent from '../Switch'
import PickerDate from '../PickerDate'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const ImportantDates = ({ defaults={}, onChange=()=>{}, sync=false, syncUrl='' }) => {
  const [ac] = useAppContext()

  const isFirstRender = useIsFirstRender()
  const [showImportantDates, setShowImportantDates] = useState(defaults?.showImportantDates || false)
  const debouncedShowImportantDates = useDebounce(showImportantDates, 250)

  const [data, setData] = useState(defaults?.importantDates || [])
  const [term, setTerm] = useState()
  const debouncedTerm = useDebounce(term, 250)

  useEffect(() => {
    if(isFirstRender) return;

    if(sync && defaults?.marketID) {
      let requestBody = {
        options_attributes: {
          show_important_dates: showImportantDates
        }
      }
      FetchHelper({
        url: `/api/v2/admin/markets/${defaults?.marketID}`,
        method: 'put',
        body: { market: requestBody }
      }, (res) => {
          ac.showSpinner(false)
      }, (res) => {
          ac.showSpinner(false)
      })
    }

    onChange({...defaults, showImportantDates: debouncedShowImportantDates})
  }, [debouncedShowImportantDates])

  useEffect(() => {
    if (!debouncedTerm)
      return

    const index = term.index

    let newState = [...data]
    let item = newState[index]

    if (sync && item.name && item.date) {
      ac.showSpinner(true)

      let date = item.date.split('T')[0].split('-')
      date.push(date.shift())

      let body = {
        name: item.name,
        the_date: date.join('/'),
        the_time: item.time_applicable ? UtilsHelper.format12HourTime(item.date) : undefined,
        time_applicable: item.time_applicable
      }

      FetchHelper({
        url: item.url || syncUrl,
        method: item.url ? 'put' : 'post',
        body: {
          important_date: body
        }
      }, (res) => {
        ac.showSpinner(false)

        item.url = res.body.url
        newState[index] = item

        setData(newState)
      }, (res) => {
        ac.showSpinner(false)
      })
    }
  
  }, [debouncedTerm])

  const updateField = (key, value, index) => {
    let newState = [...data]
    let item = newState[index]

    newState[index][key] = value

    setData(newState)
    onChange({...defaults, importantDates: newState})

    setTerm({key, value, index})
  }

  const removeField = (index) => {
    let newState = [...data]
    let item = newState[index]

    if (sync && item.url) {
      ac.showSpinner(true)

      FetchHelper({
        url: item.url,
        method: 'delete'
      }, (res) => {
        ac.showSpinner(false)
      }, (res) => {
        ac.showSpinner(false)
      })
    }

    newState.splice(index, 1)

    setData(newState)
    onChange({...defaults, importantDates: newState})
  }

  const addField = () => {
    let newState = [...data]

    const date = new Date()

    let minutes = date.getMinutes()

    if (minutes < 10) {
      minutes = '0' + minutes
    }

    newState.push({
      name: '',
      date: '',
      time_applicable: false
    })

    setData(newState)
    onChange({...defaults, importantDates: newState})
  }

  const renderRow = (item, index) => {
    const date = item.date.split('.')[0]

    return (
      <div key={`important-dates-row-${index}`} className="important-dates-row">
        <div className="important-dates-inputs">
          <input type="text" value={item.name} onChange={(e) => updateField('name', e.target.value, index)} />
          {data[index].time_applicable === false ? (
            <PickerDate initialValue={date} onChange={(value) => updateField('date', value, index)} dateFormat="YYYY-MM-DD" />
          ) : (
            <PickerDateTime initialValue={date} onChange={(value) => updateField('date', value, index)} dateFormat="YYYY-MM-DDTHH:mm" />
          )}
          <button className="delete" onClick={() => removeField(index)}><i className="bi bi-trash-fill"></i></button>
        </div>
        <div>
          <CheckboxComponent value={data[index].time_applicable} events={{ handleChange: (value) => updateField('time_applicable', value, index) }} /> Time applicable?
        </div>
      </div>
    )
  }

  return (
    <div className="important-dates-container">
      <div className='show-important-dates-row'>
        <label>Show Important Dates</label>
        <SwitchComponent value={showImportantDates} onChange={setShowImportantDates} />
      </div>

      <hr className='divider'/>

      <button className="new-date-button" onClick={addField}>New Date</button>

      {data && data.length > 0 && data.map((e, i) => {
        return renderRow(e, i)
      })}
    </div>
  )
}

export default ImportantDates
