import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ControlBar,
  FieldSelector,
  Form,
  PageHeader
} from '../../../components';
import { Utils } from '../../../helpers';
import RouteHelper from '../../../helpers/route';
import { useAppContext, useFetch, useNotices, useTabs } from '../../../hooks';
import useIsFirstRender from '../../../hooks/useIsFirstRender';
import {
  getGeneralFormFields,
  getStudiosFormFields
} from '../providers/form-fields';
import './index.css';

const LabsEdit = () => {
  const [ac] = useAppContext();
  const params = useParams();
  const [NoticeComponent, notices] = useNotices('Administration/Labs/Edit');
  const { FetchHelper } = useFetch();
  const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] =
    useTabs('General', ['General', 'Enhancements', 'Studios']);
  const [availableStudios, setAvailableStudios] = useState([]);

  const [data, setData] = useState();
  const [dataGeneral, setDataGeneral] = useState();
  const [dataEnhancements, setDataEnhancements] = useState();
  const [dataStudios, setDataStudios] = useState();
  const [doesPageLiveUpdates, setDoesPageLiveUpdates] = useState(false);

  const [generalFormFields, setGeneralFormFields] = useState(
    getGeneralFormFields(dataGeneral)
  );
  const [studiosFormFields, setStudiosFormFields] = useState(
    getStudiosFormFields(availableStudios, dataStudios)
  );

  const [errors, setErrors] = useState();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    let fields = getGeneralFormFields(dataGeneral);
    setGeneralFormFields(fields);
  }, [dataGeneral]);

  useEffect(() => {
    let fields = getStudiosFormFields(availableStudios, dataStudios);
    setStudiosFormFields(fields);
  }, [availableStudios, dataStudios?.studio_ids]);

  useEffect(() => {
    if (isFirstRender) return;

    let tabsWithFormsInfo = [
      {
        tab: 'General',
        forms: [{ id: 'Main', fields: generalFormFields }]
      },
      {
        tab: 'Studios',
        forms: [{ id: 'Main', fields: studiosFormFields }]
      }
    ];
    setTabsFormsErrors(tabsWithFormsInfo, errors);
  }, [errors]);

  useEffect(() => {
    switch (tab) {
      case 'Enhancements':
        setDoesPageLiveUpdates(true);
        break;

      default:
        setDoesPageLiveUpdates(false);
    }
  }, [tab]);

  useEffect(() => {
    const fetchData = async () => {
      let fetchDataPromises = [];

      // --- fetch lab data
      fetchDataPromises.push(
        FetchHelper({
          url: `/api/v2/admin/labs/${params.id}`
        })
      );

      // --- fetch studios
      fetchDataPromises.push(FetchHelper({ url: '/api/v2/admin/studios' }));

      ac.showSpinner(true);
      let [results] = await Utils.executePromise(
        Promise.all(fetchDataPromises)
      );
      ac.showSpinner(false);

      let labData = results[0];
      let studiosData = results[1];

      let studiosList = [];
      let savedStudios = [];
      let savedStudioIDsMap = lodash.keyBy(
        labData?.body?.studio_ids,
        (value) => value
      );
      lodash.each(studiosData?.body?.data, (item) => {
        let studio = {
          id: item.id,
          name: item.name
        };

        studiosList.push(studio);
        if (savedStudioIDsMap[studio.id]) savedStudios.push(studio);
      });

      let generalData = {
        name: labData?.body?.name,
        logo_base64: labData?.body?.logo?.preview_url
      };

      setAvailableStudios(studiosList);
      setDataGeneral(generalData);
      setDataEnhancements(
        lodash.map(labData?.body?.enhancements?.data, (item) => {
          return {
            ...item,
            extraProps: { value: item.value }
          };
        })
      );
      setDataStudios({ studio_ids: savedStudios });
      setData(labData?.body);
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    let body = { ...dataGeneral };
    if (body.logo_base64) body.logo_base64 = body.logo_base64.value;
    body.studio_ids = lodash.map(dataStudios?.studio_ids, (item) => item.id);

    ac.showSpinner(true);
    let [res, err] = await Utils.executePromise(
      FetchHelper({
        url: `/api/v2/admin/labs/${params.id}`,
        method: 'PUT',
        body: { lab: body }
      })
    );
    ac.showSpinner(false);

    // --- error handling
    if (err) throw err;
    if (res?.status === 422) {
      let errors = Utils.preProcess422Errors([...generalFormFields, ...studiosFormFields], res.body)
      setErrors(errors);
      Utils.scrollTop();

      throw res;
    }
  };

  const renderTabContent = (tab) => {
    switch (tab) {
      case 'General':
        return (
          <>
            <NoticeComponent location="Tab/General" notices={notices} />
            {dataGeneral && (
              <Form
                key={'labs_edit_form_general'}
                formData={dataGeneral}
                formErrors={errors}
                setFormErrors={setErrors}
                onChange={setDataGeneral}
                displayErrorBanner={true}
                fields={generalFormFields}
              />
            )}
          </>
        );

      case 'Enhancements':
        return (
          <>
            <NoticeComponent location="Tab/Enhancements" notices={notices} />
            <FieldSelector
              apiPrefix="lab_enhancement"
              buttonTitle="Enhancement"
              data={dataEnhancements}
              apiURL="/api/v2/admin/lab-enhancements"
              editUrl={(id) => `/lab-enhancements/${id}/edit`}
              syncURL={data.enhancements?.url}
              onChange={setDataEnhancements}
              sortable={false}
              sync={true}
              extraOptions={{
                renderExtra: (
                  item,
                  index,
                  isExtrasDisabled,
                  handleChange,
                  renderError
                ) => {
                  return (
                    <div>
                      <div className="enhancement-value-input-container">
                        <span className="input-span">$</span>
                        <input
                          disabled={isExtrasDisabled}
                          key={'input_amount_' + (item?.value || index)}
                          type="number"
                          name={'value'}
                          value={item?.extraProps?.['value'] || ''}
                          step={0.05}
                          min={0}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value === '') value = 0;
                            handleChange(index, 'value', value);
                          }}
                        />
                      </div>
                      {renderError(index, 'value')}
                    </div>
                  );
                }
              }}
            />
          </>
        );

      case 'Studios':
        return (
          <>
            <NoticeComponent location="Tab/Studios" notices={notices} />
            <Form
              formData={dataStudios}
              formErrors={errors}
              setFormErrors={setErrors}
              onChange={setDataStudios}
              displayErrorBanner={true}
              fields={studiosFormFields}
            />
          </>
        );
    }
  };

  return (
    <div>
      <PageHeader title={`Edit Lab - ${dataGeneral?.name}`} />
      <TabsComponent
        tabs={tabs}
        tab={tab}
        setTab={setTab}
        tabPropsByTab={tabPropsByTab}
      />

      {data && renderTabContent(tab)}

      <ControlBar
        handleSubmit={handleSubmit}
        cancelUrl="/labs"
        handleAfterSubmit={() => RouteHelper.redirect('/labs')}
        handleAfterClear={() => RouteHelper.reload()}
        data={data}
        doesPageLiveUpdates={doesPageLiveUpdates}
      />
    </div>
  );
};

export default LabsEdit;
