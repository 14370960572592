import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FieldSelectorComponent from '../../components/FieldSelector'
import FormComponent from '../../components/Form'
import FieldMultiselectorComponent from '../../components/FieldMultiselector'
import PageHeaderComponent from '../../components/PageHeader'
import ProjectorComponent from '../../components/Projector'
import RouteHelper from '../../helpers/route'
import Tooltip from '../../components/Tooltip'
import UtilsHelper from '../../helpers/utils'
import AssetUploader from '../../componentsV2/Integrated/AssetUploader'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './index.css'
import { useSimpleState } from '../../UI/hooks'
import { getBasicFormFields, getDescriptionFormFields, getDigitalDeliveryFormFields, getHelperFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const PackagesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const {_state: _imageDesktop} = useSimpleState()
    const {_state: _imageMobile} = useSimpleState()

    const [NoticeComponent, notices] = useNotices('PSM/Packages/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()
    const [dataEmphasis, setDataEmphasis] = useState()
    const [dataImage, setDataImage] = useState()
    const [dataMobileImage, setDataMobileImage] = useState()
    const [dataFields, setDataFields] = useState([])
    const [dataCascadeAddons, setDataCascadeAddons] = useState([])
    const [dataCouponCodes, setDataCouponCodes] = useState([])
    const [dataHelper, setDataHelper] = useState([])
    const [dataDigitalDelivery, setDataDigitalDelivery] = useState({})
    const [optionsCategories, setOptionsCategories] = useState([])
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Basic', ['Basic', 'Description', 'Helper', 'Digital Delivery', 'Package Addons', 'Image', 'Mobile Image', 'Fields', 'Coupon Codes'])
    const [doesPageLiveUpdates, setDoesPageLiveUpdates] = useState(false)

    const [basicFormFields, setBasicFormFields] = useState(getBasicFormFields())
    const [descriptionFormFields] = useState(getDescriptionFormFields())
    const [helperFormFields] = useState(getHelperFormFields())
    const [digitalDeliveryFormFields] = useState(getDigitalDeliveryFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getBasicFormFields(suggestionsTags);
        setBasicFormFields(fields)
    }, [suggestionsTags])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Basic',
                forms: [{ id: 'Main', fields: basicFormFields }]
            },
            {
                tab: 'Description',
                forms: [{ id: 'Emphasis', fields: descriptionFormFields }]
            },
            {
                tab: 'Helper',
                forms: [{ id: 'Helper', fields: helperFormFields }]
            },
            {
                tab: 'Digital Delivery',
                forms: [{ id: 'Digital Delivery', fields: digitalDeliveryFormFields }]
            },
            {
                tab: 'Image',
                forms: [{ id: 'Image', fields: _imageDesktop.get(), fieldKeyProp: 'id' }]
            },
            {
                tab: 'Mobile Image',
                forms: [{ id: 'Mobile Image', fields: _imageMobile.get(), fieldKeyProp: 'id' }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        switch(tab) {
            case 'Package Addons':
            case 'Fields':
            case 'Coupon Codes':
                setDoesPageLiveUpdates(true)
                break;

            default:
                setDoesPageLiveUpdates(false)
        }
    }, [tab])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let imageBody = {}

        _imageDesktop.get().forEach((item, index) => {
            imageBody.image_attributes = {
                data_base64: /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                data_url: item.externalUrl,
                data_source: item.assetSource === '0' ? 'local' : 'remote'
            }
        })

        _imageMobile.get().forEach((item, index) => {
            imageBody.mobile_image_attributes = {
                data_base64: /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                data_url: item.externalUrl,
                data_source: item.assetSource === '0' ? 'local' : 'remote'
            }
        })

        let body = {...dataMain, ...dataEmphasis, ...dataHelper, ...dataDigitalDelivery, ...imageBody}

        body.tag_names = body.tags
        delete body.tags

        FetchHelper({
            url: `/api/v2/admin/packages/${params.id}`,
            method: 'PUT',
            body: body
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/packages')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(
                    [..._imageDesktop.get(), ..._imageMobile.get()],
                    res.body
                )
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/packages/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)

            _imageMobile.set([
                {
                    type: 'Mobile Image',
                    id: 'mobile_image',
                    externalUrl: res.body.mobile_image.sources.remote?.url,
                    note: 'Image for package on mobile',
                    attachedImage: {
                        data: res.body.mobile_image.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.mobile_image.source === 'local' ? '0' : '1',
                    relatedFields: ['mobile_image.data_url']
                }
            ])

            _imageDesktop.set([
                {
                    type: 'Desktop Image',
                    id: 'desktop_image',
                    externalUrl: res.body.image.sources.remote?.url,
                    note: 'Image for package on desktop',
                    attachedImage: {
                        data: res.body.image.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.image.source === 'local' ? '0' : '1',
                    relatedFields: ['image.data_url']
                }
            ])
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/packages/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/categories'
        }, (res) => {
            let categories = [{ id: 0, title: 'Select', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: i+1,
                    title: item.name,
                    value: item.id
                }
                categories.push(option)
            })
            setOptionsCategories(categories)
        }, (res) => {
        })
    }, [])

    return (
        <div className="packages-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Package - ${dataMain?.name}`} />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />

            {data ? (
                <>
                    <div className={`page-form-container ${tab === 'Basic' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Basic" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={basicFormFields} />
                    </div>

                    <div className={`page-form-container ${tab === 'Description' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Description" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setDataEmphasis} displayErrorBanner={true} fields={descriptionFormFields} />
                    </div>

                    <div className={`page-form-container ${tab === 'Helper' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Helper" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setDataHelper} displayErrorBanner={true} fields={helperFormFields} />
                    </div>

                    <div className={`page-form-container ${tab === 'Digital Delivery' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/DigitalDelivery" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setDataDigitalDelivery} displayErrorBanner={true} fields={digitalDeliveryFormFields} />
                    </div>

                    <div className={`page-form-container ${tab === 'Package Addons' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/PackageAddons" notices={notices} />

                        <FieldMultiselectorComponent entityParent="Package" entityType="Addon" data={data.addons.data} apiUrl={data.addons.url} sync={true} onChange={() => {}} columns={[
                            {
                                'title': 'Name'
                            },
                            {
                                'title': 'Price'
                            }
                        ]} />
                    </div>

                    <div className={`page-form-container ${tab === 'Image' ? 'show' : ''}`}>
                        {/* <ProjectorComponent url={data.image.image_url} /> */}
                        <NoticeComponent location="Tab/Image" notices={notices} />
                        <AssetUploader list={_imageDesktop.get()} key={tab} events={{ handleChange: (value) => _imageDesktop.set(value) }}
                            errors={errors} setErrors={setErrors} />
                    </div>

                    <div className={`page-form-container ${tab === 'Mobile Image' ? 'show' : ''}`}>
                        {/* <ProjectorComponent url={data.mobile_image.image_url} /> */}
                        <NoticeComponent location="Tab/MobileImage" notices={notices} />
                        <AssetUploader list={_imageMobile.get()} key={tab} events={{ handleChange: (value) => _imageMobile.set(value) }}
                            errors={errors} setErrors={setErrors} />
                        {/* <FormComponent formData={data} formErrors={errors} onChange={setDataMobileImage} displayErrorBanner={true} fields={[{
                            type: 'input-file',
                            name: 'mobile_image_attributes',
                            label: 'Mobile Image',
                            label_info: '(512kb max)',
                            field_info: 'We suggest rectangular for image as description and square for text description, e.g. 800x300 or 600x600. Images will be scaled proportionally'
                        }]} /> */}
                    </div>

                    <div className={`page-form-container ${tab === 'Fields' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Fields" notices={notices} />
                        <FieldSelectorComponent sync={true} syncURL={data.fields.url} apiPrefix="field" buttonTitle="Field" data={data.fields.data} apiURL="/api/v2/admin/fields" editUrl={(id) => `/fields/${id}/edit`} onChange={(data) => setDataFields(data)} />
                    </div>

                    <div className={`page-form-container ${tab === 'Coupon Codes' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/CouponCodes" notices={notices} />
                        <FieldSelectorComponent sync={true} sortable={false} syncURL={data.coupon_codes.url} apiPrefix="coupon_code" buttonTitle="Coupon Code" data={data.coupon_codes.data} apiURL="/api/v2/admin/coupon-codes" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} />
                    </div>
                </>
            ) : null}

            <ControlBar data={data} baseApiUrl="/packages" redirectUrl="/packages" cancelUrl="/packages" handleSubmit={handleSubmit}
                doesPageLiveUpdates={doesPageLiveUpdates}/>
        </div>
    )
}

export default PackagesEdit
