import showInfoExpirationMsgTTImage from '../../../assets/tooltips/expiration-d6e9a34282f59843ae9dc4d2ecf18ed4fa60c747d6ec211e6f0b389740461e9c.jpeg';
import showInfoInstructionsTTImage from '../../../assets/tooltips/instructions-2a0bca79ce88e735be55a85ed06c1cfb1a578cf31f3eca770b1b12717bcdb41e.jpeg';
import addonsHeaderTTImg from '../../../assets/tooltips/addonspopup_header-2c0a74a8270e9fc4c11a78c94e74835aea1bc836bdea20adfc05767adb196db3.jpg';
import addonsTitleTTImg from '../../../assets/tooltips/addonspopup_marketingsnippet_title-8430c1d9e64bc5cd47b66e80dd683db573872d7ebc89ec6177879233517fa768.jpg';
import empAddonsHeaderTTImg from '../../../assets/tooltips/emph_addonspopup_header-6ea3c9933902097485dc65e751e8a0f22adfa67833fb737ad5cc8b02a516dd77.jpg';
import empAddonsTitleTTImg from '../../../assets/tooltips/emph_addonspopup_title-6ba94225becc2cb1c54f2074c7011d58ba7f76488ce2894eb4eebe02738e133d.jpg';
import facebookCustomShareMsgTTImg from '../../../assets/tooltips/faceb_sharerapp-880a208932acc209c0c887f7075892f05e02b8fb979051e90cb1e0e378245fb5.jpg';
import facebookShareMsgTTImg from '../../../assets/tooltips/faceb_sharemessage-c76c92d466eb21b61f3a3356a2d575d493f23fba5e0dde8922f12dadf67e19dc.jpg';
import UtilsHelper from '../../../helpers/utils';
import { textService, textSize } from '../../../sys/constants/MarketingPlans';
import { HTMLEditorMode } from '../../../sys/constants/enums';
import { FileTypes } from '../../../sys/constants/enums';

export function getGeneralFormFields() {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name'
    },
    {
      type: 'wysiwyg',
      name: 'expiration_message',
      label: 'Expiration Message',
      label_info: <img src={showInfoExpirationMsgTTImage} />
    }
  ];
}

export function getMarketingViewFormFields(dataMarketingView) {
  return [
    {
      type: 'input-file',
      name: 'marketing_view_splash_base64',
      label: 'Splash',
      label_info: 'Max Width: 765px',
      acceptedFileTypes: FileTypes.IMAGES,
      imageUrl: dataMarketingView?.marketing_view_splash_base64
    },
    {
      type: 'input-file',
      name: 'marketing_view_sidebar_base64',
      label: 'Sidebar',
      label_info: 'Max Width: 390px',
      acceptedFileTypes: FileTypes.IMAGES,
      imageUrl: dataMarketingView?.marketing_view_sidebar_base64
    }
  ];
}

export function getAddonsPopupRegularFormFields() {
  return [
    {
      type: 'input-text',
      name: 'addons_header',
      label: 'Addons Header',
      label_info: <img src={addonsHeaderTTImg} />
    },
    {
      type: 'input-text',
      name: 'addons_snippet_title',
      label: 'Addons Title',
      label_info: <img src={addonsTitleTTImg} />
    },
    {
      type: 'wysiwyg',
      name: 'addons_snippet_body',
      label: 'Marketing Snippet Body'
    }
  ];
}

export function getAddonsPopupEmphasizedFormFields() {
  return [
    {
      type: 'input-text',
      name: 'emphasized_addons_header',
      label: 'Emphasized Addons Header',
      label_info: <img src={empAddonsHeaderTTImg} />
    },
    {
      type: 'input-text',
      name: 'emphasized_addons_snippet_title',
      label: 'Emphasized Addons Title',
      label_info: <img src={empAddonsTitleTTImg} />
    },
    {
      type: 'wysiwyg',
      name: 'emphasized_addons_snippet_body',
      label: 'Emphasized Marketing Snippet Body'
    }
  ];
}

export function getHelpMarketsFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'market_header_text',
      label: 'Header text'
    }
  ];
}

export function getHelpReservationsFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'reservation_reschedule_balance_text',
      label: 'Reschedule Balance Text'
    },
    {
      type: 'wysiwyg',
      name: 'reservation_reschedule_unavailable_text',
      label: 'Reschedule Unavailable Text'
    }
  ];
}

export function getHelpBackgroundsFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'background_selector_header_text',
      label: 'Background Selector: Header'
    }
  ];
}

export function getHelpPosesFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'pose_selector_header_text',
      label: 'Pose Selector: Header'
    }
  ];
}

export function getHelpSheetsFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'sheet_selector_header_text',
      label: 'Sheet Selector: Header'
    }
  ];
}

export function getHelpOrderConfirmationFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'instructions',
      label: 'Instructions',
      label_info: <img src={showInfoInstructionsTTImage} />
    },
    {
      type: 'input-text',
      name: 'confirmation_bottom_left_note_header',
      label: 'Bottom Left Note Header'
    },
    {
      type: 'wysiwyg',
      name: 'confirmation_bottom_left_note_content',
      label: 'Bottom Left Note Content'
    },
    {
      type: 'input-text',
      name: 'confirmation_bottom_right_note_header',
      label: 'Bottom Right Note Header'
    },
    {
      type: 'wysiwyg',
      name: 'confirmation_bottom_right_note_content',
      label: 'Bottom Right Note Content'
    }
  ];
}

export function getFacebookFormFields() {
  return [
    {
      type: 'input-text',
      name: 'custom_fb_share_message_stuff',
      label: 'I just got',
      label_info: <img src={facebookCustomShareMsgTTImg} />
    },
    {
      type: 'input-text',
      name: 'custom_fb_share_message_photo_type',
      label: 'By ordering my',
      label_info: <img src={facebookCustomShareMsgTTImg} />
    },
    {
      type: 'input-text',
      name: 'custom_fb_share_message_source_name',
      label: 'Photos via',
      label_info: <img src={facebookCustomShareMsgTTImg} />
    },
    {
      type: 'wysiwyg',
      name: 'custom_fb_share_message_shadowbox',
      label: 'Share Message in Popup',
      label_info: <img src={facebookShareMsgTTImg} />
    }
  ];
}

function getEmailContentField(name) {
  return {
    type: 'wysiwyg',
    name: name,
    label: 'Order confirmation email content',
    label_info: 'Drag and Drop Images',
    editorMode: HTMLEditorMode.MODAL,
    showTags: true
  };
}

export function getEmailConfirmationFormFields(testEmailFieldHTML) {
  return [
    {
      type: 'html',
      html: testEmailFieldHTML,
      label: 'Test Email',
      uniqKey: UtilsHelper.generateUUID()
    },
    {
      type: 'input-text',
      name: 'order_confirmation_email_subject',
      label: 'Order confirmation email subject'
    },
    {
      type: 'switch',
      name: 'order_confirmation_email_replace_content',
      label: 'Order confirmation email replace content'
    },
    getEmailContentField('order_confirmation_email_content')
  ];
}

export function getEmailReminderFormFields(testEmailFieldHTML) {
  return [
    {
      type: 'html',
      html: testEmailFieldHTML,
      label: 'Test Email',
      uniqKey: UtilsHelper.generateUUID()
    },
    {
      type: 'input-text',
      name: 'reservation_reminder_email_subject',
      label: 'Reservation reminder email subject'
    },
    {
      type: 'switch',
      name: 'reservation_reminder_email_replace_content',
      label: 'Reservation reminder email replace content'
    },
    getEmailContentField('reservation_reminder_email_content')
  ];
}

export function getEmailThankYouFormFields(testEmailFieldHTML) {
  return [
    {
      type: 'html',
      html: testEmailFieldHTML,
      label: 'Test Email',
      uniqKey: UtilsHelper.generateUUID()
    },
    {
      type: 'switch',
      name: 'send_thank_you_email',
      label: 'Send "Thank You" email when order is processed?'
    },
    {
      type: 'input-text',
      name: 'thank_you_email_subject',
      label: '"Thank You" email subject'
    },
    {
      type: 'switch',
      name: 'thank_you_email_replace_content',
      label: 'Thank you email replace content'
    },
    getEmailContentField('thank_you_email_content')
  ];
}

export function getEmailDDReadyFormFields(testEmailFieldHTML) {
  return [
    {
      type: 'html',
      html: testEmailFieldHTML,
      label: 'Test Email',
      uniqKey: UtilsHelper.generateUUID()
    },
    {
      type: 'input-text',
      name: 'ddl_ready_email_subject',
      label: 'Ddl ready email subject'
    },
    {
      type: 'switch',
      name: 'ddl_ready_email_replace_content',
      label: 'Ddl ready email replace content'
    },
    {
      type: 'select-paginated',
      name: 'ddl_ready_email_content_template_id',
      label: 'Ddl ready email content template',
      apiUrl: '/api/v2/admin/templates',
      queries: [{ key: 'type', value: 'EmailTemplate' }]
    }
  ];
}

export function getEmailWalkupFormFields(testEmailFieldHTML) {
  return [
    {
      type: 'html',
      html: testEmailFieldHTML,
      label: 'Test Email',
      uniqKey: UtilsHelper.generateUUID()
    },
    {
      type: 'input-text',
      name: 'market_walkup_email_subject',
      label: 'Market walkup email subject'
    },
    {
      type: 'switch',
      name: 'market_walkup_email_replace_content',
      label: 'Market walkup email replace content'
    },
    getEmailContentField('market_walkup_email_content')
  ];
}

export function getFooterFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'footer',
      label: 'Footer'
    }
  ];
}

export function getServicesZapierFormFields() {
  return [
    {
      type: 'switch',
      name: 'zapier_service_enabled',
      label: 'Zapier Service: Enabled?'
    },
    {
      type: 'input-text',
      name: 'zapier_service_url',
      label: 'Zapier Service: URL',
      field_info: (
        <p>
          This will change based on the equivalent setting in the following
          order of precedence: Marketing Plan URL overwrites Market Group URL
          overwrites Studio URL
        </p>
      )
    }
  ];
}

export function getServicesTextingFormFields() {
  return [
    {
      type: 'switch',
      name: 'twilio_service_enabled',
      label: 'Texting Service: Enabled?'
    },
    {
      type: 'select',
      name: 'twilio_service_order_confirmation_message_template_id',
      label: 'Texting Service: Order Confirmation Message Template',
      options: textService
    },
    {
      type: 'select',
      name: 'twilio_service_order_reminder_message_template_id',
      label: 'Texting Service: Order Reminder Message Template',
      options: textService
    },
    {
      type: 'select',
      name: 'twilio_service_thank_you_message_template_id',
      label: 'Texting Service: Thank You Message Template',
      options: textService
    },
    {
      type: 'select-paginated',
      name: 'twilio_service_ddl_ready_message_template_id',
      label: 'Texting Service: Ddl Ready Message Template',
      apiUrl: '/api/v2/admin/templates',
      queries: [{ key: 'type', value: 'EmailTemplate' }]
    }
  ];
}

export function getServicesPortableNorthPoleFormFields() {
  return [
    {
      type: 'switch',
      name: 'portable_north_pole_service_enabled',
      label: 'Portable north pole service enabled'
    }
  ];
}

export function getServicesCentricsFormFields() {
  return [
    {
      type: 'switch',
      name: 'centrics_service_enabled',
      label: 'Centrics service enabled'
    }
  ];
}

export function getServicesOrderQRCodeFormFields() {
  return [
    {
      type: 'switch',
      name: 'triprism_service_enabled',
      label: 'Order QR Code: Enabled?'
    }
  ];
}

export function getServicesDDLabFormFields() {
  return [
    {
      type: 'switch',
      name: 'ddlabs_service_attach_ddlabs_pdf_to_confirmation_email',
      label: 'DDLab Service: Attach DDLab PDF to Confirmation Email?'
    },
    {
      type: 'switch',
      name: 'ddlabs_service_receipt_prints_ddlabs_pdf',
      label: 'DDLab Service: Receipt Prints DDLab PDF?'
    },
    {
      type: 'wysiwyg',
      name: 'ddlabs_service_receipt_prints_ddlabs_pdf_message',
      label: 'DDLab Service: Non-PDF Print Instructions',
      label_info:
        'This message will display if the customer attempts to print the HTML version of the receipt. Use this to direct the customer to print the PDF.'
    }
  ];
}

export function getOverridesFormFields() {
  return [
    {
      type: 'input-text',
      name: 'override_add_to_cart_text',
      label: 'Override: Add to Cart'
    },
    {
      type: 'input-text',
      name: 'override_add_to_cart_zero_cost_text',
      label: 'Override add to cart zero cost text'
    },
    {
      type: 'input-text',
      name: 'override_get_addons_text',
      label: 'Override: Get Addons'
    },
    {
      type: 'input-text',
      name: 'override_get_addons_zero_cost_text',
      label: 'Override get addons zero cost text'
    },
    {
      type: 'input-text',
      name: 'convenience_fee_text',
      label: 'Override: Convenience Fee Text'
    },
    {
      type: 'select',
      name: 'override_convenience_fee_text_size',
      label: 'Override: Convenience Fee Text Size',
      options: textSize,
      inputDescription: (
        <div style={{ fontSize: '14px', lineHeight: '1.6em' }}>
          <span style={{ fontSize: '0.7em' }}>
            <strong>Convenience Fee:</strong> <code>Xsmall</code>
          </span>
          <br />
          <span style={{ fontSize: '0.8em' }}>
            <strong>Convenience Fee:</strong> <code>Default</code>
          </span>
          <br />
          <span style={{ fontSize: '1.0em' }}>
            <strong>Convenience Fee:</strong> <code>Medium</code>
          </span>
          <br />
          <span style={{ fontSize: '1.2em' }}>
            <strong>Convenience Fee:</strong> <code>Large</code>
          </span>
          <br />
          <span style={{ fontSize: '1.5em' }}>
            <strong>Convenience Fee:</strong> <code>Xlarge</code>
          </span>
          <br />
        </div>
      )
    },
    {
      type: 'select',
      name: 'override_market_name_text_size',
      label: 'Override: Market name text size',
      options: textSize,
      inputDescription: (
        <div style={{ fontSize: '25px', lineHeight: '1.6em' }}>
          <span style={{ fontSize: '0.7em' }}>
            <strong>Some Market Name:</strong> <code>Xsmall</code>
          </span>
          <br />
          <span style={{ fontSize: '0.8em' }}>
            <strong>Some Market Name:</strong> <code>Default</code>
          </span>
          <br />
          <span style={{ fontSize: '1.0em' }}>
            <strong>Some Market Name:</strong> <code>Medium</code>
          </span>
          <br />
          <span style={{ fontSize: '1.2em' }}>
            <strong>Some Market Name:</strong> <code>Large</code>
          </span>
          <br />
          <span style={{ fontSize: '1.5em' }}>
            <strong>Some Market Name:</strong> <code>Xlarge</code>
          </span>
          <br />
        </div>
      )
    },
    {
      type: 'input-text',
      name: 'override_scheduling_date_with_availability_text',
      label: 'Override: Scheduling Date WITH Availability'
    },
    {
      type: 'input-text',
      name: 'override_scheduling_date_without_availability_text',
      label: 'Override: Scheduling Date WITHOUT Availability'
    },
    {
      type: 'input-text',
      name: 'step_1_text',
      label: 'Override: Step 1 Text'
    },
    {
      type: 'input-text',
      name: 'step_2_text',
      label: 'Override: Step 2 Text'
    },
    {
      type: 'input-text',
      name: 'step_3_text',
      label: 'Override: Step 3 Text'
    }
  ];
}

export function getProofingFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'above_proof_code_request_form_text',
      label: 'Above Proof Code Request Form Text'
    },
    {
      type: 'wysiwyg',
      name: 'proof_code_request_drawer_text',
      label: 'Proof Code Request Drawer Text'
    },
    {
      type: 'wysiwyg',
      name: 'proof_preview_modal_text',
      label: 'Proof Preview Modal Text'
    }
  ];
}

export function getSelfServiceRefundFormFields() {
  return [
    {
      type: 'wysiwyg',
      name: 'self_service_refund_text',
      label: 'Self service refund text',
      label_info:
        'This message will display if the customer attempts to cancel their order in addtion to the default message with the amount'
    }
  ];
}
