import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'
import { FileTypes } from '../../sys/constants/enums'

const PosesNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Poses/New')

    const [errors, setErrors] = useState()
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('PSM:Poses:DataMain')
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = {...dataMain}

            if (body.image_attributes) {
                body.image_attributes = {
                    data_base64: body.image_attributes.value
                }
            }

            body.tag_names = body.tags
            delete body.tags

            FetchHelper({
                url: `/api/v2/admin/poses`,
                method: 'POST',
                body: { pose: body }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/poses/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {
        })
    }, [])

    return (
        <div className="poses-new-page-container page-container">
            <PageHeaderComponent title='New Pose' />
            <NoticeComponent location="Main" notices={notices} />
            <FormComponent formData={dataMain} formErrors={errors} onChange={setDataMain} displayErrorBanner={true}
                fields={[{
                        type: 'select',
                        name: 'type',
                        label: 'Type',
                        value: 'IndividualPose',
                        options: [{
                            title: 'Individual',
                            value: 'IndividualPose'
                        },{
                            title: 'Couple',
                            value: 'CouplePose'
                        }]
                    },{
                        type: 'input-text',
                        name: 'name',
                        label: 'Name'
                    },{
                        type: 'tags',
                        name: 'tags',
                        label: 'Tags',
                        suggestions: suggestionsTags
                    },{
                        type: 'input-text',
                        name: 'code',
                        label: 'Code',
                    },{
                        type: 'input-number',
                        name: 'shipping_premium',
                        label: 'Shipping premium',
                    },{
                        type: 'input-file',
                        name: 'image_attributes',
                        label: 'Image',
                        label_info: '(512kb max)',
                        acceptedFileTypes: FileTypes.IMAGES,
                        field_info: 'Optimal Image Size is 183 x 275 pixels.'
                }]} />
                <ControlBar handleSubmit={handleSubmit} cancelUrl="/poses" handleAfterSubmit={() => RouteHelper.redirect('/poses')} handleAfterClear={() => {
                    RouteHelper.reload()
                }} dataItems={[
                    [dataMain, clearDataMain, dataMainDefault]
                ]} />
        </div>
    )
}

export default PosesNew
