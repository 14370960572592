import PropTypes from 'prop-types'

import { Form, FormRow, FormFieldErrorComponent, FormFieldMoneyComponent } from '../../../../componentsV2/Form'
import {  LabelComponent, InputComponent, ButtonComponent, ParagraphComponent, HeadingComponent } from '../../../../componentsV2/Primitive'
import { ModalComponent } from '../../../../componentsV2/Complex'
import { PlaceholderGenericSpinnerComponent } from '../../../../componentsV2/Integrated'
import { UI } from '../../../../UI'
import FetchHelper from '../../../../helpers/fetch'
import RouteHelper from '../../../../helpers/route'

import './index.css'

const DEFAULTS = {
    reason: null,
    amount: null
}

const DiscountModalComponent = ({ _apis, _togglesModal, events={} }) => {
    const {_state} = UI.useSimpleState({
        currentView: 'form',
        clearCount: 0,
        success: false
    })

    const {_state: _api} = UI.useSimpleState()

    const {_state: _form} = UI.useSimpleState(DEFAULTS)
    const {_state: _errors} = UI.useSimpleState(DEFAULTS)

    const {_state: _transitions} = UI.useSimpleState({
        currentView: null,
        nextView: null
    })

    const {_state: _dependencies} = UI.useSimpleState({
        processed: false
    })

    const syncPost = () => {
        _errors.set({})
        
        let body = {
            discount: {
              reason: _form.get('reason'),
              value: _form.get('amount')
            }
        }

        FetchHelper({
            url: `/api/v2/admin/orders/${_apis.get('order').id}/discounts`,
            method: 'post',
            body: body
        }, (res) => {
            if (res.status === 200) {
                _state.set('success', true)

                _transitions.set({
                    currentView: 'progress',
                    nextView: 'complete'
                })
            } else {
                _errors.set(res.body)

                _transitions.set({
                    currentView: 'progress',
                    nextView: 'form'
                })
            }
        }, (res) => {
            _transitions.set({
                currentView: 'progress',
                nextView: 'form'
            })
        })  
    }

    const handleDiscountClick = () => {
        _transitions.set({
            currentView: 'form',
            nextView: 'progress'
        })

        syncPost()
    }

    const handleClose = () => {
        if (_state.get('success')) {
            RouteHelper.reload()
        } else {
            _togglesModal.set('discount', false)
        }
    }

    const handleClearClick = () => {
        _form.set(DEFAULTS)
        _state.set('clearCount', _state.get('clearCount') + 1)
    }

    const isDiscountButtonDisabled = () => {
        let returnValue = true

        if (_form.get('reason') && _form.get('amount')) {
            returnValue = false
        }

        return returnValue
    }

    const renderView = () => {
        switch (_state.get('currentView')) {
            case 'form':
                return renderViewForm()
            case 'progress':
                return renderViewProgress()
            case 'complete':
                return renderViewComplete()
        }
    }
    
    const handleTransition = () => {
        if (_transitions.get('nextView') !== null) {
            _state.set('currentView', _transitions.get('nextView'))

            _transitions.set({
                currentView: null,
                nextView: null
            })
        }
    }

    const renderViewComplete = () => {
        return (
            <UI.Layout name="discountComplete" type="container">
                <HeadingComponent type="h3">Discount Applied</HeadingComponent>
                <div className="ui--discountCompleteIcon">
                    <i className="ui-icon bi bi-check-circle"></i>
                </div>
                <div className="ui--discountCompleteUIControls">
                    <ButtonComponent config={{ palette: "blue" }} events={{ handleClick: handleClose }}>Done</ButtonComponent>
                </div>
            </UI.Layout>
        )
    }

    const renderViewProgress = () => {
        return (
            <UI.Layout
                name="discountProgress"
                type="container"
                config={{ classNames: _transitions.get('currentView') === 'progress' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <ParagraphComponent>Processing discount...</ParagraphComponent>

                <div className="ui--discountProgressSpinner">
                    <PlaceholderGenericSpinnerComponent />
                </div>
            </UI.Layout>
        )
    }

    const renderViewForm = () => {
        return (
            <UI.Layout
                name="discountForm"
                type="container"
                config={{ classNames: _transitions.get('currentView') === 'form' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <Form name="discountForm" key={`ui-key--discountForm--cleared--${_state.get('clearCount')}`}>
                    <HeadingComponent type="h3">Apply Discount</HeadingComponent>

                    <div className="ui--discountFormDetails">
                        <FormRow>
                            <LabelComponent htmlFor="reason">Reason for discount</LabelComponent>
                            <InputComponent name="reason" type="text" value={_form.get('reason')} config={{ hasError: !!_errors.get('reason') }} events={{ handleChange: (value) => _form.set('reason', value) }} />
                            <FormFieldErrorComponent fieldName="reason" errors={_errors.get()} />
                        </FormRow>

                        <FormRow>
                            <LabelComponent htmlFor="reason">Amount</LabelComponent>
                            <FormFieldMoneyComponent name="amount" value={_form.get('amount')} config={{ hasError: !!_errors.get('value') }} events={{ handleChange: (value) => _form.set('amount', value) }} />
                            <FormFieldErrorComponent fieldName="value" errors={_errors.get()} />
                        </FormRow>
                    </div>

                    <div className="ui--controls grid grid-cols-2 gap-2">
                        <ButtonComponent config={{ disabled: isDiscountButtonDisabled(), palette: 'blue' }} events={{ handleClick: handleDiscountClick }}>Apply</ButtonComponent> <ButtonComponent config={{ palette: 'defaultLight' }} events={{ handleClick: handleClearClick }}>Clear</ButtonComponent>
                    </div>
                </Form>
            </UI.Layout>
        )
    }

    return (
        <ModalComponent config={{ classNames: 'ui--discountModal', classNamesInner: _state.get('currentView') === 'complete' ? 'ui-action--centered' : '' }} events={{ handleClose: handleClose }}>
            {renderView()}
        </ModalComponent>
    )
}

// PayModal.propTypes = {

// }

export { DiscountModalComponent }