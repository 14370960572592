import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import './index.css'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import { getSettingsForm1Fields, getSettingsPercentageFormFields, getSettingsTimeFormFields, getStudioInfoForm1Fields, getStudioInfoForm2Fields, getStudioInfoForm3Fields, getThirdPartyServicesFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const StudiosNew = () => {
    const [ac] = useAppContext()
    
    const [NoticeComponent, notices] = useNotices('Administration/Studios/New')

    const [errors, setErrors] = useState()

    // DATA
    const [data, setData, clearData, dataDefault] = useDraft('Administration:Studios:Data')
    const [settings, setSettings, clearDataSettings, dataSettingsDefault] = useDraft('Administration:Studios:DataSettings')
    const [address, setAddress, clearDataAddress, dataAddressDefault] = useDraft('Administration:Studios:DataAddress')
    const [studioInfo, setStudioInfo, clearDataStudioInfo, dataStudioInfoDefault] = useDraft('Administration:Studios:DataStudioInfo')
    const [timeSettings, setTimeSettings, clearDataTimeSettings, dataTimeSettingsDefault] = useDraft('Administration:Studios:DataTimeSettings')
    const [percentSettings, setPercentSettings, clearDataPercentSettings, dataPercentSettingsDefault] = useDraft('Administration:Studios:DataPercentSettings')
    const [services, setServices, clearDataServices, dataServicesDefault] = useDraft('Administration:Studios:DataServices')
    // 
    const [billingProfiles, setBillingProfiles] = useState([])
    
    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Studio Info', ['Studio Info', 'Settings', 'Third-Party Services'])

    const [studioInfoForm1Fields, setStudioInfoForm1Fields] = useState(getStudioInfoForm1Fields(billingProfiles))
    const [studioInfoForm2Fields] = useState(getStudioInfoForm2Fields())
    const [studioInfoForm3Fields, setStudioInfoForm3Fields] = useState(getStudioInfoForm3Fields(ac.AuthHelper?.settings?.sys?.timezones))
    const [settingsForm1Fields] = useState(getSettingsForm1Fields())
    const [settingsPercentageFormFields] = useState(getSettingsPercentageFormFields())
    const [settingsTimeFormFields] = useState(getSettingsTimeFormFields())
    const [thirdPartyServicesFormFields, setThirdPartyServicesFormFields] = useState(getThirdPartyServicesFormFields())

    useEffect(() => {
        let fields = getStudioInfoForm1Fields(billingProfiles)
        setStudioInfoForm1Fields(fields)
    }, [billingProfiles])

    useEffect(() => {
        let fields = getStudioInfoForm3Fields(ac.AuthHelper?.settings?.sys?.timezones);
        setStudioInfoForm3Fields(fields)
    }, [ac.AuthHelper?.settings?.sys?.timezones])

    useEffect(() => {
        let fields = getThirdPartyServicesFormFields(undefined, undefined, services)
        setThirdPartyServicesFormFields(fields)
    }, [services])

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Studio Info',
                forms: [{ id: 'Main', fields: studioInfoForm1Fields }, { id: 'form2', fields: studioInfoForm2Fields }, { id: 'form3', fields: studioInfoForm3Fields }]
            },
            {
                tab: 'Settings',
                forms: [{ id: 'Main', fields: settingsForm1Fields }, { id: 'percentageForm', fields: settingsPercentageFormFields }, { id: 'timeForm', fields: settingsTimeFormFields }]
            },
            {
                tab: 'Third-Party Services',
                forms: [{ id: 'Main', fields: thirdPartyServicesFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/billing-profiles'
        }, (res) => {
            const options = [{ id: null, title: '--', value: "null" }];
            res.body.data.map((profile) => {
                const option = {
                    value: profile.id,
                    title: profile.name
                }
                options.push(option)
            })
            setBillingProfiles(options)
        }, (res) => {

        })
    }, [])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let appointment_aware_refund_schedule = []
            if(timeSettings && percentSettings){
                for (let i=0; i<5; i++){
                    const limitKey = Object.keys(timeSettings)[i]
                    const percentKey = Object.keys(percentSettings)[i]
                    appointment_aware_refund_schedule.push({
                        limit: timeSettings[limitKey],
                        percentage: percentSettings[percentKey]
                    })
                }
            }

            let body = {
                ...data,
                ...settings,
                ...studioInfo,
                ...services,
                address_attributes: address,
                past_due: data.past_due !== "" ? data.past_due : false,
                appointment_aware_refund_schedule: appointment_aware_refund_schedule,
                customer_service_email: UtilsHelper.stringifyEmails(data.customer_service_email)
            }

            if (body.watermark?.value)
                body.watermark_base64 = body.watermark.value

            delete body.watermark

            FetchHelper({
                url: `/api/v2/admin/studios`,
                method: 'POST',
                body: {
                    studio: body
                }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    let errors = UtilsHelper.preProcess422Errors(
                        [...studioInfoForm1Fields, ...studioInfoForm2Fields, ...studioInfoForm3Fields, ...settingsForm1Fields, ...settingsPercentageFormFields, ...settingsTimeFormFields, ...thirdPartyServicesFormFields],
                        res.body
                    )
                    setErrors(errors)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className='studios-container'>
            <PageHeaderComponent title={`New Studio`} />
            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            <div className="tab-content">
                <div className={`page-form-container ${tab === 'Studio Info' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/StudioInfo" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setData} displayErrorBanner={true} fields={studioInfoForm1Fields} />
                        <FormComponent formData={address} formErrors={errors} setFormErrors={setErrors} onChange={setAddress} fields={studioInfoForm2Fields} />
                        <FormComponent formData={studioInfo} formErrors={errors} setFormErrors={setErrors} onChange={setStudioInfo} fields={studioInfoForm3Fields} />
                    </div>
                    <div className={`page-form-container ${tab === 'Settings' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Settings" notices={notices} />
                        <FormComponent formData={settings} formErrors={errors} setFormErrors={setErrors} onChange={setSettings} displayErrorBanner={true} fields={settingsForm1Fields} />
                        <div>
                            <div className="schedule-section">
                                <div className="section-title">Appointment aware refund schedule</div>

                                <div className="global-section">
                                    <div className="schedule-section-titles">
                                        <div className="title">Percentage</div><div className="title">Limit (in hours)</div>
                                    </div>

                                    <div className="schedule-section-forms">
                                        <div className='schedule-section-form'>
                                            <FormComponent formData={percentSettings} formErrors={errors} setFormErrors={setErrors} onChange={setPercentSettings} displayErrorBanner={false} fields={settingsPercentageFormFields} />
                                        </div>
                                        
                                        <div className='schedule-section-form'>
                                            <FormComponent formData={timeSettings} formErrors={errors} setFormErrors={setErrors} onChange={setTimeSettings} displayErrorBanner={false} fields={settingsTimeFormFields} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`page-form-container ${tab === 'Third-Party Services' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/ThirdPartyServices" notices={notices} />
                        <FormComponent formData={services} formErrors={errors} setFormErrors={setErrors} onChange={setServices} displayErrorBanner={true} fields={thirdPartyServicesFormFields} />
                    </div>
            </div>

            <ControlBar handleSubmit={handleSubmit} cancelUrl="/studios" handleAfterSubmit={() => RouteHelper.redirect('/studios')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault],
                [settings, clearDataSettings, dataSettingsDefault],
                [address, clearDataAddress, dataAddressDefault],
                [studioInfo, clearDataStudioInfo, dataStudioInfoDefault],
                [timeSettings, clearDataTimeSettings, dataTimeSettingsDefault],
                [percentSettings, clearDataPercentSettings, dataPercentSettingsDefault],
                [services, clearDataServices, dataServicesDefault],
            ]} />
        </div>
    )
}

export default StudiosNew