import { useEffect, useState } from 'react'

import { useAppContext } from '../../hooks'

import './index.css'

const DisplayOverlayPrimary = () => {
    const [ac] = useAppContext()

    const handleClose = (event) => {
        event.stopPropagation()

        ac.displayOverlay.onClose()
        ac.setDisplayOverlay()
    }

    if (ac.displayOverlay) {
        const dom_boundingBox = ac.displayOverlay.target.getBoundingClientRect()
        const dom_innerWidth = window.innerWidth

        let styleLeft = ac.displayOverlay.style?.left || dom_boundingBox.left
        let styleTop = ac.displayOverlay.style?.top || dom_boundingBox.top
        let styleWidth = ac.displayOverlay.style?.width || dom_boundingBox.width
    
        if (styleWidth > dom_innerWidth) {
            styleWidth -= styleWidth - dom_innerWidth
        }

        if ((styleLeft + styleWidth + 20) > dom_innerWidth) {
            styleLeft -= styleLeft + styleWidth - dom_innerWidth + 20
        }

        return (
            <div className="display-overlay-primary-container" onClick={handleClose}>
                <div className="display-content" style={{
                    left: styleLeft,
                    top: styleTop,
                    width: styleWidth
                }}>
                    {ac.displayOverlay.content()}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default DisplayOverlayPrimary