import DropdownTextCollection from "../DropdownTextCollection";
import "./index.css";

const TextTags = ({ onSelect = () => {} }) => {
  let textSubstitutionOptions = [
    {
      type: "group",
      title: "Market",
      list: [
        { value: "%{market_name}", title: "Market Name" },
        { value: "%{market_url}", title: "Market Short URL" },
      ],
    },
    {
      type: "group",
      title: "Customer",
      list: [
        { title: "Customer First Name", value: "%{customer_first_name}" },
        { title: "Customer Last Name", value: "%{customer_last_name}" },
      ],
    },
    {
      type: "group",
      title: "Confirmation",
      list: [
        { title: "Confirmation Number", value: "%{confirmation_number}" },
        { title: "Confirmation URL", value: "%{confirmation_url}" },
      ],
    },
    {
      type: "group",
      title: "Reservation",
      list: [
        { title: "Reservation", value: "%{reservation}" },
        { title: "Reservation ETA", value: "%{reservation_eta}" },
        { title: "Reschedule URL", value: "%{reschedule_url}" },
      ],
    },
    {
      type: "group",
      title: "Campaign",
      list: [{ title: "Custom Campaign Field", value: "%{custom_REPLACEME}" }],
    },
  ];

  return (
    <div className="text-tags-container">
        <DropdownTextCollection
            options={textSubstitutionOptions} onSelect={onSelect} />
    </div>
  )
};

export default TextTags;
