import PropTypes from 'prop-types'

import { DropdownComponent } from '../../../componentsV2/Complex/Dropdown'
import { MenuComponent } from '../Menu'

import './index.css'

const EllipsisMenuComponent = ({ items }) => {
    if (!items)
        return

    return (
        <div className="ui-container--ellipsisMenu">
            <DropdownComponent>
                <i className="ui-icon bi bi-three-dots-vertical"></i>

                <MenuComponent items={items} />
            </DropdownComponent>
        </div>
    )
}

EllipsisMenuComponent.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.any,
        callback: PropTypes.func
    }))
}

export { EllipsisMenuComponent }