import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { PlaceholderGenericSpinnerComponent } from '../../Integrated'

import './index.css'

const ModalComponent = ({ identifier, children, events={}, config={}, callbacks={} }) => {
    const {_state: _toggles} = UI.useSimpleState({
        syncing: false
    })

    useEffect(() => {
        _toggles.set('syncing', config.isSyncing)
    }, [config.isSyncing])

    useEffect(() => {
        document.body.classList.add('ui-global--noScroll')

        return () => {
            document.body.classList.remove('ui-global--noScroll')
        }
    })

    return (
        ReactDOM.createPortal(
            <UI.Layout id={identifier} name="modal" type="container" config={{ hasClickBoundary: true, classNames: config.classNames }}>
                <UI.Layout name="modalDisplay" element="div" config={{ hasClickBoundary: true, classNames: `ui-global--scrollbar ${config.fullScreen ? 'fullScreen' : ''}` }}>
                    {!config.hideCloseButton && (
                        <div className="ui--modalClose" onClick={events.handleClose}>
                            <span className="bi bi-x"></span>
                        </div>
                    )}
                    
                    {_toggles.get('syncing') && (
                        <div className="ui--modalSyncingIndicator">
                            <PlaceholderGenericSpinnerComponent type="inline" config={{ size: 'tiny' }} />
                        </div>
                    )}

                    <UI.Layout name="modalDisplayContents" config={{ hasClickBoundary: true, classNames: `${config.noStyle ? 'ui-action--noStyle' : ''} ${config.fullScreen ? 'fullScreen' : ''}`, classNamesInner: `${config.classNamesInner} ${config.fullScreen ? 'fullScreen' : ''}` }} callbacks={{ onMount: ({ ref }) => callbacks.onMount && callbacks.onMount({ ref }) }}>
                        {children}
                    </UI.Layout>
                </UI.Layout>
            </UI.Layout>
        , document.getElementById('ui-portals'))
    )
}

ModalComponent.propTypes = {
    identifier: PropTypes.string,
    
    children: PropTypes.any,

    events: PropTypes.shape({
        handleClose: PropTypes.func.isRequired
    }),

    callbacks: PropTypes.shape({
        onMount: PropTypes.func
    }),

    config: PropTypes.shape({
        hideCloseButton: PropTypes.bool,
        isSyncing: PropTypes.bool,
        noStyle: PropTypes.bool
    })
}

export { ModalComponent }
