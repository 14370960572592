import {useState} from 'react'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import UtilsHelper from '../../helpers/utils'

import useNotices from '../../hooks/useNotices'

const AppointmentSlotsPage = () => {
    const [NoticeComponent, notices] = useNotices('Scheduling/AppointmentSlots/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [showTags, setShowTags] = useState(false)
    const baseUrl = '/appointment-slots'
    const baseApiUrl = '/api/v2/admin/appointment-slots'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    const handleListAfterColumnTitleClick = () => {

    }

    const filteredList = {
        ...cachedList,
        data: cachedList.data.map((row) => {
            row.ui_name_route = `${baseUrl}/${row.id}/edit`
            row.ui_name = `${row.name} ${row.display_name ? `(${row.display_name})` : ''}`
            row.ui_start_time = UtilsHelper.toTimeString(row.starts_at)
            row.ui_end_time = UtilsHelper.toTimeString(row.ends_at)
            row.ui_fee = UtilsHelper.formatDollar(row.fee)

            return row
        })
    }

    return (
        <div className="appointment-slots-page-container page-container">
            <PageHeaderComponent title="Appointment Slots" actions={[{ title: 'New Slot', route: '/appointment-slots/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl} displayNameOnSeparateLine={true} afterColumnTitleClick={handleListAfterColumnTitleClick}
                columns={[
                    {
                        key: 'ui_name',
                        title: 'Name',
                        sortable: true,
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'capacity',
                        title: 'Capacity',
                        sortable: true
                    },
                    {
                        key: 'ui_start_time',
                        title: 'Start Time',
                        sortable: true
                    },
                    {
                        key: 'ui_end_time',
                        title: 'End Time',
                        sortable: true
                    },
                    {
                        key: 'ui_fee',
                        title: 'Fee',
                        sortable: true
                    }
                ]} dropdownOptions={[
                    {
                        title: 'Preview',
                        type: 'url',
                        url: 'ui_url'
                    }
                ]} />
        </div>
    )
}

export default AppointmentSlotsPage
