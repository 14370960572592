import { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Decorators } from '../../helpers/Decorators'
import { NAMESPACE } from '../../config'

const Cell = ({ name, children, identifier, config = {} }) => {
    const attributes = Decorators.Object({
        className: Decorators.String([
            [`${NAMESPACE}-cell`, true],
            [`${NAMESPACE}-cell--${name}`, name],
            config.classNames,
        ])
    })

    return (
        <div key={identifier} {...attributes}>
            {children}
        </div>
    )
}

Cell.propTypes = {
    name: PropTypes.string,

    config: PropTypes.shape({
        classNames: PropTypes.string,
    })
}

export {
    Cell
}