import { useEffect, useState } from 'react';
import { List, PageHeader, Search, Tags } from '../../components';
import './index.css';
import { useNotices } from '../../hooks';
import { Providers } from '../../sys/constants/LabEnhancements';
import lodash from 'lodash';

const baseUrl = '/lab-enhancements';
const baseApiUrl = '/api/v2/admin/lab-enhancements';

const LabEnhancements = () => {
  const [NoticeComponent, notices] = useNotices(
    'Administration/LabEnhancements/Index'
  );

  const [page, setPage] = useState(1);
  const [cachedList, setCachedList] = useState({ data: [] });
  const [showTags, setShowTags] = useState(false);
  const [tags, setTags] = useState({ all: [], active: [] });
  const [filteredList, setFilteredList] = useState({ data: [] });

  const handleSearchAfterFetch = (results) => {
    setCachedList(results);
  };

  useEffect(() => {
    let providersByValue = lodash
      .chain(Providers)
      .keyBy('value')
      .mapValues('title')
      .value();

    let filteredList = {
      ...cachedList,
      data: cachedList.data.map((item) => {
        item.ui_name_route = `${baseUrl}/${item.id}/edit`;
        item.ui_type = lodash.get(providersByValue, item.type);
        return item;
      })
    };

    setFilteredList(filteredList);
  }, [cachedList]);

  return (
    <div className="lab-enhancements-container">
      <PageHeader
        title="Lab Enhancements"
        actions={[
          { title: 'New Lab Enhancement', route: '/lab-enhancements/new' }
        ]}
      />
      <Search
        apiUrl={baseApiUrl}
        page={page}
        setPage={setPage}
        afterFetch={handleSearchAfterFetch}
        showTags={showTags}
        setShowTags={setShowTags}
        tags={tags}
      />
      <Tags
        apiUrl={`${baseApiUrl}/tags`}
        showTags={showTags}
        onSelect={(activeTags) => setTags(activeTags)}
      />
      <NoticeComponent location="List" notices={notices} />
      {filteredList && (
        <List
          list={filteredList}
          setPage={setPage}
          baseUrl={baseUrl}
          baseApiUrl={baseApiUrl}
          columns={[
            {
              title: 'Name',
              key: 'name',
              type: 'url_route',
              route: 'ui_name_route',
              hideTitleOnMobile: true
            },
            {
              title: 'Provider',
              key: 'ui_type'
            },
            {
              title: 'Value',
              key: 'value'
            }
          ]}
        />
      )}
    </div>
  );
};

export default LabEnhancements;
