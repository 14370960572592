import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import './index.css'
import { useNavigate, useLocation } from 'react-router-dom'

const HelpNewPage = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Help/Index')

    const [data, setData] = useState({ name: `${ac.user.first_name} ${ac.user.last_name}` })
    const [errors, setErrors] = useState()
    const [isFetching, setIsFetching] = useState()
    const [dataMain, setDataMain] = useState()
    const [disabledButton, setDisableButton] = useState(false)
    const [ticketSubmitted, setTicketSubmitted] = useState(false)
    
    const navigate = useNavigate()
    const location = useLocation()

    const handleCancel = () => { changeState() }

    const changeState = (state) => {
      if (state && !location.state)
        navigate('/help', { state: { state } })
      else navigate('/help')
    }

    const handleSubmit = () => {
        ac.showSpinner(true)
        setDisableButton(true)
        setIsFetching(true)

        FetchHelper({
            url: `/api/v2/admin/tickets`,
            method: 'POST',
            body: {
              ticket: dataMain
            }
        }, (res) => {
            ac.showSpinner(false)
            setTicketSubmitted(true)
            setDisableButton(false)
            changeState()
            setIsFetching(false)
        }, (res) => {
            ac.showSpinner(false)
            setDisableButton(false)
            setIsFetching(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    return (
        <div className="help-page-container page-container">
            <PageHeaderComponent title={`Help`} />

            <NoticeComponent location="Main" notices={notices} />

            <div className="help-container">

              {ticketSubmitted && (<div className="row success">
                Ticket placed successfully!
              </div>) || null}

              {!(location.state?.state) && (<>
                <div className='instructions-container'>
                  <div className='row'>
                    <img className='help-image' src='https://fastpass.qwweb.com/assets/help-14e70cb4fa2b339bdd0bc4bb0c594e903579c89979b5e2baca291f9b02571f8c.jpg' 
                      onClick={() => {
                        changeState('newTicket')
                        setTicketSubmitted(false)
                      }}/>
                  </div>

                  <div className="row text-center">
                    <button onClick={() => {
                      changeState('newTicket')
                      setTicketSubmitted(false)
                    }}>New Ticket</button>
                  </div>

                  <div className="row info">
                    <p>For assistance with any of our features or if you have a suggestion that you want to see in a future release, please create a new ticket by clicking above and letting us know!</p>
                    <p className="signature">Thanks,<br/>The Fast Pass / Pre Pay Team</p>
                  </div>
                </div>
              </>) || null}

              {(location.state?.state === 'newTicket') && (<div className={`row ${isFetching ? 'disabled' : ''}`}>
                <FormComponent formData={data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                        type: 'select',
                        name: 'tracker',
                        label: 'What is your issue?',
                        options: [
                          {
                            title: '--',
                            value: null
                          },
                          {
                            title: 'I found a bug or something is not working properly!',
                            value: 1
                          },
                          {
                            title: 'I am having trouble doing something!',
                            value: 3
                          },
                          {
                            title: 'I have an idea for a great new feature that would make everyone\'s life easier!',
                            value: 2
                          },
                          {
                            title: 'I have completely separate issue that does not fall into any other category!',
                            value: 3
                          }
                        ]
                    },{
                        type: 'input-text',
                        name: 'subject',
                        label: 'Subject',
                    },{
                        type: 'textarea',
                        name: 'description',
                        label: 'Detailed Description',
                    },{
                        type: 'input-text',
                        name: 'name',
                        label: 'Your Name',
                    },{
                        type: 'input-text',
                        name: 'email',
                        label: 'What email can we use to contact you?',
                        placeholder: 'example@email.com'
                    }]} />

                <div className="form-actions">
                    {dataMain && <button onClick={handleSubmit} disabled={disabledButton}>Place Ticket</button> || null}
                    {dataMain && <button className='secondary ml-2' onClick={handleCancel} disabled={disabledButton}>Cancel</button> || null}
                </div>
              </div>) || null}

            </div>
        </div>
    )
}

export default HelpNewPage
