import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import lodash from 'lodash'
import { useParams } from 'react-router-dom'

const LocationGroupsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/LocationGroups/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [availableMarkets, setAvailableMarkets] = useState([])
    const [colorScheme, setColorScheme] = useState([])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/location-groups/${params.id}`
        }, (res) => {
            setData(res.body)
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: `/api/v2/admin/color-schemes`
        }, (res) => {
            ac.showSpinner(false)

            const colors = [{ id: null, title: 'System Default', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: item.id,
                    title: item.name,
                    value: item.id
                }
                colors.push(option);
            })
            setColorScheme(colors)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: '/api/v2/admin/markets',
        }, (res) => {
            let marketsList = []

            res.body.data.forEach(elem => {
                const market = {
                    id: elem.id,
                    group: elem.market_group ? elem.market_group.name : 'UNGROUPED',
                    name: elem.external_id ? elem.external_id + ' - ' + elem.name : elem.name
                }

                marketsList.push(market)
            });

            setAvailableMarkets(marketsList)

            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = { ...data }
        body.market_ids = lodash.map(data.market_ids, 'id')

        FetchHelper({
            url: `/api/v2/admin/location-groups/${params.id}`,
            method: 'PUT',
            body: {
                location_group: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/location-groups')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="Edit Location Group" />
            <NoticeComponent location="Main" notices={notices} />
            {data &&
                <>
                    <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                        {
                            type: 'input-text',
                            name: 'name',
                            label: 'Name',
                        },
                        {
                            type: 'input-text',
                            name: 'display_name',
                            label: 'Display Name'
                        }, {
                            type: 'select',
                            name: 'color_scheme_id',
                            label: 'Color scheme',
                            options: colorScheme
                        },
                        {
                            type: 'wysiwyg',
                            name: 'prompt',
                            label: 'Prompt'
                        },
                        {
                            type: 'two-multiselect',
                            name: 'market_ids',
                            label: 'Markets',
                            list_labels: ['Available Markets', 'Markets Selected'],
                            selectable: availableMarkets,
                            selected: lodash
                                .chain(data?.market_ids)
                                .map(item => {
                                    if (lodash.isString(item))
                                        return lodash.find(availableMarkets, { id: item })
                                    return item
                                })
                                .compact()
                                .value()
                        }
                    ]} />
                    
                    <ControlBar baseApiUrl="/location-groups" redirectUrl="/location-groups" cancelUrl="/location-groups" data={data} handleSubmit={handleSubmit} />
                </>
            }
        </div>
    )
}
export default LocationGroupsEdit
