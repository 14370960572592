import { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';

const PickerTime = ({ initialValue, onChange, readOnly=false }) => {
    return (
        <div className="picker-date-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    value={!initialValue || initialValue === '' ? null : dayjs(initialValue)}
                    disabled={readOnly}
                    slotProps={{ textField: { placeholder: 'Select Time' } }}
                    onChange={(newValue) => {
                        onChange(newValue);
                    }}
                />
            </LocalizationProvider>
        </div>
    )
}

export default PickerTime