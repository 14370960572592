import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'
import { FileTypes } from '../../sys/constants/enums'

const CampaignsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Campaigns/New')

    const [data, setData, clearData, dataDefault] = useDraft('Tools:Campaigns:Data')
    const [errors, setErrors] = useState()
    const [markets, setMarkets] = useState()
    const [templates, setTemplates] = useState()
    const [suggestionsTags, setSuggestionsTags] = useState([])

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/campaigns/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (error) => {

        })
    }, [])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = {
                ...data,
                csv_base64: data.csv?.value
            }

            if (body.type === 'EmailTemplate')
                body.template_id = body.template_id_email
            
            if (body.type === 'TextTemplate')
                body.template_id = body.template_id_sms

            delete body.template_id_email
            delete body.template_id_sms
            delete body.csv

            FetchHelper({
                url: `/api/v2/admin/campaigns`,
                method: 'POST',
                body: {
                    campaign: body
                }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className='campaigns-edit-page-container'>
            <PageHeaderComponent title="New Campaign" />
            <NoticeComponent location="Main" notices={notices} />
            <div className='message'>
                <div className='warning'>
                    <h3>This feature is in Beta</h3>
                </div>
                <div className='info'>
                    <p className='paragraph'>
                        This tool will send a SMS message to phone numbers in a CSV file marketing your pre-sale.
                        Content is set up in the campaign templates. Before sending, the application will make sure that there are
                        no duplicate phone numbers in your CSV file, so you do not send to the same person multiple times.
                        The phone_number <span className='must'>must</span> be an <span>exact match</span>. Non-digits will be stripped.
                    </p>
                    <h3>CSV is expected to meet the following requirements:</h3>
                    <ol className='paragraph-section'>
                        <li>Must have header</li>
                        <li>Header must be lowercase</li>
                        <li>Header must contain field named: <span className='phone'>phone_number</span></li>
                        <li>Substitution fields must match template placeholders that were setup in the corresponding template (e.g. custom_myfieldname).</li>
                        <li>Fields found in CSV take precedence over system data.</li>
                    </ol>
                </div>
            </div>
            <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                {
                    type: 'input-text',
                    name: 'name',
                    label: 'Name',
                }, {
                    type: 'tags',
                    name: 'tag_names',
                    label: 'Tags',
                    suggestions: suggestionsTags
                }, {
                    type: 'textarea',
                    name: 'description',
                    label: 'Description'
                }, {
                    type: 'select-paginated',
                    name: 'market_id',
                    label: 'Market to Promote',
                    apiUrl: '/api/v2/admin/markets',
                }, {
                    type: 'select',
                    name: 'type',
                    label: 'Type',
                    options: [
                        { id: 1, title: '', value: '' },
                        { id: 2, title: 'Text', value: 'TextTemplate' },
                        { id: 3, title: 'Email', value: 'EmailTemplate' }
                    ]
                }, {
                    type: 'select-paginated',
                    name: 'template_id_sms',
                    label: 'Template',
                    apiUrl: '/api/v2/admin/templates',
                    condition: (data) => data?.type === 'TextTemplate',
                    queries: [{ key: 'type', value: 'TextTemplate' }]
                }, {
                    type: 'select-paginated',
                    name: 'template_id_email',
                    label: 'Template',
                    apiUrl: '/api/v2/admin/templates',
                    condition: (data) => data.type === 'EmailTemplate',
                    queries: [{ key: 'type', value: 'EmailTemplate' }]
                }, {
                    type: 'select',
                    name: 'data_source',
                    label: 'Data source',
                    options: [
                        { id: 1, title: 'CSV', value: 'csv' },
                        { id: 2, title: 'Proof', value: 'proof' }
                    ],
                    defaultValue: 0
                }, {
                    type: 'input-file',
                    name: 'csv',
                    label: 'CSV',
                    fileType: '',
                    acceptedFileTypes: FileTypes.CSV
                }, {
                    type: 'datetime',
                    name: 'send_at',
                    label: 'Send at'
                }
            ]} />

            <ControlBar handleSubmit={handleSubmit} cancelUrl="/campaigns" handleAfterSubmit={() => RouteHelper.redirect('/campaigns')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault]
            ]} />
        </div>
    )
}
export default CampaignsNew