import { useState, useEffect, useRef } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'

import './edit.css'
import { getFormFields } from './providers/form-fields'

const CustomReportsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Reports/CustomReports/New')

    const ref = useRef()
    const [data, setData, clearData, dataDefault] = useDraft('Reports:CustomReports:Data', {})
    const [columns, setColumns, clearDataColumns, dataColumnsDefault] = useDraft('Reports:CustomReports:DataColumns', [])
    const [errors, setErrors] = useState()
    const [addColumn, setAddColumns] = useState(false)
    const [options, setOptions] = useState([])
    const [dragId, setDragId] = useState()

    const [formFields, _] = useState(getFormFields())

    useEffect(() => {
        ac.showSpinner(true)
        FetchHelper({
            url: `/api/v2/admin/custom-reports/columns`
        }, (res) => {
            let temp = []
            const col_options = res.body
            for (var key in col_options) {
                temp.push(key)
                const col_options_values = col_options[key]
                for (var key_val in col_options_values) {
                    temp.push({ title: key_val, value: col_options_values[key_val] })
                }
            }
            setOptions(temp)
            ac.showSpinner(false)
        }, (res) => {
        })
    }, [])

    useEffect(() => {
        if (addColumn) {
            const position = columns.length
            setColumns([...columns, { position: position, _destroy: false }])
            setAddColumns(false)
        }
    }, [addColumn])

    useEffect(() => {
        function handleClickOutside(event) {

            if (ref && ref.current && !ref.current.contains(event.target)) {
                const columnsDiv = document.querySelectorAll('.column-wrapper')
                for (let i = 0; i < columnsDiv.length; i++) {
                    if (i !== dragId) {
                        columnsDiv[i].className = "column-wrapper"
                    }
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            const body = {
                name: data.report_name,
                custom_report_columns_attributes: columns
            }

            FetchHelper({
                url: `/api/v2/admin/custom-reports`,
                method: 'POST',
                body: {
                    custom_report: body
                }
            }, (res) => {
                ac.showSpinner(false)
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    let errors = UtilsHelper.preProcess422Errors(formFields, res.body)
                    setErrors(errors)
                    UtilsHelper.scrollTop()
                }
                reject()
            })
        })
    }

    const removeColumns = (index) => {
        setColumns(columns.filter((obj, i) => (i === index ? false : true)))
    }

    const handleChange = (e, id) => {
        const { name, value } = e.target

        let temp = [...columns]
        temp[id] = { ...columns[id], [name]: value }
        setColumns(temp)
    }

    const renderOptions = (list) => {
        let temp = []

        list.map((item, i) => {
            if (!item.title) {
                temp.push(<option key={i} disabled className="option-group">{item}</option>)
            } else {
                temp.push(<option key={i} value={item.value} className="option">{item.title}</option>)
            }
        })

        return temp
    }

    const handleDrop = (e) => {
        e.dataTransfer.dropEffect = "move"
        e.dataTransfer.effectAllowed = "move"
        const target = parseInt(e.currentTarget.id)
        const drag = columns.find((col) => col.position === dragId)
        const drop = columns.find((col) => col.position === target)
        const dragOrder = drag.position
        const dropOrder = drop.position
        const columnsDiv = document.querySelectorAll('.column-wrapper')

        const newColumnsState = columns.map((col) => {
            var tempCol = col
            if (col.position === dragId) {
                tempCol = { ...tempCol, position: dropOrder }
            }
            if (col.position === target) {
                tempCol = { ...tempCol, position: dragOrder }
            }

            return tempCol
        });

        newColumnsState.sort((a, b) => a.position - b.position)
        setColumns(newColumnsState)

        for (let i = 0; i < columnsDiv.length; i++) {
            if (i !== dragId) {
                columnsDiv[i].className = "column-wrapper"
            }
        }
        e.currentTarget.className = "column-wrapper dragged"
    }

    const onDragOver = (e) => {
        e.preventDefault()
        const target = parseInt(e.currentTarget.id)
        if (dragId !== target) {
            e.currentTarget.className = "column-wrapper dragging"
        }
    }

    const onDragStart = (index) => {
        const columnsDiv = document.querySelectorAll('.column-wrapper')
        for (let i = 0; i < columnsDiv.length; i++) {
            columnsDiv[i].className = "column-wrapper"
        }
        setDragId(index)
    }

    const renderColumn = (i) => {
        return (
            <div ref={ref} id={i} key={i} className="column-wrapper" draggable={true} onDragOver={(e) => onDragOver(e, i)} onDragStart={(e) => onDragStart(i)} onDrop={handleDrop}>
                <button className="btn-move"><i className="bi bi-arrow-down-up"></i></button>
                <div key={i} className="column-inputs">
                    <input name="name" type="text" value={columns[i].name || ''} onChange={(e) => handleChange(e, i)} />
                    <select name="data_source" value={columns[i].data_source || ''} onChange={(e) => handleChange(e, i)} >
                        <option value=''>Select</option>
                        {renderOptions(options)}
                    </select>
                </div>
                <button className="btn-trash" onClick={() => removeColumns(i)}><i className="bi bi-trash3-fill"></i></button>
            </div>
        )
    }

    return (
        <div className="custom-reports-container">
            <PageHeaderComponent title="New Custom Report" />
            <NoticeComponent location="Main" notices={notices} />
            <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={formFields} />
            <div className="form-container default form-field-wrapper columns-form">
                <div className="form-label">
                    <button className="secondary" onClick={() => setAddColumns(true)}>Add Column</button>
                </div>
                <div className="form-field field-columns-wrapper">
                    {columns.map((col, i) => (
                        renderColumn(i)
                    ))}
                </div>
            </div>
            <ControlBar handleSubmit={handleSubmit} cancelUrl="/custom-reports" handleAfterSubmit={() => RouteHelper.redirect('/custom-reports')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault],
                [columns, clearDataColumns, dataColumnsDefault],
            ]} />
        </div>
    )
}

export default CustomReportsNew