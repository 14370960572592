import { useState, useEffect } from 'react';

import FetchHelper from '../helpers/fetch';
import NoticeComponent from '../components/Notice';

export default function useNotices(route) {
    const [data, setData] = useState({
        data: [],
        route
    })

    let fetched = false

    useEffect(() => {
        if (!fetched) {
            fetched = true

            FetchHelper({
                url: `/api/v2/admin/notices?r=${route}`,
            }, (res) => {
                if (res.body?.data)
                    setData({
                        data: res.body.data,
                        route
                    })
            }, (res) => {})
        }
    }, [])

    return [NoticeComponent, data]
}