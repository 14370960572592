import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { useAppContext, useDynamicState } from '../../hooks'

import './index.css'

const DropdownMenu = ({ renderTitle, renderCell, options }) => {
    const [ac] = useAppContext()

    const { _get, _set, _mounted } = useDynamicState({
        data: {
            entity: {
                options: options,
                target: null
            }
        },
        toggles: {
            entity: {
                dropdown: false
            }
        }
    })

    useEffect(() => {
        if (_get('toggles.entity').dropdown) {
            ac.setDisplayOverlay({
                target: _get('data.entity').target,

                content: () => {
                    return (
                        <div className="dropdown-menu-content-overlay">
                            <ul>
                                {_get('data.entity').options.map((item, index) => {
                                    return (
                                        <li onClick={item.onClick} key={`dropdown-menu-item-${item.data?.id}-${index}`}>
                                            {renderCell(item)}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                },

                onClose: () => {
                    _set('toggles.entity', { dropdown: false })
                }
            })
        }
    }, [_get('toggles.entity').dropdown])

    if (!_mounted)
        return

    return (
        <div className="dropdown-menu-container" onClick={(e) => e.stopPropagation}>
            <div className="dropdown-menu-title" onClick={(e) => {
                _set('data.entity', { target: e.target }, () => {
                    _set('toggles.entity', { dropdown: true })
                })
            }}>
                {renderTitle()}
            </div>
        </div>
    )
}

DropdownMenu.propTypes = {
    renderTitle: PropTypes.func.isRequired,
    renderCell: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            data: PropTypes.object,
            callback: PropTypes.func
        })
    )
}

export default DropdownMenu