import lodash from 'lodash';
import { Utils } from '../helpers';
import useFetch from './useFetch';
import { useRef } from 'react';

const useFullList = () => {
  const { FetchHelper } = useFetch();
  const cache = useRef({});

  return {
    getFullListData: async (endpoint, useCache) => {
      if (useCache && cache.current[endpoint])
        return cache.current[endpoint];

      let page = 1;
      let totalPages = 1;
      let fullListData = undefined;

      const fetchData = async () => {
        let url = `${endpoint}?page=${page}`;
        let [res, err] = await Utils.executePromise(FetchHelper({ url }));
        if (err) return;

        page = (res?.body?.current_page || 0) + 1;
        totalPages = res?.body?.total_pages || 1;
        if (!fullListData) fullListData = res?.body;
        else {
          fullListData.data = lodash.concat(fullListData.data, res?.body?.data);
          fullListData.current_page = res?.body?.current_page;
          fullListData.total_pages = res?.body?.total_pages;
          fullListData.total = res?.body?.total;
        }

        if (totalPages >= page) await fetchData(page);
      };

      await fetchData();

      cache.current[endpoint] = fullListData;
      return fullListData;
    }
  };
};

export default useFullList;
