import React, { useEffect, useState } from 'react';

import { TabsWithUnderline } from '../../Complex';
import { ImagePreview } from '../../Complex';
import { FileForImage } from '../../Complex';
import { InputComponent, RadioComponent } from '../../Primitive';

import { useSimpleState } from '../../../UI/hooks';
import lodash from 'lodash';

import './index.css';

const AssetUploadForm = ({ item, events, errors }) => {
  const handleFileChange = (value) => {
    if (events.handleFileChange)
      events.handleFileChange(value)
  }

  const handleFileDelete = () => {
    if (events.handleFileDelete)
      events.handleFileDelete(item)
  }

  const handleExternalChange = (value) => {
    if (events.handleExternalChange)
      events.handleExternalChange(value)
  }

  const handleRadioSelect = (value) => {
    if (events.handleRadioSelect)
      events.handleRadioSelect(value)
  }

  const getImageUrl = () => {
    return item.assetSource === '1' ? item.externalUrl : item.attachedImage?.data
  }

  return (
    <div className="ui-c-AssetUploadForm">
      { !lodash.isEmpty(errors) &&  
        <div className="ui-error-block--container mb-4">
          <div>Please address following errors:</div>
          <div>{ lodash.join(errors, ", ") }</div>
        </div> }
      <ImagePreview key={getImageUrl()} url={getImageUrl()} config={{ note: item.note }} />
      <RadioComponent value={item.assetSource} options={[
        {
          value: '0',
          title: <FileForImage file={item.attachedImage} events={{ handleFileChange, handleFileDelete }} />,
          disabled: !item.attachedImage?.data
        },
        {
          value: '1',
          title: <InputComponent name="externalUrl" type="text" value={item.externalUrl || ''} events={{ handleChange: handleExternalChange }} />,
          disabled: !item.externalUrl
        },
      ]} events={{
        handleChange: handleRadioSelect
      }} />
    </div>
  )
}

const AssetUploader = ({ list, events, errors, setErrors }) => {
  const { _state } = useSimpleState({
    activeTab: 0,
    headers: list
  })

  const [errorsByTabTitle, setErrorsByTabTitle] = useState({})
  
  useEffect(() => {
    let errs = lodash
      .chain(_state.get('headers'))
      .keyBy('type')
      .mapValues(item => {
        let tabKey = item.id
        let tabErrors = lodash.get(errors, tabKey)
        if (!tabErrors) return null
        return { key: tabKey, errors: tabErrors }
      })
      .omitBy(lodash.isNil)
      .value()

    setErrorsByTabTitle(errs)
  }, [_state.get('headers'), errors])

  const _TABS = list.map(item => item.type)

  const handleChange = (value) => {
    // delete related tab errors
    if(setErrors) {
      let newErrors = { ...errors }
      let tabKey = _state.get('headers')[_state.get('activeTab')]?.id
      delete newErrors[tabKey]
      setErrors(newErrors)
    }

    if (events.handleChange)
      events.handleChange(value)
  }

  const handleFileChange = (value) => {
    let newHeaders = [..._state.get('headers')]

    newHeaders[_state.get('activeTab')].attachedImage = {
      data: value.data,
      filename: value.filename
    }

    newHeaders[_state.get('activeTab')].assetSource = '0'

    handleChange(newHeaders)

    _state.set('headers', newHeaders)
  }

  const handleFileDelete = (value) => {
    let newHeaders = [..._state.get('headers')]

    newHeaders = newHeaders.map((item, index) => {
      if (item === value) {
        item.attachedImage = {
          data: null,
          filename: null
        }
        item.assetSource = '1'
      }

      return item
    })

    handleChange(newHeaders)

    _state.set('headers', newHeaders)
  }

  const handleExternalChange = (value) => {
    let newHeaders = [..._state.get('headers')]

    newHeaders[_state.get('activeTab')].externalUrl = value
    newHeaders[_state.get('activeTab')].assetSource = '1'

    handleChange(newHeaders)

    _state.set('headers', newHeaders)
  }

  const handleRadioSelect = (value) => {
    let newHeaders = [..._state.get('headers')]

    newHeaders[_state.get('activeTab')].assetSource = value

    handleChange(newHeaders)

    _state.set('headers', newHeaders)
  }

  return (
    <div className="ui-c-AssetUploader">
      <TabsWithUnderline activeTab={_state.get('activeTab')} tabs={_TABS} errorsByTabTitle={errorsByTabTitle} events={{ handleSelect: (tab) => _state.set('activeTab', tab) }} />
      <AssetUploadForm key={_state.get('activeTab')} item={_state.get('headers')[_state.get('activeTab')]} events={{ handleFileChange, handleFileDelete, handleExternalChange, handleRadioSelect }} 
        errors={errorsByTabTitle[_TABS[_state.get('activeTab')]]?.errors} />
    </div>
  );
};

export default AssetUploader;
