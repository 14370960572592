import DropdownTextCollection from "../DropdownTextCollection";
import "./index.css";

const EmailTags = ({ onSelect = () => {} }) => {
  let emailSubstitutionOptions = [
    {
      type: "group",
      title: "Customer Service",
      list: [
        { title: "Email", value: "%{customer_service_email}" },
        { title: "Phone Number", value: "%{customer_service_phone_number}" },
      ],
    },
    {
      type: "group",
      title: "Studio",
      list: [
        { title: "Logo", value: "%{studio_logo}" }
      ],
    },
    {
      type: "group",
      title: "Market",
      list: [
        { value: "%{market_name}", title: "Name" },
        { value: "%{market_url}", title: "Short URL" },
        { value: "%{market_street_address}", title: "Street Address" },
        { value: "%{market_city}", title: "City" },
        { value: "%{market_state_province}", title: "State/Province" },
        { value: "%{market_postal_code}", title: "Postal Code" },
        { value: "%{market_logo}", title: "Logo" },
        { value: "%{market_phone_number}", title: "Ph #" },
        { value: "%{market_website}", title: "Website" },
        {
          value: "%{market_special_instructions}",
          title: "Special Instructions",
        },
        { value: "%{market_important_dates}", title: "Important Dates" },
        { value: "%{market_coupons}", title: "Emailable Promotional Coupons" },
      ],
    },
    {
      type: "group",
      title: "Scheduling",
      list: [
        { value: "%{scheduling_policy}", title: "Policy" },
        { value: "%{scheduling_extra_text}", title: "Extra Text" },
      ],
    },
    {
      type: "group",
      title: "Customer",
      list: [
        { value: "%{customer_billing_address_html}", title: "Billing Address (HTML)" },
        { value: "%{customer_billing_address_txt}", title: "Billing Address (Text)" },
        { value: "%{customer_billing_address_single}", title: "Billing Address (One Line)" },
        { value: "%{customer_billing_address_address1}", title: "Billing Address: Address 1" },
        { value: "%{customer_billing_address_address2}", title: "Billing Address: Address 2" },
        { value: "%{customer_billing_address_city}", title: "Billing Address: City" },
        { value: "%{customer_billing_address_state_province}", title: "Billing Address: State/Province" },
        { value: "%{customer_billing_address_postal_code}", title: "Billing Address: Postal Code" },
        { value: "%{customer_billing_address_country}", title: "Billing Address: Country" },
        { value: "%{customer_email}", title: "Email" },
        { value: "%{customer_first_name}", title: "First Name" },
        { value: "%{customer_last_name}", title: "Last Name" },
        { value: "%{customer_phone_number}", title: "Phone Number" },
        { value: "%{customer_shipping_address_html}", title: "Shipping Address (HTML)" },
        { value: "%{customer_shipping_address_txt}", title: "Shipping Address (Text)" },
        { value: "%{customer_shipping_address_single}", title: "Shipping Address (One Line)" },
        { value: "%{customer_shipping_address_address1}", title: "Shipping Address: Address 1" },
        { value: "%{customer_shipping_address_address2}", title: "Shipping Address: Address 2" },
        { value: "%{customer_shipping_address_city}", title: "Shipping Address: City" },
        { value: "%{customer_shipping_address_state_province}", title: "Shipping Address: State/Province" },
        { value: "%{customer_shipping_address_postal_code}", title: "Shipping Address: Postal Code" },
        { value: "%{customer_shipping_address_country}", title: "Shipping Address: Country" }
      ],
    },
    {
      type: "group",
      title: "Order",
      list: [
        { value: "%{purchased_at}", title: "Purchase Date/Time" },
        { value: "%{confirmation_number}", title: "Confirmation Number" },
        { value: "%{confirmation_url}", title: "Confirmation URL" },
        { value: "%{packages_ordered}", title: "Packages Ordered" },
        { value: "%{addons_ordered}", title: "Addons Ordered" },
        { value: "%{poses_selected}", title: "Poses Selected" },
        { value: "%{sheets_selected}", title: "Sheets Selected" },
        { value: "%{backgrounds_selected}", title: "Backgrounds Selected" },
        { value: "%{donation_charity_name}", title: "Charity Name" },
        { value: "%{donation_amount}", title: "Donation Amount" },
        { value: "%{custom_fields}", title: "Custom Fields" },
        { value: "%{order_total}", title: "Total (Formatted)" },
        { value: "%{order_register_total}", title: "Register Total (Formatted)" },
        { value: "%{guided_mode_url}", title: "Walkup URL (Guided Mode)" },

        // if current_studio.proofing_service_enabled?
        { value: "%{ddl_code}", title: "Digital Delivery Code" },
        { value: "%{ddl_fulfillment_url}", title: "Digital Delivery Fulfillment URL" }
      ],
    },
    {
      type: "group",
      title: "Barcode",
      list: [
        { value: "%{barcode}", title: "Barcode" },
        { value: "%{barcode_text}", title: "Barcode Text" },
        { value: "%{email_barcode}", title: "Email Barcode" },

        // if current_studio.triprism_service_enabled?
        { value: "%{qr_barcode}", title: "Data QR Code (if configured)" },
      ],
    },
    {
      type: "group",
      title: "Reservation",
      list: [
        { value: "%{reservation_date}", title: "Date" },
        { value: "%{reservation_slot_name}", title: "Slot Name" },
        { value: "%{reservation_eta}", title: "ETA (Hours)" },
        { value: "%{reservation_eta_days}", title: "ETA (Days)" },
        { value: "%{reschedule_url}", title: "Reschedule URL" },
      ],
    },

    // if current_studio == stb
    {
      type: "group",
      title: "Zoom",
      list: [
        { value: "%{zoom_meeting_id}", title: "Meeting ID" },
        { value: "%{zoom_meeting_uuid}", title: "Meeting UUID" },
        { value: "%{zoom_meeting_topic}", title: "Topic" },
        { value: "%{zoom_meeting_host_id}", title: "Host ID" },
        { value: "%{zoom_meeting_host_email}", title: "Host Email" },
        { value: "%{zoom_meeting_start_url}", title: "Start URL" },
        { value: "%{zoom_meeting_join_url}", title: "Join URL" },
        { value: "%{zoom_meeting_password}", title: "Password" },
        { value: "%{zoom_meeting_duration}", title: "Duration" }
      ]
    },

    {
      type: "group",
      title: "Marketing Instructions",
      list: [
        {
          value: "%{marketing_instructions}",
          title: "Instructions from this Plan",
        },
      ],
    },

    // if current_studio.package_from_santa_service_enabled?
    {
      type: "group",
      title: "Package From Santa",
      list: [
        {
          value: "%{package_from_santa}",
          title: "Data Block",
        },
      ]
    },

    // if current_studio.proofing_service_enabled?
    {
      type: "group",
      title: "Proofing (if configured)",
      list: [
        { value: "%{proof_direct_url}", title: "Direct URL" },
        { value: "%{proof_primary_image_url}", title: "Primary Image URL" },
        { value: "%{proof_first_name}", title: "Subject First Name" },
        { value: "%{proof_last_name}", title: "Subject Last Name" },
        { value: "%{proof_full_name}", title: "Subject Full Name" },
        { value: "%{proof_grade}", title: "Subject Grade" },
        { value: "%{proof_group_number}", title: "Subject Group Number" },
        { value: "%{proof_teacher}", title: "Subject Teacher" },
        { value: "%{proof_default_background}", title: "Default BG (Name Only)" },
        { value: "%{proof_code}", title: "Code" }
      ]
    },

    {
      type: "group",
      title: "Portable North Pole (if configured)",
      list: [
        { value: "%{pnp_promo_code}", title: "Promo Code" },
        { value: "%{pnp_promo_code_url}", title: "Promo Code URL" },
        { value: "%{pnp_marketing_image_url}", title: "Marketing Image URL" },
      ],
    },
  ];

  return (
    <div className="email-tags-container">
        <DropdownTextCollection
            options={emailSubstitutionOptions} onSelect={onSelect} />
    </div>
  )
};

export default EmailTags;
