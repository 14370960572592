import { useEffect, useRef } from 'react'

import { UI } from '../../../UI'

import PropTypes from 'prop-types'

import './index.css'

const PlaceholderGenericSpinnerComponent = ({ show=false,  type='default', config={} }) => {
    const {_state} = UI.useSimpleState({
        showSpinner: false,
    })

    let _mountFadeInTimer = useRef(null)

    useEffect(() => {
        _mountFadeInTimer.current = window.setTimeout(() => {
            _state.set('showSpinner', show)
        }, 500)

        return () => {
            if (_mountFadeInTimer && _mountFadeInTimer.current)
                window.clearTimeout(_mountFadeInTimer.current)
        }
    }, [show])

    return (
        <div className={`ui-container--placeholderGenericSpinner ${_state.get('showSpinner') ? 'ui-action--imageReady' : ''} ${config.size === 'tiny' ? 'ui-container--placeholderGenericSpinner--size--tiny' : ''} ${type === 'inline' ? 'ui-container--placeholderGenericSpinner--type--inline' : ''} ${config.classNames || ''}`}>
            <div></div>
        </div>
    )
}

PlaceholderGenericSpinnerComponent.propTypes = {
    show: PropTypes.bool,

    type: PropTypes.oneOf(['default', 'inline']),

    config: PropTypes.shape({
        classNames: PropTypes.string,
        size: PropTypes.string
    })
}

export { PlaceholderGenericSpinnerComponent }