import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'
import { FileTypes } from '../../sys/constants/enums'

const BackgroundsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Backgrounds/New')

    const [errors, setErrors] = useState()
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('PSM:Backgrounds:DataMain')
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = {...dataMain}

            if (body.image_attributes) {
                body.image_attributes = {
                    data_base64: body.image_attributes.value
                }
            }

            if (body.production_image_base64)
                body.production_image_base64 = body.production_image_base64.value

            body.tag_names = body.tags
            delete body.tags

            FetchHelper({
                url: `/api/v2/admin/backgrounds`,
                method: 'POST',
                body: { background: body }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/backgrounds/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {
        })
    }, [])

    return (
        <div className="backgrounds-new-page-container page-container">
            <PageHeaderComponent title={`New Background`} />
            <NoticeComponent location="Main" notices={notices} />
            <FormComponent formData={dataMain} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                    type: 'input-text',
                    name: 'name',
                    label: 'Name'
                },{
                    type: 'tags',
                    name: 'tags',
                    label: 'Tags',
                    suggestions: suggestionsTags
                },{
                    type: 'input-text',
                    name: 'code',
                    label: 'Code',
                },{
                    type: 'input-text',
                    name: 'lab_code',
                    label: 'Lab Code',
                    label_info: 'Lab Code takes priority over Code when sending data to lab for fulfillment.',
                },{
                    type: 'input-number',
                    name: 'shipping_premium',
                    label: 'Shipping premium',
                },{
                    type: 'input-file',
                    name: 'image_attributes',
                    label: 'Preview Image',
                    label_info: '(512kb max)',
                    acceptedFileTypes: FileTypes.IMAGES
                },{
                    type: 'input-file',
                    name: 'production_image_base64',
                    label: 'Production Image',
                    acceptedFileTypes: FileTypes.IMAGES
                }]} />

                <ControlBar handleSubmit={handleSubmit} cancelUrl="/backgrounds" handleAfterSubmit={() => RouteHelper.redirect('/backgrounds')} handleAfterClear={() => {
                    RouteHelper.reload()
                }} dataItems={[
                    [dataMain, clearDataMain, dataMainDefault]
                ]} />
        </div>
    )
}

export default BackgroundsNew
