import { useEffect, useRef } from 'react'
import { Fetch } from '../../helpers'
import { UI } from '../../UI'
import RouteHelper from '../../helpers/route'

const FetchOrders = ({ requirements={} }, callback) => {
    let _fetchedOrder = useRef(null)
    let _fetchedMarketingPlan = useRef(null)
    let _fetchedRemaining = useRef(null)

    const {_state} = UI.useSimpleState({})

    const _results = _state.get()

    useEffect(() => {
        if (_fetchedOrder && !_fetchedOrder.current && requirements.orderId) {
            _fetchedOrder.current = true
            fetchOrder()
        }
    }, [])

    useEffect(() => {
        if (_fetchedRemaining && !_fetchedRemaining.current && _results?.order) {
            _fetchedRemaining.current = true
            fetchMarket()
            fetchPackages()
            fetchTransactions()
            fetchLogs()
            fetchIntegrations()
        }
    }, [_results?.order])

    useEffect(() => {
        if (_fetchedMarketingPlan && !_fetchedMarketingPlan.current && _results?.market?.marketing_plan_id) {
            _fetchedMarketingPlan.current = true
            fetchMarketingPlan()
            fetchPaymentAccount()
        }
    }, [_results?.market])

    useEffect(() => {
        callback(_results)
    }, [_results])

    function fetchOrder() {
        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}`
        }, (res) => {
            _state.set('order', res.body)

            if (/418|404/.test(res.status))
                RouteHelper.redirect('/orders')
        }, (res) => {
            RouteHelper.redirect('/orders')
        })        
    }

    function fetchMarket() {
        Fetch({
            url: `/api/v2/admin/markets/${_results.order.market_id}`
        }, (res) => {
            _state.set('market', res.body)
        })
    }

    function fetchPackages() {
        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/packages`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('packages', res.body)
        }, (res) => {

        })
    }

    function fetchMarketingPlan() {
        Fetch({
            url: `/api/v2/admin/marketing-plans/${_results.market.marketing_plan_id}`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('marketingPlan', res.body)
        }, (res) => {

        })
    }

    function fetchPaymentAccount() {
        Fetch({
            url: `/api/v2/admin/payment-accounts/${_results.market.payment_gateway_id}`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('paymentAccount', res.body)
        }, (res) => {

        })
    }

    function fetchLogs() {
        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/logs`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('logs', res.body)
        }, (res) => {

        })
    }

    function fetchTransactions() {
        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/transactions`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('transactions', res.body)
        }, (res) => {

        })
    }

    function fetchIntegrations() {
        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/zoom`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('zoom', res.body)
        }, (res) => {
            
        })

        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/centrics`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('centrics', res.body)
        }, (res) => {
            
        })

        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/pfs`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('pfs', res.body)
        }, (res) => {
            
        })

        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/pnp`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('pnp', res.body)
        }, (res) => {
            
        })

        Fetch({
            url: `/api/v2/admin/orders/${requirements.orderId}/ddlab`,
            headers: {
                'x-studio-id': _results.order.studio_id
            }
        }, (res) => {
            _state.set('ddlab', res.body)
        }, (res) => {
            
        })
    }

    const _reload = (api) => {
        switch (api) {
            case 'order':
                fetchOrder()
                break;
            case 'market':
                fetchMarket()
                break;
            case 'packages':
                fetchPackages()
                break;
            case 'transactions':
                fetchTransactions()
                break;
            case 'logs':
                fetchLogs()
                break;
            case 'integrations':
                fetchIntegrations()
                break;
            case 'marketingPlan':
                fetchMarketingPlan()
                break;
            case 'paymentAccount':
                fetchPaymentAccount()
                break;
            
        }
    }

    return { _results, _reload }
}

export { FetchOrders }