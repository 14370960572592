import {useState, useEffect} from 'react'

import FetchHelper from '../../helpers/fetch'
import ControlBar from '../../components/ControlBar'
import FieldSelectorComponent from '../../components/FieldSelector'
import FormComponent from '../../components/Form'
import FieldMultiselectorComponent from '../../components/FieldMultiselector'
import PageHeaderComponent from '../../components/PageHeader'
import ProjectorComponent from '../../components/Projector'
import AssetUploader from '../../componentsV2/Integrated/AssetUploader'
import RouteHelper from '../../helpers/route'
import Tooltip from '../../components/Tooltip'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'
import { useSimpleState } from '../../UI/hooks'
import lodash from 'lodash'

import './index.css'
import { getBasicFormFields, getDescriptionFormFields, getDigitalDeliveryFormFields, getHelperFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const PackagesNew = ({ match }) => {
    const [ac] = useAppContext()

    const {_state: _imageDesktop} = useSimpleState([
        {
            type: 'Desktop Image',
            id: 'image',
            externalUrl: '',
            note: 'Image for package on desktop',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1',
            relatedFields: ['image.data_url']
        }
    ])

    const {_state: _imageMobile} = useSimpleState([
        {
            type: 'Mobile Image',
            id: 'mobile_image',
            externalUrl: '',
            note: 'Image for package on mobile',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1',
            relatedFields: ['mobile_image.data_url']
        }
    ])

    const [NoticeComponent, notices] = useNotices('PSM/Packages/New')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('PSM:Packages:DataMain')
    const [dataEmphasis, setDataEmphasis, clearDataEmphasis, dataEmphasisDefault] = useDraft('PSM:Packages:DataEmphasis')
    const [dataImage, setDataImage, clearDataImage, dataImageDefault] = useDraft('PSM:Packages:DataImage')
    const [dataMobileImage, setDataMobileImage, clearDataMobileImage, dataMobileDefault] = useDraft('PSM:Packages:DataMobileImage')
    const [dataFields, setDataFields, clearDataFields, dataFieldsDefault] = useDraft('PSM:Packages:DataFields', [])
    const [dataCascadeAddons, setDataCascadeAddons, clearDataCascadeAddons, dataCascadeAddonsDefault] = useDraft('PSM:Packages:DataCascadeAddons', [])
    const [dataCouponCodes, setDataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault] = useDraft('PSM:Packages:DataCouponCodes', [])
    const [dataAddons, setDataAddons, clearDataAddons, dataAddonsDefault] = useDraft('PSM:Packages:DataAddons', [])
    const [dataHelper, setDataHelper, clearDataHelper, dataHelperDefault] = useDraft('PSM:Packages:DataHelper', [])
    const [dataDigitalDelivery, setDataDigitalDelivery, clearDataDigitalDelivery, dataDigitalDeliveryDefault] = useDraft('PSM:Packages:DataDigitalDelivery', [])
    const [optionsCategories, setOptionsCategories] = useState([])
    const [suggestionsTags, setSuggestionsTags] = useState([])
    
    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Basic', ['Basic', 'Description', 'Helper', 'Digital Delivery', 'Package Addons', 'Image', 'Mobile Image', 'Fields', 'Coupon Codes'])

    const [basicFormFields, setBasicFormFields] = useState(getBasicFormFields())
    const [descriptionFormFields] = useState(getDescriptionFormFields())
    const [helperFormFields] = useState(getHelperFormFields())
    const [digitalDeliveryFormFields] = useState(getDigitalDeliveryFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getBasicFormFields(suggestionsTags);
        setBasicFormFields(fields)
    }, [suggestionsTags])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Basic',
                forms: [{ id: 'Main', fields: basicFormFields }]
            },
            {
                tab: 'Description',
                forms: [{ id: 'Emphasis', fields: descriptionFormFields }]
            },
            {
                tab: 'Helper',
                forms: [{ id: 'Helper', fields: helperFormFields }]
            },
            {
                tab: 'Digital Delivery',
                forms: [{ id: 'Digital Delivery', fields: digitalDeliveryFormFields }]
            },
            {
                tab: 'Image',
                forms: [{ id: 'Image', fields: _imageDesktop.get(), fieldKeyProp: 'id' }]
            },
            {
                tab: 'Mobile Image',
                forms: [{ id: 'Mobile Image', fields: _imageMobile.get(), fieldKeyProp: 'id' }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])
    
    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let imageBody = {}

            _imageDesktop.get().forEach((item, index) => {
                imageBody.image_attributes = {
                    data_base64: /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                    data_url: item.externalUrl,
                    data_source: item.assetSource === '0' ? 'local' : 'remote'
                }
            })
    
            _imageMobile.get().forEach((item, index) => {
                imageBody.mobile_image_attributes = {
                    data_base64: /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                    data_url: item.externalUrl,
                    data_source: item.assetSource === '0' ? 'local' : 'remote'
                }
            })

            let body = {...dataMain, ...dataEmphasis, ...dataHelper, ...dataDigitalDelivery, ...imageBody}

            if (dataAddons) {
                body.package_addons_attributes = lodash
                    .cloneDeep(dataAddons)
                    .map(item => {
                        delete item?.item
                        return item
                    })
            }

            [{
                data: dataFields,
                attribute: 'custom_fields_attributes',
                prop: 'field_id'
            },{
                data: dataCouponCodes,
                attribute: 'item_coupon_codes_attributes',
                prop: 'coupon_code_id'
            }].forEach((config) => {
                if (!config.data.length)
                    return

                body[config.attribute] = config.data.filter((item) => {
                    return item.item.id
                }).map((item, index) => {
                    const itemData = item.item

                    let body = {
                        [config.prop]: itemData.id,
                    }

                    if (itemData._destroy) {
                        body._destroy = true
                    }

                    return body
                })
            })

            body.tag_names = body.tags
            delete body.tags

            FetchHelper({
                url: `/api/v2/admin/packages`,
                method: 'POST',
                body: {
                    package: body
                }
            }, (res) => {
                ac.showSpinner(false)

                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    let errors = UtilsHelper.preProcess422Errors(
                        [..._imageDesktop.get(), ..._imageMobile.get()],
                        res.body
                    )
                    setErrors(errors)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/packages/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/categories'
        }, (res) => {
            let categories = [{ id: 0, title: 'Select', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: i+1,
                    title: item.name,
                    value: item.id
                }
                categories.push(option)
            })
            setOptionsCategories(categories)
        }, (res) => {
        })
    }, [])

    return (
        <div className="packages-edit-page-container page-container">
            <PageHeaderComponent title={`New Package`} />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />

            <div className={`page-form-container ${tab === 'Basic' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Basic" notices={notices} />
                <FormComponent formData={dataMain} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={basicFormFields} />
            </div>
            
            <div className={`page-form-container ${tab === 'Description' ? 'show' : ''}`}>
            <NoticeComponent location="Tab/Description" notices={notices} />
                <FormComponent formData={dataEmphasis} formErrors={errors} setFormErrors={setErrors} onChange={setDataEmphasis} displayErrorBanner={true} fields={descriptionFormFields} />
            </div>

            <div className={`page-form-container ${tab === 'Helper' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Helper" notices={notices} />
                <FormComponent formData={dataHelper} formErrors={errors} setFormErrors={setErrors} onChange={setDataHelper} displayErrorBanner={true} fields={helperFormFields} />
            </div>

            <div className={`page-form-container ${tab === 'Digital Delivery' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/DigitalDelivery" notices={notices} />
                <FormComponent formData={dataDigitalDelivery} formErrors={errors} setFormErrors={setErrors} onChange={setDataDigitalDelivery} displayErrorBanner={true} fields={digitalDeliveryFormFields} />
            </div>

            <div className={`page-form-container ${tab === 'Package Addons' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/PackageAddons" notices={notices} />

                <FieldMultiselectorComponent data={dataAddons} entityParent="Package" entityType="Addon" onChange={(newState) => setDataAddons(newState)} columns={[
                    {
                        'title': 'Name'
                    },
                    {
                        'title': 'Price'
                    }
                ]} />
            </div>

            <div className={`page-form-container ${tab === 'Image' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Image" notices={notices} />
                <AssetUploader list={_imageDesktop.get()} key={tab} events={{ handleChange: (value) => _imageDesktop.set(value) }} 
                    errors={errors} setErrors={setErrors} />
            </div>

            <div className={`page-form-container ${tab === 'Mobile Image' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/MobileImage" notices={notices} />
                <AssetUploader list={_imageMobile.get()} key={tab} events={{ handleChange: (value) => _imageMobile.set(value) }} 
                    errors={errors} setErrors={setErrors} />
            </div>

            <div className={`page-form-container ${tab === 'Fields' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Fields" notices={notices} />
                <FieldSelectorComponent data={dataFields} buttonTitle="Field" apiURL="/api/v2/admin/fields" editUrl={(id) => `/fields/${id}/edit`} onChange={(data) => setDataFields(data)} />
            </div>

            <div className={`page-form-container ${tab === 'Coupon Codes' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/CouponCodes" notices={notices} />
                <FieldSelectorComponent data={dataCouponCodes} sortable={false} buttonTitle="Coupon Code" apiURL="/api/v2/admin/coupon-codes" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} />
            </div>

            <ControlBar handleSubmit={handleSubmit} cancelUrl="/packages" handleAfterSubmit={() => RouteHelper.redirect('/packages')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [dataMain, clearDataMain, dataMainDefault],
                [dataEmphasis, clearDataEmphasis, dataEmphasisDefault],
                [dataImage, clearDataImage, dataImageDefault],
                [dataMobileImage, clearDataMobileImage, dataMobileDefault],
                [dataFields, clearDataFields, dataFieldsDefault],
                [dataCascadeAddons, clearDataCascadeAddons, dataCascadeAddonsDefault],
                [dataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault],
                [dataAddons, clearDataAddons, dataAddonsDefault],
                [dataHelper, clearDataHelper, dataHelperDefault],
                [dataDigitalDelivery, clearDataDigitalDelivery, dataDigitalDeliveryDefault]
            ]} />
        </div>
    )
}

export default PackagesNew
