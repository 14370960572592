import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import lodash from 'lodash'
import { FileTypes } from '../../sys/constants/enums'
import { useParams } from 'react-router-dom'

const CampaignsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()
    
    const [NoticeComponent, notices] = useNotices('Tools/Campaigns/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [disableButton, setDisableButton] = useState(false)
    const [csv, setCsv] = useState()
    const [market, setMarket] = useState()
    const [template, setTemplate] = useState()
    const [suggestionsTags, setSuggestionsTags] = useState([])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/campaigns/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            const body = {
                ...res.body,
                template_id_sms: res.body?.type === 'TextTemplate' ? res.body.template_id : null,
                template_id_email: res.body?.type === 'EmailTemplate' ? res.body.template_id : null,
                send_at: res.body.send_at?.replace(/([-+]\d{1,2}:\d{1,2})$/, ''),
            }
            setData(body)
            setCsv(res.body.csv_url)
            fetchAssociated()
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/campaigns/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (error) => {

        })
    }, [])

    const fetchAssociated = () => {
        FetchHelper({
            url: `/api/v2/admin/markets/${data.market_id}`
        }, (res) => {
            setMarket(res.body)
        }, (res) => {

        })

        FetchHelper({
            url: `/api/v2/admin/templates/${data.template_id}`
        }, (res) => {
            setTemplate(res.body)
        }, (res) => {

        })
    }

    const handleSubmit = () => {
        ac.showSpinner(true)
        setDisableButton(true)

        let formFieldsKeys = ['name', 'tags', 'description', 'market_id', 'type', 'template_id', 'data_source', 'csv_url', 'send_at']
        let body = {
            ...lodash.pick(data, formFieldsKeys),
            tag_names: data.tags
        }

        if (body.type === 'EmailTemplate')
            body.template_id = data.template_id_email

        if (body.type === 'TextTemplate')
            body.template_id = data.template_id_sms

        if (!csv?.includes(data.csv_url)) {
            body = {
                ...body,
                csv_base64: data.csv_url?.value
            }
        }

        delete body.tags
        delete body.csv_url

        FetchHelper({
            url: `/api/v2/admin/campaigns/${params.id}`,
            method: 'PUT',
            body: {
                campaign: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/campaigns')
        }, (res) => {
            ac.showSpinner(false)
            setDisableButton(false)
            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    return (
        <div className='campaigns-edit-page-container page-container'>
            {data && !(data.sent) ? (
                <>
                    <PageHeaderComponent title="Edit Campaign" />
                    
                    <NoticeComponent location="Edit/Send" notices={notices} />

                    <div className='message'>
                        <div className='warning'>
                            <h3>This feature is in Beta</h3>
                        </div>
                        <div className='info'>
                            <p className='paragraph'>
                                This tool will send a SMS message to phone numbers in a CSV file marketing your pre-sale.
                                Content is set up in the campaign templates. Before sending, the application will make sure that there are
                                no duplicate phone numbers in your CSV file, so you do not send to the same person multiple times.
                                The phone_number <span className='must'>must</span> be an <span>exact match</span>. Non-digits will be stripped.
                            </p>
                            <h3>CSV is expected to meet the following requirements:</h3>
                            <ol className='paragraph-section'>
                                <li>Must have header</li>
                                <li>Header must be lowercase</li>
                                <li>Header must contain field named: <span className='phone'>phone_number</span></li>
                                <li>Substitution fields must match template placeholders that were setup in the corresponding template (e.g. custom_myfieldname).</li>
                                <li>Fields found in CSV take precedence over system data.</li>
                            </ol>
                        </div>
                    </div>
                    <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                        {
                            type: 'input-text',
                            name: 'name',
                            label: 'Name',
                        }, {
                            type: 'tags',
                            name: 'tags',
                            label: 'Tags',
                            suggestions: suggestionsTags
                        }, {
                            type: 'textarea',
                            name: 'description',
                            label: 'Description'
                        }, {
                            type: 'select-paginated',
                            name: 'market_id',
                            label: 'Market to Promote',
                            apiUrl: '/api/v2/admin/markets',
                        }, {
                            type: 'select',
                            name: 'type',
                            label: 'Type',
                            options: [
                                { id: 1, title: '', value: '' },
                                { id: 2, title: 'Text', value: 'TextTemplate' },
                                { id: 3, title: 'Email', value: 'EmailTemplate' }
                            ]
                        }, {
                            type: 'select-paginated',
                            name: 'template_id_sms',
                            label: 'Template',
                            apiUrl: '/api/v2/admin/templates',
                            condition: (data) => data?.type === 'TextTemplate',
                            queries: [{ key: 'type', value: 'TextTemplate' }]
                        }, {
                            type: 'select-paginated',
                            name: 'template_id_email',
                            label: 'Template',
                            apiUrl: '/api/v2/admin/templates',
                            condition: (data) => data?.type === 'EmailTemplate',
                            queries: [{ key: 'type', value: 'EmailTemplate' }]
                        }, {
                            type: 'select',
                            name: 'data_source',
                            label: 'Data source',
                            options: [
                                { id: 1, title: 'CSV', value: 'csv' },
                                { id: 2, title: 'Proof', value: 'proof' }
                            ],
                            defaultValue: 0
                        }, {
                            type: 'input-file',
                            name: 'csv_url',
                            label: 'CSV',
                            fileType: '',
                            acceptedFileTypes: FileTypes.CSV
                        }, {
                            type: 'datetime',
                            name: 'send_at',
                            label: 'Send at'
                        }
                    ]} />
                    
                    <div className="form-actions">
                        <ControlBar baseApiUrl="/campaigns" redirectUrl="/campaigns" cancelUrl="/campaigns" data={data} handleSubmit={handleSubmit} />
                    </div>
                </>
            ) : (
                data && (
                    <>
                        <PageHeaderComponent title="Campaign Details" />

                        <NoticeComponent location="Edit/Sent" notices={notices} />

                        <div className='global-section'>
                            <div className='global-section-row'>
                                <h3>Name:</h3>
                                <div className='form-field'>{data?.name}</div>
                            </div>
                            <div className='global-section-row'>
                                <h3>Description:</h3>
                                <div className='form-field'>{data?.description}</div>
                            </div>
                            <div className='global-section-row'>
                                <h3>Market:</h3>
                                <div className='form-field'>{market?.name}</div>
                            </div>
                            <div className='global-section-row'>
                                <h3>Template:</h3>
                                <div className='form-field'>{template?.name}</div>
                            </div>
                            <div className='global-section-row'>
                                <h3>CSV:</h3>
                                <div className='form-field'><a href={data?.csv_url} download={`${data?.name}.csv`}>Download</a></div>
                            </div>
                            <div className='global-section-row'>
                                <h3>Sent at:</h3>
                                <div className='form-field'>{UtilsHelper.toDateString({ date: data.send_at, displayWeekday: true, displayTime: true })}</div>
                            </div>
                        </div>
                    </>
                )
            )}
        </div>
    )
}
export default CampaignsEdit