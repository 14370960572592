import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {useEffect, useState} from 'react'

import { AppProvider, AppContext } from './AppContext'

import AddonsEditPage from './pages/Addons/edit'
import AddonsNewPage from './pages/Addons/new'
import AddonsPage from './pages/Addons/'
import AppointmentSlotsPage from './pages/AppointmentSlots/'
import AppointmentSlotsEditPage from './pages/AppointmentSlots/edit'
import AppointmentSlotsNewPage from './pages/AppointmentSlots/new'
import AppointmentProfilesPage from './pages/AppointmentProfiles/'
import AppointmentProfilesEditPage from './pages/AppointmentProfiles/edit'
import AppointmentProfilesNewPage from './pages/AppointmentProfiles/new'
import ArchivesPage from './pages/Archives'
import BackgroundsEditPage from './pages/Backgrounds/edit'
import BackgroundsNewPage from './pages/Backgrounds/new'
import BackgroundsPage from './pages/Backgrounds/'
import BillingProfiles from './pages/BillingProfiles'
import BillingProfilesEdit from './pages/BillingProfiles/edit'
import BillingProfilesNew from './pages/BillingProfiles/new'
import BlackoutsPage from './pages/Blackouts'
import BulkProcessPage from './pages/BulkProcess'
import BulkRefundPage from './pages/BulkRefund'
import CampaignsPage from './pages/Campaigns'
import CampaignsNewPage from './pages/Campaigns/new'
import CampaignsEditPage from './pages/Campaigns/edit'
import CategoriesEditPage from './pages/Categories/edit'
import CategoriesNewPage from './pages/Categories/new'
import CategoriesPage from './pages/Categories/'
import CharitiesPage from './pages/Charities'
import CharitiesEditPage from './pages/Charities/edit'
import CharitiesNewPage from './pages/Charities/new'
import { CheatSheetPage } from './pages/CheatSheet'
import ColorSchemes from './pages/ColorSchemes'
import ColorSchemesEdit from './pages/ColorSchemes/edit'
import ColorSchemesNew from './pages/ColorSchemes/new'
import CouponCodesEditPage from './pages/CouponCodes/edit'
import CouponCodesNewPage from './pages/CouponCodes/new'
import CouponCodesPage from './pages/CouponCodes/'
import CustomReports from './pages/CustomReports'
import CustomReportsNew from './pages/CustomReports/new'
import CustomReportsEdit from './pages/CustomReports/edit'
import DownloadsPage from './pages/Downloads'
import FieldsEditPage from './pages/Fields/edit'
import FieldsNewPage from './pages/Fields/new'
import FieldsPage from './pages/Fields/'
import HelpPage from './pages/Help'
import ImporterPage from './pages/Importer'
import InvoicePage from './pages/Invoice'
import LocationGroupsEditPage from './pages/LocationGroups/edit'
import LocationGroupsNew from './pages/LocationGroups/new'
import LocationGroupsPage from './pages/LocationGroups/'
import LoginPage from './pages/Login'
import MainPage from './pages/Main/'
import MarketGroupsEditPage from './pages/MarketGroups/edit'
import MarketGroupsNew from './pages/MarketGroups/new'
import MarketGroupsPage from './pages/MarketGroups/'
import MarketingPlans from './pages/MarketingPlans/'
import MarketingPlansEdit from './pages/MarketingPlans/edit'
import MarketingPlansNew from './pages/MarketingPlans/new'
import MarketsEdit from './pages/Markets/edit'
import MarketsNewPage from './pages/Markets/new'
import MarketsPage from './pages/Markets/'
import OrderPage from './pages/Order/'
import OrdersPage from './pages/Orders/'
import PackagePlansEditPage from './pages/PackagePlans/edit'
import PackagePlansNewPage from './pages/PackagePlans/new'
import PackagePlansPage from './pages/PackagePlans/'
import PackagesEditPage from './pages/Packages/edit'
import PackagesNewPage from './pages/Packages/new'
import PackagesPage from './pages/Packages/'
import PaymentAccounts from './pages/PaymentAccounts/'
import PaymentAccountsEdit from './pages/PaymentAccounts/edit'
import PaymentAccountsNew from './pages/PaymentAccounts/new'
import PoliciesPage from './pages/Policies'
import PosesEditPage from './pages/Poses/edit'
import PosesNewPage from './pages/Poses/new'
import PosesPage from './pages/Poses/'
import ProfilePage from './pages/Profile'
import ReportsPage from './pages/Reports'
import ReportsListPage from './pages/Reports/list'
import SettingsPage from './pages/Settings'
import SheetsEditPage from './pages/Sheets/edit'
import SheetsNewPage from './pages/Sheets/new'
import SheetsPage from './pages/Sheets/'
import StorefrontLinksPage from './pages/StorefrontLinks'
import Studios from './pages/Studios'
import StudiosNew from './pages/Studios/new'
import StudiosEdit from './pages/Studios/edit'
import Templates from './pages/templates/index'
import TemplatesNew from './pages/templates/new'
import TemplatesEdit from './pages/templates/edit'
import { PageTest } from './pages/Test'
import UpcomingOrdersPage from './pages/UpcomingOrders/'
import UsersEditPage from './pages/Users/edit'
import UsersNewPage from './pages/Users/new'
import UsersPage from './pages/Users/'
import VanityDomains from './pages/VanityDomains'
import VanityDomainsEdit from './pages/VanityDomains/edit'
import VanityDomainsNew from './pages/VanityDomains/new'

import { BannerComponent } from './components/Banner'
import Spinner from './components/Spinner'
import NavigationPrimary from './components/NavigationPrimary'
import DisplayModalPrimary from './components/DisplayModalPrimary'
import DisplayOverlayPrimary from './components/DisplayOverlayPrimary'
import StatusComponent from './components/Status'

import { DomainHelper } from './helpers/domain'
import RouteHelper from './helpers/route'
import UtilsHelper from './helpers/utils'

import useAuth from './hooks/useAuth'
import useWebSockets from './hooks/useWebSockets'

import './App.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'react-quill/dist/quill.snow.css';
import Workflow1 from './pages/Workflow/workflow-1'
import Workflow2 from './pages/Workflow/workflow-2'
import LabEnhancements from './pages/LabsEnhancements'
import LabEnhancementsNew from './pages/LabsEnhancements/new'
import LabEnhancementsEdit from './pages/LabsEnhancements/edit'
import ProgressBar from './components/ProgressBar'
import Enhancements from './pages/Enhancements'
import EnhancementsNew from './pages/Enhancements/new'
import EnhancementsEdit from './pages/Enhancements/edit'
import Labs from './pages/labs'
import LabsNew from './pages/labs/new'
import LabsEdit from './pages/labs/edit'

function App() {
  const [isLoading, showSpinner] = useState(false)
  const [showProgress, setProgress] = useState()
  const [isScrollDisabled, setIsScrollDisabled] = useState(false)
  const [isMocked, setIsMocked] = useState(false)
  const [displayModal, setDisplayModal] = useState()
  const [displayOverlay, setDisplayOverlay] = useState()
  const [status, setStatus] = useState({})
  const [AuthHelper, user, setUser] = useAuth()

  const { webSocket } = useWebSockets()

  useEffect(() => {
    RouteHelper.storeCurrentURL()
  }, [])

  useEffect(() => {
    if (!AuthHelper?.isAdmin() && AuthHelper?.settings?.past_due) {
      setStatus({
        message: (<p>Your subscription invoice past due, and your software is at risk of being disabled shortly.<br/>Please contact us to make arrangements to avoid disruption of service. 305-819-0700 or sales@prepaysystems.com</p>),
        type: 'danger',
        uniqID: 'past_due',
        config: { timeout: 30 * 1000, side: 'top', showCloseBtn: true }
      })
    }
  }, [AuthHelper?.isAdmin(), AuthHelper?.settings?.past_due])

  const localStorageMocked = UtilsHelper.getLocalStorage('UI:Mocked')

  useEffect(() => {
    if (localStorageMocked) {
      setIsMocked(true)

      UtilsHelper.removeLocalStorage('UI:Mocked')
    }
  }, [localStorageMocked])

  useEffect(() => {
    if (isScrollDisabled) {
      document.querySelector('body').classList.add('scroll-disabled')
    } else {
      document.querySelector('body').classList.remove('scroll-disabled')
    }
  }, [isScrollDisabled])

  return (
    <>
      {isMocked && (
        <BannerComponent type="sticky">
          <i className="bi bi-exclamation-triangle-fill"></i> This is a mocked experience. Check headers for 'x-mocked' property.
        </BannerComponent>
      )}
      <div className={`app-container ${AuthHelper.isSignedIn() ? 'signedIn' : 'signedOut'}`}>
        <BrowserRouter>
          <AppProvider value={{
            user,
            DomainHelper,
            AuthHelper,
            isLoading,
            showSpinner,
            showProgress,
            setProgress,
            isScrollDisabled,
            setIsScrollDisabled,
            isMocked,
            setIsMocked,
            displayModal,
            setDisplayModal,
            displayOverlay,
            setDisplayOverlay,
            status,
            setStatus }}>
          {!user.login ? (
            <>
              <Routes>
                <Route path="login" element={<LoginPage />} />
                <Route path="login/reset/:token" element={<LoginPage />} />
                <Route path="*" element={<></>} />
              </Routes>
            </>
          ) : (
            <>
              <Spinner isLoading={isLoading} />

              {showProgress && <ProgressBar {...showProgress} />}

              <StatusComponent message={status.message} type={status.type} uniqID={status.uniqID} config={status.config} />

                <NavigationPrimary config={user.hierarchy} />

                {displayModal && <DisplayModalPrimary />}
                {displayOverlay && <DisplayOverlayPrimary />}

                <Routes>
                  <Route path="addons" element={<AddonsPage />} />
                  <Route path="addons/:id/edit" element={<AddonsEditPage />} />
                  <Route path="addons/new" element={<AddonsNewPage />} />

                  <Route path="appointment-slots" element={<AppointmentSlotsPage />} />
                  <Route path="appointment-slots/:id/edit" element={<AppointmentSlotsEditPage />} />
                  <Route path="appointment-slots/new" element={<AppointmentSlotsNewPage />} />

                  <Route path="appointment-profiles" element={<AppointmentProfilesPage />} />
                  <Route path="appointment-profiles/:id/edit" element={<AppointmentProfilesEditPage />} />
                  <Route path="appointment-profiles/new" element={<AppointmentProfilesNewPage />} />

                  <Route path="archives" element={<ArchivesPage />} />

                  <Route path="backgrounds" element={<BackgroundsPage />} />
                  <Route path="backgrounds/:id/edit" element={<BackgroundsEditPage />} />
                  <Route path="backgrounds/new" element={<BackgroundsNewPage />} />

                  <Route path="billing-profiles" element={<BillingProfiles />} />
                  <Route path="billing-profiles/:id/edit" element={<BillingProfilesEdit />} />
                  <Route path="billing-profiles/new" element={<BillingProfilesNew />} />
                  
                  <Route path="blackouts" element={<BlackoutsPage />} />

                  <Route path="campaigns" element={<CampaignsPage />} />
                  <Route path="campaigns/new" element={<CampaignsNewPage />} />
                  <Route path="campaigns/:id/edit" element={<CampaignsEditPage />} />

                  <Route path="categories" element={<CategoriesPage />} />
                  <Route path="categories/:id/edit" element={<CategoriesEditPage />} />
                  <Route path="categories/new" element={<CategoriesNewPage />} />

                  <Route path="charities" element={<CharitiesPage />} />
                  <Route path="charities/:id/edit" element={<CharitiesEditPage />} />
                  <Route path="charities/new" element={<CharitiesNewPage />} />

                  <Route path="cheat-sheet" element={<CheatSheetPage />} />

                  <Route path="color-schemes" element={<ColorSchemes />} />
                  <Route path="color-schemes/:id/edit" element={<ColorSchemesEdit />} />
                  <Route path="color-schemes/new" element={<ColorSchemesNew />} />

                  <Route path="coupon-codes" element={<CouponCodesPage />} />
                  <Route path="coupon-codes/:id/edit" element={<CouponCodesEditPage />} />
                  <Route path="coupon-codes/new" element={<CouponCodesNewPage />} />

                  <Route path="custom-reports" element={<CustomReports />} />
                  <Route path="custom-reports/new" element={<CustomReportsNew />}/>
                  <Route path="custom-reports/:id/edit" element={<CustomReportsEdit />}/>

                  <Route path="downloads" element={<DownloadsPage />} />

                  <Route path="fields" element={<FieldsPage />} />
                  <Route path="fields/:id/edit" element={<FieldsEditPage />} />
                  <Route path="fields/new" element={<FieldsNewPage />} />

                  <Route path="help" element={<HelpPage />} />

                  <Route path="importer" element={<ImporterPage />} /> 

                  <Route path="invoice" element={<InvoicePage />} /> 

                  <Route path="location-groups" element={<LocationGroupsPage />} />
                  <Route path="location-groups/:id/edit" element={<LocationGroupsEditPage />} />
                  <Route path="location-groups/new" element={<LocationGroupsNew />} />

                  <Route path="market-groups" element={<MarketGroupsPage />} />
                  <Route path="market-groups/:id/edit" element={<MarketGroupsEditPage />} />
                  <Route path="market-groups/new" element={<MarketGroupsNew />} />

                  <Route path="marketing-plans" element={<MarketingPlans />} />
                  <Route path="marketing-plans/:id/edit" element={<MarketingPlansEdit />} />
                  <Route path="marketing-plans/new" element={<MarketingPlansNew />} />

                  <Route path="markets" element={<MarketsPage />} />
                  <Route path="markets/:id/edit" element={<MarketsEdit />} />
                  <Route path="markets/new" element={<MarketsNewPage />} />

                  <Route path="order/:id" element={<OrderPage />} />
                  <Route path="orders" element={<OrdersPage />} />

                  <Route path="orders/bulk-process" element={<BulkProcessPage />} />
                  <Route path="orders/bulk-refund" element={<BulkRefundPage />} />
                  <Route path="orders/upcoming" element={<UpcomingOrdersPage />} />

                  <Route path="package-plans" element={<PackagePlansPage />} />
                  <Route path="package-plans/:id/edit" element={<PackagePlansEditPage />} />
                  <Route path="package-plans/new" element={<PackagePlansNewPage />} />

                  <Route path="packages" element={<PackagesPage />} />
                  <Route path="packages/:id/edit" element={<PackagesEditPage />} />
                  <Route path="packages/new" element={<PackagesNewPage />} />

                  <Route path="payment-accounts" element={<PaymentAccounts />} />
                  <Route path="payment-accounts/:id/edit" element={<PaymentAccountsEdit />} />
                  <Route path="payment-accounts/new" element={<PaymentAccountsNew />} />

                  <Route path="policies" element={<PoliciesPage  />} />

                  <Route path="poses" element={<PosesPage />} />
                  <Route path="poses/:id/edit" element={<PosesEditPage />} />
                  <Route path="poses/new" element={<PosesNewPage />} />

                  <Route path="profile" element={<ProfilePage />} />

                  <Route path="reports" element={<ReportsPage />} />
                  <Route path="reports/list" element={<ReportsListPage />} />

                  <Route path="settings" element={<SettingsPage  />} />

                  <Route path="sheets" element={<SheetsPage />} />
                  <Route path="sheets/:id/edit" element={<SheetsEditPage />} />
                  <Route path="sheets/new" element={<SheetsNewPage />} />

                  <Route path="storefront-links" element={<StorefrontLinksPage />} />

                  <Route path="studios" element={<Studios />} />
                  <Route path="studios/new" element={<StudiosNew />} />
                  <Route path="studios/:id/edit" element={<StudiosEdit />} />

                  <Route path="templates" element={<Templates />} />
                  <Route path="templates/new" element={<TemplatesNew />} />
                  <Route path="templates/:id/edit" element={<TemplatesEdit />} />

                  <Route path="users" element={<UsersPage />} />
                  <Route path="users/:id/edit" element={<UsersEditPage />} />
                  <Route path="users/new" element={<UsersNewPage />} />

                  <Route path="vanity-domains" element={<VanityDomains />} />
                  <Route path="vanity-domains/:id/edit" element={<VanityDomainsEdit />} />
                  <Route path="vanity-domains/new" element={<VanityDomainsNew />} />

                  <Route path="workflow-1" element={<Workflow1 />} />
                  <Route path="workflow-2" element={<Workflow2 />} />
                  
                  <Route path="lab-enhancements" element={<LabEnhancements />} />
                  <Route path="lab-enhancements/new" element={<LabEnhancementsNew />} />
                  <Route path="lab-enhancements/:id/edit" element={<LabEnhancementsEdit />} />

                  <Route path="enhancements" element={<Enhancements />} />
                  <Route path="enhancements/new" element={<EnhancementsNew />} />
                  <Route path="enhancements/:id/edit" element={<EnhancementsEdit />} />

                  <Route path="labs" element={<Labs />} />
                  <Route path="labs/new" element={<LabsNew />} />
                  <Route path="labs/:id/edit" element={<LabsEdit />} />

                  <Route path="test" element={<PageTest />} />

                  <Route path="/" element={<MainPage />} />
                </Routes>
            </>
          )}
          </AppProvider>
        </BrowserRouter>
      </div>
    </>
  )
}

export default App
