import {useState, useEffect, useRef} from 'react'


import useAppContext from '../hooks/useAppContext'
import useDebounce from '../helpers/debounce'
import useFetch from '../hooks/useFetch'

import IconSearch from '../assets/icon-search-dark.svg'
import './Search.css'
import { FetchAbortReason } from '../sys/constants/enums'
import useTearDown from '../hooks/useTearDown'
import lodash from 'lodash'

const Search = ({ apiUrl, page, setPage, queryString = '', showTags, setShowTags, setIsFetching, showFilters, setShowFilters, afterFetch, tags={}, filters }) => {
    const [ac] = useAppContext()

    const { FetchHelper, isFetchHelperFetching } = useFetch()

    const [term, setTerm] = useState('')
    const [isFetchingPrivate, setIsFetchingPrivate] = useState(false)
    const fetchControllers = useRef([])
    useTearDown(fetchControllers)

    const debouncedTerm = useDebounce(term, 250)

    useEffect(() => {
        if (isFetchingPrivate)
            return

        setIsFetchingPrivate(true)
        ac.showSpinner(true)

        if (setIsFetching) {
            setIsFetching(true)
        }

        let queryStringTags = ''
        
        if (tags.active && tags.active.length) {
            queryStringTags = `&tagged=${tags.active.join(',')}`
        }

        let url = `${apiUrl}?q=${term}&page=${page}${queryStringTags}${queryString}`

        // market index filters
        for (let key in filters) {
            let val = filters[key]
            let prefix = ''

            if (val) {
                switch (key) {
                    case 'theme':
                        prefix = 'f_t'
                        break
                    case 'purchase_format':
                        prefix = 'f_pf'
                        break
                    case 'state':
                        prefix = 'f_s'
                        break
                    case 'market_group_id':
                        prefix = 'f_mg'
                        break
                }

                url += `&${prefix}=${val}`
            }
        }

        FetchHelper({
            url: url
        }, (res) => {
            afterFetch(res.body)
            ac.showSpinner(false)
            setIsFetchingPrivate(false)

            if (setIsFetching) {
                setIsFetching(false)
            }
        }, (res) => {
            if(res === FetchAbortReason.PAGE_TERMINATED) return;

            afterFetch({ data: [] })
            ac.showSpinner(false)
            setIsFetchingPrivate(false)

            if (setIsFetching) {
                setIsFetching(false)
            }
        }, (controller) => {
            fetchControllers.current.push(controller)
        })
    }, [debouncedTerm, page, tags, filters])

    return (
        <div className="search-container">
            <div className="search-input">
                <div className="search-input-icon-search">
                    <img src={IconSearch} alt="" />
                </div>
                <input type="text" placeholder="Search..." onChange={(e) => {
                    setPage(1)
                    setTerm(e.target.value)
                }} />
                {setShowFilters ? <div className={`search-input-tab search-input-tab-filters ${(showFilters && 'action-active') || ''}`} onClick={() => setShowFilters(!showFilters)}>
                    <i className={`icon-large bi bi-sliders ${filters && lodash.some(filters, v => v) ? "indicator" : ""}`}></i>
                </div> : null}
                {tags.all && tags.all.length ? <div className={`search-input-tab search-input-tab-tags ${(showTags && 'action-active') || ''}`} onClick={() => setShowTags(!showTags)}>
                    <i className={`icon-large bi bi-tags ${tags.active.length ? 'indicator' : ''}`}></i>
                </div> : null}
            </div>
        </div>
    )
}

export default Search
