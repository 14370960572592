import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

const useQueryParams = ({ keys=[] }) => {
    const [params, setParams] = useState()
    const hash = useLocation().hash

    useEffect(() => {
        let paramsList = {}

        keys.forEach((item, index) => {
            const value = new URLSearchParams(hash).get(item)

            if (value) {
                paramsList[item] = value
            }
        })

        setParams(paramsList)
    }, [])

    return {
        queryParams: params
    }
}

export default useQueryParams