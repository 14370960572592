import { forwardRef } from 'react'

import PropTypes from 'prop-types'

import { Decorators } from '../../helpers/Decorators'
import { NAMESPACE } from '../../config'

const InnerLayout = ({ name, children, identifier, config = {} }) => {
    const attributes = Decorators.Object({
        className: Decorators.String([
            `${NAMESPACE}-inner`, config.classNames,
            `${NAMESPACE}-inner--${name}`
        ])
    })

    return (
        <div key={identifier} {...attributes}>
            {children}
        </div>
    )
}

InnerLayout.propTypes = {
    name: PropTypes.string.isRequired,

    config: PropTypes.shape({
        classNames: PropTypes.string,
    })
}

export {
    InnerLayout
}