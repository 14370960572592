import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { useParams } from 'react-router-dom'

const VanityDomainsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/VanityDomains/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/vanity-domains/${params.id}`
        }, (res) => {
            setData(res.body)
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [])

    const handleSubmit = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/vanity-domains/${params.id}`,
            method: 'PUT',
            body: {
                vanity_domain: dataMain
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/vanity-domains')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title={`Edit Vanity Domain ${data?.name ? `— ${data.name}` : ''}`} />
            <NoticeComponent location="Main" notices={notices} />
            {data &&
                <>
                    <FormComponent formData={data} formErrors={errors} onChange={setDataMain} fields={[
                        {
                            type: 'input-text',
                            name: 'name',
                            label: 'Name',
                        }, {
                            type: 'select',
                            name: 'theme',
                            label: 'Theme',
                            options: [{
                                title: 'Select',
                                value: ''
                            }, {
                                title: 'Bunny',
                                value: 'Bunny'
                            }, {
                                title: 'Dance',
                                value: 'Dance'
                            }, {
                                title: 'Santa',
                                value: 'Santa',
                            }, {
                                title: 'Generic',
                                value: 'Generic'
                            }]
                        }, {
                            type: 'select',
                            name: 'purchase_format',
                            label: 'Purchase format',
                            options: [{
                                title: 'Select',
                                value: ''
                            }, {
                                title: 'FastPass',
                                value: 'FastPass'
                            }, {
                                title: 'PrePay',
                                value: 'PrePay'
                            }]
                        }, {
                            type: 'input-text',
                            name: 'slogan',
                            label: 'Slogan'
                        }, {
                            type: 'input-text',
                            name: 'domain',
                            label: 'Domain',
                            field_info: <div>
                                <p>Do <strong>not</strong> prefix with <span style={{ color: 'red' }}>http(s)</span></p>
                                <p>Do <strong>not</strong> prefix with <span style={{ color: 'red' }}>www</span></p>
                            </div>
                        }, {
                            type: 'switch',
                            name: 'with_www',
                            label: 'With www',
                            field_info: <div>
                                <p>This should be enabled if you want to prefix the above domain with <strong>www</strong>, i.e. myveryspecialdomain.com and www.myveryspecialdomain.com.</p>
                                <p>If only a subdomain is desired, e.g. prepay.mycompany.com, this can be disabled.</p>
                            </div>
                        }
                    ]} />
                    <ControlBar baseApiUrl="/vanity-domains" redirectUrl="/vanity-domains" cancelUrl="/vanity-domains" data={data} handleSubmit={handleSubmit} />
                </>
            }
        </div>
    )
}
export default VanityDomainsEdit
