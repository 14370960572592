import React from 'react'

import PropTypes from 'prop-types'

import { ButtonComponent, HeadingComponent, InputComponent } from '../../Primitive'
import { ModalComponent } from '../../Complex'
import { UI } from '../../../UI'

import './index.css'

const QuantityModalComponent = ({ value=0, events={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        currentView: 'main'
    })

    let _deferredUpdateTimerRef = React.useRef(null)

    const handleChange = (value) => {
        window.clearTimeout(_deferredUpdateTimerRef?.current)

        _deferredUpdateTimerRef.current = window.setTimeout(() => {
            if (events.handleChange && value)
                events.handleChange(value)
        }, 500)
    }

    const handleClose = () => {
        if (events.handleClose)
            events.handleClose()
    }

    const renderView = () => {
        switch (_state.get('currentView')) {
            case 'main':
                return renderViewMain()
        }
    }

    const renderViewMain = () => {
        return (
            <UI.Layout name="quantityModal" type="container">
                <HeadingComponent type="h3">{config.titleMain || 'Change Quantity'}</HeadingComponent>

                <div className="ui--quantityModalInput">
                    <InputComponent name="quantityModalInput" type="number" value={value} events={{ handleChange: handleChange }} />
                </div>

                <div className={`ui--controls grid grid-cols-1 gap-2`}>
                    <ButtonComponent config={{ palette: 'blue' }} events={{ handleClick: handleClose }}>Done</ButtonComponent>
                </div>
            </UI.Layout>
        )
    }

    return (
        <ModalComponent config={{ classNames: 'ui--quantityModal', isSyncing: config.isSyncing }} events={{ handleClose: handleClose }}>
            {renderView()}
        </ModalComponent>
    )
}

QuantityModalComponent.propTypes = {
    events: PropTypes.shape({
        handleSubmit: PropTypes.func
    }),

    config: PropTypes.shape({
        titleMain: PropTypes.any
    }),

}

export { QuantityModalComponent }