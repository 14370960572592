import PropTypes from 'prop-types'

import { ButtonComponent } from '../../Primitive'
import { CodeComponent } from '../../Integrated/Code'
import Helpers from '../../../helpers/utils'

import './index.css'

const EntityCardComponent = ({ item, type='default', stacked, events={}, config={} }) => {  
    const handleClick = () => {
        if (events.handleClick)
            events.handleClick(item)
    }

    const handleDelete = () => {
        if (events.handleDelete)
            events.handleDelete(item)
    }

    const _style = {}
    const _styleBackground = {}

    if (item.proof_image) {
        _style.backgroundImage = `url(${item.proof_image?.thumbnail_url})`

        if (item.background) {
            _styleBackground.backgroundImage = `url(${item.background?.image_url})`
        }
    } else if (item.image?.source) {
        if (item.image.source === 'remote') {
            _style.backgroundImage = `url(${item.image.sources.remote})`
        } else {
            _style.backgroundImage = `url(${item.image.sources.local.thumbnail_url})`
        }
    } else if (item.image?.thumbnail_url || item.image_url) {
        _style.backgroundImage = `url(${item.image?.thumbnail_url || item.image_url})`
    }

    if (type === 'proof') {
        return (
            <div className={`ui-container--entityCard ${item._ui_action_sync ? `ui-action--sync--${item._ui_action_sync}` : ''}`}>
                <div className="ui--entityCardImageBackground" style={_styleBackground}>
                    <div className="ui--entityCardImage" style={_style} onClick={handleClick}></div>
                </div>
                {config.enableCode && item.code && (
                    <div className="ui--entityCardCode">
                        <CodeComponent code={item.code} />
                    </div>
                )}
                {!stacked ? (
                    <div className="ui--entityCardFilename" onClick={() => Helpers.downloadImage(item.proof_image?.download_url, item.proof_image?.filename)}>
                        {item.proof_image?.filename} <i className="icon-download bi bi-download"></i>
                    </div>
                ) : null}
            </div>
        )
    } else {
        return (
            <div className={`ui-container--entityCard ${item._ui_action_sync ? `ui-action--sync--${item._ui_action_sync}` : ''}`}>
                <div className="ui--entityCardImage" style={_style} onClick={handleClick}>
                    <div><div></div></div>
                </div>
                {config.enableCode && item.code && (
                    <div className="ui--entityCardCode">
                        <CodeComponent code={item.code} />
                    </div>
                )}
                {config.enableDelete && !/put|post/.test(item._ui_action_sync) && item.id && (
                    <div className="ui--entityCardControls">
                        <ButtonComponent config={{ size: 'tiny', palette: 'red' }} events={{ handleClick: handleDelete }}>
                            <i className="ui-icon bi-trash-fill"></i>
                        </ButtonComponent>
                    </div>
                )}
            </div>
        )
    }
}

EntityCardComponent.propTypes = {
    item: PropTypes.object,
    
    events: PropTypes.shape({
        handleClick: PropTypes.func,
        handleDelete: PropTypes.func
    }),

    config: PropTypes.shape({
        enableDelete: PropTypes.bool,
        showCode: PropTypes.bool
    })
}
export { EntityCardComponent }