import { useState, useEffect, useRef } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { getFormFields } from './providers/form-fields'

import './edit.css'
import { useParams } from 'react-router-dom'

const CustomReportsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Reports/CustomReports/Edit')

    const ref = useRef()
    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dragId, setDragId] = useState()
    const [options, setOptions] = useState([])
    const [columns, setColumns] = useState([])
    const [addColumn, setAddColumns] = useState(false)
    const [dataColumns, setDataColumns] = useState([])

    const [formFields, _] = useState(getFormFields())

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/custom-reports/${params.id}`
        }, (res) => {
            let temp = res.body.columns.data
            temp.sort((a, b) => a.position - b.position)
            setColumns(temp)
            setDataColumns(temp)
            setData({ report_name: res.body.name })
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: `/api/v2/admin/custom-reports/columns`
        }, (res) => {
            let temp = []
            const col_options = res.body
            for (var key in col_options) {
                temp.push(key)
                const col_options_values = col_options[key]
                for (var key_val in col_options_values) {
                    temp.push({ title: key_val, value: col_options_values[key_val] })
                }
            }
            setOptions(temp)
            ac.showSpinner(false)
        }, (res) => {
        })
    }, [])

    useEffect(() => {
        if (addColumn) {
            const position = columns.length
            setColumns([...columns, { position: position, _destroy: false }])
            setDataColumns([...dataColumns, { position: position, _destroy: false }])
            setAddColumns(false)
        }
    }, [addColumn])

    useEffect(() => {
        function handleClickOutside(event) {

            if (ref && ref.current && !ref.current.contains(event.target)) {
                const columnsDiv = document.querySelectorAll('.column-wrapper')
                for (let i = 0; i < columnsDiv.length; i++) {
                    if (i !== dragId) {
                        columnsDiv[i].className = "column-wrapper"
                    }
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ref])

    const handleSubmit = () => {
        ac.showSpinner(true)

        const body = {
            name: data.report_name,
            custom_report_columns_attributes: dataColumns
        }

        FetchHelper({
            url: `/api/v2/admin/custom-reports/${params.id}`,
            method: 'PUT',
            body: {
                custom_report: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/custom-reports')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(formFields, res.body)
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    const removeColumns = (index) => {
        setColumns(columns.filter((obj) => (obj.position === index ? false : true)))
        let temp = [...dataColumns]
        temp[index] = { ...dataColumns[index], _destroy: true }
        setDataColumns(temp)
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target

        let temp = [...columns]
        temp[index] = { ...columns[index], [name]: value }
        setColumns(temp)
        const dcIndex = dataColumns.findIndex((obj => obj.position === temp[index].position))
        if (dataColumns[dcIndex]) {
            let temp_columns = [...dataColumns]
            temp_columns[dcIndex] = { ...columns[index], [name]: value }
            setDataColumns(temp_columns)
        } else {
            setDataColumns([...dataColumns, { ...columns[index], [name]: value }])
        }
    }

    const renderOptions = (list) => {
        let temp = []

        list.map((item, i) => {
            if (!item.title) {
                temp.push(<option key={i} disabled className="option-group">{item}</option>)
            } else {
                temp.push(<option key={i} value={item.value} className="option">{item.title}</option>)
            }
        })

        return temp
    }

    const handleDrop = (e) => {
        e.dataTransfer.dropEffect = "move"
        e.dataTransfer.effectAllowed = "move"
        const target = parseInt(e.currentTarget.id)
        const drag = columns.find((col) => col.position === dragId)
        const drop = columns.find((col) => col.position === target)
        const dragOrder = drag.position
        const dropOrder = drop.position
        const columnsDiv = document.querySelectorAll('.column-wrapper')

        const newColumnsState = columns.map((col) => {
            var tempCol = col
            if (col.position === dragId) {
                tempCol = { ...tempCol, position: dropOrder }
            }
            if (col.position === target) {
                tempCol = { ...tempCol, position: dragOrder }
            }

            return tempCol
        });

        newColumnsState.sort((a, b) => a.position - b.position)
        setColumns(newColumnsState)
        setDataColumns(newColumnsState)

        for (let i = 0; i < columnsDiv.length; i++) {
            if (i !== dragId) {
                columnsDiv[i].className = "column-wrapper"
            }
        }
        e.currentTarget.className = "column-wrapper dragged"
    }

    const onDragOver = (e) => {
        e.preventDefault()
        const target = parseInt(e.currentTarget.id)
        if (dragId !== target) {
            e.currentTarget.className = "column-wrapper dragging"
        }
    }

    const onDragStart = (index) => {
        const columnsDiv = document.querySelectorAll('.column-wrapper')
        for (let i = 0; i < columnsDiv.length; i++) {
            columnsDiv[i].className = "column-wrapper"
        }
        setDragId(index)
    }

    const renderColumn = (value, index) => {
        const i = value.position
        return (
            <div ref={ref} id={i} key={index} className="column-wrapper" draggable={true} onDragOver={(e) => onDragOver(e, i)} onDragStart={(e) => onDragStart(i)} onDrop={handleDrop}>
                <button className="btn-move"><i className="bi bi-arrow-down-up"></i></button>
                <div key={index} className="column-inputs">
                    <input name="name" type="text" value={value.name || ''} onChange={(e) => handleChange(e, index)} />
                    <select name="data_source" value={value.data_source || ''} onChange={(e) => handleChange(e, index)} >
                        <option value=''>Select</option>
                        {renderOptions(options)}
                    </select>
                </div>
                <button className="btn-trash" onClick={() => removeColumns(i)}><i className="bi bi-trash3-fill"></i></button>
            </div>
        )
    }

    return (
        <div className="custom-reports-container">
            <PageHeaderComponent title="Edit Custom Report" />
            <NoticeComponent location="Main" notices={notices} />
            {data && columns &&
                <>
                    <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={formFields} />
                    <div className="form-container default form-field-wrapper columns-form">
                        <div className="form-label">
                            <button className="secondary" onClick={() => setAddColumns(true)}>Add Column</button>
                        </div>
                        <div className="form-field field-columns-wrapper">
                            {columns.map((col, i) => (
                                renderColumn(columns[i], i)
                            ))}
                        </div>
                    </div>
                    <ControlBar baseApiUrl="/custom-reports" redirectUrl="/custom-reports" cancelUrl="/custom-reports" data={data} handleSubmit={handleSubmit} />
                </>
            }
        </div>
    )
}

export default CustomReportsEdit