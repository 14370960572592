import { useState, useEffect } from 'react'

import { ModalComponent } from '../../../componentsV2/Complex'
import SelectAutocompleteComponent from '../../../components/SelectAutocomplete'
import SelectPaginated from '../../../components/SelectPaginated'
import ErrorsComponent from '../../../components/Errors'
import Loading from '../../../components/Loading'
import PickerDate from '../../../components/PickerDate'
import SwitchComponent from '../../../components/Switch'
import TooltipComponent from '../../../components/Tooltip'

import FetchHelper from '../../../helpers/fetch'
import UtilsHelper from '../../../helpers/utils'
import APIHelper from '../../../helpers/api'

import { $ } from '../../../packages'

import { useAppContext } from '../../../hooks'

import './RescheduleModal.css'

const RescheduleModal = ({ order, onClose }) => {
    const [ac] = useAppContext()

    const [fetching, setFetching] = useState(false)
    const [errors, setErrors] = useState()
    const [startDate, setStartDate] = useState($.dayjs().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState($.dayjs().add(3, 'month').format('YYYY-MM-DD'))
    const [selectedMarket, setSelectedMarket] = useState()
    const [selectedMarketTitle, setSelectedMarketTitle] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [selectedDateTitle, setSelectedDateTitle] = useState()
    const [selectedSlot, setSelectedSlot] = useState()
    const [selectedSlotTitle, setSelectedSlotTitle] = useState()
    const [allowOverbook, setAllowOverbook] = useState(false)
    const [markets, setMarkets] = useState()
    const [dates, setDates] = useState([])
    const [slots, setSlots] = useState([])

    useEffect(() => {
        setFetching(true)

        APIHelper.getMarkets(({ markets }) => {
            setMarkets(markets)

            setFetching(false)
        })
    }, [])

    useEffect(() => {
        if (selectedMarket) {
            setDates([])
            setSlots([])
            setSelectedDate()
            setSelectedSlot()
            setFetching(true)

            FetchHelper({
                url: `/api/v2/admin/markets/${selectedMarket.id}/schedules?start_date=${$.dayjs().format('YYYY-MM-DD')}&end_date=${$.dayjs().add(3, 'month').format('YYYY-MM-DD')}`
            }, (res) => {
                setFetching(false)

                if (res.body.data.length) {
                    setDates([{
                        group: 'Dates', options: res.body.data.map((option) => {
                            return {
                                value: option.id,
                                title: `${option.date} ${option.extra_fee > 0 ? '(' + UtilsHelper.formatDollar(option.extra_fee) + ')' : ''}`,
                                item: option
                            }
                        })
                    }])
                }
            }, (res) => {
                setFetching(false)
            })
        }
    }, [selectedMarket, startDate, endDate])

    useEffect(() => {
        if (selectedDate) {
            setSelectedSlot()
            setFetching(true)

            FetchHelper({
                url: `/api/v2/admin/markets/schedules/${selectedDate}/slots`
            }, (res) => {
                setFetching(false)

                setSlots([
                    {
                        group: 'Slots',
                        options: res.body.data.map((option) => {
                            return {
                                value: option.slot,
                                title: `${option.name} (${option.used}/${option.capacity}) ${option.premium ? '(' + UtilsHelper.formatDollar(option.fee) + ')' : ''}`,
                                item: option
                            }
                        })
                    }
                ])
            }, (res) => {
                setFetching(false)
            })
        }
    }, [selectedDate])

    const handleSchedule = () => {
        setFetching(true)

        FetchHelper({
            url: `/api/v2/admin/orders/${order.id}/reservation`,
            method: 'put',
            body: {
                reservation: {
                    schedule_id: selectedDate,
                    slot_id: selectedSlot.id,
                    market_id: selectedMarket.id,
                    allow_overbook: allowOverbook
                }
            }
        }, (res) => {
            UtilsHelper.refreshPage()
        }, (res) => {
            setFetching(false)

            if (res.status === 422) {
                setErrors([`${res.status} - Unprocessable Entity`])
            } else {
                setErrors(res.body)
            }
        })
    }

    return <ModalComponent events={{ handleClose: onClose }}>
        <div className={`reschedule-modal-component-container ${fetching ? 'disabled' : ''}`}>
            <div className="title">
                <em>Reschedule/Move Order</em>
            </div>

            {markets && (<div className="row markets">
                <SelectPaginated apiUrl="/api/v2/admin/markets" onSelect={(option) => {
                    setSelectedDate()
                    setSelectedSlot()
                    setSelectedMarket(option.item)
                }} />
            </div>) || null}

            {selectedMarket && selectedMarket.options?.use_scheduling && (
                <div className="row dates">
                    <PickerDate initialValue={startDate} onChange={setStartDate} dateFormat="YYYY-MM-DD" />
                    <PickerDate initialValue={endDate} onChange={setEndDate} dateFormat="YYYY-MM-DD" />
                </div>
            ) || null}

            {selectedMarket && selectedMarket.options?.use_scheduling && !dates.length && !fetching && (<div className="row disclaimer">
                <em>No Availability</em>
                <p>Please try a different date range</p>
            </div>) || null}

            {selectedMarket && !selectedMarket.options?.use_scheduling && !fetching && (<div className="row disclaimer">
                <em>Rescheduling Not Available</em>
                <p>This will MOVE Order and, if exists, DELETE Reservation</p>
            </div>) || null}

            {selectedMarket && startDate && endDate && dates.length && (<div className="row">
                <SelectAutocompleteComponent suggestions={dates || []} onSelect={(option) => {
                    setSelectedDate(option.value)
                    setSelectedDateTitle(option.title)
                }} disableAll={true} />
            </div>) || null}

            {selectedMarket && selectedDate && (<div className="row">
                <SelectAutocompleteComponent suggestions={slots || []} onSelect={(option, item) => {
                    setSelectedSlot(item)
                    setSelectedSlotTitle(option.title)
                }} disableAll={true} />
            </div>) || null}

            {selectedMarket && selectedSlot?.id && order.reservation?.data?.starts_at && (
                <div className="row confirmation">
                    <div className="confirmation-message">
                        <span>You are rescheduling this order. The change will be as follows:</span>
                        <TooltipComponent position="left">
                            <p>If the location you are switching to has a different Package Plan and package/addons, you will see an exclamation point in the product details section once you click reschedule. Click the product to switch to a comparable product on the new package plan.</p>
                        </TooltipComponent>
                    </div>
                    <div className="details">
                        <p><em>{order.market_name}</em> {$.dayjs(order.reservation.data.starts_at).format('YYYY/MM/DD h:mm A')}</p>
                        <p><i className="bi bi-arrow-down"></i></p>
                        <p><em>{selectedMarket.name}</em> {selectedDateTitle} - {selectedSlotTitle}</p>
                    </div>
                </div>
            )}

            {fetching && (
                <div className="reschedule-modal-loading">
                    <Loading />
                </div>
            )}

            {selectedMarket?.options?.use_scheduling && selectedSlot?.id && <div className="row overbook">
                <SwitchComponent value={allowOverbook} onChange={(value) => setAllowOverbook(value)} /> Overbook
            </div>}

            {errors && (<div className="row">
                <ErrorsComponent errors={errors} />
            </div>)}

            <div className="ui--controls">
                <button className={selectedMarket?.options?.use_scheduling && selectedSlot?.id || selectedMarket && !selectedMarket?.options?.use_scheduling ? '' : 'disabled'} onClick={handleSchedule}>
                    {selectedMarket?.options?.use_scheduling ? 'Reschedule' : 'Move'}
                </button> <button className="secondary" onClick={() => onClose()}>Cancel</button>
            </div>
        </div>
    </ModalComponent>
}

export default RescheduleModal
