import React from 'react'
import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { OverlayComponent } from '../../Complex'

import { useWindowDimensions } from '../../../hooks'

import './index.css'

const ButtonComponent = ({ name, children, events={}, eventsDOM={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        open: false
    })

    const { width, height } = useWindowDimensions()

    const _ref = React.useRef(null)

    const decorateClassNames = () => {
        let buttonPaletteClassName = `ui-container--button--palette--${config.palette || 'default'}`

        let buttonSizeClassName = 'ui-container--button--size--'

        switch (config.size) {
            case 'tiny':
                buttonSizeClassName += 'tiny'
                break
            case 'small':
                buttonSizeClassName += 'small'
                break
            default:
                buttonSizeClassName += 'default'
                break
        }

        return `${name ? `ui-container--button--${name}` : ''} ${config.classNames || ''} ${buttonPaletteClassName} ${buttonSizeClassName} ${config.noStyle ? 'ui-action--noStyle' : ''}`
    }

    const handleClick = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (events.handleClick)
            events.handleClick(e)
    }

    const handleMouseEnter = () => {
        if (width < 1024)
            return

        _state.set('open', true)
    }

    const handleMouseLeave = () => {
        _state.set('open', false)
    }

    const handleClose = () => {
        _state.set('open', false)
    }

    const decorateEventsDOM = () => {
        const eventsConfig = {...eventsDOM}

        if (eventsConfig.onClick)
            delete eventsConfig.onClick

        return eventsConfig
    }

    const renderOverlay = () => {
        if (!_state.get('open') || !config.tip)
            return

        return (
            <OverlayComponent
                id="buttonOverlay"
                target={_ref.current}
                events={{ handleClose: handleClose }}
                config={{ 
                    position: 'top'
                }}>
                {config.tip}
            </OverlayComponent>
        )
    }

    return (
        <>
            <button ref={_ref} className={`ui-container--button ${decorateClassNames()}`} {...decorateEventsDOM()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} disabled={config.disabled}>{children}</button>
            {renderOverlay()}
        </>
    )

    
}

ButtonComponent.propTypes = {
    children: PropTypes.any,
    
    events: PropTypes.shape({
        handleclick: PropTypes.func,
    }),

    config: PropTypes.shape({
        noStyle: PropTypes.bool,
        classNames: PropTypes.string,
        palette: PropTypes.string,
        disabled: PropTypes.bool
    }),

    eventsDOM: PropTypes.object
}

export { ButtonComponent }