import dayjs from 'dayjs'
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import PageHeaderComponent from '../../components/PageHeader'
import PickerDate from '../../components/PickerDate'
import TableComponent from '../../components/Table'

import FetchHelper from '../../helpers/fetch'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import _ from 'lodash'

import './list.css'

const ReportsPage = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Reports/List')

    const [data, setData] = useState({})

    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

    useEffect(() => {
        fetchList()
    }, [startDate, endDate])

    const fetchList = () => {
        FetchHelper({
            url: `/api/v2/admin/reports`
        }, (res) => {
            setData(res.body)
        }, (res) => {

        })
    }

    const dataFiltered = {
        columns: data.columns || [],
        data: [{
            group: 'default',
            data: data.data ? data.data.map((item, i) => {
                data.columns.map((column, i) => {
                    item[`ui_${column.key}`] = _.get(item, column.key)
                })

                return item
            }) : []
        }]
    }

    return (
        <div className="reports-list-page-container page-container">
            <PageHeaderComponent title={`Reports - List`} />

            <NoticeComponent location="Main" notices={notices} />

            <div className="reports-list-options">
                <div className="reports-list-options-inner">
                    <PickerDate initialValue={startDate} onChange={(value) => setStartDate(value)} dateFormat="YYYY-MM-DD" />
                    <span>—</span>
                    <PickerDate initialValue={endDate} onChange={(value) => setEndDate(value)} dateFormat="YYYY-MM-DD" />
                </div>
            </div>

            <div className="reports-list-container">
                <TableComponent columns={dataFiltered.columns} data={dataFiltered.data} enablePagination={false} enableEditColumns={false} />
            </div>
        </div>
    )
}

export default ReportsPage