import {useEffect, useState} from 'react'

import { ButtonComponent, CheckboxComponent } from '../../../../componentsV2/Primitive'
import MessageComponent from '../../../../components/Message'
import FormComponent from '../../../../components/Form'

import { UI } from '../../../../UI'

import './index.css'
import { FileTypes } from '../../../../sys/constants/enums'


const PromotionalCouponsPartial = ({ value=[], onChange }) => {
    const {_state} = UI.useSimpleState({
        showUpoadForm: false,
        count: 0
    })
    
    const handleChange = (val) => {
        if (onChange) {
            onChange(val || value)
        }

        _state.set('count', _state.get('count') + 1)
    }

    const handleImageAttachment = (data) => {
        let values1 = value

        values1.push({
            coupon_attributes: {
                image_base64: data.image?.value
            }
        })

        handleChange(values1)
    }

    const handleImageDelete = (index) => {
        let values1 = value

        if (values1[index]?.id)
            values1[index]._destroy = true
        else
            values1.splice(index, 1)

        handleChange(values1)
    }

    const handleEmailCheck = (val, index) => {
        let values1 = value

        values1[index].include_in_confirmation_email = val

        handleChange(values1)
    }

    const handlePositionChange = (item, index, direction='up') => {
        if (direction === 'up' && index < 1 || direction === 'down' && index >= value.length - 1)
            return

        const newIndex = index + (direction === 'up' ? -1 : 1)

        let values1 = value
        let firstValue = values1[index]

        values1[index] = values1[newIndex]
        values1[newIndex] = firstValue

        handleChange(values1)
    }

    const renderCouponRow = (item, index) => {
        return (
            !item._destroy ? (
                <div className={`promotional-coupon-row ${item._destroy ? 'disabled' : ''}`} key={`promotional-coupon-row-${index}`}>
                    <div className="arrows">
                        <i className={`bi bi-chevron-compact-up`} onClick={() => handlePositionChange(item, index, 'up')}></i>
                        <i className={`bi bi-chevron-compact-down`} onClick={() => handlePositionChange(item, index, 'down')}></i>
                    </div>

                    <div className="promotional-coupon-details">
                        <div className="promotional-coupon-image">
                            {item?.coupon_attributes?.image_base64 ? (
                                <img src={`data:image/png;base64,${item?.coupon_attributes?.image_base64}`} />
                            ) : (
                                <img src={item?.image?.thumbnail_url} />
                            )}
                        </div>

                        <div className="promotional-coupon-details-options">
                            <div><CheckboxComponent name="include_in_confirmation_email" value={item.include_in_confirmation_email} events={{ handleChange: (value) => handleEmailCheck(value, index) }}/> Include in confirmation email</div>

                            <div>
                                <ButtonComponent config={{
                                        palette: 'red',
                                        tip: 'Delete image?',
                                        size: 'tiny'
                                    }}
                                    events={{ handleClick: () => handleImageDelete(index) }}>
                                    <span className="ui-icon bi bi-trash-fill"></span>
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
        )
    }

    return (
        <div className="promotional-coupon-partial-container">
            <h3>Promotional Coupon Images</h3>

            <MessageComponent message="Image previews will be resized to a width of 300px and must be no larger than 512kb." />

            <div className="promotional-coupon-body">
                <div className="promotional-coupon-details-body">
                    {value.map((item, index) => {
                        return renderCouponRow(item, index)
                    })}
                </div>

                {_state.get('showUploadForm') ? (
                    <div className="promotional-coupon-details-upload">
                        <FormComponent type="stacked" formData={{}} formErrors={{}} onChange={(data) => {
                            if (data.image?.value) {
                                handleImageAttachment(data)
                                _state.set('showUploadForm', false)
                            }
                        }} displayErrorBanner={true} fields={[
                            {
                                label: 'Upload Coupon Image',
                                type: 'input-file',
                                name: 'image',
                                acceptedFileTypes: FileTypes.IMAGES,
                                fileType: 'file'
                            } ]} />
                    </div>
                ) : (
                    <div className="promotional-coupon-details-upload">
                            <ButtonComponent config={{ palette: 'blueLight' }} events={{ handleClick: () => _state.set('showUploadForm', true) }}>Upload Image</ButtonComponent>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PromotionalCouponsPartial