import { useState, useEffect, useCallback } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import { DEFAULT_SEARCH_RESULTS } from '../../sys/constants/MarketGroups'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './edit.css'
import { getCustomCodeFormFields, getGeneralFormFields, getImagesFormFields, getServicesFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const MarketGroupsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/MarketGroups/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()
    const [dataImages, setDataImages] = useState()
    const [dataServices, setDataServices] = useState()
    const [dataCode, setCode] = useState()
    const [subdomain, setSubdomain] = useState('')
    const [colorScheme, setColorScheme] = useState([])
    const [deleteImages, setDeleteImages] = useState([])
    const [inputImages, setInputImages] = useState({
        inputFavicon: false,
        inputZipSplashXs: false,
        inputZipSplashSm: false,
        inputZipSplashMd: false,
        inputZipSplashLg: false
    })
    const dSearchRadius = DEFAULT_SEARCH_RESULTS

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('General', ['General', 'Services', 'Images', 'Custom Code'])

    const [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFields())
    const [servicesFormFields] = useState(getServicesFormFields())
    const [imagesFormFields, setImagesFormFields] = useState(getImagesFormFields())
    const [customCodeFormFields] = useState(getCustomCodeFormFields())

    const isFirstRender = useIsFirstRender()

    const onDeleteFile = useCallback((e) => {
        const name = e.target.name;
        const images = {
            inputFavicon: 'favicon',
            inputZipSplashXs: 'zip_splash_xs',
            inputZipSplashSm: 'zip_splash_sm',
            inputZipSplashMd: 'zip_splash_md',
            inputZipSplashLg: 'zip_splash_lg'
        }

        if (!inputImages[name] === true) {
            setDeleteImages([...deleteImages, images[name]])
        }
        setInputImages({ ...inputImages, [name]: !inputImages[name] })
    }, [inputImages, deleteImages])

    useEffect(() => {
        let fields = getGeneralFormFields(dSearchRadius, colorScheme, subdomain)
        setGeneralFormFields(fields)
    }, [dSearchRadius, colorScheme, subdomain])

    useEffect(() => {
        let fields = getImagesFormFields(dataImages, inputImages, onDeleteFile)
        setImagesFormFields(fields)
    }, [dataImages, inputImages, onDeleteFile])
    
    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'General',
                forms: [{ id: 'Main', fields: generalFormFields }]
            },
            {
                tab: 'Services',
                forms: [{ id: 'Services', fields: servicesFormFields }]
            },
            {
                tab: 'Images',
                forms: [{ id: 'Images', fields: imagesFormFields }]
            },
            {
                tab: 'Custom Code',
                forms: [{ id: 'Custom Code', fields: customCodeFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/market-groups/${params.id}`
        }, (res) => {
            ac.showSpinner(false)

            let basic = {
                name: res.body.name,
                subdomain: res.body.subdomain,
                color_scheme_id: res.body.color_scheme_id,
                default_search_radius: res.body.default_search_radius
            }
            let services = {
                facebook_pixel_id: res.body.facebook_pixel_id,
                tiktok_pixel_id: res.body.tiktok_pixel_id,
                ga_tracking_id: res.body.ga_tracking_id,
                gtm_container_id: res.body.gtm_container_id,
                zapier_service_url: res.body.zapier_service_url,
                zapier_service_enabled: res.body.zapier_service_enabled
            }
            let images = {
                favicon: res.body.favicon,
                zip_splash_lg: res.body.zip_splash_lg,
                zip_splash_md: res.body.zip_splash_md,
                zip_splash_sm: res.body.zip_splash_sm,
                zip_splash_xs: res.body.zip_splash_xs
            }
            setDataMain(basic)
            setDataServices(services)
            setDataImages(images)
            setData(res.body);
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: `/api/v2/admin/color-schemes`
        }, (res) => {
            ac.showSpinner(false)

            const colors = [{ id: null, title: 'System Default', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: item.id,
                    title: item.name,
                    value: item.id
                }
                colors.push(option);
            })
            setColorScheme(colors)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [])

    useEffect(() => {
        if (dataMain) {
            setSubdomain(dataMain.subdomain)
        }
    }, [dataMain])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            ...dataMain,
            ...dataServices
        }

        let images = {}
        const images_attributes = ["favicon", "zip_splash_xs", "zip_splash_sm", "zip_splash_md", "zip_splash_lg"]

        for (var i = 0; i < 5; i++) {
            const img = dataImages[images_attributes[i]]

            if (img && (img.value !== "") && img.value !== data[images_attributes[i]]) {
                images = {
                    ...images,
                    [images_attributes[i] + "_base64"]: img.value
                }

                delete body[images_attributes[i]]
            }

        }

        body = {
            ...body,
            ...images
        }

        if (deleteImages.length > 0) {
            body = {
                ...body,
                delete_images: deleteImages
            }
        }

        FetchHelper({
            url: `/api/v2/admin/market-groups/${params.id}`,
            method: 'PUT',
            body: {
                market_group: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/market-groups')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    const onChangeTab = (tab) => {
        switch (tab) {
            case 'General':
                return (
                    <>
                        <NoticeComponent location="Tab/General" notices={notices} />
                        <FormComponent tab={tab} formData={dataMain} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={generalFormFields} />
                    </>
                )
            case 'Services':
                return (
                    <>
                        <NoticeComponent location="Tab/Services" notices={notices} />
                        <FormComponent tab={tab} formData={dataServices} formErrors={errors} setFormErrors={setErrors} onChange={setDataServices} displayErrorBanner={true} fields={servicesFormFields} />
                    </>
                )
            case 'Images':
                return (
                    <>
                        <NoticeComponent location="Tab/Images" notices={notices} />
                        <FormComponent tab={tab} formData={dataImages} formErrors={errors} setFormErrors={setErrors} onChange={setDataImages} displayErrorBanner={true} fields={imagesFormFields} />
                    </>
                )
            case 'Custom Code':
                return (
                    <>
                        <NoticeComponent location="Tab/CustomCode" notices={notices} />
                        <FormComponent tab={tab} formData={dataCode} formErrors={errors} setFormErrors={setErrors} onChange={setCode} displayErrorBanner={true} fields={customCodeFormFields} />
                    </>
                )
            default:
                break;
        }
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title={`Edit Market Group ${data?.name ? `— ${data.name}` : ''}`}/>
            {data &&
                <>
                    <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
                    <div className="tab-content">
                        {onChangeTab(tab)}
                    </div>
                    <ControlBar baseApiUrl="/market-groups" redirectUrl="/market-groups" cancelUrl="/market-groups" data={data} handleSubmit={handleSubmit} />
                </>
            }
        </div>
    )
}

export default MarketGroupsEdit
