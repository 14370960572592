import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { InputComponent } from '../../Primitive'

import './index.css'

const FormFieldPhoneNumberComponent = ({ name, errors={}, config={}, events={} }) => {
    const {_state} = UI.useSimpleState({
        input: ''
    })

    const formatDisplay = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '')
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`
        }

        return value
    }
      

    return (
        <InputComponent name={name} type="text" value={_state.get('input')} events={{ handleChange: (value) => _state.set('input', value)}} config={{ displayFilter: formatDisplay, hasError: config.hasError }} />
    )
}

FormFieldPhoneNumberComponent.propTypes = {
    name: PropTypes.string.isRequired,

    errors: PropTypes.object,

    config: PropTypes.shape({
        classNames: PropTypes.string,
        displayFilter: PropTypes.func
    }),

    events: PropTypes.shape({
        onMount: PropTypes.func
    }),
}

export { FormFieldPhoneNumberComponent }
