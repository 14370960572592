import {useState, useEffect} from 'react'

import EntityCardComponent from '../../components/EntityCard'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import lodash from 'lodash'

const Sheets = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Sheets/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const baseUrl = '/sheets'
    const baseApiUrl = '/api/v2/admin/sheets'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    const toggleFlag = (item, prop) => {
        // NOTE: This does not clear or even keep search
        ac.showSpinner(true)

        let options = {
            url: `${baseApiUrl}/${item.id}`,
            method: 'PUT',
            body: {
                [prop]: !item[prop]
            }
        }

        FetchHelper(options, (res) => {
            let newList = lodash
                .chain(cachedList)
                .get('data')
                .cloneDeep()
                .map(it => {
                    if (it.id === item.id)
                        it[prop] = !it[prop]
                    return it
                })
                .value()
            setCachedList({ ...cachedList, data: newList })
            
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name_route = `${baseUrl}/${item.id}/edit`
            item.ui_product_preview = <EntityCardComponent entityData={item} entityType="sheet" displayThumbnail={true} />

            return item
        })
    }

    return (
        <div className="sheets-page-container page-container">
            <PageHeaderComponent title="Sheets" actions={[{ title: 'New Sheet', route: '/sheets/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'ui_product_preview',
                        title: 'Preview',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'code',
                        title: 'Code',
                    }
                ]}
                additionalDropdownOptions={[
                    {
                        title: 'Toggle Active State',
                        icon: (item) => {
                            return item.enabled ? 'bi-toggle-on' : 'bi-toggle-off'
                        },
                        type: 'callback',
                        callback: (item) => {
                            toggleFlag(item, 'enabled')
                        }
                    }
                ]} />
        </div>
    )
}

export default Sheets
