import {useState, useEffect} from 'react'

import LoadingComponent from '../Loading'
import PopoverComponent from '../Popover'

import useAppContext from '../../hooks/useAppContext'

import IconPlus from '../../assets/icon-plus.svg'
import FetchHelper from '../../helpers/fetch'
import './index.css'

const Picker = ({ onChange, value = [], apiUrl, sync = true, type }) => {
    const [ac] = useAppContext()

    const [picks, setPicks] = useState(value)
    const [page, setPage] = useState(1)
    const [suggestions, setSuggestions] = useState({
        data: []
    })

    const [isFetchingNewSuggestions, setIsFetchingNewSuggestions] = useState(false)

    const route = type === 'background' ? 'backgrounds' : (type === 'sheet' ? 'sheets' : 'poses')

    useEffect(() => {
        ac.showSpinner(true)
        setIsFetchingNewSuggestions(true)

        FetchHelper({
            url: `/api/v2/admin/${route}?page=${page}`
        }, (res) => {
            ac.showSpinner(false)
            setIsFetchingNewSuggestions(false)
            setSuggestions(res.body)
        }, (res) => {
            ac.showSpinner(false)
            setIsFetchingNewSuggestions(false)
        })
    }, [page])

    const refreshList = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: apiUrl,
            method: 'GET'
        }, (res) => {
            ac.showSpinner(false)
            setPicks(res.body.data)
            onChange(res.body.data)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handlePick = (suggestion) => {
        if (sync) {
            ac.showSpinner(true)

            let body = {}

            body[`${type}_id`] = suggestion.id

            FetchHelper({
                url: apiUrl,
                method: 'POST',
                body: {
                    [type]: body
                }
            }, (res) => {
                ac.showSpinner(false)
                refreshList()
            }, (res) => {
                ac.showSpinner(false)
            })
        } else {
            const newPicks = [...picks, suggestion]

            setPicks(newPicks)
            onChange(newPicks)
        }
    }

    const handleUnpick = (item) => {
        if (sync) {
            ac.showSpinner(true)

            FetchHelper({
                url: item.url,
                method: 'DELETE'
            }, (res) => {
                ac.showSpinner(false)
                refreshList()
            }, (res) => {
                ac.showSpinner(false)
            })
        } else {
            const newPicks = picks.filter((p) => p.id !== item.id)

            setPicks(newPicks)
            onChange(newPicks)
        }
    }

    const changePage = (num) => {
        let p = num

        if (p > suggestions.total_pages) {
            p = suggestions.total_pages
        } else if (p < 1) {
            p = 1
        } else {
            p = num
        }

        setPage(p)
    }

    const handlePreviousPage = () => {
        changePage(page - 1)
    }

    const handleNextPage = () => {
        changePage(page + 1)
    }

    const renderPickContainer = (pick, cta, className='', callback) => {
        return (
            <PopoverComponent position="top" offsetPx={0}>
                <div className={`picker-pick ${className}`} onClick={callback}>
                    <div className="picker-image" style={{ backgroundImage: `url(${pick.image.thumbnail_url})` }}></div>
                    {cta === 'Add'
                        ? <div className="picker-cta"><div className="code">{pick.code}</div><img src={IconPlus} /></div>
                        : <div className="picker-cta"><div className="code">{pick.code}</div><div className="cta">{cta}</div></div>
                    }
                </div>
                <div className="popover-default">
                    <ul className="popover-default-list">
                        <li>
                            <span>Code</span> <em>{pick.code}</em>
                        </li>
                        <li>
                            <span>Name</span> <em>{pick.name}</em>
                        </li>
                    </ul>
                </div>
            </PopoverComponent>
        )
    }

    return (
        <div className={`picker-container ${ac.isLoading ? 'disabled' : ''}`}>
            <div className="picker-suggestions">
                {isFetchingNewSuggestions ? <LoadingComponent show={true} /> : (
                    <>
                        <div className={`control ${page === 1 ? 'disabled' : ''}`}>
                            <i className="bi bi-caret-left-fill" onClick={handlePreviousPage}></i>
                        </div>

                        <div className="options">
                            {suggestions.data.map((suggestion, i) => {
                                return (
                                    <div key={`picker-suggestion-${suggestion.id}-${i}`} className="picker-suggestions-item">
                                        {renderPickContainer(suggestion, 'Add', 'suggestion', () => handlePick(suggestion))}
                                    </div>
                                )
                            })}
                        </div>

                        <div className={`control ${page === suggestions.total_pages ? 'disabled' : ''}`}>
                            <i className="bi bi-caret-right-fill" onClick={handleNextPage}></i>
                        </div>
                    </>
                )}
            </div>
            <ul className="picker-picks">
                {picks.length ? picks.map((item, i) => {
                    return (
                        <li key={`picker-${item.id}-${i}`}>
                            {renderPickContainer(item, 'Remove', 'picked', () => handleUnpick(item))}
                        </li>
                    )
                }) : (
                    <div className="picker-no-picks">Make a selection</div>
                )}
            </ul>
        </div>
    )
}

export default Picker