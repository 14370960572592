import PropTypes from 'prop-types'

import './index.css'

const Box = ({ children, type='base' }) => {
    return (
        <div className={`ui-l-box ui-l-box-${type}`}>
            {children}
        </div>
    )
}

Box.propTypes = {
    type: PropTypes.oneOf(['base'])
}

export { Box }