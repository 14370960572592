import {useState} from 'react'
import {useFloating, computePosition, offset} from '@floating-ui/react-dom';
import lodash from 'lodash'

import './index.css'

const Tooltip = ({ children, icon = 'bi-question-circle-fill', position = 'right'}) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const {x, y, reference, floating, strategy} = useFloating({
        placement: window.innerWidth <= 768 ? 'bottom' : position,
    })

    return (
        <>
            <i ref={reference} className={`bi ${icon} tooltip-icon`} onMouseMove={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}></i>
            {showTooltip ? (
                <div
                    ref={floating}
                    className="tooltip"
                    style={{
                        position: strategy,
                        top: y ?? '',
                        left: x ?? '',
                        zIndex: 2000
                    }}
                    onMouseMove={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <div className='tooltip-view-container'>{children}</div>
                </div>
             ) : null}
        </>
    )
}

export default Tooltip