export const textSize = [
    { value: '', title: 'Default' },
    { value: 'xsmall', title: 'Xsmall' },
    { value: 'medium', title: 'Medium' },
    { value: 'large', title: 'Large' },
    { value: 'xlarge', title: 'Xlarge' }
]
export const textService = [
    { value: '', title: 'None | Do Nothing' },
    { value: 3, title: 'Prepay Sales Are Coming up' },
    { value: 2, title: 'Remember to come' },
    { value: 1, title: 'Santa Confirmation Message' }
]