import { useState, useEffect } from 'react'

import { BulkEntityEditor } from '../../componentsV2/Integrated/BulkEntityEditor'
import ConfirmComponent from '../../components/Confirm'
import DropdownComponent from '../../components/Dropdown'
import Errors from '../../components/Errors'
import FetchHelper from '../../helpers/fetch'
import FiltersComponent from '../../components/Filters'
import FormComponent from '../../components/Form'
import ListComponent from '../../components/List'
import ModalComponent from '../../components/Modal'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import Snippets from '../../components/Snippets'
import TagsComponent from '../../components/Tags'

import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import lodash from 'lodash'

import './index.css'

const MallsPage = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/Markets/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [dataFilters, setDataFilters] = useState({})
    const [filters, setFilters] = useState({})
    const [showFilters, setShowFilters] = useState(false)
    const [showTags, setShowTags] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalItem, setModalItem] = useState()
    const [modalType, setModalType] = useState('copy')

    const [suggestionsMarketGroupsPage, setSuggestionsMarketGroupsPage] = useState(1)
    const [suggestionsMarketGroups, setSuggestionsMarketGroups] = useState([])

    const baseUrl = '/markets'
    const baseApiUrl = '/api/v2/admin/markets'

    // Modal — Copy
    const [errorsCopy, setErrorsCopy] = useState()
    const [dataCopy, setDataCopy] = useState()
    const [disableModalButtons, setDisableModalButtons] = useState(false)

    // Modal - Email
    const [dataEmail, setDataEmail] = useState({
        email_recipient: '',
        email_subject: '',
        snippet: 'marketing',
        attach_snippet: true,
        customer_csv: true,
        customer_csv_range: 0,
        message: ''
    })

    // Modal - bulk edit
    const [bulkEditFormFields, setBulkEditFormFields] = useState([])
    const [bulkEditFieldsFormData, setBulkEditFieldsFormData] = useState()
    const [bulkEditFieldsFormErrors, setBulkEditFieldsFormErrors] = useState()

    useEffect(() => {
        setDefaultsEmail()
    }, [])

    const handleFilterOnSelect = (filters) => {
        // --- prevent state changes if filters are unmodified (eg. everything is set to "All" initially) 
        if(
            lodash.isEmpty(dataFilters) &&
            lodash.every(filters, value => !value)
        ) return;

        // --- prevent state changes if no filters have changed
        if(lodash.isEqual(filters, dataFilters)) return;

        setPage(1)
        setDataFilters(filters)
    }

    const handleSearchAfterFetch = (results) => {
        const list = listDecorator(results)

        setList(list)
        setCachedList(list)
    }

    const handleListAfterColumnTitleClick = () => {

    }

    const listDecorator = (results) => {
        return {
            ...results,
            data: results.data.map((row) => {
                row.ui_name = row.name
                row.ui_enable_notes = row.notes
                row.ui_enable_email = row.email
                row.ui_enable_scheduling = row.scheduling
                row.ui_name_route = `${baseUrl}/${row.id}/edit`
                row.ui_format_route = `${baseUrl}/${row.id}/preview`
                row.ui_url = `${baseUrl}/${row.id}/view`
                row.ui_market_group_name = row.market_group?.name
                row.ui_location_group_name = row.location_group?.name

                const now = new Date()
                const startsAt = new Date(row.starts_at)
                const endsAt = row.ends_at && new Date(row.ends_at)
                const globalExpiration = results.global_expiration && new Date(results.global_expiration)

                if (endsAt && endsAt > now) {
                    row.ui_ends = UtilsHelper.toDateString({ date: endsAt, timezone: row.timezone, displayTimezone: true, displayTime: true })
                } else if(!endsAt && !globalExpiration) {
                    row.ui_ends = 'Active'
                } else {
                    row.ui_ends = startsAt < now && (now < (endsAt || globalExpiration)) ? 'Active' : 'Closed'
                }

                return row
            })
        }
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.filter((item) => {
            let unfiltered = true

            tags.active.every((tag) => {
                if (item.tags.indexOf(tag) === -1) {
                    unfiltered = false
                }

                return tag
            })

            return unfiltered
        })
    }

    const prepareModalData = (item, type) => {
        switch(type) {
            case 'bulkEdit':
                prepareBulkEditFormFields()
                prepareBulkEditFormData(item)
                break;

            case 'copy':
                setDataCopy({
                    package_plan_id: item.package_plan_id,
                    marketing_plan_id: item.marketing_plan_id,
                    market_group_id: item.market_group_id,
                    location_group_id: item.location_group_id,
                })
                break;

            default:
                break;
        }
    }

    const handleModal = (item, type) => {
        setModalType(type)
        setModalItem(item)
        prepareModalData(item, type)
        setShowModal(true)
    }

    const handleSubmitCopy = () => {
        setDisableModalButtons(true)
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/markets/${modalItem.id}/copy`,
            method: 'post',
            body: dataCopy
        }, (res) => {
            ac.showSpinner(false)
            setShowModal(false)
            setDisableModalButtons(false)
        }, (res) => {
            ac.showSpinner(false)
            setDisableModalButtons(false)

            if (res.status === 422) {
                setErrorsCopy(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    const handleSubmitEmail = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/markets/${modalItem.id}/email`,
            method: 'post',
            body: dataEmail
        }, (res) => {
            ac.showSpinner(false)
            setShowModal(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const enableEmailModalButton = () => {
        return dataEmail.message && dataEmail.message !== ''
            && dataEmail.email_subject && dataEmail.email_subject !== ''
            && dataEmail.email_recipient && dataEmail.email_recipient !== ''
    }

    const setDefaultsEmail = () => {
        // To include variables when profiles/auth implemented
        setDataEmail({
            ...dataEmail,
            email_recipient: 'bfox@foxmar.com',
            email_subject: 'Development Studio Santa FastPass icon on your website.',
            message: ``
        })
    }

    const prepareBulkEditFormFields = () => {
        const bulkEditFormFields = [
            {
                type: 'input-text',
                label: 'Market secret code',
                name: 'secret_code',
                canToggle: true,
                offerClearBtn: true,
            },
            {
                type: 'wysiwyg',
                label: 'Scheduling policy',
                label_info: 'Please constrain your content to 600x100, anything outside those constraints will hidden on the appointment confirmation.',
                name: 'scheduling_policy',
                canToggle: true,
                offerClearBtn: true,
                editor: null
            },
            {
                type: 'wysiwyg',
                label: 'Scheduling extra text',
                label_info: 'This will only appear on the page where customers select their reservation date/times.',
                name: 'scheduling_extra_text',
                canToggle: true,
                offerClearBtn: true,
                editor: null
            },
            {
                type: 'wysiwyg',
                name: 'special_instructions',
                label: 'Special Instructions',
                canToggle: true,
                offerClearBtn: true,
                editor: null
            },
            {
                type: 'wysiwyg',
                label: 'Market confirmation message',
                name: 'market_confirmation_message',
                canToggle: true,
                offerClearBtn: true,
            },
            {
                type: 'wysiwyg',
                label: 'Email collection message',
                name: 'email_collection_message',
                canToggle: true,
                offerClearBtn: true,
            },
            {
                type: 'switch',
                label: 'Self service enabled',
                name: 'self_service_enabled',
                canToggle: true,
                offerClearBtn: true,
            },
            {
                type: 'select',
                label: 'Email collection method',
                name: 'email_collection_method',
                canToggle: true,
                offerClearBtn: true,
                options: [
                    {
                        title: 'Opt Out',
                        value: 1
                    },
                    {
                        title: 'Opt in',
                        value: 2
                    },
                    {
                        title: 'Opt in w/ Age verification',
                        value: 3
                    }
                ]
            },
            {
                type: 'select',
                label: 'Email collection default',
                name: 'email_collection_default',
                canToggle: true,
                offerClearBtn: true,
                options: [
                    {
                        title: 'Unchecked',
                        value: false
                    },
                    {
                        title: 'Checked',
                        value: true
                    }
                ]
            },
            {
                type: 'tags',
                name: 'tags',
                label: 'Tags',
                suggestions: tags.all,
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'select-paginated',
                name: 'market_group_id',
                label: 'Market Group',
                apiUrl: '/api/v2/admin/market-groups',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'select-paginated',
                name: 'location_group_id',
                label: 'Location Group',
                apiUrl: '/api/v2/admin/location-groups',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'input-money',
                name: 'tax',
                label: 'Tax',
                value: '0.0',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'input-money',
                name: 'fee_adjustment',
                label: 'Fee adjustment',
                value: '0.0',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'input-money',
                name: 'snh',
                label: 'Shipping & Handling',
                value: '0.0',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'datetime',
                name: 'starts_at',
                label: 'Start Date',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'datetime',
                name: 'ends_at',
                label: 'Expiration Date',
                label_info: 'This will override global setting',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'select-paginated',
                name: 'package_plan_id',
                label: 'Package Plan',
                apiUrl: '/api/v2/admin/package-plans',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'select-paginated',
                name: 'marketing_plan_id',
                label: 'Marketing Plan',
                apiUrl: '/api/v2/admin/marketing-plans',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'select-paginated',
                name: 'color_scheme_id',
                label: 'Color Scheme',
                apiUrl: '/api/v2/admin/color-schemes',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'select-paginated',
                name: 'payment_gateway_id',
                label: 'Payment Account',
                apiUrl: '/api/v2/admin/payment-accounts',
                canToggle: true,
                offerClearBtn: true
            },
            {
                type: 'select-paginated',
                name: 'vanity_domain_id',
                label: 'Vanity Domain',
                apiUrl: '/api/v2/admin/vanity-domains',
                canToggle: true,
                offerClearBtn: true
            }
        ]
        setBulkEditFormFields(bulkEditFormFields)
    }

    const prepareBulkEditFormData = (selectedMarket) => {
        const bulkEditFieldsFormData = {
            special_instructions: selectedMarket.special_instructions,
            tags: selectedMarket.tags,
            market_group_id: selectedMarket.market_group_id,
            tax: selectedMarket.tax,
            fee_adjustment: selectedMarket.fee_adjustment,
            snh: selectedMarket.snh,
            starts_at: selectedMarket.starts_at ? selectedMarket.starts_at.replace(/(-\d{1,2}:\d{1,2})$/, '') : undefined,
            ends_at: selectedMarket.ends_at ? selectedMarket.ends_at.replace(/(-\d{1,2}:\d{1,2})$/, '') : undefined,
            package_plan_id: selectedMarket.package_plan_id,
            marketing_plan_id: selectedMarket.marketing_plan_id,
            color_scheme_id: selectedMarket.color_scheme_id,
            payment_gateway_id: selectedMarket.payment_gateway_id,
            vanity_domain_id: selectedMarket.vanity_domain_id,
            secret_code: selectedMarket.secret_code,
            scheduling_policy: selectedMarket.scheduling_policy,
            scheduling_extra_text: selectedMarket.scheduling_extra_text,
            market_confirmation_message: selectedMarket.market_confirmation_message,
            email_collection_message: selectedMarket.email_collection_message,
            self_service_enabled: selectedMarket.options.self_service_enabled,
            email_collection_method: selectedMarket.options.email_collection_method,
            email_collection_default: selectedMarket.options.email_collection_default,
            location_group_id: selectedMarket.location_group_id
        }
        setBulkEditFieldsFormData(bulkEditFieldsFormData)
        setBulkEditFieldsFormErrors(null)
    }

    const handleBulkEditSubmit = async (selectedMarkets) => {
        let fieldsToConsider = bulkEditFormFields.filter(field => field.readonly !== true)
        console.log("fieldsToConsider", fieldsToConsider)
        
        if (fieldsToConsider.length == 0) return true;
        if (lodash.isEmpty(selectedMarkets)) return true;
        
        // --- prepare request body
        let reqBody = { bulk: {} }
        reqBody.bulk.data = fieldsToConsider.reduce((ac, field) => {
            ac[field.name] = bulkEditFieldsFormData[field.name]
            return ac;
        }, {});
        reqBody.bulk.market_ids = selectedMarkets.map(m => m.id)

        let [bulkUpdateRes, bulkUpdateErr] = await UtilsHelper.executePromise(
            FetchHelper({
                url: modalItem.bulk_url,
                method: 'put',
                body: reqBody,
            })
        ) 

        // --- errors handling
        if (bulkUpdateErr) {
            setBulkEditFieldsFormErrors(bulkUpdateErr)
            return false
        }

        return true;
    }

    const renderModal = () => {
        if (!modalItem) {
            return null
        }

        switch (modalType) {
            case 'bulkEdit':
                return (
                    <BulkEntityEditor title={modalItem.name} events={{ handleClose: () => setShowModal(false), handleSubmit: handleBulkEditSubmit }} 
                        formFields={bulkEditFormFields} setFormFields={setBulkEditFormFields}
                        fieldsFormData={bulkEditFieldsFormData} setFieldsFormData={setBulkEditFieldsFormData}
                        fieldsFormErrors={bulkEditFieldsFormErrors} offerClearAllBtn={true}
                        />
                )
            case 'copy':
                return (<ModalComponent handleClose={() => setShowModal(false)}>
                    <div className='flex flex-col min-h-0 max-h-full'>
                        <div className='flex-shrink-0'>
                            <em>Copy {modalItem.name}</em>
                        </div>
                        <FormComponent formData={dataCopy} formErrors={errorsCopy} onChange={setDataCopy} type="stacked" displayErrorBanner={true} fields={[{
                            type: 'input-text',
                            name: 'name',
                            label: 'Name'
                        }, {
                            type: 'select',
                            name: 'type',
                            label: 'Type',
                            value: 'Santa',
                            options: [
                                { title: 'Santa', value: 'Santa' },
                                { title: 'Bunny', value: 'Bunny' },
                                { title: 'Dance', value: 'Dance' },
                                { title: 'Generic', value: 'Generic' }
                            ]
                        }, {
                            type: 'select',
                            name: 'scheduling',
                            label: 'Scheduling',
                            value: 'true',
                            options: [
                                { title: 'Yes', value: true },
                                { title: 'No', value: false }
                            ]
                        }, {
                            type: 'select',
                            name: 'important_dates',
                            label: 'Important Dates',
                            value: 'true',
                            options: [
                                { title: 'Yes', value: true },
                                { title: 'No', value: false }
                            ]
                        }, {
                            type: 'select-paginated',
                            name: 'package_plan_id',
                            label: 'Package Plan',
                            apiUrl: '/api/v2/admin/package-plans',
                        }, {
                            type: 'select-paginated',
                            name: 'marketing_plan_id',
                            label: 'Marketing Plan',
                            apiUrl: '/api/v2/admin/marketing-plans'
                        }, {
                            type: 'select-paginated',
                            label: 'Market Group',
                            name: 'market_group_id',
                            title: 'Market Group',
                            apiUrl: '/api/v2/admin/market-groups',
                            offerClearBtn: true
                        }, {
                            type: 'select-paginated',
                            name: 'location_group_id',
                            label: 'Location Group',
                            apiUrl: '/api/v2/admin/location-groups',
                            offerClearBtn: true
                        }]} />
                        <div className='text-center flex-shrink-0'>
                            <button onClick={handleSubmitCopy} disabled={disableModalButtons}>Copy</button>
                        </div>
                    </div>
                </ModalComponent>)
            case 'email':
                return (<ModalComponent handleClose={() => setShowModal(false)}>
                    <div className="email">
                        <div className="row"><em>Email {modalItem.name}</em></div>

                        <div className="row">To</div>
                        <div className="row">
                            <input type="text" className="recipient" value={dataEmail.email_recipient} onChange={(e) => setDataEmail({ ...dataEmail, email_recipient: e.target.value })} />
                        </div>

                        <div className="row">Subject</div>
                        <div className="row">
                            <input type="text" className="subject" value={dataEmail.email_subject} onChange={(e) => setDataEmail({ ...dataEmail, email_subject: e.target.value })} />
                        </div>

                        <div className="row">Options</div>
                        <div className="row">
                            <div><input type="radio" value="marketing" onChange={(e) => setDataEmail({ ...dataEmail, snippet: 'marketing' })} checked={dataEmail.snippet === 'marketing'} /> Marketing Page Snippet <input type="radio" value="packages" onChange={(e) => setDataEmail({ ...dataEmail, snippet: 'packages' })} checked={dataEmail.snippet === 'packages'} /> Marketing Page Snippet </div>
                            <div><input type="checkbox" checked={dataEmail.attach_snippet} onChange={(e) => setDataEmail({ ...dataEmail, attach_snippet: e.target.checked })} /> Attach Snippet </div>
                            <div><input type="checkbox" checked={dataEmail.customer_csv} onChange={(e) => setDataEmail({ ...dataEmail, customer_csv: e.target.checked })} /> Attach Customer CSV starting from <input type="text" className="customer-csv-range" value={dataEmail.customer_csv_range} disabled={!dataEmail.customer_csv} onChange={(e) => setDataEmail({ ...dataEmail, customer_csv_range: e.target.value })} /></div>
                        </div>

                        <div className="row">Message</div>
                        <div className="row">
                            <div>
                                <Snippets target="malls-page-email-modal" options={[
                                    {
                                        title: 'Add QR Code',
                                        snippet: '::qr-code-image:: ::qr-code-link::',
                                        icon: 'bi-qr-code'
                                    },
                                    {
                                        title: 'Add Market Link',
                                        snippet: '::market-url::',
                                        icon: 'bi-shop'
                                    },
                                    {
                                        title: 'Add Snippet',
                                        snippet: '::snippet::',
                                        icon: 'bi-braces-asterisk'
                                    }
                                ]} /></div>
                            <div>
                                <textarea id="malls-page-email-modal" value={dataEmail.message} onChange={(e) => setDataEmail({ ...dataEmail, message: e.target.value })} />
                            </div>
                        </div>

                        <div className="row">
                            <button onClick={handleSubmitEmail} disabled={!enableEmailModalButton()}>Send</button> <button onClick={setDefaultsEmail}>Restore Default</button>
                        </div>
                    </div>
                </ModalComponent>)
            case 'delete':
                return <ConfirmComponent apiUrl={`${baseApiUrl}/${modalItem.id}`} method='DELETE' title={`Delete the following?`} entityName={modalItem.name} cancel={() => setShowModal(false)} />

        }
    }

    return (
        <div className="malls-page-container page-container">
            {showModal && renderModal()}
            <PageHeaderComponent title="Markets" actions={[{ title: 'New Market', route: '/markets/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} filters={dataFilters} showFilters={showFilters} setShowFilters={setShowFilters} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            {showFilters ? (
                <FormComponent formData={dataFilters} formErrors={errorsCopy} onChange={handleFilterOnSelect} type="stacked" displayErrorBanner={true} fields={[
                    {
                        type: 'select',
                        label: 'Theme',
                        name: 'theme',
                        options: [
                            {
                                title: 'All',
                                value: ''
                            }, {
                                title: 'Santa',
                                value: 'santa'
                            }, {
                                title: 'Bunny',
                                value: 'bunny'
                            }, {
                                title: 'Dance',
                                value: 'dance'
                            }, {
                                title: 'Generic',
                                value: 'generic'
                            }
                        ]
                    }, {
                        type: 'select',
                        label: 'Purchase Format',
                        name: 'purchase_format',
                        options: [
                            {
                                title: 'All',
                                value: ''
                            }, {
                                title: 'Fast Pass',
                                value: 'fastpass'
                            }, {
                                title: 'Pre Pay',
                                value: 'prepay'
                            }
                        ]
                    }, {
                        type: 'select',
                        label: 'State',
                        name: 'state',
                        options: [{
                            title: 'All',
                            value: ''
                        }, {
                            title: 'Active',
                            value: 'active' // not-expired
                        }, {
                            title: 'Closed',
                            value: 'closed' // expired
                        }]
                    }, {
                        type: 'select-paginated',
                        label: 'Market Group',
                        name: 'market_group_id',
                        title: 'Market Group',
                        apiUrl: '/api/v2/admin/market-groups',
                    },
                ]} />
            ) : null}

            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl} displayNameOnSeparateLine={true} afterColumnTitleClick={handleListAfterColumnTitleClick}
                columns={[
                    {
                        key: 'ui_name',
                        title: 'Name',
                        sortable: true,
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'ui_market_group_name',
                        title: 'Group',
                        sortable: true
                    },
                    {
                        key: 'external_id',
                        title: 'Code',
                        sortable: true
                    },
                    {
                        key: 'type',
                        title: 'Theme',
                        sortable: true
                    },
                    {
                        key: 'purchase_format',
                        title: 'Format',
                        sortable: true,
                        type: 'url_route',
                        route: 'ui_format_route'
                    },
                    {
                        key: 'ui_ends',
                        title: 'Ends'
                    }
                ]} customActions={(item) => {
                    let opts = [
                        {
                            title: 'Proofing',
                            type: 'route',
                            url: `${baseUrl}/${item.id}/edit?tab=Proofing`
                        },
                        {
                            title: 'Edit',
                            type: 'route',
                            url: `${baseUrl}/${item.id}/edit`
                        },
                        {
                            title: 'Copy',
                            type: 'callback',
                            callback: () => handleModal(item, 'copy')
                        },
                        {
                            title: 'Delete',
                            type: 'callback',
                            callback: () => handleModal(item, 'delete')
                        }]

                    if (item.sale_url) {
                        opts.unshift({
                            title: 'Public Link',
                            type: 'url',
                            url: item.sale_url,
                            icon: 'bi-box-arrow-up-right'
                        })
                    }

                    if (item.options?.use_scheduling) {
                        opts.unshift({
                            title: 'Scheduling',
                            type: 'route',
                            url: `${baseUrl}/${item.id}/edit?tab=Scheduling`
                        })
                    }

                    opts.unshift({
                        title: 'Bulk Edit',
                        type: 'callback',
                        callback: () => handleModal(item, 'bulkEdit'),
                        icon: 'bi-pencil-square'
                    })

                    // if (item.contact_emails.length) {
                    //     opts.splice(0, 0, {
                    //         title: 'Email',
                    //         type: 'callback',
                    //         callback: () => handleModal(item, 'email')
                    //     })
                    // }

                    return <DropdownComponent title="Actions" options={opts} isListView={true} />
                }} />
        </div>
    )
}

export default MallsPage
