export function getBasicFormFields(suggestionsTags = []) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name',
      placeholder: 'Package A with Splash'
    },
    {
      type: 'tags',
      name: 'tags',
      label: 'Tags',
      suggestions: suggestionsTags
    },
    {
      type: 'input-text',
      name: 'display_name',
      label: 'Display Name',
      placeholder: 'Package A'
    },
    {
      type: 'input-text',
      name: 'code',
      label: 'Code',
      placeholder: 'AWS'
    },
    {
      type: 'input-text',
      name: 'lab_code',
      label: 'Lab Code',
      placeholder: 'Lab Code',
      field_info: 'Lab Code takes priority over Code when sending data to lab for fulfillment.'
    },
    {
      type: 'input-money',
      name: 'value',
      label: 'Default Price',
      placeholder: '10.00',
      step: 0.05
    },
    {
      type: 'input-money',
      name: 'shipping_premium',
      label: 'Shipping Premium',
      value: '0.0',
      step: 0.05
    },
    {
      type: 'switch',
      name: 'shipping_exempt',
      label: 'Shipping Exempt'
    },
    {
      type: 'switch',
      name: 'late_exempt',
      label: 'Late Exempt?'
    },
    {
      type: 'switch',
      name: 'tax_exempt',
      label: 'Tax Exempt'
    },
    {
      type: 'switch',
      name: 'intangible',
      label: 'Intangible?'
    },
    {
      type: 'input-number',
      name: 'limit_per',
      label: 'Limit per order',
      value: '0',
      field_info: '0 will allow for an unlimited quantity'
    }
  ];
}

export function getDescriptionFormFields() {
  return [
    {
      type: 'select',
      name: 'use_image_for_description',
      label: 'Use image for description?',
      label_info: 'Optimal size is 800x300px',
      value: 'false',
      options: [
        {
          title: 'Yes',
          value: 'true'
        },
        {
          title: 'No',
          value: 'false'
        }
      ]
    },
    {
      type: 'wysiwyg',
      name: 'description',
      label: 'Description',
      condition: (state) => {
        return state.use_image_for_description === 'false';
      }
    }
  ];
}

export function getHelperFormFields() {
  return [
    {
      type: 'input-text',
      name: 'helper_caption',
      label: 'Helper Caption'
    },
    {
      type: 'wysiwyg',
      name: 'helper_content',
      label: 'Helper Content'
    }
  ];
}

export function getDigitalDeliveryFormFields() {
  return [
    {
      type: 'switch',
      name: 'digitally_deliverable',
      label: 'Digitally Deliverable?'
    }
  ];
}
