import PropTypes from 'prop-types'

import './index.css'

const LabelComponent = ({ children, config={} }) => {
    return (
        <div className={`ui-container--label text-slate-500 ${config.classNames || ''}`}>
            <label htmlFor={config.htmlFor}>{children} {config.isRequired && <span className="ui--labelAsterisk">*</span>}</label>
        </div>
    )
}

LabelComponent.propTypes = {
    children: PropTypes.string.isRequired,

    htmlFor: PropTypes.string.isRequired,

    config: PropTypes.shape({
        classNames: PropTypes.string,
        isRequired: PropTypes.bool,
    }),
}

export { LabelComponent }