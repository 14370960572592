import { useState, useEffect } from 'react'

import {
    Notice,
    PageHeader,
    Table,
    PickerDate
} from '../../components'

import { Fetch } from '../../helpers'

import { API } from '../../helpers'

import { $ } from '../../packages'

import {
    useAppContext,
    useNotices,
    useDynamicState
 } from '../../hooks'

import './index.css'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import UtilsHelper from '../../helpers/utils'

const ReportsPage = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Reports/Index') // @TODO: Need to export this hook as object

    const { _get, _set, _mounted } = useDynamicState({
        data: {
            entity: {
                reports: []
            },
            form: {
                startDate: $.dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
                endDate: $.dayjs().format('YYYY-MM-DD'),
                button: '',
                listUrl: ''
            },
            list: {
                main: {},
                earnings: {}
            },
            options: {
                markets: []
            }
        }
    })

    useEffect(() => {
        // --- add page specific class to body element
        document.body.classList.add('reports-page-body')

        Fetch({
            url: `/api/v2/admin/reports`
        }, (res) => {
            _set('data.entity', { reports: res.body }, () => {
                API.getMarkets(({ markets }) => {
                    _set('data.options', { markets })
                })
            })
        }, (res) => {

        })

        return () => {
            // --- remove page specific class to body element
            document.body.classList.remove('reports-page-body')
        }
    }, [])

    useEffect(() => {
        if (_get('data.form').startDate && _get('data.form').endDate && _get('data.form').listUrl) {
            fetchList()
        }
    }, [_get('data.form').startDate, _get('data.form').endDate, _get('data.form').listUrl])

    const fetchList = () => {
        ac.showSpinner(true)

        _set('data.list', {
            main: [],
            earnings: []
        })

        Fetch({
            url: `${_get('data.form').listUrl}?start_date=${_get('data.form').startDate}&end_date=${_get('data.form').endDate}`
        }, (res) => {
            ac.showSpinner(false)

            if (_get('data.form').button === 'Earnings') {
                _set('data.list', { earnings: res.body })
            } else {
                _set('data.list', { main: res.body })
            }
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleExcel = (data) => {
        const tableData = { ...data, data: data.data[0].data }

        let timezone = ac.AuthHelper.settings.timezone;
        let excelData = UtilsHelper.tableDataToExcelCompatibleArray(
            tableData,
            true,
            {
                date: (value) => UtilsHelper.dayjsSafeFormat(value, 'YYYY-MM-DD', timezone),
                time: (value) => UtilsHelper.dayjsSafeFormat(value, 'h:mm A', timezone),
                datetime: (value) => UtilsHelper.dayjsSafeFormat(value, 'YYYY-MM-DD h:mm A', timezone)
            }
        )

        // title row
        let title = `${_get('data.form').button} - Start ${_get('data.form').startDate} through End ${_get('data.form').endDate}`
        excelData.unshift([title])

        // prepare worksheet configs
        let worksheetConfigs = {};
        worksheetConfigs['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: Object.keys(excelData[1]).length - 1 } } // merge title row
        ]

        UtilsHelper.exportToExcel(excelData, title, worksheetConfigs)
    }

    const handleCSV = (data) => {
        const tableData = { ...data, data: data.data[0].data }
        
        let timezone = ac.AuthHelper.settings.timezone
        let dataArray = UtilsHelper.tableDataToCSVCompatibleArray(
            tableData,
            true,
            { 
                date: (value) => UtilsHelper.dayjsSafeFormat(value, 'YYYY-MM-DD', timezone),
                time: (value) => UtilsHelper.dayjsSafeFormat(value, 'h:mm A', timezone),
                datetime: (value) => UtilsHelper.dayjsSafeFormat(value, 'YYYY-MM-DD h:mm A', timezone)
            }
        )
        let csvData = UtilsHelper.arrayToCsv(dataArray)
        let filename = `${_get('data.form').button} - Start ${_get('data.form').startDate} through End ${_get('data.form').endDate}`
        UtilsHelper.exportToCsv(csvData, filename)
    }

    const handlePDF = () => {
        let jsPDFInstance = new jsPDF()

        // add page title
        let title = `${_get('data.form').button} - Start ${_get('data.form').startDate} through End ${_get('data.form').endDate}`
        jsPDFInstance.text(title, 15, 10)

        autoTable(jsPDFInstance, { 
            html: '.table-container-wrapper .table'
        })

        jsPDFInstance.save(`${title}.pdf`)
    }
    
    const handlePrint = () => {
        window.print()
    }

    const renderListMain = () => {
        if (!_get('data.list').main.data)
            return

        let dataFiltered = {
            columns: [],
            data: []
        }

        const dataListMain = _get('data.list').main

        if (dataListMain.data) {
            dataFiltered = {
                columns: dataListMain.columns,
                data: [{
                    group: 'default',
                    data: dataListMain.data.map((item, i) => {
                        dataListMain.columns.map((column, i) => {
                            item[`ui_${column.key}`] = $._.get(item, column.key)
                        })

                        return item
                    })
                }]
            }
        }

        return (
            <div className={`reports-list-container ${ac.isLoading ? 'disabled' : ''}`}>
                <div className="reports-list-export-options">
                    <button onClick={() => handleExcel(dataFiltered)}>Excel</button>
                    <button onClick={() => handleCSV(dataFiltered)}>CSV</button>
                    <button onClick={handlePDF}>PDF</button>
                    <button onClick={handlePrint}>Print</button>
                </div>

                {/* title row */}
                <h2 className='text-base font-bold print-only printable'>{`${_get('data.form').button} - Start ${_get('data.form').startDate} through End ${_get('data.form').endDate}`}</h2>

                <Table columns={dataFiltered.columns} data={dataFiltered.data} enablePagination={false} enableEditColumns={false} />

                {!_get('data.list').main.data.length ? <div className="no-results">No results</div> : null}
            </div>
        )
    }

    const renderListEarnings = () => {
        if (!_get('data.list').earnings.data)
            return

        return (
            <div className={`reports-list-earnings-container ${ac.isLoading ? 'disabled' : ''}`}>
                <div>
                    {/* Today */}
                    <div className="ui-card">
                        <div className="ui-card--header">
                            <h2>Today { dayjs().format('MM/DD/YYYY hh:mm A z') }</h2>
                        </div>
                        
                        <div className='ui-card--content'>
                            <p className='text-base'>${_get('data.list').earnings.data.today_fee_value}</p>
                            <p className='text-base'>{_get('data.list').earnings.data.today_order_count} orders</p>

                            <div className='flex flex-wrap justify-evenly'>
                                <div className='ui-card sub-card'>
                                    <div className='ui-card--header'>
                                        <h2>Last Year (Same Day) { dayjs().subtract(1, 'year').day(dayjs().day()).format('ddd, MM/DD/YYYY') }</h2>
                                    </div>

                                    <div className='ui-card--content'>
                                        <p className='text-base'>{_get('data.list').earnings.data.day_order_count} orders</p>
                                    </div>
                                </div>

                                <div className='ui-card sub-card'>
                                    <div className='ui-card--header'>
                                        <h2>Last Year (Same Date) { dayjs().subtract(1, 'year').format('ddd, MM/DD/YYYY') }</h2>
                                    </div>

                                    <div className='ui-card--content'>
                                        <p className='text-base'>{_get('data.list').earnings.data.date_order_count} orders</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Totals */}
                    <div className="ui-card">
                        <div className="ui-card--header">
                            <h2>Totals: {dayjs(_get('data.list').earnings.statistics.range_start).format('MM/DD/YYYY')} - {dayjs(_get('data.list').earnings.statistics.range_end).format('MM/DD/YYYY')}</h2>
                        </div>
                        
                        <div className='ui-card--content'>
                            <div className='flex flex-wrap justify-evenly'>
                                <div className='ui-card sub-card'>
                                    <div className='ui-card--header'>
                                        <h2>Current: {_get('data.list').earnings.data.total_order_count} orders</h2>
                                    </div>

                                    <div className='ui-card--content text-right'>
                                        <p className='text-base'>${_get('data.list').earnings.data.total_fee_value}</p>
                                        <p className='text-base'>+ ${_get('data.list').earnings.data.total_prem_fee_val}</p>
                                        <hr />
                                        <p className='text-base'>${Number(_get('data.list').earnings.data.total_fee_value) + Number(_get('data.list').earnings.data.total_prem_fee_val)}</p>
                                    </div>
                                </div>

                                <div className='ui-card sub-card'>
                                    <div className='ui-card--header'>
                                        <h2>Previous: {_get('data.list').earnings.data.total_order_count_prev} orders</h2>
                                    </div>

                                    <div className='ui-card--content text-right'>
                                        <p className='text-base'>${_get('data.list').earnings.data.total_fee_value_prev}</p>
                                        <p className='text-base'>+ ${_get('data.list').earnings.data.total_prem_fee_val_prev}</p>
                                        <hr />
                                        <p className='text-base'>${Number(_get('data.list').earnings.data.total_fee_value_prev) + Number(_get('data.list').earnings.data.total_prem_fee_val_prev)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderReportsButtons = () => {
        if (!_get('data.entity').reports.length)
            return

        return (
            <div className={`reports-buttons-container ${ac.isLoading ? 'disabled' : ''}`}>
                <div className="reports-buttons reports-buttons-general">
                    <ul className="reports-buttons-list">
                        {_get('data.entity').reports.map((dataReportsItem, dataReportsIndex) => {
                            return (
                                <li key={`reports-button-${dataReportsItem.title}`}>
                                    <div onClick={() => {
                                        _set('data.form', {
                                            button: dataReportsItem.title,
                                            listUrl: dataReportsItem.url
                                        })
                                    }} className={`reports-button ${_get('data.form').button === dataReportsItem.title ? 'selected' : ''}`}>
                                        <i className={`bi ${dataReportsItem.icon}`}></i> {dataReportsItem.title}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    const renderReportsDates = () => {
       return (
            <div className="reports-dates-container">
                <PickerDate
                    initialValue={_get('data.form').startDate}
                    dateFormat="YYYY-MM-DD"
                    onChange={(value) => {
                        _set('data.form', { startDate: value })
                    }} />
                
                <span className="bi bi-dash-lg"></span>
                
                <PickerDate
                    initialValue={_get('data.form').endDate}
                    dateFormat="YYYY-MM-DD"
                    onChange={(value) => {
                        _set('data.form', { endDate: value })
                    }} />
            </div>
       )
    }

    if (!_mounted)
        return

    return (
        <div className="reports-index-page-container page-container">
            <PageHeader title={`Reports`} />

            <Notice location="Main" notices={notices} />

            {renderReportsDates()}

            {renderReportsButtons()}
            
            {renderListMain()}

            {renderListEarnings()}
        </div>
    )
}

export default ReportsPage