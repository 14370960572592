import lodash from 'lodash';
import { useEffect, useState } from 'react';
import {
  ControlBar,
  FieldSelector,
  Form,
  PageHeader
} from '../../../components';
import { Utils } from '../../../helpers';
import RouteHelper from '../../../helpers/route';
import {
  useAppContext,
  useDraft,
  useFetch,
  useNotices,
  useTabs
} from '../../../hooks';
import useIsFirstRender from '../../../hooks/useIsFirstRender';
import {
  getGeneralFormFields,
  getStudiosFormFields
} from '../providers/form-fields';
import './index.css';

const LabsNew = () => {
  const [ac] = useAppContext();
  const [NoticeComponent, notices] = useNotices('Administration/Labs/New');
  const { FetchHelper } = useFetch();
  const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] =
    useTabs('General', ['General', 'Enhancements', 'Studios']);
  const [availableStudios, setAvailableStudios] = useState([]);

  const [dataGeneral, setDataGeneral, clearDataGeneral, dataGeneralDefault] =
    useDraft('Administration:Labs:DataGeneral');
  const [
    dataEnhancements,
    setDataEnhancements,
    clearDataEnhancements,
    dataEnhancementsDefaults
  ] = useDraft('Administration:Labs:DataEnhancements');
  const [dataStudios, setDataStudios, clearDataStudios, dataStudiosDefault] =
    useDraft('Administration:Labs:DataStudios');

  const [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFields(dataGeneral));
  const [studiosFormFields, setStudiosFormFields] = useState(
    getStudiosFormFields(availableStudios, dataStudios)
  );

  const [errors, setErrors] = useState();

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    let fields = getGeneralFormFields(dataGeneral);
    setGeneralFormFields(fields);
  }, [dataGeneral])

  useEffect(() => {
    let fields = getStudiosFormFields(availableStudios, dataStudios);
    setStudiosFormFields(fields);
  }, [availableStudios, dataStudios?.studio_ids]);

  useEffect(() => {
    if (isFirstRender) return;

    let tabsWithFormsInfo = [
      {
        tab: 'General',
        forms: [{ id: 'Main', fields: generalFormFields }]
      },
      {
        tab: 'Studios',
        forms: [{ id: 'Main', fields: studiosFormFields }]
      }
    ];
    setTabsFormsErrors(tabsWithFormsInfo, errors);
  }, [errors]);

  useEffect(() => {
    const fetchStudios = async () => {
      ac.showSpinner(true);
      const [res] = await Utils.executePromise(
        FetchHelper({ url: '/api/v2/admin/studios' })
      );
      ac.showSpinner(false);

      let studiosList = lodash.map(res.body?.data, (item) => {
        return {
          id: item.id,
          name: item.name
        };
      });
      setAvailableStudios(studiosList);
    };
    fetchStudios();
  }, []);

  const handleSubmit = async () => {
    let body = { ...dataGeneral };
    if (body.logo_base64) body.logo_base64 = body.logo_base64.value;
    body.studio_ids = lodash.map(dataStudios?.studio_ids, (item) => item.id);
    body['lab_lab_enhancements_attributes'] = lodash.chain(dataEnhancements)
      .filter((item) => item?.item?.id)
      .map((item) => ({
        lab_enhancement_id: item.item.id,
        value: item.extraProps?.['value']
      }))
      .value();

    ac.showSpinner(true);
    let [res, err] = await Utils.executePromise(
      FetchHelper({
        url: `/api/v2/admin/labs`,
        method: 'POST',
        body: { lab: body }
      })
    );
    ac.showSpinner(false);

    // --- error handling
    if (err) throw err;
    if (res?.status === 422) {
      let errors = Utils.preProcess422Errors([...generalFormFields, ...studiosFormFields], res.body)
      setErrors(errors);
      Utils.scrollTop();

      throw res;
    }
  };

  const renderTabContent = (tab) => {
    switch (tab) {
      case 'General':
        return (
          <>
            <NoticeComponent location="Tab/General" notices={notices} />
            <Form
              key={'labs_new_form_general'}
              formData={dataGeneral}
              formErrors={errors}
              setFormErrors={setErrors}
              onChange={setDataGeneral}
              displayErrorBanner={true}
              fields={generalFormFields}
            />
          </>
        );

      case 'Enhancements':
        return (
          <>
            <NoticeComponent location="Tab/Enhancements" notices={notices} />
            <FieldSelector
              apiPrefix="lab_enhancement"
              buttonTitle="Enhancement"
              data={dataEnhancements}
              apiURL="/api/v2/admin/lab-enhancements"
              editUrl={(id) => `/lab-enhancements/${id}/edit`}
              onChange={setDataEnhancements}
              sortable={false}
              extraOptions={{
                renderExtra: (
                  item,
                  index,
                  isExtrasDisabled,
                  handleChange,
                  renderError
                ) => {
                  return (
                    <div className="enhancement-extras-container">
                      <div className="enhancement-value-input-container">
                        <span className="input-span">$</span>
                        <input
                          disabled={isExtrasDisabled}
                          key={'input_amount_' + (item?.value || index)}
                          type="number"
                          name={'value'}
                          value={item?.extraProps?.['value'] || ''}
                          step={0.05}
                          min={0}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value === '') value = 0;
                            handleChange(index, 'value', value)
                          }}
                        />
                      </div>
                      {renderError(index, 'value')}
                    </div>
                  );
                }
              }}
            />
          </>
        );

      case 'Studios':
        return (
          <>
            <NoticeComponent location="Tab/Studios" notices={notices} />
            <Form
              formData={dataStudios}
              formErrors={errors}
              setFormErrors={setErrors}
              onChange={setDataStudios}
              displayErrorBanner={true}
              fields={studiosFormFields}
            />
          </>
        );
    }
  };

  return (
    <div>
      <PageHeader title={`New Lab`} />
      <TabsComponent
        tabs={tabs}
        tab={tab}
        setTab={setTab}
        tabPropsByTab={tabPropsByTab}
      />
      {renderTabContent(tab)}
      <ControlBar
        handleSubmit={handleSubmit}
        cancelUrl="/labs"
        handleAfterSubmit={() => RouteHelper.redirect('/labs')}
        handleAfterClear={() => RouteHelper.reload()}
        dataItems={[
          [dataGeneral, clearDataGeneral, dataGeneralDefault],
          [dataEnhancements, clearDataEnhancements, dataEnhancementsDefaults],
          [dataStudios, clearDataStudios, dataStudiosDefault]
        ]}
      />
    </div>
  );
};

export default LabsNew;
