import { UI } from '../../../../UI'

import { ButtonComponent } from '../../../../componentsV2/Primitive'
import { CopyButton, FieldUpdater } from '../../../../components'

import { Layout } from '../../../../componentsV2/Layouts'

import { useWindowDimensions } from '../../../../hooks'
import Helpers from '../../../../helpers/utils'
import { Fetch } from '../../../../helpers'

import dayjs from 'dayjs'

import './index.css'
import { ModalComponent } from '../../../../componentsV2/Complex'
import { useEffect, useState } from 'react'

const OrderDetails = ({ _state, _togglesModal, _isOrderLocked }) => {
    const {_ac} = UI.useAppContext()

    const {_state: _toggles} = UI.useSimpleState({
        fetchingEmail: false,
        fetchingSMS: false,
        showCustomerInfo: true,
        showPNPModal: false,
        showZoomModal: false
    })

    const [hasAccessToSensitiveInformation, setHasAccessToSensitiveInformation] = useState(_ac.AuthHelper.hasPermission('sensitive_information'))

    useEffect(() => {
        setHasAccessToSensitiveInformation(
            _ac.AuthHelper.hasPermission('sensitive_information')
        ) 
    }, [_ac.AuthHelper.hasPermission('sensitive_information')])

    const {width} = useWindowDimensions()

    const dataOrder = _state.get('order')
    const dataMarket = _state.get('market')

    const handleLockReservation = () => {
        Fetch({
            method: 'put',
            url: `/api/v2/admin/orders/${dataOrder.id}/status`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            },
            body: {
                status_action: 'lock_reservation'
            }
        }, (res) => {
            // _set('data.order', { status: res.body.status })
            _state.set('order', {
                ..._state.get('order'),
                status: res.body.status
            })
        }, (res) => {

        })
    }

    const handleUnlockReservation = () => {
        Fetch({
            method: 'put',
            url: `/api/v2/admin/orders/${dataOrder.id}/status`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            },
            body: {
                status_action: 'unlock_reservation'
            }
        }, (res) => {
            _state.set('order', {
                ..._state.get('order'),
                status: res.body.status
            })
        }, (res) => {

        })
    }

    const handleProcess = () => {
        Fetch({
            method: 'put',
            url: `/api/v2/admin/orders/${dataOrder.id}/status`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            },
            body: {
                status_action: 'process'
            }
        }, (res) => {
            _state.set('order', {
                ..._state.get('order'),
                status: res.body.status
            })
        }, (res) => {

        })
    }

    const handleUnprocess = () => {
        Fetch({
            method: 'put',
            url: `/api/v2/admin/orders/${dataOrder.id}/status`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            },
            body: {
                status_action: 'unprocess'
            }
        }, (res) => {
            _state.set('order', {
                ..._state.get('order'),
                status: res.body.status
            })
        }, (res) => {

        })
    }

    const handleComplete = () => {
        Fetch({
            method: 'put',
            url: `/api/v2/admin/orders/${dataOrder.id}/status`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            },
            body: {
                status_action: 'complete'
            }
        }, (res) => {
            // _set('data.order', {
            //     ddlab: res.body
            // }) // whis is this here?
        }, (res) => {

        })
    }

    const handleResendEmail = () => {
        _toggles.set('fetchingEmail', true)

        Fetch({
            method: 'post',
            url: `/api/v2/admin/orders/${dataOrder.id}/email`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            }
        }, (res) => {
            setTimeout(() => {
                _toggles.set('fetchingEmail', false)
                _ac.setStatus({
                    type: 'success',
                    message: 'Email sent!',
                    uniqID: Helpers.generateUUID()
                })
            }, 2000)
        }, (res) => {
            setTimeout(() => {
                _toggles.set('fetchingEmail', false)
                _ac.setStatus({
                    type: 'danger',
                    message: 'Error sending email. Please try again later!',
                    uniqID: Helpers.generateUUID()
                })
            }, 2000)
        })
    }

    const handleResendSMS = () => {
        _toggles.set('fetchingSMS', true)

        Fetch({
            method: 'post',
            url: `/api/v2/admin/orders/${dataOrder.id}/sms`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            }
        }, (res) => {
            setTimeout(() => {
                _toggles.set('fetchingSMS', false)
                _ac.setStatus({
                    type: 'success',
                    message: 'SMS sent!',
                    uniqID: Helpers.generateUUID()
                })
            }, 2000)
        }, (res) => {
            setTimeout(() => {
                _toggles.set('fetchingSMS', false)
                _ac.setStatus({
                    type: 'danger',
                    message: 'Error sending SMS. Please try again later!',
                    uniqID: Helpers.generateUUID()
                })
            }, 2000)
        })
    }

    const handleOptOut = () => {
        _ac.showSpinner(true)

        Fetch({
            method: 'put',
            url: `/api/v2/admin/orders/${dataOrder.id}/customer`,
            headers: {
                'x-studio-id': dataOrder.studio_id
            },
            body: {
                customer: { email_opt: "out" }
            }
        }, (res) => {
            _ac.showSpinner(false)

            _state.set('order', {
                ..._state.get('order'),
                customer: {
                    ..._state.get('order').customer,
                    email_opt: 'out'
                }
            })
        }, (res) => {
            _ac.showSpinner(false)
        })
    }

    const downloadDDLabPDF = async () => {
        _ac.showSpinner(true)
        await Helpers.downloadImage(`/api/v2/admin/orders/${dataOrder.id}/ddlab`, `${dataOrder.id}_ddlab.pdf`)
        _ac.showSpinner(false)
    }

    const renderIntegrationsButtons = () => {
        const dataDDLab = dataOrder?.integrations?.ddlab_service
        const dataPNP = _state.get('pnp')
        const dataZoom = _state.get('zoom')

        if (!(dataDDLab || dataZoom || dataPNP))
            return

        return (
            <div className="primary-info-integrations-buttons">
                {dataDDLab ? <ButtonComponent config={{ palette: 'blueLight' }} events={{ handleClick: () => downloadDDLabPDF() }}>DDLab</ButtonComponent> : null}
                {dataPNP ? <ButtonComponent config={{ palette: 'blueLight' }} events={{ handleClick: () => _toggles.set('showPNPModal', true) }}>PNP</ButtonComponent> : null}
                {dataZoom ? <ButtonComponent config={{ palette: 'blueLight' }} events={{ handleClick: () => _toggles.set('showZoomModal', true) }}>Zoom</ButtonComponent> : null}
            </div>
        )
    }

    const renderModalPNP = () => {
        const dataPNP = _state.get('pnp')

        if (!_toggles.get('showPNPModal') || !dataPNP)
            return

        return (
            <ModalComponent config={{ classNames: 'ui--pnpModal' }} events={{ handleClose: () => _toggles.set('showPNPModal', false) }}>
                <ul className="pnp-container">
                    {dataPNP.marketing_image_url && (
                        <li>
                            <img src={dataPNP.marketing_image_url} />
                        </li>
                    )}
                    <li>
                        <em>Promo Code:</em> <a href={dataPNP.pnp_url}>{dataPNP.promo_code}</a>
                    </li>
                </ul>
            </ModalComponent>
        )
    }

    const renderModalZoom = () => {
        const dataZoom = _state.get('zoom')

        if (!_toggles.get('showZoomModal'))
            return

        return (
            <ModalComponent config={{ classNames: 'ui--zoomModal' }} events={{ handleClose: () => _toggles.set('showZoomModal', false) }}>
                <ul className="zoom-container">
                    <li>
                        <span className="title">Zoom Internal</span>

                        <ul>
                            <li><b>Meeting ID:</b> {dataZoom.meeting_id}</li>
                            <li><b>Meeting UUID:</b> {dataZoom.meeting_uuid}</li>
                        </ul>
                    </li>
                    <li>
                        <span className="title">Customer</span>

                        <ul>
                            <li><b>Join URL:</b> {dataZoom.meeting_join_url}</li>
                            <li><b>Password:</b> {dataZoom.meeting_password}</li>
                            <li><b>Duration:</b> {dataZoom.meeting_duration}</li>
                        </ul>
                    </li>
                    <li>
                        <span className="title">Host</span>

                        <ul>
                            <li><b>Host ID:</b> {dataZoom.meeting_id}</li>
                            <li><b>Host Email:</b> {dataZoom.meeting_host_email}</li>
                            <li><b>Start URL:</b> <a href={dataZoom.meeting_start_url}>{dataZoom.meeting_start_url}</a></li>
                        </ul>
                    </li>
                </ul>
            </ModalComponent>
        )
    }

    const renderPrimaryButtons = () => {
        const orderStatus = dataOrder.status

        return (
            <div className="ui-primaryButtons">
                {orderStatus === 'processed' && (
                    <ButtonComponent config={{ palette: 'default', tip: 'Unprocess Order' }} events={{ handleClick: handleUnprocess }}>Unprocess</ButtonComponent>
                )}

                {orderStatus === 'reservation_locked' && (
                    (_ac.AuthHelper.isManager() || _ac.AuthHelper.hasPermission('order_unlock_allowed')) && (
                        <ButtonComponent config={{ palette: 'default', tip: 'Unlock Order' }} events={{ handleClick: handleUnlockReservation }}>Unlock Reservation</ButtonComponent>
                    ) || (
                        <span className="message"><em>Locked</em> This order has been locked either manually or because its booking date has passed. To unlock, please seek assistance from a supervisor.</span>
                    )
                )}

                {orderStatus === 'refunded' && (
                    <span className="message"><em>Refunded</em></span>
                )}

                {orderStatus === 'paid' && (
                    <ButtonComponent config={{ palette: 'default', tip: 'Complete Order' }} events={{ handleClick: handleComplete }}>Complete</ButtonComponent>
                )}

                {orderStatus === 'completed' && (
                    <>
                        <ButtonComponent config={{ palette: 'blue', tip: 'Process Order' }} events={{ handleClick: handleProcess }}>Process</ButtonComponent>

                        {(_ac.AuthHelper.isManager() || _ac.AuthHelper.hasPermission('order_unlock_allowed')) && dataOrder.reservation?.data && (
                            <ButtonComponent config={{ palette: 'default', tip: 'Lock Order' }} events={{ handleClick: handleLockReservation }}>Lock Reservation</ButtonComponent>
                        )}
                    </>
                )}
            </div>
        )
    }

    const renderCustomerDetails = () => {
        return (
            <>
                <div className="section section-one">
                    {dataOrder.customer.optional_name1 ? (
                        <div className="customer-alternative-names">
                            <em>Attendees</em>
                            <div>
                                <FieldUpdater field={{ url: dataOrder.customer.url, name: 'optional_name1', value: dataOrder.customer.optional_name1, type: 'single_line' }} apiType="customer" studioId={dataOrder.studio_id} />
                                <FieldUpdater field={{ url: dataOrder.customer.url, name: 'optional_name2', value: dataOrder.customer.optional_name2, type: 'single_line' }} apiType="customer" studioId={dataOrder.studio_id} />
                            </div>
                        </div>
                    ) : null}

                    <div className="customer-address">
                        <div>
                            <FieldUpdater field={{ url: dataOrder.customer.url, name: 'billing_address_attributes', value: !hasAccessToSensitiveInformation ? 'You do not have access to sensitive information! Please contact your studio manager for assistance.' : dataOrder.customer.billing_address, type: 'address', ask: 'Address' }} apiType="customer" studioId={dataOrder.studio_id}
                                editable={hasAccessToSensitiveInformation} />
                        </div>

                        {dataOrder.customer.shipping_address ? (
                            <div>
                                <em>Shipping Address</em>
                                <FieldUpdater field={{ url: dataOrder.customer.url, name: 'shipping_address_attributes', value: !hasAccessToSensitiveInformation ? 'You do not have access to sensitive information! Please contact your studio manager for assistance.' : dataOrder.customer.shipping_address, type: 'address', ask: 'Shipping Address' }} apiType="customer" studioId={dataOrder.studio_id}
                                    editable={hasAccessToSensitiveInformation} />
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="section section-two">
                    <div>
                        <FieldUpdater field={{ url: dataOrder.customer.url, name: 'email', value: dataOrder.customer.email, type: 'email', ask: 'Email' }} apiType="customer" studioId={dataOrder.studio_id} />
                    </div>

                    <div>
                        <FieldUpdater field={{ url: dataOrder.customer.url, name: 'phone_number', value: dataOrder.customer.phone_number, type: 'phone', ask: 'Phone' }} apiType="customer" studioId={dataOrder.studio_id}
                            editable={hasAccessToSensitiveInformation} />
                    </div>
                </div>
            </>
        )
    }

    if (!dataOrder.id || !dataMarket.id)
        return null

    return (
        <>
            {_isOrderLocked && (
                <div className="locked-order-message">
                    <strong>Order refunded and locked.</strong>
                    <ButtonComponent
                        config={{
                            palette: 'defaultDark',
                            size: 'small',
                            classNames: _toggles.get('fetchingEmail') ? 'animation-fetching' : ''
                        }}
                        events={{ handleClick: handleResendEmail }}>
                        <i className="bi bi-envelope"></i> Email
                    </ButtonComponent>
                </div>
            )}
            
            <div className={`primary-info-block ${_isOrderLocked ? 'disabled' : ''}`}>
                <div className="primary-info-block-title">
                    <div className="purchase-date">
                        <em>Purchase Date</em> {dayjs(dataOrder.purchased_at).tz(dataMarket.timezone).format('YYYY/MM/DD h:mm A z')}
                    </div>

                    {dataOrder.reservation?.data?.starts_at && (
                        <>
                            <div className="icon">
                                {width < 1024 ? <i className="bi bi-arrow-down-short"></i> : <i className="bi bi-arrow-right-short"></i>}
                            </div>
                        
                            <div className="visit-date">
                                <em>Visit Date</em> {dayjs(dataOrder.reservation.data.starts_at).tz(dataMarket.timezone).format('YYYY/MM/DD h:mm A z')}
                            </div>
                        </>
                    )}

                    <div className="global-spacer"></div>

                    <div className="customer-actions">
                        <ButtonComponent config={{ palette: 'blueLight', tip: 'Reschedule/move order to new market' }} events={{ handleClick: () => _togglesModal.set('reschedule', true) }}>Reschedule/Move</ButtonComponent>
                        <ButtonComponent config={{ palette: 'blueLight', tip: dataOrder.refundable ? 'Refund order' : 'Refund unavilable because order is non-refundable', disabled: !dataOrder.refundable }} events={{ handleClick: () => _togglesModal.set('refund', true) }}>Refund</ButtonComponent>
                    </div>
                </div>

                {_toggles.get('showCustomerInfo') && (
                    <>
                        <div className="primary-info-block-details-container">
                            <div className="barcode">
                                <img src={'data:image/jpeg;base64,' + dataOrder.barcode.data} />
                                <a href={dataOrder.url} target="_blank">{dataOrder.barcode.text}</a>
                                {(dataMarket?.options?.use_scheduling && dataMarket?.scheduling_rescheduling_disabled === false) && (
                                    <>
                                        <hr />
                                        <ul>
                                            <li><a href={dataOrder.reschedule_url}>Reschedule Link for Customer</a></li>
                                        </ul>
                                    </>
                                )}
                            </div>

                            <div className="customer-details">
                                <div className="customer">
                                    <FieldUpdater field={{ url: dataOrder.customer.url, name: 'first_name', value: dataOrder.customer.first_name, type: 'single_line' }} apiType="customer" studioId={dataOrder.studio_id} />
                                    <FieldUpdater field={{ url: dataOrder.customer.url, name: 'last_name', value: dataOrder.customer.last_name, type: 'single_line' }} apiType="customer" studioId={dataOrder.studio_id}
                                        editable={hasAccessToSensitiveInformation} />
                                </div>

                                <div className="primary-info-block-details-sections">
                                    {renderCustomerDetails()}
                                </div>

                                <div className="primary-info-block-credit-card-container">
                                    <em>{dataOrder.customer.credit_card.brand.toUpperCase()}</em> <span className="cc-number">{dataOrder.customer.credit_card.last_digits}</span> {dataOrder.customer.credit_card.exp_month}/{dataOrder.customer.credit_card.exp_year}
                                </div>
                            </div>
                        </div>
                        
                        <div className='conf-url-container'>
                            <div className='title-row'>
                                <h3>Confirmation URL</h3>
                                <button onClick={() => { window.open(dataOrder?.url, '_blank') }}><i className='bi bi-box-arrow-up-right'></i></button>
                                <CopyButton value={dataOrder?.url} showInput={false} />
                            </div>
                            <code><pre>{dataOrder?.url}</pre></code>

                            <div className='title-row mt-2'>
                                <h3>Reschedule URL</h3>
                                <button onClick={() => { window.open(dataOrder?.reschedule_url, '_blank') }}><i className='bi bi-box-arrow-up-right'></i></button>
                                <CopyButton value={dataOrder?.reschedule_url} showInput={false} />
                            </div>
                            <code><pre>{dataOrder?.reschedule_url}</pre></code>
                        </div>
                    </>
                )}

                {renderIntegrationsButtons()}

                {
                    <div className="primary-info-block-opt-out">
                        <>
                            {/* Opted In */}
                            {dataOrder.customer.email_opt != 'out' && (
                                <>
                                    <p>Customer Opted Into Email Subscription</p>
                                    <ButtonComponent config={{ palette: 'defaultLight', size: 'small', tip: 'Opt out? This action can only be done once.' }} events={{ handleClick: handleOptOut }}>Opt Out</ButtonComponent>
                                </>
                                )}

                            {/* Opted Out */}
                            {dataOrder.customer.email_opt == 'out' && (
                                <p className='text-red-400'>Customer Opted Out of Email Subscription</p>
                                )}
                        </>
                    </div>
                }

                <div className="primary-info-block-controls">
                    <div className="ui-primaryOptions">
                        {renderPrimaryButtons()}
                        
                        <div className="ui-secondaryButtons">
                            <ButtonComponent
                                config={{
                                    palette: 'defaultDark',
                                    tip: <span>Send email</span>,
                                    size: 'small',
                                    classNames: _toggles.get('fetchingEmail') ? 'animation-fetching' : ''
                                }}
                                events={{ handleClick: handleResendEmail }}>
                                <i className="bi bi-envelope"></i> Email
                            </ButtonComponent>

                            {dataOrder.integrations.twilio_service && dataOrder.accept_sms ? (
                                <ButtonComponent
                                    config={{
                                        palette: 'defaultDark',
                                        tip: <span>Send text</span>,
                                        size: 'small', classNames: _toggles.get('fetchingSMS') ? 'animation-fetching' : '' 
                                    }}
                                    events={{ handleClick: handleResendSMS }}>
                                    <i className="bi bi-chat"></i> SMS
                                </ButtonComponent>
                            ) : null}
                        </div>
                    </div>

                    { Number(dataOrder.balance) > 0 && (
                        <div className="ui-secondaryOptions">
                            <ButtonComponent config={{ palette: 'blue', tip: 'Process Payment' }} events={{ handleClick: () => _togglesModal.set('pay', true) }}>Pay</ButtonComponent>

                            <ButtonComponent config={{ palette: 'blue', tip: 'Apply Discount'}} events={{ handleClick: () => _togglesModal.set('discount', true) }}>Discount</ButtonComponent>
                        </div>
                    )}
                </div>
            </div>

            {renderModalPNP()}
            {renderModalZoom()}
        </>
    )
}

export { OrderDetails }