import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

const useDynamicState = (config) => {
    const [_mounted, _setMounted] = useState(false)
    const [_stateCurrent, _setStateCurrent] = useState({})
    const [_statePrevious, _setStatePrevious] = useState({})
    const [_mapDynamic, _setMapDynamic] = useState({})
    const [_queueSetState, _setQueueSetState] = useState([])
    const [_count, _setCount] = useState(0)

    useEffect(() => {
        let states_default = {}
        let dynamics = {}

        for (const key_groupName in config) {
            const item_config = config[key_groupName]

            for (const key_stateName in item_config) {
                const item_state = item_config[key_stateName]
                const selector = `${key_groupName}.${key_stateName}`

                let value_state = {}

                for (const key_item_state in item_state) {
                    let item = item_state[key_item_state]

                    // if (Array.isArray(item)) {
                    //     if (typeof item[1] === 'function') {
                    //         const item_state_default = item[0]
                    //         const item_state_callback = item[1]

                    //         value_state = {
                    //             ...value_state,
                    //             [key_item_state]: item_state_default
                    //         }

                    //         dynamics = {
                    //             ...dynamics,
                    //             [selector]: {
                    //                 ...dynamics[selector],
                    //                 [key_item_state]: {
                    //                     callback: item_state_callback
                    //                 }
                    //             }
                    //         }
                    //     } else {
                    //         const item_state_default = item[0]
                    //         const item_state_listeners = item[1]
                    //         const item_state_callback = item[2]

                    //         value_state = {
                    //             ...value_state,
                    //             [key_item_state]: item_state_default
                    //         }

                    //         dynamics = {
                    //             ...dynamics,
                    //             [selector]: {
                    //                 ...dynamics[selector],
                    //                 [key_item_state]: {
                    //                     listeners: item_state_listeners,
                    //                     callback: item_state_callback
                    //                 }
                    //             }
                    //         }
                    //     }
                    // } else {
                        value_state = {
                            ...value_state,
                            [key_item_state]: item
                        }
                    // }
                }

                states_default[selector] = value_state
            }
        }
        
        _setStatePrevious(states_default)
        _setStateCurrent(states_default)
        _setMapDynamic(dynamics)
        _setMounted(true)
    }, [])

    useEffect(() => {
        if (_queueSetState.length) {
            let queue = [..._queueSetState]
            
            const [queueSelector, queueValues, queueCallback] = queue.shift()

            let stateCurrent = {
                ..._stateCurrent,
                [queueSelector]: {
                    ..._stateCurrent[queueSelector],
                    ...queueValues
                }
            }

            _setQueueSetState(queue)
            _setStatePrevious(_stateCurrent)
            _setStateCurrent(stateCurrent)
            queueCallback(stateCurrent[queueSelector])
        }
    }, [_queueSetState])

    const stateGet = (selector, prop) => {
        if (!selector) {
            return {..._stateCurrent}
        }

        if (!_stateCurrent[selector]) {
            return {}
        }

        let properties = {..._stateCurrent[selector]}

        if (selector && !prop) {
            return properties
        }

        if (Array.isArray(prop)) {
            for (const key in prop) {
                if (prop.indexOf(key) === -1) {
                    delete properties[key]
                }
            }

            return properties
        }

        return properties[prop]
    }

    const stateSet = (selector, values, callback=()=>{}) => {
        let queueSetState = [..._queueSetState, [selector, values, callback]]

        _setQueueSetState(queueSetState)
    }

    return {
        _get: stateGet,
        _set: stateSet,
        _mounted
    }
}

useDynamicState.propTypes = {
    config: PropTypes.object.isRequired
}

export { useDynamicState }
