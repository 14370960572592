import { useState, useEffect } from 'react'

import CopyButton from '../../components/CopyButton'
import PageHeaderComponent from '../../components/PageHeader'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import lodash from 'lodash'

import './index.css'
import { useTabs } from '../../hooks'

const StorefrontLinks = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/StorefrontLinks')

    const getAllSectionUrls = (settingsData) => {
        return {
            events: {
                list: settingsData?.theme && `${settingsData?.theme[settingsData?.default_theme]?.event_listing_url}?all`,
                search: settingsData?.theme && `${settingsData?.theme[settingsData?.default_theme]?.event_search_url}?all`
            } ,
            proofs: {
                search: settingsData?.theme && `${settingsData?.theme[settingsData?.default_theme]?.proof_search_url}?all`
            }
        }
    }

    const getThemesUrls = (themesData) => {
        return lodash
            .mapValues(themesData, (themeData) => {
                return lodash.pick(themeData, ['event_listing_url', 'event_search_url', 'proof_search_url'])
            })
    }

    const [privateUrl, setPrivateUrl] = useState(ac.AuthHelper?.settings?.private_url)
    const [allSectionUrls, setAllSectionUrls] = useState(getAllSectionUrls(ac.AuthHelper?.settings))
    const [themeUrls, setThemeUrls] = useState(getThemesUrls(ac.AuthHelper?.settings?.theme))

    const [tabs, tab, setTab, TabsComponent] = useTabs('All', ['All', 'Dance', 'Santa', 'Bunny', 'Generic'])

    useEffect(() => {
        setPrivateUrl(ac.AuthHelper?.settings?.private_url)
    }, [ac.AuthHelper?.settings?.private_url])

    useEffect(() => {
        setAllSectionUrls(getAllSectionUrls(ac.AuthHelper?.settings))
    }, [ac.AuthHelper?.settings?.default_theme, ac.AuthHelper?.settings?.theme])

    useEffect(() => {
        setThemeUrls(getThemesUrls(ac.AuthHelper?.settings?.theme))
    }, [ac.AuthHelper?.settings?.theme])

    const snippet = `<div id="sfp_code_form">
    <form action="${privateUrl}" method="post">
        <input name="code" type="text">
        <button type="submit" class="btn">Go</button>
    </form>
</div>`

    const renderThemeBlock = (theme, themeDisplayName) => {
        return (
            <>
                {renderUrlBlock(
                    `Events`,
                    themeUrls[theme]?.event_listing_url,
                    <span>
                        This will show a search field and <strong>{themeDisplayName} events</strong> will be visible when the page loads
                    </span>
                )}

                {renderUrlBlock(
                    `Events Search`,
                    themeUrls[theme]?.event_search_url,
                    <span>
                        This will show a search field and <strong>{themeDisplayName} events</strong> will not be visible when the page loads
                    </span>
                )}

                {renderUrlBlock(
                    `Proofs Search`,
                    themeUrls[theme]?.proof_search_url,
                    <span>
                        This will show a search field and <strong>{themeDisplayName} proofs</strong> will not be visible when the page loads
                    </span>
                )}
            </>
        )
    }

    const renderUrlBlock = (title, url, description) => {
        return (
            <div className="links-section">
                <em> {title} </em>
                
                <div className="flex">
                    <div className="link-block">
                        <a href={url} target="_blank">{ url }</a>
                        {description}
                    </div>
                </div>
            </div>
        )
    }

    const renderTabContents = (tab) => {
        switch (tab) {
            case "All":
                return (
                  <>
                    {renderUrlBlock(
                      'Events',
                      allSectionUrls.events?.list,
                      <span>
                        This will show a search field and{' '}
                        <strong>all events</strong> will be visible when the
                        page loads
                      </span>
                    )}

                    {renderUrlBlock(
                        'Events Search',
                        allSectionUrls.events?.search,
                        <span>
                            This will show a search field and <strong>all events</strong> will not be visible when the page loads
                        </span>
                    )}

                    {renderUrlBlock(
                        'Proofs Search',
                        allSectionUrls.proofs?.search,
                        <span>
                            This will show a search field and <strong>all proofs</strong> will not be visible when the page loads
                        </span>
                    )}
                  </>
                );

            case "Dance":
                return renderThemeBlock('dance', 'Dance')

            case "Santa":
                return renderThemeBlock('santa', 'Santa')

            case "Bunny":
                return renderThemeBlock('bunny', 'Bunny')

            case "Generic":
                return renderThemeBlock('generic', 'Generic')

            default:
                break
        }
    }

    return (
        <div className="storefront-links-page-container page-container">
            <PageHeaderComponent title="Storefront Links" />
            <NoticeComponent location="Main" notices={notices} />
            <div className="page-container-inner">
                <div className="snippet-container">
                    <div className="snipper-code-container">
                        <h3><span>Below is the snippet for private market pages</span> <CopyButton value={snippet} showInput={false} /></h3>
                        <code><pre>{snippet}</pre></code>
                    </div>

                    <div className="link-block">
                        <a href={privateUrl} target="_blank">{ privateUrl }</a>
                        This will show a password field, and, upon entry of a correct secret code, will load the respective market or, if multiple matches, a list of markets
                    </div>
                </div>

                <div className="links-container">
                    <div className="links-container-title">
                        <p>Below are the links to your event listings.</p>
                        <p>Either set a DNS Forwarder for your custom domain to point to this URL, or place a link to this URL on your homepage.</p>
                        <p>The following subdomains can be used in place of the <em>www</em> in the URLs below to provide an isolated customer experience: ipohh or simontestt</p>
                    </div>

                    <div className='links-sections'>
                        <TabsComponent tabs={tabs} tab={tab} setTab={setTab} />
                        <div className='py-2 px-4'>{renderTabContents(tab)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StorefrontLinks