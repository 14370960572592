import { useState } from 'react';
import { ControlBar, Form, PageHeader } from '../../../components';
import { Utils } from '../../../helpers';
import RouteHelper from '../../../helpers/route';
import { useAppContext, useDraft, useFetch, useNotices } from '../../../hooks';
import { getWorkflow2FormFields } from '../providers/form-fields';
import './index.css';
import { useNavigate } from 'react-router-dom';

const Workflow2 = () => {
  const navigate = useNavigate();
  const [ac] = useAppContext();
  const [NoticeComponent, notices] = useNotices('Workflow2');

  const [formFields, setFormFields] = useState(getWorkflow2FormFields());
  const [data, setData, clearData, dataDefault] = useDraft(
    'Workflow:Workflow2',
    {}
  );
  const [errors, setErrors] = useState({});
  const { FetchHelper } = useFetch();

  const handleSubmit = async () => {
    ac.showSpinner(true);
    const [res, err] = await Utils.executePromise(
      FetchHelper({
        url: `/api/v2/admin/markets/${data.market_id}/generate/barcodes`,
        method: 'POST',
        body: { generate: { quantity: data.quantity, email: data.email }}
      })
    );
    ac.showSpinner(false);

    if (err) throw err;
    if (res?.status === 422) {
      setErrors(res.body);
      throw res;
    }
    
    // --- show success message
    ac.setStatus({
      type: 'success',
      message: 'Request submitted! You will receive codes in your email.',
      uniqID: Utils.generateUUID(),
    })
  };

  return (
    <div>
      <PageHeader title="Workflow 2" />
      <NoticeComponent location="Index" notices={notices} />

      <Form
        formData={data}
        onChange={setData}
        formErrors={errors}
        setFormErrors={setErrors}
        fields={formFields}
        displayErrorBanner={true}
      />
      <ControlBar
        handleSubmit={handleSubmit}
        dataItems={[[data, clearData, dataDefault]]}
        handleAfterClear={() => {
          RouteHelper.reload();
        }}
        handleAfterSubmit={() => {
          navigate('/');
        }}
      />
    </div>
  );
};

export default Workflow2;
