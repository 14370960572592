import {useState} from 'react'

import Errors from '../components/Errors'
import FetchHelper from '../helpers/fetch'
import RouteHelper from '../helpers/route'

import './Confirm.css'

const Confirm = ({ title, apiUrl, method='POST', entityName, reload=true, body={}, cancel=()=> {}, confirm=()=> {} }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})

    const handleFetch = (e) => {
        setIsLoading(true)
        setErrors({})

        FetchHelper({
            url: apiUrl,
            method: method,
            body: body
        }, (res) => {
            setIsLoading(false)
            
            if (reload) {
                RouteHelper.reload()
            } else {
                confirm()
            }
        }, (res) => {
            setIsLoading(false)

            if (res.body) {
                setErrors(res.body)
            } else {
                cancel(res)
            }
        })
    }

    return (
        <div className="confirm-container">
            <div className={isLoading ? 'disabled' : ''}>
                <div className="confirm-title">
                    {title} <span>{entityName}</span>
                </div>
                <div className="confirm-options">
                    <button onClick={handleFetch}>Confirm</button> <button onClick={cancel}>Cancel</button>
                </div>
            </div>

            <Errors errors={errors} />
        </div>
    )
}

export default Confirm