export const COUNTRIES = [
    {
        title: "",
        value: ""
    },
    {
        title: "United States of America",
        value: "US"
    },
    {
        title: "Canada",
        value: "CA"
    },
    {
        title: "Australia",
        value: "AU"
    },
    {
        title: "Andorra",
        value: "AD"
    },
    {
        title: "Afghanistan",
        value: "AF"
    },
    {
        title: "Antigua and Barbuda",
        value: "AG"
    },
    {
        title: "Anguilla",
        value: "AI"
    },
    {
        title: "Albania",
        value: "AL"
    },
    {
        title: "Armenia",
        value: "AM"
    },
    {
        title: "Angola",
        value: "AO"
    },
    {
        title: "Antarctica",
        value: "AQ"
    },
    {
        title: "Argentina",
        value: "AR"
    },
    {
        title: "American Samoa",
        value: "AS"
    },
    {
        title: "Austria",
        value: "AT"
    },
    {
        title: "Aruba",
        value: "AW"
    },
    {
        title: "Åland Islands",
        value: "AX"
    },
    {
        title: "Algeria",
        value: "DZ"
    },
    {
        title: "Azerbaijan",
        value: "AZ"
    },
    {
        title: "Bosnia and Herzegovina",
        value: "BA"
    },
    {
        title: "Barbados",
        value: "BB"
    },
    {
        title: "Bangladesh",
        value: "BD"
    },
    {
        title: "Belgium",
        value: "BE"
    },
    {
        title: "Burkina Faso",
        value: "BF"
    },
    {
        title: "Bulgaria",
        value: "BG"
    },
    {
        title: "Bahrain",
        value: "BH"
    },
    {
        title: "Burundi",
        value: "BI"
    },
    {
        title: "Benin",
        value: "BJ"
    },
    {
        title: "Bermuda",
        value: "BM"
    },
    {
        title: "Brunei Darussalam",
        value: "BN"
    },
    {
        title: "Bolivia (Plurinational State of)",
        value: "BO"
    },
    {
        title: "Bonaire, Sint Eustatius and Saba",
        value: "BQ"
    },
    {
        title: "Brazil",
        value: "BR"
    },
    {
        title: "Bahamas",
        value: "BS"
    },
    {
        title: "Bhutan",
        value: "BT"
    },
    {
        title: "British Indian Ocean Territory",
        value: "IO"
    },
    {
        title: "Bouvet Island",
        value: "BV"
    },
    {
        title: "Botswana",
        value: "BW"
    },
    {
        title: "Belarus",
        value: "BY"
    },
    {
        title: "Belize",
        value: "BZ"
    },
    {
        title: "Cocos (Keeling) Islands",
        value: "CC"
    },
    {
        title: "Congo (Democratic Republic of the)",
        value: "CD"
    },
    {
        title: "Central African Republic",
        value: "CF"
    },
    {
        title: "Congo",
        value: "CG"
    },
    {
        title: "Côte d'Ivoire",
        value: "CI"
    },
    {
        title: "Cook Islands",
        value: "CK"
    },
    {
        title: "Chile",
        value: "CL"
    },
    {
        title: "Cameroon",
        value: "CM"
    },
    {
        title: "China",
        value: "CN"
    },
    {
        title: "Colombia",
        value: "CO"
    },
    {
        title: "Costa Rica",
        value: "CR"
    },
    {
        title: "Cuba",
        value: "CU"
    },
    {
        title: "Cabo Verde",
        value: "CV"
    },
    {
        title: "Curaçao",
        value: "CW"
    },
    {
        title: "Christmas Island",
        value: "CX"
    },
    {
        title: "Cyprus",
        value: "CY"
    },
    {
        title: "Czechia",
        value: "CZ"
    },
    {
        title: "Croatia",
        value: "HR"
    },
    {
        title: "Germany",
        value: "DE"
    },
    {
        title: "Djibouti",
        value: "DJ"
    },
    {
        title: "Denmark",
        value: "DK"
    },
    {
        title: "Dominica",
        value: "DM"
    },
    {
        title: "Dominican Republic",
        value: "DO"
    },
    {
        title: "Ecuador",
        value: "EC"
    },
    {
        title: "El Salvador",
        value: "SV"
    },
    {
        title: "Estonia",
        value: "EE"
    },
    {
        title: "Egypt",
        value: "EG"
    },
    {
        title: "Eritrea",
        value: "ER"
    },
    {
        title: "Eswatini",
        value: "SZ"
    },
    {
        title: "Ethiopia",
        value: "ET"
    },
    {
        title: "Finland",
        value: "FI"
    },
    {
        title: "Fiji",
        value: "FJ"
    },
    {
        title: "Falkland Islands (Malvinas)",
        value: "FK"
    },
    {
        title: "French Guiana",
        value: "GF"
    },
    {
        title: "French Southern Territories",
        value: "TF"
    },
    {
        title: "Faroe Islands",
        value: "FO"
    },
    {
        title: "France",
        value: "FR"
    },
    {
        title: "Gabon",
        value: "GA"
    },
    {
        title: "Grenada",
        value: "GD"
    },
    {
        title: "Georgia",
        value: "GE"
    },
    {
        title: "Guernsey",
        value: "GG"
    },
    {
        title: "Ghana",
        value: "GH"
    },
    {
        title: "Gibraltar",
        value: "GI"
    },
    {
        title: "Greenland",
        value: "GL"
    },
    {
        title: "Gambia",
        value: "GM"
    },
    {
        title: "Guinea",
        value: "GN"
    },
    {
        title: "Guadeloupe",
        value: "GP"
    },
    {
        title: "Equatorial Guinea",
        value: "GQ"
    },
    {
        title: "Greece",
        value: "GR"
    },
    {
        title: "Guatemala",
        value: "GT"
    },
    {
        title: "Guam",
        value: "GU"
    },
    {
        title: "Guinea-Bissau",
        value: "GW"
    },
    {
        title: "Guyana",
        value: "GY"
    },
    {
        title: "Holy See",
        value: "VA"
    },
    {
        title: "Hong Kong",
        value: "HK"
    },
    {
        title: "Heard Island and McDonald Islands",
        value: "HM"
    },
    {
        title: "Honduras",
        value: "HN"
    },
    {
        title: "Haiti",
        value: "HT"
    },
    {
        title: "Hungary",
        value: "HU"
    },
    {
        title: "Indonesia",
        value: "ID"
    },
    {
        title: "Ireland",
        value: "IE"
    },
    {
        title: "Israel",
        value: "IL"
    },
    {
        title: "Isle of Man",
        value: "IM"
    },
    {
        title: "India",
        value: "IN"
    },
    {
        title: "Iraq",
        value: "IQ"
    },
    {
        title: "Iran (Islamic Republic of)",
        value: "IR"
    },
    {
        title: "Iceland",
        value: "IS"
    },
    {
        title: "Italy",
        value: "IT"
    },
    {
        title: "Jersey",
        value: "JE"
    },
    {
        title: "Jamaica",
        value: "JM"
    },
    {
        title: "Jordan",
        value: "JO"
    },
    {
        title: "Japan",
        value: "JP"
    },
    {
        title: "Kenya",
        value: "KE"
    },
    {
        title: "Kyrgyzstan",
        value: "KG"
    },
    {
        title: "Cambodia",
        value: "KH"
    },
    {
        title: "Kiribati",
        value: "KI"
    },
    {
        title: "Comoros",
        value: "KM"
    },
    {
        title: "Korea (Democratic People's Republic of)",
        value: "KP"
    },
    {
        title: "Korea (Republic of)",
        value: "KR"
    },
    {
        title: "Kuwait",
        value: "KW"
    },
    {
        title: "Cayman Islands",
        value: "KY"
    },
    {
        title: "Kazakhstan",
        value: "KZ"
    },
    {
        title: "Lao People's Democratic Republic",
        value: "LA"
    },
    {
        title: "Lebanon",
        value: "LB"
    },
    {
        title: "Saint Lucia",
        value: "LC"
    },
    {
        title: "Liechtenstein",
        value: "LI"
    },
    {
        title: "Sri Lanka",
        value: "LK"
    },
    {
        title: "Liberia",
        value: "LR"
    },
    {
        title: "Lesotho",
        value: "LS"
    },
    {
        title: "Lithuania",
        value: "LT"
    },
    {
        title: "Luxembourg",
        value: "LU"
    },
    {
        title: "Latvia",
        value: "LV"
    },
    {
        title: "Libya",
        value: "LY"
    },
    {
        title: "Micronesia (Federated States of)",
        value: "FM"
    },
    {
        title: "Morocco",
        value: "MA"
    },
    {
        title: "Monaco",
        value: "MC"
    },
    {
        title: "Moldova (Republic of)",
        value: "MD"
    },
    {
        title: "Montenegro",
        value: "ME"
    },
    {
        title: "Saint Martin (French part)",
        value: "MF"
    },
    {
        title: "Madagascar",
        value: "MG"
    },
    {
        title: "Marshall Islands",
        value: "MH"
    },
    {
        title: "North Macedonia",
        value: "MK"
    },
    {
        title: "Mali",
        value: "ML"
    },
    {
        title: "Myanmar",
        value: "MM"
    },
    {
        title: "Mongolia",
        value: "MN"
    },
    {
        title: "Macao",
        value: "MO"
    },
    {
        title: "Northern Mariana Islands",
        value: "MP"
    },
    {
        title: "Martinique",
        value: "MQ"
    },
    {
        title: "Mauritania",
        value: "MR"
    },
    {
        title: "Mayotte",
        value: "YT"
    },
    {
        title: "Montserrat",
        value: "MS"
    },
    {
        title: "Malta",
        value: "MT"
    },
    {
        title: "Mauritius",
        value: "MU"
    },
    {
        title: "Maldives",
        value: "MV"
    },
    {
        title: "Malawi",
        value: "MW"
    },
    {
        title: "Mexico",
        value: "MX"
    },
    {
        title: "Malaysia",
        value: "MY"
    },
    {
        title: "Mozambique",
        value: "MZ"
    },
    {
        title: "Namibia",
        value: "NA"
    },
    {
        title: "New Caledonia",
        value: "NC"
    },
    {
        title: "Niger",
        value: "NE"
    },
    {
        title: "Norfolk Island",
        value: "NF"
    },
    {
        title: "Nigeria",
        value: "NG"
    },
    {
        title: "Nicaragua",
        value: "NI"
    },
    {
        title: "Netherlands",
        value: "NL"
    },
    {
        title: "Norway",
        value: "NO"
    },
    {
        title: "Nepal",
        value: "NP"
    },
    {
        title: "Nauru",
        value: "NR"
    },
    {
        title: "Niue",
        value: "NU"
    },
    {
        title: "New Zealand",
        value: "NZ"
    },
    {
        title: "Oman",
        value: "OM"
    },
    {
        title: "Panama",
        value: "PA"
    },
    {
        title: "Peru",
        value: "PE"
    },
    {
        title: "French Polynesia",
        value: "PF"
    },
    {
        title: "Papua New Guinea",
        value: "PG"
    },
    {
        title: "Philippines",
        value: "PH"
    },
    {
        title: "Pakistan",
        value: "PK"
    },
    {
        title: "Poland",
        value: "PL"
    },
    {
        title: "Saint Pierre and Miquelon",
        value: "PM"
    },
    {
        title: "Pitcairn",
        value: "PN"
    },
    {
        title: "Puerto Rico",
        value: "PR"
    },
    {
        title: "Palestine, State of",
        value: "PS"
    },
    {
        title: "Portugal",
        value: "PT"
    },
    {
        title: "Palau",
        value: "PW"
    },
    {
        title: "Paraguay",
        value: "PY"
    },
    {
        title: "Qatar",
        value: "QA"
    },
    {
        title: "Réunion",
        value: "RE"
    },
    {
        title: "Romania",
        value: "RO"
    },
    {
        title: "Saint Barthélemy",
        value: "BL"
    },
    {
        title: "Serbia",
        value: "RS"
    },
    {
        title: "Russian Federation",
        value: "RU"
    },
    {
        title: "Rwanda",
        value: "RW"
    },
    {
        title: "Saint Vincent and the Grenadines",
        value: "VC"
    },
    {
        title: "Saint Kitts and Nevis",
        value: "KN"
    },
    {
        title: "Saudi Arabia",
        value: "SA"
    },
    {
        title: "Solomon Islands",
        value: "SB"
    },
    {
        title: "South Georgia and the South Sandwich Islands",
        value: "GS"
    },
    {
        title: "Seychelles",
        value: "SC"
    },
    {
        title: "Sudan",
        value: "SD"
    },
    {
        title: "Sweden",
        value: "SE"
    },
    {
        title: "Singapore",
        value: "SG"
    },
    {
        title: "Saint Helena, Ascension and Tristan da Cunha",
        value: "SH"
    },
    {
        title: "Slovenia",
        value: "SI"
    },
    {
        title: "Svalbard and Jan Mayen",
        value: "SJ"
    },
    {
        title: "Slovakia",
        value: "SK"
    },
    {
        title: "Sierra Leone",
        value: "SL"
    },
    {
        title: "San Marino",
        value: "SM"
    },
    {
        title: "Senegal",
        value: "SN"
    },
    {
        title: "Somalia",
        value: "SO"
    },
    {
        title: "Spain",
        value: "ES"
    },
    {
        title: "Suriname",
        value: "SR"
    },
    {
        title: "South Sudan",
        value: "SS"
    },
    {
        title: "Sao Tome and Principe",
        value: "ST"
    },
    {
        title: "Samoa",
        value: "WS"
    },
    {
        title: "South Africa",
        value: "ZA"
    },
    {
        title: "Sint Maarten (Dutch part)",
        value: "SX"
    },
    {
        title: "Syrian Arab Republic",
        value: "SY"
    },
    {
        title: "Switzerland",
        value: "CH"
    },
    {
        title: "Turks and Caicos Islands",
        value: "TC"
    },
    {
        title: "Chad",
        value: "TD"
    },
    {
        title: "Togo",
        value: "TG"
    },
    {
        title: "Thailand",
        value: "TH"
    },
    {
        title: "Tajikistan",
        value: "TJ"
    },
    {
        title: "Tokelau",
        value: "TK"
    },
    {
        title: "Timor-Leste",
        value: "TL"
    },
    {
        title: "Turkmenistan",
        value: "TM"
    },
    {
        title: "Tunisia",
        value: "TN"
    },
    {
        title: "Tonga",
        value: "TO"
    },
    {
        title: "Turkey",
        value: "TR"
    },
    {
        title: "Trinidad and Tobago",
        value: "TT"
    },
    {
        title: "Tuvalu",
        value: "TV"
    },
    {
        title: "Taiwan, Province of China",
        value: "TW"
    },
    {
        title: "Tanzania, United Republic of",
        value: "TZ"
    },
    {
        title: "Ukraine",
        value: "UA"
    },
    {
        title: "Uganda",
        value: "UG"
    },
    {
        title: "United Arab Emirates",
        value: "AE"
    },
    {
        title: "United States Minor Outlying Islands",
        value: "UM"
    },
    {
        title: "United Kingdom of Great Britain and Northern Ireland",
        value: "GB"
    },
    {
        title: "Uruguay",
        value: "UY"
    },
    {
        title: "Uzbekistan",
        value: "UZ"
    },
    {
        title: "Venezuela (Bolivarian Republic of)",
        value: "VE"
    },
    {
        title: "Virgin Islands (British)",
        value: "VG"
    },
    {
        title: "Virgin Islands (U.S.)",
        value: "VI"
    },
    {
        title: "Viet Nam",
        value: "VN"
    },
    {
        title: "Vanuatu",
        value: "VU"
    },
    {
        title: "Wallis and Futuna",
        value: "WF"
    },
    {
        title: "Western Sahara",
        value: "EH"
    },
    {
        title: "Yemen",
        value: "YE"
    },
    {
        title: "Zambia",
        value: "ZM"
    },
    {
        title: "Zimbabwe",
        value: "ZW"
    }
]