export function getPaymentInfoFormFields(
  paymentGateways = [],
  paymentSettings
) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name'
    },
    {
      type: 'select',
      name: 'type',
      label: 'Type',
      options: paymentGateways
    },
    {
      type: 'input-text',
      name: 'login',
      label: paymentSettings?.login
    },
    {
      type: 'input-text',
      name: 'password',
      label: paymentSettings?.password,
      condition: () => !!paymentSettings?.password
    },
    {
      type: 'input-text',
      name: 'merchant_id',
      label: paymentSettings?.merchant_id,
      condition: () => !!paymentSettings?.merchant_id
    },
    {
      type: 'switch',
      name: 'testing',
      label: 'Testing Mode?'
    }
  ];
}

export function getPaymentTypeFormFields() {
  return [
    {
      type: 'switch',
      name: 'accept_american_express',
      label: 'American Express',
      value: true
    },
    {
      type: 'switch',
      name: 'accept_discover',
      label: 'Discover Card',
      value: true
    },
    {
      type: 'switch',
      name: 'accept_master',
      label: 'Master Card',
      value: true
    },
    {
      type: 'switch',
      name: 'accept_visa',
      label: 'Visa',
      value: true
    }
  ];
}

export function getApplePayFormFields(merchantCertificate, merchantKey) {
  return [
    {
      type: 'switch',
      name: 'apple_pay',
      label: 'Apple Pay'
    },
    {
      type: 'input-text',
      name: 'merchant_identifier',
      label: 'Merchant identifier',
      placeholder: 'merchant.com.studio.applepay'
    },
    {
      type: 'input-file',
      name: 'merchant_certificate',
      label: 'Merchant certificate',
      field_info: merchantCertificate && (
        <span className="installed">Installed</span>
      ),
      relatedFields: ['apple_pay_integration.merchant_certificate'],
      fileType: ''
    },
    {
      type: 'input-file',
      name: 'merchant_key',
      label: 'Merchant key',
      field_info: merchantKey && <span className="installed">Installed</span>,
      relatedFields: ['apple_pay_integration.merchant_key'],
      fileType: ''
    }
  ];
}
