import { useState, useRef, useEffect } from 'react';

import FetchHelper from '../../helpers/fetch';
import Utils from '../../helpers/utils'
import useAppContext from '../../hooks/useAppContext';
import './index.css'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import EmailTags from '../EmailTags';
import { HTMLEditorMode } from '../../sys/constants/enums';
import Modal from '../Modal';
import {
  ClassicEditor,
  Alignment,
  Bold,
  Essentials,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  GeneralHtmlSupport,
  Image,
  ImageInsert,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Table,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
  SimpleUploadAdapter,
  BalloonToolbar,
  TableCellProperties
} from 'ckeditor5'
import 'ckeditor5/ckeditor5.css';

const ckEditorPlugins = [
  Alignment,
  BalloonToolbar,
  Bold,
  Essentials,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  GeneralHtmlSupport,
  Image,
  ImageInsert,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResize,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Table,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TableCellProperties,
  Underline,
  Undo,
  SimpleUploadAdapter
];

const ckeditorConfigs = {
  plugins: ckEditorPlugins,
  toolbar: {
    items: [
      'sourceEditing',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'outdent',
      'indent',
      '|',
      'alignment',
      '|',
      'fontFamily',
      'fontSize',
      'fontColor',
      '|',
      'insertTable',
      'specialCharacters',
      'insertImage',
      'link',
      '|',
      'undo',
      'redo'
    ],
    shouldNotGroupWhenFull: true
  },
  balloonToolbar: ['bold', 'italic', '|', 'link', 'insertImage', '|', 'bulletedList', 'numberedList'],
  language: 'en',
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true
      }
    ],
    disallow: [
      {
        attributes: [
          { key: /^on(.*)/i, value: true },
          {
            key: /.*/,
            value: /(\b)(on\S+)(\s*)=|javascript:|(<\s*)(\/*)script/i
          },
          { key: /.*/, value: /data:(?!image\/(png|jpg|jpeg|gif|webp))/i }
        ]
      },
      { name: 'script' }
    ]
  },
  image: {
    toolbar: [
      'imageTextAlternative',
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
      'linkImage'
    ]
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties'
    ]
  },
  simpleUpload: {
    withCredentials: true,
    uploadUrl: `/api/v2/admin/inline/image`
  }
};

const Editor = ({ value, setValue, error, setError, onChange, readOnly = false, showTags, onEditorReady=()=>{} }) => {
    const editor = useRef()

    const insertTextInEditor = (editor, text) => {
        const htmlString = text;
        const htmlDP = editor.data.processor;
        const viewFragment = htmlDP.toView( htmlString );

        const modelFragment = editor.data.toModel( viewFragment );
        editor.model.insertContent(modelFragment)
    }

    return (
        <div className={`editor-container ${readOnly ? 'disabled' : ''}`}>
            <div>
                { showTags &&
                    <EmailTags onSelect={(value) => { insertTextInEditor(editor.current, value) }} /> }

                <CKEditor
                    editor={ClassicEditor}
                    data={value}
                    config={ckeditorConfigs}
                    disabled={readOnly}
                    onChange={(e, editor) => {
                        let newValue = editor.getData()
                        if (newValue == '<p>&nbsp;</p>') newValue = ''
                        if (newValue === value) return

                        setValue(newValue);

                        if (!onChange) return
                        onChange(newValue)
                    }}
                    onReady={(e) => {
                        editor.current = e;
                        onEditorReady(e)   
                    }}
                />
            </div>

            {error ? (
                <div className="error">
                    {error}
                </div>
            ) : null}
        </div>
    )
}

const HTMLEditor = ({ initialValue, onChange, readOnly = false, showTags, onEditorReady=()=>{}, mode, title }) => {
    const [ac] = useAppContext()

    const [value, setValue] = useState(initialValue)
    const [error, setError] = useState()

    const [showModal, setShowModal] = useState(false)

/*
 * Quill Toolbar Config for reference
 *
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image'],
            [{ 'table': 'table' }],
            [{ 'table-column-left': '' }],
            [{ 'table-column-right': '' }],
            [{ 'table-row-above': '' }],
            [{ 'table-row-below': '' }],
            [{ 'table-row-remove': '' }],
            [{ 'table-column-remove': '' }],
        ],
        handlers: {
            image: imageHandler,
            'table-row-above': function() {
                quill.current.getModule('table').insertRowAbove();
            },
            'table-row-below': function() {
                quill.current.getModule('table').insertRowBelow();
            },
            'table-column-left': function() {
                quill.current.getModule('table').insertColumnLeft();
            },
            'table-column-right': function() {
                quill.current.getModule('table').insertColumnRight();
            },
            'table-row-remove': function() {
                quill.current.getModule('table').deleteRow();
            },
            'table-column-remove': function() {
                quill.current.getModule('table').deleteColumn();
            }
        },
    },
    table: true,
*/

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const renderModal = () => {
        return (
            <Modal handleClose={() => setShowModal(false)}>
                <div className='flex flex-col gap-4'>
                    { title && <p className='text-base font-bold'>{title}</p> }
                    <Editor value={value} setValue={setValue} error={error} setError={error} onChange={onChange} readOnly={readOnly} showTags={showTags} onEditorReady={onEditorReady} />

                    <div className='text-end'>
                        <button onClick={() => setShowModal(false)} className='flex-initial'>Done</button>
                    </div>
                </div>
            </Modal>
        )
    }
    
    if (mode === HTMLEditorMode.MODAL)
        return (
            <div className='html-editor-container'>
                { showModal && renderModal() }
                <button onClick={() => setShowModal(true)}>Open Editor</button>
            </div>
        )

    return (
        <div className='html-editor-container'>
            <Editor value={value} setValue={setValue} error={error} setError={error} onChange={onChange} readOnly={readOnly} showTags={showTags} onEditorReady={onEditorReady} />
        </div>
    );
};

HTMLEditor.defaultProps = {
    mode: HTMLEditorMode.NORMAL
}

export default HTMLEditor