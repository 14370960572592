const ConditionWrapper = ({ children, condition={} }) => {
    if (!condition.test()) {
        return condition.placeholder ? condition.placeholder() : children
    }

    return children
}

export {
    ConditionWrapper
}