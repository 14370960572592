import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const OverlayComponent = ({ id, children, target, events={}, config={} }) => {
    const {_state: _statePosition} = UI.useSimpleState({
        top: null,
        left: null,
        right: null,
        bottom: null
    })

    const {_state: _stateSize} = UI.useSimpleState({
        width: config.width || null, 
        height: config.height || null
    })

    let _ref = React.useRef(null)
    let _refTimer = React.useRef(null)
    let _finishedAnimatingRef = React.useRef(false)
    let _mouseEnteredRef = React.useRef(false)

    const clearTimer = () => {
        if (_refTimer && _refTimer.current)
            clearTimeout(_refTimer.current)
    }

    const initializeTimer = () => {
        clearTimer()

        _refTimer.current = setTimeout(() => {
            events.handleClose()
        }, 1500)
    }

    useEffect(() => {
        if (_ref && _ref.current && config.enableTimer && events.handleClose)
            initializeTimer()

        const handleClick = (e) => {
            if (!_ref || !_ref.current)
                return
            
            if (!target.contains(e.target)) {
                if (events.handleClose)
                    events.handleClose()
            }
        }

        window.addEventListener('click', handleClick)

        return () => {
            window.removeEventListener('click', handleClick)
        }
    }, [_ref])

    useEffect(() => {
        if (!target || !_ref || !_ref.current)
            return

        const updateOverlay = () => {
            const targetRect = target.getBoundingClientRect()
            const overlayRect = _ref.current.getBoundingClientRect()

            let styles = {}

            const styleCenterX = targetRect.left + (targetRect.width / 2) - (overlayRect.width / 2)
            const styleCenterY = targetRect.top + (targetRect.height / 2) - (overlayRect.height / 2)

            switch (config.position || 'top') {
                case 'top':
                    styles = {
                        top: targetRect.top - overlayRect.height,
                        left: styleCenterX
                    }
                    break
                case 'top-left':
                    styles = {
                        top: targetRect.top - overlayRect.height,
                        left: targetRect.left
                    }
                    break
                case 'top-right':
                    styles = {
                        top: targetRect.top - overlayRect.height,
                        left: targetRect.right - overlayRect.width
                    }
                    break
                case 'right':
                    styles = {
                        top: styleCenterY,
                        left: targetRect.right
                    }
                    break
                case 'right-top':
                    styles = {
                        top: targetRect.top,
                        left: targetRect.right
                    }
                    break
                case 'right-bottom':
                    styles = {
                        top: targetRect.bottom - overlayRect.height,
                        left: targetRect.right
                    }
                    break
                case 'bottom':
                    styles = {
                        top: targetRect.bottom,
                        left: styleCenterX
                    }
                    break
                case 'bottom-left':
                    styles = {
                        top: targetRect.bottom,
                        left: targetRect.left
                    }
                    break
                case 'bottom-right':
                    styles = {
                        top: targetRect.bottom,
                        left: targetRect.right - overlayRect.width
                    }
                    break
                case 'left':
                    styles = {
                        top: styleCenterY,
                        left: targetRect.left - overlayRect.width
                    }
                    break
                case 'left-top':
                    styles = {
                        top: targetRect.top,
                        left: targetRect.left - overlayRect.width
                    }
                    break
                case 'left-bottom':
                    styles = {
                        top: targetRect.bottom - overlayRect.height,
                        left: targetRect.left - overlayRect.width
                    }
                    break
            }

            if (/^top/.test(config.position)) {
                styles.top -= config.offsetY || 10
            }

            if (/^right/.test(config.position)) {
                styles.left += config.offsetX || 10
            }

            if (/^bottom/.test(config.position)) {
                styles.top += config.offsetY || 10
            }

            if (/^left/.test(config.position)) {
                styles.left -= config.offsetX || 10
            }

            if (config.matchTargetWidth) {
                _stateSize.set({ width: targetRect.width })
            }

            _statePosition.set(styles)
        }

        window.addEventListener('scroll', updateOverlay)
        window.addEventListener('resize', updateOverlay)

        updateOverlay()

        return () => {
            window.removeEventListener('scroll', updateOverlay)
            window.removeEventListener('resize', updateOverlay)
        }
    }, [target, _ref])

    const checkClipping = () => {
        if (!_ref || !_ref.current)
            return

        const overlayRect = _ref.current.getBoundingClientRect()

        const { innerWidth, innerHeight } = window
        const { top, left } = _statePosition.get()

        // if (overlayRect.right > innerWidth) {
        //     _statePosition.set('left', left - 100)
        // } else if (overlayRect.left < 0) {
        //     _statePosition.set('left', left + 100)
        // }

        // more conditions down here
    }

    useEffect(() => {
        window.addEventListener('resize', checkClipping)

        return () => {
            window.removeEventListener('resize', checkClipping)
        }
    }, [])

    const handleClick = (e) => {
        e.preventDefault()
    }

    const handleClose = () => {
        if (!_finishedAnimatingRef?.current || !_mouseEnteredRef?.current)
            return

        if (events.handleClose)
            events.handleClose()
    }

    const handleAnimationEnd = () => {
        _finishedAnimatingRef.current = true
    }

    const handleMouseEnter = () => {
        if (!_finishedAnimatingRef?.current)
            return

        _mouseEnteredRef.current = true

        clearTimer()
    }

    const handleMouseLeave = () => {
        handleClose()
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Escape') {
            // close overlay
        }
    }

    const handleRef = (newRef) => {
        _ref.current = newRef
    }

    const style = {
        ..._statePosition.get(),
        ..._stateSize.get()
    }

    return (
        createPortal(
            // <OutsideClickComponent events={{ onClose: events.onClose }}>
                <div ref={handleRef} className={`ui-container--overlay ${id ? `ui-container--overlay--${id}` : ''} ${config.noStyle ? '' : 'ui-action--noStyle'} ${config.classNames || ''}`} style={style} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onAnimationEnd={handleAnimationEnd} onKeyUp={handleKeyUp} onClick={handleClick}>
                    <div className="ui-inner ui-container--overlay-inner">
                        {children}
                    </div>
                </div>
            // </OutsideClickComponent>
        , document.getElementById('ui-portals'))
    )
}

OverlayComponent.propTypes = {
    target: PropTypes.any,

    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),

    events: PropTypes.shape({
        handleClose: PropTypes.func
    }),

    config: PropTypes.shape({
        classNames: PropTypes.string,
        matchTargetWidth: PropTypes.bool,
        offsetX: PropTypes.number,
        offsetY: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        enableTimer: PropTypes.bool,
        noStyle: PropTypes.bool
    })
}

export { OverlayComponent }