import {useState} from 'react'

import './Projector.css'

const Projector = ({ url }) => {
    const [showIcon, setShowIcon] = useState(true)

    const hideIcon = () => {
        setShowIcon(false)
    }
    return (
        <div className="projector-container" style={{ backgroundImage: !showIcon ? `url(${url})` : `` }}>
            {showIcon ? <i className="bi bi-image"></i> : null}
            <img src={url} onLoad={hideIcon} className="hidden" />
        </div>
    )
}

export default Projector