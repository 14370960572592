import propTypes from 'prop-types'

import TwoSidedMultiselectPaginated from '../../../components/TwoSidedMultiselectPaginated'
import { CheckboxComponent } from '../../../componentsV2/Primitive/Checkbox'
import { ButtonComponent } from '../../Primitive'
import { ModalComponent } from '../../Complex'

import FetchHelper from '../../../helpers/fetch'

import { UI } from '../../../UI'
import { Layout } from '../../../componentsV2/Layouts'

import './index.css'
import FormComponent from '../../../components/Form'
import { useRef, useState } from 'react'

const BulkEntityEditor = ({ title, formFields, setFormFields, fieldsFormData, setFieldsFormData, fieldsFormErrors, events={}, offerClearAllBtn = false }) => {
    const {_state} = UI.useSimpleState({
        complete: false,
        error: false
    })
    const {_state: _selectedMarkets} = UI.useSimpleState({})
    const {_state: _selectedFields} = UI.useSimpleState({})
    const [submitBtnConfig, setSubmitBtnConfig] = useState({ palette: 'blue' })
    const inputForm = useRef()

    const handleClose = () => {
        if (events.handleClose)
            events.handleClose()
    }

    const handleSubmit = async () => {
        // --- disable submit button
        let disabledBtnConfig = { ...submitBtnConfig, disabled: true }
        setSubmitBtnConfig(disabledBtnConfig);

        let success = true;
        if (events.handleSubmit) 
            success = await events.handleSubmit(_selectedMarkets.get())

        if (success) _state.set('complete', true)
            
        // --- enable submit button
        delete disabledBtnConfig.disabled
        setSubmitBtnConfig(disabledBtnConfig)
    }

    return (
        <ModalComponent events={{ handleClose: handleClose }}>
            <div className="ui-c-BulkEntityEditor">
                {_state.get('complete') ? (
                    <>
                        <div className="ui-bulkCopyComplete">
                            Fields successfully copied.
                        </div>
                        <div className="ui--controls">
                            <ButtonComponent events={{ handleClick: handleClose }}>Done</ButtonComponent>
                        </div>
                    </>
                ) : (
                <>
                        { title && <Layout.SectionTitle>{ title }</Layout.SectionTitle> }
                        <div className="ui-marketSelect">
                            <Layout.SectionTitle>Select Markets</Layout.SectionTitle>
                            <TwoSidedMultiselectPaginated value={_selectedMarkets.get()} apiUrl="/api/v2/admin/markets" renderTitle={(item) => `${item.name} — ${item.type}`} tabOptions={['Markets']} itemType="markets" onChange={(items) => {
                                _selectedMarkets.set(items)
                            }} />
                        </div>
                        <div className="ui-propSelect">
                            <div className='flex items-end mb-2'>
                                <Layout.SectionTitle className='flex-1'>Select Fields to Copy</Layout.SectionTitle>
                                {offerClearAllBtn && <button onClick={() => { inputForm.current?.clearAllSelected() }}>Clear all selected fields</button>}
                            </div>
                            <FormComponent ref={inputForm} formData={fieldsFormData} formErrors={fieldsFormErrors} 
                                onChange={setFieldsFormData} displayErrorBanner={true} 
                                fields={formFields} setFields={setFormFields} />
                        </div>
                        <div className="ui--controls">
                            <ButtonComponent config={submitBtnConfig} events={{ handleClick: handleSubmit }}>Copy Fields</ButtonComponent> <ButtonComponent events={{ handleClick: handleClose }}>Cancel</ButtonComponent>
                        </div>
                    </>
                )}
            </div>
        </ModalComponent>
    )
}

BulkEntityEditor.propTypes = {
    events: propTypes.shape({
        handleSave: propTypes.func,
        handleClose: propTypes.func
    })
}

export { BulkEntityEditor }