import {useState, useEffect} from 'react'

import { Link, useNavigate } from 'react-router-dom'

import PageHeaderComponent from '../../components/PageHeader'
import TableComponent from '../../components/Table'
import FetchHelper from '../../helpers/fetch'
import APIHelper from '../../helpers/api'
import UtilsHelper from '../../helpers/utils'
import PickerDate from '../../components/PickerDate'
import SelectPaginated from '../../components/SelectPaginated'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import _ from 'lodash'
import dayjs from 'dayjs'

import './index.css'

const OrdersPage = () => {
    const [ac] = useAppContext()
    const navigate = useNavigate();

    const [NoticeComponent, notices] = useNotices('Orders/Index')

    const [data, setData] = useState({})
    const [page, setPage] = useState(1)
    const [fetched, setFetched] = useState(false)
    const [theme, setTheme] = useState('All')
    const [orderSearchBy, setOrderSearchBy] = useState('fastpass')
    const [orderSearchByInput, setOrderSearchByInput] = useState('')
    const [orderID, setOrderID] = useState()
    const [excludeStatus, setExcludeStatus] = useState('')
    const [markets, setMarkets] = useState([])
    const [selectedMarket, setSelectedMarket] = useState('')
    const [startDate, setStartDate] = useState(dayjs().subtract(3, 'month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [optionsFields, setOptionsFields] = useState([])

    useEffect(() => {
        APIHelper.getMarketsGrouped(setMarkets)
    }, [])

    useEffect(() => {
        if (selectedMarket) {
            FetchHelper({
                url: selectedMarket?.fields?.url
            }, (res) => {
                setOptionsFields(res.body.data)
            }, (res) => {})
        } else {
            setOptionsFields([])
            setOrderSearchBy('fastpass')
        }
    }, [selectedMarket])

    useEffect(() => {
        if (fetched) {
            fetchOrders()
        }
    }, [page])

    const fetchOrders = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/orders/search?page=${page}&start_date=${startDate}&end_date=${endDate}&theme=${theme}&market=${selectedMarket?.id || ''}&by=${orderSearchBy}&term=${orderSearchByInput}&exclude_status=${excludeStatus}`
        }, (res) => {
            setData(res.body)
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        setFetched(true)
        fetchOrders()
    }

    const dataFiltered = {
        columns: data.columns || [],
        data: [{
            group: 'default',
            data: data.data ? data.data.map((item, i) => {
                data.columns.map((column, i) => {
                    item[`ui_${column.key}`] = _.get(item, column.key)
                })

                item.ui_fastpass = <Link to={`/order/${item.id}`}>{item.ui_fastpass}</Link>

                return item
            }) : []
        }]
    }

    return (
        <div className="orders-page-container page-container">
            <PageHeaderComponent title="Orders" />
            <NoticeComponent location="List" notices={notices} />
                <div className={`orders-page-container-inner ${ac.isLoading ? 'disabled' : ''}`}>
                    <div className="orders-filters">
                        <form onSubmit={handleSubmit}>
                            <div className="orders-filters-fields section-date-range">
                                <div>
                                    <label>Date Range</label>
                                    <div className="flex">
                                        <PickerDate initialValue={startDate} onChange={(value) => {
                                            UtilsHelper.setLocalStorage('OrderSearch_startDate', value)
                                            setStartDate(value)
                                        }} dateFormat="YYYY-MM-DD" />
                                        <PickerDate initialValue={endDate} onChange={(value) => {
                                            UtilsHelper.setLocalStorage('OrderSearch_endDate', value)
                                            setEndDate(value)
                                        }} dateFormat="YYYY-MM-DD" />
                                    </div>
                                </div>
                            </div>
                            <div className="orders-filters-fields section-theme-market">
                                <div>
                                    <label>Theme</label>
                                    <select value={theme} onChange={(e) => setTheme(e.target.value)}>
                                        <option value="All">All</option>
                                        <option value="santa">Santa</option>
                                        <option value="bunny">Bunny</option>
                                        <option value="dance">Dance</option>
                                        <option value="generic">Generic</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Market</label>
                                    <SelectPaginated apiUrl="/api/v2/admin/markets" offerAllOption={true} onSelect={(option) => {
                                        setSelectedMarket(option.item)
                                    }} />
                                </div>
                            </div>
                            <div className="orders-filters-fields section-filter">
                                <label>Filter</label>
                                <div className="flex">
                                    <select value={orderSearchBy} onChange={(e) => setOrderSearchBy(e.target.value)}>
                                        <option value="fastpass">Confirmation #</option>
                                        <option value="first_name">First Name</option>
                                        <option value="last_name">Last Name</option>
                                        <option value="email">Email</option>
                                        <option value="phone_number">Phone Number</option>
                                        <option value="card_first_digits">Credit Card First 6 Digits</option>
                                        <option value="card_last_digits">Credit Card Last 4 Digits</option>
                                        {optionsFields.length ? (
                                            <>
                                                <option>-----------------</option>
                                                {optionsFields.map((item) => {
                                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                                })}
                                            </>
                                        ) : null}
                                    </select>
                                    <input type="text" value={orderSearchByInput} onChange={(e) => setOrderSearchByInput(e.target.value)} />
                                    <select value={excludeStatus} onChange={(e) => setExcludeStatus(e.target.value)}>
                                        <option value="">Exclude: None</option>
                                        <option value="processed">Exclude: Processed</option>
                                    </select>
                                    <button onClick={handleSubmit}>Search</button>
                                </div>
                            </div>
                        </form>

                        <h2 className='my-6 text-center font-bold text-base'>-------- OR --------</h2>

                        <form onSubmit={() => { navigate(`/order/${orderID}`) }}>
                            <div className="orders-filters-fields">
                                <div>
                                    <label>Order Hash</label>
                                    <div className='flex flex-wrap gap-4'>
                                        <input className='flex-1' type="text" value={orderID || ''} onChange={(e) => setOrderID(e.target.value)} required />
                                        <button className='global-button' type="submit" disabled={!orderID}>Go to order</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {data.columns && data.data.length ? (
                        <div className="orders-table">
                            <TableComponent columns={dataFiltered.columns} data={dataFiltered.data} onSetPage={setPage} />
                        </div>
                    ) : null}
                </div>
        </div>
    )
}

export default OrdersPage
