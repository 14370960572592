import { FileTypes } from '../../../sys/constants/enums';

export function getWorkflow1FormFields(processedFilesCount, totalFilesCount) {
  const isProcessingFiles =
    processedFilesCount !== null && processedFilesCount !== undefined;
  return [
    {
      type: 'select-paginated',
      name: 'market_id',
      label: 'Market',
      apiUrl: '/api/v2/admin/markets',
      readonly: isProcessingFiles
    },
    {
      type: 'date',
      name: 'starts_at',
      label: 'Start Date',
      label_info: 'Order range start date',
      readonly: isProcessingFiles
    },
    {
      type: 'date',
      name: 'ends_at',
      label: 'End Date',
      label_info: 'Order range end date',
      readonly: isProcessingFiles
    },
    {
      type: 'input-file',
      name: 'files',
      label: 'Upload proofs',
      label_info:
        'Upload images including QR codes. Images will be attached to preceding QR code.',
      fileType: FileTypes.IMAGES,
      outputFormat: 'file',
      multiple: true,
      inputDescription:
        processedFilesCount !== null && processedFilesCount !== undefined
          ? `Files processed: ${processedFilesCount} / ${totalFilesCount} `
          : '',
      condition: (data) => data.market_id,
      readonly: isProcessingFiles
    }
  ];
}

export function getWorkflow2FormFields() {
  return [
    {
      type: 'input-number',
      name: 'quantity',
      label: 'Quantity of QR codes to generate'
    },
    {
      type: 'select-paginated',
      name: 'market_id',
      label: 'Market',
      apiUrl: '/api/v2/admin/markets'
    },
    {
      type: 'input-text',
      name: 'email',
      label: 'Email',
      label_info: 'Email address to send generated QR codes to'
    }
  ];
}
