import PropTypes from 'prop-types'

import './index.css'

const StatusIndicatorComponent = ({ status, config={} }) => {
    if (!status)
        return

    return (
        <span className={`ui-container--statusIndicator ${config.palette ? `ui--statusIndicatorPalette--${config.palette}` : ''}`}>{status}</span>
    )
}

StatusIndicatorComponent.propTypes = {
    status: PropTypes.string,

    config: PropTypes.shape({
        palette: PropTypes.string
    })
}

export { StatusIndicatorComponent }