import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { ModalComponent } from '../../Complex'
import { ButtonComponent, HeadingComponent } from '../../Primitive'
import { EntityGridComponent } from '../EntityGrid'
import { PlaceholderGenericSpinnerComponent } from '../PlaceholderGenericSpinner'

import { Fetch } from '../../../helpers'

import './index.css'

const EntitySelectorModalComponent = ({ selected=[], totalRequired, collection=[], type, events={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        currentView: 'main',
        selection: null,
        selectionMain: null,
    })

    const {_state: _transitions} = UI.useSimpleState({
        currentView: null,
        nextView: null
    })
    
    useEffect(() => {
        if (_state.get('currentView') === 'selection' && events.handleGetAvailable)
            events.handleGetAvailable()
    }, [_state.get('currentView')])

    const handleTransition = () => {
        if (_transitions.get('nextView') !== null) {
            _state.set('currentView', _transitions.get('nextView'))

            _transitions.set({
                currentView: null,
                nextView: null
            })
        }
    }

    const handleClose = () => {
        if (events.handleClose)
            events.handleClose()
    }

    const transitionToMainView = () => {
        _transitions.set({
            currentView: _state.get('currentView'),
            nextView: 'main'
        })
    }

    const handleSelection = (item) => {
        if (!item)
            return

        if (!_state.get('selection')) {
            handleNewSelection(item)
            return
        }

        transitionToMainView()
        
        if (events.handleReplaceSelection) {
            events.handleReplaceSelection(item, _state.get('selection'))
        }

        _state.set('selection', null)
    }

    const handleDeleteSelection = (item) => {
        if (events.handleDelete)
            events.handleDelete(item)
    }

    const handleAddNewSelection = () => {
        _transitions.set({
            currentView: _state.get('currentView'),
            nextView: 'selection'
        })
    }

    const handleNewSelection = (item) => {
        if (events.handleAddNewSelection)
            events.handleAddNewSelection(item)

        transitionToMainView()
    }

    const handleMainSelection = (item) => {
        if (!item || type === 'proof')
            return

        _state.set('selection', item)

        _transitions.set({
            currentView: 'main',
            nextView: 'selection'
        })
    }

    const getSelectedAndTotalCount = () => {
        return `(${selected.length}${totalRequired ? `/${totalRequired}` : ''})`
    }

    const renderMainView = () => {
        return (
            <UI.Layout
                name="entitySelectorMain"
                config={{ classNames: _transitions.get('currentView') === 'main' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <HeadingComponent type="h3">Selections {getSelectedAndTotalCount()}</HeadingComponent>
                <EntityGridComponent items={selected} type={type} config={{ enableAdd: (selected.length < totalRequired) || type === 'addon', enableDelete: type !== 'proof', enableCode: true }} events={{ handleSelection: handleMainSelection, handleDelete: handleDeleteSelection, handleAddNewSelection: handleAddNewSelection }} />
                <div className="ui--controls">
                    <ButtonComponent config={{ palette: 'blue' }} events={{ handleClick: handleClose }}>Done</ButtonComponent>
                </div>
            </UI.Layout>
        )
    }

    const renderSelectionView = () => {
        return (
            <UI.Layout
                name="entitySelectorSelection"
                config={{ classNames: _transitions.get('currentView') === 'selection' ? 'ui-animation--fadeOut' : '' }}
                configPlaceholder={{
                    dependencies: [collection !== undefined],
                    placeholder: () => <PlaceholderGenericSpinnerComponent />
                }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <HeadingComponent type="h3">Make a selection</HeadingComponent>
                <EntityGridComponent items={collection} type={type} config={{ enableCode: true }} events={{ handleSelection: handleSelection }} />
                <div className="ui--controls">
                    <ButtonComponent events={{ handleClick: transitionToMainView }}>Cancel</ButtonComponent>
                </div>
            </UI.Layout>
        )
    }

    const renderView = () => {
        switch (_state.get('currentView')) {
            case 'main':
                return renderMainView()
            case 'selection':
                return renderSelectionView()
        }
    }

    return (
        <ModalComponent events={{ handleClose }} config={{ isSyncing: config.isSyncing }}>
            {renderView()}
        </ModalComponent>
    )
}

EntitySelectorModalComponent.propTypes = {
    selected: PropTypes.array,

    collection: PropTypes.array,

    events: PropTypes.shape({
        handleChange: PropTypes.func,
        handleAddNewSelection: PropTypes.func
    }),

    config: PropTypes.shape({
        isSyncing: PropTypes.bool
    })
}

export { EntitySelectorModalComponent }