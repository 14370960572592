import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { Link, useParams } from 'react-router-dom'

import { OrderProvider, OrderContext } from './OrderContext'

import {
    Modal,
    DropdownMenu,
    FieldUpdater,
    Tooltip,
    PageHeader,
} from '../../components'

import {
    PlaceholderGenericSpinnerComponent
} from '../../componentsV2/Integrated'

import {
    Utils,
    DomainHelper,
    Fetch
} from '../../helpers'

import {
    useAppContext,
    useTabs
} from '../../hooks'

import { UI } from '../../UI'

import {
    OrderDetails,
    PayModalComponent,
    DiscountModalComponent,
    PackageDetails
} from './components'

import { FetchOrders } from './fetch'

import RescheduleModal from './partials/RescheduleModal'
import RefundModal from './partials/RefundModal'

import './index.css'

const OrderPage = () => {
    const params = useParams()
    const {_state} = UI.useSimpleState({
        currentView: 'order',
        entitySelection: null,
        entityCollection: null
    })

    const {_state: _transitions} = UI.useSimpleState({
        currentView: null,
        nextView: null
    })

    const {_state: _apis} = UI.useSimpleState({
        order: null,
        market: null,
        paymentAccount: null,
        packages: null,
        marketingPlan: null,
        logs: null,
        zoom: null,
        centricts: null,
        pfs: null,
        pnp: null,
        ddlab: null,
        entitySelection: null
    })

    const {_state: _dependencies} = UI.useSimpleState({
        order: false,
        market: false,
        paymentAccount: false,
        packages: false,
        marketingPlan: false,
        logs: false,
        integrations: false,
        transactions: false
    })

    const {_state: _togglesModal} = UI.useSimpleState({
        zoom: false,
        pfs: false,
        pnp: false,
        reschedule: false,
        refund: false,
        pay: false,
        discount: false
    })

    const _dataOrder = _apis.get('order')
    const _studioId = _apis.get('order')?.studio_id

    const _isOrderLocked = _dataOrder?.status === 'refunded'

    const [tabs, tab, setTab, Tabs] = useTabs('Order', ['Order', 'Transactions', 'Rescheduled/Moved'])

    const [ac] = useAppContext()
    const [hasAccessToSensitiveInformation, setHasAccessToSensitiveInformation] = useState(ac.AuthHelper.hasPermission('sensitive_information'))

    useEffect(() => {
        setHasAccessToSensitiveInformation(
            ac.AuthHelper.hasPermission('sensitive_information')
        ) 
    }, [ac.AuthHelper.hasPermission('sensitive_information')])

    const {_results, _reload} = FetchOrders({
        requirements: {
            orderId: params.id
        }
    }, (results) => {
        if (results.order) {
            _apis.set('order', results.order)
            _dependencies.set('order', true)
        }

        if (results.market) {
            _apis.set('market', results.market)
            _dependencies.set('market', true)
        }

        if (results.packages) {
            _apis.set('packages', results.packages)
            _dependencies.set('packages', true)
        }

        if (results.marketingPlan) {
            _apis.set('marketingPlan', results.marketingPlan)
            _dependencies.set('marketingPlan', true)
        }

        if (results.paymentAccount) {
            _apis.set('paymentAccount', results.paymentAccount)
            _dependencies.set('paymentAccount', true)
        }

        if (results.logs) {
            _apis.set('logs', results.logs)
            _dependencies.set('logs', true)
        }

        if (results.transactions) {
            _apis.set('transactions', results.transactions)
            _dependencies.set('transactions', true)
        }

        if (results.zoom || results.centrics || results.pfs || results.pnp || results.ddlab) {
            _apis.set('zoom', results.zoom)
            _apis.set('centrics', results.centrics)
            _apis.set('pfs', results.pfs)
            _apis.set('pnp', results.pnp)
            _apis.set('ddlab', results.ddlab)
            _dependencies.set('integrations', true)
        }
    })

    const getViewByTabName = (tabName) => {
        let viewName = 'order'

        switch (tabName) {
            case 'Transactions':
                viewName = 'transactions'
                break
            case 'Rescheduled/Moved':
                viewName = 'logs'
                break
        }

        return viewName
    }

    const getTabByViewName = (viewName) => {
        let tabName = 'Order'

        switch (viewName) {
            case 'transactions':
                tabName = 'Transactions'
                break
            case 'logs':
                tabName = 'Rescheduled/Moved'
                break
        }

        return tabName
    }

   const handleTabChange = (selectedTab) => {
        const nextView = getViewByTabName(selectedTab)

        if (nextView === _state.get('currentView'))
            return

        _transitions.set({
            currentView: _state.get('currentView'),
            nextView: nextView
        })
    }

    const handleTransition = () => {
        if (_transitions.get('nextView') !== null) {
            _state.set('currentView', _transitions.get('nextView'))

            setTab(getTabByViewName(_transitions.get('nextView')))

            _transitions.set({
                currentView: null,
                nextView: null
            })
        }
    }

    const renderRescheduleModal = () => {
        if (!_togglesModal.get('reschedule'))
            return

        return ReactDOM.createPortal(
            <RescheduleModal order={_dataOrder} onClose={() => _togglesModal.set('reschedule', false)} />,
            document.getElementById('ui-portals')
        )
    }

    const renderRefundModal = () => {
        if (!_togglesModal.get('refund'))
            return

        return ReactDOM.createPortal(
            <RefundModal order={_dataOrder} onClose={() => _togglesModal.set('refund', false)} />,
            document.getElementById('ui-portals')
        )
    }

    const renderPNPModal = () => {
        if (!_togglesModal.get('pnp'))
            return
        
        const dataPNP = _apis.get('pnp')

        return ReactDOM.createPortal(
            <Modal handleClose={() => _togglesModal.set('pnp', false)}>
                <ul className="pnp-container">
                    {dataPNP.marketing_image_url && (
                        <li>
                            <img src={dataPNP.marketing_image_url} />
                        </li>
                    )}
                    <li>
                        <em>Promo Code:</em> <a href={dataPNP.pnp_url}>{dataPNP.promo_code}</a>
                    </li>
                </ul>
            </Modal>,
        document.getElementById('ui-portals'))
    }

    const renderPFSModal = () => {
        if (!_togglesModal.get('pfs'))
            return

        const dataPFS = _apis.get('pfs')
        
        return ReactDOM.createPortal(
            <Modal handleClose={() => _togglesModal.set('pfs', false)}>
                <ul className="pfs-container">
                    <li><em>CORE Offer</em> <a href={dataPFS.url} target="_blank">{dataPFS.url}</a></li>
                    <li><em>Personalize Message</em> <a href={dataPFS.url_message} target="_blank">{dataPFS.url_message}</a></li>
                    <li><em>Nice List</em> <a href={dataPFS.url_nicelist} target="_blank">{dataPFS.url_nicelist}</a></li>
                </ul>
            </Modal>
        , document.getElementById('ui-portals'))
    }

    const renderZoomModal = () => {
        if (!_togglesModal.get('zoom'))
            return

        return ReactDOM.createPortal(
            <Modal handleClose={() => _togglesModal.set('zoom', false)}>
                <div className="zoom-container">
                    Zoom
                </div>
            </Modal>
        , document.getElementById('ui-portals'))
    }

    const renderAddress = (address) => {
        if (address) {
            return (
                <div className="customer-address">
                    {address.address1}<br />
                    {address.address2 ? (<>{address.address2}<br /></>) : null}
                    {address.city}, {address.state_province} {address.postal_code}<br />{address.country}
                </div>)
        } else {
            return null
        }
    }

    const renderTransactionsTab = () => {
        const dataTransactions = _apis.get('transactions')
        const classNames = _transitions.get('currentView') === 'transactions' ? 'ui-animation--fadeOut' : ''

        let data = dataTransactions?.data || []

        return (
            <UI.Layout
                name="transactionTab"
                identifier={name => `${name}`}
                config={{ classNames: classNames }}
                configPlaceholder={{
                    classNames: classNames,
                    dependencies: [_dependencies.get('transactions')],
                    placeholder: () => <PlaceholderGenericSpinnerComponent />
                }}
                callbacks={{
                    onAnimationEnd: handleTransition,
                    handlePlaceholderAnimationEnd: handleTransition,
                }}>
                <div className="transactions-container">
                    <ul className="transactions-titles">
                        <li>Data/Time</li>
                        <li>Authorization</li>
                        <li>Action</li>
                        <li>Message</li>
                        <li>Note</li>
                        <li>Amount</li>
                        <li>Successful?</li>
                        <li>By whom?</li>
                    </ul>

                    {data.length ? data.map((transaction, i) => {
                        return (
                            <ul className={`transactions-row ${!transaction.success ? 'unsuccessful' : ''}`} key={`transaction-${i}`}>
                                <li>
                                    <span className="transaction-row-mobile-column">Data/Time</span>
                                    <span className="transaction-row-value">{Utils.toDateString({ date: transaction.created_at, timezone: transaction.timezone })}</span>
                                </li>
                                <li>
                                    <span className="transaction-row-mobile-column">Authorization</span>
                                    <span className="transaction-row-value">{transaction.authorization}</span>
                                </li>
                                <li>
                                    <span className="transaction-row-mobile-column">Action</span>
                                    <span className="transaction-row-value">{transaction.action}</span>
                                </li>
                                <li>
                                    <span className="transaction-row-mobile-column">Message</span>
                                    <span className="transaction-row-value">{transaction.message}</span>
                                </li>
                                <li>
                                    <span className="transaction-row-mobile-column">Note</span>
                                    <span className="transaction-row-value">{transaction.note}</span>
                                </li>
                                <li>
                                    <span className="transaction-row-mobile-column">Amount</span>
                                    <span className="transaction-row-value">{transaction.amount?.human}</span>
                                </li>
                                <li>
                                    <span className="transaction-row-mobile-column">Successful?</span>
                                    <span className="transaction-row-value">{String(transaction.success)}</span>
                                </li>
                                <li>
                                    <span className="transaction-row-mobile-column">By whom?</span>
                                    <span className="transaction-row-value">{transaction.by}</span>
                                </li>
                            </ul>
                        )
                    }) : null}
                </div>
            </UI.Layout>
        )
    }

    const renderLogsTab = () => {
        const dataLogs = _apis.get('logs')
        const classNames = _transitions.get('currentView') === 'logs' ? 'ui-animation--fadeOut' : ''

        return (
            <UI.Layout
            name="logsTab"
            identifier={name => `${name}`}
            config={{ classNames: classNames }}
            configPlaceholder={{
                classNames: classNames,
                dependencies: [_dependencies.get('logs')],
                placeholder: () => <PlaceholderGenericSpinnerComponent />
            }}
            callbacks={{
                onAnimationEnd: handleTransition,
                handlePlaceholderAnimationEnd: handleTransition,
            }}>
                <div className="logs-container">
                    <ul className="logs-titles">
                        <li>Date/Time</li>
                        <li>From</li>
                        <li>To</li>
                        <li>Message</li>
                    </ul>

                    {dataLogs?.data && dataLogs?.data.map((log, i) => {
                        return (
                            <ul className={`logs-row`} key={`logs-${i}`}>
                                <li>
                                    <span className="log-row-mobile-column">Date/Time</span>
                                    <span className="log-row-value">{Utils.toDateString({ date: log.created_at, timezone: log.timezone })}</span>
                                </li>
                                <li>
                                    <span className="log-row-mobile-column">From</span>
                                    <span className="log-row-value">{log.from}</span>
                                </li>
                                <li>
                                    <span className="log-row-mobile-column">To</span>
                                    <span className="log-row-value">{log.to}</span>
                                </li>
                                <li>
                                    <span className="log-row-mobile-column">Message</span>
                                    <span className="log-row-value">{log.message}</span>
                                </li>
                            </ul>
                        )
                    })}
                </div>
            </UI.Layout>
        )
    }

    const renderOrderTab = () => {
        const classNames = _transitions.get('currentView') === 'order' ? 'ui-animation--fadeOut' : ''

        return (
            <UI.Layout
                name="orderTabMain"
                identifier={name => `${name}`}
                config={{ classNames: classNames }}
                configPlaceholder={{
                    classNames: classNames,
                    dependencies: [_dependencies.get('market')],
                    placeholder: ({ name }) => <PlaceholderGenericSpinnerComponent />
                }}
                callbacks={{
                    onAnimationEnd: handleTransition,
                    handlePlaceholderAnimationEnd: handleTransition,
                }}>
                <OrderDetails _state={_apis} _togglesModal={_togglesModal} _isOrderLocked={_isOrderLocked} />

                <div className={_isOrderLocked ? 'disabled' : ''}>
                    {renderCustomFields()}
                    {renderPackageDetails()}
                    {renderPricingBreakdown()}
                </div>
            </UI.Layout>
        )
    }

    const renderTab = () => {
        switch (_state.get('currentView')) {
            case 'order':
                return renderOrderTab()
            case 'transactions':
                return renderTransactionsTab()
            case 'logs':
                return renderLogsTab()
            default:
                return null
        }
    }

    const renderCustomFields = () => {
        if (!_dataOrder.fields?.data?.length)
            return

        let fields = []
        let group = []
        
        let lastSetNumber

        _dataOrder.fields.data.forEach((item, index) => {
            if (!index) {
                lastSetNumber = item.set_number
            }

            group.push(item)

            if (lastSetNumber !== item.set_number) {
                fields.push(group)
                group = []
            }
        })

        if (fields.length === 0) return null;
        
        return (
            <div className="custom-fields-container">
                <div className="custom-fields-title">
                    <div className="flex">
                        <span className="title">Custom Field Details</span>
                    </div>
                </div>

                <div>
                    {fields.map((group, i) => {
                        return (
                            <ul className="custom-field-updaters grid grid-cols-1 md:grid-cols-3 gap-2" key={`custom-field-updaters-${i}`}>
                                {group.map((item, index) => {
                                    return (
                                        <li key={`custom-field-updaters-${i}-${item.id}-${index}`}>
                                            <FieldUpdater field={item} studioId={_studioId}
                                                editable={item.type !== 'children' || hasAccessToSensitiveInformation} />
                                        </li>
                                    )
                                })}
                            </ul>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderPackageDetailsBPS = (bps, type) => {
        const count = bps.data.length

        return (
            <ul className={`package-details-bps-${type}`}>
                {bps.data.map((e, i) => {
                    return (
                        <li key={`package-details-bps-${type}-${i}`}>
                            <em>{e.name}</em>
                            <img src={e.image_url} />
                        </li>
                    )
                })}
            </ul>
        )
    }

    const renderDiscounts = () => {
        if (!_dataOrder.discounts?.data?.length)
            return
        
        return (
            <li><em>Discounts</em> {Utils.formatDollar(_dataOrder.discount)}</li>
        )
    }

    const renderDonation = () => {
        if (!_dataOrder.donation)
            return
        
        return (
            <li><em>Donation</em> {Utils.formatDollar(_dataOrder.donation?.amount?.dollars_and_cents)}</li>
        )
    }

    const renderCouponInfo = () => {
        if (!_dataOrder?.coupon?.id)
            return
        
            return (
                <li><em>{_dataOrder.coupon.name}</em> ({Utils.formatDollar(_dataOrder.coupon.discount_amount)})</li>
            )
    }

    const renderPricingBreakdown = () => {
        const dataMarket = _apis.get('market')
        const dataMarketingPlan = _apis.get('marketingPlan')
        
        if (!dataMarket || !dataMarketingPlan)
            return

        return (
            <div className="order-pricing-breakdown">
                <div className="special-instructions">
                    {dataMarket.special_instructions && (
                        <>
                            <h2>Special Instructions</h2>

                            <div dangerouslySetInnerHTML={{ __html: dataMarket.special_instructions }} />
                        </>
                    ) || null}
                </div>
                

                <ul className="totals">
                    <li><em>Tax</em> {Utils.formatDollar(_dataOrder.tax)}</li>
                    <li><em>Register Subtotal</em> {Utils.formatDollar(_dataOrder.register_total)}</li>
                    <li><em>Shipping and Handling</em> {Utils.formatDollar(_dataOrder.snh)}</li>
                    <li><em>{dataMarketingPlan.convenience_fee_text}</em> {Utils.formatDollar(_dataOrder.convenience_fee_total)}</li> {/* // @TODO: from marketing plan */}
                    {renderDiscounts()}
                    {renderCouponInfo()}
                    {renderDonation()}
                    <li><em>Grand Total</em> {Utils.formatDollar(_dataOrder.grand_total)}</li>
                    <li><em>Paid</em> {Utils.formatDollar(_dataOrder.paid)}</li>
                    {_dataOrder.refundable ? (<li><em>Refunded</em> {Utils.formatDollar(_dataOrder.refunded)}</li>) : null}
                    <li><em>Balance {_dataOrder.balance > 0 && <Tooltip>This order has a pending balance</Tooltip>}</em> {Utils.formatDollar(_dataOrder.balance)}</li>
                </ul>
            </div>
        )
    }

    const renderPackageDetails = () => {
        const dataPackages = _apis.get('order').packages

        if (!dataPackages?.data)
            return

        return dataPackages.data.map(item => {
            return (
                <PackageDetails key={`ui-key--packageDetails--${item.id}`} entity={item} studioId={_studioId} />
            )
        })
    }

    const renderPayModal = () => {
        if (!_togglesModal.get('pay'))
            return

        return <PayModalComponent _apis={_apis} _togglesModal={_togglesModal} events={{ onClose: () => _togglesModal.set('pay', false) }} />
    }

    const renderDiscountModal = () => {
        if (!_togglesModal.get('discount'))
            return

        return <DiscountModalComponent _apis={_apis} _togglesModal={_togglesModal} events={{ onClose: () => _togglesModal.set('discount', false) }} />
    }

    const renderRemaining = () => {
        if (!_apis.get('order'))
            return

        return (
            <>
                {renderTab()}
                {renderRescheduleModal()}
                {renderZoomModal()}
                {renderPFSModal()}
                {renderPNPModal()}
                {renderRefundModal()}
                {renderPayModal()}
                {renderDiscountModal()}
            </>
        )
    }

    const _sectionLayoutOrderPage = {
        name: "orderPage",
        identifier: name => `${name}`,
        type: 'page',
        configPlaceholder: {
            placeholder: ({ name }) => <PlaceholderGenericSpinnerComponent />,
            dependencies: [_dependencies.get('order')]
        }
    }

    const _sectionLayoutOrderPageContent = {
        name: "orderPageContent",
        configPlaceholder: {
            placeholder: ({ name }) => <PlaceholderGenericSpinnerComponent />,
            dependencies: [_dependencies.get('market')]
        }
    }

    const _sectionLayoutOrderPageIntegrations = {
        name: "orderPageIntegrations",
        configPlaceholder: {
            placeholder: ({ name }) => <PlaceholderGenericSpinnerComponent type="inline" />,
            dependencies: [_dependencies.get('integrations')]
        }
    }

    return (  
        <OrderProvider value={{ reload: _reload }}>
            <UI.Layout {..._sectionLayoutOrderPage}>
                <>
                    <PageHeader title={`Order - ${_dataOrder?.fastpass}`} />

                    <UI.Layout {..._sectionLayoutOrderPageContent}>
                        <div className="primary-title">
                            <div className="title">
                                <em>{_dataOrder?.market_name}</em> {_dataOrder?.market_theme}
                            </div>

                            {/* <UI.Layout {..._sectionLayoutOrderPageIntegrations}>
                                {renderIntegrationsDropdown()}
                            </UI.Layout> */}
                        </div>

                        <div className="primary-tabs">
                            <Tabs tabs={tabs} tab={tab} events={{ handleChange: handleTabChange }} />
                        </div>

                        {renderRemaining()}
                    </UI.Layout>
                </>
            </UI.Layout>
        </OrderProvider>
    )
}

export default OrderPage
