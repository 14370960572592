import RouteHelper from './route'
import FetchHelper from './fetch'
import dayjs from 'dayjs';
import lodash from 'lodash';
import * as XLSX from 'xlsx'
import { firstValueFrom, from, mergeAll, reduce } from 'rxjs';

const Helpers = {

    scrollTop: () => {
        window.scrollTo(0, 0)
    },

    refreshPage: () => {
        window.location.reload(false);
    },

    generateUUID: () => {
        // Generate a random array of 16 bytes
        const randomBytes = new Uint8Array(16);
        crypto.getRandomValues(randomBytes);

        // Set version (4) and variant (8, 9, A, or B) bits
        randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40;
        randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80;

        // Convert the byte array to a hexadecimal string
        const hex = Array.from(randomBytes)
          .map((byte) => byte.toString(16).padStart(2, '0'))
          .join('');

        // Format the UUID string
        const uuid = `${hex.substr(0, 8)}-${hex.substr(8, 4)}-${hex.substr(12, 4)}-${hex.substr(16, 4)}-${hex.substr(20)}`;

        return uuid;
    },

    toBase64: file => new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, ''))
        reader.onerror = error => reject(error)
    }),

    filterByTags: (tags, filteredList) => {
        if (tags.active.length) {
            filteredList.data = filteredList.data.filter((item) => {
                let unfiltered = true
    
                tags.active.every((tag) => {
                    if (item.tags.indexOf(tag) === -1) {
                        unfiltered = false
                    }
    
                    return tag
                })
    
                return unfiltered
            })
        }
    },

    getDate: (msOffset = 0) => {
        let date = new Date()
        const offset = date.getTimezoneOffset()

        date = new Date(date.getTime() - (offset * 60 * 1000) + msOffset)

        return date.toISOString().split('T')[0]
    },

    toDateString: ({ date, timezone = 'America/New_York', displayWeekday = false, displayTime = false, displayTimeOnly = false, displayTimezone = false }) => {
        const _date = new Date(date)
        const _timezone = _date.toLocaleTimeString('en-US', { timeZone: timezone, timeZoneName: 'short' }).split(' ')[2]
        const config = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }

        if (displayWeekday) {
            config.weekday = 'long'
        }

        if (displayTimeOnly) {
            return `${_date.toLocaleTimeString('en-us')} ${displayTimezone ? _timezone : ''}`
        } else {
            return `${_date.toLocaleDateString('en-us', config)} ${displayTime ? _date.toLocaleTimeString('en-us') : ''} ${displayTimezone ? _timezone : ''}`
        }
    },

    toTimeString: (str) => {
        return dayjs(str, "HH:mm:ss").format("hh:mm:ss A")
    },

    // https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
    toDataURL: (src, callback, outputFormat) => {
        var img = new Image();

        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;

            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;

            ctx.drawImage(this, 0, 0);

            dataURL = canvas.toDataURL(outputFormat);

            callback(dataURL);
        };

        img.src = src;

        if (img.complete || img.complete === undefined) {
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
            img.src = src;
        }
    },

    // https://stackoverflow.com/a/16233919
    formatDollar: (val, currency = 'USD') => {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val);
    },

    format12HourTime: (time) => {
        const [h, m] = time.split('T')[1].split(':')
        
        let hours = parseInt(h)
        let minutes = parseInt(m)

        if (h > 12) {
            hours = h - 12
        }

        if (hours === 0) {
            hours = 12
        }

        if (minutes < 10) {
            minutes = '0' + minutes
        }

        return (hours + ':' + minutes) + ' ' + ((h >= 12) ? 'PM' : 'AM')
    },

    formatErrorsIntoList: (errors) => {
        return (
            <ul>
                {errors.map((error, i) => {
                    return <li key={`error-list-${i}`}>{error}</li>
                })}
            </ul>
        )
    },

    getLocalStorage: (key, isJson = false) => {
        let value = null

        if (isJson) {
            try {
                value = JSON.parse(localStorage.getItem(key))
            } catch (e) { }
        } else {
            value = localStorage.getItem(key)
        }

        return value || null
    },

    setLocalStorage: (key, value, isJson = false) => {
        if (isJson) {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            localStorage.setItem(key, value)
        }
    },

    removeLocalStorage: (key) => {
        localStorage.removeItem(key);
    },

    insertAtCaret: (areaId, text) => {
        var txtarea = document.getElementById(areaId);
        var scrollPos = txtarea.scrollTop;
        var strPos = 0;
        var br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
            "ff" : (document.selection ? "ie" : false));
        if (br == "ie") {
            txtarea.focus();
            var range = document.selection.createRange();
            range.moveStart('character', -txtarea.value.length);
            strPos = range.text.length;
        }
        else if (br == "ff") strPos = txtarea.selectionStart;

        var front = (txtarea.value).substring(0, strPos);
        var back = (txtarea.value).substring(strPos, txtarea.value.length);
        txtarea.value = front + text + back;
        strPos = strPos + text.length;
        if (br == "ie") {
            txtarea.focus();
            var range = document.selection.createRange();
            range.moveStart('character', -txtarea.value.length);
            range.moveStart('character', strPos);
            range.moveEnd('character', 0);
            range.select();
        }
        else if (br == "ff") {
            txtarea.selectionStart = strPos;
            txtarea.selectionEnd = strPos;
            txtarea.focus();
        }
        txtarea.scrollTop = scrollPos;
    },

    // https://stackoverflow.com/a/19876218
    trimTextareaColumns: (value) => {
        let maxLength = 10
        let lines = value.split(/(\r\n|\n|\r)/gm); 

        for (var i = 0; i < lines.length; i++) {
            if (lines[i].length > maxLength) {
                lines[i] = lines[i].substring(0, maxLength);
            }
        }
        
        return lines.join('')
    },

    downloadImage: async (imageSrc, imageFilename) => {
        const image = await fetch(imageSrc)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
      
        const link = document.createElement('a')
        link.href = imageURL
        link.download = imageFilename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    },
    
    downloadBlob: (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = url;
        a.download = filename || 'Untitled'

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    },

    handleHeaderEvents: (response) => {
        const accessControlHeader = response.headers.get('X-Access-Control');
        const mockHeader = response.headers.get('x-mocked');

        if (accessControlHeader) {
            RouteHelper.redirect('/')
        }

        if (mockHeader) {
            Helpers.setLocalStorage('UI:Mocked', true)
        }
    },

    // --- a safe way to execute a promise and return the response/error
    executePromise: async (promise) => {
        let res = [null, null];
        try {
            let response = await promise;
            if (response instanceof Error) throw response;
            res[0] = response;
        } catch (error) {
            res[1] = error;
        }
        return res;
    },

    // --- execute observables with parallel execution limit (eg. max 10 observable executions should run parallel)
    executeObservablesWithParallelLimit: async (
        observables,
        limit = 10,
        onProgress
    ) => {
        let processedCount = 0;
        return Helpers.executePromise(
            firstValueFrom(
                from(observables)
                    .pipe(mergeAll(limit))
                    .pipe(
                        reduce((acc, val) => {
                            if (onProgress) onProgress(++processedCount);
                            return acc.concat(val);
                        }, [])
                    )
            )
        );
    },

    handleUnauthorizedError: async () => {
        // --- if its public page (eg. login), do nothing
        if (RouteHelper.isPublicRoute()) return;

        // --- we assume that unAuthorized error will occur when the user session is expired,
        // so we will logout the user & redirect the user to the login page in this case
        await Helpers.executePromise(
            FetchHelper({
                url: '/api/v2/admin/logout',
                method: 'delete'
            })
        )
        
        RouteHelper.clearCurrentURL()
        RouteHelper.redirectToRoot()
    },

    stringifyEmails: (emails) => {
        if (typeof emails === 'string') emails = emails.split(';')
        return (emails || []).map(email => email.trim()).filter(e => e).join(";")
    },

    tableDataToExcelCompatibleArray: (data, generateHeaders = true, customFormats) => {
        // customFormats can have key/value pairs where key = type of field/column and value = a function returning formatted value
        let columns = data.columns || []
        let columnsMap = lodash.keyBy(columns, 'key')
        let rows = data.data || []
        let headersRow = generateHeaders ? lodash.map(columns, 'title') : undefined;
        let dataRows = lodash.map(rows, row => {
            return lodash
                .chain(row)
                .pickBy((value, key) => !!columnsMap[key])
                .map((value, key) => {
                    let column = columnsMap[key];
                    if (customFormats && customFormats[column.type]) {
                        let formatFunction = customFormats[column.type];
                        value = formatFunction(value);
                    }
                    return value;
                })
                .value();
        });
        return generateHeaders ? [headersRow, ...dataRows] : dataRows;
    },

    tableDataToCSVCompatibleArray: (data, generateHeaders = true, customFormats) => {
        // customFormats can have key/value pairs where key = type of field/column and value = a function returning formatted value
        let columns = data.columns || []
        let rows = data.data || []
        let headersRow = generateHeaders ? lodash.map(columns, 'title') : undefined;
        let dataRows = lodash.map(rows, row => {
            return lodash.map(columns, column => {
                let value = lodash.get(row, column.key);
                if (customFormats && customFormats[column.type]) {
                    let formatFunction = customFormats[column.type];
                    value = formatFunction(value);
                }
                return value;
            });
        });
        return generateHeaders ? [headersRow, ...dataRows] : dataRows;
    },

    arrayToCsv: (data) => {
        return data
            .map((row) => row
                .map((v) => v === null || v === undefined ? "" : v) // handle null/undefined
                .map(String) // convert every value to String
                .map((v) => v.replaceAll('"', '""')) // escape double quotes
                .map((v) => `"${v}"`) // quote it
                .join(",") // comma-separated
            )
            .join("\r\n"); // rows starting on new lines
    },

    exportToCsv: (csvData, filename = 'Untitled') => {
        let blob = new Blob([csvData], { type: 'text/csv' });
        Helpers.downloadBlob(blob, `${filename || 'Untitled'}.csv`);
    },

    dayjsSafeFormat: (value, format, timezone) => {
        try {
            return dayjs(value).tz(timezone).format(format)
        } catch (e) {
            return dayjs(value).format(format)
        }
    },

    exportToExcel: (excelData, filename = 'Untitled', worksheetConfigs) => {
        // process filename
        filename = filename.replace(/[^a-z0-9 -]/gi, '_');

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(excelData, { skipHeader: true });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet);

        /* calculate column width */
        worksheet["!cols"] = excelData.reduce((acc, row) => {
            let currentMaxChars = lodash.map(row, (value, key) => {
                let maxChars = value?.toString().length || 0;
                return { wch: maxChars };
            });
            if(acc.length === 0) return currentMaxChars;
            return acc.map((col, idx) => {
                return {
                    wch: Math.max(col.wch, currentMaxChars[idx].wch)
                };
            });
        }, [])

        // apply worksheet configs
        if(worksheetConfigs) {
            Object.keys(worksheetConfigs).forEach((key) => {
                worksheet[key] = worksheetConfigs[key];
            });
        }

        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSX.writeFile(workbook, `${filename || 'Untitled'}.xlsx`, { compression: true });
    },

    preProcess422Errors: (fields, errors) => {
        if(!fields || !errors) return errors;

        // --- map errors based on related fields
        lodash.each(fields, field => {
            let fieldKey = field.name || field.id;
            lodash.each(field.relatedFields, relatedField => {
                if (errors[relatedField]) {
                    if (!errors[fieldKey]) errors[fieldKey] = []
                    errors[fieldKey] = errors[fieldKey].concat(errors[relatedField])
                }
            })
            if (errors[fieldKey])
                errors[fieldKey] = lodash.uniq(errors[fieldKey])
        })

        return errors;
    },

    isURL: (str) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        return !!pattern.test(str);
    },

    extractQueryParams: (url) => { 
        let queryParams = {}
        let urlParts = url.split('?')
        if (urlParts.length > 1) {
            let params = urlParts[1].split('&')
            params.forEach(param => {
                let [key, value] = param.split('=')
                queryParams[key] = value
            })
        }
        return queryParams
    },

    bypassAccessText: (text) => {
        if (text?.toLowerCase().startsWith('you do not have access'))
            return ''
        return text
    }
}

export default Helpers
