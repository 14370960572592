import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FieldSelectorComponent from '../../components/FieldSelector'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'
import ProjectorComponent from '../../components/Projector'
import AssetUploader from '../../componentsV2/Integrated/AssetUploader'

import RouteHelper from '../../helpers/route'
import Tooltip from '../../components/Tooltip'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'
import useTabs from '../../hooks/useTabs'
import { useSimpleState } from '../../UI/hooks'

import './index.css'
import { getBasicFormFields, getDigitalDeliveryFormFields, getEmphasisFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const AddonsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Addons/New')
    
    const [errors, setErrors] = useState()
    const [data, setData, clearData, dataDefault] = useDraft('PSM:Addons:Data')
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('PSM:Addons:DataMain')
    const [dataEmphasis, setDataEmphasis, clearDataEmphasis, dataEmphasisDefault] = useDraft('PSM:Addons:DataEmphasis')
    const [dataDigitalDelivery, setDataDigitalDelivery, clearDataDigitalDelivery, dataDigitalDeliveryDefault] = useDraft('PSM:Addons:DataDigitalDelivery')
    const [dataImage, setDataImage, clearDataImage, dataImageDefault] = useDraft('PSM:Addons:DataImage')
    const [dataFields, setDataFields, clearDataFields, dataFieldsDefault] = useDraft('PSM:Addons:DataFields', [])
    const [dataCascadeAddons, setDataCascadeAddons, clearDataCascadeAddons, dataCascadeAddonsDefault] = useDraft('PSM:Addons:DataCascadeAddons', [])
    const [dataCouponCodes, setDataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault] = useDraft('PSM:Addons:DataCouponCodes', [])
    const [optionsCategories, setOptionsCategories] = useState([])
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Basic', ['Basic', 'Emphasis', 'Cascade Addons', 'Digital Delivery', 'Image', 'Fields', 'Coupon Codes'])

    const [basicFormFields, setBasicFormFields] = useState(getBasicFormFields())
    const [emphasisFormFields] = useState(getEmphasisFormFields())
    const [digitalDeliveryFormFields] = useState(getDigitalDeliveryFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getBasicFormFields(suggestionsTags, optionsCategories);
        setBasicFormFields(fields)
    }, [suggestionsTags, optionsCategories])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Basic',
                forms: [{ id: 'Main', fields: basicFormFields }]
            },
            {
                tab: 'Emphasis',
                forms: [{ id: 'Main', fields: emphasisFormFields }]
            },
            {
                tab: 'Digital Delivery',
                forms: [{ id: 'Main', fields: digitalDeliveryFormFields }]
            },
            {
                tab: 'Image',
                forms: [{ id: 'Image', fields: _image.get(), fieldKeyProp: 'id' }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    const {_state: _image} = useSimpleState([{
        type: 'Image',
        id: 'image',
        externalUrl: '',
        note: 'We suggest rectangular for image as description and square for text description, e.g. 800x300 or 600x600. Images will be scaled proportionally',
        attachedImage: {
            data: '',
            filename: ''
        },
        assetSource: '1',
        relatedFields: ['image.data_url']
    }])

    const handleSubmit = () => {
        const promise = new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let imageBody = {}

            _image.get().forEach((item, index) => {
                imageBody.image_attributes = {
                    data_base64: /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                    data_url: item.externalUrl,
                    data_source: item.assetSource === '0' ? 'local' : 'remote'
                }
            })

            let body = {...dataMain, ...dataEmphasis, ...dataDigitalDelivery, ...imageBody}
    
            let attributes = [{
                data: dataCascadeAddons,
                attribute: 'cascade_addons_attributes',
                prop: 'addon_id'
            },{
                data: dataFields,
                attribute: 'custom_fields_attributes',
                prop: 'field_id'
            },{
                data: dataCouponCodes,
                attribute: 'item_coupon_codes_attributes',
                prop: 'coupon_code_id'
            }]
            
            attributes.forEach((config) => {
                if (!config.data.length)
                    return
    
                body[config.attribute] = config.data.filter((item) => {
                    return item.item.id
                }).map((item, index) => {
                    const itemData = item.item
    
                    let body = {
                        [config.prop]: itemData.id,
                    }
    
                    if (itemData._destroy) {
                        body._destroy = true
                    }
    
                    return body
                })
            })
    
            body.tag_names = body.tags
            delete body.tags
    
            FetchHelper({
                url: `/api/v2/admin/addons`,
                method: 'POST',
                body: body
            }, (res) => {
                ac.showSpinner(false)

                resolve()
            }, (res) => {
                ac.showSpinner(false)
    
                if (res.status === 422) {
                    let errors = UtilsHelper.preProcess422Errors(
                        [..._image.get()],
                        res.body
                    )
                    setErrors(errors)
                    UtilsHelper.scrollTop()
                }
    
                reject()
            })
        })

        return promise
    }

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/addons/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/categories'
        }, (res) => {
            let categories = [{ id: 0, title: 'Base', value: '' }];
            res.body.data.map((item, i) => {
                const option = {
                    id: i+1,
                    title: item.name,
                    value: item.id
                }
                categories.push(option)
            })
            setOptionsCategories(categories)
        }, (res) => {
        })
    }, [])

    return (
        <div className="addons-edit-page-container page-container">
            <PageHeaderComponent title={`New Addon`} />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />

            <div className={`page-form-container ${tab === 'Basic' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Basic" notices={notices} />
                <FormComponent formData={dataMain} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={basicFormFields} />
            </div>
            
            <div className={`page-form-container ${tab === 'Emphasis' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Emphasis" notices={notices} />
                <FormComponent formData={dataEmphasis} formErrors={errors} setFormErrors={setErrors} onChange={setDataEmphasis} displayErrorBanner={true} fields={emphasisFormFields} />
            </div>

            <div className={`page-form-container ${tab === 'Cascade Addons' ? 'show' : ''}`}>
                {dataEmphasis && dataEmphasis.emphasized === 'true' ? (
                    <div className="cascade-addons">
                        <NoticeComponent location="Tab/CascadeAddons" notices={notices} />
                        <h2>Cascade Addons <Tooltip>Cascaded addons will not allow you to set price overrides in the package plan, cannot associate with poses, sheets and backgrounds and if they are regular add-ons,  will use the default category tab</Tooltip></h2>
                        <FieldSelectorComponent buttonTitle="Addon" apiURL="/api/v2/admin/addons" editUrl={(id) => `/addons/${id}/edit`} data={dataCascadeAddons || []} onChange={(data) => { if (data.length) setDataCascadeAddons(data) }} />
                    </div>
                ) : (
                    <div className="">
                        <div className="page-message">
                            <i className="bi bi-info-circle"></i> <span>Must be emphasized to add addons</span>
                        </div>
                    </div>
                )}
            </div>

            <div className={`page-form-container ${tab === 'Digital Delivery' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/DigitalDelivery" notices={notices} />
                <FormComponent formData={dataDigitalDelivery} formErrors={errors} setFormErrors={setErrors} onChange={setDataDigitalDelivery} displayErrorBanner={true} fields={digitalDeliveryFormFields} />
            </div>

            <div className={`page-form-container ${tab === 'Image' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Image" notices={notices} />
                <AssetUploader list={_image.get()} key={tab} events={{ handleChange: (value) => _image.set(value) }}
                    errors={errors} setErrors={setErrors} />
            </div>

            <div className={`page-form-container ${tab === 'Fields' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/Fields" notices={notices} />
                <FieldSelectorComponent buttonTitle="Field" apiURL="/api/v2/admin/fields" editUrl={(id) => `/fields/${id}/edit`} data={dataFields || []} onChange={(data) => { if (data.length > 0) setDataFields(data) }} />
            </div>

            <div className={`page-form-container ${tab === 'Coupon Codes' ? 'show' : ''}`}>
                <NoticeComponent location="Tab/CouponCodes" notices={notices} />
                <FieldSelectorComponent sortable={false} buttonTitle="Coupon Code" apiURL="/api/v2/admin/coupon-codes" editUrl={(id) => `/coupon-codes/${id}/edit`} data={dataCouponCodes || []} onChange={(data) => { if (data.length) setDataCouponCodes(data) }} />
            </div>

            <ControlBar
                handleSubmit={handleSubmit}
                cancelUrl="/addons" 
                handleAfterSubmit={() => {
                    RouteHelper.redirect('/addons')
                }} handleAfterClear={() => {
                    setTab('Basic')
                    RouteHelper.reload()
                }} dataItems={[
                    [data, clearData, dataDefault],
                    [dataMain, clearDataMain, dataMainDefault],
                    [dataEmphasis, clearDataEmphasis, dataEmphasisDefault],
                    [dataDigitalDelivery, clearDataDigitalDelivery, dataDigitalDeliveryDefault],
                    [dataImage, clearDataImage, dataImageDefault],
                    [dataFields, clearDataFields, dataFieldsDefault],
                    [dataCascadeAddons, clearDataCascadeAddons, dataCascadeAddonsDefault],
                    [dataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault]
                ]} />
        </div>
    )
}

export default AddonsNew
