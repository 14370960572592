
import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { UI } from '../../../../UI'

import {
    EllipsisMenuComponent, ModalComponent
} from '../../../../componentsV2/Complex'

import {
    PlaceholderGenericSpinnerComponent,
    SelectWithSearchModalComponent,
    QuantityModalComponent
} from '../../../../componentsV2/Integrated'

import FieldUpdater from '../../../../components/FieldUpdater'

import { PackageDetailsEntitySelector } from '../PackageDetailsEntitySelector'

import { Fetch, Utils} from '../../../../helpers'
import RouteHelper from '../../../../helpers/route'

import './index.css'
import { useAppContext } from '../../../../hooks'

const AddonsDetails = ({ entity, studioId, events={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        entity: entity,
        modalPage: 1,
        modalTerm: '',
    })

    const {_state: _apis} = UI.useSimpleState({
        packages: null,
        addons: null
    })

    const {_state: _dependencies} = UI.useSimpleState({
        packages: null
    })

    const {_state: _toggles} = UI.useSimpleState({
        modalPackage: false,
        modalQuantity: false,
        syncing: false
    })

    const _mountedRef = React.useRef(null)
    const _syncCountRef = React.useRef(0)
    const _searchTermTimerRef = React.useRef(null)

    const _entity = _state.get('entity')

    const [ac] = useAppContext()
    const [hasAccessToSensitiveInformation, setHasAccessToSensitiveInformation] = useState(ac.AuthHelper.hasPermission('sensitive_information'))

    useEffect(() => {
        setHasAccessToSensitiveInformation(
            ac.AuthHelper.hasPermission('sensitive_information')
        ) 
    }, [ac.AuthHelper.hasPermission('sensitive_information')])

    useEffect(() => {
        if (_mountedRef && !_mountedRef.current) {
            _mountedRef.current = true

            syncGetPackages({ term: _state.get('modalTerm'), page: _state.get('modalPage') })
        }
    }, [])

    const incrementSyncCounter = () => {
        _syncCountRef.current = _syncCountRef.current + 1
    }

    const decrementSyncCounter = () => {
        _syncCountRef.current = _syncCountRef.current - 1

        if (_syncCountRef.current < 0)
            _syncCountRef.current = 0
    }
    
    const turnSyncingFlagOn = () => {
        incrementSyncCounter()

        _toggles.set('syncing', true)
    }

    const turnSyncingFlagOff = () => {
        decrementSyncCounter()

        if (_syncCountRef?.current === 0) {
            _toggles.set('syncing', false)
        }
    }

    const updateQuantity = (value) => {
        let newEntity = {..._state.get('entity')}

        newEntity.quantity = value

        _state.set('entity', newEntity)
    }

    const syncUpdateEntity = (item) => {
        turnSyncingFlagOn()

        Fetch({
            url: item.package_url
        }, (res) => {
            _state.set('entity', res.body)
            turnSyncingFlagOff()
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const syncDeletePackage = (item) => {
        turnSyncingFlagOn()

        // Fetch({
        //     url: item.url,
        //     headers: {
        //         'x-studio-id': studioId
        //     },
        //     method: 'DELETE'
        // }, (res) => {
        //     syncGetSelections()
        //     handleChange()
        //     turnSyncingFlagOff()
        // }, (res) => {
        //     turnSyncingFlagOff()
        // })
    }

    const syncGetPackages = ({ term='', page=1 }) => {
        turnSyncingFlagOn()

        Fetch({
            url: `${entity.alternatives_url}?page=${page}&q=${term}`,
            headers: {
                'x-studio-id': studioId
            },
            method: 'GET'
        }, (res) => {
            _apis.set('packages', res.body)
            _state.set('modalPage', res.body.current_page || page)
            _state.set('modalTerm', term)

            _dependencies.set('packages', true)

            turnSyncingFlagOff()
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const handleSearchPageChange = (page) => {
        syncGetPackages({ page, term: _state.get('modalTerm') })
    }

    const handleSearchTermChange = (term) => {
        window.clearTimeout(_searchTermTimerRef.current)

        _searchTermTimerRef.current = window.setTimeout(() => {
            syncGetPackages({ term, page: 1 })
        }, 250)
    }

    const handlePackageSelect = (item) => {
        _toggles.set('modalPackage', false)

        turnSyncingFlagOn()

        Fetch({
            url: entity.url,
            method: 'put',
            body: {
                addon: {
                    addon_id: item.addon_id
                }
            }
        }, (res) => {
            _state.set('entity', res.body)
            turnSyncingFlagOff()
            RouteHelper.reload()
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const handlePackageQuantityUpdate = (value) => {
        turnSyncingFlagOn()

        Fetch({
            url: entity.url,
            method: 'put',
            body: {
                addon: {
                    quantity: value
                }
            }
        }, (res) => {
            updateQuantity(res.body.quantity)
            turnSyncingFlagOff()
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const generateEntityLink = (item) => {
        return `/addons/${item.addon_id}/edit`
    }

    const renderPackageDetailsDropdown = () => {
        let options = [{
            title: 'Change Addon',
            callback: () => _toggles.set('modalPackage', true)
        }]

        if (_entity.available)
            options.push({
                title: 'Change Quantity',
                callback: () => _toggles.set('modalQuantity', true)
            })

        return (
            <div className="package-details-dropdown">
                <EllipsisMenuComponent items={options} />
            </div>
        )
    }

    const renderPackageDetailsFields = (fields, row) => {
        return (
            <ul className="package-details-fields grid grid-cols-1 md:grid-cols-3 gap-3">
                {fields.data.map((e, i) => {
                    return (
                        <li key={`package-details-field-updater-${i}`} className="span-">
                            <FieldUpdater field={e} studioId={studioId} 
                                editable={row.available && (e.type !== 'children' || hasAccessToSensitiveInformation)} />
                        </li>
                    )
                })}
            </ul>
        )
    }

    const renderPackageDetailsRow = (row) => {
        let addonsAll = null
        let addons = null
        let backgrounds = null
        let sheets = null
        let poses = null
        let fields = null

        if (row.addons) {
            // addonsAll = row.addons.data.map((item, index) => {
            //     return (
            //         <>
            //             <hr className="ui-global--hr" />
            //             <div key={`package-details-row-${index}`}>
            //                 {/* {renderPackageDetailsRow(item, 'addon')} */}
            //                 {/* <PackageDetails entity={item} studioId={studioId} /> */}
            //             </div>
            //         </>
            //     )
            // })

            addons = (
                <div className="container-addons">
                    <PackageDetailsEntitySelector entity={row.addons} title="Addons" type="addon" studioId={studioId} />
                </div>
            )
        }

        if (row.backgrounds?.total_required) {
            backgrounds = (
                <div className="container-backgrounds">
                    <PackageDetailsEntitySelector entity={row.backgrounds} title="Backgrounds" type="background" studioId={studioId} />
                </div>
            )
        }

        if (row.sheets?.total_required) {
            sheets = (
                <div className="container-sheets">
                    <PackageDetailsEntitySelector entity={row.sheets} title="Sheets" type="sheet" studioId={studioId} />
                </div>
            )
        }

        if (row.poses?.total_required) {
            poses = (
                <div className="container-poses">
                    <PackageDetailsEntitySelector entity={row.poses} title="Poses" type="pose" studioId={studioId} />
                </div>
            )
        }

        if (row.fields?.data?.length) {
            fields = (
                <div className="container-fields">
                    <em className="title light">Fields</em>

                    {renderPackageDetailsFields(row.fields, row)}
                </div>
            )
        }

        return (
            <>
                <div className="package-details-package-title">
                    <i className="bi bi-arrow-return-right"></i> <Link to={generateEntityLink(row)} target="_blank" className="title">{row.name}</Link> {renderPackageDetailsDropdown()}

                    <span className="pricing-info">
                        <span>{Utils.formatDollar(row.price)}</span>
                        <span className="qty">{row.quantity}</span>
                        <span>{Utils.formatDollar(row.total_price)}</span>
                    </span>
                </div>

                <div className={`package-details-sections-wrapper ${!_entity.available ? 'ui-global--disabled' : ''}`}>
                    {(backgrounds || sheets || poses) && (
                        <ul className="package-details-sections">
                            {backgrounds && (<li className="backgrounds">{backgrounds}</li>) || null}
                            {sheets && (<li className="sheets">{sheets}</li>) || null}
                            {poses && (<li className="poses">{poses}</li>) || null}
                        </ul>
                    )}

                    {addons && (
                        <ul className="package-details-sections">
                            <li className="addons">{addons}</li>
                        </ul>
                    )}

                    {fields && (
                        <>
                            <hr className="ui-global--hr" />

                            <div className="package-details-sections-fields">
                                {fields}
                            </div>
                        </>
                    )}
                </div>

                {addonsAll}
            </>
        )
    }

    if (!_entity)
        return

    const _packages = _apis.get('packages')

    return (
        <UI.Layout name="packageDetails" type="container" config={{ classNames: _toggles.get('syncing') ? 'ui-global--disabled ui-animation--pulseSlow' : '' }}>
            <div key={`package-details-package-${_entity.id}`} className={`package-details-container`}>
                {_toggles.get('syncing') && (
                    <div className="package-details-package-syncing">
                        <PlaceholderGenericSpinnerComponent type="inline" config={{ size: 'tiny' }} />
                    </div>
                )}

                {!_entity.available && (
                    <div className="package-details-package-not-available">
                        {!_entity.available ? <>
                            <i className="bi bi-lock-fill"></i> This addon is not available for this order's event. Please choose another addon to unlock editing.
                        </> : ''}
                    </div>
                )}

                <div className="package-details-package-inner">
                    <div className="package-details-title">
                        <span className="title">Addon Details</span>

                        <span className="pricing-titles">
                            <span>Price</span>
                            <span className="qty">Qty</span>
                            <span>Total</span>
                        </span>
                    </div>
                    <div className={`package-details-package-row`}>
                        {renderPackageDetailsRow(_entity)}
                    </div>
                </div>
            </div>

            {_toggles.get('modalPackage') && _packages && (
                <SelectWithSearchModalComponent
                    collection={_packages.data}
                    config={{
                        isSyncing: _toggles.get('syncing'),
                        titleMain: 'Select New Addon'
                    }}
                    configSearch={{
                        term: _state.get('modalTerm'),
                        page: _state.get('modalPage'),
                        totalPages: _packages.total_pages || 1
                    }}
                    events={{
                        handleClose: () => _toggles.set('modalPackage', false),
                        handleSelect: handlePackageSelect,
                        handleSearchTermChange: handleSearchTermChange,
                        handleSearchPageChange: handleSearchPageChange
                    }} />
            )}

            {_toggles.get('modalQuantity') && (
                <QuantityModalComponent
                    value={_entity.quantity}
                    config={{
                        isSyncing: _toggles.get('syncing'),
                        titleMain: 'Change Addon Quantity'
                    }}
                    events={{
                        handleClose: () => _toggles.set('modalQuantity', false),
                        handleChange: handlePackageQuantityUpdate,
                    }} />
            )}
        </UI.Layout>
    )
}

AddonsDetails.propTypes = {
    item: PropTypes.object,
    
    events: PropTypes.shape({
        handleChange: PropTypes.func
    }),

    config: PropTypes.shape({
        enableDelete: PropTypes.bool
    })
}
export { AddonsDetails }