export function getGeneralFormFields(
  dataMain,
  dataTimeAdjustment,
  setDataTimeAdjustment,
  dataReverseAdjustmentDirection,
  setDataReverseAdjustmentDirection
) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name'
    },
    {
      type: 'switch',
      name: 'hide_slot_for_current_time',
      field_info: 'Hide Slot for current time',
      label: 'Options',
      relatedFields: ["time_adjustment"],
      inputDescription: dataMain && dataMain.hide_slot_for_current_time && (
        <div className="options-container">
          <div className="form-field flex">
            If&emsp;
            <input
              type="number"
              placeholder="0"
              value={dataTimeAdjustment}
              onChange={(e) => setDataTimeAdjustment(e.target.value)}
            />
            &emsp;minutes&emsp;
            <select
              value={dataReverseAdjustmentDirection}
              onChange={(e) =>
                setDataReverseAdjustmentDirection(e.target.value)
              }
            >
              <option value="false">have elapsed</option>
              <option value="true">remaining</option>
            </select>{' '}
            <span className="flex-block">&emsp;in block.</span>
          </div>
        </div>
      )
    }
  ];
}
