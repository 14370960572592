import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'
import ProjectorComponent from '../../components/Projector'

import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import { FileTypes } from '../../sys/constants/enums'
import { useParams } from 'react-router-dom'

const CharitiesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Charities/Edit')

    const [data, setData] = useState()
    const [dataMain, setDataMain] = useState()

    const [errors, setErrors] = useState()

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            ...dataMain
        }

        if (body.image_attributes) {
            body.image_base64 = body.image_attributes.value
        }

        delete body.image_attributes

        FetchHelper({
            url: `/api/v2/admin/charities/${params.id}`,
            method: 'put',
            body: {
                charity: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/charities')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/charities/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])
    
    return (
        <div className="charities-page-container page-container">
            <PageHeaderComponent title={`Edit Charity`} />

            <div className="page-form-container">
                {data ? (
                    <>
                        <ProjectorComponent url={data.image_url} />

                        <NoticeComponent notices={notices} location="Main" />
                        
                        <FormComponent formData={data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[
                            {
                                type: 'input-text',
                                name: 'name',
                                label: 'Name',
                            },
                            {
                                type: 'input-text',
                                name: 'caption',
                                label: 'Caption',
                            },
                            {
                                type: 'input-file',
                                fileType: 'image',
                                name: 'image_attributes',
                                label: 'Image',
                                acceptedFileTypes: FileTypes.IMAGES
                            }
                        ]}/>
                    </>
                ) : null}
            </div>

            <ControlBar baseApiUrl="/charities" redirectUrl="/charities" cancelUrl="/charities" data={data} handleSubmit={handleSubmit} />
        </div>
    )
}

export default CharitiesEdit