import {useEffect, useState} from 'react'

import FetchHelper from '../../helpers/fetch'
import FormComponent from '../Form'

import './index.css'

const CustomFields = ({ defaults=[], onChange=()=>{} }) => {
  const [data, setData] = useState([])
  const [fields, setFields] = useState([])
  const [selection, setSelection] = useState()

  useEffect(() => {
    if (!data) {
      setData(defaults)
    }

    FetchHelper({
      url: `/api/v2/admin/fields`
    }, (res) => {
      setFields(res.body.data)
    }, (res) => {})
  }, [])

  const handleAdd = (index) => {
    let newData = data

    newData.push(selection)

    setData(newData)
    onChange(newData)
  }

  const handleRemove = (index) => {
    let newData = data

    newData.splice(index, 1)

    setData(newData)
    onChange(newData)
  }

  return (
    <div className="custom-fields-container">
      <div className="custom-fields-options">
        <select value={selection} onChange={(e) => setSelection(e.target.value)}>
          {fields.map((item, i) => {
            return (
              <option key={`custom-fields-options-${i}`} value={i}>{item.name}</option>
            )
          })}
        </select>

        <button onClick={handleAdd}>Add</button>
      </div>

      <div className="custom-fields-list">
        {data ? data.map((item, i) => {
          return (
            <div key={`custom-fields-list-${i}`}>
              <span className="title">{item.name}</span>
              <span className="options"><button onClick={handleRemove(i)}>&times;</button></span>
            </div>
          )
        }) : null}
      </div>
    </div>
  )
}

export default CustomFields
