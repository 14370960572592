import React from 'react';
import PropTypes from 'prop-types'

import './index.css';

const TabsWithUnderline = ({ tabs, activeTab, events, errorsByTabTitle }) => {
    const handleSelect = (index) => {
        if (events.handleSelect)
            events.handleSelect(index)
    };

    return (
        <div className="ui-c-TabsWithUnderline">
            <div className="tabs">
                <div className="tab-indicator" style={{ left: `${activeTab * (100 / tabs.length)}%`, width: `${100 / tabs.length}%` }}></div>
                {tabs.map((tab, index) => (
                    <div key={index} className={`tab ${activeTab === index ? 'active' : ''}`} onClick={() => handleSelect(index)}>
                        {tab}
                        { errorsByTabTitle && errorsByTabTitle[tab]?.errors.length > 0 && <span className="error-count">{errorsByTabTitle[tab]?.errors.length}</span> }
                    </div>
                ))}
            </div>
        </div>
    );
};

TabsWithUnderline.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string),

    activeTab: PropTypes.number,

    events: PropTypes.shape({
        handleSelect: PropTypes.func
    })
}

export { TabsWithUnderline };
