export const XMLTAGS = [
    {
        value: '',
        title: 'None'
    },
    {
        group: 'DDLab - Sports',
        options: [
            {
                value: 'OrderID',
                title: 'OrderID'
            }, {
                value: 'SubjectID',
                title: 'SubjectID'
            }, {
                value: 'Comments',
                title: 'Comments'
            }, {
                value: 'Type',
                title: 'Type'
            }, {
                value: 'CardNumb',
                title: 'CardNumb'
            }, {
                value: 'ScanID',
                title: 'ScanID'
            }, {
                value: 'SportType',
                title: 'SportType'
            }, {
                value: 'DivisionSchool',
                title: 'DivisionSchool'
            }, {
                value: 'LeagueHomeroom',
                title: 'LeagueHomeroom'
            }, {
                value: 'TeamGrade',
                title: 'TeamGrade'
            }, {
                value: 'TeamNumber',
                title: 'TeamNumber'
            }, {
                value: 'FirstName',
                title: 'FirstName'
            }, {
                value: 'LastName',
                title: 'LastName'
            }, {
                value: 'JerseyNumber',
                title: 'JerseyNumber'
            }, {
                value: 'Age',
                title: 'Age'
            }, {
                value: 'HeightFeetAndInches',
                title: 'HeightFeetAndInches'
            }, {
                value: 'HeightFeet',
                title: 'HeightFeet'
            }, {
                value: 'HeightInches',
                title: 'HeightInches'
            }, {
                value: 'Weight',
                title: 'Weight'
            }, {
                value: 'PlayerPosition',
                title: 'PlayerPosition'
            }, {
                value: 'CoachTeacher',
                title: 'CoachTeacher'
            }, {
                value: 'ParentsNames',
                title: 'ParentsNames'
            }, {
                value: 'Street',
                title: 'Street'
            }, {
                value: 'AptNumber',
                title: 'AptNumber'
            }, {
                value: 'City',
                title: 'City'
            }, {
                value: 'State',
                title: 'State'
            }, {
                value: 'Zip',
                title: 'Zip'
            }, {
                value: 'Email',
                title: 'Email'
            }, {
                value: 'DueDate',
                title: 'DueDate'
            }, {
                value: 'PhotogID',
                title: 'PhotogID'
            }, {
                value: 'Shipping',
                title: 'Shipping'
            }
        ]
    },
    {
        group: 'DDLab - School',
        options: [
            {
                value: 'First Name',
                title: 'First Name'
            },
            {
                value: 'Last Name',
                title: 'Last Name'
            },
            {
                value: 'Grade',
                title: 'Grade'
            },
            {
                value: 'Teacher',
                title: 'Teacher',
            },
            {
                value: 'Student ID',
                title: 'Student ID'
            },
            {
                value: 'Homeroom',
                title: 'Homeroom'
            }
        ]
    },
    {
        group: 'DRUMS',
        options: [
            {
                value: 'Student First Name',
                title: 'Student First Name'
            },
            {
                value: 'Student Last Name',
                title: 'Student Last Name',
            },
            {
                value: 'Grade',
                title: 'Grade'
            },
            {
                value: 'Student ID Number',
                title: 'Student ID Number'
            },
            {
                value: 'Teacher',
                title: 'Teacher'
            },
            {
                value: 'Reference Number',
                title: 'Reference Number'
            },
            {
                value: 'Nickname',
                title: 'Nickname'
            }
        ]
    }
]