import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const HeadingComponent = ({ type, children, config={} }) => {
    let _html = ''

    const classNames = `${config.classNames || ''} ui-container--heading`

    switch (type) {
        case 'h1':
            _html = <h1 className={`text-2xl ${classNames}`}>{children}</h1>
            break
        case 'h2':
            _html = <h2 className={`text-xl ${classNames}`}>{children}</h2>
            break
        case 'h3':
            _html = <h3 className={`text-lg ${classNames}`}>{children}</h3>
            break
        case 'h4':
            _html = <h4 className={`text-base ${classNames}`}>{children}</h4>
            break
        case 'h5':
            _html = <h5 className={`text-sm ${classNames}`}>{children}</h5>
            break
        case 'h6':
            _html = <h6 className={`text-sm ${classNames}`}>{children}</h6>
            break
    }

    return _html
}

HeadingComponent.propTypes = {
    config: PropTypes.shape({
        classNames: PropTypes.string,
        includeInner: PropTypes.bool,
    }),

    children: PropTypes.any,
}

export { HeadingComponent }