import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import PageFooterComponent from './PageFooter'

import useAppContext from '../hooks/useAppContext'
import useWindowDimensions from '../hooks/useWindowDimensions'

import './NavigationPrimary.css'

const NavigationPrimary = ({ config }) => {
    const [ac] = useAppContext()

    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation()
    const path = (location.pathname).split('/')
    const pathName = path[1]

    const [quickSearchTerm, setQuickSearchTerm] = useState('')

    const { width, height } = useWindowDimensions()

    useEffect(() => {
        setIsOpen(false)
    }, [width, height])

    let hierarchy = [...config]

    const renderHierarchy = () => {
        let quickSearchHierarchy = []

        if (quickSearchTerm) {
            hierarchy.forEach((hierarchyItem) => {
                if (hierarchyItem.type === 'link') {
                    for (let i=0; i < hierarchyItem.tags.length; i++) {
                        if (hierarchyItem.tags[i].indexOf(quickSearchTerm) > -1) {
                            quickSearchHierarchy.push(hierarchyItem)
                            break
                        }
                    }
                } else {
                    hierarchyItem.links.forEach((hierarchyItemLinkItem) => {
                        for (let i=0; i < hierarchyItemLinkItem.tags.length; i++) {
                            if (hierarchyItemLinkItem.tags[i].indexOf(quickSearchTerm) > -1) {
                                quickSearchHierarchy.push(hierarchyItemLinkItem)
                                break
                            }
                        }
                    })
                }                
            })
        }

        return (
            <>
                <div className="quick-search">
                    <div className="quick-search-input-wrapper">
                        <input type="text" placeholder="Quick search..." value={quickSearchTerm} onChange={(e) => setQuickSearchTerm(e.target.value)} onKeyUp={(e) => {
                            if (e.key === 'Escape')
                                setQuickSearchTerm('')
                        }}/>
                        {quickSearchTerm === '' ? (<i className="bi bi-search"></i>) : (<i className="bi bi-x-circle-fill" onClick={() => setQuickSearchTerm('')}></i>)}
                    </div>
                </div>

                {quickSearchTerm ? (
                    <ul className="quick-search-results">
                        {quickSearchHierarchy.map((quickSearchHierarchyItem, quickSearchHierarchyIndex) => {
                            return renderSearchLink(quickSearchHierarchyItem)
                        })}
                    </ul>
                ) : null}

                {!quickSearchTerm ? (
                    hierarchy.map((hierarchyItem, hierarchiIndex) => {
                        if (hierarchyItem.type === 'link') {
                            return renderLink(hierarchyItem, true)
                        } else {
                            return (
                                <li key={hierarchyItem.title} className={`${hierarchyItem.className} ${isCategoryExpanded(hierarchyItem.links) ? 'action-expanded' : ''}`}>
                                    <span className="navigation-primary-category" onClick={expandCategory}>{hierarchyItem.title}<i className={`icon ${hierarchyItem.icon}`}></i></span>
                                    <ul className="navigation-primary-links">
                                        {hierarchyItem.links.map((link) => {
                                            return renderLink(link)
                                        })}
                                    </ul>
                                </li>
                            )
                        }
                    })
                ) : null}

                <PageFooterComponent />
            </>
        )
    }

    const renderLink = (item, displayIcon) => {
        let url = item.path

        return (
            <li key={url}>
                <Link to={url} className={url === location.pathname && 'action-active' || ''} onClick={toggleOpen}>{item.title} {displayIcon && <i className={`icon ${item.icon}`}></i> || null}</Link>
            </li>
        )
    }

    const renderSearchLink = (item) => {
        let url = item.path

        return (
            <li key={item.title} className="search-link">
                <Link to={url} className={url === location.pathname && 'action-active' || ''} onClick={toggleOpen}>{item.title}</Link>
            </li>
        )
    }

    const expandCategory = (category) => {
        category.target.parentNode.classList.toggle('action-expanded')
    }

    const collapseCategories = () => {
        let expandedCategories = document.querySelectorAll('.navigation-primary-container .action-expanded')

        expandedCategories.forEach((category) => {
            if (!category.querySelector('.action-active')) {
                category.classList.remove('action-expanded')
            }
        })
    }

    const isCategoryExpanded = (links) => {
        let isExpanded = false

        links.forEach((link) => {
            let temp_link = link.path.split('/')

            if (temp_link[1] === pathName) {
                isExpanded = true
            }
        })

        return isExpanded
    }

    const toggleOpen = () => {
        setIsOpen(!isOpen)

        if (isOpen) {
            collapseCategories()
        }
    }

    return (
        <div className={`navigation-primary-container ${isOpen ? 'open' : ''}`}>
            <div className="navigation-primary-logo"></div>
            <div className="navigation-primary-header" onClick={toggleOpen}>
                <div className="navigation-primary-hamburger"><i className="bi bi-list"></i></div>
                <div className="navigation-primary-logo-small"></div>
            </div>
            <ul className="navigation-primary-menu">
                {renderHierarchy()}
            </ul>
        </div>
    )
}

export default NavigationPrimary
