import { AnchorComponent } from './Anchor'
import { ButtonComponent } from './Button'
import { CheckboxComponent } from './Checkbox'
import { HeadingComponent } from './Heading'
import { InputComponent } from './Input'
import { LabelComponent } from './Label'
import { ParagraphComponent } from './Paragraph'
import { RadioComponent } from './Radio'
import { SelectComponent } from './Select'
import { TextareaComponent } from './Textarea'

export {
    AnchorComponent,
    ButtonComponent,
    CheckboxComponent,
    HeadingComponent,
    InputComponent,
    LabelComponent,
    ParagraphComponent,
    RadioComponent,
    SelectComponent,
    TextareaComponent
}