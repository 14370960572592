import {useState} from 'react'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import ListComponent from '../../components/List'
import TagsComponent from '../../components/Tags'
import UtilsHelper from '../../helpers/utils'

// @TODO: Implement fetch onload

import useNotices from '../../hooks/useNotices'
import Switch from '../../components/Switch'
import useFetch from '../../hooks/useFetch'
import useAppContext from '../../hooks/useAppContext'

const Users = () => {
    const [NoticeComponent, notices] = useNotices('Tools/Users/Index')
    const [ac] = useAppContext()
    const { FetchHelper } = useFetch()

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [showTags, setShowTags] = useState(false)
    const baseUrl = '/users'
    const baseApiUrl = '/api/v2/admin/users'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name_route = `${baseUrl}/${item.id}/edit`
            item.name = item.login

            return item
        })
    }

    const toggleUserStatus = async (value, user) => {
        ac.showSpinner(true);

        let [userUpdated, errUpdatingUser] = await UtilsHelper.executePromise(
            FetchHelper({ 
                url: user.url,
                method: 'PUT',
                body: { enabled: value }
            })
        );

        ac.showSpinner(false);
    }

    return (
        <div className="users-page-container page-container">
            <PageHeaderComponent title="Users" actions={[{ title: 'New User', route: '/users/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags}  />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                copyEnabled={false}
                columns={[
                    {
                        key: 'full_name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    }
                ]}
                getAdditionalActions={(item) => {
                    return [
                        {
                            title: 'Enable/Disable User',
                            type: 'html',
                            html: (item) => {
                                return (
                                    <div>
                                        <Switch title={'Enable/Disable User'} value={item.enabled}
                                            onChange={(value) => toggleUserStatus(value, item)} />
                                    </div>
                                )
                            }
                        }
                    ]
                }}
                 />
        </div>
    )
}

export default Users
