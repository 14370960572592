import { useEffect } from 'react'

import { useDynamicState } from '../../hooks/useDynamicState'

import './index.css'

const PageTest = ({}) => {
    const { _stateGet, _stateSet } = useDynamicState({
        data: {
            contact: {
                nameFirst: '',
                nameLast: '',
                phone: ['', ({ value }) => {
                    return value.replace(/[^\d]/, '')
                }],
                email: ['', ['nameFirst', 'nameLast', 'phone'], ({ value, values }) => {
                    return `${Object.values(values).join('.')}@example.com`
                }]
            },
            contactConditions: {
                disabled: false
            }
        },
        timers: {
            contact: {
                submit: null
            }
        }
    })

    useEffect(() => {
        //
    }, [_stateGet('data.contact', 'email')])

    const renderForm = () => {
        const handleSubmit = (e) => {
            e.preventDefault()
        }

        const selectorContact = 'data.contact'
        const selectorContactConditions = 'data.contactConditions'
        const selectorTimers = 'timers.contact'
        
        return (
            <div className="page-test-container" data-disabled={_stateGet(selectorContactConditions, 'disabled')}>
                <form id="contact" onSubmit={handleSubmit}>
                    <input value={_stateGet(selectorContact, 'nameFirst')} onChange={(e) => _stateSet(selectorContact, { nameFirst: e.target.value })} />
                    <input value={_stateGet(selectorContact, 'nameLast')} onChange={(e) => _stateSet(selectorContact, { nameLast: e.target.value })} />
                    <input value={_stateGet(selectorContact, 'phone')} onChange={(e) => _stateSet(selectorContact, { phone: e.target.value })} />
                    <input value={_stateGet(selectorContact, 'email')} onChange={(e) => _stateSet(selectorContact, { email: e.target.value })} />
                    <button onClick={() => {
                        _stateSet(selectorContactConditions, { disabled: true })

                        setTimeout(() => {
                            _stateSet(selectorContactConditions, { disabled: false })
                        }, 1000)
                    }}>Test</button>
                </form>
            </div>
        )
    }

    return renderForm()
}

export { PageTest }
