import { useEffect, useState } from "react";
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'

import useAppContext from "../../hooks/useAppContext"
import useNotices from '../../hooks/useNotices'

const MarketGroups = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/MarketGroups/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const baseUrl = '/market-groups'
    const baseApiUrl = '/api/v2/admin/market-groups'
    const [colorScheme, setColorScheme] = useState([])

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    const showColor = (id) => {
        let name = ''

        colorScheme.forEach(function(elem){
            if(id === null){
                name = "System Default"
            }else{
                if(elem.id === id){
                    name = elem.name
                }
            }
        })

        return name
    }

    useEffect(() => {
        ac.showSpinner(true);

        FetchHelper({
            url: `/api/v2/admin/color-schemes`
        }, (res) => {
            ac.showSpinner(false)
            setColorScheme(res.body.data)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name = item.name
            item.ui_subdomain = item.subdomain ? item.subdomain : 'N/A'
            item.ui_color_scheme = showColor(item.color_scheme_id)
            item.ui_name_route = `${baseUrl}/${item.id}/edit`

            return item
        })
    }

    return (
        <div className="location-groups-page-container page-container">
            <PageHeaderComponent title="Market Groups" actions={[{ title: 'New Market Group', route: '/market-groups/new' }]}/>
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'ui_name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'ui_subdomain',
                        title: 'Subdomain'
                    },
                    {
                        key: 'ui_color_scheme',
                        title: 'Color Scheme'
                    }
                ]} />
        </div>
    )
}

export default MarketGroups
