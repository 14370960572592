import {useState, useEffect, useContext} from 'react'

import PopoverComonent from '../Popover'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import './index.css'

const EntityCard = ({ entityType, entityData, displayThumbnail }) => {
    const {width, height} = useWindowDimensions()

    const isBPS = /sheet|background|pose/.test(entityType)
    const sourcedImage = (img, format) => {
      if (!img.id)
        return 'https://via.placeholder.com/300x300?text=MISSING'

      if (!format || img.source === 'remote')
        return img.sources[img.source]['url']

      return img.sources[img.source][`${format}_url`]
    }

    const renderEntity = () => {
        if (isBPS) {
            return (
                <div className="entity-card-container">
                    <div className="entity-card-name">{entityData.name}</div>
                    <div className="entity-card-image">
                        <div style={{ backgroundImage: `url(${entityData.image.image_url})` }}></div>
                    </div>
                    <ul className="entity-card-details">
                        <li><span className="entity-card-details-title">Code</span> {entityData.code}</li>
                        {entityType === 'pose' ? (<li><span className="entity-card-details-title">Type</span> {entityData.type === 'IndividualPose' ? 'Individual Pose' : 'Couple'}</li>) : null}
                    </ul>
                </div>
            )
        } else if (entityType === 'package plan') {
            return (
                <div className="entity-card-container">
                    <div className="entity-card-name">{entityData.name}</div>
                    <ul className="entity-card-details">
                        <li>Poses: {entityData.poses.data.length}</li>
                        <li>Backgrounds: {entityData.backgrounds.data.length}</li>
                        <li>Sheets: {entityData.sheets.data.length}</li>
                        <li>Packages: {entityData.packages.data.length}</li>
                        <li>Addons: {entityData.addons.data.length}</li>
                        <li>Coupon Codes: {entityData.coupon_codes.data.length}</li>
                        <li>Fields: {entityData.fields.data.length}</li>
                    </ul>
                </div>
            )
        } else if (entityType === 'package') {
            return (
                <div className="entity-card-container">
                    <div className="entity-card-name">{entityData.name}</div>
                    <div className="entity-card-image">
                        <div style={{ backgroundImage: `url(${sourcedImage(entityData.image)})` }}></div>
                    </div>
                    <ul className="entity-card-details">
                        <li>Addons: {entityData.addons.data.length}</li>
                        <li>Fields: {entityData.fields.data.length}</li>
                        <li>Coupon Codes: {entityData.coupon_codes.data.length}</li>
                    </ul>
                </div>
            )
        } else if (entityType === 'charity') {
            return (
                <div className="entity-card-container">
                    <div className="entity-card-name">{entityData.name}</div>
                    <div className="entity-card-image">
                        <div style={{ backgroundImage: `url(${entityData.image_url})` }}></div>
                    </div>
                    <ul className="entity-card-details">
                        <li>{entityData.caption}</li>
                    </ul>
                </div>
            )
        } else {
            return null
        }
    }

    if (displayThumbnail && isBPS) {
        return <PopoverComonent position={width < 1024 ? 'right' : 'top'}>
            <div className="entity-card-thumbnail" style={{ backgroundImage: `url(${entityData.image.thumbnail_url})` }}></div>
            {renderEntity()}
        </PopoverComonent>
    } else if (displayThumbnail && entityType === 'package') {
        return <PopoverComonent position={width < 1024 ? 'right' : 'top'}>
            <div className="entity-card-thumbnail" style={{ backgroundImage: `url(${sourcedImage(entityData.image, 'thumbnail')})` }}></div>
            {renderEntity()}
        </PopoverComonent>
    } else if (displayThumbnail && entityType === 'charity') {
        return <PopoverComonent position={width < 1024 ? 'right' : 'top'}>
            <div className="entity-card-thumbnail" style={{ backgroundImage: `url(${entityData.thumbnail_url})` }}></div>
            {renderEntity()}
        </PopoverComonent>
    } else if (displayThumbnail) {
        return <PopoverComonent position={width < 1024 ? 'right' : 'top'}>
            <div className="entity-card-icon"><i className="bi bi-boxes"></i></div>
            {renderEntity()}
        </PopoverComonent>
    } else {
        return null
    }
}

export default EntityCard