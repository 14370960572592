import { useState, useEffect, useRef } from 'react'
import { SketchPicker } from 'react-color'
import './ColorPicker.css'

const ColorPicker = ({ type, name, data, onChange, readOnly }) => {
    const inputRef = useRef(null)
    const pickerRef = useRef(null)
    const [picker, setPicker] = useState(false)
    const [color, setColor] = useState()
    const [rgba, setRgba] = useState({})

    useEffect(() => {
        setColor(data)
        if(data){
            setRgba(convertHex(data))
        }
    }, [data])

    useEffect(() => {

        function handleClickOutside(event) {

            if (picker && pickerRef && !(pickerRef.current.contains(event.target))) {
                setPicker(false)

                if (!(/(^#[0-9A-F]{3}$)|(^#[0-9A-F]{6}$)/i).test(color)) {
                    setColor('#000000')
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }

    }, [picker, color])

    function convertHex(hexCode){

        var hex = hexCode.replace('#','');
    
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
    
        var r = parseInt(hex.substring(0,2), 16),
            g = parseInt(hex.substring(2,4), 16),
            b = parseInt(hex.substring(4,6), 16);

        return {r: r, g: g, b: b}
    }

    const  renderShades = (a) => {
        return "rgb("+rgba.r+" "+rgba.g+" "+rgba.b+" / "+a+")"
    }

    const  renderGradient = (num) => {
        let divs = []
        let percentage = [
            {0: '90%'}, {1: '70%'}, {2: '60%'}, {3: '50%'},
            {4: '30%'}, {5: '20%'}, {6: '10%'}
        ]

        if(num === 0.2){
            return(
                <div className="color-shade last" style={{backgroundColor: color? renderShades('20%') : "#eeeeee"}}></div>
            )
        }else{
            const start = num === 6? 1 : 0

            for(var i=start;i<num;i++){

                if(num === 6){
                    percentage[4] = {4: '40%'}
                    percentage[5] = {5: '30%'}
                }

                divs.push(<div key={i} className={"color-shade "+(i+1 === num? "last" : "")} style={{backgroundColor: color? renderShades(percentage[i][i]) : "#eeeeee"}}></div>)
            }
        }
        
        return divs
    }

    const styles = {
        color: type === 'text' ? (color ? color : '#505050') : '#505050',
        backgroundColor: type === 'text' || !color ? "#eeeeee" : (type === 'gradient-md'? renderShades('90%') : color),
        borderTop: '1px solid #CCC',
        borderRight: '1px solid #CCC',
        borderBottom: '1px solid #CCC'
    }

    const handleChange = (e) => {
        let value = e.target.value
        let temp = value.replace(/[^0-9A-z]/g, '')

        setPicker(true)
        setColor("#" + temp)
    }

    const handlePicker = () => {
        if (readOnly) return
        setPicker(!picker)
    }

    const handleColor = (color, setColor) => {
        setRgba(color.rgb)
        setColor(color.hex)
        onChange(color.hex)
    }

    const renderButton = () => {
        switch (type) {
            case 'block':
                return (
                    <button className="color-button" onClick={handlePicker} style={styles}></button>
                )
            case 'text':
                return (
                    <button className="color-button text" onClick={handlePicker} style={styles}>Text</button>
                )
            case 'gradient-sm':
                return (
                    <div className="gradient sm">
                        <button className="color-button" onClick={handlePicker} style={styles}></button>
                        {renderGradient(0.2)}
                    </div>
                )
            case 'gradient-md':
                return (
                    <div className="gradient md">
                        <button className="color-button" onClick={handlePicker} style={styles}></button>
                        {renderGradient(6)}
                    </div>
                )
            case 'gradient-lg':
                return (
                    <div className="gradient lg">
                        <button className="color-button" onClick={handlePicker} style={styles}></button>
                        {renderGradient(7)}
                    </div>
                )
            default:
                break
        }
    }

    return (
        <div className="color-picker-container">
            <input className="color-input" ref={inputRef} type="text" name={name} value={color || ''} maxLength="7" onChange={handleChange} readOnly={readOnly} />
            {renderButton()}
            {picker &&
                <div id="color-picker" ref={pickerRef}>
                    <SketchPicker
                        color={color}
                        disableAlpha={true}
                        onChange={(e) => handleColor(e, setColor)}
                    />
                </div>
            }
        </div>
    )
}
export default ColorPicker
