export function getFormFields() {
  return [
    {
      type: "input-text",
      name: "report_name",
      label: "Name",
      relatedFields: ["name"]
    },
  ];
}
