import { Box } from './Box'
import { PageSection } from './PageSection'
import { Section } from './Section'
import { SectionTitle } from './SectionTitle'

const Layout = {
    Box,
    PageSection,
    Section,
    SectionTitle
}

export { Layout }
