import { Decorators } from '../../helpers'

const ClickBoundaryWrapper = ({ children, properties }) => {
    let { decoratedProps, originalProps } = Decorators.Props(properties)

    decoratedProps.onClick = (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (originalProps.onClick)
            originalProps.onClick(event)
    }

    const WrappedChildren = () => {
        return children
    }

    return <WrappedChildren {...decoratedProps} />
}

export {
    ClickBoundaryWrapper
}