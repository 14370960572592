import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import _ from 'lodash'

import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'
import PaginationComponent from '../../components/Pagination'
import TableComponent from '../../components/Table'

import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useFetch from '../../hooks/useFetch'

import dayjs from 'dayjs'

import './index.css'

const InvoicePage = () => {
    const [ac] = useAppContext()
    
    const { FetchHelper } = useFetch()

    // FORM
    const [errors, setErrors] = useState()

    // DATA
    const [data, setData] = useState()

    const [dataRange, setDataRange] = useState({
        start_date: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end_date: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    })

    const [page, setPage] = useState(1)

    useEffect(() => {
        fetchInvoices()
    }, [dataRange])

    const fetchInvoices = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/invoices?start_date=${dataRange.start_date}&end_date=${dataRange.end_date}&p=${page}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        })
    }

    const downloadAllInvoices = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/invoices.pdf`,
            headers: {
                'Content-Type': 'application/pdf'
            }
        }, (res) => {
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const downloadStudioInvoice = (item) => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/studios/${item.id}/invoice.pdf`,
            headers: {
                'Content-Type': 'application/pdf'
            }
        }, (res) => {
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const renderMinimumNotMet = (minimumMet=true) => {
        if (!minimumMet) {
           return <span className="minimum-not-met">
                Minimum not met <i className="bi bi-exclamation-triangle-fill"></i>
            </span>
        }
    }

    const renderPagination = () => {
        if (!data) {
            return null
        }

        return (
            <div className="invoice-page-pagination">
                <PaginationComponent total={data.total} currentPage={data.current_page} totalPages={data.total_pages} setPage={setPage} />
            </div>
        )
    }

    const renderStudioSection = (item) => {
        let count = 0

        // @TODO: Move to API
        const dataFiltered = {
            columns: [
                { key: 'name', title: 'Name' },
                { key: 'quantity', title: 'Quantity' },
                { key: 'subtotal', title: 'Subtotal' },
                { key: 'shipping', title: 'Shipping' },
                { key: 'fees', title: 'Fees' },
                { key: 'tax', title: 'Tax' },
                { key: 'donation_count', title: 'Donation Count' },
                { key: 'donations', title: 'Donation Amount' },
                { key: 'total', title: 'Total' },
            ],
            data: item.data.map((item, idx) => {
                return {
                    group: item.billing_profile.name,
                    totals: item.totals,
                    billing_profile: item.billing_profile,
                    data: item.data.length ? item.data.map((itm, idx) => {
                        count++

                        itm.ui_name = itm.name
                        itm.ui_quantity = itm.count
                        itm.ui_subtotal = UtilsHelper.formatDollar(itm.subtotal)
                        itm.ui_shipping = UtilsHelper.formatDollar(itm.shipping)
                        itm.ui_fees = UtilsHelper.formatDollar(itm.fees)
                        itm.ui_tax = UtilsHelper.formatDollar(itm.sales_tax)
                        itm.ui_total = UtilsHelper.formatDollar(itm.total)
                        itm.ui_donation_count = itm.donation_count
                        itm.ui_donations = UtilsHelper.formatDollar(itm.donations)
    
                        return itm
                    }) : []
                }
            }),
            totals: item.totals,
        }

        return (
            <div className="studio-section-container">
                <div className="studio-section-header">
                    <div className="studio-name">
                        <Link to={`/studios/${item.id}/edit`}>{item.name}</Link>
                    </div>

                    <div className="studio-pdf">
                        <button onClick={() => downloadStudioInvoice(item)}><i className="bi bi-filetype-pdf"></i> Download PDF</button>
                    </div>
                </div>

                {dataFiltered.data.length ? (
                    <>
                        <TableComponent columns={dataFiltered.columns} data={dataFiltered.data} enablePagination={false} enableEditColumns={false} />

                        <div className="studio-section-totals">
                            {dataFiltered.data.map((dataFilteredItem, dataFilteredIndex) => {
                                let billingProfileItem = dataFilteredItem.billing_profile
                                let billingProfileTotals = dataFilteredItem.totals

                                return (
                                    <div className="studio-section-total" key={`studio-section-totals-${billingProfileItem.name}-${billingProfileItem.id}`}>
                                        <div className="total-title">{billingProfileItem.name}</div>
                                        <ul className="total-breakdown">
                                            <li>{billingProfileItem.base_rate}% of {UtilsHelper.formatDollar(billingProfileTotals.base_amount)} sales: {UtilsHelper.formatDollar(billingProfileTotals.base_total)}</li>
                                            <li>{billingProfileItem.tx_rate} * {billingProfileTotals.tx_count} per transaction fee: {UtilsHelper.formatDollar(billingProfileTotals.tx_total)}</li>
                                            <li>{billingProfileItem.fees_rate}% revenue share on {UtilsHelper.formatDollar(billingProfileTotals.fees_amount)} convenience fees: {UtilsHelper.formatDollar(billingProfileTotals.fees_total)}</li>
                                            <li>{UtilsHelper.formatDollar(billingProfileTotals.total)}</li>
                                        </ul>
                                    </div>
                                )
                            })}

                            <div className="studio-section-total">
                                <div className="total-title">Grand Total</div>
                                <ul className="total-breakdown">
                                    <li>% of {UtilsHelper.formatDollar(dataFiltered.totals.subtotal)} sales: {UtilsHelper.formatDollar(dataFiltered.totals.base_total)}</li>
                                    <li>* {dataFiltered.totals.tx_count} per transaction fee: {UtilsHelper.formatDollar(dataFiltered.totals.tx_total)}</li>
                                    <li>% revenue share on {UtilsHelper.formatDollar(dataFiltered.totals.fees)} convenience fees: {UtilsHelper.formatDollar(dataFiltered.totals.fees_total)}</li>
                                    <li>{renderMinimumNotMet(dataFiltered.totals.minimum_met)} {UtilsHelper.formatDollar(dataFiltered.totals.total)}</li>
                                </ul>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="no-results">No data to display</div>
                )}
                
                <div className="studio-section-count">
                    Showing {count} entries
                </div>
            </div>
        )
    }

    return (
        <div className="invoice-page-container page-container">
            <PageHeaderComponent title="Invoice" />

            <div className="invoice-page-controls">
                <div className="invoice-page-controls-primary">
                    <FormComponent formData={dataRange} formErrors={errors} onChange={setDataRange} displayErrorBanner={true} componentClassName="invoice-range" fields={[
                        {
                            type: 'date',
                            name: 'start_date',
                            label: 'Start Date',
                        },
                        {
                            type: 'date',
                            name: 'end_date',
                            label: 'End Date',
                        }] } />
                </div>
            </div>

            <div className="invoice-page-controls-secondary">
                <button onClick={downloadAllInvoices}><i className="bi bi-filetype-pdf"></i> Download All PDFs</button>
            </div>

            {renderPagination()}

            {data ? data.data.map((item, index) => {
                return <div className="studio-section" key={`studio-section-container-${index}`}>{renderStudioSection(item, index)}</div>
            }) : null}

            {renderPagination()}
        </div>
    )
}

export default InvoicePage