import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'
import lodash from 'lodash'

const LocationGroupsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/LocationGroups/New')

    const [data, setData, clearData, dataDefault] = useDraft('Markets:LocationGroups:Data')
    const [errors, setErrors] = useState()
    const [colorScheme, setColorScheme] = useState([])
    const [availableMarkets, setAvailableMarkets] = useState([])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/color-schemes`
        }, (res) => {
            ac.showSpinner(false)

            const colors = [{ id: null, title: 'System Default', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: item.id,
                    title: item.name,
                    value: item.id
                }
                colors.push(option);
            })
            setColorScheme(colors)
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/markets',
        }, (res) => {

            let marketsList = []

            res.body.data.forEach(elem => {
                const market = {
                    id: elem.id,
                    group: elem.market_group ? elem.market_group.name : 'UNGROUPED',
                    name: elem.external_id ? elem.external_id + ' - ' + elem.name : elem.name
                }
                marketsList.push(market)
            });

            setAvailableMarkets(marketsList)

            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = { ...data }
            body.market_ids = lodash.map(data.market_ids, 'id')

            FetchHelper({
                url: `/api/v2/admin/location-groups`,
                method: 'POST',
                body: {
                    location_group: body
                }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="New Location Group" />
            <>
                <NoticeComponent location="Main" notices={notices} />
                <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                    {
                        type: 'input-text',
                        name: 'name',
                        label: 'Name',
                    },
                    {
                        type: 'input-text',
                        name: 'display_name',
                        label: 'Display Name'
                    }, {
                        type: 'select',
                        name: 'color_scheme_id',
                        label: 'Color scheme',
                        options: colorScheme
                    },
                    {
                        type: 'wysiwyg',
                        name: 'prompt',
                        label: 'Prompt'
                    },
                    {
                        type: 'two-multiselect',
                        name: 'market_ids',
                        label: 'Markets',
                        list_labels: ['Available Markets', 'Markets Selected'],
                        selectable: availableMarkets,
                        selected: data?.market_ids || []
                    }
                ]} />

                <ControlBar handleSubmit={handleSubmit} cancelUrl="/location-groups" handleAfterSubmit={() => RouteHelper.redirect('/location-groups')} handleAfterClear={() => {
                    RouteHelper.reload()
                }} dataItems={[
                    [data, clearData, dataDefault]
                ]} />
            </>
        </div>
    )
}
export default LocationGroupsNew
