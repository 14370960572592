import { useEffect, useState } from 'react'

import './index.css'

const POSITION_VISIBLE = 20
const POSITION_INVISIBLE = -200

const Status = ({ message, type, uniqID, config = { timeout: 3000, side: 'bottom', showCloseBtn: false } }) => {
    const [positionBottom, setPositionBottom] = useState(POSITION_INVISIBLE)

    const getIcon = () => {
        switch (type) {
            case 'danger':
                return <i className="bi bi-exclamation-triangle-fill"></i>
            case 'warning':
                return <i className="bi bi-exclamation-circle-fill"></i>
            case 'success':
                return <i className="bi bi-check-circle-fill"></i>
        }
    }

    const resetPosition = () => {
        setPositionBottom(POSITION_INVISIBLE)
    }
    
    useEffect(() => {
        let timer;
        if (message) {
            setPositionBottom(POSITION_VISIBLE)

            let timeout = config.timeout || 3000
            timer = setTimeout(resetPosition, timeout)
        }

        return () => {
            if(timer) clearTimeout(timer)
        }
    }, [message, uniqID])

    if (!message)
        return

    return (
        <div className={`status-component-container ${type}`}
            style={{ [config.side || 'bottom']: positionBottom, animation: `${config.side === 'top' ? 'animation-slide-down' : 'animation-slide-up'} 450ms ease-out` }}>
            <div className="status-component-icon">
                {getIcon()}
            </div>

            <div className="status-component-message">
                {message}
            </div>

            {config?.showCloseBtn && <div>
                <i className="bi bi-x-lg cursor-pointer" onClick={resetPosition}></i>
            </div>}
        </div>
    )
}

export default Status