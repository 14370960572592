import { useState, useEffect } from 'react'

import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import dayjs from 'dayjs'

import './index.css'

const PickerDate = ({ initialValue, onChange, dateFormat, readOnly=false }) => {
    return (
        <div className="picker-date-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={!initialValue || initialValue === '' ? null : dayjs(initialValue)}
                    disabled={readOnly}
                    slotProps={{ textField: { placeholder: 'Select Date' } }}
                    onChange={(newValue) => {
                        onChange(dateFormat ? dayjs(newValue).format(dateFormat) : newValue)
                    }}
                />
            </LocalizationProvider>
        </div>
    )
}

export default PickerDate