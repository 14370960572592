import { useState, useEffect } from 'react'
import Utils from '../helpers/utils'
import './FileUploader.css'

const FileUploader = ({field, value, onChange, readOnly, fileType='image', imageUrl: imageData, outputFormat='base64', acceptedFileTypes, multiple }) => {
    const [image, setImage] = useState()
    const [fileName, setFileName] = useState('')

    useEffect(() => {
        if (value) {
            setFileName(value.name)
        }
    }, [value])

    const handleChange = (data, field, files) => {
        let name = multiple ? `${files.length} files` : files[0].name
        onChange({value: data, name: name}, field)
        setFileName(name)
    }

    const renderImage = () => {
        if (imageData || image) {
            let imageUrl;
            let imgB64;
            if (image) imgB64 = image
            else if (imageData && typeof imageData === 'object' && imageData?.value && typeof imageData.value === 'string')
                imgB64 = imageData.value
            else if (imageData && typeof imageData === 'string')
                imageUrl = imageData
                
            return (
                <div className={`file-image ${field.name}`}>
                    <img src={imgB64 ? `data:image/png;base64,${imgB64}` : imageUrl} />
                </div>
            )
        }
    }

    return (
        <div className={`input-file-wrapper ${readOnly ? 'disabled' : ''}`}>
            <div className="input-file-wrapper-inner">
                <label>
                    <input type="file" multiple={multiple} name={field.name}
                        onChange={async (e) => {
                            let output = multiple ? e.target.files : e.target.files[0];
                            if (!output) return;
                            if(outputFormat === 'base64') {
                                if (multiple)
                                    output = await Promise.all(
                                        output.map(async (file) => await Utils.toBase64(file))
                                    )
                                else
                                    output = await Utils.toBase64(output)
                            } 
                            handleChange(output, field, e.target.files)
                            if(!multiple) setImage(output)
                        }}
                        disabled={readOnly}
                        accept={ acceptedFileTypes || undefined }
                    />
                    Upload File
                </label>
                {fileName ? <div className="filename"><i className="bi bi-file-earmark"></i> {fileName}</div> : null}
            </div>
            {fileType === 'image' && !multiple ? renderImage() : null}
        </div>
    )
}
export default FileUploader
