import {useState} from 'react'

import EntityCardComponent from '../../components/EntityCard'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import Tooltip from '../../components/Tooltip'
import UtilsHelper from '../../helpers/utils'
import PopoverComponent from '../../components/Popover'

import useNotices from '../../hooks/useNotices'

const Packages = () => {
    const [NoticeComponent, notices] = useNotices('PSM/Packages/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const baseUrl = '/packages'
    const baseApiUrl = '/api/v2/admin/packages'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name_route = `${baseUrl}/${item.id}/edit`
            item.ui_product_preview = <EntityCardComponent entityData={item} entityType="package" displayThumbnail={true} />

            return item
        })
    }

    return (
        <div className="packages-page-container page-container">
            <PageHeaderComponent title="Packages" actions={[{ title: 'New Package', route: '/packages/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'ui_product_preview',
                        title: 'Preview',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },{
                        key: 'code',
                        title: 'Code',
                    }
                ]}
                additionalDropdownOptions={[
                    {
                        title: 'Preview',
                        type: 'html',
                        html: (item) => {
                            return (
                                <PopoverComponent position="left" offsetPx={24}>
                                    <>
                                        <span>Preview</span> <i className="bi bi-display"></i>
                                    </>
                                    {item.use_image_for_description ? (
                                        <div className="popover-default">
                                            <div className="popover-default-title">{item.display_name}</div>
                                            <div className="popover-default-image" style={{backgroundImage: `url(${item.image.id ? item.image.sources[item.image.source]['url'] : 'https://via.placeholder.com/300x300?text=MISSING'})`}}></div>
                                            <hr />
                                            <div className="popover-default-title price">{UtilsHelper.formatDollar(item.value)}</div>
                                        </div>
                                    ) : (
                                        <div className="popover-default">
                                            <div className="popover-default-title">{item.display_name}</div>
                                            <div className="package-popover-description">
                                                <div dangerouslySetInnerHTML={{__html: item.description}} />
                                                <div className="popover-default-image" style={{backgroundImage: `url(${item.image.id ? item.image.sources[item.image.source]['url'] : 'https://via.placeholder.com/300x300?text=MISSING'})`}}></div>
                                            </div>
                                            <hr />
                                            <div className="popover-default-title price">{UtilsHelper.formatDollar(item.value)}</div>
                                        </div>
                                    )}
                                </PopoverComponent>
                            )
                        }
                    }
                ]} />
        </div>
    )
}

export default Packages
