import {useState} from 'react'

import './Filters.css'

const FiltersComponent = ({ showFilters, filters = [], onSelect }) => {
    const [activeFilters, setActiveFilters] = useState({})

    const onChangeFilter = (e) => {
        const newFilters = {
            ...activeFilters,
            [e.target.dataset.category]: e.target.value
        }
        
        setActiveFilters(newFilters)
        onSelect(newFilters)
    }

    const renderFilters = () => {
        return filters.map((filter) => {
            return (
                <div className="filters-field" key={filter.category}>
                    <span className="filters-field-label">{filter.category}</span>
                    <select value={activeFilters[filter.key]} data-category={filter.key} onChange={onChangeFilter}>
                        {filter.options.map(option => <option key={`filter-${filter.key}-${option.value}`} value={option.value} onClick={onChangeFilter}>{option.label}</option>)}
                    </select>
                </div>
            )
        })
    }

    if (!showFilters)
        return null

    return (
        <div className="filters-container">
            {renderFilters()}
        </div>
    )
}

export default FiltersComponent