import { useState, useEffect } from 'react'

import TabsComponent from '../components/Tabs'

import RouteHelper from '../helpers/route'
import lodash from 'lodash'

export default function useTabs(activeTab, tabs, key, initialTabPropsByTab) {
  const queryParams = RouteHelper.getQueryParams()

  const [tab, setTab] = useState(key && queryParams[key] ? queryParams[key].replace('_', ' ') : activeTab)
  const [tabPropsByTab, setTabPropsByTab] = useState(initialTabPropsByTab || {})

  // useEffect(() => {
  //     RouteHelper.setQueryParams('tab', tab.replace(' ', '_'))
  // }, [tab])

  // --- handle error counts update integrity due to async nature of state updates
  const updateTabErrorCounts = (prevValue, tab, errorGroupID, errorsCount) => {
    let errorCountsByGroup = prevValue[tab]?.errorCountsByGroup || {}
    errorCountsByGroup[errorGroupID] = errorsCount
    let totalErrorCount = Object.values(errorCountsByGroup).reduce((acc, val) => acc + val, 0)

    return {
        ...prevValue,
        [tab]: {
            ...prevValue[tab],
            errorCountsByGroup,
            errorCount: totalErrorCount
        }
    }
  }

  // --- set tab errors for given errorGroupID (eg. formID)
  const setTabErrors = (tab, errorGroupID, errorCount) => {
    let prevErrorCounts = (tabPropsByTab[tab]?.errorCountsByGroup || {})[errorGroupID] || 0
    if (errorCount === prevErrorCounts) return;

    setTabPropsByTab(
      prevValue => updateTabErrorCounts(prevValue, tab, errorGroupID, errorCount)
    )
  }

  // --- set errors for tabs forms
  const setTabsFormsErrors = (tabsWithFormsInfo, errors) => {
    // example structure of tabsWithFormsInfo
    // [{"tab":"General","forms":[{"id":"Main","fields":[]},{"id":"subForm","fields":[]}]}]

    // example structure of errors
    // {"name":["can't be blank"],"market_header_text":["can't be blank"],"sheet_selector_header_text":["can't be blank"]}
    
    lodash.each(tabsWithFormsInfo, (tabInfo) => {
      let tabName = tabInfo.tab;
      let tabForms = tabInfo.forms;

      lodash.each(tabForms, (formInfo) => {
        let formID = formInfo.id;
        let formFields = formInfo.fields;
        let formFieldKeys = lodash.map(formFields, (formInfo.fieldKeyProp || 'name'));
        let formErrorsCount = lodash.chain(errors).pick(formFieldKeys).size().value();
        setTabErrors(tabName, formID, formErrorsCount);
      })
    })
  }

  return [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors]
}