import { useState, useEffect, useContext } from 'react'

import { Link } from 'react-router-dom'

import AccordionComponent from '../../components/Accordion'
import PageHeaderComponent from '../../components/PageHeader'
import SelectAutocompleteComponent from '../../components/SelectAutocomplete'
import PaginationComponent from '../../components/Pagination'
import FormComponent from '../../components/Form'

import PickerDate from '../../components/PickerDate'

import APIHelper from '../../helpers/api'
import FetchHelper from '../../helpers/fetch'
import RouteHelper from '../../helpers/route'
import UtilsHelper from '../../helpers/utils'

import EmailTemplateForm from './partials/EmailForm'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import dayjs from 'dayjs'
import lodash from 'lodash'

import './index.css'

const emailFormFieldUniqKey = UtilsHelper.generateUUID()

const Blackouts = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Orders/Blackouts/Index')

    const [errors, setErrors] = useState([])
    const [data, setData] = useState()
    const [formData, setFormData] = useState()
    const [dataEmail, setDataEmail] = useState({
        subject: '',
        body: ''
    })
    const [page, setPage] = useState(1)
    const [allSelection, setAllSelection] = useState(false)
    const [markets, setMarkets] = useState()
    const [selectedMarket, setSelectedMarket] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [fetching, setFetching] = useState(false)

    const [optionsTemplates, setOptionsTemplates] = useState([])

    const [clickAndDragState, setClickAndDragState] = useState()
    const [clickAndDragIndexStart, setClickAndDragIndexStart] = useState()
    const [clickAndDragIndexEnd, setClickAndDragIndexEnd] = useState()
    const [prevFilters, setPrevFilters] = useState()

    useEffect(() => {
        ac.showSpinner(true)

        APIHelper.getMarketsGrouped(setMarkets)

        setStartDate(UtilsHelper.getLocalStorage('Blackouts_startDate') || dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
        setEndDate(UtilsHelper.getLocalStorage('Blackouts_endDate') || dayjs().format('YYYY-MM-DD'))

        FetchHelper({
            url: `/api/v2/admin/templates`
        }, (res) => {
            ac.showSpinner(false)
            setOptionsTemplates(res.body.data.map((item, index) => {
                return {
                    title: item.name,
                    value: item.id
                }
            }))
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    const applyFilters = async () => {
        if(!startDate || !endDate || !selectedMarket) return;

        ac.showSpinner(true)
        setFetching(true)

        let [res, err] = await UtilsHelper.executePromise(
            FetchHelper({
                url: `/api/v2/admin/markets/${selectedMarket}/schedules?start_date=${startDate}&end_date=${endDate}&page=${page}`
            })
        );
        
        ac.showSpinner(false)
        setFetching(false)
        if(err || res.status < 200 || res.status > 299) return

        setData(res.body)
        setPrevFilters({ startDate, endDate, selectedMarket })
    }

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            blackout: {
                market_id: selectedMarket,
                start_date: startDate,
                end_date: endDate,
                data: getSelected(),
                action: formData.action
            }
        }

        if (formData.send_email) {
            body.blackout.email = {
                send: true,
                subject: dataEmail.subject,
                content: dataEmail.content
            }
        }

        if (formData.send_sms) {
            body.blackout.sms = {
                send: true,
                template_id: formData.sms_template_id
            }
        }
        
        FetchHelper({
            url: `/api/v2/admin/blackouts`,
            method: 'post',
            body: body
        }, (res) => {
            RouteHelper.redirect('/blackouts')
        }, (res) => {
            
        })
    }

    const getSelected = () => {
        let selected = []

        data.data.forEach((itemData) => {
            itemData.slots.data.forEach(itemDataSlot => {
                if (itemDataSlot.ui_selected) {
                    selected.push({
                        schedule_id: itemData.id,
                        slot_id: itemDataSlot.id
                    })
                }
            })
        })

        return selected
    }

    const handleSelection = (e, rowItem) => {
        setData({
            ...data,
            data: data.data.map((item, index) => {
                if (item.id === rowItem.id) {
                    item.ui_selected = e.target.checked

                    item.slots.data = item.slots.data.map((slotItem, slotIndex) => {
                        slotItem.ui_selected = e.target.checked

                        return slotItem
                    })
                }

                return item
            })
        })
    }

    const handleSelectionRow = (e, rowItem) => {
        setData({
            ...data,
            data: data.data.map((item, index) => {
                item.slots.data = item.slots.data.map((slotItem, slotIndex) => {
                    if (slotItem.id === rowItem.id) {
                        slotItem.ui_selected = e.target.checked
                    }

                    return slotItem
                })

                return item
            })
        })
    }

    const handleAllSelection = (e) => {
        const state = e.target.checked

        setData({
            ...data,
            data: data.data.map((item, index) => {
                item.ui_selected = state

                item.slots.data = item.slots.data.map((slotItem, slotIndex) => {
                    slotItem.ui_selected = state

                    return slotItem
                })

                return item
            })
        })

        setAllSelection(state)
    }

    const handleStartDate = (newDate) => {
        UtilsHelper.setLocalStorage('Blackouts_startDate', newDate)

        setStartDate(newDate)
    }

    const handleEndDate = (newDate) => {
        UtilsHelper.setLocalStorage('Blackouts_endDate', newDate)

        setEndDate(newDate)
    }

    const renderPagination = () => {
        return data?.data?.length > 0 && (<div className="row"><PaginationComponent total={data.total} currentPage={data.current_page} totalPages={data.total_pages} setPage={setPage} /></div>) || null
    }

    const handleMouseDownCheckboxRow = (e, rowItem, rowIndex) => {
        if (!e.target.classList.contains('blackouts-checkbox'))
            return

        setClickAndDragState(!e.target.checked)
        setClickAndDragIndexStart(rowIndex)
    }

    const handleMouseEnterCheckboxRow = (rowItem, rowIndex) => {
        if (isNaN(clickAndDragIndexStart))
            return

        setClickAndDragIndexEnd(rowIndex)

        setData({
            ...data,
            data: data.data.map((item, index) => {
                item.slots.data = item.slots.data.map((slotItem, slotIndex) => {
                    if (!slotItem.ui_selected_in_current_selection && (slotIndex >= clickAndDragIndexStart && slotIndex <= rowIndex || slotIndex <= clickAndDragIndexStart && slotIndex >= rowIndex)) {
                        slotItem.ui_selected = clickAndDragState
                        slotItem.ui_selected_in_current_selection = true
                    }

                    return slotItem
                })

                return item
            })
        })
    }

    const handleMouseUpCheckboxRow = () => {
        setClickAndDragIndexStart()
        setClickAndDragIndexEnd()
        setData({
            ...data,
            data: data.data.map((item, index) => {
                item.slots.data = item.slots.data.map((slotItem, slotIndex) => {
                    slotItem.ui_selected_in_current_selection = false

                    return slotItem
                })

                return item
            })
        })
    }

    if(data?.data)
        data.data = lodash.map(data.data, (dataItem, dataIndex) => {
            let countBooked = 0
            let countCapacity = 0
            let countBlackedOut = 0
            let countBlackedOutTotal = dataItem.slots.data.length

            dataItem.slots.data.map((slotItem, slotIndex) => {
                countBooked += slotItem.used
                countCapacity += slotItem.capacity
                countBlackedOut += slotItem.blacked_out ? 1 : 0
            })

            dataItem.ui_count_booked = countBooked
            dataItem.ui_count_capacity = countCapacity
            dataItem.ui_count_blacked_out = countBlackedOut
            dataItem.ui_count_blacked_out_total = countBlackedOutTotal

            return dataItem
        })

    return (
        <div className="blackouts-page-container page-container">
            <PageHeaderComponent title="Blackouts" />

            <NoticeComponent location="Main" notices={notices} />

            <div className="blackouts-container">
                <div className="row markets">
                    <label>Market</label>
                    <SelectAutocompleteComponent disableAll={true} suggestions={markets || []} onSelect={(option) => setSelectedMarket(option.value)} cacheValue={true} cacheNamespace="Blackouts_Markets" />
                </div>
                <div className="row dates">
                    {startDate && endDate ? (
                        <>
                            <div>
                                <label>Start Date</label>
                                <PickerDate initialValue={startDate} onChange={handleStartDate} dateFormat="YYYY-MM-DD" />
                            </div>
                            <div>
                                <label>End Date</label>
                                <PickerDate initialValue={endDate} onChange={handleEndDate} dateFormat="YYYY-MM-DD" />
                            </div>
                        </>
                    ) : null}
                </div>
                <div>
                    <button className='mt-2' onClick={applyFilters} 
                        disabled={fetching || !startDate || !endDate || !selectedMarket || lodash.isEqual(prevFilters, { startDate, endDate, selectedMarket })}>Search</button>
                </div>

                <div className={fetching ? 'disabled' : ''}>
                    {/* {renderPagination()} */}

                    { !fetching && data?.data?.length === 0 && 
                        <div className='mt-8'><p className='text-center'>No data found!</p></div>}

                    {data?.data?.length > 0 && (<div className="row orders">
                        <ul>
                            <li className="blackouts-checkbox-row header">
                                <div className="column-checkbox"><input type="checkbox" checked={allSelection} onChange={(e) => handleAllSelection(e)} /></div>
                                <div><span className="column-title">Name</span></div>
                                <div><span className="column-title">Booked</span></div>
                                <div><span className="column-title">Capacity</span></div>
                                <div><span className="column-title">Blacked Out</span></div>
                            </li>
                            {data.data.map((item, i) => {
                                return (
                                    <AccordionComponent key={`blackouts-row-${item.id}-${i}`} open={false}>
                                        <div className="blackouts-row-title">
                                            <input className="blackouts-row-title-checkbox" type="checkbox" checked={item.ui_selected || false} onClick={(e) => e.stopPropagation()} onChange={(e) => handleSelection(e, item)} />
                                            <div>{item.date}</div>
                                            <div>{item.ui_count_booked}</div>
                                            <div>{item.ui_count_capacity}</div>
                                            <div>{item.ui_count_blacked_out} / {item.ui_count_blacked_out_total}</div>
                                        </div>
                                        <div className="blackouts-row-content">
                                            {item.slots?.data?.map((slotItem, slotIndex) => {
                                                return (
                                                    <li key={`blackouts-checkbox-${slotItem.id}-${slotIndex}`} className={`blackouts-checkbox-row`} onMouseUp={handleMouseUpCheckboxRow} onMouseDown={(e) => handleMouseDownCheckboxRow(e, slotItem, slotIndex)} onMouseEnter={() => handleMouseEnterCheckboxRow(slotItem, slotIndex)}>
                                                        <div className="column-checkbox"><input type="checkbox" className="blackouts-checkbox" checked={slotItem.ui_selected || false} onChange={(e) => handleSelectionRow(e, slotItem)} /></div>
                                                        <div>{slotItem.name}</div>
                                                        <div>{slotItem.used}</div>
                                                        <div>{slotItem.capacity}</div>
                                                        <div>{slotItem.blacked_out ? 'Yes' : 'No'}</div>
                                                    </li>
                                                )
                                            })}
                                        </div>
                                    </AccordionComponent>
                                )
                            })}
                        </ul>
                    </div>) || null}

                    {/* {renderPagination()} */}
                </div>
            </div>

            <div className="blackouts-form">
                <FormComponent formData={formData} formErrors={errors} onChange={setFormData} fields={[{
                    type: 'switch',
                    name: 'send_email',
                    label: 'Send Email'
                }, {
                    type: 'html',
                    html: <EmailTemplateForm key={'email-form'} onChange={(dataSubject, dataBody) => setDataEmail({
                            subject: dataSubject,
                            content: dataBody
                        }) } />,
                    uniqKey: emailFormFieldUniqKey,
                    condition: (data) => data.send_email === true,
                }, {
                    type: 'switch',
                    name: 'send_sms',
                    label: 'Send SMS'
                }, {
                    type: 'select',
                    name: 'sms_template_id',
                    condition: (data) => {
                        return data.send_sms
                    },
                    options: optionsTemplates
                }, {
                    type: 'radio',
                    name: 'action',
                    label: 'Action',
                    value: 'blackout',
                    options: [
                        {
                            label: 'Blackout',
                            value: 'blackout'
                        },
                        {
                            label: 'Open for Business',
                            value: 'open'
                        },

                    ]
                }]} />

                <button onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    )
}

export default Blackouts
