import React from 'react';
import PropTypes from 'prop-types';

import { ButtonComponent } from '../../Primitive';

import './index.css';

const FileForImage = ({ file, events }) => {
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result

        if (events.handleFileChange)
          events.handleFileChange({
            data: base64Image,
            filename: file.name
          })
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="ui-c-FileForImage">
      {file?.data && (
        <div className="ui-attachedImage">
          <span className="ui-filename">Image attached</span>

          <ButtonComponent
            config={{
                palette: 'red',
                tip: 'Delete image?',
                size: 'small'
            }}
            events={{ handleClick: () => events.handleFileDelete() }}>
              <span className="ui-icon bi bi-trash-fill"></span>
          </ButtonComponent>
        </div>
      ) || <input type="file" accept="image/*" className="ui-fileInput" onChange={handleImageUpload} />}
    </div>
  );
};

FileForImage.propTypes = {
  events: PropTypes.shape({
    handleFileChange: PropTypes.func,
    handleFileDelete: PropTypes.func
  })
};

export { FileForImage };
