import {useState, useEffect} from 'react'

import ErrorsComponent from '../Errors'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../Form'
import Utils from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'

import './index.css'
import { FileTypes } from '../../sys/constants/enums'

const ImageDragAndDrop = ({ apiUrl, method='post', image, onComplete=()=>{}, onError=()=>{} }) => {
    const [ac] = useAppContext()

    const [errors, setErrors] = useState([])
    const [isDragActive, setIsDragActive] = useState(false)

    const handleImageAdd = (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (event.type === 'dragenter' || event.type === 'dragover') {
            setIsDragActive(true)
        } else {
            setIsDragActive(false)
        }

        if (event.type === 'drop') {
            uploadImage(event)
        }
    }

    const uploadImage = (event, base64) => {
        let file = null

        if (event) {
            file = event.dataTransfer.files && event.dataTransfer.files[0]
        }
        

        if (file) {
            Utils.toBase64(file)
                .then((data) => {
                    fetchImage(data, file.name)
                })
        }
    }

    const fetchImage = (base64, filename) => {
        ac.showSpinner(true)

        setErrors([])
        
        if (method === 'put') {
            FetchHelper({
                url: apiUrl,
                method: method,
                body: {
                    proof_image: {
                        image_base64: base64
                    }
                }
            }, (res) => {
                ac.showSpinner(false)
                onComplete(res.body)
            }, (res) => {
                ac.showSpinner(false)
                setErrors(res.body)
                onError(res.body)
            })
        } else {
            FetchHelper({
                url: apiUrl,
                method: method,
                body: {
                    proof_image: {
                        filename: filename,
                        image_base64: base64
                    }
                }
            }, (res) => {
                ac.showSpinner(false)
                onComplete(res.body)
            }, (res) => {
                ac.showSpinner(false)
                setErrors(res.body)
                onError(res.body)
            })
        }
        
    }

    let styles = {}

    if (image) {
        styles.backgroundImage = `url(${image})`
    }

    return (
        <div className="image-drag-and-drop-container">
            <div className={`drop-zone ${isDragActive ? 'drag-active' : ''}`} onDragEnter={handleImageAdd} onDragLeave={handleImageAdd} onDragOver={handleImageAdd} onDrop={handleImageAdd}>
                <div className="image" style={styles}></div>
            </div>
            <div className="browse-zone">
                <FormComponent type="stacked" formData={{}} formErrors={{}} onChange={(data) => {
                    if (data.image?.value) {
                        fetchImage(data.image.value, data.image.name)
                    }
                }} displayErrorBanner={true} fields={[
                    {
                        label: 'Image',
                        type: 'input-file',
                        name: 'image',
                        fileType: 'file',
                        acceptedFileTypes: FileTypes.IMAGES
                    } ]} />
                <ErrorsComponent errors={errors} />
            </div>
        </div>
    )
}

export default ImageDragAndDrop