import { useEffect, useState } from 'react'

import ControlBar from '../../components/ControlBar'
import DropdownTextCollectionComponent from '../../components/DropdownTextCollection'
import PageHeaderComponent from '../../components/PageHeader'
import ErrorsComponent from '../../components/Errors'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'

import './edit.css'
import TextTags from '../../components/TextTags'
import HTMLEditor from '../../components/HTMLEditor'

const TemplatesNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Templates/New')

    const [dataName, setDataName, clearDataName, dataNameDefault] = useDraft('Tools:Templates:DataName')
    const [dataType, setDataType, clearDataType, dataTypeDefault] = useDraft('Tools:Templates:DataType')
    const [dataBodyText, setDataBodyText, clearDataBodyText, dataBodyTextDefault] = useDraft('Tools:Templates:DataBodyText', '')
    const [dataBodyEmail, setDataBodyEmail, clearDataBodyEmail, dataBodyEmailDefault] = useDraft('Tools:Templates:DataBodyEmail', '')
    const [errors, setErrors] = useState()

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = {
                name: dataName,
                type: dataType,
                body: dataType === 'TextTemplate' ? dataBodyText : dataBodyEmail
            }

            FetchHelper({
                url: `/api/v2/admin/templates`,
                method: 'POST',
                body: {
                    template: body
                }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className="templates-edit-page-container">
            <PageHeaderComponent title={`New Template`} />

            <NoticeComponent location="Main" notices={notices} />

            <div className="global-section">
                <ErrorsComponent errors={errors} />

                <div className="input-container main-input-name">
                    <h3 className="label">Name</h3>

                    <input type="text" name="name" value={dataName} onChange={(e) => setDataName(e.target.value)} />
                </div>

                <div className="input-container main-input-type">
                    <h3 className="label">Type</h3>

                    <select name='type' value={dataType} onChange={(e) => setDataType(e.target.value)}>
                        <option key='none' value=''></option>
                        <option key='text' value='TextTemplate'>Text</option>
                        <option key='email' value='EmailTemplate'>Email</option>
                    </select>
                </div>

                { dataType && 
                    <div className="input-container main-input-body">
                        <h3 className="label">Body</h3>

                        { dataType === 'TextTemplate' &&
                            <>
                                <TextTags onSelect={(value) => {
                                    // @TODO: insert at cursor
                                    setDataBodyText(`${dataBodyText} ${value}`)
                                }} />
        
                                <div className="body">
                                    <textarea className="global-textarea" name="body" value={dataBodyText} onChange={(e) => setDataBodyText(e.target.value)}></textarea>
                                </div>

                                <p className="disclaimer">After adding Custom Campaign Field, you MUST change <span className="replace-text">REPLACEME</span> to the name of your desired data field.</p>
                            </> }
                            
                        { dataType === 'EmailTemplate' &&
                            <HTMLEditor initialValue={dataBodyEmail} onChange={(value) => setDataBodyEmail(value)}
                                showTags={true} /> }

                    </div>
                }
            </div>

            <ControlBar handleSubmit={handleSubmit} baseApiUrl="/templates" redirectUrl="/templates" cancelUrl="/templates" handleAfterSubmit={() => RouteHelper.redirect('/templates')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [dataName, clearDataName, dataNameDefault],
                [dataType, clearDataType, dataTypeDefault],
                [dataBodyText, clearDataBodyText, dataBodyTextDefault],
                [dataBodyEmail, clearDataBodyEmail, dataBodyEmailDefault]
            ]} />
        </div>
    )
}

export default TemplatesNew
