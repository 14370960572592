import DropdownTextComponent from '../DropdownText'

import { PropTypes } from 'prop-types'

import './index.css'

const DropdownTextCollection = ({ identifier = '', options, onSelect }) => {
    return (
        <div className={`dropdown-text-collection-container id-${identifier}`}>
            {options.map((item, index) => {
                return (
                    <DropdownTextComponent key={`templates-text-substitution-${item.title}`} options={item} onClick={(value) => {
                        onSelect(value)
                    }} />
                )
            })}
        </div>
    )
}

DropdownTextCollection.propTypes = {
    identifier: PropTypes.string,
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired
}

export default DropdownTextCollection