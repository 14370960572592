import {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import PaginationComponent from '../components/Pagination'

import IconSort from '../assets/icon-table-sort.svg'
import IconSortAscending from '../assets/icon-table-sort-ascending.svg'
import IconSortDescending from '../assets/icon-table-sort-descending.svg'

import dayjs from 'dayjs'

import './Table.css'
import useAppContext from '../hooks/useAppContext';

const TableComponent = ({ columns, data, enablePagination=true, enableEditColumns=true, onSetPage=()=>{} }) => {
    const [ac] = useAppContext()
    const [page, setPage] = useState(1)
    const [activeColumns, setActiveColumns] = useState({})
    const [sortColumn, setSortColumn] = useState(columns[0])
    const [sortOrder, setSortOrder] = useState(false)
    const [showColumnSelector, setShowColumnSelector] = useState(false)
    const numberOfColumns = columns.length

    useEffect(() => {
        setSortColumn(columns[0].key)

        setActiveColumns(
            columns.reduce((prev, cur, i) => {
                if (enableEditColumns) {
                    prev[cur.key] = i < 8 ? true : false
                } else {
                    prev[cur.key] = true
                }

                return prev
            }, {})
        )
    }, [])

    const updateSort = (key) => {
        if (key !== sortColumn) {
            setSortOrder(false)
        } else {
            setSortOrder(!sortOrder)
        }

        setSortColumn(key)
    }

    const getSortBackgroundImage = (columnKey) => {
        if (columnKey === sortColumn) {
            if (!sortOrder) {
                return IconSortAscending
            } else {
                return IconSortDescending
            }
        } else {
            return IconSort
        }
    }

    const getDataLength = () => {
        let count = 0

        data.forEach((groupItem) => {
            count += groupItem.data.length
        })

        return count
    }

    const renderPagination = () => {
        let count = getDataLength()

        return enablePagination && data.length > 0 ? <PaginationComponent currentPage={page} totalPages={Math.ceil(count/40)} setPage={(page) => {
            setPage(page)
            onSetPage(page)
        }} /> : null
    }

    const renderColumnSelector = () => {
        if (!enableEditColumns)
            return null

        if (!showColumnSelector) {
            return (
                 <div className="table-column-selector-toggle" onClick={() => setShowColumnSelector(true)}>
                    <span>Edit Columns</span>
                 </div>
            )
        }

        return (
            <div className="table-column-selector">
                {columns.map((column) => {
                    return (
                        <div key={`table-column-selector-${column.key}`}className="table-column-selector-cell" onClick={() => setActiveColumns({ ...activeColumns, [column.key]: !activeColumns[column.key] })}>
                            <input type="checkbox" checked={activeColumns[column.key]} readOnly /> {column.title}
                        </div>
                    )
                })}

                <div className="table-column-selector-toggle" onClick={() => setShowColumnSelector(false)}>
                    <span>Done</span>
                 </div>
            </div>
        )
    }

    data = data.map((group) => {
        group.data.sort((_a, _b) => {
            const key = `ui_${sortColumn}`

            let a = _a[key]
            let b = _b[key]

            // remove additional characters from monetary values
            if (/^\$/.test(a)) {
                a = a.replace('$', '').replace(',', '')
                b = b.replace('$', '').replace(',', '')
            }

            if (!isNaN(a)) {
                a = Number(a)
                b = Number(b)
            }

            if (a < b) {
                return !sortOrder ? -1 : 1
            } else if (a > b) {
                return !sortOrder ? 1 : -1
            }

            return 0
        })

        return group
    })

    const processCellByColumnType = (value, column) => {
        let val = value
        let format;

        switch (column.type) {
            // listing out all possible cases
            case 'string':
            case 'cc_digits':
            case 'confirmation_number':
            case 'theme':
            case 'state':
            case 'order':
                val = val
                break
            case 'number':
                val = Number(val)
                break
            case 'date':
                format = 'YYYY-MM-DD z'
                try {
                    val = dayjs(val).tz(ac.AuthHelper.settings.timezone).format(format)
                } catch (e) {
                    val = dayjs(val).format(format)
                }
                break
            case 'datetime':
                format = 'YYYY-MM-DD h:mm A z'
                try {
                    val = dayjs(val).tz(ac.AuthHelper.settings.timezone).format(format)
                } catch (e) {
                    val = dayjs(val).format(format)
                }
                break
            case 'time':
                format = 'h:mm A z'
                try {
                    val = dayjs(val).tz(ac.AuthHelper.settings.timezone).format(format)
                } catch (e) {
                    val = dayjs(val).format(format)
                }
                break
        }

        return val
    }

    return (
        <div className="table-container">
            {renderPagination()}
            {renderColumnSelector()}
            <div className="table-container-wrapper printable">
                <table className="table">
                    <thead>
                        <tr>
                            {columns.map((column) => {
                                if (!activeColumns[column.key]) return null

                                return (
                                    <th key={`table-head-${column.title}`} onClick={() => updateSort(column.key)} style={{ backgroundImage: `url(${getSortBackgroundImage(column.key)})` }} className={sortColumn === column.key ? 'action-sort' : ''}>{column.title}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    {data.map((item, itemIndex) => {
                        return (
                            <tbody key={`${item.group}-${itemIndex}`}>
                                {item.group !== 'default' ? (
                                    <tr>
                                        <td className="table-body-row-group-title" colSpan={columns.length}>{item.group}</td>
                                    </tr>
                                ) : null}

                                {item.data.map((groupItem, groupItemIndex) => {
                                    return (
                                        <tr key={`table-body-row-${item.group}-${groupItemIndex}`} className={`table-body-row ${groupItem.ui_className}`}>
                                            {columns.map((column, columnIndex) => {
                                                if (!activeColumns[column.key]) return null

                                                const key = `ui_${column.key}`
                                                const isUndefined = groupItem[key] === undefined
                                                let val = isUndefined ? 'N/A' : groupItem[key]

                                                val = processCellByColumnType(val, column)

                                                return (
                                                    <td key={`table-body-row-${item.group}-${groupItemIndex}-column-${column.title}`} className={isUndefined ? 'table-undefined' : ''}>
                                                        {column.type === 'order' ? (<Link to={`/order/${groupItem.id}`}>{val}</Link>) : val}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        )
                    })}
                </table>
            </div>
            {renderPagination()}
        </div>
    )
}

export default TableComponent
