import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { TagComponent } from '..'

import './index.css'

const TagCollectionComponent = ({ items=[], events={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        tagged: items || []
    })

    const handleClick = (item) => {
        let list = _state.get('tagged').filter(taggedItem => taggedItem.id !== item.id)

        _state.set('tagged', list)

        if (events.handleChange)
            events.handleChange(list)
    }

    return (
        <UI.Layout name="tagCollection" type="container">
            {items.map((item, index) => {
                return <TagComponent key={`ui-key--tagCollection--item--${index}`} item={item} events={{ handleClick: () => handleClick(item) }}/>
            })}
        </UI.Layout>
    )
}

TagCollectionComponent.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),

    config: PropTypes.shape({})
}

export { TagCollectionComponent }