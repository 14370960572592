import {useState, useEffect} from 'react'

import './index.css'

const Accordion = ({ children, open=false, className }) => {
    const [isOpen, setIsOpen] = useState(open)

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    return (
        <div className={`accordion-container ${isOpen ? 'open' : 'closed'} ${className}`}>
            <div className="accordion-titlebar" onClick={() => setIsOpen(!isOpen)}>
                {children[0]}
            </div>

            {isOpen ? (
                <div className="accordion-content">
                    {children[1]}
                </div>
            ) : null}
        </div>
    )
}

export default Accordion