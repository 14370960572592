import { FileTypes } from "../../../sys/constants/enums";

export function getNameFormFields() {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name'
    }
  ];
}

export function getHeaderFormFields() {
  return [
    {
      type: 'color-picker',
      name: 'header_bg_color',
      label: 'Header BG Color',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'header_divider_color1',
      label: 'Header Divider Color:',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'header_divider_color2',
      label: 'Header Divider Color:',
      color_type: 'block'
    }
  ];
}

export function getPageFormFields() {
  return [
    {
      type: 'color-picker',
      name: 'page_bg_color',
      label: 'Page BG Color',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'page_text_color1',
      label: 'Page Text Color 1',
      color_type: 'text'
    },
    {
      type: 'color-picker',
      name: 'page_text_color2',
      label: 'Page Text Color 2',
      color_type: 'text'
    },
    {
      type: 'color-picker',
      name: 'page_text_color3',
      label: 'Page Text Color 3',
      color_type: 'text'
    }
  ];
}

export function getBoxFormFields() {
  return [
    {
      type: 'color-picker',
      name: 'box_bg_color1',
      label: 'Box BG Color #1',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'box_bg_color2',
      label: 'Box BG Color #2',
      color_type: 'block'
    }
  ];
}

export function getCalendarFormFields() {
  return [
    {
      type: 'color-picker',
      name: 'calendar_color_1',
      label: 'Primary Calendar Color',
      color_type: 'gradient-lg'
    },
    {
      type: 'color-picker',
      name: 'calendar_color_2',
      label: 'Secondary Calendar Color',
      color_type: 'gradient-md'
    },
    {
      type: 'color-picker',
      name: 'calendar_color_3',
      label: 'Calendar color 3',
      color_type: 'gradient-sm'
    }
  ];
}

export function getAddonsFormFields() {
  return [
    {
      type: 'color-picker',
      name: 'addons_tab_text_color',
      label: 'Addons tab text color',
      color_type: 'text'
    },
    {
      type: 'color-picker',
      name: 'addons_tab_bg_color',
      label: 'Addons tab bg color',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'addons_tab_text_color_active',
      label: 'Addons tab text color active',
      color_type: 'text'
    },
    {
      type: 'color-picker',
      name: 'addons_tab_bg_color_active',
      label: 'Addons tab bg color active',
      color_type: 'block'
    }
  ];
}

export function getButtonsFormFields() {
  return [
    {
      type: 'color-picker',
      name: 'button_bg_color1',
      label: 'Button BG Color #1',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'button_text_color1',
      label: 'Button Text Color #1',
      color_type: 'text'
    },
    {
      type: 'color-picker',
      name: 'button_bg_color2',
      label: 'Button BG Color #2',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'button_text_color2',
      label: 'Button Text Color #2',
      color_type: 'text'
    },
    {
      type: 'color-picker',
      name: 'button_bg_color3',
      label: 'Button BG Color #3',
      color_type: 'block'
    },
    {
      type: 'color-picker',
      name: 'button_text_color3',
      label: 'Button Text Color #3',
      color_type: 'text'
    }
  ];
}

export function getFooterFormFields() {
  return [
    {
      type: 'color-picker',
      name: 'footer_bg_color',
      label: 'Footer BG Color',
      color_type: 'block'
    }
  ];
}

export function getCustomFilesFormFields(
  customFiles,
  inputImages,
  onDeleteFile
) {
  return [
    {
      type: 'input-file',
      name: 'custom_stylesheet',
      label: 'Stylesheet',
      fileType: '',
      acceptedFileTypes: FileTypes.CSS,
      label_info: customFiles?.custom_stylesheet
        ? [
            <a
              key={`download-file-custom-stylesheet`}
              download
              href={customFiles.custom_stylesheet}
            >
              Download
            </a>
          ]
        : '',
      field_info: customFiles?.custom_stylesheet
        ? [
            <div key={`delete-file-custom-stylesheet`}>
              <input
                type="checkbox"
                onChange={onDeleteFile}
                name="customFiles"
                value={inputImages?.customFiles}
                checked={inputImages.customFiles}
              />
              Delete Image?
            </div>
          ]
        : ''
    }
  ];
}

export function getMarketsFormFields(
    availableMarkets = [],
    selectedMarketIds = []
) {
  return [
    {
      type: 'two-multiselect',
      name: 'market_ids',
      label: 'Markets',
      list_labels: ['Available Markets', 'Markets Selected'],
      selectable: availableMarkets,
      selected: selectedMarketIds || []
    }
  ];
}
