import { useState, useEffect, useCallback } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import AssetUploader from '../../componentsV2/Integrated/AssetUploader'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './index.css'
import { useSimpleState } from '../../UI/hooks'
import { getAddonsFormFields, getBoxFormFields, getButtonsFormFields, getCalendarFormFields, getCustomFilesFormFields, getFooterFormFields, getHeaderFormFields, getMarketsFormFields, getNameFormFields, getPageFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const ColorSchemesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('UserExperience/ColorSchemes/Edit')

    const {_state: _imagesHeader} = useSimpleState()
    const {_state: _imagesPage} = useSimpleState()
    const {_state: _imagesFooter} = useSimpleState()

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [disableButton, setDisableButton] = useState(false)
    const [name, setName] = useState()
    const [header, setHeader] = useState()
    const [page, setPage] = useState()
    const [box, setBox] = useState()
    const [calendar, setCalendar] = useState()
    const [addons, setAddons] = useState()
    const [buttons, setButtons] = useState()
    const [footer, setFooter] = useState()
    const [deleteFiles, setDeleteFiles] = useState([])
    const [customFiles, setCustomFiles] = useState()
    const [markets, setMarkets] = useState([])
    const [dataMarkets, setDataMarkets] = useState([])
    const [availableMarkets, setAvailableMarkets] = useState([])
    const [inputImages, setInputImages] = useState({
        pageBgImage: false,
        customFiles: false,
        footerBgImage: false,
        headerImageLg: false,
        headerImageMd: false,
        headerImageSm: false,
        headerImageXs: false
    })

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Name', ['Name', 'Header', 'Page', 'Box', 'Calendar', 'Addons', 'Buttons', 'Footer', 'Custom Files', 'Markets'])

    const [nameFormFields] = useState(getNameFormFields());
    const [headerFormFields] = useState(getHeaderFormFields());
    const [pageFormFields] = useState(getPageFormFields());
    const [boxFormFields] = useState(getBoxFormFields());
    const [calendarFormFields] = useState(getCalendarFormFields());
    const [addonsFormFields] = useState(getAddonsFormFields());
    const [buttonsFormFields] = useState(getButtonsFormFields());
    const [footerFormFields] = useState(getFooterFormFields());
    const [customFilesFormFields, setCustomFilesFormFields] = useState(getCustomFilesFormFields());
    const [marketsFormFields, setMarketsFormFields] = useState(getMarketsFormFields())

    const isFirstRender = useIsFirstRender()

    const onDeleteFile = useCallback((e) => {
        let delete_images = []
        const name = e.target.name;
        const images = {
            pageBgImage: 'page_bg_image',
            footerBgImage: 'footer_bg_image',
            headerImageLg: 'header_image_lg',
            headerImageMd: 'header_image_md',
            headerImageSm: 'header_image_sm',
            headerImageXs: 'header_image_xs',
            customFiles: 'custom_stylesheet'
        }

        if (!inputImages[name] === true) {
            delete_images = [...deleteFiles, images[name]]
        }

        setDeleteFiles(delete_images)
        setInputImages({ ...inputImages, [name]: !inputImages[name] })
    }, [inputImages, deleteFiles])

    useEffect(() => {
        let fields = getCustomFilesFormFields(customFiles, inputImages, onDeleteFile)
        setCustomFilesFormFields(fields)
    }, [customFiles, inputImages, onDeleteFile])

    useEffect(() => {
        let fields = getMarketsFormFields(availableMarkets, markets?.market_ids)
        setMarketsFormFields(fields)
    }, [availableMarkets, markets?.market_ids])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Name',
                forms: [{ id: 'Main', fields: nameFormFields }]
            },
            {
                tab: 'Header',
                forms: [{ id: 'Main', fields: headerFormFields }]
            },
            {
                tab: 'Page',
                forms: [{ id: 'Main', fields: pageFormFields }]
            },
            {
                tab: 'Box',
                forms: [{ id: 'Main', fields: boxFormFields }]
            },
            {
                tab: 'Calendar',
                forms: [{ id: 'Main', fields: calendarFormFields }]
            },
            {
                tab: 'Addons',
                forms: [{ id: 'Main', fields: addonsFormFields }]
            },
            {
                tab: 'Buttons',
                forms: [{ id: 'Main', fields: buttonsFormFields }]
            },
            {
                tab: 'Footer',
                forms: [{ id: 'Main', fields: footerFormFields }]
            },
            {
                tab: 'Custom Files',
                forms: [{ id: 'Main', fields: customFilesFormFields }]
            },
            {
                tab: 'Markets',
                forms: [{ id: 'Main', fields: marketsFormFields }]
            },
            {
                tab: 'Header',
                forms: [{ id: 'Header', fields: _imagesHeader.get(), fieldKeyProp: 'id' }]
            },
            {
                tab: 'Page',
                forms: [{ id: 'Page', fields: _imagesPage.get(), fieldKeyProp: 'id' }]
            },
            {
                tab: 'Footer',
                forms: [{ id: 'Footer', fields: _imagesFooter.get(), fieldKeyProp: 'id' }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/color-schemes/${params.id}`
        }, (res) => {
            ac.showSpinner(false)

            _imagesHeader.set([
                {
                    type: 'Header: Large',
                    id: 'header_image_lg',
                    externalUrl: res.body.header_image_lg.sources.remote?.url,
                    note: 'Recommended dimensions: 1170x200',
                    attachedImage: {
                        data: res.body.header_image_lg.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.header_image_lg.source === 'local' ? '0' : '1',
                    relatedFields: ['header_image_lg_url']
                },
                {
                    type: 'Header: Medium',
                    id: 'header_image_md',
                    externalUrl: res.body.header_image_md.sources.remote?.url,
                    note: 'Recommended dimensions: 970x200',
                    attachedImage: {
                        data: res.body.header_image_md.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.header_image_md.source === 'local' ? '0' : '1',
                    relatedFields: ['header_image_md_url']
                },
                {
                    type: 'Header: Small',
                    id: 'header_image_sm',
                    externalUrl: res.body.header_image_sm.sources.remote?.url,
                    note: 'Recommended dimensions: 750x200',
                    attachedImage: {
                        data: res.body.header_image_sm.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.header_image_sm.source === 'local' ? '0' : '1',
                    relatedFields: ['header_image_sm_url']
                },
                {
                    type: 'Header: Extra Small',
                    id: 'header_image_xs',
                    externalUrl: res.body.header_image_xs.sources.remote?.url,
                    note: 'Recommended dimensions: 767x200',
                    attachedImage: {
                        data: res.body.header_image_xs.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.header_image_xs.source === 'local' ? '0' : '1',
                    relatedFields: ['header_image_xs_url']
                },
            ])

            _imagesPage.set([
                {
                    type: 'Page Image',
                    id: 'page_bg_image',
                    externalUrl: res.body.page_bg_image.sources.remote?.url,
                    note: 'Image will be tiled',
                    attachedImage: {
                        data: res.body.page_bg_image.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.page_bg_image.source === 'local' ? '0' : '1',
                    relatedFields: ['page_bg_image_url']
                }
            ])
            
            _imagesFooter.set([
                {
                    type: 'Footer Image',
                    id: 'footer_bg_image',
                    externalUrl: res.body.footer_bg_image.sources.remote?.url,
                    note: 'Image will not be resized. Image will be tiled horizontally.',
                    attachedImage: {
                        data: res.body.header_image_lg.sources.local?.url,
                        filename: ''
                    },
                    assetSource: res.body.header_image_lg.source === 'local' ? '0' : '1'
                }
            ])

            let headerData = {
                // header_image_lg: res.body.header_image_lg,
                // header_image_md: res.body.header_image_md,
                // header_image_sm: res.body.header_image_sm,
                // header_image_xs: res.body.header_image_xs,
                header_bg_color: res.body.header_bg_color,
                header_divider_color1: res.body.header_divider_color1,
                header_divider_color2: res.body.header_divider_color2
            }

            let pageData = {
                // page_bg_image: res.body.page_bg_image,
                page_bg_color: res.body.page_bg_color,
                page_text_color1: res.body.page_text_color1,
                page_text_color2: res.body.page_text_color2,
                page_text_color3: res.body.page_text_color3
            }
            let boxData = {
                box_bg_color1: res.body.box_bg_color1,
                box_bg_color2: res.body.box_bg_color2
            }
            let calendarData = {
                calendar_color_1: res.body.calendar_color_1,
                calendar_color_2: res.body.calendar_color_2,
                calendar_color_3: res.body.calendar_color_3
            }
            let addons = {
                addons_tab_bg_color: res.body.addons_tab_bg_color,
                addons_tab_text_color: res.body.addons_tab_text_color,
                addons_tab_bg_color_active: res.body.addons_tab_bg_color_active,
                addons_tab_text_color_active: res.body.addons_tab_text_color_active
            }
            let buttons = {
                button_bg_color1: res.body.button_bg_color1,
                button_bg_color2: res.body.button_bg_color2,
                button_bg_color3: res.body.button_bg_color3,
                button_text_color1: res.body.button_text_color1,
                button_text_color2: res.body.button_text_color2,
                button_text_color3: res.body.button_text_color3
            }
            let footerData = {
                footer_bg_color: res.body.footer_bg_color,
                // footer_bg_image: res.body.footer_bg_image
            }
            setName({ name: res.body.name })
            setHeader(headerData)
            setPage(pageData)
            setBox(boxData)
            setCalendar(calendarData)
            setAddons(addons)
            setButtons(buttons)
            setFooter(footerData)
            setCustomFiles({ custom_stylesheet: res.body.custom_stylesheet })
            setDataMarkets(res.body.market_ids)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: '/api/v2/admin/markets',
        }, (res) => {
            let marketsList = []
            let savedMarkets = []

            res.body.data.forEach(elem => {
                const market = {
                    id: elem.id,
                    group: elem.market_group ? elem.market_group.name : 'UNGROUPED',
                    name: elem.external_id ? elem.external_id + ' - ' + elem.name : elem.name
                }

                dataMarkets.forEach(item => {
                    if (elem.id === item) {
                        savedMarkets.push(market)
                    }
                })
                marketsList.push(market)
            });

            setAvailableMarkets(marketsList)
            let sortedMarkets = savedMarkets.sort((a, b) => (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0))
            setMarkets({ market_ids: sortedMarkets })

            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [dataMarkets])

    const handleSubmit = () => {
        ac.showSpinner(true)
        setDisableButton(false)

        let images = {}
        let temp_body = { ...header, ...page, ...footer, ...customFiles }
        const files_attributes = ["custom_stylesheet"]

        for (var i = 0; i < 1; i++) {
            const img = temp_body[files_attributes[i]]

            if (files_attributes[i]) {
                if (img && (img.value !== "") && (img !== data[files_attributes[i]])) {
                    images = {
                        ...images,
                        [files_attributes[i] + "_base64"]: img.value
                    }
                }
                delete temp_body[files_attributes[i]]
            }
        }

        let imageBody = {}

        _imagesHeader.get().forEach((item, index) => {
            imageBody[`${item.id}_url`] = item.externalUrl
            imageBody[`${item.id}_source`] = item.assetSource === '0' ? 'local' : 'remote'
            imageBody[`${item.id}_base64`] = /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null
        })

        _imagesPage.get().forEach((item, index) => {
            imageBody[`${item.id}_url`] = item.externalUrl
            imageBody[`${item.id}_source`] = item.assetSource === '0' ? 'local' : 'remote'
            imageBody[`${item.id}_base64`] = /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null
        })

        _imagesFooter.get().forEach((item, index) => {
            imageBody[`${item.id}_url`] = item.externalUrl
            imageBody[`${item.id}_source`] = item.assetSource === '0' ? 'local' : 'remote'
            imageBody[`${item.id}_base64`] = /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null
        })

        let body = {
            name: name.name,
            ...temp_body,
            ...images,
            ...imageBody,
            ...box,
            ...calendar,
            ...addons,
            ...buttons,
            delete_images: deleteFiles
        }

        if (markets && markets.market_ids) {

            let market_ids = []
            markets.market_ids.map(elem => {
                market_ids.push(elem.id)
            })

            body = {
                ...body,
                market_ids: market_ids
            }
        }

        FetchHelper({
            url: `/api/v2/admin/color-schemes/${params.id}`,
            method: 'PUT',
            body: {
                color_scheme: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/color-schemes')
        }, (res) => {
            ac.showSpinner(false)
            setDisableButton(true)
            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(
                    [..._imagesHeader.get(), ..._imagesPage.get()],
                    res.body
                )
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    const onChangeTab = (tab) => {
        switch (tab) {
            case 'Name':
                return (
                    <>
                        <NoticeComponent location="Tab/Name" notices={notices} />
                        <FormComponent tab={tab} formData={name} onChange={setName} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={nameFormFields} />
                    </>
                )
            case 'Header':
                return (
                    <>
                        <NoticeComponent location="Tab/Header" notices={notices} />

                        {_imagesHeader.get() && <AssetUploader list={_imagesHeader.get()} key={tab} events={{ handleChange: (value) => _imagesHeader.set(value) }}
                            errors={errors} setErrors={setErrors} />}

                        <FormComponent tab={tab} formData={header} onChange={setHeader} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={headerFormFields} />
                    </>
                )
            case 'Page':
                return (
                    <>
                        <NoticeComponent location="Tab/Page" notices={notices} />

                        {_imagesPage.get() && <AssetUploader list={_imagesPage.get()} key={tab} events={{ handleChange: (value) => _imagesPage.set(value) }}
                            errors={errors} setErrors={setErrors} />}

                        <FormComponent tab={tab} formData={page} onChange={setPage} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={pageFormFields} />
                    </>
                )
            case 'Box':
                return (
                    <>
                        <NoticeComponent location="Tab/Box" notices={notices} />
                        <FormComponent tab={tab} formData={box} onChange={setBox} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={boxFormFields} />
                    </>
                )
            case 'Calendar':
                return (
                    <>
                        <NoticeComponent location="Tab/Calendar" notices={notices} />
                        <FormComponent tab={tab} formData={calendar} onChange={setCalendar} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={calendarFormFields} />
                    </>
                )
            case 'Addons':
                return (
                    <>
                        <NoticeComponent location="Tab/Addons" notices={notices} />
                        <FormComponent tab={tab} formData={addons} onChange={setAddons} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={addonsFormFields} />
                    </>
                )
            case 'Buttons':
                return (
                    <>
                        <NoticeComponent location="Tab/Buttons" notices={notices} />
                        <FormComponent tab={tab} formData={buttons} onChange={setButtons} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={buttonsFormFields} />
                    </>
                )
            case 'Footer':
                return (
                    <>
                        <NoticeComponent location="Tab/Footer" notices={notices} />
                        
                        {_imagesFooter.get() && <AssetUploader list={_imagesFooter.get()} key={tab} events={{ handleChange: (value) => _imagesFooter.set(value) }}
                            errors={errors} setErrors={setErrors} />}

                        <FormComponent tab={tab} formData={footer} onChange={setFooter} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={footerFormFields} />
                    </>
                )
            case 'Custom Files':
                return (
                    <>
                        <NoticeComponent location="Tab/CustomFiles" notices={notices} />
                        <FormComponent tab={tab} formData={customFiles} onChange={setCustomFiles} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={customFilesFormFields} />
                    </>
                )
            case 'Markets':
                return (
                    <>
                        <NoticeComponent location="Tab/Markets" notices={notices} />
                        <FormComponent tab={tab} formData={markets} formErrors={errors} setFormErrors={setErrors} onChange={setMarkets} fields={marketsFormFields} />
                    </>
                )
            default:
                break;
        }
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="Edit Color Schemes" />
            {data &&
                <>
                    <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
                    <div className="tab-content">
                        {onChangeTab(tab)}
                    </div>
                    <ControlBar baseApiUrl="/color-schemes" redirectUrl="/color-schemes" cancelUrl="/color-schemes" data={data} handleSubmit={handleSubmit} />
                </>
            }
        </div>
    )
}
export default ColorSchemesEdit
