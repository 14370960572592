import {useState} from 'react'

import { Tooltip, PageHeader, Search, Tags, Notice, List, Popover } from '../../components'

import { useNotices } from '../../hooks'

import './index.css'

const Addons = () => {
    const [NoticeComponent, notices] = useNotices('PSM/Addons/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const baseUrl = '/addons'
    const baseApiUrl = '/api/v2/admin/addons'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name_route = `${baseUrl}/${item.id}/edit`
            
            item.ui_emphasized = item.emphasized ? (
                <Tooltip icon="bi-star-fill" position="top">Emphasized</Tooltip>
            ) : (
                <i className="bi bi-star-fill disabled"></i>
            )

            return item
        })
    }

    return (
        <div className="addons-page-container page-container">
            <PageHeader title="Addons" actions={[{ title: 'New Addon', route: '/addons/new' }]} />
            <Search apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <Tags apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <Notice location="List" notices={notices} />
            <List list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'ui_emphasized',
                        title: '',
                        className: 'icon',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },{
                        key: 'code',
                        title: 'Code',
                    },{
                        key: 'lab_code',
                        title: 'Lab Code',
                    }
                ]}
                additionalDropdownOptions={[
                    {
                        title: 'Preview',
                        type: 'html',
                        html: (item) => {
                            return (
                                <Popover position="top-end" offsetPx={24}>
                                    <>
                                        <span>Preview</span> <i className="bi bi-display"></i>
                                    </>
                                    <div className="popover-default">
                                        <div className="popover-default-title">{item.name}</div>
                                        <hr />
                                        <div className="popover-default-image" style={{backgroundImage: `url(${item.image.id ? item.image.sources[item.image.source]['url'] : 'https://via.placeholder.com/300x300?text=MISSING'})`}}></div>
                                    </div>
                                </Popover>
                            )
                        }
                    }
                ]}
                />
        </div>
    )
}

export default Addons
