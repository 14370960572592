import { useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { OverlayComponent } from '../Overlay'

import './index.css'

const DropdownComponent = ({ id, children, config={} }) => {
    const {_state} = UI.useSimpleState({
        open: false
    })

    const _ref = useRef(null)

    const handleLabelClick = () => {
        _state.set('open', !_state.get('open'))
    }

    const handleClose = () => {
        _state.set('open', false)
    }

    const renderOverlay = () => {
        if (!_state.get('open'))
            return

        return (
            <OverlayComponent
                id={id}
                target={_ref.current}
                events={{ handleClose: handleClose }}
                config={{ 
                    noStyle: config.noStyle,
                    offsetX: config.offsetX,
                    offsetY: config.offsetY,
                    position: config.position,
                }}>
                {children[1]}
            </OverlayComponent>
        )
    }

    return (
        <UI.Layout name="dropdown" type="container" config={{ hasClickBoundary: true, classNames: config.classNames }}>
            <div className="ui--dropdownLabel" onClick={handleLabelClick} ref={_ref}>
                {children[0]}
            </div>
            {renderOverlay()}
        </UI.Layout>
    )
}

DropdownComponent.propTypes = {
    id: PropTypes.string,
    
    children: PropTypes.any,

    config: PropTypes.shape({
        position: PropTypes.string,
        classNames: PropTypes.string,
        noStyle: PropTypes.bool,
        offsetX: PropTypes.number,
        offsetY: PropTypes.number,
    })
}

export { DropdownComponent }