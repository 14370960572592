import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const PaginationComponent = ({ page, totalPages, config={}, events={} }) => {
    const {_state} = UI.useSimpleState({
        page: page
    })

    useEffect(() => {
        _state.set('page', page)
    }, [page])

    const currentPage = _state.get('page')

    const handleJumpToPage = () => {
        // setJumpToPage(currentPage)
    }

    const handleJumpToPageChange = (e) => {
        // setJumpToPage(Number(e.target.value))
    }

    const handleJumpToPageSubmit = (e) => {
        e.preventDefault()

        if (events.handleChange)
            events.handleChange()
    }

    const handlePageChange = (page) => {
        const newPage = Number(page)

        _state.set('page', newPage)
        
        if (events.handleChange)
            events.handleChange(newPage)
    }

    const handlePageChangePrevious = () => {
        const newPage = _state.get('page') - 1

        _state.set('page', newPage)

        if (events.handleChange)
            events.handleChange(newPage)
    }

    const getPageNumbers = () => {
        const pageNumbers = []

        let start = Math.max(1, currentPage - config.radius)
        let end = Math.min(totalPages, currentPage + config.radius)

        if (start > 1) {
            pageNumbers.push(1)

            if (start > 2)
                pageNumbers.push('...')
        }
        
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i)
        }
        
        if (end < totalPages) {
            if (end < totalPages - 1)
                pageNumbers.push('...')

            pageNumbers.push(totalPages)
        }
        
        return pageNumbers
    }

    const pageNumbers = getPageNumbers()

    return (
        <UI.Layout name="pagination" type="container" config={{ hasClickBoundary: true, classNames: config.classNames }}>
            <ul className="ui--paginationList" key={`ui--paginationList--${_state.get('page')}`}>
                <li className="ui--paginationListItem">
                    <button disabled={currentPage === 1} onClick={handlePageChangePrevious}>
                        <span className="ui-icon material-symbols-outlined">keyboard_arrow_left</span>
                    </button>
                </li>
                
                {pageNumbers.map((pageNumber, index) => (
                    <li key={`ui-key--paginationListItem--${index}`} className={`ui--paginationListItem ${pageNumber === currentPage ? 'ui-action--active' : ''}`}>
                        <button disabled={typeof pageNumber === 'string'} onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>
                    </li>
                ))}
                
                <li className="ui--paginationListItem">
                    <button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                        <span className="ui-icon material-symbols-outlined">keyboard_arrow_right</span>
                    </button>
                </li>

                {/* <li className="pagination-component-list-item">
                    <button onClick={handleJumpToPage}>Jump to</button>
                </li> */}
            </ul>
            {/* {jumpToPage && (
                <div className="overlay">
                    <form onSubmit={handleJumpToPageSubmit}>
                        <label>
                            Jump to page:
                            <input type="number" min="1" max={totalPages} value={jumpToPage} onChange={handleJumpToPageChange} />
                        </label>

                        <button type="submit">Go</button>
                    </form>
                </div>
            )} */}
        </UI.Layout>
    )
}

PaginationComponent.propTypes = {
    page: PropTypes.number.isRequired,
    
    totalPages: PropTypes.number.isRequired,

    events: PropTypes.shape({
        handleChange: PropTypes.func
    }),

    config: PropTypes.shape({
        radius: PropTypes.number,
        classNames: PropTypes.string
    })
}

PaginationComponent.defaultProps = {
    config: {
        radius: 2
    }
}

export { PaginationComponent }
