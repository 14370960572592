import { useEffect, useState } from 'react'

import { useAppContext } from '../../hooks'

import './index.css'

const DisplayModalPrimary = () => {
    const [ac] = useAppContext()

    const handleClose = (event) => {
        event.stopPropagation()
        
        ac.displayModal.onClose()
        ac.setDisplayModal()
    }

    if (ac.displayModal) {
        return (
            <div className="display-modal-primary-container">
                <div className="display-window">
                    <div className="display-close" onClick={handleClose}><i className="bi bi-x"></i></div>

                    <div className="display-content">
                        {ac.displayModal.content()}
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default DisplayModalPrimary