import AnimationLoading from '../assets/animation-loading.gif'
import './Spinner.css'

const Spinner = ({ isLoading = false }) => {
    if (!isLoading) {
        return null
    }

    return (
        <div className="spinner-container" style={{ backgroundImage: `url(${AnimationLoading})`}}></div>
    )
}

export default Spinner