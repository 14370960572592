import PropTypes from 'prop-types'

import './index.css'

const SectionTitle = ({ children, type='base', className='' }) => {
    return (
        <div className={`ui-l-sectionTitle ui-l-sectionTitle-${type} ${className}`}>
            {children}
        </div>
    )
}

SectionTitle.propTypes = {
    type: PropTypes.oneOf(['base']),

    className: PropTypes.string
}

export { SectionTitle }