import { useState, useEffect } from 'react'

import DropdownComponent from '../../components/Dropdown'
import FetchHelper from '../../helpers/fetch'
import ListComponent from '../../components/List'
import PageHeaderComponent from '../../components/PageHeader'
import RouteHelper from '../../helpers/route'
import SearchComponent from '../../components/Search'
import TabsComponent from '../../components/Tabs'
import TagsComponent from '../../components/Tags'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import './index.css'

const Archives = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Archives/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [archives, setArchives] = useState([])
    const [archivesTitles, setArchivesTitles] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [tab, setTab] = useState('Markets')
    const [disclaimer, setDisclaimer] = useState()
    const [baseUrl, setBaseUrl] = useState('/markets')
    const [baseApiUrl, setBaseApiUrl] = useState('/api/v2/admin/markets')

    useEffect(() => {
        ac.showSpinner(true)
        setIsFetching(true)
        
        FetchHelper({
            url: `/api/v2/admin/archives`
        }, (res) => {
            ac.showSpinner(false)
            setIsFetching(false)
            setArchives(res.body)
            setArchivesTitles(res.body.map((item) => item.title))
        }, (res) => {
            ac.showSpinner(false)
            setIsFetching(false)
        })
    }, [])

    const handleSearchAfterFetch = (results) => {
        const list = {
            ...results,
            data: results.data.map((row) => {
                return row
            })
        }

        setList(list)
        setCachedList(list)
    }

    const unarchive = (item) => {
        ac.showSpinner(true)
        setIsFetching(true)

        FetchHelper({
            url: `${baseApiUrl}/${item.id}`,
            method: 'delete'
        }, (res) => {
            ac.showSpinner(false)
            setIsFetching(false)

            const newList = {...list}
            
            newList.data = list.data.filter((itm) => itm.id !== item.id)

            setList(newList)
            setCachedList(newList)
        }, (res) => {
            ac.showSpinner(false)
            setIsFetching(false)
        })
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.deleted_at = UtilsHelper.toDateString({ date: item.deleted_at, timezone: item.timezone, displayWeekday: true, displayTime: true })

            return item
        })
    }

    

    let activeArchive = archives.filter(item => item.title === tab)
    let columns = activeArchive.length && activeArchive[0].columns || null

    return archives.length && (<div className="archives-page-container page-container" key={`${baseApiUrl}`}>
        <PageHeaderComponent title="Archives" />

        <TabsComponent tabs={archivesTitles} tab={tab} setTab={(tab) => {
            let disclaimer = null

            setTab(tab)

            archives.forEach((item) => {
                if (item.title === tab) {   
                    disclaimer = item.disclaimer
                    setBaseApiUrl(item.url)
                }
            })

            setDisclaimer(disclaimer)
        }} />

        <div className={isFetching ? 'disabled' : ''}>
            <SearchComponent apiUrl={baseApiUrl} queryString={'&archived=1'} setIsFetching={setIsFetching} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />

            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />

            {disclaimer && (
                <div className="disclaimer">
                    {disclaimer}
                </div>
            ) || null}

            <NoticeComponent location="List" notices={notices} />

            <ListComponent key={list} list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl} columns={columns}
                customActions={(item) => {
                    return <DropdownComponent title="Actions" isListView={true} options={[{
                        title: 'Restore',
                        type: 'callback',
                        callback: () => unarchive(item)
                    }]} />
                }} />
            </div>
    </div>) || null
}

export default Archives
