import React from 'react'

import PropTypes from 'prop-types'

import { ButtonComponent, ParagraphComponent, HeadingComponent, InputComponent } from '../../Primitive'
import { MenuComponent, ModalComponent, PaginationComponent } from '../../Complex'
import { PlaceholderGenericSpinnerComponent } from '..'
import { SelectPaginated } from '../../../components'
import { Fetch } from '../../../helpers'
import { UI } from '../../../UI'

import './index.css'

const SelectWithSearchModalComponent = ({ collection=[], events={}, config={}, configSearch={} }) => {
    const {_state} = UI.useSimpleState({
        currentView: 'main',
        clearCount: 0
    })

    const {_state: _transitions} = UI.useSimpleState({
        currentView: null,
        nextView: null
    })

    const {_state: _dependencies} = UI.useSimpleState({
        packages: null
    })

    let _termTimerRef = React.useRef(null)
    let _pageTimerRef = React.useRef(null)

    const handleSelect = (selectedOption) => {
        if (events.handleSelect)
            events.handleSelect(selectedOption)
    }

    const handleSearchTermChange = (term) => {
        window.clearTimeout(_termTimerRef.current)

        _termTimerRef.current = window.setTimeout(() => {
            if (events.handleSearchTermChange)
                events.handleSearchTermChange(term)
        }, 450)
    }

    const handleSearchPageChange = (page) => {
        window.clearTimeout(_pageTimerRef.current)

        _pageTimerRef.current = window.setTimeout(() => {
            if (events.handleSearchPageChange)
                events.handleSearchPageChange(page)
        }, 450)

        if (events.handleSearchPageChange)
            events.handleSearchPageChange(page)
    }

    const handleClose = () => {
        if (events.handleClose)
            events.handleClose()
    }

    const renderView = () => {
        switch (_state.get('currentView')) {
            case 'main':
                return renderViewMain()
            case 'progress':
                return renderViewProgress()
            case 'complete':
                return renderViewComplete()
        }
    }
    
    const handleTransition = () => {
        if (_transitions.get('nextView') !== null) {
            _state.set('currentView', _transitions.get('nextView'))

            _transitions.set({
                currentView: null,
                nextView: null
            })
        }
    }

    const renderViewComplete = () => {
        return (
            <UI.Layout name="selectWithSearchComplete" type="container">
                <HeadingComponent type="h3">{config.titleComplete || 'Complete'}</HeadingComponent>
                <div className="ui--selectWithSearchCompleteIcon">
                    <i className="ui-icon bi bi-check-circle"></i>
                </div>
                <div className="ui--selectWithSearchCompleteUIControls">
                    <ButtonComponent config={{ palette: "blue" }} events={{ handleClick: handleClose }}>Done</ButtonComponent>
                </div>
            </UI.Layout>
        )
    }

    const renderViewProgress = () => {
        setTimeout(() => {
            _transitions.set({
                currentView: 'progress',
                nextView: 'complete'
            })
        }, 3000)

        return (
            <UI.Layout
                name="selectWithSearchProgress"
                type="container"
                config={{ classNames: _transitions.get('currentView') === 'progress' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <ParagraphComponent>Processing update...</ParagraphComponent>

                <div className="ui--selectWithSearchProgressSpinner">
                    <PlaceholderGenericSpinnerComponent />
                </div>
            </UI.Layout>
        )
    }

    const renderViewMain = () => {
        return (
            <UI.Layout
                name="selectWithSearchForm"
                type="container"
                config={{ classNames: _transitions.get('currentView') === 'main' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <HeadingComponent type="h3">{config.titleMain || 'Select Option'}</HeadingComponent>

                <div className="ui--selectWithSearchFormSearch">
                    <InputComponent name="selectWithSearchInput" type="text" config={{ cell: <i className="bi bi-search"></i> }} value={configSearch.term || ''} events={{ handleChange: handleSearchTermChange }} />
                </div>

                <div className="ui--selectWithSearchFormDetails">
                    {!collection.length && `No results for "${configSearch.term}"...`}

                    {collection.length ? (
                        <MenuComponent items={collection.map((item) => {
                            return {
                                title: item.name,
                                callback: () => handleSelect(item)
                            }
                        })} />
                    ) : null}
                </div>

                <div className={`ui--controls grid grid-cols-1 gap-2 ${config.isSyncing ? 'ui-global--disabled' : ''}`}>
                    <PaginationComponent identifier={`ui-key--selectWithSearchModal--${configSearch.page}`} page={configSearch.page} totalPages={configSearch.totalPages} events={{ handleChange: handleSearchPageChange }} />
                </div>
            </UI.Layout>
        )
    }

    return (
        <ModalComponent config={{ classNames: 'ui--selectWithSearchModal', classNamesInner: _state.get('currentView') === 'complete' ? 'ui-action--centered' : '', isSyncing: config.isSyncing }} events={{ handleClose: handleClose }}>
            {renderView()}
        </ModalComponent>
    )
}

SelectWithSearchModalComponent.propTypes = {
    events: PropTypes.shape({
        handleSelct: PropTypes.func
    }),

    config: PropTypes.shape({}),

    configSearch: PropTypes.shape({
        term: PropTypes.string,
        page: PropTypes.number,
        totalPages: PropTypes.number
    })
}

export { SelectWithSearchModalComponent }