import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const Form = ({ name, children, config={}, events={} }) => {
    const handleSubmit = (e) => {
        e.preventDefault()

        if (events.onSubmit)
            events.onSubmit()
    }

    const decorateEvents = () => {
        let eventsConfig = {...events}

        return eventsConfig
    }

    return (
        <UI.Layout name="form">
            <form name={name} className={`ui-container--form ${config.classNames || ''}`} onSubmit={handleSubmit} {...decorateEvents()}>
                {children}
            </form>
        </UI.Layout>
    )
}

Form.propTypes = {
    name: PropTypes.string.isRequired,

    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    
    config: PropTypes.shape({
        classNames: PropTypes.string
    }),
    
    events: PropTypes.object
}

export { Form }