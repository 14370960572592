export function getBasicFormFields(
  suggestionsTags = [],
  optionsCategories = []
) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name',
      placeholder: 'Addon A with Splash'
    },
    {
      type: 'tags',
      name: 'tags',
      label: 'Tags',
      suggestions: suggestionsTags
    },
    {
      type: 'input-text',
      name: 'display_name',
      label: 'Display Name',
      placeholder: 'Addon A'
    },
    {
      type: 'input-text',
      name: 'code',
      label: 'Code',
      placeholder: 'AWS'
    },
    {
      type: 'input-text',
      name: 'lab_code',
      label: 'Lab Code',
      placeholder: 'Lab Code',
      field_info: 'Lab Code takes priority over Code when sending data to lab for fulfillment.'
    },
    {
      type: 'select',
      name: 'category_id',
      label: 'Default Category',
      options: optionsCategories
    },
    {
      type: 'input-number',
      name: 'value',
      label: 'Default Price',
      placeholder: '10.00'
    },
    {
      type: 'input-number',
      name: 'shipping_premium',
      label: 'Shipping Premium',
      value: '0.0'
    },
    {
      type: 'switch',
      name: 'shipping_exempt',
      label: 'Shipping Exempt'
    },
    {
      type: 'switch',
      name: 'late_exempt',
      label: 'Late Exempt?'
    },
    {
      type: 'switch',
      name: 'tax_exempt',
      label: 'Tax Exempt'
    },
    {
      type: 'switch',
      name: 'intangible',
      label: 'Intangible?'
    }
  ];
}

export function getEmphasisFormFields() {
  return [
    {
      type: 'select',
      name: 'emphasized',
      label: 'Emphasized?',
      value: 'true',
      options: [
        {
          title: 'Yes',
          value: 'true'
        },
        {
          title: 'No',
          value: 'false'
        }
      ]
    },
    {
      type: 'select',
      name: 'use_image_for_description',
      label: 'Use image for description?',
      value: 'false',
      options: [
        {
          title: 'Yes',
          value: 'true'
        },
        {
          title: 'No',
          value: 'false'
        }
      ],
      condition: (state) => {
        return state.emphasized === 'true';
      }
    },
    {
      type: 'wysiwyg',
      name: 'emphasized_description',
      label: 'Emphasized description',
      condition: (state) => {
        return (
          state.use_image_for_description === 'false' &&
          state.emphasized === 'true'
        );
      }
    }
  ];
}

export function getDigitalDeliveryFormFields() {
  return [
    {
      type: 'switch',
      name: 'digitally_deliverable',
      label: 'Digitally Deliverable?'
    }
  ];
}
