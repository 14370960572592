import { useState, useEffect, useCallback } from 'react'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'
import RouteHelper from '../../helpers/route'
import SelectPaginated from '../../components/SelectPaginated'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'

import './edit.css'
import FieldSelectorComponent from '../../components/FieldSelector'
import { useTabs } from '../../hooks'
import { getGeneralFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const AppointmentProfilesEdit = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Scheduling/AppointmentProfiles/New')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('Scheduling:AppointmentProfiles:DataMain')
    const [dataAppointmentSlots, setDataAppointmentSlots, clearDataAppointmentSlots, dataAppointmentSlotsDefault] = useDraft('Scheduling:AppointmentProfiles:DataAppointmentSlots', [])
    const [dataReverseAdjustmentDirection, setDataReverseAdjustmentDirection, clearDataReverseAdjustmentDirection, dataReverseAdjustmentDirectionDefault] = useDraft('Scheduling:AppointmentProfiles:DataAdjustmentDirection', false)
    const [dataTimeAdjustment, setDataTimeAdjustment, clearDataTimeAdjustment, dataTimeAdjustmentDefault] = useDraft('Scheduling:AppointmentProfiles:DataTimeAdjustment', 0)

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('General', ['General', 'Appointment Slots'])

    const setDataTimeAdjustmentCallback = useCallback((value) => {
        setDataTimeAdjustment(value)
    }, [])

    const setDataReverseAdjustmentDirectionCallback = useCallback((value) => {
        setDataReverseAdjustmentDirection(value)
    }, [])

    const [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFields(dataMain, dataTimeAdjustment, setDataTimeAdjustmentCallback, dataReverseAdjustmentDirection, setDataReverseAdjustmentDirectionCallback))
    
    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getGeneralFormFields(dataMain, dataTimeAdjustment, setDataTimeAdjustmentCallback, dataReverseAdjustmentDirection, setDataReverseAdjustmentDirectionCallback);
        setGeneralFormFields(fields)
    }, [dataMain, dataTimeAdjustment, setDataTimeAdjustmentCallback, dataReverseAdjustmentDirection, setDataReverseAdjustmentDirectionCallback])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'General',
                forms: [{ id: 'Main', fields: generalFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = { appointment_profile: { ...dataMain } }
            let dynamicItems = [
                { data: dataAppointmentSlots, attribute: 'appointment_profile_appointment_slots_attributes', prop: 'appointment_slot_id' }
            ]
            dynamicItems.forEach(config => {
                if (!config.data.length)
                    return

                body['appointment_profile'][config.attribute] = config.data.filter((item) => {
                    return item.item.id
                }).map((item) => {
                    return { [config.prop]: item.item.id }
                })
            })
            

            if (dataMain.hide_slot_for_current_time) {
                body.appointment_profile.time_adjustment = dataTimeAdjustment
                body.appointment_profile.reverse_adjustment_direction = dataReverseAdjustmentDirection
            }

            FetchHelper({
                url: `/api/v2/admin/appointment-profiles`,
                method: 'POST',
                body: body
            }, (res) => {
                ac.showSpinner(false)

                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    let errors = UtilsHelper.preProcess422Errors(generalFormFields, res.body)
                    setErrors(errors)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className="appointment-profiles-edit-page-container page-container">
            <PageHeaderComponent title="New Appointment Profile" />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            {tab === 'General' && (
                <>
                    <NoticeComponent location="Tab/General" notices={notices} />

                    <FormComponent formData={dataMain} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={generalFormFields} />
                </>
            )}

            {tab === 'Appointment Slots' && (
                <>
                    <NoticeComponent location="Tab/AppointmentSlots" notices={notices} />

                    <div className='appointment-slots-container'>
                        <FieldSelectorComponent sortable={false} apiPrefix="appointment_slot" buttonTitle="Slot" 
                        data={dataAppointmentSlots} apiURL="/api/v2/admin/appointment-slots" option={{ showEditBtn: false }} onChange={(data) => setDataAppointmentSlots(data)} />
                    </div>
                </>
            )}

            <ControlBar handleSubmit={handleSubmit} cancelUrl="/appointment-profiles" handleAfterSubmit={() => RouteHelper.redirect('/appointment-profiles')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [dataMain, clearDataMain, dataMainDefault],
                [dataAppointmentSlots, clearDataAppointmentSlots, dataAppointmentSlotsDefault],
                [dataReverseAdjustmentDirection, clearDataReverseAdjustmentDirection, dataReverseAdjustmentDirectionDefault],
                [dataTimeAdjustment, clearDataTimeAdjustment, dataTimeAdjustmentDefault],
            ]} />
        </div>
    )
}

export default AppointmentProfilesEdit
