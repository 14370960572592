
import { useRef } from 'react'

import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const TextareaComponent = ({ name, value, events={}, eventsDOM={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        value: value
    })

    const _textareaRef = useRef(null)

    const handleChange = (e) => {
        const value = e.target.value

        _state.set('value', value)

        if (events.handleChange)
            events.handleChange(value)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Escape') {
            _textareaRef.current.blur()
        }
    }

    const decorateEventsDOM = () => {
        let eventsConfig = {...eventsDOM}

        delete eventsConfig.onChange

        if (config.enableKeyUpBlur) {
            eventsConfig.onKeyUp = handleKeyUp
        }

        return eventsConfig
    }

    return (
        <div className={`ui-container--textarea ${config.classNames || ''}`}>
            <textarea
                name={name}
                value={_state.get('value') || ''}
                ref={_textareaRef}
                readOnly={config.readOnly}
                disabled={config.disabled}
                autoFocus={config.autoFocus}
                maxLength={config.maxLength}
                placeholder={config.placeholder}
                onChange={handleChange}
                className="ui-global--scrollbar"
                {...decorateEventsDOM()}></textarea>
        </div>
    )
}

TextareaComponent.propTypes = {
    name: PropTypes.string.isRequired,
    
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    
    events: PropTypes.shape({
        onChange: PropTypes.func
    }),
    
    config: PropTypes.shape({
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        classNames: PropTypes.string,
        autoFocus: PropTypes.bool,
        maxlength: PropTypes.number,
        placeholder: PropTypes.string,
        enableKeyUpBlur: PropTypes.bool
    })
}

export { TextareaComponent }