import { useState } from 'react';
import { ControlBar, Form, PageHeader } from '../../../components';
import { Utils } from '../../../helpers';
import RouteHelper from '../../../helpers/route';
import { useAppContext, useDraft, useFetch, useNotices } from '../../../hooks';
import { getGeneralFormFields } from '../providers/form-fields';
import './index.css';

const EnhancementsNew = () => {
  const [ac] = useAppContext();
  const [NoticeComponent, notices] = useNotices('PSM/Enhancements/New');
  const { FetchHelper } = useFetch();

  const [data, setData, clearData, dataDefault] = useDraft(
    'PSM:Enhancements:Data'
  );

  const [generalFormFields, setGeneralFormFields] = useState(
    getGeneralFormFields()
  );

  const [errors, setErrors] = useState();

  const handleSubmit = async () => {
    let body = { ...data };
    if (body.image_base64) body.image_base64 = body.image_base64.value;

    ac.showSpinner(true);
    let [res, err] = await Utils.executePromise(
      FetchHelper({
        url: `/api/v2/admin/enhancements`,
        method: 'POST',
        body: { enhancement: body }
      })
    );
    ac.showSpinner(false);

    // --- error handling
    if (err) throw err;
    if (res?.status === 422) {
      setErrors(res.body);
      Utils.scrollTop();
      throw res;
    }
  };

  return (
    <div>
      <PageHeader title={`New Enhancement`} />
      <NoticeComponent location="Main" notices={notices} />

      <Form
        formData={data}
        formErrors={errors}
        onChange={setData}
        displayErrorBanner={true}
        fields={generalFormFields}
      />

      <ControlBar
        handleSubmit={handleSubmit}
        cancelUrl="/enhancements"
        handleAfterSubmit={() => RouteHelper.redirect('/enhancements')}
        handleAfterClear={() => {
          RouteHelper.reload();
        }}
        dataItems={[[data, clearData, dataDefault]]}
      />
    </div>
  );
};

export default EnhancementsNew;
