import {useEffect, useState, useRef} from 'react'

import useAppContext from './useAppContext'

const useWebSockets = () => {
    const [ac] = useAppContext()

    let webSocket = useRef(null)
    let webSocketTimer = useRef(null)

    const connectWebsocket = () => {
        if (!webSocket.current)
            webSocket.current = new WebSocket("wss://localhost:3000/cable")
    }

    useEffect(() => {
        connectWebsocket()
    })

    useEffect(() => {
        if (!webSocket.current || !ac.setStatus)
            return
        
        webSocket.current.onopen = () => {
            ac.setStatus({
                type: 'success',
                message: 'Web Socket connection open...'
            })
        }

        webSocket.current.onclose = () => {
            if (!ac.setStatus)
                return

            ac.setStatus({
                type: 'danger',
                message: 'Web Socket connection closed. Reconnecting in 5 seconds...'
            })

            webSocketTimer.current = setTimeout(() => connectWebsocket(), 5000);
        }

    
        webSocket.current.onmessage = e => {
            ac.setStatus({
              type: 'success',
              message: JSON.parse(e.data)
            })
        }

        return () => {
            clearTimeout(webSocketTimer.current)
        }
    }, [webSocket.current, ac.setStatus])

    return { webSocket: webSocket.current }
}

export default useWebSockets