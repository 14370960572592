import {useState, useEffect} from 'react'

import DropdownComponent from '../Dropdown'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../Form'
import ModalComponent from '../../components/Modal'
import PopoverComponent from '../Popover'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'

import './index.css'
import { FileTypes } from '../../sys/constants/enums'

const Notice = ({ notices, location }) => {
    const [ac] = useAppContext()

    const [errors, setErrors] = useState()
    
    const [data, setData] = useState()
    const [dataModal, setDataModal] = useState()
    const [dataAddModal, setDataAddModal] = useState()
    const [dataEditModal, setDataEditModal] = useState()
    
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('add')
    const [modalEditUrl, setModalEditUrl] = useState()
    const [contentModal, setContentModal] = useState()

    const sortItem = (item, index, direction) => {
        ac.showSpinner(true)

        let body = {
            notice_id: item.id,
            position: direction === 'up' ? index - 1 : index + 1
        }

        FetchHelper({
            url: item.url,
            method: 'put',
            body: {
                notice: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.reload()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleDelete = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: dataModal.url,
            method: 'delete',
        }, (res) => {
            ac.showSpinner(false)
            setShowModal(false)
            RouteHelper.reload()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleShowContent = (item) => {
        setContentModal(item)
    }

    const handleSubmitEditModal = () => {
        ac.showSpinner(true)

        let body = {...dataEditModal, location}

        if (body.image) {
            body.image_base64 = dataEditModal.image.value
        }

        delete body.image

        FetchHelper({
            url: dataModal.url,
            method: 'put',
            body: {
                notice: body
            }
        }, (res) => {
            ac.showSpinner(false)
            setDataModal()
            setDataEditModal()
            setShowModal(false)
            RouteHelper.reload()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleSubmitAddModal = () => {
        ac.showSpinner(true)

        let body = { ...dataAddModal, location, route: notices.route }

        if (body.image) {
            body.image_base64 = dataAddModal.image.value
        }

        delete body.image

        FetchHelper({
            url: '/api/v2/admin/notices',
            method: 'post',
            body: {
                notice: body
            }
        }, (res) => {
            ac.showSpinner(false)
            setDataAddModal()
            setShowModal(false)
            RouteHelper.reload()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleAddModal = () => {
        setModalType('add')
        setShowModal(true)
    }

    const handleEditModal = (item) => {
        setModalType('edit')
        setDataModal(item)
        setDataEditModal(item)
        setShowModal(true)
    }
    
    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleCloseContentModal = () => {
        setContentModal()
    }

    useEffect(() => {
        if (contentModal) {
            ac.setDisplayModal({
                content: () => {
                    return (
                        <div className="notice-modal global-modal">
                            {contentModal.image_url ? (
                                <div className="notice-modal-image">
                                    <img src={contentModal.image_url} width="100%" height="auto" />
                                </div>
                            ) : null}
                            {contentModal.short_content ? (
                                <div className="global-modal-html" dangerouslySetInnerHTML={{__html: contentModal.short_content }} />
                            ) : null}
                            {contentModal.long_content ? (
                                <>
                                    <hr />
                                    <div className="global-modal-html" dangerouslySetInnerHTML={{__html: contentModal.long_content }} />
                                </>
                            ) : null}
                        </div>
                    )
                },
                onClose: () => {
                    setContentModal()
                }
            })
        }
    }, [contentModal])

    let filteredNotices

    if (notices) {
        filteredNotices = notices.data.filter((item, index) => {
            return item.location === location
        })
    }

    return (
        <div className="notice-component-container">
            {ac.AuthHelper.isAdmin() ? (
                <div className="controls">
                    <button onClick={handleAddModal} className="admin">
                        <i className="bi bi-three-dots-vertical"></i>
                    </button>
                </div>
            ) : null}
            {filteredNotices && filteredNotices.length ? (
                <div className="notice-message-container">
                    {filteredNotices.map((item, index) => {
                        return (
                            <div className="notice-message-notice" key={`notice-message-notice-${item.location}-${index}`}>
                                <div className={`notice-message ${item.level} interactive`} onClick={() => handleShowContent(item) }>
                                    <i className={`icon bi ${item.level === 'info' ? 'bi-info-circle' : 'bi-exclamation-triangle'}`}></i><div dangerouslySetInnerHTML={{__html: item.short_content}} />
                                </div>
                                
                                {ac.AuthHelper.isAdmin() ? (
                                    <>
                                        {filteredNotices.length > 1 ? (
                                            <span className="notice-message-sort">
                                                <i onClick={() => sortItem(item, index, 'up')} className={`icon bi bi-chevron-up ${index === 0 ? 'disabled' : ''}`}></i>
                                                <i onClick={() => sortItem(item, index, 'down')} className={`icon bi bi-chevron-down ${index === filteredNotices.length - 1 ? 'disabled' : ''}`}></i>
                                            </span>
                                        ) : null}
                                        
                                        <button onClick={() => handleEditModal(item)} className="admin"><i className="bi bi-three-dots-vertical"></i></button>
                                    </>
                                ) : null}
                            </div>
                        )
                    })}
                </div>
            ) : null}

            {/* {contentModal ? (
                <ModalComponent handleClose={handleCloseContentModal}>
                    <div className="notice-modal global-modal">
                        {contentModal.image_url ? (
                            <div className="notice-modal-image">
                                <img src={contentModal.image_url} width="100%" height="auto" />
                            </div>
                        ) : null}
                        {contentModal.short_content ? (
                            <div className="global-modal-html" dangerouslySetInnerHTML={{__html: contentModal.short_content }} />
                        ) : null}
                        {contentModal.long_content ? (
                            <>
                                <hr />
                                <div className="global-modal-html" dangerouslySetInnerHTML={{__html: contentModal.long_content }} />
                            </>
                        ) : null}
                    </div>
                </ModalComponent>
            ) : null} */}

            {showModal ? (
                <ModalComponent handleClose={handleCloseModal}>
                    {modalType === 'add' ? (
                        <>
                            <FormComponent type="stacked" formData={dataAddModal} formErrors={errors} onChange={setDataAddModal} displayErrorBanner={true} fields={[
                                {
                                    label: 'Type',
                                    name: 'level',
                                    type: 'select',
                                    value: 'info',
                                    options: [
                                        {
                                            title: 'Info',
                                            value: 'info'
                                        },
                                        {
                                            title: 'Alert',
                                            value: 'alert'
                                        }
                                    ]
                                },{
                                    label: 'Short Content',
                                    type: 'editor',
                                    name: 'short_content',
                                },{
                                    label: 'Long Content',
                                    type: 'editor',
                                    name: 'long_content',
                                },{
                                    label: 'Image',
                                    type: 'input-file',
                                    name: 'image',
                                    acceptedFileTypes: FileTypes.IMAGES
                                } ]} />

                                <div className="controls">
                                    <button className="" onClick={handleSubmitAddModal}>Create</button> <button className="secondary" onClick={handleCloseModal}>Cancel</button>
                                </div>
                        </>
                    ) : (
                        <>
                            <FormComponent type="stacked" formData={dataEditModal} formErrors={errors} onChange={setDataEditModal} displayErrorBanner={true} fields={[
                            {
                                label: 'Type',
                                name: 'level',
                                type: 'select',
                                value: 'info',
                                options: [
                                    {
                                        title: 'Info',
                                        value: 'info'
                                    },
                                    {
                                        title: 'Alert',
                                        value: 'alert'
                                    }
                                ]
                            },{
                                label: 'Short Content',
                                type: 'editor',
                                name: 'short_content',
                            },{
                                label: 'Long Content',
                                type: 'editor',
                                name: 'long_content',
                            },{
                                label: 'Image',
                                type: 'input-file',
                                name: 'image',
                                imageUrl: dataModal.image_url,
                                acceptedFileTypes: FileTypes.IMAGES
                            }, ]} />

                            <div className="controls">
                                <button className="" onClick={handleSubmitEditModal}>Edit</button> <button className="secondary" onClick={handleCloseModal}>Cancel</button> <button className="delete" onClick={handleDelete}>Delete this Notice</button>
                            </div>
                        </>
                    )}
                </ModalComponent>
            ) : null}
        </div>
    )
}

export default Notice