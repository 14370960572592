import {useState} from 'react'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import Tooltip from '../../components/Tooltip'
import UtilsHelper from '../../helpers/utils'

import useNotices from '../../hooks/useNotices'

import './index.css'

const Fields = () => {
    const [NoticeComponent, notices] = useNotices('PSM/Fields/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const baseUrl = '/fields'
    const baseApiUrl = '/api/v2/admin/fields'

    const handleSearchAfterFetch = (results) => {
        const list = {
            ...results,
            data: results.data.map((row) => {
                return row
            })
        }

        setList(list)
        setCachedList(list)
    }

    const getIconByType = (item) => {
        let icon = ''

        switch (item.field_type) {
            case 'single_line':
                icon = 'bi-input-cursor-text'
                break;
            case 'dropdown':
                icon = 'bi-menu-button'
                break;
            case 'checkbox':
                icon = 'bi-check2-square'
                break;
            case 'date':
                icon = 'bi-calendar-day'
                break;
            case 'number':
                icon = 'bi-123'
                break;
            case 'email':
                icon = 'bi-envelope'
                break;
            case 'text':
                icon = 'bi-fonts'
            case 'children':
                icon = 'bi-person'
        }

        return (
            <>
                <Tooltip icon={icon} position="top">{item.field_type}</Tooltip> {item.required ? <Tooltip icon="bi-exclamation-diamond-fill" position="top">This field is required</Tooltip> : ''}
            </>
        )
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name_route = `${baseUrl}/${item.id}/edit`
            item.ui_field_type = getIconByType(item)

            return item
        })
    }

    return (
        <div className="fields-page-container page-container">
            <PageHeaderComponent title="Fields" actions={[{ title: 'New Field', route: '/fields/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'ui_field_type',
                        title: 'Type',
                        className: 'icon',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    }
                ]} />
        </div>
    )
}

export default Fields
