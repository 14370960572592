import {Link} from 'react-router-dom'
import {useState, useEffect, useRef} from 'react'

import useWindowDimensions from '../hooks/useWindowDimensions'

import IconChevronDown from '../assets/icon-chevron-down-light.svg'
import './Dropdown.css'

const DropdownComponent = ({ title, options, item, isResponsive=true, isListView=false, dropdownTitle}) => {
    const {width, height} = useWindowDimensions()
    const [showOptions, setShowOptions] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShowOptions(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [ref])

    const renderOptionType = (option) => {
        if (option.type === 'route') {
            return (
                <Link to={option.url} title={option.title}>
                    <span className="label">{option.title}</span> {renderOptionIcon(option, item)}
                </Link>
            )
        } else if (option.type === 'url') {
            return (
                <a href={option.url} title={option.title} target={option.target || "_blank"}>
                    <span className="label">{option.title}</span> {renderOptionIcon(option, item)}
                </a>
            )
        } else if (option.type === 'callback') {
            return (
                <a onClick={() => {
                    option.callback(item)
                    setShowOptions(false)
                }} title={option.title}>
                    <span className="label">{option.title}</span> {renderOptionIcon(option, item)}
                </a>
            )
        } else if (option.type === 'icon') {
            return (
                <div title={option.title}>
                    <span className="label">{option.title}</span> { renderOptionIcon(option, item) }
                </div>
            )
        } else if (option.type === 'html') {
            return option.html(item)
        } else {
            return null
        }
    }

    const renderOptionIcon = (option, targetItem) => {
        let className = 'bi '

        if (option.icon) {
            className += typeof option.icon === 'function' ? option.icon(targetItem) : option.icon
        } else if (/Add|Edit|Copy|Delete|Email|Restore|Scheduling|Proofing/.test(option.title)) {
            switch (option.title) {
                case 'Add':
                    className += 'bi-plus'
                    break;
                case 'Edit':
                    className += 'bi-pen'
                    break;
                case 'Copy':
                    className += 'bi-files'
                    break;
                case 'Delete':
                    className += 'bi-trash-fill'
                    break;
                case 'Email':
                    className += 'bi-envelope'
                    break;
                case 'Restore':
                    className += 'bi-arrow-clockwise'
                    break;
                case 'Scheduling':
                    className += 'bi-calendar-date'
                    break;
                case 'Proofing':
                    className += 'bi-camera-fill'
                    break;
            }
        } else {
            className += 'bi-braces-asterisk'
        }

        return <i className={className}></i>
    }

    return (
        <div className={`dropdown-container ${isResponsive && width >= 1024 ? 'icon-view' : 'dropdown-view'}`} ref={ref}>
            <div className="dropdown-button" onClick={() => setShowOptions(!showOptions)}>
                {dropdownTitle ? <div className="dropdown-title">{dropdownTitle}</div> : null} <div className="dropdown-icon"><i className="bi bi-chevron-down"></i></div>
            </div>
            <ul className="dropdown-options" style={{ display: showOptions || isListView && width >= 1024 ? 'block' : 'none' }} onMouseLeave={() => setShowOptions(false)}>
                {options.map((option, index) => {
                    return (
                        <li key={`dropdown-option-${index}`} className={`dropdown-option ${option.primary ? 'primary' : ''}`}>
                            {renderOptionType(option)}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default DropdownComponent
