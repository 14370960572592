import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const FormRow = ({ children, config={} }) => {
    return (
        <UI.Layout name="formRow" config={{ classNames: config.classNames, classNamesInner: config.classNamesInner }}>
            {children}
        </UI.Layout>
    )
}

FormRow.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,

    config: PropTypes.shape({
        classNames: PropTypes.string
    })
}

export { FormRow }