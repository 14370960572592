const DomainHelper = {
    getBaseUrl: () => {
        let baseUrl = ''
    
        switch (process.env.REACT_APP_ENVIRONMENT) {
            case 'staging':
                baseUrl = 'https://stage.dev.santasfastpass.com'
                break
            case 'production':
                baseUrl = 'https://www.prepaysystems.com'
                break
            default:
                baseUrl = 'https://dev.santasfastpass.com'
                baseUrl = 'https://stage.dev.santasfastpass.com'
                break
        }

        return baseUrl
    },

    getSubDomain: () => {
        let subDomain = ''

        switch (process.env.REACT_APP_ENVIRONMENT) {
            case 'staging':
                subDomain = 'stage.dev'
                break
            case 'production':
                subDomain = 'www'
                break
            default:
                subDomain = 'dev'
                break
        }

        return subDomain
    }
}

export { DomainHelper }
