import {useEffect, useState} from 'react'

import './index.css'

const AlertModal = ({ title, body, onClose=()=>{}}) => {
    return (
        <div className="alert-modal-container">
            <div className="alert-modal">
                <div className="alert-modal-title">{title}</div>
                <div className="alert-modal-body">{body}</div>
                <div className="alert-modal-options">
                    <button onClick={onClose}>Okay</button>
                </div>
            </div>
        </div>
    )
}

export default AlertModal