import useWindowDimensions from '../hooks/useWindowDimensions'

import PropTypes from 'prop-types'

import './Tabs.css'

const Tabs = ({ tabs, tab, setTab, events={}, tabPropsByTab={} }) => {
    const {width, height} = useWindowDimensions()

    const handleTabChange = (clickedTab) => {
        if (setTab)
            setTab(clickedTab)

        if (events.handleChange)
            events.handleChange(clickedTab)
    }
        
    return (
        <div className={`tabs-container scroll-invisible ${width < 1024 ? 'tabs-load-animation' : ''}`}>
            <ul>
                {tabs.map((t) => {
                    return (
                        <li key={`tabs-tab-${t}`} className={`tabs-tab ${t === tab ? 'action-active' : ''}`} onClick={() => handleTabChange(t)}>
                            {t}
                            { tabPropsByTab[t]?.errorCount > 0 && <span className="error-count">{tabPropsByTab[t].errorCount}</span> }
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

Tabs.propTypes = {
    events: PropTypes.shape({
        handleChange: PropTypes.func
    })
}

export default Tabs