import {useState} from 'react'

import EntityCardComponent from '../../components/EntityCard'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import UtilsHelper from '../../helpers/utils'

import useNotices from '../../hooks/useNotices'

import './index.css'

const PackagePlans = () => {
    const [NoticeComponent, notices] = useNotices('PSM/PackagePlans/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const baseUrl = '/package-plans'
    const baseApiUrl = '/api/v2/admin/package-plans'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name_route = `${baseUrl}/${item.id}/edit`
            item.ui_product_preview = <EntityCardComponent entityData={item} entityType="package plan" displayThumbnail={true} />

            return item
        })
    }

    return (
        <div className="package-plans-page-container page-container">
            <PageHeaderComponent title="Package Plans" actions={[{ title: 'New Package Plan', route: '/package-plans/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'ui_product_preview',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    }
                ]} />
        </div>
    )
}

export default PackagePlans
