import { useEffect, useState, useRef } from 'react'

import BPSSelector from '../BPSSelector'

import FetchHelper from '../../helpers/fetch'

import useAppContext from '../../hooks/useAppContext'

import _ from 'lodash'

import './index.css'

const BPSUpdater = ({ row, type, studioId, editable=true }) => {
    const [ac] = useAppContext()

    const [data, setData] = useState([])
    const [available, setAvailable] = useState([])
    const [showSelector, setShowSelector] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [selectedPoseType, setSelectedPoseType] = useState()
    const [itemProcessed, setItemProcessed] = useState()
    // const [selection, setSelection] = useState()

    const bpsMountedRef = useRef(null)
    const bpsUpdaterRef = useRef(null)

    useEffect(() => {
        if (bpsMountedRef.current)
            return

        bpsMountedRef.current = true

        refreshList()
    }, [])

    useEffect(() => {
        if (showSelector && bpsUpdaterRef.current) {
            const bpsUpdaterRefRect = bpsUpdaterRef.current.getBoundingClientRect()

            window.scrollTop = bpsUpdaterRefRect.top
        }
    }, [showSelector, bpsUpdaterRef.current])

    const refreshList = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: row.url,
            headers: {
                'x-studio-id': studioId
            }
        }, (res) => {
            ac.showSpinner(false)

            setData(res.body.data)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const refreshAvailableList = () => {
        const rowUrl = /^addons$/.test(type) ? row.url : /^(field|addon|package)$/.test(type) ? row.alternatives_url : row.available_url

        FetchHelper({
            url: selectedPoseType ? rowUrl + '?t=' + selectedPoseType.toLowerCase() : rowUrl,
            headers: {
                'x-studio-id': studioId
            }
        }, (res) => {
            setAvailable(res.body.data)
        }, (res) => {

        })
    }

    const updateSelection = (selection) => {
        FetchHelper({
            url: selectedItem ? selectedItem.url : row.url,
            method: selectedItem ? 'PUT' : 'POST',
            headers: {
                'x-studio-id': studioId
            },
            body: {
                [type]: {
                    [`${type}_id`]: selection[`${type}_id`]
                }
            }
        }, (res) => {
            refreshList()
            closeBPSSelector()
        }, (res) => {

        })
    }

    const deleteSelection = (selection) => {
        ac.showSpinner(true)
        setItemProcessed(selection)

        FetchHelper({
            url: selection.url,
            headers: {
                'x-studio-id': studioId
            },
            method: 'DELETE'
        }, (res) => {
            refreshList()
            closeBPSSelector()
            setItemProcessed()
            ac.showSpinner(false)
        }, (res) => {
            setItemProcessed()
            ac.showSpinner(false)
        })
    }

    const setSelected = (item) => {
        setSelectedItem(item)
        setShowSelector(true)
        refreshAvailableList()
    }

    const closeBPSSelector = () => {
        setSelectedItem()
        setShowSelector(false)
    }

    const handleSelect = (selection) => {
        updateSelection(selection)
        closeBPSSelector()
    }

    if (type === 'pose') {
        const sortedIndividualPoses = data.filter(item => {
            return item.type === 'IndividualPose'
        }).slice(0, row.individual_poses_required)

        const sortedCouplePoses = data.filter(item => {
            return item.type === 'CouplePose'
        }).slice(0, row.any_poses_required)

        const sortedAnyPoses = data.filter(item => {
            return item.type === 'IndividualPose'
        })
            .slice(row.individual_poses_required)
            .concat(
                data.filter(item => {
                    return item.type === 'CouplePose'
                }).slice(row.any_poses_required)
            )

        const renderPoseSection = (section, title) => {
            let selectButtonCount = 0

            if (title === 'Individual') {
                selectButtonCount = row.individual_poses_required - section.length || 1
            } else if (title === 'Couple') {
                selectButtonCount = row.couple_poses_required - section.length || 1
            } else {
                selectButtonCount = row.any_poses_required - section.length || 1
            }

            const remainingSelections = selectButtonCount > 0 ? Array(selectButtonCount).fill(0).map((item, index) => {
                return (
                    <li className="available" key={`remaining-selections-${index}`}>
                        <button onClick={() => {
                            setSelectedPoseType(title)
                            setSelected()
                        }} className="bps-button"></button>
                    </li>
                )
            }) : null

            return (
                <div className="bps-pose-list" key={`bps-pose-list-${title}`}>
                    <div className="title">
                        {title === 'Individual' ? (`${title}: ${section.length}/${row.individual_poses_required}`) : null}
                        {title === 'Couple' ? (`${title}: ${section.length}/${row.couple_poses_required}`) : null}
                        {title === 'Any' ? (`${title}: ${section.length}/${row.any_poses_required}`) : null}
                    </div>
                    <ul className="list-items">
                        {section.map((item, i) => {
                            return (
                                <li key={`bps-item-${item.id}-${i}`} className={`${item === selectedItem ? 'active' : ''} ${item === itemProcessed ? 'processed' : ''}`}>
                                    <div className="bps-image" style={{ backgroundImage: `url(${item.image_url})` }} onClick={() => editable && setSelected(item)}></div>
                                    <em>{item.name}</em>
                                    {editable && (
                                        <button className="delete" onClick={() => deleteSelection(item)}>
                                            <i className="bi bi-x"></i>
                                        </button>
                                    )}
                                </li>
                            )
                        })}
                        {section.length < row.individual_poses_required && editable ? remainingSelections : null}
                    </ul>
                </div>
            )
        }

        return (
            <div className="bps-updater-container" ref={bpsUpdaterRef}>
                {showSelector && available && <BPSSelector data={available} onSelect={handleSelect} onClose={closeBPSSelector} />}

                <div className="pose-list">
                    {[[sortedIndividualPoses, 'Individual'], [sortedCouplePoses, 'Couple'], [sortedAnyPoses, 'Any']].map((poseCollection, i) => {
                        return (
                            renderPoseSection(poseCollection[0], poseCollection[1])
                        )
                    })}
                </div>
            </div>
        )
    } else {
        const remainingSelections = Array(row.total_required ? row.total_required - data.length : 1).fill(0).map((item, index) => {
            return (
                <li key={`remaining-selections-${index}`}>
                    <button onClick={() => setSelected()} className="bps-button"></button>
                </li>
            )
        })

        return (
            <div className="bps-updater-container">
                {showSelector && available && <BPSSelector data={available} onSelect={handleSelect} onClose={closeBPSSelector} />}

                <ul className="bps-list">
                    {data && data.map((item, i) => {
                        return (
                            <li key={`bps-item-${item.id}-${i}`} className={`${item === selectedItem ? 'active' : ''} ${item === itemProcessed ? 'processed' : ''}`}>
                                <div className="bps-image" style={{ backgroundImage: `url(${item.image_url})` }} onClick={() => editable && setSelected(item)}></div>
                                <em>{item.name}</em>
                                {editable && (
                                    <button className="delete" onClick={() => deleteSelection(item)}>
                                        <i className="bi bi-x"></i>
                                    </button>
                                )}
                            </li>
                        )
                    })}
                    {editable && remainingSelections}
                </ul>
            </div>
        )
    }


}

export default BPSUpdater
