import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './index.css'

import { getSettingsForm1Fields, getSettingsPercentageFormFields, getSettingsTimeFormFields, getStudioInfoForm1Fields, getStudioInfoForm2Fields, getStudioInfoForm3Fields, getThirdPartyServicesFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const StudiosEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Administration/Studios/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [settings, setSettings] = useState()
    const [studioInfo, setStudioInfo] = useState()
    const [timeSettings, setTimeSettings] = useState()
    const [percentSettings, setPercentSettings] = useState()
    const [billingProfiles, setBillingProfiles] = useState([])
    const [services, setServices] = useState()
    const [address, setAddress] = useState()

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Studio Info', ['Studio Info', 'Settings', 'Third-Party Services'])

    const [studioInfoForm1Fields, setStudioInfoForm1Fields] = useState(getStudioInfoForm1Fields(billingProfiles))
    const [studioInfoForm2Fields] = useState(getStudioInfoForm2Fields())
    const [studioInfoForm3Fields, setStudioInfoForm3Fields] = useState(getStudioInfoForm3Fields(ac.AuthHelper?.settings?.sys?.timezones))
    const [settingsForm1Fields] = useState(getSettingsForm1Fields())
    const [settingsPercentageFormFields] = useState(getSettingsPercentageFormFields())
    const [settingsTimeFormFields] = useState(getSettingsTimeFormFields())
    const [thirdPartyServicesFormFields, setThirdPartyServicesFormFields] = useState(getThirdPartyServicesFormFields(true, true, services))

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getStudioInfoForm1Fields(billingProfiles)
        setStudioInfoForm1Fields(fields)
    }, [billingProfiles])

    useEffect(() => {
        let fields = getStudioInfoForm3Fields(ac.AuthHelper?.settings?.sys?.timezones);
        setStudioInfoForm3Fields(fields)
    }, [ac.AuthHelper?.settings?.sys?.timezones])

    useEffect(() => {
        let fields = getThirdPartyServicesFormFields(true, true, services)
        setThirdPartyServicesFormFields(fields)
    }, [services])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Studio Info',
                forms: [{ id: 'Main', fields: studioInfoForm1Fields }, { id: 'form2', fields: studioInfoForm2Fields }, { id: 'form3', fields: studioInfoForm3Fields }]
            },
            {
                tab: 'Settings',
                forms: [{ id: 'Main', fields: settingsForm1Fields }, { id: 'percentageForm', fields: settingsPercentageFormFields }, { id: 'timeForm', fields: settingsTimeFormFields }]
            },
            {
                tab: 'Third-Party Services',
                forms: [{ id: 'Main', fields: thirdPartyServicesFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/studios/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            let limits = {}
            let percentages = {}
            const refundSchedule = res.body.appointment_aware_refund_schedule
            if (refundSchedule) {
                for (let i = 0; i < refundSchedule.length; i++) {
                    const limit = refundSchedule[i].limit
                    const percent = refundSchedule[i].percentage
                    limits = { ...limits, ['hours' + i]: limit }
                    percentages = { ...percentages, ['percent' + i]: percent }
                }
            }

            let data = res.body
            if (data.customer_service_email)
                data.customer_service_email = UtilsHelper.stringifyEmails(data.customer_service_email)
            setData(data)
            setAddress({
                address1: res.body.address.address1,
                address2: res.body.address.address2,
                city: res.body.address.city,
                postal_code: res.body.address.postal_code,
                state_province: res.body.address.state_province,
                country: res.body.address.country
            })
            setStudioInfo({
                timezone: res.body.timezone,
                phone_number: res.body.phone_number,
                convenience_fee: res.body.convenience_fee,
                website: res.body.website,
                postal_code: res.body.postal_code
            })
            setSettings({
                policy_description: res.body.policy_description,
                policy_url: res.body.policy_url,
                self_service_enabled: res.body.self_service_enabled,
                refunding_requires_approval: res.body.refunding_requires_approval,
                lock_no_shows: res.body.lock_no_shows,
                appointment_aware_refunding: res.body.appointment_aware_refunding
            })
            setTimeSettings(limits)
            setPercentSettings(percentages)
            setServices({
                vanity_domain_service_enabled: res.body.third_party.vanity_domain_service_enabled,
                api_access_token_enabled: res.body.api_access_token_enabled,
                api_access_token: res.body.api_access_token,
                verification_token: res.body.third_party.verification_token,
                ga_tracking_id: res.body.third_party.ga_tracking_id,
                gtm_container_id: res.body.third_party.gtm_container_id,
                twilio_service_enabled: res.body.third_party.twilio_service_enabled,
                twilio_service_numbers: res.body.third_party.twilio_service_numbers,
                twilio_service_sid: res.body.third_party.twilio_service_sid,
                twilio_service_auth_token: res.body.third_party.twilio_service_auth_token,
                centrics_service_enabled: res.body.third_party.centrics_service_enabled,
                ddlabs_service_enabled: res.body.third_party.ddlabs_service_enabled,
                drums_service_enabled: res.body.third_party.drums_service_enabled,
                triprism_service_enabled: res.body.third_party.triprism_service_enabled,
                package_from_santa_service_enabled: res.body.third_party.package_from_santa_service_enabled,
                zapier_service_enabled: res.body.third_party.zapier_service_enabled,
                zapier_service_url: res.body.third_party.zapier_service_url,
                // zoom_service_enabled: res.body.third_party.zoom_service_enabled,
                // zoom_service_api_key: res.body.third_party.zoom_service_api_key,
                // zoom_service_api_secret: res.body.third_party.zoom_service_api_secret,
                portable_north_pole_service_enabled: res.body.third_party.portable_north_pole_service_enabled,
                portable_north_pole_service_username: res.body.third_party.portable_north_pole_service_username,
                portable_north_pole_service_password: res.body.third_party.portable_north_pole_service_password,
                proofing_service_enabled: res.body.third_party.proofing_service_enabled
            })
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/billing-profiles'
        }, (res) => {
            const options = [{ id: null, title: '--', value: "null" }];
            res.body.data.map((profile) => {
                const option = {
                    value: profile.id,
                    title: profile.name
                }
                options.push(option)
            })
            setBillingProfiles(options)
        }, (res) => {

        })
    }, [])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let appointment_aware_refund_schedule = []
        if (timeSettings && percentSettings) {
            for (let i = 0; i < 5; i++) {
                const limitKey = Object.keys(timeSettings)[i]
                const percentKey = Object.keys(percentSettings)[i]
                appointment_aware_refund_schedule.push({
                    limit: timeSettings[limitKey],
                    percentage: percentSettings[percentKey]
                })
            }
        }

        let body = {
            ...data,
            ...settings,
            ...studioInfo,
            ...services,
            address_attributes: address,
            past_due: data.past_due !== "" ? data.past_due : false,
            appointment_aware_refund_schedule: appointment_aware_refund_schedule,
            customer_service_email: UtilsHelper.stringifyEmails(data.customer_service_email)
        }

        if (body.watermark?.value)
            body.watermark_base64 = body.watermark.value

        delete body.watermark

        FetchHelper({
            url: `/api/v2/admin/studios/${params.id}`,
            method: 'PUT',
            body: {
                studio: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/studios')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    const onChangeTab = (tab) => {
        switch (tab) {
            case 'Studio Info':
                return (
                    <>
                        <NoticeComponent location="Tab/StudioInfo" notices={notices} />
                        <FormComponent tab={tab} formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setData} displayErrorBanner={true} fields={studioInfoForm1Fields} />
                        <FormComponent tab={tab} formData={address} formErrors={errors} setFormErrors={setErrors} onChange={setAddress} fields={studioInfoForm2Fields} />
                        <FormComponent tab={tab} formData={studioInfo} formErrors={errors} setFormErrors={setErrors} onChange={setStudioInfo} fields={studioInfoForm3Fields} />
                    </>
                )
            case 'Settings':
                return (
                    <>
                        <NoticeComponent location="Tab/Settings" notices={notices} />
                        <FormComponent tab={tab} formData={settings} formErrors={errors} setFormErrors={setErrors} onChange={setSettings} displayErrorBanner={true} fields={settingsForm1Fields} />
                        <div>
                            <div className="schedule-section">
                                <div className="section-title">Appointment aware refund schedule</div>

                                <div className="global-section">
                                    <div className="schedule-section-titles">
                                        <div className="title">Percentage</div><div className="title">Limit (in hours)</div>
                                    </div>

                                    <div className="schedule-section-forms">
                                        <div className='schedule-section-form'>
                                            <FormComponent tab={tab} formData={percentSettings} formErrors={errors} setFormErrors={setErrors} onChange={setPercentSettings} displayErrorBanner={true} fields={settingsPercentageFormFields} />
                                        </div>
                                        
                                        <div className='schedule-section-form'>
                                            <FormComponent tab={tab} formData={timeSettings} formErrors={errors} setFormErrors={setErrors} onChange={setTimeSettings} displayErrorBanner={true} fields={settingsTimeFormFields} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            case 'Third-Party Services':
                return (
                    <>
                        <NoticeComponent location="Tab/ThirdPartyServices" notices={notices} />
                        <FormComponent tab={tab} formData={services} formErrors={errors} setFormErrors={setErrors} onChange={setServices} displayErrorBanner={true} fields={thirdPartyServicesFormFields} />
                    </>
                )
            default:
                break;
        }
    }

    return (
        <div className='studios-container'>
            {data && address && studioInfo &&
                <>
                    <PageHeaderComponent title={`Edit Studio - ${data?.name}`} />
                    <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
                    <div className="tab-content">
                        {onChangeTab(tab)}
                    </div>
                    <ControlBar baseApiUrl="/studios" redirectUrl="/studios" cancelUrl="/studios" data={data} handleSubmit={handleSubmit} />
                </>
            }
        </div>
    )
}

export default StudiosEdit