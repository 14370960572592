import PropTypes from 'prop-types'

import { ButtonComponent } from '../../Primitive'

import './index.css'

const TagComponent = ({ item, config={}, events={} }) => {
    const handleClick = () => {
        if (events.handleClick)
            events.handleClick()
    }

    return (
        <ButtonComponent events={{ handleClick }} config={{ size: 'small', classNames: 'ui--tagButton'}}>
            {item.name} <span className="ui-icon bi bi-x"></span>
        </ButtonComponent>
    )
}

TagComponent.propTypes = {
    item: PropTypes.object,

    config: PropTypes.shape({}),

    events: PropTypes.shape({
        handleClick: PropTypes.func
    })
}

export { TagComponent }