import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import FetchHelper from '../../helpers/fetch'
import FormComponent from '../Form'
import SelectPaginatedComponent from '../SelectPaginated'
import ProjectorComponent from '../Projector'
import ModalComponent from '../../components/Modal'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'

import './index.css'

export default ({ data=[], apiUrl, sync=false, onChange, columns, entityType='Package' }) => {
    const [ac] = useAppContext()

    const [_data, setData] = useState([])
    const [formData, setFormData] = useState()
    const [dataModal, setDataModal] = useState([])
    const [errors, setErrors] = useState({})
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)

    useEffect(() => {
        let newData = data.map((item, index) => {
            return item
        })

        setData(newData)
        onChange(newData)
    }, [])

    const handleDeleteAddon = (item) => {
        setErrors({})

        if (sync && item.value !== null) {
            ac.showSpinner(true)

            FetchHelper({
                url: item.url,
                // relativePath: false,
                method: 'DELETE'
            }, (res) => {
                ac.showSpinner(false)
                refreshList()
            }, (res) => {
                ac.showSpinner(false)
            })
        } else {
            let newState = _data.filter(e => {
                return e.addon_id.value !== item.addon_id.value
            })

            setData(newState)
            onChange(newState)
        }
    }

    const refreshList = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: apiUrl,
            // relativePath: false,
            method: 'GET'
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body.data)
            onChange(res.body.data)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleShowEditModal = (item) => {
        setFormData(item)
        setShowEditModal(true)
    }

    const handleCloseModal = () => {
        setErrors({})
        setShowAddModal(false)
        setShowEditModal(false)
    }

    const handleAddAddon = () => {
        setErrors({})

        if (sync) {
            ac.showSpinner(true)

            let body = {...dataModal}

            body.addon_id = [body.addon_id.value]

            FetchHelper({
                url: apiUrl,
                // relativePath: false,
                method: 'POST',
                body: {
                    package_addon: body
                }
            }, (res) => {
                ac.showSpinner(false)

                handleCloseModal()
                refreshList()
            }, (res) => {
                ac.showSpinner(false)

                if (res.body)
                    setErrors(res.body[0]) // only supports single addon
            })
        } else {
            let exists = false
            let body = {...dataModal, ...dataModal.addon_id}

            body.name = body.addon_id.item.name
            body.emphasized = body.addon_id.item.emphasized

            _data.forEach(item => {
                if (item.addon_id.item.id === body.addon_id.item.id) {
                    exists = true
                }
            })

            if (!exists) {
                let newData = [..._data, body]

                setData(newData)
                onChange(newData)
            }
        }
    }

    const handleEditAddon = () => {
        ac.showSpinner(true)

        let body = {...dataModal}

        FetchHelper({
            url: formData.url,
            // relativePath: false,
            method: 'PUT',
            body: {
                package_addon: body
            }
        }, (res) => {
            ac.showSpinner(false)

            handleCloseModal()
            refreshList()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleDrop = (e, item, index, section) => {
        setErrors({})

        let dataReference = (section === 'emphasized') ? filteredDataEmphasized : filteredDataRegular

        e.preventDefault()

        let target = 0

        if (!e.target.getAttribute('data-drag-index')) {
            target = parseInt(e.target.closest('li').getAttribute('data-drag-index'))
        } else {
            target = parseInt(e.target.getAttribute('data-drag-index'))
        }

        if (draggedElementIndex === target) {
            return
        }

        if (sync) {
            ac.showSpinner(true)

            let body = {
                position: target - ((section === 'emphasized') ? filteredDataEmpasizedOffset : filteredDataRegularOffset)
            }

            FetchHelper({
                url: dataReference[draggedElementIndex].url,
                // relativePath: false,
                method: 'PUT',
                body: body
            }, (res) => {
                ac.showSpinner(false)
            }, (res) => {
                ac.showSpinner(false)
            })
        }

        let newFields = [...dataReference]
        let element = newFields[draggedElementIndex]
        
        newFields.splice(draggedElementIndex, 1)
        newFields.splice(target, 0, element)

        if (section === 'emphasized') {
            setData([...newFields, ...filteredDataRegular])
            onChange([...newFields, ...filteredDataRegular])
        } else {
            setData([...newFields, ...filteredDataEmphasized])
            onChange([...newFields, ...filteredDataEmphasized])
        }
    }

    const renderHeaders = () => {
        return (
            <ul className="row columns">
                {columns.map((column, index) => {
                    <li key={`field-multiselector-header-${column.name}`}>{column.title}</li>
                })}
            </ul>
        )
    }

    let filteredDataEmpasizedOffset = 0
    let filteredDataEmphasized = _data.filter(a => {
        if (a.emphasized && a.bundled)
            filteredDataEmpasizedOffset += 1

        return a.emphasized
    })

    let filteredDataRegularOffset = 0
    let filteredDataRegular = _data.filter(a => {
        if (!a.emphasized && a.bundled)
            filteredDataRegularOffset += 1

        return !a.emphasized
    })

    let draggedElementIndex = null

    return (
        <div className={`field-multiselector-container ${ac.isLoading ? 'disabled' : ''}`}>
            <div className="row header-controls">
                <button className="secondary" onClick={() => setShowAddModal(true)}>Attach Addon</button>
            </div>

            {renderHeaders()}

            <ul className="rows">
                <h2>Emphasized {filteredDataEmphasized.length > 0 ? (<div className="titles"><span>Code</span> <span>Price</span></div>) : null}</h2>

                {filteredDataEmphasized.length > 0 ? filteredDataEmphasized.map((item, index) => {
                    let section = 'emphasized'

                    if (item.bundled) {
                        return (
                            <li>
                                <div className="info">
                                    <i className="bi bi-arrow-down-up disabled"></i> <div className="flex"><span>{item.name}</span> <span>{item.code}</span> <span>{UtilsHelper.formatDollar(item.price_override || item.value)}</span></div> 
                                </div>

                                <div className="controls">
                                <button onClick={() => RouteHelper.redirect(`/addons/${item.addon_id}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                    {entityType === 'Package Plan' ? (
                                        <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-boxes"></i></button>
                                    ) : null}
                                    {sync ? (
                                        <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                    ) : null}
                                    <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                </div>
                            </li>
                        )
                    } else {
                        return (
                            <li key={`field-multiselector-bundled-emphasized-${index}`}
                                draggable="true"
                                data-drag-index={index}
                                onDragStart={() => draggedElementIndex = index}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, item, index, section)}>
                                    <div className="info">
                                        <i className="bi bi-arrow-down-up"></i> <div className="flex"><span>{item.name}</span> <span>{item.code}</span> <span>{UtilsHelper.formatDollar(item.price_override || item.value)}</span></div> 
                                    </div>

                                    <div className="controls">
                                        <button onClick={() => RouteHelper.redirect(`/addons/${item.addon_id}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                        {entityType === 'Package Plan' ? (
                                            <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-boxes"></i></button>
                                        ) : null}
                                        {sync ? (
                                            <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                        ) : null}
                                        <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                    </div>
                                </li>
                        )
                    }
                }) : <p>No emphasized addons currently attached to this package</p>}
            </ul>

            <ul className="rows">
                <h2>Regular {filteredDataRegular.length > 0 ? (<div className="titles"><span>Code</span> <span>Price</span></div>) : null}</h2>

                {filteredDataRegular.length > 0 ? filteredDataRegular.map((item, index) => {
                    let section = 'regular'

                    if (item.bundled) {
                        return (
                            <li>
                                <div className="info">
                                    <i className="bi bi-arrow-down-up disabled"></i> <div className="flex"><span>{item.name}</span> <span>{item.code}</span> <span>{UtilsHelper.formatDollar(item.price_override || item.value)}</span></div> 
                                </div>

                                <div className="controls">
                                    <button onClick={() => RouteHelper.redirect(`/addons/${item.addon_id}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                    {entityType === 'Package Plan' ? (
                                        <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-boxes"></i></button>
                                    ) : null}
                                    {sync ? (
                                        <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                    ) : null}
                                    <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                </div>
                            </li>
                        )
                    } else {
                        return (
                            <li key={`field-multiselector-bundled-emphasized-${index}`}
                                draggable="true"
                                data-drag-index={index}
                                onDragStart={() => draggedElementIndex = index}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, item, index, section)}>
                                    <div className="info">
                                        <i className="bi bi-arrow-down-up"></i> <div className="flex"><span>{item.name}</span> <span>{item.code}</span> <span>{UtilsHelper.formatDollar(item.price_override || item.value)}</span></div> 
                                    </div>

                                    <div className="controls">
                                        <button onClick={() => RouteHelper.redirect(`/addons/${item.addon_id}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                        {entityType === 'Package Plan' ? (
                                            <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-boxes"></i></button>
                                        ) : null}
                                        {sync ? (
                                            <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                        ) : null}
                                        <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                    </div>
                                </li>
                        )
                    }
                }) : <p>No regular addons currently attached to this package</p>}
            </ul>

            {showAddModal ? (
                <ModalComponent handleClose={handleCloseModal}>
                    {entityType === 'Package' ? 
                        <FormComponent formErrors={errors} onChange={setDataModal} displayErrorBanner={true} fields={[{
                            type: 'select-paginated',
                            name: 'addon_id',
                            label: 'Addon',
                            field_info: '* represents emphasized addons',
                            placeholder: 'Addon A with Splash',
                            apiUrl: '/api/v2/admin/addons',
                            titleFilter: item => item.emphasized ? `* ${item.name}` : item.name,
                            onChangeFilter: (value) => value
                        },{
                            type: 'switch',
                            name: 'bundled',
                            label: 'Bundled?',
                        },{
                            type: 'input-number',
                            name: 'limit_per',
                            label: 'Limit per Package',
                            condition: (data) => !data.bundled
                        },{
                            type: 'input-text',
                            name: 'price_override',
                            label: 'Price Override',
                            field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                        }]} />
                    : null}
                    
                    {entityType === 'Package Plan' ? 
                        <FormComponent formErrors={errors} onChange={setDataModal} displayErrorBanner={true} fields={[{
                            type: 'select-paginated',
                            name: 'addon_id',
                            label: 'Addon',
                            field_info: '* represents emphasized addons',
                            placeholder: 'Addon A with Splash',
                            apiUrl: '/api/v2/admin/addons',
                            titleFilter: item => item.emphasized ? `* ${item.name}` : item.name,
                            onChangeFilter: (value) => value
                        },{
                            type: 'switch',
                            name: 'bundled',
                            label: 'Bundled?',
                        },{
                            type: 'input-number',
                            name: 'limit_per',
                            label: 'Limit per Package',
                            condition: (data) => !data.bundled
                        },{
                            type: 'switch',
                            name: 'notify',
                            label: 'Notify on Purchase?'
                        },{
                            type: 'input-number',
                            name: 'pose_count_individual',
                            label: 'Pose count individual'
                        },{
                            type: 'input-number',
                            name: 'pose_count_couple',
                            label: 'Pose count couple'
                        },{
                            type: 'input-number',
                            name: 'pose_count_any',
                            label: 'Pose count any'
                        },{
                            type: 'input-number',
                            name: 'background_count',
                            label: 'Background count'
                        },{
                            type: 'input-number',
                            name: 'sheet_count',
                            label: 'Sheet count'
                        },{
                            type: 'input-text',
                            name: 'price_override',
                            label: 'Price Override',
                            field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                        }]} />
                    : null}

                    <div className="row flex controls">
                        <button onClick={handleAddAddon}>Attach</button> <button className="secondary" onClick={() => setShowAddModal(false)}>Cancel</button>
                    </div>
                </ModalComponent>
            ) : null}

            {showEditModal ? (
                <ModalComponent handleClose={handleCloseModal}>
                    <ProjectorComponent url={formData.image.image_url} />
                    
                    <div className="form-field-wrapper addon">
                        <div className="form-label">Addon</div>
                        <div className="form-field">
                            {formData.name}
                        </div>
                    </div>

                    {entityType === 'Package Plan' ? 
                        <FormComponent formData={formData} formErrors={errors} onChange={setDataModal} displayErrorBanner={true} fields={[{
                            type: 'switch',
                            name: 'bundled',
                            label: 'Bundled?',
                        },{
                            type: 'input-number',
                            name: 'limit_per',
                            label: 'Limit per Package',
                            condition: (data) => !data.bundled
                        },{
                            type: 'switch',
                            name: 'notify',
                            label: 'Notify on Purchase?'
                        },{
                            type: 'input-number',
                            name: 'pose_count_individual',
                            label: 'Pose count individual'
                        },{
                            type: 'input-number',
                            name: 'pose_count_couple',
                            label: 'Pose count couple'
                        },{
                            type: 'input-number',
                            name: 'pose_count_any',
                            label: 'Pose count any'
                        },{
                            type: 'input-number',
                            name: 'background_count',
                            label: 'Background count'
                        },{
                            type: 'input-number',
                            name: 'sheet_count',
                            label: 'Sheet count'
                        },{
                            type: 'input-text',
                            name: 'price_override',
                            label: 'Price Override',
                            field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                        }]} />
                    : null}

                    {entityType === 'Package' ? 
                        <FormComponent formData={formData} formErrors={errors} onChange={setDataModal} displayErrorBanner={true} fields={[{
                            type: 'switch',
                            name: 'bundled',
                            label: 'Bundled?',
                        },{
                            type: 'input-number',
                            name: 'limit_per',
                            label: 'Limit per Package',
                            condition: (data) => !data.bundled
                        },{
                            type: 'input-text',
                            name: 'price_override',
                            label: 'Price Override',
                            field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                        }]} />
                    : null}

                    <div className="row flex controls">
                        <button onClick={handleEditAddon}>Save</button> <button className="secondary" onClick={() => setShowEditModal(false)}>Cancel</button>
                    </div>
                </ModalComponent>
            ) : null}
        </div>
    )
}