import {useState, useEffect} from 'react'

import ErrorsComponent from '../../components/Errors'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../../components/Form'
import SwitchComponent from '../../components/Switch'

import RouteHelper from '../../helpers/route'
import UtilsHelper from '../../helpers/utils'

import './index.css'
import { useParams } from 'react-router-dom'

const LoginPage = () => {
    const params = useParams()
    const [paramsToken, setParamsToken] = useState(params.token)

    const [tab, setTab] = useState('Login')

    const [errorsLogin, setErrorsLogin] = useState([])
    const [errorsReset, setErrorsReset] = useState([])
    const [errorsNewPassword, setErrorsNewPassword] = useState()

    const [dataLogin, setDataLogin] = useState()
    const [dataReset, setDataReset] = useState()
    const [dataNewPassword, setDataNewPassword] = useState()

    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        if (paramsToken) {
            setTab('New Password')
        }
    }, [paramsToken])

    useEffect(() => {
        const handleSubmitByEnter = (e) => {
            const dom_loginFieldInput = document.querySelector('.login-form.login input[name="login"]')
            const dom_passwordFieldInput = document.querySelector('.login-form.login input[name="password"]')

            if (e.code === 'Enter' && (dom_loginFieldInput === document.activeElement || dom_passwordFieldInput === document.activeElement)) {
                handleSubmitLogin()
            }
        }

        window.addEventListener('keyup', handleSubmitByEnter)

        return () => {
            window.removeEventListener('keyup', handleSubmitByEnter)
        }
    })

    const handleTabSwitch = (tabString) => {
        switch (tabString) {
            case 'Login':
                RouteHelper.redirect('/login')
                setErrorsLogin()
                setDataLogin()
                break
            case 'Reset':
                setErrorsReset()
                setDataReset()
                break
            case 'Reset Sent':
                
                break
            case 'New Password':
                setErrorsNewPassword()
                setDataNewPassword()
                break
            case 'Password Changed':
                
                break
        }

        setTab(tabString)
    }

    const handleSubmitNewPassword = () => {
        if (!paramsToken)
            return

        if (!dataNewPassword?.password || !dataNewPassword?.password_confirmation) {
            return
        }

        setErrorsNewPassword()
        setIsFetching(true)

        FetchHelper({
            url: `/api/v2/admin/reset-password`,
            method: 'put',
            body: {
                admin_user: {
                    reset_password_token: paramsToken,
                    password: dataNewPassword.password,
                    password_confirmation: dataNewPassword.password_confirmation
                }
            }
        }, (res) => {
            handleTabSwitch('Password Changed')
            setIsFetching(false)
        }, (res) => {

            if (res.status === 422) {
                setErrorsNewPassword(res.body.errors)
            } else {
                setErrorsNewPassword(['There was an error. Please try again.'])                
            }

            setIsFetching(false)
        })
    }

    const handleSubmitReset = () => {
        if (!dataReset?.login) {
            return
        }

        setErrorsReset()
        setIsFetching(true)

        FetchHelper({
            url: `/api/v2/admin/reset-password`,
            method: 'post',
            body: {
                admin_user: {
                    login: dataReset.login
                }
            }
        }, (res) => {
            handleTabSwitch('Reset Sent')
            setIsFetching(false)
        }, (res) => {
            if (res.status === 422) {
                setErrorsReset(res.body.errors)
            } else {
                setErrorsReset(['There was an error. Please try again.'])                
            }

            setIsFetching(false)
        })
    }

    const handleSubmitLogin = () => {
        if (isFetching || !dataLogin) 
            return 

        setErrorsLogin()
        setIsFetching(true)

        FetchHelper({
            url: '/api/v2/admin/login',
            method: 'post',
            body: {
                admin_user: {
                    login: dataLogin.login,
                    password: dataLogin.password,
                    // remember_me: dataLogin.remember_me
                }
            }
        }, (res) => {
            const referrer = UtilsHelper.getLocalStorage('UI:LastURL')
            
            if (referrer) {
                RouteHelper.redirect(referrer)
            } else {
                RouteHelper.redirectToRoot()
            }

            setIsFetching(false)
        }, (res) => {
            setIsFetching(false)

            if (res.status === 401) {
                setErrorsLogin(['Invalid login'])
            }
        })
    }

    return (
        <div className={`login-page-container ${isFetching ? 'disabled' : ''}`}>
            <div className="login-container">
                <div className="login-content">
                    <div className="login-logo"></div>

                    {tab === 'Password Changed' ? (
                        <>
                            <div className="login-form password-changed">
                                Password successfully changed
                            </div>

                            <div className="login-controls">
                                <div className="button-row">
                                    <button className="secondary" onClick={() => handleTabSwitch('Login')}>Return to login</button>
                                </div>
                            </div>
                        </>
                    ) : null}

                    {tab === 'New Password' ? (
                        <>
                            <div className="login-form new-password">
                                <FormComponent type="stacked" formData={dataNewPassword} formErrors={errorsReset} onChange={setDataNewPassword} fields={[
                                    {
                                        type: 'input-password',
                                        name: 'password',
                                        label: 'Password',
                                    },
                                    {
                                        type: 'input-password',
                                        name: 'password_confirmation',
                                        label: 'New Password',
                                    }
                                ]} />
                            </div>

                            {errorsNewPassword ? (
                                <ErrorsComponent errors={errorsNewPassword} />
                            ) : null}

                            <div className="login-controls">
                                <div className="button-row">
                                    <button className="login-button" onClick={handleSubmitNewPassword}>Change password</button>
                                </div>
                                <div className="button-row">
                                    <button className="secondary" onClick={() => handleTabSwitch('Login')}>Return to login</button>
                                </div>
                            </div>
                        </>
                    ) : null}

                    {tab === 'Reset' ? (
                        <>
                            <div className="login-form reset">
                                <FormComponent type="stacked" formData={dataReset} formErrors={errorsReset} onChange={setDataReset} fields={[
                                    {
                                        type: 'input-text',
                                        name: 'login',
                                        label: 'Login',
                                    }
                                ]} />
                            </div>

                            {errorsReset ? (
                                <ErrorsComponent errors={errorsReset} />
                            ) : null}

                            <div className="login-controls">
                                <div className="button-row">
                                    <button className="login-button" onClick={handleSubmitReset}>Request password reset instructions</button>
                                </div>
                                <div className="button-row">
                                    <button className="secondary" onClick={() => handleTabSwitch('Login')}>Return to login</button>
                                </div>
                            </div>
                        </>
                    ) : null}

                    {tab === 'Reset Sent' ? (
                        <>
                            <div className="login-form reset-sent">
                                Instructions for resetting your password have been sent to the email associated to <strong>{dataReset.login}</strong>
                            </div>

                            <div className="login-controls">
                                <div className="button-row">
                                    <button className="secondary" onClick={() => handleTabSwitch('Login')}>Return to login</button>
                                </div>
                            </div>
                        </>
                    ) : null}
                    
                    {tab === 'Login' ? (
                        <>
                            {errorsLogin ? (
                                <div className="login-form errors">
                                    <ErrorsComponent errors={errorsLogin} />
                                </div>
                            ) : null}

                            <div className="login-form login">
                                <FormComponent type="stacked" formData={dataLogin} formErrors={errorsLogin} onChange={setDataLogin} fields={[
                                    {
                                        type: 'input-text',
                                        name: 'login',
                                        label: 'Login',
                                    },
                                    {
                                        type: 'input-password',
                                        name: 'password',
                                        label: 'Password'
                                    },
                                    // {
                                    //     type: 'switch',
                                    //     name: 'remember_me',
                                    //     label: 'Remember me'
                                    // }
                                ]} />
                            </div>

                            <div className="login-controls">
                                <div className="button-row">
                                    <button className="login-button" onClick={handleSubmitLogin}>Continue to Admin Panel <i className="bi bi-chevron-right"></i></button>
                                </div>
                                <div className="button-row">
                                    <button className="secondary" onClick={() => handleTabSwitch('Reset')}>I'm having trouble logging in...</button>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>

                <div className="copyright">&copy; {(new Date()).getFullYear()} TP2 Software Inc.</div>
            </div>
        </div>
    )
}

export default LoginPage