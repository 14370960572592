import { useEffect, useState } from 'react';

function getLocalStorage(key) {
  const data = localStorage.getItem(`Draft:${key}`)

  try {
    return JSON.parse(data)
  } catch (e) {
    return null
  }
}

function setLocalStorage(key, data) {
    try {
        localStorage.setItem(`Draft:${key}`, JSON.stringify(data))
    } catch (e) {}
}

function clearLocalStorage(key) {
    localStorage.removeItem(`Draft:${key}`)
}

export default function useDraft(key, defaultValue) {
    const [dataDefault, setDataDefault] = useState(getLocalStorage(`${key}:Default`))
    const [data, _setData] = useState(getLocalStorage(key) || defaultValue);

    function setData(d) {
        if (!dataDefault) {
          setDataDefault(d)
          setLocalStorage(`${key}:Default`, d)
        }

        _setData(d)
        setLocalStorage(key, d)
    }

    function clearData() {
        clearLocalStorage(key)
        clearLocalStorage(`${key}:Default`)
        _setData(defaultValue)
    }

    return [data, setData, clearData, dataDefault]
}