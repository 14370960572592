import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'

import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useTabs from '../../hooks/useTabs'

import './index.css'

const PoliciesPage = () => {
    const [ac] = useAppContext()
    
    const [tabsTheme, tabTheme, setTabTheme, TabsComponent] = useTabs('Bunny', ['Bunny', 'Dance', 'Santa', 'Generic'])
    const [tabsType, tabType, setTabType] = useTabs('Fast Pass', ['Fast Pass', 'Pre Pay'])

    // FORM
    const [errors, setErrors] = useState()

    // DATA
    const [data, setData] = useState()
    const [dataBunnyFastPass, setDataBunnyFastPass] = useState()
    const [dataBunnyPrePay, setDataBunnyPrePay] = useState()
    const [dataDanceFastPass, setDataDanceFastPass] = useState()
    const [dataDancePrePay, setDataDancePrePay] = useState()
    const [dataSantaFastPass, setDataSantaFastPass] = useState()
    const [dataSantaPrePay, setDataSantaPrePay] = useState()
    const [dataGenericFastPass, setDataGenericFastPass] = useState()
    const [dataGenericPrePay, setDataGenericPrePay] = useState()

    useEffect(() => {
        if (!data) {
            ac.showSpinner(true)

            FetchHelper({
                url: `/api/v2/admin/configuration`
            }, (res) => {
                ac.showSpinner(false)
    
                setData(res.body)
            }, (res) => {
                ac.showSpinner(false)
            })
        }
    }, [])

    const handleSubmit = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/configuration`,
            method: 'put',
            body: {
                configuration: {
                    ...dataBunnyFastPass,
                    ...dataBunnyPrePay,
                    ...dataDanceFastPass,
                    ...dataDancePrePay,
                    ...dataSantaFastPass,
                    ...dataSantaPrePay,
                    ...dataGenericFastPass,
                    ...dataGenericPrePay
                }
            }
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
            RouteHelper.redirect('/dashboard')
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const getFormFields = (section) => {
        return [{
            type: 'editor',
            name: `${section}_privacy_policy`,
            label: 'Privacy Policy',
        },{
            type: 'editor',
            name: `${section}_refund_policy`,
            label: 'Refund Policy',
        },{
            type: 'editor',
            name: `${section}_terms_of_use`,
            label: 'Terms of Use',
        }]
    }

    return (
        <div className="policies-page-container page-container">
            <PageHeaderComponent title={`Policies`} />
            
            <TabsComponent tabs={tabsTheme} tab={tabTheme} setTab={setTabTheme} />
            <TabsComponent tabs={tabsType} tab={tabType} setTab={setTabType} />

            <div className={`page-form-container`}>
                {data ? (
                    <>
                        {tabTheme === 'Bunny' && tabType === 'Fast Pass' ? <FormComponent formData={data} formErrors={errors} onChange={setDataBunnyFastPass} displayErrorBanner={true} fields={getFormFields('bunny_fastpass')} /> : null}
                        {tabTheme === 'Bunny' && tabType === 'Pre Pay' ? <FormComponent formData={data} formErrors={errors} onChange={setDataBunnyPrePay} displayErrorBanner={true} fields={getFormFields('bunny_prepay')} /> : null}
                        {tabTheme === 'Dance' && tabType === 'Fast Pass' ? <FormComponent formData={data} formErrors={errors} onChange={setDataDanceFastPass} displayErrorBanner={true} fields={getFormFields('dance_fastpass')} /> : null}
                        {tabTheme === 'Dance' && tabType === 'Pre Pay' ? <FormComponent formData={data} formErrors={errors} onChange={setDataDancePrePay} displayErrorBanner={true} fields={getFormFields('dance_prepay')} /> : null}
                        {tabTheme === 'Santa' && tabType === 'Fast Pass' ? <FormComponent formData={data} formErrors={errors} onChange={setDataSantaFastPass} displayErrorBanner={true} fields={getFormFields('santa_fastpass')} /> : null}
                        {tabTheme === 'Santa' && tabType === 'Pre Pay' ? <FormComponent formData={data} formErrors={errors} onChange={setDataSantaPrePay} displayErrorBanner={true} fields={getFormFields('santa_prepay')} /> : null}
                        {tabTheme === 'Generic' && tabType === 'Fast Pass' ? <FormComponent formData={data} formErrors={errors} onChange={setDataGenericFastPass} displayErrorBanner={true} fields={getFormFields('generic_fastpass')} /> : null}
                        {tabTheme === 'Generic' && tabType === 'Pre Pay' ? <FormComponent formData={data} formErrors={errors} onChange={setDataGenericPrePay} displayErrorBanner={true} fields={getFormFields('generic_prepay')} /> : null}
                    </>
                ) : null}
            </div>

            <ControlBar data={data} submitButtonTitle="Edit" handleSubmit={handleSubmit} enableCopy={false} enableDelete={false} />
        </div>
    )
}

export default PoliciesPage