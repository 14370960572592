import {useState, useEffect} from 'react'

import IconX from '../assets/icon-x.svg'
import './TagsInput.css'

const TagsInput = ({ value=[], suggestions, onDelete, onAdd, readOnly }) => {
    const [tags, setTags] = useState([])
    const [term, setTerm] = useState('')

    useEffect(() => {
        let val = value
        if (!val) val = [];
        setTags(val)
    }, [value])

    const handleTagClick = (clickedTag) => {
        let newTags = tags.filter((tag) => {
            return !(tag === clickedTag)
        })

        setTags(newTags)
        onDelete(newTags)
    }

    const handleReturn = (e) => {
        if (e.keyCode !== 13 || term === '') {
            return
        }

        addTag(term)
    }

    const addTag = (term) => {
        let newTags = [...tags]

        if (tags.indexOf(term) === -1) {
            newTags.push(term)
            setTags(newTags)
            setTerm('')
            onAdd(newTags)
        }
    }

    const renderSuggestions = () => {
        if (term === '') {
            return null
        }

        const matches = suggestions.filter((suggestion) => {
            return suggestion.indexOf(term) > -1
        })

        if (matches.length === 0) {
            return null
        }

        return (
            <ul className="tags-input-suggestions">
                {matches.map((suggestion) => {
                    return (
                        <li key={`tags-input-suggestion-${suggestion}`} onClick={() => addTag(suggestion)}>{suggestion}</li>
                    )
                })}
            </ul>
        )
    }

    const renderTags = () => {
        if (!tags || tags.length === 0) {
            return null
        }

        return (
            <ul className="tags-input-list">
                {tags.map((tag, i) => {
                    return (
                        <li key={`tags-input-list-${tag}-${i}`}><button onClick={() => handleTagClick(tag)}><span>{tag}</span> <img src={IconX} alt="" className="tags-tag-x" /></button></li>
                    )
                })}
            </ul>
        )
    }

    return (
        <div className="tags-input-container">
            {renderTags()}
            <div className="tags-input">
                <input type="text" value={term} onChange={(e) => setTerm(e.target.value)} placeholder="Search for tags..." onKeyDown={handleReturn} disabled={readOnly} />
            </div>
            {renderSuggestions()}
        </div>
    )
}

export default TagsInput
