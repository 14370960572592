import lodash from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  ControlBar,
  Form,
  Modal,
  PageHeader,
  Switch
} from '../../../components';
import { Utils } from '../../../helpers';
import { extractQRFromImageFile } from '../../../helpers/image-utils';
import { useAppContext, useFetch, useNotices, useWindowDimensions } from '../../../hooks';
import { getWorkflow1FormFields } from '../providers/form-fields';
import './index.css';
import dayjs from 'dayjs';
import { InputComponent } from '../../../componentsV2/Primitive';
import FormComponent from '../../../components/Form';
import { defer } from 'rxjs';

const Workflow1 = () => {
  const [ac] = useAppContext();
  const { FetchHelper } = useFetch();
  const [NoticeComponent, notices] = useNotices('Workflow1');
  const isMounted = useRef(false);
  const [data, setData] = useState({
    starts_at: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
    ends_at: dayjs().format('YYYY-MM-DD')
  });
  const [processedFilesCount, setProcessedFilesCount] = useState();
  const [dataImages, setDataImages] = useState();
  const dataImagesRef = useRef(dataImages);
  const [filteredDataImages, setFilteredDataImages] = useState();
  const draggedItem = useRef(null);
  const dragOverItem = useRef(null);
  const [modalImg, setModalImg] = useState();
  const { width: windowWidth } = useWindowDimensions()
  const [mode, setMode] = useState(); // eg. bulk mode

  const [formFields, setFormFields] = useState(getWorkflow1FormFields());
  const [dataShowImages, setDataShowImages] = useState(false);

  const [dataMarketOrdersById, setDataMarketOrdersById] = useState([]);
  const [dataFilters, setDataFilters] = useState({});
  const [dataMoveToOrder, setDataMoveToOrder] = useState();
  const [dataMoveToOrderErrors, setDataMoveToOrderErrors] = useState({});
  const [dataImgGroupInfo, setDataImgGroupInfo] = useState();
  const [dataUploadResults, setDataUploadResults] = useState();

  const dataBulkSelection = useRef([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    isMounted.current = true;
    return onPageDestroy;
  }, []);

  const onPageDestroy = () => {
    isMounted.current = false;

    // --- revoke all images object urls
    lodash.each(dataImagesRef.current, (groupData) => {
      if (groupData.qrImgObjectURL)
        URL.revokeObjectURL(groupData.qrImgObjectURL);
      if (groupData.qrThumbnailObjURL)
        URL.revokeObjectURL(groupData.qrThumbnailObjURL);
      groupData.images.forEach((imgData) => {
        URL.revokeObjectURL(imgData.imgDataUrl);
        URL.revokeObjectURL(imgData.thumbnailObjURL);
      });
    });
    setDataImages(null);
  };
  
  // --- maintain options-container height in css variable
  useEffect(() => {
    let optionsContainer = document.querySelector('.options-container')
    if (!optionsContainer) return

    document.documentElement.style.setProperty('--height-sticky-options-container', `${optionsContainer.offsetHeight}px`)
  }, [windowWidth, dataImages, filteredDataImages])

  useEffect(() => {
    if (!data.market_id || !data.starts_at || !data.ends_at) {
      setDataMarketOrdersById({});
      return;
    }

    // --- fetch market orders
    const fetchMarketOrders = async () => {
      const fetchAllOrders = async (page) => {
        const [res] = await Utils.executePromise(
          FetchHelper({
            url: `/api/v2/admin/orders?start_date=${data.starts_at}&end_date=${data.ends_at}&page=${page}&market_id=${data.market_id}`
          })
        );

        let totalPages = res?.body?.total_pages || 0;
        let orders = res?.body?.data || [];
        let nextPage = page + 1;
        if (nextPage <= totalPages) {
          let nextOrders = await fetchAllOrders(nextPage);
          orders = orders.concat(nextOrders);
        }
        return orders;
      };

      ac.showSpinner(true);
      let marketOrders = await fetchAllOrders(1);
      ac.showSpinner(false);
      setDataMarketOrdersById(lodash.keyBy(marketOrders, 'id'));
    };
    fetchMarketOrders();
  }, [data.market_id, data.starts_at, data.ends_at]);

  useEffect(() => {
    let fields = getWorkflow1FormFields(
      processedFilesCount,
      data.files?.value?.length
    );
    setFormFields(fields);
  }, [processedFilesCount, data.files?.value?.length]);

  useEffect(() => {
    if (!data.files || !data.files.value) return;

    setDataImages(undefined); // reset imagesByQR
    onFilesSelected();
  }, [data?.files]);

  useEffect(() => {
    dataImagesRef.current = dataImages;
  }, [dataImages]);

  const isOrderIdValid = (orderID) => {
    return !!dataMarketOrdersById[orderID];
  };

  useEffect(() => {
    let newImagesByQR = lodash.map(dataImages, (groupData) => {
      let orderRelatedInfo = prepareOrderRelatedInfo(
        groupData.qrCode,
        dataMarketOrdersById[groupData.qrCode]
      );

      // --- if order id is invalid, then mark all images as unselected
      if (!orderRelatedInfo.isOrderIdValid) {
        groupData.images.forEach((imgData) => {
          imgData.selected = false;
          imgData.primary = false;
        });
      }

      return { ...groupData, ...orderRelatedInfo };
    });
    if (lodash.isEmpty(newImagesByQR)) newImagesByQR = null;
    setDataImages(newImagesByQR);
  }, [dataMarketOrdersById]);

  useEffect(() => {
    let filteredData = lodash.filter(dataImages, (groupData, index) => {
      if (dataFilters.imgCount === '' || lodash.isNil(dataFilters.imgCount))
        return true;
      if (index === 0) return true; // unassigned images
      if (groupData?.images?.length >= dataFilters.imgCount) return true;
      return false;
    });
    setFilteredDataImages(filteredData);
  }, [dataFilters, dataImages]);

  const processFiles = async (files) => {
    if (files.length === 0) return;

    setProcessedFilesCount(0);
    setDataImages(null);

    // --- loop through each files, and create imagesByQR groups
    let imagesByQR = [createNewImgItem('Unassigned Images', null, null)];

    let imagesByQRItemIndex = imagesByQR.length - 1;
    for (const file of files) {
      if (!isMounted.current) break;
      let { qrCodesData, imgObjectURL, thumbnailObjURL } =
        await extractQRFromImageFile(file, 2000, 100, 1);
      let qrData = lodash.nth(qrCodesData, 0);
      if (qrData?.data) {
        if (Utils.isURL(qrData.data)) {
          // eg. support URL of format "https://example.com?proof_code=1234"
          let queryParams = Utils.extractQueryParams(qrData.data);
          if (queryParams.proof_code) qrData.data = queryParams.proof_code;

          // eg. support URL of format "https://example.com/1234"
          if (!queryParams.proof_code) {
            let urlParts = qrData.data.split('/');
            let lastPart = lodash.last(urlParts);
            if (lastPart) qrData.data = lastPart;
          }
        }

        imagesByQRItemIndex = lodash.findIndex(imagesByQR, { qrCode: qrData.data })
        if (imagesByQRItemIndex === -1) {
          imagesByQR.push(
            createNewImgItem(qrData.data, file, imgObjectURL, thumbnailObjURL)
          );
          imagesByQRItemIndex = imagesByQR.length - 1;
        }
        continue;
      }

      let groupData = imagesByQR[imagesByQRItemIndex];
      groupData.images.push({
        file,
        imgObjectURL,
        thumbnailObjURL,
        selected: groupData.isOrderIdValid,
      });

      setProcessedFilesCount((prev) => prev + 1);
    }
    if (!isMounted.current) return;

    setDataImages(imagesByQR);
    setProcessedFilesCount(undefined);
  };

  const onFilesSelected = async () => {
    if (!data.files || !data.files.value || data.files.value.length < 2) {
      setErrors({ files: ['Please select at least two files'] });
      return;
    }

    ac.showSpinner(true);
    await processFiles(data.files.value);
    ac.showSpinner(false);
  };

  const resetForm = () => {
    setData({ ...data, files: null });
    setErrors({});
    setProcessedFilesCount(undefined);
    setDataImages(null);
  }

  const uploadImagesData = async () => {
    let dataToUpload = lodash
      .chain(dataImages)
      .map((group) => {
        if (!group || !group.isOrderIdValid) return null;
        if (group.images.length === 0) return null;

        // --- prepare request body
        let formData = new FormData();
        formData.append('proof[code]', group.qrCode);
        lodash.each(group.images, image => {
          if (!image.selected) return null;
  
          formData.append('proof[proof_images_attributes][][filename]', image.file.name);
          formData.append('proof[proof_images_attributes][][image]', image.file, image.file.name);
          formData.append('proof[proof_images_attributes][][primary]', image.primary);
        });

        return {formData, qrCode: group.qrCode};
      })
      .filter()
      .value();

    // --- helper function to upload image group
    const uploadImageGroup = async (formData, qrCode) => {
      // --- upload group images
      const [res, err] = await Utils.executePromise(
        FetchHelper({
          url: `/api/v2/admin/markets/${data.market_id}/proofs`,
          method: 'POST',
          body: formData,
          headers: { 
            'Content-Type': 'multipart/form-data'
          }
        })
      )

      let status = !err && res.status !== 422 ? 'success' : 'error'
      return {
        status: status,
        data: status === 'success' ? res?.body : null,
        error: status === 'error' ? err || res?.body : null,
        qrCode: qrCode
      }
    };

    // --- prepare observables
    let uploadGroupsObservables = lodash.map(dataToUpload, (item) => {
      return defer(() => {
        return uploadImageGroup(item.formData, item.qrCode)
      })
    });

    // --- execute observables with parallel limit
    ac.setProgress({ processed: 0, total: dataToUpload.length });
    const [results] = await Utils.executeObservablesWithParallelLimit(
      uploadGroupsObservables,
      10,
      (count) => { 
        ac.setProgress({ processed: count, total: dataToUpload.length }) 
      }
    )
    ac.setProgress() 

    return results;
  };

  const handleSubmit = async () => {
    let results = await uploadImagesData();
    ac.showSpinner(false)
    
    setDataUploadResults(results)
    resetForm()
  }

  const markAllFirstPrimary = () => {
    lodash.each(dataImages, (groupData) => {
      let offset = 0;
      lodash.each(groupData.images, (imgData, index) => {
        if (!imgData.selected) offset++;
        imgData.primary = imgData.selected && index === offset;
      });
    });
    setDataImages([...dataImages]);
  };

  const markAllLastPrimary = () => {
    lodash.each(dataImages, (groupData) => {
      let offset = 0;
      lodash.eachRight(groupData.images, (imgData, index) => {
        if (!imgData.selected) offset++;
        imgData.primary =
          imgData.selected && index === groupData.images.length - 1 - offset;
      });
    });
    setDataImages([...dataImages]);
  };

  const toggleGroupSelection = (index) => {
    let isAnySelectedInGroup = lodash.some(
      dataImages[index].images,
      (i) => i.selected
    );
    dataImages[index].images.forEach((imgData) => {
      imgData.selected = !isAnySelectedInGroup;
      if (!imgData.selected && imgData.primary) imgData.primary = false;
    });
    setDataImages([...dataImages]);
  };

  const toggleBulkSelection = (qrCode, imgIndex) => {
    let itemIndex = lodash.findIndex(dataImages, { qrCode });
    if (itemIndex === -1) return;

    let newIsSelected = !dataImages[itemIndex].images[imgIndex].isSelectedForBulkAction
    dataImages[itemIndex].images[imgIndex].isSelectedForBulkAction = newIsSelected;
    setDataImages([...dataImages]);

    if (newIsSelected) {
      let item = { qrCode, imgIndex }
      dataBulkSelection.current.push(item)
    }

    if (!newIsSelected) {
      let index = lodash.findIndex(dataBulkSelection.current, { qrCode, imgIndex })
      if (index !== -1) dataBulkSelection.current.splice(index, 1)
    }

    setMode(dataBulkSelection.current.length > 0 ? 'bulk' : null)
  };

  // --- remove bulk selection from data
  const removeBulkSelection = (data) => {
    return lodash.map(data, (item) => {
      item.images = lodash.map(item.images, imgData => {
        imgData.isSelectedForBulkAction = false
        return imgData;
      })
      return item;
    })
  }

  // --- unselect all bulk selected images and exit bulk mode
  const exitBulkMode = () => {
    let newDataImages = [...dataImages];
    newDataImages = removeBulkSelection(newDataImages)
    setDataImages([...dataImages])

    dataBulkSelection.current = []
    setMode(null)
  }

  const toggleImgSelection = (qrCode, imgIndex) => {
    let index = lodash.findIndex(dataImages, { qrCode });
    if (index === -1) return;
    
    dataImages[index].images[imgIndex].selected =
      !dataImages[index].images[imgIndex].selected;
    if (
      !dataImages[index].images[imgIndex].selected &&
      dataImages[index].images[imgIndex].primary
    )
      togglePrimary(qrCode, imgIndex);
    setDataImages([...dataImages]);
  };

  const togglePrimary = (qrCode, imgIndex) => {
    let index = lodash.findIndex(dataImages, { qrCode });
    if (index === -1) return;

    dataImages[index].images.forEach((imgData, index) => {
      imgData.primary = index === imgIndex && !imgData.primary;
    });
    setDataImages([...dataImages]);
  };

  const onDragStart = (event) => {
    const qrCode = event.target.getAttribute('data-qr-code');
    const imgIndex = event.target.getAttribute('data-img-index');
    if (!qrCode || !imgIndex) return;
    draggedItem.current = { qrCode, imgIndex };
  };

  const onDragEnter = (event) => {
    const qrCode = event.currentTarget.getAttribute('data-qr-code');
    const imgIndex = event.currentTarget.getAttribute('data-img-index');

    // prevent moving items in same section
    if (!qrCode || !imgIndex || draggedItem.current?.qrCode === qrCode) {
      dragOverItem.current = null;
      return;
    }

    dragOverItem.current = { qrCode, imgIndex };
    event.currentTarget.classList.add('drag-over');
  };

  const onDragLeave = (event) => {
    event.currentTarget.classList.remove('drag-over');
  };

  const onDragEnd = (event) => {
    if (!draggedItem.current || !dragOverItem.current) return;

    const { qrCode: fromQrCode, imgIndex: fromImgIndex } = draggedItem.current;
    const { qrCode: toQrCode, imgIndex: toImgIndex } = dragOverItem.current;

    const fromGroup = lodash.find(dataImages, { qrCode: fromQrCode });
    const toGroup = lodash.find(dataImages, { qrCode: toQrCode });

    // --- move image from fromGroup to toGroup
    moveImageFromGroupToGroup(fromGroup, fromImgIndex, toGroup, toImgIndex);

    setDataImages([...dataImages]);

    draggedItem.current = null;
    dragOverItem.current = null;
  };

  const moveImageItemsToOrder = () => {
    if (!dataMoveToOrder) return;

    let targetOrderID = dataMoveToOrder.formData?.order_id;
    if (!lodash.has(dataMarketOrdersById, targetOrderID)) {
      setDataMoveToOrderErrors({ order_id: ['Invalid order ID'] });
      return;
    }

    let newDataImages = [...dataImages];

    // --- prepare to group
    let toGroup = lodash.find(newDataImages, { qrCode: targetOrderID });
    if (!toGroup) {
      toGroup = createNewImgItem(targetOrderID, null, null);
      newDataImages.push(toGroup);
    }

    lodash
      .chain(dataMoveToOrder.imagesData)
      .groupBy('qrCode')
      .mapValues(items => lodash.chain(items).map('imgIndex').orderBy(value => value, 'desc').value())
      .each((imgIndexes, qrCode) => {
        lodash.each(imgIndexes, imgIndex => {
          // --- prepare from group and image index
          let fromGroup = lodash.find(newDataImages, { qrCode })
          const fromImgIndex = imgIndex;
      
          // --- prepare to image index
          const toImgIndex = toGroup.images.length;
      
          // --- move image from fromGroup to toGroup
          if (fromGroup.qrCode === toGroup.qrCode) return;
          moveImageFromGroupToGroup(fromGroup, fromImgIndex, toGroup, toImgIndex);
        })
      })
      .value()

    // --- clear bulk selection
    if (mode === 'bulk') 
      newDataImages = removeBulkSelection(newDataImages)

    // --- update state
    setDataImages(newDataImages);
    setDataMoveToOrder(null);
    setDataMoveToOrderErrors(null);

    if (mode === 'bulk') {
      dataBulkSelection.current = []
      setMode(null)
    }
  };

  const moveImageToUnassignedGroup = (groupQR, imgIndex) => {
    let newDataImages = [...dataImages];
    let fromGroup = lodash.find(newDataImages, { qrCode: groupQR });
    let fromImgIndex = imgIndex;
    let toGroup = newDataImages[0];
    let toImgIndex = toGroup.images.length;
    moveImageFromGroupToGroup(fromGroup, fromImgIndex, toGroup, toImgIndex);
    setDataImages(newDataImages);
  };

  const bulkMoveToUnassignedGroup = () => {
    // --- adjust data for bulk move
    let newDataImages = [...dataImages];
    lodash
      .chain(dataBulkSelection.current)
      .groupBy('qrCode')
      .mapValues(items => lodash.chain(items).map('imgIndex').orderBy(value => value, 'desc').value())
      .each((imgIndexes, qrCode) => {
        lodash.each(imgIndexes, imgIndex => {
          let fromGroup = lodash.find(newDataImages, { qrCode });
          let fromImgIndex = imgIndex;
          let toGroup = newDataImages[0];
          let toImgIndex = toGroup.images.length;
          if (fromGroup.qrCode === toGroup.qrCode) return;
          moveImageFromGroupToGroup(fromGroup, fromImgIndex, toGroup, toImgIndex);
        })
      })
      .value()

    // --- remove bulk selection
    newDataImages = removeBulkSelection(newDataImages)

    setDataImages(newDataImages);
    dataBulkSelection.current = []
    setMode(null)
  }

  const bulkMoveToOrder = () => {
    let newDataAddToOrder = { ...dataMoveToOrder, imagesData: dataBulkSelection.current }
    setDataMoveToOrder(newDataAddToOrder)
  }

  const moveImageFromGroupToGroup = (
    fromGroup,
    fromImgIndex,
    toGroup,
    toImgIndex
  ) => {
    const imgData = fromGroup.images[fromImgIndex];

    if (!toGroup.isOrderIdValid) {
      imgData.primary = false;
      imgData.selected = false;
    }

    // --- remove primary flag if primary image already present in target group
    if (imgData.primary) {
      let doesPrimaryImgAlreadyExist = lodash.some(
        toGroup.images,
        (i) => i.primary
      );
      if (doesPrimaryImgAlreadyExist) imgData.primary = false;
    }

    fromGroup.images.splice(fromImgIndex, 1);
    toGroup.images.splice(toImgIndex, 0, imgData);
  };

  // --- prepare order related info
  const prepareOrderRelatedInfo = (qrCode, orderData) => {
    // --- preare info data
    // eg. [{ level: "order", infoData: [{key: 'name', value: 'abc}] }]
    let infoData = {};
    lodash
      .chain(orderData)
      .get('fields.data')
      .each((field) => {
        let valueStr;
        switch (field.type) {
          case 'checkbox':
            valueStr = field.value === true ? 'Yes' : 'No';
            break;

          case 'children':
            if (lodash.isObject(field.value))
              valueStr = lodash
                .chain(field.value)
                .map((child) => {
                  let value = child.first_name;
                  if (value) value += ' ';
                  value += child.last_name;
                  if (value) value += ', ';
                  if (
                    child.age !== null &&
                    child.age !== undefined &&
                    child.age !== ''
                  )
                    value += `Age ${child.age}`;
                  return value;
                })
                .join(' | ')
                .value();
            valueStr = field.value || 'N/A';
            break;

          default:
            valueStr = field.value;
            break;
        }

        let level = field.level;
        if (!infoData[level]) infoData[level] = [];
        infoData[level].push({ key: field.ask, value: valueStr });
      })
      .value();
    infoData = lodash.map(infoData, (data, level) => {
      return { level, infoData: data };
    });

    // --- prepare primary info data string
    let firstInfoDataItem = lodash.nth(infoData, 0);
    let primaryInfoStr = lodash
      .chain(firstInfoDataItem?.infoData)
      .map((i) => `${i.key}: ${i.value}`)
      .join(', ')
      .value();

    return { infoData, primaryInfoStr, isOrderIdValid: isOrderIdValid(qrCode) };
  };

  // --- helper function to generate item for dataImages
  const createNewImgItem = (
    qrCode,
    qrImgFile,
    qrImgObjectURL,
    qrThumbnailObjURL
  ) => {
    let orderData = lodash.get(dataMarketOrdersById, qrCode);
    let orderRelatedInfo = prepareOrderRelatedInfo(qrCode, orderData);

    return {
      ...orderRelatedInfo,
      qrCode,
      qrImgFile,
      qrImgObjectURL,
      qrThumbnailObjURL,
      images: []
    };
  };

  const renderUploadResults = () => {
    if (!dataUploadResults) return null;
    const allSuccess = lodash.every(dataUploadResults, { status: 'success' });
    const errors = lodash
      .chain(dataUploadResults)
      .filter({ status: 'error' })
      .map(result => {
        let error = result.error;

        let errMsg;
        if (error instanceof Error) errMsg = error.message;
        else if (lodash.isObject(error)) 
          errMsg = lodash.chain(error).map((value, key) => `${key}: ${value}`).join(', ').value()

        return `${result.qrCode}: ${errMsg}`;
      })
      .value()

    return (
      <Modal
        className={'upload-results-modal'}
        handleClose={() => setDataUploadResults(null)}
      >
        <div>
          <h2 className="text-base font-bold">Upload Results</h2>

          <div className="results-container mt-4">
            {allSuccess && (
              <p>All images are uploaded successfully</p>
            )}

            {!allSuccess && (
              <>
                <p>We have faced following errors while uploading given groups. Other data is uploaded successfully.</p>
                <ul className='mt-2 pl-8 list-disc'>
                  {errors.map((errMsg, index) => {
                    return (
                      <li key={index}><p>{errMsg}</p></li>
                    );
                  }
                  )}
                </ul>
              </>
            )}
          </div>

          <button className='mt-4' onClick={() => setDataUploadResults(null)}>Close</button>
        </div>
      </Modal>
    )
  }

  const renderImagesGroups = (data) => {
    if (!data) return null;

    return (
      <div className="images-groups-container">
        {modalImg && (
          <Modal
            className={'img-preview-modal'}
            handleClose={() => setModalImg(null)}
          >
            <img src={modalImg} alt="Image" className="modal-img" />
          </Modal>
        )}

        {dataMoveToOrder && (
          <Modal
            className={'add-to-order-modal'}
            handleClose={() => {
              setDataMoveToOrderErrors(null);
              setDataMoveToOrder(null);
            }}
          >
            <div className="add-to-order-view-container">
              <h2 className="text-base font-bold">Move Image(s) to order</h2>
              <FormComponent
                fields={[
                  { type: 'input-text', name: 'order_id', label: 'Order ID' }
                ]}
                onChange={(data) => {
                  setDataMoveToOrder({ ...dataMoveToOrder, formData: data });
                }}
                formErrors={dataMoveToOrderErrors}
              />

              <button
                onClick={() => moveImageItemsToOrder()}
                disabled={!dataMoveToOrder.formData?.order_id}
              >
                Move to order
              </button>
            </div>
          </Modal>
        )}

        {dataImgGroupInfo && (
          <Modal
            className={'img-group-info-container'}
            handleClose={() => setDataImgGroupInfo(null)}
          >
            <>
              <h2 className="text-base font-bold">More info</h2>
              {dataImgGroupInfo.map((item) => {
                return (
                  <div key={`${item.level}`} className="group-container">
                    <div className="info-rows-container">
                      {item.infoData.map((i, index) => {
                        return (
                          <div key={`${item.level}_${index}`}>
                            <p className="m-0 text-gray-500 font-medium">
                              {i.key}
                            </p>
                            <p className="m-0">{i.value}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          </Modal>
        )}

        {lodash.map(data, (groupData, index) => {
          let selectedImgCount = lodash.filter(
            groupData.images,
            (i) => i.selected
          ).length;
          let totalImgCount = groupData.images.length;
          const isUnassignedGroup = groupData.qrCode === 'Unassigned Images';

          return (
            <Accordion
              key={index}
              open={dataShowImages}
              className={`accordion ${isUnassignedGroup ? 'unassigned-row' : ''}`}
            >
              <div
                className={`title-section ${
                  selectedImgCount == 0 ? 'unselected' : ''
                }`}
              >
                <div className="title-row-1">
                  <p className="flex-1">
                    <i
                      className={`bi ${
                        selectedImgCount > 0 ? 'bi-check-square' : 'bi-square'
                      } mr-2 ${!groupData.isOrderIdValid ? 'disabled' : ''}`}
                      onClick={(e) => {
                        if (!groupData.isOrderIdValid) return;
                        e.stopPropagation();
                        toggleGroupSelection(index);
                      }}
                    ></i>
                    {groupData.qrCode}{' '}
                    {!isUnassignedGroup && !groupData.isOrderIdValid && (
                      <span className="text-error">(Invalid order id)</span>
                    )}
                  </p>
                  <p className="py-1">
                    <i className="bi bi-image mr-2"></i>
                    {selectedImgCount} / {totalImgCount}
                  </p>
                </div>

                <div className="title-row-2 gap-4">
                  {groupData.primaryInfoStr && (
                    <>
                      <p className="flex-1 primary-info">
                        {groupData.primaryInfoStr}
                      </p>
                      <p
                        onClick={(e) => {
                          e.stopPropagation();
                          setDataImgGroupInfo(groupData.infoData);
                        }}
                      >
                        <i className="bi bi-info-circle"></i>
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="details-section">
                <div className="images-container">
                  {/* Empty view */}
                  {groupData.images.length === 0 && (
                    <div className="empty-view">
                      <p className="text-base">
                        No images found available in this group
                      </p>
                    </div>
                  )}

                  {groupData.images.map((imgData, imgIndex) => {
                    return (
                      <div
                        key={`${index}_${imgIndex}`}
                        className={`image-item-container ${
                          imgData.selected ? null : 'unselected'
                        }`}
                        draggable={mode != 'bulk'}
                        data-qr-code={groupData.qrCode}
                        data-img-index={imgIndex}
                        onDragStart={onDragStart}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDragEnd={onDragEnd}
                      >
                        <input title={'select/unselect for bulk action'} className='self-end'
                          type='checkbox' value={!!imgData.isSelectedForBulkAction} checked={!!imgData.isSelectedForBulkAction}
                          onChange={() => {toggleBulkSelection(groupData.qrCode, imgIndex)}}/>
                        <img
                          src={imgData.thumbnailObjURL}
                          alt="img"
                          draggable={false}
                          onClick={() => setModalImg(imgData.imgObjectURL)}
                        />
                        <p>{imgData.file?.name}</p>

                        {/* image actions */}
                        <div className="actions">
                          {/* include/exclude */}
                          {!isUnassignedGroup && 
                            <button
                              title="Include/Exclude Image"
                              onClick={() => toggleImgSelection(groupData.qrCode, imgIndex)}
                              disabled={!groupData.isOrderIdValid}
                            >
                              <i
                                className={`bi ${
                                  imgData.selected
                                    ? 'bi-check-square'
                                    : 'bi-square'
                                }`}
                              ></i>
                            </button>}

                          {/* Primary image toggle */}
                          {!isUnassignedGroup && 
                            <button
                              disabled={!imgData.selected}
                              title="Primary Image"
                              onClick={() => togglePrimary(groupData.qrCode, imgIndex)}
                            >
                              <i
                                className={`bi ${
                                  imgData.primary ? 'bi-star-fill' : 'bi-star'
                                }`}
                              ></i>
                            </button>}

                          {/* add to other order toggle */}
                          {isUnassignedGroup && (
                            <button
                              disabled={mode === 'bulk'}
                              title="Add to order"
                              onClick={() => {
                                setDataMoveToOrder({imagesData: [{ qrCode: groupData.qrCode, imgIndex }]});
                              }}
                            >
                              <i className={`bi bi-plus-square`}></i>
                            </button>
                          )}

                          {/* move to Unassigned group */}
                          {groupData.qrCode !== 'Unassigned Images' && (
                            <button
                              disabled={!imgData.selected || mode === 'bulk'}
                              title="Move to Unassigned Images"
                              onClick={() => moveImageToUnassignedGroup(groupData.qrCode, imgIndex)}
                            >
                              <i className={`bi bi-escape`}></i>
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  {/* empty space coverage */}
                  <div
                    className="flex-1 blank-area"
                    data-qr-code={groupData.qrCode}
                    data-img-index={groupData.images.length || 0}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                  ></div>
                </div>
              </div>
            </Accordion>
          );
        })}
      </div>
    );
  };

  const renderOptions = () => {
    return (
      <>
        <div className="filters-container">
          <label className="font-bold">Filters:</label>
          <div className="flex-1"></div>
          <div className="flex items-center">
            <p className="min-w-max">Image Count</p>
            <InputComponent
              name="imgCount"
              config={{ classNames: 'ml-2' }}
              type="number"
              value={dataFilters?.imgCount || ''}
              events={{
                handleChange: (value) => {
                  setDataFilters({ ...dataFilters, imgCount: value });
                }
              }}
            />
          </div>
        </div>
        <div className="options-container">
          <div className="flex-1">
            <i className="bi bi-card-image mr-2"></i>
            <Switch
              value={dataShowImages}
              onChange={(value) => setDataShowImages(value)}
            />
          </div>

          <div className="flex flex-wrap gap-2">
            {/* selected bulk mode controls */}
            { mode === 'bulk' &&
              <>
                <button className='mr-2' onClick={() => bulkMoveToOrder()}>
                  Move to order
                </button>
                <button className='mr-2' onClick={() => bulkMoveToUnassignedGroup()}>
                  Move to unassigned images
                </button>
                <button onClick={() => exitBulkMode()}>
                  Exit bulk mode
                </button>
              </>
            }

            {/* non-selected bulk mode controls */}
            { mode !== 'bulk' &&
              <>
                <button className="mr-2" onClick={() => markAllFirstPrimary()}>
                  Mark first images as primary
                </button>
                <button onClick={() => markAllLastPrimary()}>
                  Mark last images as primary
                </button>
              </>
            }
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="workflow-container">
      <PageHeader title="Workflow 1" />
      <NoticeComponent location="Index" notices={notices} />

      <Form
        formData={data}
        onChange={setData}
        formErrors={errors}
        setFormErrors={setErrors}
        fields={formFields}
        displayErrorBanner={true}
      />

      {dataImages && filteredDataImages && (
        <div className="images-view-container">
          {renderOptions()}
          {renderImagesGroups(filteredDataImages)}
        </div>
      )}

      {renderUploadResults()}

      {dataImages && (
        <ControlBar handleSubmit={handleSubmit} submitButtonTitle={'Proceed'} />
      )}
    </div>
  );
};

export default Workflow1;
