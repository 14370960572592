// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icon-chevron-right.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/icon-chevron-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accordion-container {\n    border-bottom: 1px solid #eee;\n}\n\n.accordion-container .accordion-titlebar {\n    background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0% 50%;\n    padding-left: 15px;\n}\n\n.accordion-container.open .accordion-titlebar {\n    background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 0% 50%;\n}\n\n@media only screen and (min-width: 1024px)  {\n    .accordion-container {}\n}", "",{"version":3,"sources":["webpack://./src/components/Accordion/index.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,gFAAmF;IACnF,kBAAkB;AACtB;;AAEA;IACI,gFAAkF;AACtF;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".accordion-container {\n    border-bottom: 1px solid #eee;\n}\n\n.accordion-container .accordion-titlebar {\n    background: transparent url('../../assets/icon-chevron-right.svg') no-repeat 0% 50%;\n    padding-left: 15px;\n}\n\n.accordion-container.open .accordion-titlebar {\n    background: transparent url('../../assets/icon-chevron-down.svg') no-repeat 0% 50%;\n}\n\n@media only screen and (min-width: 1024px)  {\n    .accordion-container {}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
