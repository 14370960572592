import Snippet from './Snippet'

import './Snippets.css'

const Snippets = ({ options, target }) => {
    return (
        <div className="snippets-container">
            {options.map((item) => {
                return <Snippet target={target} {...item} key={`snippet-${item.title}`} />
            })}
        </div>
    )
}

export default Snippets