import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import { PlaceholderGenericSpinnerComponent } from '../../Integrated';

import './index.css';

const ImagePreview = ({ url, config }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            setIsImageLoaded(true);
            setImageDimensions({ width: img.width, height: img.height });
        };
    }, [url]);

    return (
        <div className="ui-c-ImagePreview">
            <div className="ui-preview">
                {isImageLoaded ? (
                    <img src={url} alt="Preview" />
                ) : (
                    url ? <PlaceholderGenericSpinnerComponent /> : <div className="ui-imageMessage">Add image to preview</div>
                )}
            </div>
            {config.note && (
                <div className="ui-imageDimensions">
                    {config.note}
                </div>
            )}
        </div>
    );
};

ImagePreview.propTypes = {
    url: PropTypes.string,

    config: PropTypes.shape({
        dimensions: PropTypes.string
    })
}

export { ImagePreview };
