import {useState, useEffect, useRef} from 'react'

import './SelectAutocomplete.css'

import UtilsHelper from '../helpers/utils'

const SelectAutocomplete = ({ suggestions = [], onSelect, disableAll = false, initialTitle = '--', cacheValue = false, cacheNamespace = null }) => {
    const [selectedOption, setSelectedOption] = useState()
    const [term, setTerm] = useState('')
    const [showAutocomplete, setShowAutocomplete] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
      const cachedValue = UtilsHelper.getLocalStorage(`SelectAutocomplete_${cacheNamespace}`, true)

      if (cacheValue && cacheNamespace && cachedValue) {
        selectOption(cachedValue.value, cachedValue.title)
      } else if (disableAll) {
        selectOption(null, initialTitle)
      } else {
        selectOption('All', 'All')
      }
    }, [])

    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target) && !e.target.classList.contains('select-autocomplete-dropdown-input')) {
                setShowAutocomplete(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [ref])

    const selectOption = (value, title, item) => {
        const option = {
            value: value,
            title: title
        }

        setSelectedOption(option)
        UtilsHelper.setLocalStorage(`SelectAutocomplete_${cacheNamespace}`, option, true)
        hideAutocomplete()

        if (selectedOption !== null) {
          onSelect(option, item)
        }
    }

    const hideAutocomplete = () => {
        setTerm('')
        setShowAutocomplete(false)
    }

    const renderAutocomplete = () => {
        if (!showAutocomplete) {
            return null
        }

        return (
            <div className="select-autocomplete-dropdown">
                <input type="text" className="select-autocomplete-dropdown-input" value={term} onChange={(e) => setTerm(e.target.value)} placeholder="Search..." />
                {renderSuggestions()}
            </div>
        )
    }

    const renderSuggestions = () => {
        let matches = suggestions

        if (term !== '') {
            matches = []

            suggestions.forEach((suggestion) => {
                const filteredOptions = suggestion.options.filter((option) => {
                    return option.title.toLowerCase().indexOf(term.toLowerCase()) > -1
                })

                if (filteredOptions.length > 0) {
                    matches.push({
                        group: suggestion.group,
                        options: filteredOptions
                    })
                }
            })
        }

        return (
            <div className="select-autocomplete-suggestions" ref={ref}>
                {!disableAll && (<div className="select-autocomplete-option" onClick={() => selectOption('All', 'All')}>All</div>) || null}
                {matches.map((suggestion) => {
                    return (
                        <div key={`select-autocomplete-group-${suggestion.group}`}>
                            <div className="select-autocomplete-group-title">{suggestion.group}</div>
                            {suggestion.options.map((option) => {
                                return (
                                    <div className="select-autocomplete-option" key={`select-autocomplete-suggestion-${suggestion.group}-${option.value}`} onClick={() => selectOption(option.value, option.title, option.item)}>{option.title}</div>
                                )
                            })}
                        </div>
                    )
                }) || null}
            </div>
        )
    }

    if (!selectedOption) {
      return null
    } else {
      return (
          <div className="select-autocomplete-container">
              <div className="select-autocomplete-title" onClick={() => setShowAutocomplete(!showAutocomplete)}>{selectedOption.title}</div>
              {renderAutocomplete()}
          </div>
      )
    }
}

export default SelectAutocomplete
