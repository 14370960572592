import {useState, useEffect} from 'react'

import './InputAutocomplete.css'

const InputAutocomplete = ({ suggestions = [], onChange, value = '', readOnly }) => {
    const [term, setTerm] = useState(value)
    const [showAutocomplete, setShowAutocomplete] = useState(true)

    const handleReturn = (e) => {
        if (e.keyCode !== 13 || term === '') {
            return
        }

        updateSuggestion(e.target.value)
    }

    const updateSuggestion = (term, hideAutoComplete = true) => {
        setTerm(term)
        onChange(term)

        if (hideAutoComplete) {
            setShowAutocomplete(false)
        }
    }

    const renderSuggestions = () => {
        if (!showAutocomplete || term === '') {
            return null
        }

        const matches = suggestions.filter((suggestion) => {
            return suggestion.title.toLowerCase().indexOf(term.toLowerCase()) > -1
        })

        if (matches.length === 0) {
            return null
        }

        return (
            <ul className="input-autocomplete-suggestions">
                {matches.map((suggestion) => {
                    return (
                        <li key={`input-autocomplete-suggestion-${suggestion.value}`} onClick={() => updateSuggestion(suggestion.value)}>{suggestion.value}</li>
                    )
                })}
            </ul>
        )
    }

    return (
        <div className="input-autocomplete-container">
            <input
                type="text"
                value={term}
                onChange={(e) => {
                    setShowAutocomplete(true)
                    updateSuggestion(e.target.value, false)
                }}
                onKeyDown={handleReturn}
                readOnly={readOnly} />
            {renderSuggestions()}
        </div>
    )
}

export default InputAutocomplete
