import {useState, useEffect} from 'react'

import {Link} from 'react-router-dom'

import dayjs from 'dayjs'

import './index.css'

const PageFooter = () => {
    return (
        <div className="page-footer-container">
            <ul className="page-footer-legal">
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/terms-of-use">Terms of Use</Link></li>
            </ul>
            <div className="page-footer-copyright">
                &copy; {dayjs().year()} TP2 Software Inc.
            </div>
        </div>
    )
}

export default PageFooter