import { DomainHelper } from './domain'
import UtilsHelper from './utils'

const fetchHelper = async (options = {}, onSuccess = () => {}, onError = () => {}, onControllerReady = (controller) => {}) => {
    let baseUrl = DomainHelper.getBaseUrl()

    if (options.relativePath === false) {
        baseUrl = ''
    }

    const controller = new AbortController()
    if (controller) 
        onControllerReady({
            ...controller,
            abort: (reason) => { controller.abort(reason) }
        });

    let opts = {
        method: options.method || 'GET',
        credentials: 'include',
        headers: {
            ...options.headers,
            Accept: 'application/json'
        },
        signal: controller.signal
    }

    if (!opts.headers['Content-Type']) {
        opts.headers['Content-Type'] = 'application/json'
    }

    if (opts.method !== 'GET') {
        opts.body = options.body ? JSON.stringify(options.body) : ''
    }

    options.url = options.url.replace(baseUrl, '')

    // --- fetch blob data
    const fetchBlob = async () => {
        let response = await fetch(`${options.url}`, opts)
        if (!response.ok) throw response;

        UtilsHelper.handleHeaderEvents(response)

        if (!options.filename) {
            const header = response.headers.get('Content-Disposition');
            const parts = header && header.split(';') || [];
            options.filename = parts[1].split('=')[1].replace(/"/g, '')
        }
        
        let blob = await response.blob()
        UtilsHelper.downloadBlob(blob, options.filename)
    }

    // --- fetch json data
    const fetchJSON = async () => {
        let response = await fetch(`${options.url}`, opts)
        const data = await response.text()
        const body = data ? JSON.parse(data) : {}
        const status = response.status
        const res = { body, status }

        UtilsHelper.handleHeaderEvents(response)

        if (!response.ok) throw res;
        return res;
    }

    let fetchOperation = options.blob ? fetchBlob : fetchJSON;

    try {
        let response = await fetchOperation();
        onSuccess(response);
        return response;
    } catch (error) {
        if (error && error.status === 401) 
            UtilsHelper.handleUnauthorizedError();
        
        onError(error);
        return error;
    }
}

export default fetchHelper
