import {useState, useEffect} from 'react'

import { UI } from '../../UI'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import './index.css'

const ProfilePage = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Profile')
    
    // FORM
    const [errors, setErrors] = useState()

    // DATA
    const [data, setData] = useState({
        login: ac.user.login,
        first_name: ac.user.first_name,
        last_name: ac.user.last_name,
        email: ac.user.email
    })

    const [dataMain, setDataMain] = useState()

    const handleSubmit = () => {
        ac.showSpinner()

        let body = {...dataMain}

        FetchHelper({
            url: `/api/v2/admin/profile`,
            method: 'put',
            body: {
                profile: body
            }
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    return (
        <>
            <PageHeaderComponent title={`Profile`} />

            <UI.Layout name="profile" type="page">
                <NoticeComponent location="Main" notices={notices} />

                {data ? (
                    <div className={`page-form-container`}>
                        <FormComponent formData={data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[
                            {
                                type: 'input-text',
                                name: 'login',
                                label: 'Login',
                                readonly: true,
                                label_info: 'Your login cannot be changed'
                            },
                            {
                                type: 'input-text',
                                name: 'first_name',
                                label: 'First Name'
                            },
                            {
                                type: 'input-text',
                                name: 'last_name',
                                label: 'Last Name'
                            },
                            {
                                type: 'input-text',
                                name: 'email',
                                label: 'Email'
                            },
                            {
                                type: 'input-password',
                                name: 'password',
                                label: 'Password'
                            },
                            {
                                type: 'input-password',
                                name: 'password_confirmation',
                                label: 'Password Confirmation'
                            }
                        ]} />
                    </div>
                ) : null}

                <ControlBar data={data} handleSubmit={handleSubmit} cancelUrl="/" enableCopy={false} enableDelete={false} />
            </UI.Layout>
        </>
    )
}

export default ProfilePage