import { cloneElement, forwardRef, useState, useEffect } from 'react'

const PlaceholderWrapper = ({ children, name, dependencies = [], placeholder, config={}, callbacks={} }) => {
    const [showPlaceholder, setShowPlaceholder] = useState(true)

    useEffect(() => {
        if (dependencies.every(Boolean) && showPlaceholder)
            setShowPlaceholder(false)
    }, dependencies);

    const handleAnimationStart = (event) => {
        if (callbacks.handleAnimationStart)
            callbacks.handleAnimationStart(event)
    }

    const handleAnimationEnd = (event) => {
        if (callbacks.handleAnimationEnd)
            callbacks.handleAnimationEnd(event)
    }

    const getDecoratedEventsDOM = () => {
        return {
            onAnimationStart: handleAnimationStart,
            onAnimationEnd: handleAnimationEnd
        }
    }

    if (showPlaceholder && placeholder) {
        return (
            <div className={`ui-container--placeholderWrapper ${config.classNames || ''}`} {...getDecoratedEventsDOM()}>
                {placeholder({ name, config })}
            </div>
        )
    } else {
        return children
    }
}

export {
    PlaceholderWrapper
}