import {useState, useContext, useEffect, isValidElement} from 'react'
import {Link} from 'react-router-dom'


import PaginationComponent from '../components/Pagination'
import DropdownComponent from '../components/Dropdown'
import ConfirmComponent from '../components/Confirm'

import useAppContext from '../hooks/useAppContext'

import IconEllipsis from '../assets/icon-ellipsis.svg'
import lodash from 'lodash'

import './List.css'

const ListComponent = ({ list = {}, setPage, baseUrl='/', baseApiUrl, columns, showActions = true, customActions, getAdditionalActions, afterColumnTitleClick, displayNameOnSeparateLine=false, copyEnabled = true, deleteEnabled = true, additionalDropdownOptions = [] }) => {
    const [ac] = useAppContext()

    const [itemToDelete, setItemToDelete] = useState()
    const [itemToCopy, setItemToCopy] = useState()

    const mounted = list.hasOwnProperty('total')

    const handleColumnTitleClick = () => {
        // afterColumnTitleClick()
    }

    const hasResults = () => {
        return list?.data?.length > 0
    }

    const renderPagination = () => {
        return <PaginationComponent total={list.total} currentPage={list.current_page} totalPages={list.total_pages} setPage={setPage} />
    }

    const renderNoResults = () => {
        if (mounted && !hasResults()) {
            return (
                <div className="list-no-results">
                    No results
                </div>
            )
        } else {
            return null
        }
    }

    const renderColumnTitles = () => {
        if (!hasResults()) {
            return null
        }

        return (
            <div className="list-column-titles">
                {columns.map((column) => {
                    if (displayNameOnSeparateLine && column.key === 'ui_name') {
                        return
                    }
                    
                    return <div onClick={handleColumnTitleClick} className={`list-column-title ${column.key} ${column.className ?? ''} ${column.sortable ? 'action-sortable' : ''}`} key={`list-column-title-${column.key}`}>{column.title}</div>
                })}
            </div>
        )
    }

    const renderDropdownOptions = (item, row, additionalActions) => {
        let options = [
            {
                title: 'Edit',
                type: 'route',
                url: `${baseUrl}/${row.id}/edit`
            }
        ]

        if (deleteEnabled)
            options.push({
                title: 'Delete',
                type: 'callback',
                callback: () => setItemToDelete(item)
            })

        if (copyEnabled) {
            options.splice(1, 0, {
                title: 'Copy',
                type: 'callback',
                callback: () => setItemToCopy(item)
            })
        }

        if(additionalActions)
            options = [...additionalActions, ...options];

        if (additionalDropdownOptions.length > 0) {
            options = additionalDropdownOptions.concat(options)
        }

        return <DropdownComponent title="Actions" options={options} item={item} isListView={true} />
    }

    const extractUniqKeyAndFieldValue = (column, row, rowIndex, colIndex) => {
        let uniqueKey = `${column.key}-${rowIndex}-${colIndex}`

        let fieldValue = row[column.key]
        if(fieldValue && typeof fieldValue === 'object' && !isValidElement(fieldValue)) {
            fieldValue = fieldValue.name || lodash.toString(fieldValue)
        }
        
        return {uniqueKey, fieldValue}
    }

    const renderList = () => {
        if (hasResults()) {
            return (
                <div className="list-rows">
                    {list.data.map((item, rowIndex) => {
                        let row = {...item}

                        return (
                            <div className="list-row" key={row.id} data-id={row.id}>
                                {displayNameOnSeparateLine && columns.filter(column => column.key === 'ui_name').map((column, colIndex) => {
                                    let { uniqueKey, fieldValue } = extractUniqKeyAndFieldValue(column, row, rowIndex, colIndex)
                                    return (
                                        <div className={`list-row-column separated-row ${column.key}`} key={uniqueKey}>
                                            <Link to={row[column.route]}>{fieldValue}</Link>
                                        </div>
                                    )
                                })}

                                <div className="list-row-main">
                                    <div className="list-row-main-columns">
                                        {columns.map((column, colIndex) => {
                                            let { uniqueKey, fieldValue } = extractUniqKeyAndFieldValue(column, row, rowIndex, colIndex)

                                            if (displayNameOnSeparateLine && column.key === 'ui_name') {
                                                return
                                            }

                                            if (column.type === 'url') {
                                                return (
                                                    <div className={`list-row-column ${column.key}`} key={uniqueKey}>
                                                        <a href={row[column.url]}>{fieldValue}</a>
                                                    </div>
                                                )
                                            }

                                            if (column.type === 'url_route') {
                                                return (
                                                    <div className={`list-row-column ${column.key}`} key={uniqueKey}>
                                                        <Link to={row[column.route]}>{fieldValue}</Link>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className={`list-row-column ${column.key} ${column.className ?? ''}`} key={uniqueKey}>{fieldValue}</div>
                                            )
                                        })}
                                    </div>

                                    {customActions ? customActions(item) : null}

                                    {!customActions && showActions && renderDropdownOptions(item, row, getAdditionalActions ? getAdditionalActions(item) : []) || null}
                                </div>

                                <div className="list-row-details">
                                    {columns.map((column, colIndex) => {
                                        let { uniqueKey, fieldValue } = extractUniqKeyAndFieldValue(column, row, rowIndex, colIndex)
                                        const renderRow = () => {
                                            if (displayNameOnSeparateLine && column.key === 'ui_name') {
                                                return
                                            }

                                            if (column.type === 'url') {
                                                return (
                                                    <div className={`list-row-column ${column.key}`} key={uniqueKey}>
                                                        <a href={row[column.url]}>{fieldValue}</a>
                                                    </div>
                                                )
                                            }

                                            if (column.type === 'url_route') {
                                                return (
                                                    <div className={`list-row-column ${column.key}`} key={uniqueKey}>
                                                        <Link to={row[column.route]}>{fieldValue}</Link>
                                                    </div>
                                                )
                                            }

                                            return (
                                                <div className={`list-row-column ${column.key} ${column.className ?? ''}`} key={uniqueKey}>{fieldValue}</div>
                                            )
                                        }

                                        return (
                                            <div className="list-row-details-row" key={`list-row-details-row-${column.key}`}>
                                                {!column.hideTitleOnMobile ? <span className="list-row-details-key">{column.title}</span> : null} <span className={`list-row-details-content-value ${column.key}`} key={`list-row-details-${uniqueKey}`}>
                                                   {renderRow()}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        } else {
            return renderNoResults()
        }
    }

    const showPagination = hasResults()

    return (
        mounted ?
            <div className="list-container">
                {showPagination ? renderPagination() : null}
                {renderColumnTitles()}
                {renderList()}
                {showPagination ? renderPagination() : null}
                {itemToDelete && <ConfirmComponent apiUrl={`${baseApiUrl}/${itemToDelete.id}`} method='DELETE' title={`Delete the following?`} entityName={itemToDelete.name} cancel={() => setItemToDelete()} />}
                {itemToCopy && <ConfirmComponent apiUrl={`${baseApiUrl}/${itemToCopy.id}/copy`} method='GET' title={`Copy the following?`} entityName={itemToCopy.name} cancel={() => setItemToCopy()} />}
            </div>
        : null
    )
}

export default ListComponent