import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useFetch from '../../hooks/useFetch'
import useNotices from '../../hooks/useNotices'
import { useParams } from 'react-router-dom'

const AppointmentSlotsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const { FetchHelper } = useFetch()

    const [NoticeComponent, notices] = useNotices('Scheduling/AppointmentSlots/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()

    const handleSubmit = () => {
        let startHour = parseInt(dataMain.starts_at.split(':')[0])
        let endHour = parseInt(dataMain.ends_at.split(':')[0])

        if (endHour < startHour) {
          if (!window.confirm('End time less than start time. Continue?')) {
            return
          }
        }

        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/appointment-slots/${data.id}`,
            method: 'PUT',
            body: {
                appointment_slot: dataMain
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/appointment-slots')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/appointment-slots/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    return (
        <div className="appointment-slots-edit-page-container page-container">
            <PageHeaderComponent title="Edit Appointment Slot" />
            <NoticeComponent location="Main" notices={notices} />
            {data ? 
                <>
                    <FormComponent formData={data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                        type: 'input-text',
                        name: 'name',
                        label: 'Name'
                    },{
                        type: 'input-text',
                        name: 'display_name',
                        label: 'Display Name'
                    },{
                        type: 'input-text',
                        name: 'capacity',
                        label: 'Capacity'
                    },{
                        type: 'time',
                        name: 'starts_at',
                        label: 'Start Time'
                    },{
                        type: 'time',
                        name: 'ends_at',
                        label: 'End Time'
                    },{
                        type: 'input-money',
                        name: 'fee',
                        label: 'Fee'
                    }]} />

                    <ControlBar baseApiUrl="/appointment-slots" redirectUrl="/appointment-slots" cancelUrl="/appointment-slots" data={data} handleSubmit={handleSubmit} />
                </>
            : null}
        </div>
    )
}

export default AppointmentSlotsEdit
