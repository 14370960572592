import {useState, useEffect, useContext} from 'react'

import {AppContext} from '../../AppContext'
import {Link} from 'react-router-dom'

import PageHeaderComponent from '../../components/PageHeader'
import PaginationComponent from '../../components/Pagination'
import SelectPaginated from '../../components/SelectPaginated'
import SwitchComponent from '../../components/Switch'
import Modal from '../../components/Modal'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import APIHelper from '../../helpers/api'
import PickerDate from '../../components/PickerDate'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import lodash from 'lodash'

import './index.css'
import dayjs from 'dayjs'

const BulkProcess = () => {
  const [ac] = useAppContext()

  const [NoticeComponent, notices] = useNotices('Orders/BulkProcess/Index')

  const [errors, setErrors] = useState([])
  const [fetching, setFetching] = useState(false)
  const [data, setData] = useState()
  const [page, setPage] = useState(1)
  const [allSelection, setAllSelection] = useState(false)
  const [markets, setMarkets] = useState()
  const [selectedMarket, setSelectedMarket] = useState('All')
  const [selectedMarketGroup, setSelectedMarketGroup] = useState('All')
  const [selectedLocationGroup, setSelectedLocationGroup] = useState('All')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [onlyLateOrders, setOnlyLateOrders] = useState(false)
  const [onlyProofOrders, setOnlyProofOrders] = useState(false)
  const [fetchTimeout, setFetchTimeout] = useState()
  const [fetchCount, setFetchCount] = useState(0)

  useEffect(() => {
      setStartDate(UtilsHelper.getLocalStorage('BulkProcess_startDate') || dayjs().subtract(3, 'month').format('YYYY-MM-DD'))
      setEndDate(UtilsHelper.getLocalStorage('BulkProcess_endDate') || dayjs().format('YYYY-MM-DD'))
  }, [])

  useEffect(() => {
    clearTimeout(fetchTimeout)

    if (startDate && endDate && selectedMarket) {
      ac.showSpinner(true)
      setFetching(true)

      setFetchTimeout(setTimeout(() => {
        FetchHelper({
              url: `/api/v2/admin/orders?start_date=${startDate}&end_date=${endDate}&page=${page}&metric=unprocessed${selectedMarket !== 'All' ? `&market_id=${selectedMarket}` : ''}${selectedMarketGroup !== 'All' ? `&market_group_id=${selectedMarketGroup}` : ''}${selectedLocationGroup !== 'All' ? `&location_group_id=${selectedLocationGroup}` : ''}${onlyLateOrders ? '&late=1' : ''}${onlyProofOrders ? '&proofs=1' : ''}`
          }, (res) => {
              ac.showSpinner(false)
              setFetching(false)
              setData(res.body)
          }, (res) => {
              ac.showSpinner(false)
              setFetching(false)
          })
      }, 500))
    }
  }, [startDate, endDate, selectedMarket, selectedMarketGroup, selectedLocationGroup, onlyLateOrders, onlyProofOrders, page])

  const getIds = () => {
    let ids = []

    data.data.forEach((item) => {
      if (item.ui_selected) {
        ids.push(item.id)
      }
    })

    return ids
  }

  const handleProcess = ({ download = false }) => {
    ac.showSpinner(true)
    setFetching(true)

    FetchHelper({
          url: `/api/v2/admin/orders/bulk`,
          method: 'put',
          body: {
            orders: getIds()
          }
      }, (res) => {
          ac.showSpinner(false)
          setFetching(false)

          const ids = Object.keys(res.body)
          let errors = []
          let xmlIds = []

          data.data.forEach((item, i) => {
            let index = ids.indexOf(item.id)

            if (index > -1) {
              if (res.body[item.id]) {
                xmlIds.push(item.id)
                item.ui_processed = true
              } else {
                item.ui_processed_error = true
              }
            }
          })

          if (download) {
            handleProcessXML(xmlIds)
          }

          setData(data)
          setErrors(errors)
          handleProcessed()
      }, (res) => {
          ac.showSpinner(false)
          setFetching(false)
      })
  }

  const handleProcessXML = (ids) => {
    ac.showSpinner(true)
    setFetching(true)

    FetchHelper({
      url: `/api/v2/admin/downloads/drums.xml?dl=1`,
      method: 'post',
      blob: true,
      filename: "bulk-process-drums.xml",
      body: {
        orders: ids
      }
    }, (res) => {
      ac.showSpinner(false)
      setFetching(false)
    }, (res) => {
      ac.showSpinner(false)
      setFetching(false)
    })
  }

  const handleSelection = (e, id) => {
    let newData = data.data.map((item) => {
      if (item.id === id) {
        item.ui_selected = e.target.checked
      }

      return item
    })
    setData({ ...data, data: newData })

    let isAllSelected = lodash.every(newData, i => i.ui_selected)
    setAllSelection(isAllSelected)
  }

  const handleAllSelection = (e) => {
    const state = e.target.checked

    setData({
      ...data,
      data: data.data.map((item) => {
        item.ui_selected = state

        return item
      })
    })

    setAllSelection(state)
  }

  const handleProcessed = () => {
    setTimeout(() => {
      data.data.forEach((item, i) => {
        if (item.ui_processed && !item.ui_processed_error) {
          delete data.data[i]
        }
      })

      setData(data)
    }, 450)
  }

  const handleStartDate = (newDate) => {
    UtilsHelper.setLocalStorage('BulkProcess_startDate', newDate)

    setStartDate(newDate)
  }

  const handleEndDate = (newDate) => {
    UtilsHelper.setLocalStorage('BulkProcess_endDate', newDate)

    setEndDate(newDate)
  }

  const showOrders = () => {
    return data && data.data && data.data.length > 0
  }

  const renderPagination = () => {
      return showOrders() && (<div className="row"><PaginationComponent total={data.total} currentPage={data.current_page} totalPages={data.total_pages} setPage={setPage} /></div>) || null
  }

  return (
      <div className="bulk-process-page-container page-container">
          <PageHeaderComponent title="Bulk Process" />

          <NoticeComponent location="Main" notices={notices} />

          <div className="bulk-process-container">
            <div className="row dates">
              <div>
                  <label>Start Date</label>
                  <PickerDate initialValue={startDate} onChange={handleStartDate} dateFormat="YYYY-MM-DD" />
              </div>
              <div>
                  <label>End Date</label>
                  <PickerDate initialValue={endDate} onChange={handleEndDate} dateFormat="YYYY-MM-DD" />
              </div>
              <div>
                  <label>Late Only?</label>
                  <SwitchComponent value={onlyLateOrders} onChange={setOnlyLateOrders} />
              </div>
              <div>
                  <label>Proofs Only?</label>
                  <SwitchComponent value={onlyProofOrders} onChange={setOnlyProofOrders} />
              </div>
            </div>

            <div className="row markets">
              <label>Market</label>
              <SelectPaginated apiUrl="/api/v2/admin/markets" offerAllOption={true} allValue={'All'} onSelect={(option) => setSelectedMarket(option.value)} />
            </div>

            {selectedMarket === 'All' ? (
              <>
                <div className="row markets-groups">
                  <label>Market Group</label>
                  <SelectPaginated apiUrl="/api/v2/admin/market-groups" offerAllOption={true} allValue={'All'} onSelect={(option) => setSelectedMarketGroup(option.value)} />
                </div>

                <div className="row location-groups">
                  <label>Location Group</label>
                  <SelectPaginated apiUrl="/api/v2/admin/location-groups" offerAllOption={true} allValue={'All'} onSelect={(option) => setSelectedLocationGroup(option.value)} />
                </div>
              </>
            ) : null}

            <div className={fetching ? 'disabled' : ''}>
              {renderPagination()}

              {showOrders() && (<div className="row orders">
                <ul>
                  <li className="bulk-checkbox-row header">
                    <div className="column-checkbox"><input type="checkbox" checked={allSelection} onChange={(e) => handleAllSelection(e)} /></div>
                    <div><span className="column-title">Order</span></div>
                    <div><span className="column-title">Purchased At</span></div>
                    <div><span className="column-title">Full Name</span></div>
                    <div><span className="column-title">Market</span></div>
                  </li>
                  {data.data.map((item, i) => {
                    let date = UtilsHelper.toDateString({ date: item.purchased_at, timezone: item.timezone })
                    let dateFull = UtilsHelper.toDateString({ date: item.purchased_at, timezone: item.timezone, displayWeekday: true, displayTime: true })

                    return (
                      <li key={`bulk-checkbox-${i}`} className={`bulk-checkbox-row ${item.ui_processed_error ? 'error' : ''} ${item.ui_processed ? 'processed' : ''}`}>
                        <div className="column-checkbox"><input type="checkbox" checked={item.ui_selected} onChange={(e) => handleSelection(e, item.id)} /></div>
                        <div><span className="column-title">Order</span> <Link to={`/order/${item.id}`}>{item.fastpass}</Link></div>
                        <div title={dateFull}><span className="column-title">Purchased At</span> {UtilsHelper.toDateString({ date: item.purchased_at, timezone: item.timezone, displayTime: true })}</div>
                        <div><span className="column-title">Full Name</span> {item.customer.full_name}</div>
                        <div><span className="column-title">Market</span> {item.market_name}</div>
                      </li>
                    )
                  })}
                </ul>
              </div>) || (<><div className="row orders"><h1>No Results</h1></div></>)}

              {renderPagination()}

              <div className="row controls">
                <button onClick={handleProcess} 
                  disabled={ !lodash.some(data?.data, i => i.ui_selected) }>Process All Checked</button>
                <button onClick={() => handleProcess({ download: true })}
                  disabled={ !lodash.some(data?.data, i => i.ui_selected) }>Process All Checked and Generate DRUMS XML</button>
              </div>
            </div>
          </div>
      </div>
  )
}

export default BulkProcess
