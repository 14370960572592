import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import DropdownTextCollectionComponent from '../../components/DropdownTextCollection'
import ErrorsComponent from '../../components/Errors'
import PageHeaderComponent from '../../components/PageHeader'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import './edit.css'
import TextTags from '../../components/TextTags'
import HTMLEditor from '../../components/HTMLEditor'
import { useParams } from 'react-router-dom'

const TemplatesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Templates/Edit')

    const [errors, setErrors] = useState()
    const [data, setData] = useState()
    const [dataName, setDataName] = useState()
    const [dataType, setDataType] = useState()
    const [dataBodyText, setDataBodyText] = useState('')
    const [dataBodyEmail, setDataBodyEmail] = useState('')

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            name: dataName,
            body: dataType === 'TextTemplate' ? dataBodyText : dataBodyEmail
        }

        FetchHelper({
            url: `/api/v2/admin/templates/${params.id}`,
            method: 'PUT',
            body: {
                template: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/templates')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/templates/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
            setDataName(res.body.name)
            setDataType(res.body.type)
            if (res.body.type === 'TextTemplate') setDataBodyText(res.body.body)
            else setDataBodyEmail(res.body.body)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    return (
        <div className="templates-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Template`} />

            <NoticeComponent location="Main" notices={notices} />

            {data && (
                <>
                    <div className="global-section">
                        <ErrorsComponent errors={errors} />

                        <div className="input-container main-input-name">
                            <h3 className="label">Name</h3>

                            <input type="text" name="name" value={dataName} onChange={(e) => setDataName(e.target.value)} />
                        </div>

                        <div className="input-container main-input-type">
                            <h3 className="label">Type</h3>

                            <select name='type' value={dataType} onChange={(e) => setDataType(e.target.value)} disabled>
                                <option key='none' value=''></option>
                                <option key='text' value='TextTemplate'>Text</option>
                                <option key='email' value='EmailTemplate'>Email</option>
                            </select>
                        </div>

                        { dataType &&
                            <div className="input-container main-input-body">
                                <h3 className="label">Body</h3>

                                { dataType === 'TextTemplate' &&
                                    <>
                                        <div className="text-substitutions">
                                            <TextTags onSelect={(value) => {
                                                // @TODO: insert at cursor
                                                setDataBodyText(`${dataBodyText} ${value}`)
                                            }} />
                                        </div>

                                        <div className="body">
                                            <textarea className="global-textarea" name="body" value={dataBodyText} onChange={(e) => setDataBodyText(e.target.value)}></textarea>
                                        </div>

                                        <p className="disclaimer">After adding Custom Campaign Field, you MUST change <span className="replace-text">REPLACEME</span> to the name of your desired data field.</p>
                                    </> }

                                { dataType === 'EmailTemplate' &&
                                    <HTMLEditor initialValue={dataBodyEmail} onChange={(value) => setDataBodyEmail(value)} 
                                        showTags={true}/>}
                            </div>
                        }
                    </div>

                    <ControlBar baseApiUrl="/templates" redirectUrl="/templates" cancelUrl="/templates" data={data} handleSubmit={handleSubmit} />
                </>
            )}
        </div>
    )
}

export default TemplatesEdit
