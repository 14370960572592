import {useEffect, useState} from 'react'

import './index.css'

const BPSSelector = ({ data=[], onSelect, onClose }) => {
  return (
    <div className="bps-selector-container">
      <div className="bps-selector-items">
        <ul>
          {data.map((e, i) => {
            return (
              <li onClick={() => onSelect(e)}>
                <div className="image" style={{ backgroundImage: `url(${e.image.image_url})` }}></div>
                <em>{e.code}</em>
              </li>
            )
          })}
        </ul>
      </div>
      <button onClick={onClose}>Cancel</button>
    </div>
  )
}

export default BPSSelector
