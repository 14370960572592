import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import TwoSidedMultiselectPaginated from '../../components/TwoSidedMultiselectPaginated'

import { PlaceholderGenericSpinnerComponent, SelectWithSearchModalComponent } from '../../componentsV2/Integrated'
import { ButtonComponent } from '../../componentsV2/Primitive'

import { Layout } from '../../componentsV2/Layouts'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useTabs from '../../hooks/useTabs'

// @TODO:
// Plug in APIs for Market IDs and Market Groups selects

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { useSimpleState } from '../../UI/hooks'

import ErrorsComponent from '../../components/Errors'

import './index.css'
import { useParams } from 'react-router-dom'

const UsersEdit = () => {
    const params = useParams()
    const {_state: _MarketSelectOptions} = useSimpleState({
        showModal: false,
        term: '',
        page: 1,
        syncing: false
    })

    const {_state: _apiMarkets} = useSimpleState()
    const {_state: _apiMarketGroups} = useSimpleState()

    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Users/Edit')

    const [tabs, tab, setTab, TabsComponent] = useTabs('Generic', ['Generic', 'Markets'])

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [formType, setFormType] = useState({
        type: 'StudioPersonnel'
    })

    const [dataMain, setDataMain] = useState()
    const [dataPersonnel, setDataPersonnel] = useState()
    const [dataViewer, setDataViewer] = useState()
    const [dataMarketGroupViewer, setDataMarketGroupViewer] = useState()
    const [dataManager, setDataManager] = useState()

    const [dataSelectedMarket, setDataSelectedMarket] = useState()
    const [dataSelectedMarkets, setDataSelectedMarkets] = useState()
    const [dataSelectedMarketGroups, setDataSelectedMarketGroups] = useState()

    const [suggestionsMarketIds, setSuggestionsMarketIds] = useState([])
    const [suggestionsMarketGroups, setSuggestionsMarketGroups] = useState([])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            user: Object.assign({
                type: formType.type
            }, dataMain)
        }

        if (formType.type === 'StudioPersonnel') {
            body.user = Object.assign(body.user, dataPersonnel)

            if (dataSelectedMarkets)
                body.user.market_ids = dataSelectedMarkets.data.map(item => item.id)
        } else if (formType.type === 'MarketViewer') {
            body.user = Object.assign(body.user, dataViewer)

            if (dataSelectedMarket)
                body.user.market_id = dataSelectedMarket.id
        } else if (formType.type === 'MarketGroupViewer') {
            body.user = Object.assign(body.user, dataMarketGroupViewer)

            if (dataSelectedMarketGroups)
                body.user.market_group_ids = dataSelectedMarketGroups.data.map(item => item.id)
        } else if (formType.type === 'StudioManager') {
            body.user = Object.assign(body.user, dataManager)
        }

        FetchHelper({
            url: `/api/v2/admin/users/${data.id}`,
            method: 'PUT',
            body: body
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/users')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/users/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)

            setFormType({
                type: res.body.type
            })

            if (res.body.type === 'MarketViewer' && res.body.markets.data[0]) {
                setDataSelectedMarket(res.body.markets.data[0])
            } else if (res.body.type === 'MarketGroupViewer' && res.body.market_groups) {
                setDataSelectedMarketGroups(res.body.market_groups)
            } else if (res.body.type === 'StudioPersonnel' && res.body.markets) {
                setDataSelectedMarkets(res.body.markets)
            }
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    useEffect(() => {
        _MarketSelectOptions.set('syncing', true)

        FetchHelper({
            url: `/api/v2/admin/markets?page=${_MarketSelectOptions.get('page')}&q=${_MarketSelectOptions.get('term')}`
        }, (res) => {
            _apiMarkets.set(res.body)
            _MarketSelectOptions.set('syncing', false)
        }, (res) => {
            _MarketSelectOptions.set('syncing', false)
        })
    }, [_MarketSelectOptions.get('term'), _MarketSelectOptions.get('page')])

    const getMarketName = () => {
        if (dataSelectedMarket?.name)
            return `${dataSelectedMarket.name} - ${dataSelectedMarket.type}`
    }
    
    return (
        <div className="ui-p-UsersEditPage ui-p">
            <PageHeaderComponent title={`Edit User ${(data?.full_name) ? `— ${data.full_name}` : ''}`} />

            {/* <TabsComponent tabs={tabs} tab={tab} setTab={setTab} /> */}

            {data && _apiMarkets.get() && tab === 'Generic' ? (
                <>
                    <NoticeComponent location="Tab/Generic" notices={notices} />

                    {ac.AuthHelper.isAdmin() && (
                        <FormComponent formData={data} formErrors={errors} onChange={setFormType} displayErrorBanner={true} fields={[{
                            type: 'select',
                            name: 'type',
                            value: formType.type,
                            label: 'Type',
                            options: [
                                { title: 'Administrator', value: 'SuperUser' },
                                { title: 'Manager', value: 'StudioManager' },
                                { title: 'Personnel', value: 'StudioPersonnel' },
                                { title: 'Viewer', value: 'MarketViewer' },
                                { title: 'MGViewer', value: 'MarketGroupViewer' },
                            ]
                        }]} />
                    ) || ac.AuthHelper.isManager() && (
                        <FormComponent formData={data} formErrors={errors} onChange={setFormType} displayErrorBanner={true} fields={[{
                            type: 'select',
                            name: 'type',
                            value: formType.type,
                            label: 'Type',
                            options: [
                                { title: 'Personnel', value: 'StudioPersonnel' },
                                { title: 'Viewer', value: 'MarketViewer' },
                                { title: 'MGViewer', value: 'MarketGroupViewer' },
                            ]
                        }]} />
                    )}

                    <FormComponent formData={data} formErrors={errors} onChange={setDataMain} displayErrorBanner={false} fields={[{
                            type: 'input-text',
                            name: 'login',
                            label: 'Login'
                        },{
                            type: 'input-text',
                            name: 'first_name',
                            label: 'First Name',
                        },{
                            type: 'input-text',
                            name: 'last_name',
                            label: 'Last Name',
                        },{
                            type: 'input-password',
                            name: 'password',
                            label: 'New Password',
                        },{
                            type: 'input-password',
                            name: 'password_confirmation',
                            label: 'Password Confirmation',
                        },{
                            type: 'input-text',
                            name: 'email',
                            label: 'Email'
                        }]} />

                    {formType.type === 'StudioPersonnel' ? <FormComponent formData={data} formErrors={errors} onChange={setDataPersonnel} displayErrorBanner={false} fields={[
                        {
                            type: 'switch',
                            name: 'allow_viewing_financials',
                            label: 'Allow viewing daily financials?'
                        },{
                            type: 'switch',
                            name: 'sensitive_information_visible',
                            label: 'Sensitive Information Visible?'
                        },{
                            type: 'switch',
                            name: 'allow_order_lock_management',
                            label: 'Allow management of Order locking?',
                            label_info: 'Allows user to lock/unlock reservations when system configured to lock no shows'
                        },{
                            type: 'switch',
                            name: 'order_access_allowed',
                            label: 'Order access allowed?'
                        },{
                            type: 'switch',
                            name: 'action_order_process_allowed',
                            label: 'Processing orders allowed?'
                        },{
                            type: 'switch',
                            name: 'full_refund_allowed',
                            label: 'Full Refund Allowed?'
                        },{
                            type: 'switch',
                            name: 'partial_refund_allowed',
                            label: 'Partial Refund Allowed?'
                        },{
                            type: 'switch',
                            name: 'appointment_aware_refund_allowed',
                            label: 'Appointment Aware Refund Allowed?'
                        }]} /> : null}
                    
                    {formType.type === 'StudioManager' ? <FormComponent formData={data} formErrors={errors} onChange={setDataManager} displayErrorBanner={false} fields={[
                        {
                            type: 'switch',
                            name: 'blackouts_access_disabled',
                            label: 'Blackouts Access Disabled?'
                        },{
                            type: 'switch',
                            name: 'appointment_slots_access_disabled',
                            label: 'Appointment Slots Access Disabled?'
                        },{
                            type: 'switch',
                            name: 'notify_on_order_purchase',
                            label: 'Notifications on order purchase?',
                        }]} /> : null}
                    
                    {formType.type === 'MarketGroupViewer' ? <FormComponent formData={data} formErrors={errors} onChange={setDataMarketGroupViewer} displayErrorBanner={false} fields={[
                        {
                            type: 'switch',
                            name: 'allow_viewing_financials',
                            label: 'Allow viewing financials?'
                        },{
                            type: 'switch',
                            name: 'sensitive_information_visible',
                            label: 'Sensitive Information Visible?'
                        }]} /> : null}
                    
                    {formType.type === 'MarketViewer' ? <FormComponent formData={data} formErrors={errors} onChange={setDataViewer} displayErrorBanner={false} fields={[
                        {
                            type: 'switch',
                            name: 'allow_viewing_financials',
                            label: 'Allow viewing financials?'
                        },{
                            type: 'switch',
                            name: 'sensitive_information_visible',
                            label: 'Sensitive Information Visible?'
                        }]} /> : null}
                    
                    {/StudioPersonnel/.test(formType.type) && (
                        <Layout.PageSection>
                            <Layout.Box>
                                <Layout.SectionTitle>
                                    Associate User to Markets
                                </Layout.SectionTitle>

                                <TwoSidedMultiselectPaginated value={dataSelectedMarkets || { data: [] }} apiUrl="/api/v2/admin/markets" renderTitle={item => `${item.name} - ${item.type}`} tabOptions={['Markets']} itemType="markets" onChange={(items) => {
                                    setDataSelectedMarkets({ data: items })
                                }} />
                            </Layout.Box>
                        </Layout.PageSection>
                    )}

                    {/MarketViewer/.test(formType.type) && (
                        <Layout.PageSection>
                            <Layout.Box>
                                <Layout.SectionTitle>
                                    Associate User to a Market
                                </Layout.SectionTitle>

                                <Layout.Section className="ui-selectedMarket">
                                    <div className="ui-marketName">{getMarketName()}</div>
                                    <ButtonComponent events={{
                                            handleClick: () => _MarketSelectOptions.set('showModal', true)
                                        }} config={{
                                            palette: 'blue',
                                            size: 'small'
                                        }}>
                                            Select Market
                                        </ButtonComponent>
                                </Layout.Section>

                                <Layout.Section>
                                    <ErrorsComponent errors={errors} />
                                </Layout.Section>

                                {_MarketSelectOptions.get('showModal') && (
                                    <SelectWithSearchModalComponent
                                        collection={_apiMarkets.get('data')}
                                        config={{
                                            isSyncing: _MarketSelectOptions.get('syncing'),
                                            titleMain: 'Select Market'
                                        }}
                                        configSearch={{
                                            term: _MarketSelectOptions.get('term'),
                                            page: _MarketSelectOptions.get('page'),
                                            totalPages: _apiMarkets.get('total_pages') || 1
                                        }}
                                        events={{
                                            handleClose: () => _MarketSelectOptions.set('showModal', false),
                                            handleSelect: (value) => {
                                                _MarketSelectOptions.set('showModal', false)
                                                setDataSelectedMarket(value)
                                            },
                                            handleSearchTermChange: (value) => _MarketSelectOptions.set('term', value),
                                            handleSearchPageChange: (value) => _MarketSelectOptions.set('page', value)
                                        }}
                                    />
                                )}
                            </Layout.Box>
                        </Layout.PageSection>
                    )}

                    {/MarketGroupViewer/.test(formType.type) && (
                        <Layout.PageSection>
                            <Layout.Box>
                                <Layout.SectionTitle>Associate User to Market Groups</Layout.SectionTitle>

                                <TwoSidedMultiselectPaginated value={dataSelectedMarketGroups || { data: [] }} apiUrl="/api/v2/admin/market-groups" tabOptions={['Market Groups']} itemType="market groups" onChange={(items) => {
                                    setDataSelectedMarketGroups({ data: items })
                                }} />

                                <Layout.Section>
                                    <ErrorsComponent errors={errors} />
                                </Layout.Section>
                            </Layout.Box>
                        </Layout.PageSection>
                    )}
                </>               
            ) : (
                <Layout.PageSection>
                    <PlaceholderGenericSpinnerComponent />
                </Layout.PageSection>
            )}

            {data && <ControlBar baseApiUrl="/users" redirectUrl="/users" cancelUrl="/users" data={data} modalProp='login' handleSubmit={handleSubmit} /> || null}
        </div>
    )
}

export default UsersEdit
