import { useState, useEffect } from 'react'

import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import dayjs from 'dayjs'

import './index.css'

const PickerDate = ({ initialValue, onChange, dateFormat, readOnly=false }) => {
    const [value, setValue] = useState(dayjs(initialValue))

    useEffect(() => {
        if (initialValue === '') initialValue = null;
        setValue(initialValue)
    }, [initialValue])

    return (
        <div className="picker-date-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue)
                        
                        onChange(dateFormat ? dayjs(newValue).format(dateFormat) : newValue)
                    }}
                    disabled={readOnly}
                    renderInput={(params) => <TextField {...params} 
                        inputProps={{
                            ...params.inputProps,
                            placeholder: "Select Date",
                        }} />}
                />
            </LocalizationProvider>
        </div>
    )
}

export default PickerDate