import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { EntityCardComponent } from '..'

import './index.css'

const EntityGridComponent = ({ items=[], type, events={}, config={} }) => {
    const handleSelection = (item) => {
        if (events.handleSelection)
            events.handleSelection(item)
    }

    const handleAddNewSelection = () => {
        if (events.handleAddNewSelection)
            events.handleAddNewSelection()
    }

    const handleDelete = (item) => {
        if (events.handleDelete)
            events.handleDelete(item)
    }

    const _entityGridListHTML = items.map((item, index) => {
        return (
            <li key={`ui-key--entityGridCardListItem--${item.image_url}--${item.id}-${index}`} className="ui-animation--fadeIn">
                <EntityCardComponent item={item} type={type} config={{ enableDelete: config.enableDelete, enableCode: config.enableCode }} events={{ handleClick: handleSelection, handleDelete: handleDelete }} />
            </li>
        )
    })

    return (
        <UI.Layout name="entityGrid" type="container" config={{ hasClickBoundary: true }}>
            <ul className="ui--entityGridList flex flex-wrap items-start gap-3">
                {_entityGridListHTML}
                {config.enableAdd && (
                    <li className="ui--entityGridListAdd" onClick={() => handleAddNewSelection()}>
                        <i className="bi bi-plus-circle-dotted"></i>
                    </li>
                )}
            </ul>
        </UI.Layout>
    )
}

EntityGridComponent.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),

    events: PropTypes.shape({
        handleSelection: PropTypes.func,
        handleAddNewSelection: PropTypes.func,
        handleDelete: PropTypes.func
    }),

    config: PropTypes.shape({
        enableAdd: PropTypes.bool,
        enableCode: PropTypes.bool
    })
}

export { EntityGridComponent }