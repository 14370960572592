import { useEffect, useState, useRef } from 'react'

const useResolver = (dependencies=[], callback) => {
    const [resolved, setResolved] = useState(false)

    let ref = useRef(false)

    useEffect(() => {
        if (dependencies.every((item) => item) && !resolved && ref && !ref.current) {
            ref.current = true

            setResolved(true)

            if (callback)
                callback()
        }
    }, dependencies)

    return {
        _resolved: resolved
    }
}

export { useResolver }