import {useState, useEffect} from 'react'

import './index.css'

const Switch = ({ value, onChange=()=>{}, disabled=false, title }) => {
    const [checked, setChecked] = useState(value)

    useEffect(() => {
        setChecked(value)
    }, [value])

    return (
        <label title={title} className={`switch-container ${disabled ? ' disabled' : ''}`}>
            <input type="checkbox" checked={checked} value={checked} onChange={(e) => {
                const val = e.target.checked

                setChecked(val)
                onChange(val)
            }} />
            <span className="slider round"></span>
        </label>
    )
}

export default Switch