import {useState} from 'react'

import EntityCardComponent from '../../components/EntityCard'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import UtilsHelper from '../../helpers/utils'

import useNotices from '../../hooks/useNotices'

import './index.css'

const CharitiesPage = () => {
    const [NoticeComponent, notices] = useNotices('Tools/Charities/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [showTags, setShowTags] = useState(false)
    const baseUrl = '/charities'
    const baseApiUrl = '/api/v2/admin/charities'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    const handleListAfterColumnTitleClick = () => {

    }

    const filteredList = {
        ...cachedList,
        data: cachedList.data.map((row) => {
            row.ui_charity_preview = <EntityCardComponent entityData={row} entityType="charity" displayThumbnail={true} />
            row.ui_name_route = `${baseUrl}/${row.id}/edit`
            row.ui_name = row.name

            return row
        })
    }

    return (
        <div className="charities-page-container page-container">
            <PageHeaderComponent title="Charities" actions={[{ title: 'New Charity', route: '/charities/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl} afterColumnTitleClick={handleListAfterColumnTitleClick}
                columns={[
                    {
                        key: 'ui_charity_preview',
                        title: 'Preview',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'ui_name',
                        title: 'Name',
                        sortable: true,
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    }
                ]} dropdownOptions={[
                    // {
                    //     title: 'Preview',
                    //     type: 'url',
                    //     url: 'ui_url'
                    // }
                ]} />
        </div>
    )
}

export default CharitiesPage
