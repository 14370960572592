import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Cell, InnerLayout } from './layouts'
import { PlaceholderWrapper } from './wrappers'
import { Decorators } from './helpers/Decorators'
import { useSimpleState, useAppContext, useResolver } from './hooks'

import { NAMESPACE } from './config'

const Layout = ({ identifier, name, id, children, type='section', element='section', eventsDOM = {}, config = {}, configPlaceholder = {}, callbacks = {} }) => {
    const {_state: _isAnimating} = useSimpleState(false)

    let _ref = React.useRef(null)

    useEffect(() => {
        if (_ref && _ref.current && callbacks.onMount)
            callbacks.onMount({ ref: _ref })
    }, [_ref])

    const getDecoratedClassNames = () => {
        const classNamesContainer =           [`${NAMESPACE}-${type}`, true]
        const classNamesContainerName =       [`${NAMESPACE}-${type}--${name}`, name]
        const classNamesContainerNameWithId = [`${NAMESPACE}-${type}--${name}--${id}`, name && id]
        const classNamesDisabled =            [`${NAMESPACE}-action--disabled`, config.isDisabled]
        // const classNamesDisabledAnimations = [`${NAMESPACE}-action--disabledAnimations`, _isAnimating.get()]

        return Decorators.String([
            classNamesContainer,
            classNamesContainerName,
            classNamesDisabled,
            classNamesContainerNameWithId,
            // classNamesDisabledAnimations,
            config.classNames
        ])
    }

    const handleOnAnimationStart = (event) => {
        _isAnimating.set(true)

        if (callbacks.onAnimationStart)
            callbacks.onAnimationStart(event)
    }

    const handleOnAnimationEnd = (event) => {
        _isAnimating.set(false)

        if (callbacks.onAnimationEnd)
            callbacks.onAnimationEnd(event)
    }

    const handlePlaceholderAnimationStart = (event) => {
        if (callbacks.handlePlaceholderAnimationStart)
            callbacks.handlePlaceholderAnimationStart(event)
    }

    const handlePlaceholderAnimationEnd = (event) => {
        if (callbacks.handlePlaceholderAnimationEnd)
            callbacks.handlePlaceholderAnimationEnd(event)
    }

    const getDecoratedAnimationClassNames = () => {
        return Decorators.Object({
            onAnimationStart: handleOnAnimationStart,
            onAnimationEnd: handleOnAnimationEnd
        })
    }

    const getKey = () => {
        const baseKey = typeof identifier === 'function' ? identifier(name || '') : identifier
        
        return [baseKey].join('-')
    }

    const getDecoratedAttributes = () => {
        let attributes = Decorators.Object({
            ...eventsDOM,
            ...getDecoratedAnimationClassNames(),
            className: getDecoratedClassNames(),
            ref: _ref
        })

        if (identifier)
            attributes.key = getKey()

        if (config.isDisabled)
            attributes.disabled = true

        if (config.hasEventBoundary) {
            attributes.onClick = (event) => {
                event.preventDefault()
                event.stopPropagation()

                if (eventsDOM.onClick)
                    eventsDOM.onClick(event)
            }
        }

        return attributes
    }

    const getHtmlContainer = () => {
        let html = (
            <InnerLayout name={name} identifier={`${getKey()}-inner`} config={{ classNames: config.classNamesInner }} >
                {children}
            </InnerLayout>
        )

        const attributes = getDecoratedAttributes()

        switch (element) {
            case 'div':
                html = <div {...attributes}>{html}</div>
                break
            case 'header':
                html = <header {...attributes}>{html}</header>
                break
            case 'nav':
                html = <nav {...attributes}>{html}</nav>
                break
            case 'main':
                html = <main {...attributes}>{html}</main>
                break
            case 'footer':
                html = <footer {...attributes}>{html}</footer>
                break
            case 'article':
                html = <article {...attributes}>{html}</article>
                break
            case 'aside':
                html = <aside {...attributes}>{html}</aside>
                break
            default:
                html = <section {...attributes}>{html}</section>
        }

        return html
    }

    let _html = getHtmlContainer()

    if (configPlaceholder.dependencies && configPlaceholder.placeholder) {
        return (
            <PlaceholderWrapper
                name={name}
                identifier={`${getKey()}-placeholder`}
                dependencies={configPlaceholder.dependencies}
                placeholder={configPlaceholder.placeholder}
                config={{
                    classNames: configPlaceholder.classNames
                }}
                callbacks={{
                    handleAnimationStart: handlePlaceholderAnimationStart,
                    handleAnimationEnd: handlePlaceholderAnimationEnd
                }}>
                {_html}
            </PlaceholderWrapper>
        )
    } else {
        return _html
    }
}

Layout.propTypes = {
    identifier: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

    name: PropTypes.string.isRequired,

    id: PropTypes.string,

    element: PropTypes.oneOf(['div', 'header', 'nav', 'main', 'footer', 'article', 'aside']),

    eventsDOM: PropTypes.objectOf(PropTypes.func),

    type: PropTypes.oneOf(['container', 'section', 'placeholder', 'page']),

    children: PropTypes.any,

    config: PropTypes.shape({
        classNames: PropTypes.string,
    }),

    configPlaceholder: PropTypes.shape({
        condition: PropTypes.arrayOf(PropTypes.bool),
        placeholder: PropTypes.func,
    }),

    callbacks: PropTypes.shape({
        onMount: PropTypes.func,
        onAnimationStart: PropTypes.func,
        onAnimationEnd: PropTypes.func,
        onMountTimeout: PropTypes.func
    })
}

const UI = {
    Layout,
    Cell,
    useSimpleState,
    useAppContext,
    useResolver,
    Decorators,
    NAMESPACE,
}

export { UI }