import { useState, useEffect } from 'react'

import DropdownTextCollectionComponent from '../../../../components/DropdownTextCollection'
import HTMLEditor from '../../../../components/HTMLEditor'

import './index.css'

const EmailTemplateForm = ({ onChange=()=>{} }) => {
    const [dataSubject, setDataSubject] = useState('')
    const [dataBody, setDataBody] = useState('')
    const [textRange, setTextRange] = useState(0) // update on focus

    useEffect(() => {
        onChange(dataSubject, dataBody)
    }, [dataSubject, dataBody])

    return (
        <div className="email-template-form-container">
            <div className="title">Send Email</div>
            <div className="editor">
                <div className="row subject">
                    <input type="text" value={dataSubject} onChange={(e) => setDataSubject(e.target.value)} name="email_subject" placeholder="Subject" />
                </div>
                <div className="row body">
                    <HTMLEditor initialValue={dataBody} showTags={true} onChange={(value) => setDataBody(value)} />
                </div>
            </div>
        </div>
    )
}

export default EmailTemplateForm