import { COUNTRIES } from '../../../sys/constants/Countries';
import { FileTypes } from '../../../sys/constants/enums';

export function getStudioInfoForm1Fields(billingProfiles = []) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name',
      placeholder: 'Lovely Studios, LLC.'
    },
    {
      type: 'input-text',
      name: 'display_name',
      label: 'Display Name',
      placeholder: 'A Lovely Studio'
    },
    {
      type: 'select',
      name: 'billing_profile_id',
      label: 'Default Billing Profile',
      options: billingProfiles,
      relatedFields: ['billing_profile']
    },
    {
      type: 'input-money',
      name: 'minimum_monthly_bill',
      label: 'Minimum monthly bill',
      step: 0.05
    },
    {
      type: 'switch',
      name: 'past_due',
      label: 'Past Due?'
    },
    {
      type: 'input-text',
      name: 'email_friendly_from',
      label: 'Friendly From Name for Email',
      placeholder: 'A Lovely Studio'
    },
    {
      type: 'input-text',
      name: 'customer_service_email',
      label: 'Customer Service Email',
      placeholder: 'cs@lovelystudio.com',
      field_info: 'Separate multiple emails with a semi-colon ( ; )'
    }
  ];
}

export function getStudioInfoForm2Fields() {
  return [
    {
      type: 'input-text',
      name: 'address1',
      label: 'Address 1',
      relatedFields: ['address.address1']
    },
    {
      type: 'input-text',
      name: 'address2',
      label: 'Address 2'
    },
    {
      type: 'input-text',
      name: 'city',
      label: 'City',
      relatedFields: ['address.city']
    },
    {
      type: 'input-text',
      name: 'state_province',
      label: 'State/Province',
      relatedFields: ['address.state_province']
    },
    {
      type: 'select',
      name: 'country',
      label: 'Country',
      options: COUNTRIES,
      relatedFields: ['address.country']
    },
    {
      type: 'input-text',
      name: 'postal_code',
      label: 'Postal Code',
      relatedFields: ['address.postal_code']
    }
  ];
}

export function getStudioInfoForm3Fields(timezones = []) {
  return [
    {
      type: 'select',
      name: 'timezone',
      label: 'Timezone',
      options: timezones
    },
    {
      type: 'input-text',
      name: 'phone_number',
      label: 'Phone Number',
      placeholder: '305-555-0123'
    },
    {
      type: 'input-money',
      name: 'convenience_fee',
      label: 'Convenience fee',
      step: 0.05
    },
    {
      type: 'input-text',
      name: 'website',
      label: 'Website'
    }
  ];
}

export function getSettingsForm1Fields() {
  return [
    {
      type: 'input-text',
      name: 'policy_description',
      label: 'Policy: Description'
    },
    {
      type: 'input-text',
      name: 'policy_url',
      label: 'Policy: URL'
    },
    {
      type: 'switch',
      name: 'self_service_enabled',
      label: 'Self-Service Enabled?'
    },
    {
      type: 'switch',
      name: 'refunding_requires_approval',
      label: 'Refunding requires approval'
    },
    {
      type: 'switch',
      name: 'lock_no_shows',
      label: 'Lock no shows'
    },
    {
      type: 'switch',
      name: 'appointment_aware_refunding',
      label: 'Appointment aware refunding'
    }
  ];
}

export function getSettingsPercentageFormFields() {
  return [
    {
      type: 'input-percent',
      name: 'percent1'
    },
    {
      type: 'input-percent',
      name: 'percent2'
    },
    {
      type: 'input-percent',
      name: 'percent3'
    },
    {
      type: 'input-percent',
      name: 'percent4'
    },
    {
      type: 'input-percent',
      name: 'percent5'
    }
  ];
}

export function getSettingsTimeFormFields() {
  return [
    {
      type: 'input-time',
      name: 'hours1',
      tag: 'hours',
      symbol: '>='
    },
    {
      type: 'input-time',
      name: 'hours2',
      tag: 'hours',
      symbol: '>='
    },
    {
      type: 'input-time',
      name: 'hours3',
      tag: 'hours',
      symbol: '>='
    },
    {
      type: 'input-time',
      name: 'hours4',
      tag: 'hours',
      symbol: '>='
    },
    {
      type: 'input-time',
      name: 'hours5',
      tag: 'hours',
      symbol: '>='
    }
  ];
}

export function getThirdPartyServicesFormFields(
  showApiAccessToken = false,
  showVerificationToken = false,
  services
) {
  let fields = [];

  fields.push({
    type: 'switch',
    name: 'api_access_token_enabled',
    label: 'API Access?'
  });

  if (showApiAccessToken) {
    fields.push({
      type: 'copy-button',
      name: 'api_access_token',
      label: 'API access token'
    });
  }

  if (showVerificationToken) {
    fields.push({
      type: 'copy-button',
      name: 'verification_token',
      label: 'Verification token'
    });
  }

  fields = fields.concat([
    {
      type: 'input-text',
      name: 'gtm_container_id',
      label: 'Google Tag Manager Container ID'
    },
    {
      type: 'switch',
      name: 'twilio_service_enabled',
      label: 'Twilio'
    },
    {
      type: 'textarea',
      name: 'twilio_service_numbers',
      placeholder:
        'Phone Number. Multiple numbers separated by semicolon. Sending number will be selected randomly.'
    },
    {
      type: 'input-text',
      name: 'twilio_service_sid',
      placeholder: 'SID'
    },
    {
      type: 'input-text',
      name: 'twilio_service_auth_token',
      placeholder: 'Auth Token'
    },
    {
      type: 'switch',
      name: 'centrics_service_enabled',
      label: 'Centrics?'
    },
    {
      type: 'switch',
      name: 'ddlabs_service_enabled',
      label: 'DDLab?'
    },
    {
      type: 'switch',
      name: 'proofing_service_enabled',
      label: 'Proofs?'
    },
    {
      type: 'input-file',
      name: 'watermark',
      label: 'Watermark',
      label_info: 'This will be used for proofing',
      imageUrl: services?.watermark,
      acceptedFileTypes: FileTypes.IMAGES
    },
    {
      type: 'switch',
      name: 'drums_service_enabled',
      label: 'DRUMS?'
    },
    {
      type: 'switch',
      name: 'triprism_service_enabled',
      label: 'Order QR Code?'
    },
    {
      type: 'switch',
      name: 'package_from_santa_service_enabled',
      label: 'Package From Santa?'
    },
    {
      type: 'switch',
      name: 'zapier_service_enabled',
      label: 'Zapier?'
    },
    {
      type: 'input-text',
      name: 'zapier_service_url',
      label: 'Zapier: URL',
      field_info:
        'This will change based on the equivalent setting in the following order of precedence: Marketing Plan URL overwrites Market Group URL overwrites Studio URL'
    },
    {
      type: 'switch',
      name: 'portable_north_pole_service_enabled',
      label: 'Portable North Pole?'
    },
    {
      type: 'input-text',
      name: 'portable_north_pole_service_username',
      label: 'Portable North Pole: API Username'
    },
    {
      type: 'input-text',
      name: 'portable_north_pole_service_password',
      label: 'Portable North Pole: API Password'
    },
    {
      type: 'switch',
      name: 'vanity_domain_service_enabled',
      label: 'Vanity Domains?'
    }
  ]);

  return fields;
}
