import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import AssetUploader from '../../componentsV2/Integrated/AssetUploader'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'
import { useSimpleState } from '../../UI/hooks'
import { getAddonsFormFields, getBoxFormFields, getButtonsFormFields, getCalendarFormFields, getCustomFilesFormFields, getFooterFormFields, getHeaderFormFields, getMarketsFormFields, getNameFormFields, getPageFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const ColorSchemesNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('UserExperience/ColorSchemes/New')

    const {_state: _imagesHeader} = useSimpleState([
        {
            type: 'Header: Large',
            id: 'header_image_lg',
            externalUrl: '',
            note: 'Recommended dimensions: 1170x200',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1',
            relatedFields: ['header_image_lg_url']
        },
        {
            type: 'Header: Medium',
            id: 'header_image_md',
            externalUrl: '',
            note: 'Recommended dimensions: 970x200',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1',
            relatedFields: ['header_image_md_url']
        },
        {
            type: 'Header: Small',
            id: 'header_image_sm',
            externalUrl: '',
            note: 'Recommended dimensions: 750x200',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1',
            relatedFields: ['header_image_sm_url']
        },
        {
            type: 'Header: Extra Small',
            id: 'header_image_xs',
            externalUrl: '',
            note: 'Recommended dimensions: 767x200',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1',
            relatedFields: ['header_image_xs_url']
        },
    ])

    const {_state: _imagesPage} = useSimpleState([
        {
            type: 'Page Image',
            id: 'page_bg_image',
            externalUrl: '',
            note: 'Image will be tiled',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1',
            relatedFields: ['page_bg_image_url']
        }
    ])

    const {_state: _imagesFooter} = useSimpleState([
        {
            type: 'Footer Image',
            id: 'footer_bg_image',
            externalUrl: '',
            note: 'Image will not be resized. Image will be tiled horizontally.',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '1'
        }
    ])

    // DATA
    const [data, setData, clearData, dataDefault] = useDraft('UserExperience:ColorSchemes:Data')
    const [header, setHeader, clearDataHeader, dataHeaderDefault] = useDraft('UserExperience:ColorSchemes:DataHeader')
    const [page, setPage, clearDataPage, dataPageDefault] = useDraft('UserExperience:ColorSchemes:DataPage')
    const [box, setBox, clearDataBox, dataBoxDefault] = useDraft('UserExperience:ColorSchemes:DataBox')
    const [calendar, setCalendar, clearDataCalendar, dataCalendarDefault] = useDraft('UserExperience:ColorSchemes:DataCalendar')
    const [addons, setAddons, clearDataAddons, dataAddonsDefault] = useDraft('UserExperience:ColorSchemes:DataAddons')
    const [buttons, setButtons, clearDataButtons, dataButtonsDefault] = useDraft('UserExperience:ColorSchemes:DataButons')
    const [footer, setFooter, clearDataFooter, dataFooterDefault] = useDraft('UserExperience:ColorSchemes:DataFooter')
    const [markets, setMarkets, clearDataMarkets, dataMarketsDefault] = useDraft('UserExperience:ColorSchemes:DataMarkets', [])
    const [customFiles, setCustomFiles, claerDataCustomFiles, dataCustomFilesDefault] = useDraft('UserExperience:ColorSchemes:DataCustomFiles')

    const [errors, setErrors] = useState()
    const [availableMarkets, setAvailableMarkets] = useState([])
    
    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Name', ['Name', 'Header', 'Page', 'Box', 'Calendar', 'Addons', 'Buttons', 'Footer', 'Custom Files', 'Markets'])

    const [nameFormFields] = useState(getNameFormFields());
    const [headerFormFields] = useState(getHeaderFormFields());
    const [pageFormFields] = useState(getPageFormFields());
    const [boxFormFields] = useState(getBoxFormFields());
    const [calendarFormFields] = useState(getCalendarFormFields());
    const [addonsFormFields] = useState(getAddonsFormFields());
    const [buttonsFormFields] = useState(getButtonsFormFields());
    const [footerFormFields] = useState(getFooterFormFields());
    const [customFilesFormFields] = useState(getCustomFilesFormFields());
    const [marketsFormFields, setMarketsFormFields] = useState(getMarketsFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getMarketsFormFields(availableMarkets, markets?.market_ids)
        setMarketsFormFields(fields)
    }, [availableMarkets, markets?.market_ids])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Name',
                forms: [{ id: 'Main', fields: nameFormFields }]
            },
            {
                tab: 'Header',
                forms: [{ id: 'Main', fields: headerFormFields }]
            },
            {
                tab: 'Page',
                forms: [{ id: 'Main', fields: pageFormFields }]
            },
            {
                tab: 'Box',
                forms: [{ id: 'Main', fields: boxFormFields }]
            },
            {
                tab: 'Calendar',
                forms: [{ id: 'Main', fields: calendarFormFields }]
            },
            {
                tab: 'Addons',
                forms: [{ id: 'Main', fields: addonsFormFields }]
            },
            {
                tab: 'Buttons',
                forms: [{ id: 'Main', fields: buttonsFormFields }]
            },
            {
                tab: 'Footer',
                forms: [{ id: 'Main', fields: footerFormFields }]
            },
            {
                tab: 'Custom Files',
                forms: [{ id: 'Main', fields: customFilesFormFields }]
            },
            {
                tab: 'Markets',
                forms: [{ id: 'Main', fields: marketsFormFields }]
            },
            {
                tab: 'Header',
                forms: [{ id: 'Header', fields: _imagesHeader.get(), fieldKeyProp: 'id' }]
            },
            {
                tab: 'Page',
                forms: [{ id: 'Page', fields: _imagesPage.get(), fieldKeyProp: 'id' }]
            },
            {
                tab: 'Footer',
                forms: [{ id: 'Footer', fields: _imagesFooter.get(), fieldKeyProp: 'id' }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: '/api/v2/admin/markets',
        }, (res) => {

            let marketsList = []

            res.body.data.forEach(elem => {
                const market = {
                    id: elem.id,
                    group: elem.market_group ? elem.market_group.name : 'UNGROUPED',
                    name: elem.external_id ? elem.external_id + ' - ' + elem.name : elem.name
                }
                marketsList.push(market)
            });

            setAvailableMarkets(marketsList)

            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let images = {}
            let temp_body = { ...header, ...page, ...footer, ...customFiles }
            const files_attributes = ["custom_stylesheet"]

            for (var i = 0; i < 1; i++) {
                const img = temp_body[files_attributes[i]]

                if (files_attributes[i]) {
                    if (img && (img.value !== "") && img.value !== data[files_attributes[i]]) {
                        images = {
                            ...images,
                            [files_attributes[i] + "_base64"]: img.value
                        }
                    }
                    delete temp_body[files_attributes[i]]
                }
            }

            let imageBody = {}

            _imagesHeader.get().forEach((item, index) => {
                imageBody[`${item.id}_url`] = item.externalUrl
                imageBody[`${item.id}_source`] = item.assetSource === '0' ? 'local' : 'remote'
                imageBody[`${item.id}_base64`] = /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null
            })

            _imagesPage.get().forEach((item, index) => {
                imageBody[`${item.id}_url`] = item.externalUrl
                imageBody[`${item.id}_source`] = item.assetSource === '0' ? 'local' : 'remote'
                imageBody[`${item.id}_base64`] = /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null
            })

            _imagesFooter.get().forEach((item, index) => {
                imageBody[`${item.id}_url`] = item.externalUrl
                imageBody[`${item.id}_source`] = item.assetSource === '0' ? 'local' : 'remote'
                imageBody[`${item.id}_base64`] = /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null
            })

            let body = {
                name: data.name,
                ...temp_body,
                ...imageBody,
                ...box,
                ...calendar,
                ...addons,
                ...buttons
            }

            if(markets && markets.market_ids && markets.market_ids.length > 0){

                let market_ids = []
                markets.market_ids.map(elem => {
                    market_ids.push(elem.id)
                })

                body = {
                    ...body,
                    market_ids: market_ids
                }
            }

            FetchHelper({
                url: `/api/v2/admin/color-schemes`,
                method: 'POST',
                body: {
                    color_scheme: body
                }
            }, (res) => {
                ac.showSpinner(false)
                resolve()
            }, (res) => {
                ac.showSpinner(false)
                if (res.status === 422) {
                    let errors = UtilsHelper.preProcess422Errors(
                        [..._imagesHeader.get(), ..._imagesPage.get()],
                        res.body
                    )
                    setErrors(errors)
                    UtilsHelper.scrollTop()
                }
                reject()
            })
        })
    }

    const onChangeTab = (tab) => {
        switch (tab) {
            case 'Name':
                return (
                    <>
                        <NoticeComponent location="Tab/Name" notices={notices} />
                        <FormComponent tab={tab} formData={data} onChange={setData} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={nameFormFields} />
                    </>
                )
            case 'Header':
                return (
                    <>
                        <NoticeComponent location="Tab/Header" notices={notices} />
                        {_imagesHeader.get() && <AssetUploader list={_imagesHeader.get()} key={tab} events={{ handleChange: (value) => _imagesHeader.set(value) }}
                            errors={errors} setErrors={setErrors} />}
                        <FormComponent tab={tab} formData={header} onChange={setHeader} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={headerFormFields} />
                    </>
                )
            case 'Page':
                return (
                    <>
                        <NoticeComponent location="Tab/Page" notices={notices} />
                        {_imagesPage.get() && <AssetUploader list={_imagesPage.get()} key={tab} events={{ handleChange: (value) => _imagesPage.set(value) }}
                            errors={errors} setErrors={setErrors} />}
                        <FormComponent tab={tab} formData={page} onChange={setPage} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={pageFormFields} />
                    </>
                )
            case 'Box':
                return (
                    <>
                        <NoticeComponent location="Tab/Box" notices={notices} />
                        <FormComponent tab={tab} formData={box} onChange={setBox} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={boxFormFields} />
                    </>
                )
            case 'Calendar':
                return (
                    <>
                        <NoticeComponent location="Tab/Calendar" notices={notices} />
                        <FormComponent tab={tab} formData={calendar} onChange={setCalendar} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={calendarFormFields} />
                    </>
                )
            case 'Addons':
                return (
                    <>
                        <NoticeComponent location="Tab/Addons" notices={notices} />
                        <FormComponent tab={tab} formData={addons} onChange={setAddons} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={addonsFormFields} />
                    </>
                )
            case 'Buttons':
                return (
                    <>
                        <NoticeComponent location="Tab/Buttons" notices={notices} />
                        <FormComponent tab={tab} formData={buttons} onChange={setButtons} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={buttonsFormFields} />
                    </>
                )
            case 'Footer':
                return (
                    <>
                        <NoticeComponent location="Tab/Footer" notices={notices} />
                        {_imagesFooter.get() && <AssetUploader list={_imagesFooter.get()} key={tab} events={{ handleChange: (value) => _imagesFooter.set(value) }} />}
                        <FormComponent tab={tab} formData={footer} onChange={setFooter} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={footerFormFields} />
                    </>
                )
            case 'Custom Files':
                return (
                    <>
                        <NoticeComponent location="Tab/CustomFiles" notices={notices} />
                        <FormComponent tab={tab} formData={customFiles} onChange={setCustomFiles} formErrors={errors} setFormErrors={setErrors} displayErrorBanner={true} fields={customFilesFormFields} />
                    </>
                )
            case 'Markets':
                return (
                    <>
                        <NoticeComponent location="Tab/Markets" notices={notices} />
                        <FormComponent formData={markets} formErrors={errors} setFormErrors={setErrors} onChange={setMarkets} fields={marketsFormFields} />
                    </>
                )
            default:
                break;
        }
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="New Color Schemes" />
            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            <div className="tab-content">
                {onChangeTab(tab)}
            </div>

            <ControlBar handleSubmit={handleSubmit} cancelUrl="/color-schemes" handleAfterSubmit={() => RouteHelper.redirect('/color-schemes')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault],
                [header, clearDataHeader, dataHeaderDefault],
                [page, clearDataPage, dataPageDefault],
                [box, clearDataBox, dataBoxDefault],
                [calendar, clearDataCalendar, dataCalendarDefault],
                [addons, clearDataAddons, dataAddonsDefault],
                [buttons, clearDataButtons, dataButtonsDefault],
                [footer, clearDataFooter, dataFooterDefault],
                [markets, clearDataMarkets, dataMarketsDefault],
                [customFiles, claerDataCustomFiles, dataCustomFilesDefault]
            ]} />
        </div>
    )
}
export default ColorSchemesNew
