import { useEffect, useState, useRef } from 'react'

import FetchHelper from '../helpers/fetch'
import RouteHelper from '../helpers/route'
import UtilsHelper from '../helpers/utils'

export default function useAuth() {
    const [user, setUser] = useState({})
    const [settings, setSettings] = useState({})
    const fetched = useRef(false)

    useEffect(() => {
        // @TODO: Create helper to test more routes
        if (!fetched.current && !/^\/login/.test(RouteHelper.getCurrentRoute())) {
            fetched.current = true

            FetchHelper({
                url: `/api/v2/admin/profile`,
            }, (res) => {
                setUser(res.body)
                fetchSettings()
            }, (res) => {
                RouteHelper.redirect('/login')
            })
        }
    }, [])

    // --- triggers when user data changes
    useEffect(() => {
        setDefaultStudio()
    }, [user])

    const fetchSettings = () => {
        FetchHelper({
            url: `/api/v2/admin/settings`,
        }, (res) => {
            setSettings(res.body)
        }, (res) => {

        })
    }

    // --- set default studio for super-user (eg. the one lastly stored in local storage)
    const setDefaultStudio = () => {
        if (!AuthHelper.isAdmin()) return;
        if (!user || user.current.studio_id !== null) return; // already set
        
        let lastUsedStudioID = UtilsHelper.getLocalStorage(`SelectAutocomplete_PageHeaderStudio`);
        
        FetchHelper({
            url: '/api/v2/admin/set/current',
            method: 'put',
            body: { studio_id: lastUsedStudioID }
        })
    }

    const isSignedIn = () => {
        return user.login
    }

    const isAdmin = () => {
        return user.type === 'SuperUser'
    }
    
    const isManager = () => {
        return isAdmin() || user.type === 'StudioManager'
    }
    
    const isPersonnel = () => {
        return isManager() || user.type === 'StudioPersonnel'
    }
    
    const isMarketViewer = () => {
        return isPersonnel() || user.type === 'MarketViewer'
    }
    
    const isMarketGroupViewer = () => {
        return isPersonnel() || user.type === 'MarketGroupViewer'
    }
    
    const hasPermission = (permission) => {
        return user.permissions.indexOf(permission) > -1
    }

    const checkFeature = (featureName) => {
        switch (featureName) {
            case 'API':
                return settings?.api_access_token_enabled === true
            case 'AppointmentAwareRefunding':
                return settings?.appointment_aware_refunding === true
            case 'CentricsService':
                return settings?.third_party?.centrics_service_enabled === true
            case 'DDLabService':
                return settings?.third_party?.ddlabs_service_enabled === true
            case 'DRUMSService':
                return settings?.third_party?.drums_service_enabled === true
            case 'PackageFromSantaService':
                return settings?.third_party?.package_from_santa_service_enabled === true
            case 'PortableNorthPoleService':
                return settings?.third_party?.portable_north_pole_service_enabled === true
            case 'ProofingService':
                return settings?.third_party?.proofing_service_enabled === true
            case 'SelfService':
                return settings?.self_service_enabled === true
            case 'TriprismService':
                return settings?.third_party?.triprism_service_enabled === true
            case 'TwilioService':
                return settings?.third_party?.twilio_service_enabled === true
            case 'VerifiedService':
                return settings?.third_party?.verified_service_enabled === true
            case 'ZapierService':
                return settings?.third_party?.zapier_service_enabled === true
            default:
                return false
        }
    }
    
    const AuthHelper = { isAdmin, isManager, isPersonnel, isMarketViewer, isMarketGroupViewer, isSignedIn, checkFeature, hasPermission, setUser, settings }

    return [AuthHelper, user, setUser]
}