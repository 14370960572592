import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import { SUPPORTED_PAYMENT_GATEWAYS, PAYMENT_ACCOUNT_ATTR_TRANSLATIONS } from '../../sys/constants/PaymentAccount'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './edit.css'
import { getApplePayFormFields, getPaymentInfoFormFields, getPaymentTypeFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const PaymentAccountsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/PaymentAccounts/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [type, setType] = useState()
    const [applePay, setApplePay] = useState()
    const [applePayId, setApplePayId] = useState()
    const [key, setKey] = useState()
    const [certificate, setCertificate] = useState()
    const PaymenGatewaysConstant = SUPPORTED_PAYMENT_GATEWAYS
    const PaymentSettingsConstant = PAYMENT_ACCOUNT_ATTR_TRANSLATIONS
    const [payments_gateways, setPayments_gateways] = useState([{ id: 0, title: "Select", value: "" }])
    const [paymentSettings, setPaymentSettings] = useState({ login: 'Login', password: 'Password', merchant_id: 'Merchant ID' })

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Payment Information', ['Payment Information', 'Payment Type', 'Apple Pay'])

    const [paymentInfoFormFields, setPaymentInfoFormFields] = useState(getPaymentInfoFormFields())
    const [paymentTypeFormFields] = useState(getPaymentTypeFormFields())
    const [applePayFormFields, setApplePayFormFields] = useState(getApplePayFormFields())
   
    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getPaymentInfoFormFields(payments_gateways, paymentSettings)
        setPaymentInfoFormFields(fields)
    }, [payments_gateways, paymentSettings])

    useEffect(() =>{
        let fields = getApplePayFormFields(certificate, key)
        setApplePayFormFields(fields)
    }, [certificate, key])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Payment Information',
                forms: [{ id: 'Main', fields: paymentInfoFormFields }]
            },
            {
                tab: 'Payment Type',
                forms: [{ id: 'Main', fields: paymentTypeFormFields }]
            },
            {
                tab: 'Apple Pay',
                forms: [{ id: 'Main', fields: applePayFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        let temp = payments_gateways

        PaymenGatewaysConstant.map((item, i) => {
            let obj = {
                id: i + 1,
                title: item.name,
                value: item.value
            }
            temp.push(obj)
        })

        setPayments_gateways(temp)

    }, [])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: '/api/v2/admin/payment-accounts/' + params.id
        }, (res) => {
            ac.showSpinner(false)

            let payment_info = {
                id: res.body.id,
                name: res.body.name,
                password: res.body.password,
                testing: res.body.testing,
                type: res.body.type,
                merchant_id: res.body.merchant_id,
                login: res.body.login
            }
            let payment_type = {
                accept_visa: res.body.accept_visa,
                accept_master: res.body.accept_master,
                accept_discover: res.body.accept_discover,
                accept_american_express: res.body.accept_american_express
            }
            let apple_pay = {
                apple_pay: res.body.apple_pay,
            }

            if (res.body.apple_pay_integration) {
                apple_pay = {
                    ...apple_pay,
                    merchant_key: res.body.apple_pay_integration.merchant_key,
                    merchant_identifier: res.body.apple_pay_integration.merchant_identifier,
                    merchant_certificate: res.body.apple_pay_integration.merchant_certificate
                }
            }

            setData(payment_info)
            setType(payment_type)
            setApplePay(apple_pay)
            setApplePayId(res.body.apple_pay_integration.id)
            setKey(res.body.apple_pay_integration.merchant_key)
            setCertificate(res.body.apple_pay_integration.merchant_certificate)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [])

    useEffect(() => {
        if (data && PaymentSettingsConstant[data.type]) {
            let selectedType = PaymentSettingsConstant[data.type]
            setPaymentSettings({
                ...paymentSettings,
                login: selectedType.login,
                password: selectedType.password ? selectedType.password : '',
                merchant_id: selectedType.merchant_id ? selectedType.merchant_id : false
            })
        }
    }, [data])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            ...data,
            ...type,
            apple_pay: applePay.apple_pay
        }

        let apple_pay_integration = {
            merchant_identifier: applePay.merchant_identifier
        }

        if (!paymentSettings.merchant_id) {
            body = {
                ...body,
                merchant_id: ''
            }
        }

        if (applePayId) {
            apple_pay_integration = {
                id: applePayId,
                ...apple_pay_integration
            }
        }

        for (var key in applePay) {
            const file = applePay[key]
            if (file && (file.value !== "") && ((key === "merchant_key" && key !== file.value) || (key === "merchant_certificate" && certificate !== file.value))) {
                apple_pay_integration = {
                    ...apple_pay_integration,
                    [key + '_base64']: file.value
                }
            }
        }

        body = {
            ...body,
            apple_pay_integration_attributes: apple_pay_integration
        }

        FetchHelper({
            url: '/api/v2/admin/payment-accounts/' + params.id,
            method: 'PUT',
            body: {
                payment_account: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/payment-accounts')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(
                    [...paymentInfoFormFields, ...paymentTypeFormFields, ...applePayFormFields],
                    res.body
                )
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    const onChangeTab = (tab) => {
        switch (tab) {
            case 'Payment Information':
                return (
                    <>
                        <NoticeComponent location="Tab/PaymentInformation" notices={notices} />
                        <FormComponent tab={tab} formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setData} displayErrorBanner={true} fields={paymentInfoFormFields} />
                    </>
                )
            case 'Payment Type':
                return (
                    <>
                        <NoticeComponent location="Tab/PaymentType" notices={notices} />
                        <FormComponent tab={tab} formData={type} formErrors={errors} setFormErrors={setErrors} onChange={setType} displayErrorBanner={true} fields={paymentTypeFormFields} />
                    </>
                )
            case 'Apple Pay':
                return (
                    <>
                        <NoticeComponent location="Tab/ApplePay" notices={notices} />
                        <FormComponent tab={tab} formData={applePay} formErrors={errors} setFormErrors={setErrors} onChange={setApplePay} displayErrorBanner={true} fields={applePayFormFields} />
                    </>
                )
            default:
                break
        }
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="Edit Payment Account" />
            {data &&
                <>
                    <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
                    <div className="tab-content">
                        {onChangeTab(tab)}
                    </div>
                    <ControlBar baseApiUrl="/payment-accounts" redirectUrl="/payment-accounts" cancelUrl="/payment-accounts" data={data} handleSubmit={handleSubmit} />
                </>
            }
        </div>
    )
}
export default PaymentAccountsEdit
