import { useState, useRef } from 'react'

import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const CarouselComponent = ({ children, config={} }) => {
    const {_state} = UI.useSimpleState({
        activeIndex: 0,
        touchStart: 0,
        touchPosition: 0,
        swipeDirection: 0
    })

    const carouselRef = useRef(null)

    function handlePreviousClick() {
        _state.set('activeIndex', (_state.get('activeIndex') - 1 + children.length) % children.length) 
    }

    function handleNextClick() {
        _state.set('activeIndex', (_state.get('activeIndex') + 1) % children.length)
    }

    function handleTouchStart(event) {
        _state.set('touchStart', event.touches[0].clientX)
        _state.set('touchPosition', 0)
    }

    function handleTouchMove(event) {
        const touchCurrent = event.touches[0].clientX
        const touchDistance = touchCurrent - _state.get('touchStart')

        _state.set('touchPosition', touchDistance)

        carouselRef.current.style.transform = `translateX(${touchDistance}px)`
    }

    function handleTouchEnd(event) {
        const touchEnd = event.changedTouches[0].clientX
        const touchDistance = touchEnd - _state.get('touchStart')

        if (touchDistance > 50) {
            handlePreviousClick()
        } else if (touchDistance < -50) {
            handleNextClick()
        }

        carouselRef.current.style.transform = ''
    }

    return (
        <UI.Layout name="carousel" type="container" config={config}>
            <div className="ui--carouselSlides">
                <div className="ui--carouselSlidesInner" ref={carouselRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                    {children.map((child, index) => (
                        <div key={index} className={`ui--carouselSlide ${index === _state.get('activeIndex') ? 'ui-action--active' : ''}`}>
                            {child}
                        </div>
                    ))}
                </div>
            </div>
            <div className="ui--carouselIndicators">
                {children.map((child, index) => (
                    <div key={index} className={`ui--carouselIndicator ${index === _state.get('activeIndex') ? 'ui-action--active' : ''}`} onClick={() => _state.set('activeIndex', index )}>
                        <span></span>
                    </div>
                ))}
            </div>
        </UI.Layout>
    )
}

CarouselComponent.propTypes = {
    children: PropTypes.any,

    config: PropTypes.shape({
        classNames: PropTypes.string
    })
}

export { CarouselComponent }