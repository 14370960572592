import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import ProjectorComponent from '../../components/Projector'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { FileTypes } from '../../sys/constants/enums'
import { useParams } from 'react-router-dom'

const PosesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Poses/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState({})
    const [dataMain, setDataMain] = useState()
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {...dataMain}

        if (body.image_attributes) {
            body.image_attributes = {
                id: data.image.id,
                data_base64: body.image_attributes.value
            }
        }

        body.tag_names = body.tags
        delete body.tags

        FetchHelper({
            url: `/api/v2/admin/poses/${data.id}`,
            method: 'PUT',
            body: { pose: body }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/poses')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/poses/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/poses/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {
        })
    }, [])

    return (
        <div className="poses-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Pose - ${dataMain?.name}`} />
            {data ? <>
                <ProjectorComponent url={data.image.image_url} />
                <NoticeComponent location="Main" notices={notices} />
                <FormComponent formData={data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true}
                    fields={[{
                            type: 'select',
                            name: 'type',
                            label: 'Type',
                            value: 'IndividualPose',
                            options: [{
                                title: 'Individual',
                                value: 'IndividualPose'
                            },{
                                title: 'Couple',
                                value: 'CouplePose'
                            }]
                        },{
                            type: 'input-text',
                            name: 'name',
                            label: 'Name'
                        },{
                            type: 'tags',
                            name: 'tags',
                            label: 'Tags',
                            suggestions: suggestionsTags
                        },{
                            type: 'input-text',
                            name: 'code',
                            label: 'Code',
                        },{
                            type: 'input-number',
                            name: 'shipping_premium',
                            label: 'Shipping premium',
                        },{
                            type: 'input-file',
                            name: 'image_attributes',
                            label: 'Image',
                            label_info: '(512kb max)',
                            acceptedFileTypes: FileTypes.IMAGES,
                            field_info: 'Optimal Image Size is 183 x 275 pixels.'
                    }]} />
                <ControlBar baseApiUrl="/poses" redirectUrl="/poses" cancelUrl="/poses" data={data} handleSubmit={handleSubmit} />
            </> : null}
        </div>
    )
}

export default PosesEdit
