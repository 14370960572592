import './index.css'

const BannerComponent = ({ children, type }) => {
    return (
        <div className={`banner-component-container ${type === 'sticky' ? 'banner-component-sticky' : 'banner-component-alert'}`}>
            {children}
        </div>
    )
}

export { BannerComponent }