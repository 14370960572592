import PropTypes from 'prop-types'

import { Form, FormRow, FormCreditCardNumberComponent, FormFieldErrorComponent, FormFieldMoneyComponent } from '../../../../componentsV2/Form'
import { SelectComponent, LabelComponent, InputComponent, ButtonComponent, ParagraphComponent, HeadingComponent } from '../../../../componentsV2/Primitive'
import { ModalComponent } from '../../../../componentsV2/Complex'
import { PlaceholderGenericSpinnerComponent } from '../../../../componentsV2/Integrated'
import { Layout } from '../../../../componentsV2/Layouts'
import { UI } from '../../../../UI'
import FetchHelper from '../../../../helpers/fetch'
import RouteHelper from '../../../../helpers/route'

import dayjs from 'dayjs'

import './index.css'


const DEFAULTS = {
    number: null,
    csv: null,
    expiration_month: null,
    expiration_year: null,
    amount: null
}

const PayModalComponent = ({ _togglesModal, _apis, events={} }) => {
    const {_state} = UI.useSimpleState({
        currentView: 'form',
        clearCount: 0,
        error: {}
    })

    const {_state: _api} = UI.useSimpleState({})

    const {_state: _form} = UI.useSimpleState(DEFAULTS)
    const {_state: _errors} = UI.useSimpleState()
    const {_state: _transitions} = UI.useSimpleState({
        currentView: null,
        nextView: null
    })

    const {_state: _dependencies} = UI.useSimpleState({
        processed: false
    })

    const _paymentAccount = _apis.get('paymentAccount') || {}

    const syncPost = () => {
        _state.set('error', {})
        _errors.set({})
        
        let body = {
            payment: {
              amount: Number(_form.get('amount')) * 100,
              credit_card: {
                card_number: _form.get('number'),
                card_verification: _form.get('csv'),
                card_exp_month: _form.get('expiration_month'),
                card_exp_year: _form.get('expiration_year')
              }
            }
        }

        FetchHelper({
            url: `/api/v2/admin/orders/${_apis.get('order').id}/transactions`,
            method: 'post',
            body: body
        }, (res) => {
            _api.set(res.body)

            if (res.status === 200 && res.body?.success) {
                _transitions.set({
                    currentView: 'progress',
                    nextView: 'complete'
                })
            } else if (res.status === 200 && !res.body?.success) {
                _state.set('error', { error: res.body.message })

                _transitions.set({
                    currentView: 'progress',
                    nextView: 'form'
                })
            }
        }, (res) => {
            if (res.status === 422) {
                _errors.set(res.body)
            }

            _transitions.set({
                currentView: 'progress',
                nextView: 'form'
            })
        })  
    }

    const generateExpirationYearOptions = () => {
        let options = []

        Array(20).fill(0).forEach((item, index) => {
            let year = dayjs().add(index, 'year').format('YYYY')

            options.push({
                value: year,
                title: year,
            })
        })

        return options
    }

    const generateExpirationMonthOptions = () => {
        let options = []

        Array(12).fill(0).forEach((item, index) => {
            let month = dayjs().month(index).format('MMMM')

            options.push({
                value: `${index}`,
                title: month,
            })
        })

        return options
    }

    const isPayButtonDisabled = () => {
        let returnValue = true

        if (_form.get('number') && _form.get('csv') && _form.get('expiration_month') && _form.get('expiration_year') && _form.get('amount')) {
            returnValue = false
        }

        return returnValue
    }

    const handlePayClick = () => {
        _transitions.set({
            currentView: 'form',
            nextView: 'progress'
        })

        syncPost()
    }

    const handleClose = () => {
        if (_api.get('success')) {
            RouteHelper.reload()
        } else {
            _togglesModal.set('pay', false)
        }
    }

    const handleClearClick = () => {
        _form.set(DEFAULTS)
        _state.set('clearCount', _state.get('clearCount') + 1)
    }

    const renderView = () => {
        switch (_state.get('currentView')) {
            case 'form':
                if (_paymentAccount.type === 'Stripe')
                    return renderViewPaymentGatewayNotSupported()
                else
                    return renderViewForm()
            case 'progress':
                return renderViewProgress()
            case 'complete':
                return renderViewComplete()
        }
    }
    
    const handleTransition = () => {
        if (_transitions.get('nextView') !== null) {
            _state.set('currentView', _transitions.get('nextView'))

            _transitions.set({
                currentView: null,
                nextView: null
            })
        }
    }

    const renderViewComplete = () => {
        return (
            <UI.Layout name="payComplete" type="container">
                <HeadingComponent type="h3">Payment Complete</HeadingComponent>
                <div className="ui--payCompleteIcon">
                    <i className="ui-icon bi bi-check-circle"></i>
                </div>
                <div className="ui--payCompleteUIControls">
                    <ButtonComponent config={{ palette: "blue" }} events={{ handleClick: () => RouteHelper.reload() }}>Done</ButtonComponent>
                </div>
            </UI.Layout>
        )
    }

    const renderViewProgress = () => {
        return (
            <UI.Layout
                name="payProgress"
                type="container"
                config={{ classNames: _transitions.get('currentView') === 'progress' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <ParagraphComponent>Processing payment...</ParagraphComponent>

                <div className="ui--payProgressSpinner">
                    <PlaceholderGenericSpinnerComponent />
                </div>
            </UI.Layout>
        )
    }

    const renderViewPaymentGatewayNotSupported = () => {
        return (
            <UI.Layout
                name="paymentNotSupported"
                type="container"
                config={{ classNames: _transitions.get('currentView') === 'form' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                <HeadingComponent type="h3">Payment not Supported</HeadingComponent>

                <ParagraphComponent>
                    <strong>Stripe</strong> is currently not a supported payment gateway.
                </ParagraphComponent>

                <div className="ui--controls">
                    <ButtonComponent config={{ palette: 'blue' }} events={{ handleClick: () => _togglesModal.set('pay', false) }}>Close</ButtonComponent>
                </div>
            </UI.Layout>
        )
    }

    const renderViewForm = () => {
        return (
            <UI.Layout
                name="payForm"
                type="container"
                config={{ classNames: _transitions.get('currentView') === 'form' ? 'ui-animation--fadeOut' : '' }}
                callbacks={{ onAnimationEnd: handleTransition }}>
                
                <Form name="payForm" key={`ui-key--payForm--cleared--${_state.get('clearCount')}`}>
                    <HeadingComponent type="h3">Pay Amount</HeadingComponent>

                    <Layout.Section>
                        <FormFieldErrorComponent fieldName="error" errors={_state.get('error')} />
                    </Layout.Section>

                    <FormRow>
                        <LabelComponent htmlFor="number">Credit Card Number</LabelComponent>
                        <FormCreditCardNumberComponent name="number" value={_form.get('number')} config={{ hasError: !!_errors.get('number') }} events={{ handleChange: (value) => _form.set('number', value) }} />
                        <FormFieldErrorComponent fieldName="number" errors={_errors.get()} />
                    </FormRow>

                    <div className="ui--creditCardDetails">
                        <FormRow>
                            <LabelComponent htmlFor="expiration_month">Exp. Month</LabelComponent>
                            <SelectComponent name="expiration_month" value={_form.get('expiration_month') || ''} config={{ hasError: !!_errors.get('expiration_month') }} options={generateExpirationMonthOptions()} events={{ handleChange: (value) => _form.set('expiration_month', value) }} />
                            <FormFieldErrorComponent fieldName="expiration_month" errors={_errors.get()} />
                        </FormRow>
                        <FormRow>
                            <LabelComponent htmlFor="expiration_year">Exp. Year</LabelComponent>
                            <SelectComponent name="expiration_year" value={_form.get('expiration_year') || ''} config={{ hasError: !!_errors.get('expiration_year') }} options={generateExpirationYearOptions()} events={{ handleChange: (value) => _form.set('expiration_year', value) }} />
                            <FormFieldErrorComponent fieldName="expiration_year" errors={_errors.get()} />
                        </FormRow>
                        <FormRow>
                            <LabelComponent htmlFor="csv">CSV</LabelComponent>
                            <InputComponent name="csv" type="text" value={_form.get('csv')} config={{ hasError: !!_errors.get('csv'), maxLength: 6 }} events={{ handleChange: (value) => _form.set('csv', value) }} />
                            <FormFieldErrorComponent fieldName="csv" errors={_errors.get()} />
                        </FormRow>
                    </div>

                    <div className="ui--creditCardAmount">
                        <FormRow>
                            <LabelComponent htmlFor="amount">Amount</LabelComponent>
                            <FormFieldMoneyComponent name="amount" value={_form.get('amount')} config={{ hasError: !!_errors.get('amount') }} events={{ handleChange: (value) => _form.set('amount', value) }} />
                            <FormFieldErrorComponent fieldName="amount" errors={_errors.get()} />
                        </FormRow>
                    </div>

                    <div className="ui--controls">
                        <ButtonComponent config={{ palette: 'blue', disabled: isPayButtonDisabled() }} events={{ handleClick: handlePayClick }}>Pay</ButtonComponent>
                        <ButtonComponent config={{ palette: 'defaultLight' }} events={{ handleClick: handleClearClick }}>Clear</ButtonComponent>
                    </div>
                </Form>
            </UI.Layout>
        )
    }

    return (
        <ModalComponent config={{ classNames: `ui--payModal`, classNamesInner: _state.get('currentView') === 'complete' ? 'ui-action--centered' : '' }} events={{ handleClose: handleClose }}>
            {renderView()}
        </ModalComponent>
    )
}

// PayModalComponent.propTypes = {

// }

export { PayModalComponent }