import { useEffect, useState } from 'react';
import { List, PageHeader, Search, Tags } from '../../components';
import { useNotices } from '../../hooks';
import './index.css';
import { Utils } from '../../helpers';

const baseUrl = '/enhancements';
const baseApiUrl = '/api/v2/admin/enhancements';

const Enhancements = () => {
  const [NoticeComponent, notices] = useNotices('PSM/Enhancements/Index');

  const [page, setPage] = useState(1);
  const [cachedList, setCachedList] = useState({ data: [] });
  const [showTags, setShowTags] = useState(false);
  const [tags, setTags] = useState({ all: [], active: [] });
  const [filteredList, setFilteredList] = useState({ data: [] });

  const handleSearchAfterFetch = (results) => {
    setCachedList(results);
  };

  useEffect(() => {
    let filteredList = {
      ...cachedList,
      data: cachedList.data.map((item) => {
        item.ui_value = Utils.formatDollar(item.value);
        item.ui_name_route = `${baseUrl}/${item.id}/edit`;
        return item;
      })
    };

    setFilteredList(filteredList);
  }, [cachedList]);

  return (
    <div>
      <PageHeader
        title="Enhancements"
        actions={[{ title: 'New Enhancement', route: '/enhancements/new' }]}
      />
      <Search
        apiUrl={baseApiUrl}
        page={page}
        setPage={setPage}
        afterFetch={handleSearchAfterFetch}
        showTags={showTags}
        setShowTags={setShowTags}
        tags={tags}
      />
      <Tags
        apiUrl={`${baseApiUrl}/tags`}
        showTags={showTags}
        onSelect={(activeTags) => setTags(activeTags)}
      />
      <NoticeComponent location="List" notices={notices} />
      {filteredList && (
        <List
          list={filteredList}
          setPage={setPage}
          baseUrl={baseUrl}
          baseApiUrl={baseApiUrl}
          columns={[
            {
              title: 'Name',
              key: 'name',
              type: 'url_route',
              route: 'ui_name_route',
              hideTitleOnMobile: true
            },
            {
              title: 'Display Name',
              key: 'display_name'
            },
            {
              title: 'Value',
              key: 'ui_value'
            }
          ]}
        />
      )}
    </div>
  );
};

export default Enhancements;
