import {useState, useEffect, useContext} from 'react'

import {AppContext} from '../../AppContext'
import APIHelper from '../../helpers/api'
import FetchHelper from '../../helpers/fetch'
import PageHeaderComponent from '../../components/PageHeader'
import PickerDate from '../../components/PickerDate'
import SelectPaginatedComponent from '../../components/SelectPaginated'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import SwitchComponent from '../../components/Switch'

import './index.css'

const DownloadsPage = () => {
  const [ac] = useAppContext()

  const [NoticeComponent, notices] = useNotices('Reports/Downloads/Index')
  
  const [errors, setErrors] = useState()
  const [selectedType, setSelectedType] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [markets, setMarkets] = useState()
  const [selectedMarket, setSelectedMarket] = useState()
  const [disableXMLButton, setDisableXMLButton] = useState(true)
  const [disableCSVButton, setDisableCSVButton] = useState(true)
  const [disablePDFButton, setDisablePDFButton] = useState(true)
  const [query, setQuery] = useState('')
  const [customTypes, setCustomTypes] = useState()
  const [selectedCustomType, setSelectedCustomType] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [byPackage, setByPackage] = useState(false)
  const [ddlabSort, setDDLabSort] = useState()
  const [customFields, setCustomFields] = useState([])

  // sort
  // --------------------------------------------------
  const [dataSort, setDataSort] = useState([''])
  const [optionsSort, setOptionsSort] = useState([
    {
      title: 'Invoice #',
      value: 'order:id'
    },
    {
      title: 'Purchase Date',
      value: 'order:purchased_at'
    },
    {
      title: 'Billing First Name',
      value: 'customer:first_name'
    },
    {
      title: 'Billing Last Name',
      value: 'customer:last_name'
    },
  ])

  const updateSortField = (value, index) => {
    let newState = [...dataSort]

    newState[index] = value

    setDataSort(newState)
    setQuery(newState.filter((item, i) => {
      if (item === '') {
        return false
      }

      return newState.indexOf(item) === i
    }).join('|'))
  }

  const removeSortField = (index) => {
    let newState = [...dataSort]

    newState.splice(index, 1)

    setDataSort(newState)
  }

  const addSortField = () => {
    let newState = [...dataSort]

    newState.push('')

    setDataSort(newState)
  }
  // -----------------------------------------------

  useEffect(() => {
    APIHelper.getMarketsGrouped(setMarkets)
    setStartDate(UtilsHelper.getLocalStorage('Downloads_startDate') || UtilsHelper.getDate())
    setEndDate(UtilsHelper.getLocalStorage('Downloads_endDate') || UtilsHelper.getDate())
  }, [])

  useEffect(() => {
    if (selectedMarket) {
      FetchHelper({
            url: `/api/v2/admin/markets/${selectedMarket}/fields`
        }, (res) => {
            ac.showSpinner(false)
            setIsFetching(false)

            setCustomFields(res.body.data.map((item) => {
              return {
                title: item.name,
                value: `custom:${item.id}`
              }
            }))
        }, (res) => {
            ac.showSpinner(false)
            setIsFetching(false)
        })
    }
  }, [selectedMarket])

  useEffect(() => {
    if (markets) {
      setIsFetching(false)
      ac.showSpinner(false)
    }
  }, [markets])

  const handleDownload = (extension, onSuccess, onFailure) => {
    setErrors([])
    setIsFetching(true)
    ac.showSpinner(true)

    FetchHelper({
          url: `/api/v2/admin/markets/${selectedMarket}/downloads/${selectedType}.${extension}?dl=1&start_date=${startDate}&end_date=${endDate}${query !== '' && /address|barcode|ddlab/.test(selectedType) ? `&sort=${query}` : ''}${selectedCustomType !== '' && selectedType === 'custom' ? `&custom_report_id=${selectedCustomType}` : ''}${/drums|roes/.test(selectedType) && byPackage ? `&package=1` : ''}`,
          blob: true
      }, (res) => {
          ac.showSpinner(false)
          setIsFetching(false)

          onSuccess(res)
      }, (res) => {
          ac.showSpinner(false)
          setIsFetching(false)

          if (res.status === 204) {
            setErrors(['No orders'])
          } else if (res.status === 422) {
            setErrors(['Date range required'])
          } else if (res.status === 404) {
            setErrors(['Customer report not found'])
          }

          onFailure(res)
      })
  }

  const handleDownloadXML = () => {
    handleDownload('xml', (res) => {

    }, (res) => {

    })
  }

  const handleDownloadCSV = () => {
    handleDownload('csv', (res) => {

    }, (res) => {

    })
  }

  const handleDownloadPDF = () => {
    handleDownload('pdf', (res) => {

    }, (res) => {

    })
  }

  const handleSelectionAddress = () => {
    setSelectedType('address')
    setDisablePDFButton(false)
    setDisableCSVButton(true)
    setDisableXMLButton(true)
  }

  const handleChangeAddress = (newState) => {
    setQuery(newState)
  }

  const handleChangeBarcode = (newState) => {
    setQuery(newState)
  }

  const handleChangeDDLab = (newState) => {
    setQuery(newState)
  }

  const handleSelectionBarcode = () => {
    setSelectedType('barcode')
    setDisablePDFButton(false)
    setDisableCSVButton(true)
    setDisableXMLButton(true)
  }

  const handleSelectionDrums = () => {
    setSelectedType('drums')
    setDisablePDFButton(true)
    setDisableCSVButton(false)
    setDisableXMLButton(false)
  }

  const handleSelectionRoes = () => {
    setSelectedType('roes')
    setDisablePDFButton(true)
    setDisableCSVButton(false)
    setDisableXMLButton(true)
  }

  const handleSelectionCustomer = () => {
    setSelectedType('customer')
    setDisablePDFButton(true)
    setDisableCSVButton(false)
    setDisableXMLButton(true)
  }

  const handleSelectionDP2 = () => {
    setSelectedType('dp2')
    setDisablePDFButton(true)
    setDisableCSVButton(false)
    setDisableXMLButton(true)
  }

  const handleSelectionDDLab = () => {
    setSelectedType('ddlab')
    setDisablePDFButton(false)
    setDisableCSVButton(true)
    setDisableXMLButton(true)
  }

  const handleSelectionCustom = () => {
    setSelectedType('custom')
    setDisablePDFButton(true)
    setDisableCSVButton(false)
    setDisableXMLButton(true)

    setIsFetching(true)
    ac.showSpinner(true)

    FetchHelper({
        url: `/api/v2/admin/custom-reports`
    }, (res) => {
        ac.showSpinner(false)
        setIsFetching(false)
        setCustomTypes(res.body.data)
    }, (res) => {
        ac.showSpinner(false)
        setIsFetching(false)
    })
  }

  const handleStartDate = (newDate) => {
    UtilsHelper.setLocalStorage('Downloads_startDate', newDate)

    setStartDate(newDate)
  }

  const handleEndDate = (newDate) => {
    UtilsHelper.setLocalStorage('Downloads_endDate', newDate)

    setEndDate(newDate)
  }

  const getClassName = (selectionType) => {
    return (selectedType === selectionType) ? 'primary' : 'secondary'
  }

  return (
    <div className="downloads-page-container page-container">
      <PageHeaderComponent title="Downloads" />

      <NoticeComponent location="Main" notices={notices} />

      <div className="downloads-container">
        {errors && errors.length && (<div className="row errors">{errors[0]}</div>) || null}

        <div className="row label">Dates</div>

        <div className="row dates flex">
          <PickerDate initialValue={startDate} onChange={handleStartDate} dateFormat="YYYY-MM-DD" /> <span className="icon bi bi-dash-lg"></span> <PickerDate initialValue={endDate} onChange={handleEndDate} dateFormat="YYYY-MM-DD" />
        </div>

        <div className="row label">Reporting</div>

        <div className="row types">
          <button onClick={handleSelectionAddress} className={getClassName('address')}>Address</button>
          <button onClick={handleSelectionBarcode} className={getClassName('barcode')}>Barcode</button>
          <button onClick={handleSelectionDrums} className={getClassName('drums')}>DRUMS</button>
          <button onClick={handleSelectionRoes} className={getClassName('roes')}>ROES</button>
          <button onClick={handleSelectionCustomer} className={getClassName('customer')}>Customer</button>
          <button onClick={handleSelectionDP2} className={getClassName('dp2')}>DP2</button>
          <button onClick={handleSelectionDDLab} className={getClassName('ddlab')}>DDLab</button>
          <button onClick={handleSelectionCustom} className={`custom-button ${getClassName('custom')}`}>Custom</button> {selectedType === 'custom' && customTypes && (
            <select value={selectedCustomType} className="custom-select" onChange={(e) => setSelectedCustomType(e.target.value)}>
              <option>--</option>
              {customTypes.map((item, i) => {
                return <option key={`custom-type-${i}`} value={item.id}>{item.name}</option>
              })}
            </select>
          ) || null}
        </div>

        <div className={isFetching ? 'disabled' : ''}>
          <div className="row options">
            <div className="row label">Market</div>
            <div className="row">
              <SelectPaginatedComponent apiUrl="/api/v2/admin/markets" onSelect={(option) => setSelectedMarket(option.value)} />
            </div>

            {/address|barcode|ddlab/.test(selectedType) && customFields && (
              <div className="sort-container">
                <div className="row label">Sort</div>
                {dataSort && dataSort.length && dataSort.map((item, i) => {
                  return (
                    <div key={`sort-${i}`} className="row sort">
                      <select value={dataSort[i]} onChange={(e) => updateSortField(e.target.value, i)}>
                        <option>--</option>
                        {[...optionsSort, ...customFields].map((option, ii) => {
                          return <option key={`downloads-sort-${ii}`} value={option.value}>{option.title}</option>
                        })}
                      </select> {i === 0 && (<button disabled={dataSort.length >= optionsSort.length + customFields.length ? 'disabled' : ''} className="bi bi-plus-lg" onClick={addSortField}></button>) || null} {i !== 0 && <button className="bi bi-trash-fill delete" onClick={() => removeSortField(i)}></button> || null}
                    </div>
                  )
                })}
              </div>
            ) || null}
          </div>

          {/roes|drums/.test(selectedType) && <div className="row package"><SwitchComponent value={byPackage} onChange={setByPackage} />&emsp;By package?</div> || null}

          <div className="row controls">
            <div className="row downloads">
              <button onClick={handleDownloadXML} disabled={disableXMLButton}><i className="icon bi bi-filetype-xml"></i> Download XML</button>
              <button onClick={handleDownloadCSV} disabled={disableCSVButton}><i className="icon bi bi-filetype-csv"></i> Download CSV</button>
              <button onClick={handleDownloadPDF} disabled={disablePDFButton}><i className="icon bi bi-filetype-pdf"></i> Download PDF</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DownloadsPage
