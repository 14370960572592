import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const CheckboxComponent = ({ name, value, events={}, eventsDOM={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        'input': value
    })

    const handleOnChange = (e) => {
        const value = !_state.get('input')

       _state.set('input', value)

        if (events.handleChange)
            events.handleChange(value)
    }

    const decorateEventsDOM = () => {
        let eventsConfig = {...eventsDOM}

        delete eventsConfig.onChange

        return eventsConfig
    }

    return (
        <div className={`ui-container--checkbox ${config.classNames || ''}`}>
            <input
                type="checkbox"
                name={name}
                checked={_state.get('input')}
                onChange={handleOnChange}
                {...decorateEventsDOM()} />

            <div className={`ui--checkboxBox ${_state.get('input') ? 'ui-action--checked' : ''}`}>
                <span className="ui-icon material-symbols-outlined">check_small</span>
            </div>
        </div>
    )
}

CheckboxComponent.propTypes = {
    name: PropTypes.string,
    
    value: PropTypes.bool,

    events: PropTypes.shape({
        handleChange: PropTypes.func,
    }),

    eventsDOM: PropTypes.object,

    config: PropTypes.shape({
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        classNames: PropTypes.string
    })
}

export { CheckboxComponent }