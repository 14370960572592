import {useState, useEffect} from 'react'

import SpinnerComponent from '../components/Spinner'

import useFetch from '../hooks/useFetch'

import IconX from '../assets/icon-x.svg'
import './Tags.css'

const TagsComponent = ({ apiUrl, showTags, onSelect, value=[] }) => {
    const { FetchHelper } = useFetch()

    const [tags, setTags] = useState(value)
    const [activeTags, setActiveTags] = useState({})
    const [isFetching, setIsFetching] = useState(false)

    const handleTagClick = (e) => {
        const selectedTag = e.target.dataset.tag

        const tags = {
            ...activeTags,
            [selectedTag]: !activeTags[selectedTag]
        }

        setActiveTags(tags)
    }

    const getActiveTags = () => {
        const listOfTags = []

        for (const key in activeTags) {
            if (activeTags[key]) {
                listOfTags.push(key)
            }
        }

        return listOfTags
    }

    useEffect(() => {
        if (isFetching)
            return

            setIsFetching(true)

        FetchHelper({
            url: apiUrl
        }, (res) => {
            setIsFetching(false)            
            setTags(res.body)
        }, (res) => {
            setIsFetching(false)
        })
    }, [])

    useEffect(() => {
        onSelect({
            all: tags,
            active: getActiveTags()
        })
    }, [activeTags, tags])

    const renderTags = () => {
        return tags.map((tag) => {
            return (
                <li key={`tag-${tag}`}><button data-tag={tag} className={`tags-tag ${(activeTags[tag] && 'action-active') || ''}`} onClick={handleTagClick}><span>{tag}</span> <img src={IconX} alt="" className="tags-tag-x" /></button></li>
            )
        })
    }

    if (!showTags)
        return null

    return (
        <div className="tags-container">
            <SpinnerComponent isLoading={isFetching} />
            {renderTags()}
        </div>
    )
}

export default TagsComponent