import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const FormFieldErrorComponent = ({ fieldName, errors={}, config={} }) => {
    if (!errors[fieldName])
        return

    return (
        <UI.Layout name="formFieldError" type="container" config={{ classNames: config.classNames }}>
            <div className="ui--errorIcon">
                <i className="bi bi-exclamation-triangle-fill"></i>
            </div>
            
            <div className="ui--errorMessage">
                {errors[fieldName]}
            </div>
        </UI.Layout>
    )
}

FormFieldErrorComponent.propTypes = {
    fieldName: PropTypes.string.isRequired,

    errors: PropTypes.object.isRequired,

    config: PropTypes.shape({
        classNames: PropTypes.string
    })
}

export { FormFieldErrorComponent }
