import { useState, useEffect } from 'react'

import FetchHelper from '../../helpers/fetch'
import PageHeaderComponent from '../../components/PageHeader'

import PapaParse from 'papaparse'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import './index.css'
import { Form } from '../../components'
import SampleImportFile from '../../assets/samples/scheduling-importer-sample.csv'
import { useTabs } from '../../hooks'
import { FileTypes } from '../../sys/constants/enums'
import { Utils } from '../../helpers'
import useFullList from '../../hooks/useFullList'
import lodash from 'lodash'

const ImporterPage = () => {
    const [ac] = useAppContext()
    const [tabs, tab, setTab, TabsComponent] = useTabs('Schedules', ['Schedules', 'Blackouts', 'Markets', 'Packages', 'Addons', 'Coupons', 'Backgrounds', 'Sheets', 'Poses'])

    const [NoticeComponent, notices] = useNotices('Tools/Importer')
    const { getFullListData } = useFullList()

    const defaultData = {
        replace: true, // true = replace, false = append
        import_file: null
    }
    const [schedulesData, setSchedulesData] = useState({ ...defaultData })
    const [blackoutsData, setBlackoutsData] = useState({ ...defaultData })
    const [marketsData, setMarketsData] = useState({ ...defaultData })
    const [packagesData, setPackagesData] = useState({ ...defaultData })
    const [addonsData, setAddonsData] = useState({ ...defaultData })
    const [couponsData, setCouponsData] = useState({ ...defaultData })
    const [backgroundsData, setBackgroundsData] = useState({ ...defaultData })
    const [sheetsData, setSheetsData] = useState({ ...defaultData })
    const [posesData, setPosesData] = useState({ ...defaultData })

    const [schedulesErrors, setSchedulesErrors] = useState({})
    const [blackoutsErrors, setBlackoutsErrors] = useState({})
    const [marketsErrors, setMarketsErrors] = useState({})
    const [packagesErrors, setPackagesErrors] = useState({})
    const [addonsErrors, setAddonsErrors] = useState({})
    const [couponsErrors, setCouponsErrors] = useState({})
    const [backgroundsErrors, setBackgroundsErrors] = useState({})
    const [sheetsErrors, setSheetsErrors] = useState({})
    const [posesErrors, setPosesErrors] = useState({})

    const downloadMarketList = async () => {
        ac.showSpinner(true)

        const marketsListData = await getFullListData(`/api/v2/admin/markets`, true)

        const now = new Date()
        const globalExpiration = marketsListData?.global_expiration && new Date(marketsListData?.global_expiration)
        let marketsList = lodash
            .chain(marketsListData.data)
            .map(market => {
                const startsAt = new Date(market.starts_at)
                const endsAt = market.ends_at && new Date(market.ends_at)
                if (endsAt && endsAt > now) {
                    market.isActive = true
                } else if(!endsAt && !globalExpiration) {
                    market.isActive = true
                } else {
                    market.isActive = startsAt < now && (now < (endsAt || globalExpiration)) ? true : false
                }

                return market
            })
            .value()

        // --- prepare csv data to download
        let columns = [
            { title: 'Internal ID', key: 'id' },
            { title: 'External ID', key: 'external_id' },
            { title: 'Name', key: 'name' },
            { title: 'Theme', key: 'type' },
            { title: 'Scheduling Enabled?', key: 'options.use_scheduling', type: 'boolean'},
            { title: 'Active?', key: 'isActive', type: 'boolean' },
            { title: 'Archived?', key: 'deleted_at', type: 'boolean' }
        ]
        let dataArray = Utils.tableDataToCSVCompatibleArray(
            { columns, data: marketsList },
            true,
            { boolean: (value) => value ? 'Yes' : 'No' }
        )
        let csvData = Utils.arrayToCsv(dataArray)
        Utils.exportToCsv(csvData, 'markets-list')
        ac.showSpinner(false)
    }

    const onFileChange = (file, tab) => {
        if(!file) return;
        let errors = tab === 'Schedules' ? schedulesErrors : blackoutsErrors
        let setErrors = tab === 'Schedules' ? setSchedulesErrors : setBlackoutsErrors

        PapaParse.parse(schedulesData.import_file?.value, {
            header: false,
            error: (err, file, inputElem, reason) => {
                let currentErrors = { import_file: [reason] }
                setErrors({ ...errors, ...currentErrors })
            },
            complete: results => {
                console.log(results)
                if(results.errors) {
                    let importFilesErrors = results.errors.map(e => e.message)
                    if(importFilesErrors.length > 0) console.error(importFilesErrors);
                    
                    let currentErrors = importFilesErrors.length > 0 ? { import_file: ['Invalid file format'] } : {}
                    setErrors({ ...errors, ...currentErrors })
                }
            }
        })
    }

    useEffect(() => {
        onFileChange(schedulesData.import_file?.value, 'Schedules')
    }, [schedulesData.import_file])

    useEffect(() => {
        onFileChange(blackoutsData.import_file?.value, 'Blackouts')
    }, [blackoutsData.import_file])

    const renderImporter = (formData, setFormData, formErrors) => {
        return <div className="importer-container">
                <Form formData={formData} onChange={setFormData} formErrors={formErrors} displayErrorBanner={true} fields={[
                    {
                        type: 'switch',
                        label: 'Import Method',
                        name: 'replace',
                        field_label: (<label className='ml-2'>{formData.replace ? 'Replace' : 'Append/Merge'}</label>)
                    },
                    {
                        type: 'input-file',
                        name: 'import_file',
                        label: 'Import File',
                        fileType: '',
                        outputFormat: 'file',
                        acceptedFileTypes: FileTypes.CSV,
                        inputDescription: (
                            <>
                                <div className='flex mt-2'>
                                    <a className='link' href={SampleImportFile} download='scheduling-importer-sample.csv'>Download Sample File</a>
                                    <span className='mx-2'>|</span>
                                    <a className='link' onClick={downloadMarketList}>Download Market List</a>
                                </div>
                            </>
                        )
                    }
                    ]} />
            </div>
    }

    const renderTabContent = (tab) => {
        switch (tab) {
            case 'Schedules':
                return renderImporter(schedulesData, setSchedulesData, schedulesErrors)
            case 'Blackouts':
                return renderImporter(blackoutsData, setBlackoutsData, blackoutsErrors)
            case 'Markets':
                return renderImporter(marketsData, setMarketsData, marketsErrors)
            case 'Packages':
                return renderImporter(packagesData, setPackagesData, packagesErrors)
            case 'Addons':
                return renderImporter(addonsData, setAddonsData, addonsErrors)
            case 'Coupons':
                return renderImporter(couponsData, setCouponsData, couponsErrors)
            case 'Backgrounds':
                return renderImporter(backgroundsData, setBackgroundsData, backgroundsErrors)
            case 'Sheets':
                return renderImporter(sheetsData, setSheetsData, sheetsErrors)
            case 'Poses':
                return renderImporter(posesData, setPosesData, posesErrors)
        }
    }

    return (
        <div className="importer-page-container page-container">
            <PageHeaderComponent title={`Scheduling Importer`} />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} />
            <NoticeComponent location={`Tab/${tab}`} notices={notices} />
            { tab === 'Schedules' && renderTabContent(tab) }
            { tab === 'Blackouts' && renderTabContent(tab) }
            { tab === 'Markets' && renderTabContent(tab) }
            { tab === 'Packages' && renderTabContent(tab) }
            { tab === 'Addons' && renderTabContent(tab) }
            { tab === 'Coupons' && renderTabContent(tab) }
            { tab === 'Backgrounds' && renderTabContent(tab) }
            { tab === 'Sheets' && renderTabContent(tab) }
            { tab === 'Poses' && renderTabContent(tab) }
        </div>
    )
}

export default ImporterPage
