import PropTypes from 'prop-types'

import './index.css'

const CodeComponent = ({ code, config={} }) => {
    if (!code)
        return

    return (
        <div className="ui-container--code">{code}</div>
    )
}

CodeComponent.propTypes = {
    code: PropTypes.string
}

export { CodeComponent }