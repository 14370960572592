import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { useParams } from 'react-router-dom'

const CategoriesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Categories/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()

    const handleSubmit = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/categories/${data.id}`,
            method: 'PUT',
            body: {
                category: dataMain
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/categories')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/categories/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)

            setData({
                id: params.id,
                name: 'Miscellaneous'
            })
        })
    }, [])

    return (
        <div className="categories-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Category - ${dataMain?.name}`} />

            <NoticeComponent location="Main" notices={notices} />

            {data ? (
                <>
                    <FormComponent formData={dataMain || data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                        type: 'input-text',
                        name: 'name',
                        label: 'Name'
                    }]} />

                    <ControlBar baseApiUrl="/categories" redirectUrl="/categories" cancelUrl="/categories" data={data} handleSubmit={handleSubmit} />
                </>
            ) : null}
        </div>
    )
}

export default CategoriesEdit
