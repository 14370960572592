export function getGeneralFormFields(
  data,
  selectedProviderEnhancement,
  providerEnhancementChangeFilter,
  deleteImage,
  onDeleteChange
) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name',
      placeholder: 'Retouch ($5.00)'
    },
    {
      type: 'input-text',
      name: 'display_name',
      label: 'Display Name',
      placeholder: 'Retouching'
    },
    {
      type: 'select-paginated',
      name: 'lab_lab_enhancement_id',
      label: 'Provider Enhancement',
      apiUrl: '/api/v2/admin/enhancements/providers',
      onChangeFilter: providerEnhancementChangeFilter
    },
    {
      type: 'input-money',
      name: 'value',
      label: 'Value',
      value: '0.0',
      step: '0.05',
      min: '0',
      label_info: `Base Cost: ${
        selectedProviderEnhancement?.base_cost || '0.0'
      }`
    },
    {
      type: 'input-file',
      name: 'image_base64',
      label: 'Image',
      imageUrl: data?.image_base64,
      field_info: data?.image_base64
        ? [
            <div key={`delete-image`} className="flex items-center">
              <input
                type="checkbox"
                onChange={onDeleteChange}
                name="customFiles"
                value={deleteImage}
              />
              <span className="ml-2">Delete Image?</span>
            </div>
          ]
        : ''
    },
    {
      type: 'wysiwyg',
      name: 'description',
      label: 'Description'
    }
  ];
}
