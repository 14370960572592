import { useEffect } from "react";
import useAppContext from "./useAppContext";
import { FetchAbortReason } from "../sys/constants/enums";

export default function useTearDown(fetchControllers) {
  const [ac] = useAppContext();

  useEffect(() => {
    return () => {
      ac.showSpinner(false);
      fetchControllers.current.forEach((c) =>
        c.abort(FetchAbortReason.PAGE_TERMINATED)
      );
      fetchControllers.current = [];
    };
  }, []);
}
