import { useState } from 'react'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import ListComponent from '../../components/List'
import TagsComponent from '../../components/Tags'
import UtilsHelper from '../../helpers/utils'

import useNotices from '../../hooks/useNotices'

const ColorSchemes = () => {
    const [NoticeComponent, notices] = useNotices('UserExperience/ColorSchemes/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [showTags, setShowTags] = useState(false)
    const baseUrl = '/color-schemes'
    const baseApiUrl = '/api/v2/admin/color-schemes'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_name_route = `${baseUrl}/${item.id}/edit`

            return item
        })
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="Color Schemes" actions={[{ title: 'New Color Scheme', route: '/color-schemes/new' }]}  />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    }
                ]} />
        </div>
    )
}
export default ColorSchemes
