export const SUPPORTED_PAYMENT_GATEWAYS =  [
    { name: "Authorize.net", value: "AuthorizeNet" },
    { name: "Beanstream", value: "Beanstream" },
    { name: "Elavon", value: "Elavon" },
    { name: "Eway", value: "Eway" },
    { name: "NabTransact", value: "NabTransact" },
    { name: "NMI", value: "Nmi" },
    { name: "Payeezy", value: "Payeezy" },
    { name: "Stripe", value: "Stripe" },
    { name: "Square Developer", value: "SquareDeveloper" },
    { name: "USA ePay", value: "UsaEpay" }
]

export const PAYMENT_ACCOUNT_ATTR_TRANSLATIONS  = {
    AuthorizeNet: {
        login: "API Login ID",
        password: "Transaction Key"
    },
    Beanstream: {
        login: "User",
        password: "Password",
        merchant_id: "Merchant ID"
    },
    Elavon: {
        login: "User",
        password: "Password",
        merchant_id: "Login"
    },
    Eway: {
        login: "Login",
        password: "Password"
    },
    NabTransact: {
        login: "Login",
        password: "Password"
    },
    Nmi: {
        login: "Login",
        password: "Password"
    },
    Payeezy: {
        login: "API Key",
        password: "API Secret",
        merchant_id: "Merchant Token"
    },
    Stripe: {
        login: "Secret Live Key Token",
        password: "Publishable Key (Optional)"
    },
    SquareDeveloper: {
        login: "Application ID",
        password: "Access Token",
        merchant_id: "Location ID"
    },
    UsaEpay: {
        login: "Login",
        password: "Password",
        merchant_id: "Software ID"
    }
}
