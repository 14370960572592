import {useState} from 'react'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import UtilsHelper from '../../helpers/utils'

import useNotices from '../../hooks/useNotices'

const AppointmentProfilesPage = () => {
    const [NoticeComponent, notices] = useNotices('Scheduling/AppointmentProfiles/Index')

    const [list, setList] = useState({ data: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [showTags, setShowTags] = useState(false)
    const baseUrl = '/appointment-profiles'
    const baseApiUrl = '/api/v2/admin/appointment-profiles'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    const handleListAfterColumnTitleClick = () => {

    }

    const filteredList = {
        ...cachedList,
        data: cachedList.data.map((row) => {
            row.ui_name_route = `${baseUrl}/${row.id}/edit`
            row.ui_name = `${row.name} ${row.display_name ? `(${row.display_name})` : ''}`
            let appointmentSlots = row?.appointment_slots?.data || []
            row.ui_first_slot = appointmentSlots.length > 0 ? appointmentSlots[0].display_name : ''
            row.ui_last_slot = appointmentSlots.length > 0 ? appointmentSlots[appointmentSlots.length - 1].display_name : ''

            return row
        })
    }

    return (
        <div className="appointment-profiles-page-container page-container">
            <PageHeaderComponent title="Appointment Profiles" actions={[{ title: 'New Profile', route: '/appointment-profiles/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl} displayNameOnSeparateLine={true} afterColumnTitleClick={handleListAfterColumnTitleClick}
                columns={[
                    {
                        key: 'ui_name',
                        title: 'Name',
                        sortable: true,
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'total_capacity',
                        title: 'Capacity',
                        sortable: true
                    },
                    {
                        key: 'ui_first_slot',
                        title: 'First slot'
                    },
                    {
                        key: 'ui_last_slot',
                        title: 'Last slot'
                    }
                ]} dropdownOptions={[
                    {
                        title: 'Preview',
                        type: 'url',
                        url: 'ui_url'
                    }
                ]} />
        </div>
    )
}

export default AppointmentProfilesPage
