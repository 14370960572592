import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './index.css'

const AnchorComponent = ({ url, type, children, events={}, eventsDOM={}, config={} }) => {
    const handleClick = () => {
        window.open(url, '_blank')
    }
    
    const decorateEventsDOM = () => {
        const eventsConfig = {...events}

        return eventsConfig
    }

    const decorateAttributes = () => {
        const attributesConfig = {}

        if (type === 'external')
            attributesConfig.target = '_blank'

        return attributesConfig
    }

    let _html = <>
        {children} {type === 'external' && <span className="ui-icon material-symbols-outlined">open_in_new</span>}
    </>

    if (type === 'external')
        return (
            <a href={url} className={`ui-container--anchor ${config.classNames || ''}`} onClick={handleClick} {...decorateEventsDOM()} {...decorateAttributes()}>
                {_html}
            </a>
        )

    return (
        <Link to={url} className={`ui-container--anchor ${config.classNames || ''}`} {...decorateEventsDOM()} {...decorateAttributes()}>
            {_html}
        </Link>
    )
}

AnchorComponent.propTypes = {
    url: PropTypes.string.isRequired,

    type: PropTypes.string,

    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),

    config: PropTypes.shape({
        classNames: PropTypes.string
    }),

    events: PropTypes.object,

    eventsDOM: PropTypes.object
}

export { AnchorComponent }