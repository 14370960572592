import { useState, useEffect } from 'react'
import PageHeaderComponent from '../../components/PageHeader'
import TagsComponent from '../../components/Tags'
import TabsComponent from '../../components/Tabs'
import ListComponent from '../../components/List'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import IconSearch from '../../assets/icon-search-dark.svg'
import useDebounce from '../../helpers/debounce'

import useAppContext from '../../hooks/useAppContext'

import './index.css'
import dayjs from 'dayjs'

const Campaigns = () => {
    const [ac] = useAppContext()

    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [tab, setTab] = useState('Upcoming Campaigns')
    const [pageUpcoming, setPageUpcoming] = useState(1)
    const [pageCompleted, setPageCompleted] = useState(1)
    const [pageFlag, setPageFlag] = useState(false)
    const [markets, setMarkets] = useState()
    const [templates, setTemplates] = useState()
    const [upcomingList, setUpcomingList] = useState()
    const [completedList, setCompletedList] = useState()
    const [search, setSearch] = useState('')
    const [searchUpcoming, setSearchUpcoming] = useState('')
    const [searchCompleted, setSearchCompleted] = useState('')
    const debouncedTerm = useDebounce((search), 250)
    const baseUrl = '/campaigns'
    const baseApiUrl = '/api/v2/admin/campaigns'

    const columns = [{
        key: 'name',
        title: 'Name',
        type: 'url_route',
        route: 'ui_name_route'
    },
    {
        key: 'ui_market',
        title: 'Market',
    },
    {
        key: 'ui_template',
        title: 'Template',
    },
    {
        key: 'ui_data_source',
        title: 'Data Source',
    },
    {
        key: 'download'
    },
    {
        key: 'delete'
    }]

    const showName = (id, list) => {
        let name = ''
        list?.forEach(function (elem) {
            if (elem.id === id) {
                name = elem.name
            }
        })
        return name
    }

    const filterList = (list) => {
        const temp = list.filter((item) => {
            const sendAt = item.send_at?.replace(/([-+]\d{1,2}:\d{1,2})$/, '')
            const date = sendAt ? dayjs(sendAt).format('MMM DD, YYYY hh:mm A') : ''

            item.ui_market = showName(item.market_id, markets)
            item.ui_template = showName(item.template_id, templates)
            item.ui_name_route = `${baseUrl}/${item.id}/edit`
            item.ui_data_source = item.data_source === 'proof' ? 'Proof' : 'CSV'

            if (!item.sent) {
                item.sendAt = date
            } else {
                item.sent_at = date
            }

            return true
        })

        return temp
    }

    const pageChange = (p) => {
        setPageFlag(true)
        if (tab === 'Upcoming Campaigns') {
            setPageUpcoming(p)
        } else {
            setPageCompleted(p)
        }
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/markets`
        }, (res) => {
            ac.showSpinner(false)
            setMarkets(res.body.data)
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: `/api/v2/admin/templates`
        }, (res) => {
            ac.showSpinner(false)
            setTemplates(res.body.data)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    useEffect(() => {
        ac.showSpinner(true)

        if (markets && templates) {
            FetchHelper({
                url: `/api/v2/admin/campaigns?sent=1`
            }, (res) => {
                ac.showSpinner(false)
                const list = res.body
                setCompletedList({
                    ...list,
                    data: filterList(list.data)
                })
            }, (res) => {
                ac.showSpinner(false)
            })

            FetchHelper({
                url: `/api/v2/admin/campaigns?sent=0`
            }, (res) => {
                ac.showSpinner(false)
                const list = res.body
                setUpcomingList({
                    ...list,
                    data: filterList(list.data)
                })
            }, (res) => {
                ac.showSpinner(false)
            })
        }

    }, [markets, templates])

    useEffect(() => {
        ac.showSpinner(true)
        const sent = tab === 'Upcoming Campaigns' ? 0 : 1

        if (tab === 'Upcoming Campaigns') {
            setSearchUpcoming(search)
        } else {
            setSearchCompleted(search)
        }

        FetchHelper({
            url: `${baseApiUrl}?sent=${sent}&q=${search}`
        }, (res) => {
            const list = res.body

            if (tab === 'Upcoming Campaigns') {
                setUpcomingList({
                    ...list,
                    data: filterList(list.data)
                })
            } else {
                setCompletedList({
                    ...list,
                    data: filterList(list.data)
                })
            }
            ac.showSpinner(false)
        }, (res) => {
            const list = { data: [] }
            tab === 'Upcoming Campaigns' ? setUpcomingList(list) : setCompletedList(list)
            ac.showSpinner(false)
        })

    }, [debouncedTerm])

    useEffect(() => {

        if (pageFlag) {
            ac.showSpinner(true)
            const sent = tab === 'Upcoming Campaigns' ? 0 : 1
            const page = tab === 'Upcoming Campaigns' ? pageUpcoming : pageCompleted

            FetchHelper({
                url: `${baseApiUrl}?sent=${sent}&q=${search}&page=${page}`
            }, (res) => {
                if (tab === 'Upcoming Campaigns') {
                    setUpcomingList(res.body)
                } else {
                    setCompletedList(res.body)
                }
                ac.showSpinner(false)
                setPageFlag(false)
            }, (res) => {
                const list = { data: [] }
                tab === 'Upcoming Campaigns' ? setUpcomingList(list) : setCompletedList(list)
                ac.showSpinner(false)
            })
        }

    }, [pageFlag])

    useEffect(() => {
        if (tab === 'Upcoming Campaigns') {
            setSearch(searchUpcoming)
        } else {
            setSearch(searchCompleted)
        }
    }, [tab])

    const onChangeTab = (tab) => {
        switch (tab) {
            case 'Upcoming Campaigns':
                var renderColumns = [...columns]
                renderColumns.splice(1, 0, { key: 'sendAt', title: 'Send At' })
                return (
                    <ListComponent list={upcomingList} setPage={pageChange} baseUrl={baseUrl} showActions={true} baseApiUrl={baseApiUrl} copyEnabled={false}
                        columns={renderColumns}
                        additionalDropdownOptions={[
                            {
                                title: 'download',
                                type: 'html',
                                html: (ele) => {
                                    if (ele.csv_url) {
                                        return (
                                            <a href={ele.csv_url} download={`${ele.name}.csv`} title='Download' target='_blank'><i className="bi bi-download"></i></a>
                                        )
                                    }
                                }
                            }
                        ]}
                    />
                )
            case 'Completed Campaigns':
                var renderColumns = [...columns]
                renderColumns.splice(1, 0, { key: 'sent_at', title: 'Sent At' })
                return (
                    <ListComponent list={completedList} setPage={pageChange} baseUrl={baseUrl} showActions={true} baseApiUrl={baseApiUrl} copyEnabled={false}
                        columns={renderColumns}
                        customActions={(item) => {
                            return (
                                <ul className='dropdown-options'>
                                    <li className='dropdown-option'><a href={item.csv_url} title='Download' download={`${item.name}.csv`} target='_blank'><i className="bi bi-download"></i></a></li>
                                    <li className='dropdown-option'><a href={`#${baseUrl}/${item.id}/edit`} title='Details'><i className="bi bi-file-text"></i></a></li>
                                </ul>
                            )
                        }}
                    />
                )
            default:
                break;
        }
    }

    return (
        <div className='campaigns-page-container'>
            <PageHeaderComponent title="Campaigns" actions={[{ title: 'New Campaign', route: '/campaigns/new' }]} />
            <div className="search-container">
                <div className="search-input">
                    <div className="search-input-icon-search">
                        <img src={IconSearch} alt="" />
                    </div>
                    <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." />
                    {tags.all && tags.all.length ? <div className={`search-input-tab search-input-tab-tags ${(showTags && 'action-active') || ''}`} onClick={() => setShowTags(!showTags)}>
                        <i className={`icon-large bi bi-tags ${tags.active.length ? 'indicator' : ''}`}></i>
                    </div> : null}
                </div>
            </div>
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <TabsComponent tabs={['Upcoming Campaigns', 'Completed Campaigns']} tab={tab} setTab={setTab} />
            <div className="tab-content">
                {(upcomingList && completedList) && onChangeTab(tab)}
            </div>
        </div>
    )
}
export default Campaigns