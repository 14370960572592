import { UI } from '../../UI'

import {
    AnchorComponent,
    ButtonComponent, 
    CheckboxComponent,
    InputComponent,
    LabelComponent,
    ParagraphComponent,
    RadioComponent,
    SelectComponent,
    TextareaComponent
} from '../../componentsV2/Primitive'

import { FormFieldErrorComponent, FormFieldMoneyComponent } from '../../componentsV2/Form'

import {
    CarouselComponent,
    FileComponent,
    FileForImage,
    ImagePreview,
    IndicatorComponent,
    PaginationComponent,
    TabsWithUnderline,
    TagCollectionComponent,
} from '../../componentsV2/Complex'

import {
    EntityGridComponent,
    EntitySelectorModalComponent,
    EntityStackComponent
} from '../../componentsV2/Integrated'

import './index.css'

const selectDemoOptions = [
    {
        title: 'Option 1',
        value: 'option_1'
    },
    {
        title: 'Option 2',
        value: 'option_2'
    },
    {
        title: 'Option 3',
        value: 'option_3'
    },
]

const entityItems = [
    {
        id: 10,
        image_url: `https://picsum.photos/id/10/200/300`,
    },
    {
        id: 27,
        image_url: `https://picsum.photos/id/27/200/300`,
    },
    {
        id: 30,
        image_url: `https://picsum.photos/id/30/200/300`,
    },
    {
        id: 42,
        image_url: `https://picsum.photos/id/42/200/300`,
    },
    {
        id: 56,
        image_url: `https://picsum.photos/id/56/200/300`,
    },
    {
        id: 91,
        image_url: `https://picsum.photos/id/91/200/300`,
    },
]

const errors = {
    selectDemo2: 'This field is required',
    inputDemo5: 'Person not found',
    inputDemo6: 'Amount must be greater than $0'
}

const CheatSheetPage = () => {
    const {_state} = UI.useSimpleState({
        activeTab: 0
    })

    const {_state: _toggles} = UI.useSimpleState({
        entitySelectorModal: false
    })

    return (
        <UI.Layout name="cheatSheet" type="page">
            <div className="ui--cheatSheetHeader"></div>

            <div className="">
                {/* <TabsWithUnderline activeTab={_state.get('activeTab')} tabs={['Tab 1', 'Tab 2', 'Tab 3']} events={{ handleSelect: (tab) => _state.set('activeTab', tab) }} />
                <ImagePreview url={mockHeaders[_state.get('activeTab')].url} />
                <FileForImage events={{ handleSelect: (data) => console.log(data) }} /> */}
            </div>

            <div className="grid grid-cols-1 gap-2 mb-30">
                <div className="grid grid-cols-3 gap-2">
                    <ButtonComponent
                        config={{
                            palette: 'default',
                            tip: 'Example of the default palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>default</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'defaultLight',
                            tip: 'Example of the defaultLight palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>defaultLight</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'defaultDark',
                            tip: 'Example of the defaultDark palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>defaultDark</ButtonComponent>
                </div>

                <div className="grid grid-cols-3 gap-2">
                    <ButtonComponent
                        config={{
                            palette: 'blue',
                            tip: 'Example of the blue palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>blue</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'blueLight',
                            tip: 'Example of the blueLight palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>blueLight</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'blueDark',
                            tip: 'Example of the blueDark palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>blueDark</ButtonComponent>
                </div>

                <div className="grid grid-cols-3 gap-2">
                    <ButtonComponent
                        config={{
                            palette: 'red',
                            tip: 'Example of the red palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>red</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'redLight',
                            tip: 'Example of the redLight palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>redLight</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'redDark',
                            tip: 'Example of the redDark palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>redDark</ButtonComponent>
                </div>

                <div className="grid grid-cols-3 gap-2">
                    <ButtonComponent
                        config={{
                            palette: 'green',
                            tip: 'Example of the green palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>green</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'greenLight',
                            tip: 'Example of the greenLight palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>greenLight</ButtonComponent>
                    <ButtonComponent
                        config={{
                            palette: 'greenDark',
                            tip: 'Example of the greenDark palette'
                        }}
                        events={{ handleClick: () => console.log('click') }}>greenDark</ButtonComponent>
                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="grid grid-cols-2 gap-2">
                        <ButtonComponent
                            config={{
                                palette: 'red',
                                tip: 'Delete?',
                                size: 'small'
                            }}
                            events={{ handleClick: () => console.log('click') }}><span className="ui-icon bi bi-trash-fill"></span></ButtonComponent>

                        <ButtonComponent
                            config={{
                                palette: 'blue',
                                tip: 'Refresh?',
                                size: 'small'
                            }}
                            events={{ handleClick: () => console.log('click') }}><span className="ui-icon bi bi-arrow-repeat"></span></ButtonComponent>
                    </div>
                    <div className="flex gap-2">
                        <div>
                            <ButtonComponent
                                config={{
                                    noStyle: true
                                }}
                                events={{ handleClick: () => console.log('click') }}><i className="ui-icon bi bi-person-plus"></i></ButtonComponent>
                        </div>
                        <div>
                            <ButtonComponent
                                    config={{
                                        noStyle: true
                                    }}
                                    events={{ handleClick: () => console.log('click') }}><i className="ui-icon bi bi-bell"></i></ButtonComponent>
                            <IndicatorComponent />
                        </div>
                    </div>
                </div>
            </div>

            <hr className="ui-global--hr" />

            <div className="grid grid-cols-3 gap-2">
                <div>
                    <LabelComponent htmlFor="selectDemo1">Select</LabelComponent>
                    <SelectComponent name="selectDemo1" value={''} options={selectDemoOptions} config={{ title: 'Select option...' }} />
                </div>

                <div>
                    <LabelComponent htmlFor="selectDemo2">Select with error</LabelComponent>
                    <SelectComponent name="selectDemo2" value={''} options={selectDemoOptions} config={{ title: 'Select option...', hasError: true }} />
                    <FormFieldErrorComponent fieldName="selectDemo2" errors={errors} />
                </div>

                <div>
                    
                </div>
            </div>

            <hr className="ui-global--hr" />

            <div className="grid grid-cols-1 gap-2">
                <div className="grid grid-cols-3 gap-2">
                    <div>
                        <LabelComponent htmlFor="inputDemo1">Input</LabelComponent>
                        <InputComponent type="text" name="inputDemo1" value={''} events={{ handleChange: () => {} }} />
                    </div>

                    <div>
                        <LabelComponent htmlFor="inputDemo1">Input with cell</LabelComponent>
                        <InputComponent type="text" name="inputDemo2" value={''} config={{ cell: <span className="ui-icon bi bi-person-fill"></span> }} events={{ handleChange: () => {} }} />
                    </div>

                    <div>
                        <LabelComponent htmlFor="inputDemo3">Money input</LabelComponent>
                        <FormFieldMoneyComponent name="inputDemo3" value={''} events={{ handleChange: (value) => {} }} />
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="">
                        <LabelComponent htmlFor="inputDemo4">Disabled input</LabelComponent>
                        <InputComponent type="text" name="inputDemo4" value={''} config={{ disabled: true }} events={{ handleChange: () => {} }} />
                    </div>

                    <div className="">
                        <LabelComponent htmlFor="inputDemo5">Input with cell and error</LabelComponent>
                        <InputComponent type="text" name="inputDemo5" value={''} config={{ cell: <span className="ui-icon bi bi-person-fill"></span>, state: 'error' }} events={{ handleChange: () => {} }} />
                        <FormFieldErrorComponent fieldName="inputDemo5" errors={errors} />
                    </div>

                    <div className="">
                        <LabelComponent htmlFor="inputDemo6">Money input with error</LabelComponent>
                        <FormFieldMoneyComponent name="inputDemo6" value={''} config={{ state: 'error' }} events={{ handleChange: (value) => {} }} />
                        <FormFieldErrorComponent fieldName="inputDemo6" errors={errors} />
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-2">
                    <div className="">
                        <LabelComponent htmlFor="inputDemo5">Input with success</LabelComponent>
                        <InputComponent type="text" name="inputDemo5" value={''} config={{ state: 'success' }} events={{ handleChange: () => {} }} />
                    </div>

                    <div className="">
                        <LabelComponent htmlFor="inputDemo5">Input with cell and success</LabelComponent>
                        <InputComponent type="text" name="inputDemo5" value={''} config={{ cell: <span className="ui-icon bi bi-check-lg"></span>, state: 'success' }} events={{ handleChange: () => {} }} />
                    </div>

                    <div className="">
                        
                    </div>
                </div>
            </div>

            <hr className="ui-global--hr" />

            <div className="grid grid-cols-3 gap-2">
                <div>
                    <RadioComponent name="radioDemo1" value={''} options={selectDemoOptions} />
                </div>

                <div>
                    <CheckboxComponent name="checkboxDemo1" value={true} />
                </div>

                <div>
                    <TagCollectionComponent items={[
                        {
                            name: 'Tag 1'
                        },
                        {
                            name: 'Tag 2'
                        },
                        {
                            name: 'Tag 3'
                        },
                    ]} />
                </div>
            </div>

            <hr className="ui-global--hr" />

            <div className="grid grid-cols-3 gap-2">
                <div>
                    <TextareaComponent name="textareaDemo1" value="Sed ut perspiciatis unde omnis iste natus error sit voluptatem" />
                </div>

                <div>
                    <CarouselComponent>
                        <ParagraphComponent>Sed ut perspiciatis unde omnis iste natus error sit voluptatem <AnchorComponent url="/cheat-sheet">accusantium doloremque</AnchorComponent> laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et <AnchorComponent url="https://www.duckduckgo.com" type="external">quasi architecto</AnchorComponent> beatae vitae.</ParagraphComponent>
                        <ParagraphComponent>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.</ParagraphComponent>
                        <ParagraphComponent>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?</ParagraphComponent>
                    </CarouselComponent>
                </div>

                <div>
                    <FileComponent />
                </div>
            </div>

            <hr className="ui-global--hr" />

            <div className="grid grid-cols-3 gap-2">
                <div className="grid gap-2">
                    <ParagraphComponent>Sed ut perspiciatis unde omnis iste natus error sit voluptatem <AnchorComponent url="/cheat-sheet">accusantium doloremque</AnchorComponent> laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et <AnchorComponent url="https://www.duckduckgo.com" type="external">quasi architecto</AnchorComponent> beatae vitae.</ParagraphComponent>
                </div>

                <div>
                    <PaginationComponent page={5} totalPages={30} events={{ handleChange: (page) => console.log(page) }} />
                </div>

                <div className="grid gap-2">
                    
                </div>
            </div>

            <hr className="ui-global--hr" />

            <div className="grid grid-cols-3 gap-2">
                <div className="grid gap-2">
                    <EntityGridComponent items={entityItems} />
                </div>

                <div>
                    <EntityStackComponent items={entityItems} events={{ handleClick: () => _toggles.set('entitySelectorModal', true) }} />
                    {_toggles.get('entitySelectorModal') && <EntitySelectorModalComponent selected={entityItems} collection={entityItems} events={{ handleClose: () => _toggles.set('entitySelectorModal', false) }}/>}
                </div>

                <div className="grid gap-2">
                    
                </div>
            </div>
        </UI.Layout>
    )
}

export { CheatSheetPage }
