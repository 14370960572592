import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const RadioComponent = ({ name, value, options, events={}, eventsDOM={}, config={} }) => {
    const handleChange = (e) => {
        const value = e.target.value

        if (events.handleChange)
            events.handleChange(value)
    }

    const decorateEvents = () => {
        let eventsConfig = {...eventsDOM}

        delete eventsConfig.onChange

        return eventsConfig
    }

    return (
        <UI.Layout name="radio" type="container" config={config}>
            {options.map((option) => (
                <label className="ui--radioLabel" key={`ui-key--radioItem--${option.value}`}>
                    <input
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={value === option.value}
                        onChange={handleChange}
                        disabled={option.disabled}
                        className="ui--radioInput"
                        {...decorateEvents()}
                    />
                    {option.title}
                </label>
            ))}
        </UI.Layout>
    )
}

RadioComponent.propTypes = {
    value: PropTypes.string.isRequired,

    name: PropTypes.string,

    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            title: PropTypes.any.isRequired,
        })
    ).isRequired,

    events: PropTypes.shape({
        onChange: PropTypes.func
    }),

    eventsDOM: PropTypes.shape({}),

    config: PropTypes.shape({
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        classNames: PropTypes.string
    })
}

export { RadioComponent }
