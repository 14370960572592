import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import FieldSelectorComponent from '../../components/FieldSelector'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'
import ProjectorComponent from '../../components/Projector'
import AssetUploader from '../../componentsV2/Integrated/AssetUploader'

import RouteHelper from '../../helpers/route'
import Tooltip from '../../components/Tooltip'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'

import useFetch from '../../hooks/useFetch'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'
import { useSimpleState } from '../../UI/hooks'

import './index.css'
import { getBasicFormFields, getDigitalDeliveryFormFields, getEmphasisFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const AddonsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const { FetchHelper } = useFetch()

    const [NoticeComponent, notices] = useNotices('PSM/Addons/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()
    const [dataEmphasis, setDataEmphasis] = useState()
    const [dataDigitalDelivery, setDataDigitalDelivery] = useState()
    const [dataImage, setDataImage] = useState()
    const [dataFields, setDataFields] = useState([])
    const [dataCascadeAddons, setDataCascadeAddons] = useState([])
    const [dataCouponCodes, setDataCouponCodes] = useState([])
    const [optionsCategories, setOptionsCategories] = useState([])
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Basic', ['Basic', 'Emphasis', 'Cascade Addons', 'Digital Delivery', 'Image', 'Fields', 'Coupon Codes'])
    const [doesPageLiveUpdates, setDoesPageLiveUpdates] = useState(false)

    const [basicFormFields, setBasicFormFields] = useState(getBasicFormFields())
    const [emphasisFormFields] = useState(getEmphasisFormFields())
    const [digitalDeliveryFormFields] = useState(getDigitalDeliveryFormFields())
    
    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getBasicFormFields(suggestionsTags, optionsCategories);
        setBasicFormFields(fields)
    }, [suggestionsTags, optionsCategories])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Basic',
                forms: [{ id: 'Main', fields: basicFormFields }]
            },
            {
                tab: 'Emphasis',
                forms: [{ id: 'Main', fields: emphasisFormFields }]
            },
            {
                tab: 'Digital Delivery',
                forms: [{ id: 'Main', fields: digitalDeliveryFormFields }]
            },
            {
                tab: 'Image',
                forms: [{ id: 'Image', fields: _image.get(), fieldKeyProp: 'id' }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    const {_state: _image} = useSimpleState()

    useEffect(() => {
        switch(tab) {
            case 'Cascade Addons':
            case 'Fields':
            case 'Coupon Codes':
                setDoesPageLiveUpdates(true)
                break;

            default:
                setDoesPageLiveUpdates(false)
        }
    }, [tab])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let imageBody = {}

        _image.get().forEach((item, index) => {
            imageBody.image_attributes = {
                data_base64: /^data/.test(item.attachedImage.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                data_url: item.externalUrl,
                data_source: item.assetSource === '0' ? 'local' : 'remote'
            }
        })

        let body = {...dataMain, ...dataEmphasis, ...dataDigitalDelivery, ...imageBody}

        body.tag_names = body.tags
        delete body.tags

        FetchHelper({
            url: `/api/v2/admin/addons/${params.id}`,
            method: 'PUT',
            body: body
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/addons')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(
                    [..._image.get()],
                    res.body
                )
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/addons/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)

            _image.set([{
                type: 'Image',
                id: 'image',
                externalUrl: res.body.image?.sources?.remote,
                note: 'We suggest rectangular for image as description and square for text description, e.g. 800x300 or 600x600. Images will be scaled proportionally',
                attachedImage: {
                    data: res.body.image?.sources?.local?.url,
                    filename: ''
                },
                assetSource: res.body.image?.source === 'local' ? '0' : '1',
                relatedFields: ['image.data_url']
            }])
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/addons/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        FetchHelper({
            url: '/api/v2/admin/categories'
        }, (res) => {
            let categories = [{ id: 0, title: 'Base', value: '' }];
            res.body.data.map((item, i) => {
                const option = {
                    id: i+1,
                    title: item.name,
                    value: item.id
                }
                categories.push(option)
            })
            setOptionsCategories(categories)
        }, (res) => {
        })
    }, [])

    return (
        <div className="addons-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Addon - ${dataMain?.name}`} />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />

            {data ? (
                <>
                    <div className={`page-form-container ${tab === 'Basic' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Basic" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={basicFormFields} />
                    </div>

                    <div className={`page-form-container ${tab === 'Emphasis' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Emphasis" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setDataEmphasis} displayErrorBanner={true} fields={emphasisFormFields} />
                    </div>

                    <div className={`page-form-container ${tab === 'Cascade Addons' ? 'show' : ''}`}>
                        {dataEmphasis && dataEmphasis.emphasized === 'true' ? (
                            <div className="cascade-addons">
                                <NoticeComponent location="Tab/CascadeAddons" notices={notices} />
                                <h2>Cascade Addons <Tooltip>Cascaded addons will not allow you to set price overrides in the package plan, cannot associate with poses, sheets and backgrounds and if they are regular add-ons,  will use the default category tab</Tooltip></h2>
                                <FieldSelectorComponent sync={true} syncURL={data.cascade_addons.url} data={data.cascade_addons.data} apiPrefix="addon" buttonTitle="Addon" apiURL="/api/v2/admin/addons" editUrl={(id) => `/addons/${id}/edit`} onChange={(data) => setDataCascadeAddons(data)} />
                            </div>
                        ) : (
                            <div className="">
                                <div className="page-message">
                                    <i className="bi bi-info-circle"></i> <span>Must be emphasized to add addons</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={`page-form-container ${tab === 'Digital Delivery' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/DigitalDelivery" notices={notices} />
                        <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setDataDigitalDelivery} displayErrorBanner={true} fields={digitalDeliveryFormFields} />
                    </div>

                    <div className={`page-form-container ${tab === 'Image' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Image" notices={notices} />
                        {_image.get() && <AssetUploader list={_image.get()} key={tab} events={{ handleChange: (value) => _image.set(value) }}
                           errors={errors} setErrors={setErrors} />}
                    </div>

                    <div className={`page-form-container ${tab === 'Fields' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/Fields" notices={notices} />
                        <FieldSelectorComponent sync={true} syncURL={data.fields.url} apiPrefix="field" buttonTitle="Field" data={data.fields.data} apiURL="/api/v2/admin/fields" editUrl={(id) => `/fields/${id}/edit`} onChange={(data) => setDataFields(data)} />
                    </div>

                    <div className={`page-form-container ${tab === 'Coupon Codes' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/CouponCodes" notices={notices} />
                        <FieldSelectorComponent sync={true} sortable={false} syncURL={data.coupon_codes.url} apiPrefix="coupon_code" buttonTitle="Coupon Code" data={data.coupon_codes.data} apiURL="/api/v2/admin/coupon-codes" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} />
                    </div>
                </>
            ) : null}

            <ControlBar data={data} baseApiUrl="/addons" redirectUrl="/addons" cancelUrl="/addons" handleSubmit={handleSubmit} 
                doesPageLiveUpdates={doesPageLiveUpdates}/>
        </div>
    )
}

export default AddonsEdit
