import QrScanner from 'qr-scanner';
import { Utils } from '.';

export async function getImageDataURL(file) {
  const reader = new FileReader();

  let onFileLoad = new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
  });

  reader.readAsDataURL(file);

  let result = await onFileLoad;

  // --- cleanup event listeners
  reader.onload = null;
  reader.onerror = null;

  return result.target.result;
}

export async function loadImage(imgSrc) {
  // --- create image element
  let imgElem = new Image();

  // --- create promise function of src image load
  let onImgLoad = new Promise((resolve, reject) => {
    imgElem.onload = resolve;
    imgElem.onerror = reject;
  });

  // --- set source of image
  imgElem.src = imgSrc;

  // --- wait for image to load
  await onImgLoad;

  // --- cleanup event listeners
  imgElem.onload = null;
  imgElem.onerror = null;

  return imgElem;
}

// --- cap given dimensions to given maxCap
export function capDimensions(width, height, maxCap = 500) {
  if (width > maxCap || height > maxCap) {
    let ratio = width / height;

    if (width > height) {
      width = maxCap;
      height = Math.round(width / ratio);
    } else {
      height = maxCap;
      width = Math.round(height * ratio);
    }
  }

  return { width, height };
}

/**
 * draw image on canvas and return canvas
 * @param {Image} imgElem image element where image is loaded
 * @param {number} (Optional) maxCap max cap for width and height of image (if you want to resize the image)
 * @returns canvas element with image drawn on it
 */
export function drawOnCanvas(imgElem, maxCap) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  let origWidth = imgElem.width;
  let origHeight = imgElem.height;
  let croppedWidth = origWidth;
  let croppedHeight = origHeight;
  if (maxCap) {
    const { width, height } = capDimensions(
      imgElem.width,
      imgElem.height,
      maxCap
    );
    croppedWidth = width;
    croppedHeight = height;
  }

  canvas.width = croppedWidth;
  canvas.height = croppedHeight;
  context.drawImage(
    imgElem,
    0,
    0,
    origWidth,
    origHeight,
    0,
    0,
    croppedWidth,
    croppedHeight
  );
  return canvas;
}

/**
 * get image data (uint8clampedarray) from image element
 * @param {Canvas} canvas canvas element with image drawn on it
 * @returns image data (uint8clampedarray)
 */
export function getImageData(canvas) {
  const context = canvas.getContext('2d');
  let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  return imageData;
}

/**
 * Rexsize canvas image (modifies original canvas element)
 * @param {HTMLCanvasElement} canvas canvas element to resize
 * @param {number} newWidth new width of canvas
 * @param {number} newHeight new height of canvas
 * @returns canvas element with resized image
 */
export async function resizeCanvasImage(canvas, newWidth, newHeight) {
  // --- resize canvas image without creating new canvas
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = newWidth;
  tempCanvas.height = newHeight;

  const tempContext = tempCanvas.getContext('2d');
  tempContext.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  // --- copy resized image to original canvas
  canvas.width = newWidth;
  canvas.height = newHeight;
  canvas.getContext('2d').drawImage(tempCanvas, 0, 0, newWidth, newHeight);
  tempCanvas.remove();
  return canvas;
}

/**
 * Erase section of canvas (modifies original canvas element)
 * @param {HTMLCanvasElement} canvas canvas element to erase section
 * @param {number} x left-top x coordinate of section to start erasing
 * @param {number} y left-top y coordinate of section to start erasing
 * @param {number} width width of section to erase
 * @param {number} height height of section to erase
 */
export function eraseSection(canvas, x, y, width, height) {
  const context = canvas.getContext('2d');
  context.clearRect(x, y, width, height);
}

/**
 * Cap canvas dimensions to maxCap
 * @param {HTMLCanvasElement} canvas canvas element to cap
 * @param {number} maxCap max cap for width and height of image
 * @returns capped canvas element
 */
export async function capCanvas(canvas, maxCap = 500) {
  const { width, height } = capDimensions(canvas.width, canvas.height, maxCap);
  if (width === canvas.width && height === canvas.height) return canvas;

  return resizeCanvasImage(canvas, width, height);
}

/**
 * get object URL from canvas
 * @param {Canvas} canvas
 * @returns object url for canvas contents (eg. image)
 */
export async function getObjectURL(canvas) {
  const toBlob = new Promise((resolve, reject) => {
    canvas.toBlob(resolve);
  });

  const blob = await toBlob;
  let url = URL.createObjectURL(blob);
  return url;
}

/**
 * Extract QR code from image file
 * @param {File} file image file object
 * @param {number} maxCap max cap for width and height of image (eg. it improves processing speed since it caps the image to maxCap and then processes it)
 * @returns object with qrData, imgDataUrl
 */
export async function extractQRFromImageFile(
  file,
  maxCap = 2000,
  thumbnailMaxCap = 100,
  maxQRLimit = 1
) {
  try {
    const imgObjectURL = URL.createObjectURL(file);
    const imgElem = await loadImage(imgObjectURL);
    const canvas = drawOnCanvas(imgElem, maxCap);

    let qrCodesData = [];

    let qrData;
    do {
      // --- erase previous qr code section
      if (qrData)
        eraseSection(
          canvas,
          qrData.cornerPoints[0].x,
          qrData.cornerPoints[0].y,
          qrData.cornerPoints[2].x - qrData.cornerPoints[0].x,
          qrData.cornerPoints[2].y - qrData.cornerPoints[0].y
        );

      // --- scan qr code from image
      [qrData] = await Utils.executePromise(
        QrScanner.scanImage(canvas, { returnDetailedScanResult: true })
      );
      if(qrData) qrCodesData.push(qrData);
    } while (qrCodesData.length < maxQRLimit && qrData);

    capCanvas(canvas, thumbnailMaxCap);
    const thumbnailObjURL = await getObjectURL(canvas);

    // clean up
    imgElem.remove(); // remove image
    canvas.remove(); // remove canvas

    return { qrCodesData, imgObjectURL, thumbnailObjURL };
  } catch (err) {
    console.error(err);
    return { qrData: null, imgDataUrl: null };
  }
}
