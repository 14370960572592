import { useEffect, useState } from 'react'
import './CopyButton.css'

const CopyButton = ({ value, showInput=true, icon='bi-clipboard'}) => {
    const [showCopied, setShowCopied] = useState(false)

    const handleCopy = () => {
        navigator.clipboard.writeText(value)
    }

    const handleButtonClick = () => {
        handleCopy()
        setShowCopied(true)

        setTimeout(() => {
            setShowCopied(false)
        }, 1000)
    }

    return (
        <div className='copy-button-wrapper'>
            {showInput ? <input value={value} disabled readOnly /> : null}
            <button className={`copy-button ${showCopied ? 'copied' : ''}`} onClick={(e) => {
                e.stopPropagation()
                handleButtonClick()
            }}><i className={`bi ${icon} copy-button-icon-idle`}></i><i className="bi bi-check-lg copy-button-icon-active"></i></button>
        </div>
    )
}

export default CopyButton