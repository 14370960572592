import { useState } from 'react'

import { DomainHelper } from '../helpers/domain'

import UtilsHelper from '../helpers/utils'

const useFetch = () => {
    const fetchMap = {}

    return {
        FetchHelper: async (options = {}, onSuccess = () => {}, onError = () => {}, onControllerReady = (controller) => {}) => {
            let baseUrl = DomainHelper.getBaseUrl()

            if (options.relativePath === false) {
                baseUrl = ''
            }
        
            const controller = new AbortController()
            if (controller) 
                onControllerReady({
                    ...controller,
                    abort: (reason) => {
                        controller.abort(reason)
                        delete fetchMap[fetchMapKey.key]
                    }
                });

            let opts = {
                method: options.method || 'GET',
                credentials: 'include',
                headers: {
                    ...options.headers,
                    Accept: 'application/json'
                },
                signal: controller.signal
            }
        
            if (!opts.headers['Content-Type']) {
                opts.headers['Content-Type'] = 'application/json'
            }
            if (opts.headers['Content-Type'] === 'multipart/form-data')
                delete opts.headers['Content-Type']
        
            if (opts.method !== 'GET') {
                if (opts.headers['Content-Type'] === 'application/json')
                    opts.body = options.body ? JSON.stringify(options.body) : ''
                else
                    opts.body = options.body || ''
            }

            options.url = options.url.replace(baseUrl, '')

            const generateReqUniqKey = () => {
                let key = `${options.url}`
                if (opts.headers['Content-Type'] === 'application/json') {
                    key += `::${JSON.stringify(opts.body)}`
                }
                if (opts.body instanceof FormData) {
                    key += `::${opts.body.get('uniqKey') || UtilsHelper.generateUUID()}`
                }
                return key
            }

            let fetchMapKey = {
                key: generateReqUniqKey(),
                timestamp: new Date().getMilliseconds()
            }

            if (fetchMap[fetchMapKey.key] && fetchMap[fetchMapKey.key].timestamp > (fetchMapKey.timestamp + 5000)) {
                delete fetchMap[fetchMapKey.key]
            }

            // --- fetch blob data
            const fetchBlob = async () => {
                let response = await fetch(`${options.url}`, opts)
                if (!response.ok) throw response;

                UtilsHelper.handleHeaderEvents(response)

                if (!options.filename) {
                    const header = response.headers.get('Content-Disposition');
                    const parts = header && header.split(';') || [];
                    options.filename = parts[1].split('=')[1].replace(/"/g, '')
                }
                
                let blob = await response.blob()
                UtilsHelper.downloadBlob(blob, options.filename)
            }

            // --- fetch json data
            const fetchJSON = async () => {
                let response = await fetch(`${options.url}`, opts)
                const data = await response.text()
                const body = data ? JSON.parse(data) : {}
                const status = response.status
                const res = { body, status }
        
                UtilsHelper.handleHeaderEvents(response)
        
                if (!response.ok) throw res;
                return res;
            }

            if (!fetchMap[fetchMapKey.key]) {
                if (options.blob) {
                    fetchMap[fetchMapKey.key] = {
                        timestamp: fetchMapKey.timestamp,
                        fetch: fetchBlob
                    }
                } else {
                    fetchMap[fetchMapKey.key] = {
                        timestamp: fetchMapKey.timestamp,
                        fetch: fetchJSON
                    }
                }
            }

            try {
                let response = await fetchMap[fetchMapKey.key].fetch();
                onSuccess(response);
                return response;
            } catch (error) {
                if (error && error.status === 401) 
                    UtilsHelper.handleUnauthorizedError();
                
                onError(error);
                return error;
            }
        }
    }
}

export default useFetch