import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FieldSelectorComponent from '../../components/FieldSelector'
import FieldMultiselectorComponent from '../../components/FieldMultiselector'
import FormComponent from '../../components/Form'
import PickerComponent from '../../components/Picker'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './edit.css'
import { getCharityFormFields, getHelperFormFields, getPlanFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const PackagePlansEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/PackagePlans/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataPlan, setDataPlan] = useState()
    const [dataHelper, setDataHelper] = useState()
    const [dataCharity, setDataCharity] = useState()
    const [dataPackages, setDataPackages] = useState()
    const [dataAddons, setDataAddons] = useState()
    const [dataEnhancements, setDataEnhancements] = useState()
    const [dataCouponCodes, setDataCouponCodes] = useState()
    const [dataPoses, setDataPoses] = useState()
    const [dataBackgrounds, setDataBackgrounds] = useState()
    const [dataSheets, setDataSheets] = useState()
    const [dataFields, setDataFields] = useState()
    const [dataNotice, setDataNotice] = useState()
    const [suggestionsBarcodeFields, setSuggestionsBarcodeFields] = useState([])
    const [suggestionsPackages, setSuggestionsPackages] = useState([])
    const [suggestionsAddons, setSuggestionsAddons] = useState([])
    const [suggestionsFields, setSuggestionsFields] = useState([])
    const [suggestionsTags, setSuggestionsTags] = useState([])
    const [suggestionsCouponCodes, setSuggestionsCouponCodes] = useState([])
    const [couponCodeSelection, setCouponCodeSelection] = useState('')
    const [optionsCategories, setOptionsCategories] = useState([])
    const [fieldsSelection, setFieldsSelection] = useState('')

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Plan', ['Plan', 'Poses', 'Backgrounds', 'Sheets', 'Packages', 'Global Addons', 'Enhancements', 'Coupon Codes', 'Fields', 'Charity', 'Helper'])

    // attach package form
    const [showAttachPackageForm, setShowAttachPackageForm] = useState(false)
    const [dataAttachPackageForm, setDataAttachPackageForm] = useState()
    const [dataAttachPackageFormCouponCodes, setDataAttachPackageFormCouponCodes] = useState([])
    const [attachPackageFormErrors, setAttachPackageFormErrors] = useState()

    // package plan addons form
    const [showAttachAddonsForm, setShowAttachAddonsForm] = useState(false)
    const [dataAttachAddonsForm, setDataAttachAddonsForm] = useState()
    const [attachAddonsFormErrors, setAttachAddonsFormErrors] = useState()

    const [doesPageLiveUpdates, setDoesPageLiveUpdates] = useState(false)
    
    const [planFormFields, setPlanFormFields] = useState(getPlanFormFields())
    const [charityFormFields] = useState(getCharityFormFields())
    const [helperFormFields] = useState(getHelperFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let barcodeFieldsOptions = suggestionsBarcodeFields.concat(
          (dataFields || []).map((item) => {
            return {
              value: item.value,
              title: item.title
            };
          })
        );
        let fields = getPlanFormFields(suggestionsTags, barcodeFieldsOptions);
        setPlanFormFields(fields);
    }, [suggestionsTags, suggestionsBarcodeFields, dataFields])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Plan',
                forms: [{ id: 'Main', fields: planFormFields }]
            },
            {
                tab: 'Charity',
                forms: [{ id: 'Charity', fields: charityFormFields }]
            },
            {
                tab: 'Helper',
                forms: [{ id: 'Helper', fields: helperFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        switch(tab) {
            case 'Packages':
            case 'Poses':
            case 'Backgrounds':
            case 'Sheets':
            case 'Global Addons':
            case 'Enhancements':
            case 'Coupon Codes':
            case 'Fields':
                setDoesPageLiveUpdates(true)
                break;

            default:
                setDoesPageLiveUpdates(false)
        }
    }, [tab])

    const handleSubmit = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/package-plans/${params.id}`,
            method: 'PUT',
            body: {
                package_plan: {
                    ...dataPlan,
                    ...dataHelper,
                    package_plan_charity_attributes: dataCharity
                }
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/package-plans')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/package-plans/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            const body = res.body

            setData(body)
            setDataPackages(body.packages.data)
            setDataAddons(body.addons.data)
            setDataEnhancements(body.enhancements?.data || [])
            setDataCouponCodes(body.coupon_codes.data)
            setDataPoses(body.poses.data)
            setDataBackgrounds(body.backgrounds.data)
            setDataSheets(body.sheets.data)
            setDataFields(body.fields.data.map((item, index) => {
                return {
                    title: item.name,
                    value: item.id,
                    item
                }
            }))
            setDataCharity(body.charity.data)
            setDataHelper({
                helper_caption: body.helper_caption,
                helper_content: body.helper_content
            })
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/addons/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        setSuggestionsBarcodeFields([
            {
                title: 'Confirmation # (Default)',
                value: 'fastpass'
            },
            {
                title: 'Email',
                value: 'email'
            }
            // custom fields attached to package plan
        ])

        FetchHelper({
            url: `/api/v2/admin/packages`
        }, (res) => {
            setSuggestionsPackages(res.body.data.map((item) => {
                return {
                    title: item.name,
                    id: item.id
                }
            }))
        }, (res) => {
        })

        FetchHelper({
            url: `/api/v2/admin/addons`
        }, (res) => {
            setSuggestionsAddons(res.body.data)
        }, (res) => {
        })

        FetchHelper({
            url: '/api/v2/admin/categories'
        }, (res) => {
            let categories = [{ id: 0, title: 'Select', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: i+1,
                    title: item.name,
                    value: item.id
                }
                categories.push(option)
            })
            setOptionsCategories(categories)
        }, (res) => {
        })
    }, [])

    const handleAttachPackageFormSubmit = () => {
        ac.showSpinner(true)

        const body = {
            packages_attributes: [{...dataAttachPackageForm,
                item_coupon_codes_attributes: dataAttachPackageFormCouponCodes
            }]
        }

        FetchHelper({
            url: `/api/v2/admin/package-plans/${params.id}`,
            method: 'PUT',
            body: body
        }, (res) => {
            ac.showSpinner(false)
            setShowAttachPackageForm(false)
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setAttachPackageFormErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    const handleAttachAddonsFormSubmit = () => {
        ac.showSpinner(true)

        const body = {
            addons_attributes: dataAttachAddonsForm
        }

        FetchHelper({
            url: `/api/v2/admin/package-plans/${params.id}`,
            method: 'PUT',
            body: body
        }, (res) => {
            ac.showSpinner(false)
            setShowAttachAddonsForm(false)
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setAttachPackageFormErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    if (!data || !dataPackages || !dataAddons || !dataEnhancements || !dataCouponCodes || !dataPoses || !dataBackgrounds || !dataSheets || !dataFields) {
        return null
    }

    return (
        <div className="package-plans-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Package Plan - ${dataPlan?.name}`} />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />

            {tab === 'Plan' ?
                <>
                    <NoticeComponent location="Tab/Plan" notices={notices} />

                    <FormComponent formData={dataPlan || data} formErrors={errors} setFormErrors={setErrors} onChange={setDataPlan} displayErrorBanner={true} fields={planFormFields} />
                </>
            : null}

            {tab === 'Charity' ?
                <>
                    <NoticeComponent location="Tab/Charity" notices={notices} />

                    <FormComponent formData={dataCharity} formErrors={errors} setFormErrors={setErrors} onChange={setDataCharity} displayErrorBanner={true} fields={charityFormFields} />
                </>
            : null}

            {tab === 'Helper' ?
                <>
                    <NoticeComponent location="Tab/Helper" notices={notices} />

                    <FormComponent formData={dataHelper} formErrors={errors} setFormErrors={setErrors} onChange={setDataHelper} displayErrorBanner={true} fields={helperFormFields} />
                </>
            : null}

            {tab === 'Packages' ?
                <>
                    <NoticeComponent location="Tab/Packages" notices={notices} />

                    <FieldMultiselectorComponent data={dataPackages} apiUrl={data.packages.url} sync={true} entityParent="Package Plan" entityType="Package" onChange={(data) => { setDataPackages(data) }} columns={[
                        {
                            'title': 'Name'
                        },
                        {
                            'title': 'Price'
                        }
                    ]} withSelectors={{ backgrounds: dataBackgrounds.length != 0, poses: dataPoses.length != 0, sheets: dataSheets.length != 0, proofs: ac.AuthHelper.checkFeature('ProofingService') }} />
                </>
            : null}

            {tab === 'Global Addons' ?
                <>
                    {/* <div className="page-message">
                        <i className="bi bi-info-circle"></i> <span>Addons shown below will be offered regardless of what package is in cart, and even if the customer chooses the a la carte option.</span>
                    </div> */}

                    <NoticeComponent location="Tab/GlobalAddons" notices={notices} />

                    <FieldMultiselectorComponent data={dataAddons} apiUrl={data.addons.url} sync={true} entityParent="Package Plan" entityType="Addon" onChange={(data) => { setDataAddons(data) }} columns={[
                        {
                            'title': 'Name'
                        },
                        {
                            'title': 'Price'
                        }
                    ]} withSelectors={{ backgrounds: dataBackgrounds.length != 0, poses: dataPoses.length != 0, sheets: dataSheets.length != 0, proofs: ac.AuthHelper.checkFeature('ProofingService') }} />
                </>
            : null}

            {tab === 'Enhancements' &&
                <>
                    <NoticeComponent location="Tab/Enhancements" notices={notices} />

                    <FieldSelectorComponent sync={true} sortable={false} syncURL={data.enhancements?.url} apiPrefix="enhancement" buttonTitle="Enhancement" data={dataEnhancements} apiURL="/api/v2/admin/enhancements" editUrl={(id) => `/enhancements/${id}/edit`} onChange={(data) => setDataEnhancements(data)} />
                </>}

            {tab === 'Coupon Codes' ?
                <>
                    <NoticeComponent location="Tab/CouponCodes" notices={notices} />

                    <div className={`page-form-container ${tab === 'Coupon Codes' ? 'show' : ''}`}>
                        <FieldSelectorComponent sync={true} sortable={false} syncURL={data.coupon_codes.url} apiPrefix="coupon_code" buttonTitle="Coupon Code" data={dataCouponCodes} apiURL="/api/v2/admin/coupon-codes" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} />
                    </div>
                </>
            : null}

            {tab === 'Poses' ?
                <>
                    <NoticeComponent location="Tab/Poses" notices={notices} />

                    <div className="package-plans-poses-container page-subsection-container">
                        <PickerComponent value={dataPoses} onChange={setDataPoses} apiUrl={data.poses.url} type="pose" />
                    </div>
                </>
            : null}

            {tab === 'Backgrounds' ?
                <>
                    <NoticeComponent location="Tab/Backgrounds" notices={notices} />

                    <div className="package-plans-backgrounds-container page-subsection-container">
                        <PickerComponent value={dataBackgrounds} onChange={setDataBackgrounds} apiUrl={data.backgrounds.url} type="background"/>
                    </div>
                </>
            : null}

            {tab === 'Sheets' ?
                <>
                    <NoticeComponent location="Tab/Sheets" notices={notices} />

                    <div className="package-plans-sheets-container page-subsection-container">
                        <PickerComponent value={dataSheets} onChange={setDataSheets} apiUrl={data.sheets.url} type="sheet" />
                    </div>
                </>
            : null}

            {tab === 'Fields' ?
                <>
                    <NoticeComponent location="Tab/Fields" notices={notices} />

                    <div className={`page-form-container ${tab === 'Fields' ? 'show' : ''}`}>
                        <FieldSelectorComponent sync={true} syncURL={data.fields.url} apiPrefix="field" buttonTitle="Field" data={dataFields} apiURL="/api/v2/admin/fields" editUrl={(id) => `/fields/${id}/edit`} onChange={(data) => setDataFields(data)} />
                    </div>
                </>
            : null}

            <ControlBar baseApiUrl="/package-plans" redirectUrl="/package-plans" cancelUrl="/package-plans" data={data} handleSubmit={handleSubmit}
                doesPageLiveUpdates={doesPageLiveUpdates}/>
        </div>
    )
}

export default PackagePlansEdit
