import { useState, useEffect } from 'react'

import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import dayjs from 'dayjs'

const PickerDateTime = ({ initialValue, onChange, dateFormat, readOnly=false }) => {
    return (
        <div className="picker-date-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    value={!initialValue || initialValue === '' ? null : dayjs(initialValue)}
                    disabled={readOnly}
                    slotProps={{ textField: { placeholder: 'Select Date & Time' } }}
                    onChange={(newValue) => {
                        onChange(dateFormat ? dayjs(newValue).format(dateFormat) : newValue)
                    }}
                />
            </LocalizationProvider>
        </div>
    );
}

export default PickerDateTime