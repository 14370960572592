import { useEffect, useState } from "react"
import '../components/TwoSideMultiselect.css'

const TwoSideMultiselect = ({ value, options, onChange, labels, readOnly }) => {
    const [selectable, setSelectable] = useState([])
    const [selected, setSelected] = useState([])

    useEffect(() => {
        if (value.length > 0) {
            let list = options
            value.forEach(elem => {
                list = list.filter((item) => item.id !== elem.id)
            });
            setSelectable(sortList(list))
        } else {
            setSelectable(sortList(options))
        }
        setSelected(value)
    }, [value, options])

    const sortList = (list) => {
        return list.sort((a, b) => (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0))
    }

    const handleSelection = (label, itemId, type, group) => {
        const list = type === 'selectable' ? selectable : selected
        const temp = list.filter((item) => item.id != itemId)
        let filteredList = sortList(temp)

        if (type === 'selectable') {
            setSelectable(filteredList)

            let temp = [...selected, { id: itemId, name: label, group: group }]
            let newList = sortList(temp)

            onChange(temp)
            setSelected(newList)
        }

        if (type === 'selected') {
            setSelected(filteredList)
            onChange(filteredList)

            let temp = [...selectable, { id: itemId, name: label, group: group }]
            let newList = sortList(temp)
            setSelectable(newList)
        }
    }

    const handleGroup = (group, type) => {
        const list = type === 'selectable' ? selectable : selected

        let newSelectable = list.filter((item) => item.group !== group)
        let newSelected = list.filter((item) => item.group === group)

        if (type === 'selectable') {
            setSelectable(newSelectable)
            let temp = [...selected, ...newSelected]
            onChange(sortList(temp))
            setSelected(sortList(temp))
        }

        if (type === 'selected') {
            onChange(newSelectable)
            setSelected(newSelectable)
            let temp = [...selectable, ...newSelected]
            setSelectable(sortList(temp))
        }
    }

    const renderItem = (list, type, groupName) => {
        let li = []
        for(var i=0;i < list.length;i++){
            let elem = list[i]
            if (groupName === elem.group) {
                li.push(<li key={i} onClick={() => handleSelection(elem.name, elem.id, type, groupName)}>{elem.name}</li>)
            }
        }
        return li
    }

    const renderList = (list, type) => {
        let group = []
        let listClass = readOnly ? 'disabled' : null
        const listCopy = list

        const element = list.map((item, i) => {
            let groupName = item.group

            if (!group.includes(groupName)) {
                group.push(groupName)
                return (
                    <ul className={listClass} key={i} style={{ marginBottom: 15 }}>
                        <li className="group-name" onClick={() => handleGroup(groupName, type)}>{groupName}</li>
                        {renderItem(listCopy, type, groupName)}
                    </ul>
                )
            }
        })


        return element
    }

    return (
        <div className="two-multiselect-container">
            <div className="multiselect-wrap">
                <p className="title">{labels[0]}</p>
                <div className="multiselect">
                    {renderList(selectable, 'selectable')}
                </div>
            </div>
            <div className="multiselect-wrap">
                <p className="title">{labels[1]}</p>
                <div className="multiselect">
                    {renderList(selected, 'selected')}
                </div>
            </div>

        </div>
    )
}
export default TwoSideMultiselect
