import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import ProjectorComponent from '../../components/Projector'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { FileTypes } from '../../sys/constants/enums'
import { useParams } from 'react-router-dom'

const BackgroundsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Backgrounds/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {...dataMain}

        if (body.image_attributes) {
            body.image_attributes = {
                id: data.image.id,
                data_base64: body.image_attributes.value
            }
        }

        if (body.production_image_base64)
            body.production_image_base64 = body.production_image_base64.value

        body.tag_names = body.tags
        delete body.tags

        FetchHelper({
            url: `/api/v2/admin/backgrounds/${data.id}`,
            method: 'PUT',
            body: { background: body }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/backgrounds')
        }, (res) => {
            ac.showSpinner(false)
            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/backgrounds/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/backgrounds/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {
        })
    }, [])

    return (
        <div className="backgrounds-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Background - ${dataMain?.name}`} />
            {data ? <>
                <ProjectorComponent url={data.image.image_url} />

                <NoticeComponent location="Main" notices={notices} />

                <FormComponent formData={data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                    type: 'input-text',
                    name: 'name',
                    label: 'Name'
                },{
                    type: 'tags',
                    name: 'tags',
                    label: 'Tags',
                    suggestions: suggestionsTags
                },{
                    type: 'input-text',
                    name: 'code',
                    label: 'Code',
                },{
                    type: 'input-text',
                    name: 'lab_code',
                    label: 'Lab Code',
                    label_info: 'Lab Code takes priority over Code when sending data to lab for fulfillment.',
                },{
                    type: 'input-number',
                    name: 'shipping_premium',
                    label: 'Shipping premium',
                },{
                    type: 'input-file',
                    name: 'image_attributes',
                    label: 'Preview Image',
                    label_info: '(512kb max)',
                    acceptedFileTypes: FileTypes.IMAGES
                },{
                    type: 'input-file',
                    name: 'production_image_base64',
                    label: 'Production Image',
                    acceptedFileTypes: FileTypes.IMAGES,
                    imageUrl: data.production_image?.preview_url
                }]} />

                <ControlBar baseApiUrl="/backgrounds" redirectUrl="/backgrounds" cancelUrl="/backgrounds" data={data} handleSubmit={handleSubmit} />
            </> : null}
        </div>
    )
}

export default BackgroundsEdit
