import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import TwoSidedMultiselectPaginated from '../../components/TwoSidedMultiselectPaginated'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './edit.css'
import { getGeneralFormFields, getLimitsFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const CouponCodesNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/CouponCodes/New')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataGeneral, setDataGeneral, clearDataGeneral, dataGeneralDefault] = useDraft('PSM:CouponCodes:DataGeneral')
    const [dataLimits, setDataLimits, clearDataLimits, dataLimitsDefault] = useDraft('PSM:CouponCodes:DataLimits')
    const [dataSelectedAddons, setDataSelectedAddons] = useState({ data: [] })
    const [dataSelectedPackages, setDataSelectedPackages] = useState({ data: [] })
    const [dataSelectedPackagePlans, setDataSelectedPackagePlans] = useState({ data: [] })
    const [dataSelectedMarkets, setDataSelectedMarkets] = useState({ data: [] })
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('General', ['General', 'Limits', 'Addon', 'Package', 'Package Plan', 'Market'])

    const [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFields())
    const [limitsFormFields] = useState(getLimitsFormFields())
    
    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getGeneralFormFields(suggestionsTags)
        setGeneralFormFields(fields)
    }, [suggestionsTags])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'General',
                forms: [{ id: 'Main', fields: generalFormFields }]
            },
            {
                tab: 'Limits',
                forms: [{ id: 'Main', fields: limitsFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])
    
    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = {
                ...dataGeneral,
                ...dataLimits
            }

            body.addon_ids = dataSelectedAddons.data.map(item => item.id)
            body.package_ids = dataSelectedPackages.data.map(item => item.id)
            body.package_plan_ids = dataSelectedPackagePlans.data.map(item => item.id)
            body.market_ids = dataSelectedMarkets.data.map(item => item.id)

            body.limit_uses = body.limit_uses || 0
            body.limit_min_total = body.limit_min_total || 0
            body.limit_min_total = body.limit_min_total || 0

            body.tag_names = body.tags
            delete body.tags

            FetchHelper({
                url: `/api/v2/admin/coupon-codes`,
                method: 'POST',
                body: {
                    coupon_code: body
                }
            }, (res) => {
                ac.showSpinner(false)
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }
                reject()
            })
        })
    }

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/coupon-codes/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {
        })
    }, [])

    return (
        <div className="coupon-codes-edit-page-container page-container">
            <PageHeaderComponent title="New Coupon Code" />
            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            {tab === 'General' ? (
                <>
                    <NoticeComponent location="Tab/General" notices={notices} />
                    <FormComponent formData={dataGeneral} formErrors={errors} setFormErrors={setErrors} onChange={setDataGeneral} displayErrorBanner={true} fields={generalFormFields} />
                </>
            ) : null}
            {tab === 'Limits' ? (
                <>
                    <NoticeComponent location="Tab/Limits" notices={notices} />
                    <FormComponent formData={dataLimits} formErrors={errors} setFormErrors={setErrors} onChange={setDataLimits} displayErrorBanner={true} fields={limitsFormFields} />
                </>
            ): null}
            {tab === 'Addon' && (
                <>
                    <NoticeComponent location="Tab/Addons" notices={notices} />
                    <div className="ui-g-pageSection">
                        <TwoSidedMultiselectPaginated value={dataSelectedAddons} apiUrl="/api/v2/admin/addons" renderTitle={(item) => `${item.name} — ${item.code}`} tabOptions={['Addons']} itemType="addons" onChange={(items) => {
                            setDataSelectedAddons({ data: items })
                        }} />
                    </div>
                </>
            )}
            {tab === 'Package' && (
                <>
                    <NoticeComponent location="Tab/Packages" notices={notices} />
                    <div className="ui-g-pageSection">
                        <TwoSidedMultiselectPaginated value={dataSelectedPackages} apiUrl="/api/v2/admin/packages" renderTitle={(item) => `${item.name} — ${item.code}`} tabOptions={['Pacakges']} itemType="packages" onChange={(items) => {
                            setDataSelectedPackages({ data: items })
                        }} />
                    </div>
                </>
            )}
            {tab === 'Package Plan' && (
                <>
                    <NoticeComponent location="Tab/PackagePlans" notices={notices} />
                    <div className="ui-g-pageSection">
                        <TwoSidedMultiselectPaginated value={dataSelectedPackagePlans} apiUrl="/api/v2/admin/package-plans" tabOptions={['Pacakge Plans']} itemType="package plans" onChange={(items) => {
                            setDataSelectedPackagePlans({ data: items })
                        }} />
                    </div>
                </>
            )}
            {tab === 'Market' && (
                <>
                    <NoticeComponent location="Tab/Markets" notices={notices} />
                    <div className="ui-g-pageSection">
                        <TwoSidedMultiselectPaginated value={dataSelectedMarkets} apiUrl="/api/v2/admin/markets" tabOptions={['Markets']} itemType="markets" onChange={(items) => {
                            setDataSelectedMarkets({ data: items })
                        }} />
                    </div>
                </>
            )}
            <ControlBar handleSubmit={handleSubmit} cancelUrl="/coupon-codes" handleAfterSubmit={() => RouteHelper.redirect('/coupon-codes')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [dataGeneral, clearDataGeneral, dataGeneralDefault],
                [dataLimits, clearDataLimits, dataLimitsDefault]
            ]} />
        </div>
    )
}

export default CouponCodesNew
