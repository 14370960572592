import { useEffect, useState } from 'react'

import {Link, useLocation, useNavigate} from 'react-router-dom'

import DropdownComponent from './Dropdown'
import SelectPaginatedComponent from './SelectPaginated'

import FetchHelper from '../helpers/fetch'
import RouteHelper from '../helpers/route'
import UtilsHelper from '../helpers/utils'

import useAppContext from '../hooks/useAppContext'
import useWindowDimensions from '../hooks/useWindowDimensions'

import './PageHeader.css'

const PageHeader = ({ title, showBack, actions = [] }) => {
    const [ac] = useAppContext()

    const {width, height} = useWindowDimensions()

    const navigate = useNavigate()
    const location = useLocation()
    const locationPathname = location.pathname.split('/')
    const pathname = (locationPathname[1]).replace(/-/g, ' ')
    const goBackName = pathname.replace(/\b\w/g, l => l.toUpperCase())

    if (showBack === undefined && locationPathname.length > 2 && (locationPathname.includes('new') || locationPathname.includes('edit')) )
        showBack = true
    
    document.title = title

    useEffect(() => {
        RouteHelper.storeCurrentURL()
    }, [])

    // --- maintain page-header-container height in css variable
    useEffect(() => {
        let headerContainer = document.querySelector('.page-header-container')
        if (!headerContainer) return

        const computedStyle = window.getComputedStyle(headerContainer)
        const position = computedStyle.getPropertyValue('position')
        let headerStickyHeight = position === 'sticky' ? headerContainer.offsetHeight : 0
        document.documentElement.style.setProperty('--height-sticky-header-container', `${headerStickyHeight}px`)
    }, [window.innerWidth])

    const handleLogout = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: '/api/v2/admin/logout',
            method: 'delete'
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.clearCurrentURL()
            RouteHelper.redirectToRoot()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const renderUserMenu = () => {
        const title = width < 1024 ? <i className="bi bi-person"></i> : ac.user.login

        return <DropdownComponent title="Menu" isResponsive={false} dropdownTitle={title}
            options={[
                {
                    title: 'Profile',
                    type: 'callback',
                    icon: 'bi-person',
                    callback: () => {
                        RouteHelper.redirect('/profile')
                    }
                },
                {
                    title: 'Logout',
                    type: 'callback',
                    icon: 'bi-door-closed',
                    callback: handleLogout
                }
            ]} />
    }

    const handleSetStudio = (studio) => {
        UtilsHelper.setLocalStorage(`SelectAutocomplete_PageHeaderStudio`, studio.value)

        ac.showSpinner(true)
        
        FetchHelper({
            url: '/api/v2/admin/set/current',
            method: 'put',
            body: {
                studio_id: studio.value
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.reload()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    return (
        <div className="page-header-container">
          <div className="page-header-profile">
            {ac.AuthHelper.isAdmin() ? (
                <div className="studio-menu">
                    <SelectPaginatedComponent apiUrl={`/api/v2/admin/studios`} initialValue={UtilsHelper.getLocalStorage(`SelectAutocomplete_PageHeaderStudio`) || ac.user.current.studio_id} onSelect={handleSetStudio} />
                </div>
            ) : null}
            <div className="user-menu">
                {renderUserMenu()}
            </div>
          </div>
          <div className="page-header-tab">
            { showBack &&
                <>
                    <button onClick={() => navigate(-1)} className="breadcrumbs arrowBack"><i className="bi bi-arrow-left"></i></button>
                    <div onClick={() => navigate(-1)} className="breadcrumbs pathname">
                        <span>{goBackName}</span><i className="bi bi-chevron-left"></i>
                    </div>
                </>
            }
            <div className="page-header-name">{title}</div>
            
            { showBack && <button className='back-btn' onClick={() => navigate(-1)}>Back</button>}
            
            <ul className="page-header-actions">
                {actions.length > 0 ? actions.map((action, index) => {
                    return (
                        <li key={`page-header-action-${index}`}><Link to={action.route} className="page-header-action global-button secondary">{action.title}</Link></li>
                    )
                }) : null}
            </ul>
          </div>
        </div>
    )
}

export default PageHeader
