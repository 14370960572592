import {useState, useEffect} from 'react'

import FetchHelper from '../../helpers/fetch'
import LoadingComponent from '../Loading'
import PopoverComponent from '../Popover'
import PaginationComponent from '../Pagination'
import RouteHelper from '../../helpers/route'
import Tooltip from '../Tooltip'
import UtilsHelper from '../../helpers/utils'

import useDebounce from '../../helpers/debounce'
import useAppContext from '../../hooks/useAppContext'

import AnimationLoading from '../../assets/animation-loading.gif'
import './index.css'

export default ({ entityType, entityData, entityTitleFilter, onChange }) => {
    const [ac] = useAppContext()

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [pages, setPages] = useState(1)
    const [term, setTerm] = useState('')
    const [page, setPage] = useState(1)
    const [suggestions, setSuggestions] = useState([])
    const [picks, setPicks] = useState([])

    const debouncedTerm = useDebounce(term, 250)

    const apiUrl = `/api/v2/admin/${entityType}`

    useEffect(() => {
        setIsLoading(true)
        setPages(1)
        
        if (!/null|undefined/.test(term) && page) {
            FetchHelper({
                url: `${apiUrl}?q=${term}&page=${page}`
            }, (res) => {
                setIsLoading(false)
                setPages(res.body.total_pages)
                setSuggestions(res.body.data.map((item) => {
                    let name = entityTitleFilter ? entityTitleFilter(item) : item.name

                    return {
                        value: item.id,
                        title: name,
                        item: item
                    }
                }))
            }, (res) => {
                setIsLoading(false)
            })
        }
    }, [debouncedTerm, page])

    const handleAdd = (item) => {
        let newPicks = [
            ...picks,
            item
        ]

        setPicks(newPicks)
        onChange(newPicks)
    }

    const handleRemove = (index) => {
        let newPicks = picks.filter((item, i) => index !== i)

        setPicks(newPicks)
        onChange(newPicks)
    }

    const handleSelectedClass = (item) => {
        let isSelected = false

        picks.forEach((itm, idx) => {
            if (item.item.id === itm.item.id) {
                isSelected = true
            }
        })

        return isSelected
    }

    return (
        <div className={`entity-selector-container scroll-invisible`}>
            <div className="entity-selector-container-inner">
                <div className="entity-selector-search">
                    <input type="text" value={term} onChange={(e) => setTerm(e.target.value)} placeholder="Search..." />
                </div>

                <ul className="entity-selector-suggestions entity-selector-list">
                    {isLoading ? <LoadingComponent show={isLoading} /> : suggestions && suggestions.length ? (
                        suggestions.map((item, index) => {
                            return (
                                <li key={`entity-selector-suggestion-${index}`} className={handleSelectedClass(item) ? 'selected' : ''} onClick={() => handleAdd(item)}>
                                    <span className="name">
                                        <PopoverComponent position="top-start" offsetPx={24}>
                                            <>
                                                <em className="entity-title">{item.title}</em>
                                            </>
                                            <div className="popover-default">
                                                <div className="popover-default-title">{item.title}</div>
                                                <hr />
                                                <div className="popover-default-image" style={{backgroundImage: `url(${item.item.image.image_url})`}}></div>
                                                <hr />
                                                <ul className="popover-default-list">
                                                    <li><em className="title">Code</em> {item.item.code}</li>
                                                    <li><em className="title">Price</em> {UtilsHelper.formatDollar(item.item.value)}</li>
                                                </ul>
                                            </div>
                                        </PopoverComponent>
                                    </span>
                                    <span className="code">{item.item.code}</span>
                                    <span className="list-controls">
                                        <i className="icon bi bi-plus"></i>
                                    </span>
                                </li>
                            )
                        })
                    ) : (
                        <span>No results...</span>
                    )}
                </ul>

                <div className={`entity-selector-controls ${isLoading ? 'disabled' : null}`}>
                    <PaginationComponent currentPage={page} totalPages={pages} setPage={setPage} />
                </div>

                <ul className={`entity-selector-picks entity-selector-list ${isLoading ? 'disabled' : null}`}>
                    {picks && picks.length ? (
                        picks.map((item, index) => {
                            return (
                                <li key={`entity-selector-pick-${index}`} onClick={() => handleRemove(index)}>
                                    <span className="name">
                                        <PopoverComponent position="top-start" offsetPx={24}>
                                            <>
                                                <em className="entity-title">{item.title}</em>
                                            </>
                                            <div className="popover-default">
                                                <div className="popover-default-title">{item.title}</div>
                                                <hr />
                                                <div className="popover-default-image" style={{backgroundImage: `url(${item.item.image.image_url})`}}></div>
                                                <hr />
                                                <ul className="popover-default-list">
                                                    <li><em className="title">Code</em> {item.item.code}</li>
                                                    <li><em className="title">Price</em> {UtilsHelper.formatDollar(item.item.value)}</li>
                                                </ul>
                                            </div>
                                        </PopoverComponent>
                                    </span>
                                    <span className="code">{item.item.code}</span>
                                    <span className="list-controls">
                                        <i className="icon bi bi-dash"></i>
                                    </span>
                                </li>
                            )
                        })
                    ) : (
                        <div>Make a selection</div>
                    )}
                </ul>
            </div>
        </div>
    )
}