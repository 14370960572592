import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'

import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import { FileTypes } from '../../sys/constants/enums'

const CharitiesPageNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Charities/New')

    const [data, setData, clearData, dataDefault] = useDraft('Tools:Charities:Data')

    const [errors, setErrors] = useState()

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = {
                ...data
            }

            if (body.image_attributes) {
                body.image_base64 = body.image_attributes.value
            }

            delete body.image_attributes

            FetchHelper({
                url: `/api/v2/admin/charities`,
                method: 'POST',
                body: {
                    charity: body
                }
            }, (res) => {
                ac.showSpinner(false)
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }
                reject()
            })
        })
    }

    return (
        <div className="charities-page-container page-container">
            <PageHeaderComponent title={`New Charity`} />

            <NoticeComponent notices={notices} location="Main" />

            <div className="page-form-container">
                <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                    {
                        type: 'input-text',
                        name: 'name',
                        label: 'Name',
                    },
                    {
                        type: 'input-text',
                        name: 'caption',
                        label: 'Caption',
                    },
                    {
                        type: 'input-file',
                        fileType: 'image',
                        name: 'image_attributes',
                        label: 'Image',
                        acceptedFileTypes: FileTypes.IMAGES
                    }
                ]}/>
            </div>

            <ControlBar handleSubmit={handleSubmit} cancelUrl="/charities" handleAfterSubmit={() => RouteHelper.redirect('/charities')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault],
            ]} />
        </div>
    )
}

export default CharitiesPageNew