import lodash from 'lodash';
import { Accordion } from '../../../components';
import { ModalComponent } from '../../../componentsV2/Complex';
import RouteHelper from '../../../helpers/route';
import './OrderOverviewModal.css';

const OrderOverviewModal = ({ order, onClose }) => {
  const getFieldDisplayValueStr = (field) => {
    let valueStr;
    switch (field.type) {
      case 'checkbox':
        valueStr = field.value === true ? 'Yes' : 'No';
        break;

      case 'children':
        if (lodash.isObject(field.value))
          valueStr = lodash
            .chain(field.value)
            .map((child) => {
              let value = child.first_name;
              if (value) value += ' ';
              value += child.last_name;
              if (value) value += ', ';
              if (
                child.age !== null &&
                child.age !== undefined &&
                child.age !== ''
              )
                value += `Age ${child.age}`;
              return value;
            })
            .join(' | ')
            .value();
        valueStr = field.value || 'N/A';
        break;

      default:
        valueStr = field.value;
        break;
    }

    return valueStr;
  };

  return (
    <ModalComponent
      events={{ handleClose: onClose }}
      config={{ classNames: 'order-overview-container' }}
    >
      <div>
        <p className="text-lg font-bold">Order Overview ({order.fastpass})</p>

        {/* Order level Custom Fields */}
        <p className="text-base font-bold mt-1 mb-1">Order Fields</p>
        <div className="fields-container">
          {order.fields?.data?.length === 0 && (
            <p className="m-0 text-gray-500 font-medium text-center">
              No order fields available
            </p>
          )}
          {order.fields?.data?.map((field) => {
            if (field?.level !== 'order') return null;

            return (
              <div key={field.id}>
                <p className="m-0 text-gray-500 font-medium">{field.ask}</p>
                <p className="m-0">{getFieldDisplayValueStr(field)}</p>
              </div>
            );
          })}
        </div>

        <hr className="my-2" />

        {/* Packages */}
        <p className="text-base font-bold mb-1">Packages</p>
        {order.packages?.data?.length === 0 && (
          <p className="m-0 text-gray-500 font-medium text-center">
            No packages available
          </p>
        )}
        {/* Headers */}
        {order.packages?.data?.length > 0 && (
          <div className="package-item">
            <div className="headers">
              <p className="name">Name</p>
              <p className="addons-count">Addons Count</p>
              <p className="quantity">Quantity</p>
              <p className="total-price">Total Price</p>
            </div>
          </div>
        )}
        {order.packages?.data?.map((p) => {
          return (
            <div key={p.id} className="package-item">
              {/* Items */}
              <Accordion className={'py-2'}>
                {/* Title */}
                <div className="flex">
                  <p className="name">{p.name}</p>
                  <p className="addons-count">{p.addons?.data?.length || 0}</p>
                  <p className="quantity">{p.quantity}</p>
                  <p className="total-price">{p.total_price}</p>
                </div>

                {/* Content */}
                <div className="pl-4 pt-2">
                  <p className="font-medium mb-1">Package Fields</p>
                  <div className="fields-container">
                    {p?.fields?.data?.length === 0 && (
                      <p className="m-0 text-gray-500 font-medium text-center">
                        No package fields available
                      </p>
                    )}
                    {p?.fields?.data?.map((f) => {
                      return (
                        <div key={f.id}>
                          <p className="m-0 text-gray-500 font-medium">
                            {f.ask}
                          </p>
                          <p className="m-0">{getFieldDisplayValueStr(f)}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Accordion>
            </div>
          );
        })}

        <hr className="mt-4 mb-2" />

        {/* action buttons */}
        <div className="ui--controls">
          <button
            onClick={() => RouteHelper.redirect(`/order/${order.id}`, true)}
          >
            Open Order
          </button>

          <button className="secondary" onClick={() => onClose()}>
            Cancel
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default OrderOverviewModal;
