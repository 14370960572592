export const DEFAULT_SEARCH_RESULTS = [
    {
        id: 1,
        title: 5,
        value: 5
    }, {
        id: 2,
        title: 10,
        value: 10
    }, {
        id: 3,
        title: 25,
        value: 25
    }, {
        id: 4,
        title: 50,
        value: 50
    }, {
        id: 5,
        title: 75,
        value: 75
    }, {
        id: 6,
        title: 100,
        value: 100
    }, {
        id: 7,
        title: 150,
        value: 150
    }
]