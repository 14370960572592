export function getPlanFormFields(
  suggestionsTags = [],
  barcodeFieldsOptions = []
) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name'
    },
    {
      type: 'tags',
      name: 'tags',
      label: 'Tags',
      suggestions: suggestionsTags
    },
    {
      type: 'group',
      label: 'Barcode Field',
      label_info:
        'If any custom fields are added to this Package Plan, they will appear as options for the barcode',
      fields: [
        {
          type: 'select',
          name: 'barcode_type',
          value: 'code_39',
          options: [
            {
              value: 'code_39',
              title: 'Code 39'
            },
            {
              value: 'code_128b',
              title: 'Code 128B'
            },
            {
              value: 'qr_code',
              title: 'QR Code'
            }
          ]
        },
        {
          type: 'select',
          name: 'barcode_field',
          value: 'fastpass',
          options: barcodeFieldsOptions
        }
      ]
    },
    {
      type: 'input-text',
      name: 'notify_email',
      label: 'Notification Email',
      label_info:
        'Email will only be sent to this recipient if attached products have "Notify on Purchase?" toggled on.'
    },
    {
      type: 'switch',
      name: 'auto_add_packages',
      label: 'Auto-Add All Packages?',
      label_info:
        "This will cause any packages attached to this package plan to be added to the customer's order automatically as soon as the customer visits the associated Santa Mall's website."
    },
    {
      type: 'switch',
      name: 'disable_package_listing',
      label: 'Disable Package Listing',
      label_info:
        "This will immediately and automatically send the customer to the cart as soon as the customer visits the associated Santa Mall's website. It will also prevent the customer from removing items from their shopping cart. Only available when 'Auto-Add ALL Packages' is enabled."
    }
  ];
}

export function getCharityFormFields() {
  return [
    {
      type: 'select-paginated',
      name: 'charity_id',
      label: 'Charity',
      apiUrl: '/api/v2/admin/charities'
    },
    {
      type: 'input-text',
      name: 'caption_override',
      label: 'Caption Override'
    }
  ];
}

export function getHelperFormFields() {
  return [
    {
      type: 'input-text',
      name: 'helper_caption',
      label: 'Helper Caption',
      placeholder: 'What is this?'
    },
    {
      type: 'wysiwyg',
      name: 'helper_content',
      label: 'Helper Content'
    }
  ];
}
