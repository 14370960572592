import PropTypes from 'prop-types'

import './index.css'

const Section = ({ children, className='' }) => {
    return (
        <div className={`ui-l-section ${className}`}>
            {children}
        </div>
    )
}

Section.propTypes = {
    className: PropTypes.string
}

export { Section }