import {useState, useEffect} from 'react'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import { ProgressBar } from '@uppy/react'
import { DragDrop, useUppy } from '@uppy/react'

import './index.css'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

function AdvancedFileUploader({ endpoint, onUpload=()=>{}, onComplete=()=>{}, onUploadSuccess=()=>{}, onUploadError=()=>{} }) {
    const uppy = useUppy(() => {
        return new Uppy({
            restrictions: {
                allowedFileTypes: ['image/*'],
            },
            autoProceed: true
        }).use(XHRUpload, { endpoint: endpoint })
    })

    uppy.on('files-added', (file, error, response) => {
        onUpload()
    })

    uppy.on('file-added', (file, error, response) => {
        onUpload()
    })

    uppy.on('upload-success', (file, response) => {
        onUploadSuccess(file, response)

        setTimeout(() => {
            uppy.removeFile(file.id);
        }, 250);
    })

    uppy.on('upload-error', (result, error) => {
        let errorMessage = 'Error uploading file'

        try {
            errorMessage = JSON.parse(error.request.response)[0]
        } catch {}
        
        onUploadError(result, errorMessage)
    })

    uppy.on('complete', (result) => {
        onComplete(result)
    })

    return (
        <div className="advanced-file-uploader-container">
            <ProgressBar uppy={uppy} hideAfterFinish={true} />
            <DragDrop
                width="100%"
                height="100%"
                // note="Images up to 200×200px"
                uppy={uppy}
                locale={{
                    strings: {
                        // Text to show on the droppable area.
                        // `%{browse}` is replaced with a link that opens the system file selection dialog.
                        dropHereOr: 'Drop here or %{browse}',
                        // Used as the label for the link that opens the system file selection dialog.
                        browse: 'browse',
                    },
                }}
                allowMultipleFiles={true}
                onDrop={(event) => {

                }}
            />
        </div>
    )
}

export default AdvancedFileUploader