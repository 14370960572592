import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'

const CategoriesNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Categories/New')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('PSM:Categories:DataMain')

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            FetchHelper({
                url: `/api/v2/admin/categories`,
                method: 'POST',
                body: {
                    category: dataMain
                }
            }, (res) => {
                ac.showSpinner(false)

                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className="categories-new-page-container page-container">
            <PageHeaderComponent title="New Category" />
            <NoticeComponent location="Main" notices={notices} />
            <FormComponent formData={dataMain} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                    type: 'input-text',
                    name: 'name',
                    label: 'Name'
                }]} />
            <ControlBar handleSubmit={handleSubmit} cancelUrl="/categories" handleAfterSubmit={() => RouteHelper.redirect('/categories')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [dataMain, clearDataMain, dataMainDefault]
            ]} />
        </div>
    )
}

export default CategoriesNew
