import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'
import {XMLTAGS} from '../../sys/constants/XMLTags'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { useParams } from 'react-router-dom'

const FieldsEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/Fields/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()
    const [dataConstraints, setDataConstraints] = useState({})
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            field: {...dataMain}
        }

        // POST requires tags to be renamed to tag_names
        body.field.tag_names = body.field.tags
        delete body.field.tags

        if (body.field_type === 'single_line') {
            body = {...body, ...dataConstraints}
        }

        FetchHelper({
            url: `/api/v2/admin/fields/${data.id}`,
            method: 'PUT',
            body: body
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/fields')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/fields/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })

        FetchHelper({
            url: '/api/v2/admin/fields/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (error) => {

        })
    }, [])

    return (
        <div className="fields-edit-page-container page-container">
            <PageHeaderComponent title={`Edit Field - ${dataMain?.name}`} />
            <NoticeComponent location="Main" notices={notices} />
            {data ? <FormComponent key="optionsMain" formData={dataMain || data} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                type: 'input-text',
                name: 'name',
                label: 'Name',
                placeholder: 'Name'
            },{
                type: 'tags',
                name: 'tags',
                label: 'Tags',
                suggestions: suggestionsTags
            },{
                type: 'input-text',
                name: 'ask',
                label: 'Ask',
                placeholder: `What's your favorite color?`
            },{
                type: 'select',
                name: 'field_type',
                label: 'Field Type',
                options: [
                    { title: 'Single Line', value: 'single_line' },
                    { title: 'Dropdown', value: 'dropdown' },
                    { title: 'Multiline Text', value: 'text' },
                    { title: 'Checkbox', value: 'checkbox' },
                    { title: 'Email', value: 'email' },
                    { title: 'Date', value: 'date' },
                    { title: 'Number', value: 'number' },
                    { title: 'Children', value: 'children' }
                ]
            },{
                type: 'input-text',
                name: 'caption',
                label: 'Caption',
                placeholder: 'Please enter or select a color.'
            },{
                type: 'textarea',
                name: 'options',
                label: 'Options',
                placeholder: 'Red;Green;Blue',
                field_info: 'This field is required for Dropdown type fields. Items separated by semicolon (;)',
                condition: (data) => {
                    return (data.field_type === 'dropdown')
                }
            },{
                type: 'select',
                name: 'required',
                label: 'Required?',
                options: [
                    { title: 'No', value: 'false' },
                    { title: 'Yes', value: 'true' }
                ]
            },{
                type: 'select',
                name: 'xml_tag',
                label: 'XML Tag',
                options: XMLTAGS
            }]} /> : null}
            {data && dataMain && dataMain.field_type === 'single_line' ?
                <div className="constraints-container">
                    <FormComponent formData={dataConstraints || data} formErrors={errors} onChange={setDataConstraints} fields={[{
                            type: 'title',
                            title: 'Constraints'
                        },{
                            type: 'textarea',
                            name: 'error_message',
                            label: 'Error Message',
                            field_info: 'This field only applies for Constraint Related errors. This overrides default error message.',
                            placeholder: 'Name'
                        },{
                            type: 'select',
                            name: 'numbers',
                            label: 'Numbers?',
                            value: 'allow',
                            options: [
                                { title: 'Allow', value: 'allow' },
                                { title: 'Disallow', value: 'disallow' }
                            ]
                        },{
                            type: 'select',
                            name: 'letters',
                            label: 'Letters?',
                            value: 'allow',
                            options: [
                                { title: 'Allow', value: 'allow' },
                                { title: 'Disallow', value: 'disallow' }
                            ]
                        },{
                            type: 'group',
                            label: 'Characters?',
                            field_info: 'Entered text is treated as a group of single characters, e.g. ABC = A and B and C. This is treated as a bypass, e.g. if numbers are allowed, but the character 9 is set to disallow, 0-8 is the final constraint',
                            fields: [{
                                type: 'select',
                                name: 'these_are',
                                value: 'allow',
                                options: [
                                    { title: 'Allow', value: 'allow' },
                                    { title: 'Disallow', value: 'disallow' }
                                ]
                            },{
                                type: 'input-text',
                                name: 'these'
                            }]
                        },{
                            type: 'input-number',
                            name: 'min_length_or_value',
                            label: 'Minimum Length'
                        },{
                            type: 'input-number',
                            name: 'max_length_or_value',
                            label: 'Maximum Length'
                        },{
                            type: 'textarea',
                            name: 'regex',
                            label: 'Custom Regular Expression',
                            field_info: 'Providing a Regular Expression constraint takes precedence over all other constraints.'
                        }]} />
                </div> : null}
            <ControlBar baseApiUrl="/fields" redirectUrl="/fields" cancelUrl="/fields" data={data} handleSubmit={handleSubmit} />
        </div>
    )
}

export default FieldsEdit
