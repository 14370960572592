import { OrderDetails } from './OrderDetails'
import { OrderPlaceholder } from './OrderPlaceholder'
import { PackageDetails } from './PackageDetails'
import { PayModalComponent } from './PayModal'
import { DiscountModalComponent } from './DiscountModal'

export {
    OrderDetails,
    OrderPlaceholder,
    PackageDetails,
    PayModalComponent,
    DiscountModalComponent
}