import { COUNTRIES } from "../../../sys/constants/Countries";
import dayjs from 'dayjs';

export function getGeneralFormFieldsNew(
  suggestionsTags = [],
  centricsServiceEnabled,
  currencies = [],
  timezones = [],
  dataMain
) {
  return [
    {
      type: "input-text",
      name: "name",
      label: "Name",
    },
    {
      type: "tags",
      name: "tags",
      label: "Tags",
      suggestions: suggestionsTags,
    },
    {
      type: "input-file",
      name: "favicon",
      label: "Favicon",
      label_info: "Square, ideally 512x512px",
      imageUrl: dataMain?.favicon
    },
    {
      type: "input-file",
      name: "location_group_image",
      label: "Location Group Image",
      label_info: "(800px wide)",
      imageUrl: dataMain?.location_group_image
    },
    {
      type: "input-text",
      name: "external_id",
      label: "External ID",
    },
    {
      type: "input-text",
      name: "centrics_partner_id",
      label: "Centrics Partner ID",
      label_info:
        "(Must be a number between 1000 and 9999, inclusive. If external id is valid or not a Centrics Partner, this is not necessary)",
      condition: () => {
        return centricsServiceEnabled;
      },
    },
    {
      type: "select-paginated",
      name: "location_group_id",
      label: "Location Group",
      apiUrl: "/api/v2/admin/location-groups",
    },
    {
      type: "select-paginated",
      name: "market_group_id",
      label: "Market Group",
      apiUrl: "/api/v2/admin/market-groups",
    },
    {
      type: "select",
      name: "type",
      label: "Theme",
      value: "Santa",
      options: [
        { title: "Santa", value: "Santa" },
        { title: "Bunny", value: "Bunny" },
        { title: "Dance", value: "Dance" },
        { title: "Generic", value: "Generic" },
      ],
    },
    {
      type: "select",
      name: "purchase_format",
      label: "Purchase Format",
      value: "PrePay",
      options: [
        { title: "PrePay", value: "PrePay" },
        { title: "FastPass", value: "FastPass" },
      ],
    },
    {
      type: "select",
      name: "fee_format",
      label: "Fee Format",
      value: "Backend",
      options: [
        { title: "Convenience", value: "Convenience" },
        { title: "Backend", value: "Backend" },
      ],
    },
    {
      type: "input-text",
      name: "contact_email",
      label: "Contact Email",
      field_info: "Separate multiple emails with semi-colon (;)",
    },
    {
      type: "input-text",
      name: "tax",
      label: "Tax",
      value: "0.0",
    },
    {
      type: "input-text",
      name: "snh",
      label: "Shipping & Handling",
      value: "0.0",
    },
    {
      type: "switch",
      name: "snh_taxable",
      label: "S&H Taxable?",
      value: false,
    },
    {
      type: "input-text",
      name: "fee_adjustment",
      label: "Fee adjustment",
      value: "0.0",
    },
    {
      type: "switch",
      name: "fee_taxable",
      label: "Fee taxable?",
    },
    {
      type: "input-text",
      name: "address1",
      label: "Address 1",
      errorField: 'address.address1'
    },
    {
      type: "input-text",
      name: "address2",
      label: "Address 2",
      errorField: 'address.address2'
    },
    {
      type: "input-text",
      name: "city",
      label: "City",
      errorField: 'address.city'
    },
    {
      type: "input-text",
      name: "state_province",
      label: "State/Province",
      max: 3,
      errorField: 'address.state_province'
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      value: "US",
      options: [{ title: '--', value: '' }].concat(COUNTRIES),
      errorField: 'address.country'
    },
    {
      type: "input-text",
      name: "postal_code",
      label: "Postal Code",
      errorField: 'address.postal_code'
    },
    {
      type: "select",
      name: "currency_code",
      label: "Currency Code",
      options: [{ title: '--', value: '' }].concat(currencies),
    },
    {
      type: "wysiwyg",
      name: "special_instructions",
      label: "Special Instructions",
    },
    {
      type: "datetime",
      name: "starts_at",
      label: "Start Date",
      defaultValue: dayjs(),
    },
    {
      type: "datetime",
      name: "ends_at",
      label: "Expiration Date",
      label_info: "This will override global setting",
      errorField: 'expiration_date'
    },
    {
      type: "select",
      name: "timezone",
      label: "Timezone",
      options: [{ title: '--', value: '' }].concat(timezones),
    },
    {
      type: "input-text",
      name: "phone_number",
      label: "Phone Number",
    },
    {
      type: "input-text",
      name: "website",
      label: "Website",
    },
    {
      type: "input-file",
      name: "logo",
      label: "Logo",
      label_info: "(512kb Max, 650w x 84h max)",
      imageUrl: dataMain?.logo
    },
    {
      type: "select-paginated",
      name: "package_plan_id",
      label: "Package Plan",
      apiUrl: "/api/v2/admin/package-plans",
      relatedFields: ["package_plan"]
    },
    {
      type: "select-paginated",
      name: "marketing_plan_id",
      label: "Marketing Plan",
      apiUrl: "/api/v2/admin/marketing-plans",
      relatedFields: ["marketing"]
    },
    {
      type: "select-paginated",
      name: "color_scheme_id",
      label: "Color Scheme",
      apiUrl: "/api/v2/admin/color-schemes",
    },
    {
      type: "select-paginated",
      name: "payment_gateway_id",
      label: "Payment Account",
      apiUrl: "/api/v2/admin/payment-accounts",
      relatedFields: ["payment_gateway"]
    },
    {
      type: "select-paginated",
      name: "vanity_domain_id",
      label: "Vanity Domain",
      apiUrl: "/api/v2/admin/vanity-domains",
    },
  ];
}

export function getLateOrdersFormFields() {
  return [
    {
      type: "datetime",
      name: "late_order_date",
      label: "Late Order Date",
    },
    {
      type: "select-paginated",
      name: "late_order_package_plan_id",
      label: "Late Order Package Plan",
      apiUrl: "/api/v2/admin/package-plans",
    },
    {
      type: "select-paginated",
      name: "late_order_marketing_plan_id",
      label: "Late Order Marketing Plan",
      apiUrl: "/api/v2/admin/marketing-plans",
    },
    {
      type: "input-money",
      name: "late_order_shipping",
      label: "Late Order Shipping",
      value: "0.0",
      step: "0.05",
    },
    {
      type: "input-money",
      name: "late_order_fee",
      label: "Late Order Fee",
      value: "0.0",
      step: "0.05",
    },
    {
      type: "switch",
      name: "notify_on_late_order",
      label: "Notify On Late Orders?",
      label_info:
        "If this option is enabled, and an email address(es) is included, an email will be sent for any order placed after the date/time shown above. This option is ideal when accepting late orders through this system",
      checked: true,
    },
    {
      type: "input-text",
      name: "customer_service_email",
      label: "Customer Service Email",
      placeholder: "info@studio.com;heyhey@webmd.com",
      field_info:
        "Separate multiple emails with a semi-colon ( ; ). Setting this will override your global setting. Current Global Setting: info@studio.com;heyhey@webmd.com",
    },
  ];
}

export function getMarketingFormFields() {
  return [
    {
      type: "switch",
      name: "facebook_enabled",
      label: "Meta integration?",
    },
    {
      type: "input-text",
      name: "ga_tracking_id",
      label: "GA tracking ID",
    },
    {
      type: "input-text",
      name: "gtm_container_id",
      label: "GTM container ID",
    },
    {
      type: "input-text",
      name: "facebook_pixel_id",
      label: "Meta pixel",
    },
    {
      type: "input-text",
      name: "tiktok_pixel_id",
      label: "TikTok pixel",
    },
  ];
}

export function getOptionsFormFields() {
  return [
    {
      type: "switch",
      name: "block_mobile",
      label: "Block mobile purchases?",
    },
    {
      type: "switch",
      name: "hide_from_listing",
      label: "Hide from event listing?",
    },
    {
      type: "switch",
      name: "hide_from_postal_code_search",
      label: "Hide from postal code search?",
    },
    {
      type: "switch",
      name: "display_name_during_customer_experience",
      label: "Display name during customer experience?",
      apiUrl: "/api/v2/admin/marketing-plans",
    },
    {
      type: "switch",
      name: "collect_shipping",
      label: "Collect shipping address?",
      apiUrl: "/api/v2/admin/marketing-plans",
    },
    {
      type: "select",
      name: "email_collection_method",
      label: "Email collection method",
      options: [
        {
          title: "Opt Out",
          value: 1,
        },
        {
          title: "Opt in",
          value: 2,
        },
        {
          title: "Opt in w/ Age verification",
          value: 3,
        },
      ],
    },
    {
      type: "select",
      name: "email_collection_default",
      label: "Email collection default",
      options: [
        {
          title: "Unchecked",
          value: false,
        },
        {
          title: "Checked",
          value: true,
        },
      ],
    },
    {
      type: "wysiwyg",
      name: "email_collection_message",
      label: "Email collection message",
    },
    {
      type: "input-text",
      name: "email_friendly_from",
      label: "Email friendly from",
    },
    {
      type: "input-text",
      name: "secret_code",
      label: "Secret code",
    },
    {
      type: "switch",
      name: "self_service_enabled",
      label: "Self service enabled?",
    },
    {
      type: "wysiwyg",
      name: "market_confirmation_message",
      label: "Market confirmation message",
    },
  ];
}

export function getGeneralFormFieldsEdit(
  suggestionsTags = [],
  centricsServiceEnabled,
  currencies = [],
  timezones = [],
  dataMain
) {
  return [
    {
      type: "input-text",
      name: "name",
      label: "Name",
    },
    {
      type: "tags",
      name: "tags",
      label: "Tags",
      suggestions: suggestionsTags,
    },
    {
      type: "input-file",
      name: "favicon",
      label: "Favicon",
      label_info: "Square, ideally 512x512px",
      imageUrl: dataMain?.favicon,
    },
    {
      type: "input-file",
      name: "location_group_image",
      label: "Location Group Image",
      label_info: "(800px wide)",
      imageUrl: dataMain?.location_group_image,
    },
    {
      type: "input-text",
      name: "external_id",
      label: "External ID",
    },
    {
      type: "input-text",
      name: "centrics_partner_id",
      label: "Centrics Partner ID",
      label_info:
        "(Must be a number between 1000 and 9999, inclusive. If external id is valid or not a Centrics Partner, this is not necessary)",
      condition: () => centricsServiceEnabled,
    },
    {
      type: "select-paginated",
      name: "location_group_id",
      label: "Location Group",
      apiUrl: "/api/v2/admin/location-groups",
      offerClearBtn: true,
    },
    {
      type: "select-paginated",
      name: "market_group_id",
      label: "Market Group",
      apiUrl: "/api/v2/admin/market-groups",
      offerClearBtn: true,
    },
    {
      type: "select",
      name: "type",
      label: "Theme",
      value: "Santa",
      options: [
        { title: "Santa", value: "Santa" },
        { title: "Bunny", value: "Bunny" },
        { title: "Dance", value: "Dance" },
        { title: "Generic", value: "Generic" },
      ],
    },
    {
      type: "select",
      name: "purchase_format",
      label: "Purchase Format",
      value: "PrePay",
      options: [
        { title: "PrePay", value: "PrePay" },
        { title: "FastPass", value: "FastPass" },
      ],
    },
    {
      type: "select",
      name: "fee_format",
      label: "Fee Format",
      value: "Backend",
      options: [
        { title: "Convenience", value: "Convenience" },
        { title: "Backend", value: "Backend" },
      ],
    },
    {
      type: "input-text",
      name: "contact_emails",
      label: "Contact Email",
      field_info: "Separate multiple emails with semi-colon (;)",
    },
    {
      type: "input-text",
      name: "tax",
      label: "Tax",
      value: "0.0",
    },
    {
      type: "input-text",
      name: "snh",
      label: "Shipping & Handling",
      value: "0.0",
    },
    {
      type: "switch",
      name: "snh_taxable",
      label: "S&H Taxable?",
    },
    {
      type: "input-text",
      name: "fee_adjustment",
      label: "Fee adjustment",
      value: "0.0",
    },
    {
      type: "switch",
      name: "fee_taxable",
      label: "Fee taxable?",
    },
    {
      type: "input-text",
      name: "address1",
      label: "Address 1",
      errorField: 'address.address1'
    },
    {
      type: "input-text",
      name: "address2",
      label: "Address 2",
      errorField: 'address.address2'
    },
    {
      type: "input-text",
      name: "city",
      label: "City",
      errorField: 'address.city'
    },
    {
      type: "input-text",
      name: "state_province",
      label: "State/Province",
      max: 3,
      errorField: 'address.state_province'
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      value: "US",
      options: COUNTRIES,
      errorField: 'address.country'
    },
    {
      type: "input-text",
      name: "postal_code",
      label: "Postal Code",
      errorField: 'address.postal_code'
    },
    {
      type: "select",
      name: "currency_code",
      label: "Currency Code",
      options: currencies,
    },
    {
      type: "wysiwyg",
      name: "special_instructions",
      label: "Special Instructions",
    },
    {
      type: "datetime",
      name: "starts_at",
      label: "Start Date",
    },
    {
      type: "datetime",
      name: "ends_at",
      label: "Expiration Date",
      label_info: "This will override global setting",
      errorField: 'expiration_date'
    },
    {
      type: "select",
      name: "timezone",
      label: "Timezone",
      options: timezones,
    },
    {
      type: "input-text",
      name: "phone_number",
      label: "Phone Number",
    },
    {
      type: "input-text",
      name: "website",
      label: "Website",
    },
    {
      type: "input-file",
      name: "logo",
      label: "Logo",
      label_info: "(512kb Max, 650w x 84h max)",
      imageUrl: dataMain?.logo,
    },
    {
      type: "select-paginated",
      name: "package_plan_id",
      label: "Package Plan",
      apiUrl: "/api/v2/admin/package-plans",
      relatedFields: ["package_plan"],
    },
    {
      type: "select-paginated",
      name: "marketing_plan_id",
      label: "Marketing Plan",
      apiUrl: "/api/v2/admin/marketing-plans",
      relatedFields: ["marketing"],
    },
    {
      type: "select-paginated",
      name: "color_scheme_id",
      label: "Color Scheme",
      apiUrl: "/api/v2/admin/color-schemes",
      offerClearBtn: true,
    },
    {
      type: "select-paginated",
      name: "payment_gateway_id",
      label: "Payment Account",
      apiUrl: "/api/v2/admin/payment-accounts",
      relatedFields: ["payment_gateway"],
    },
    {
      type: "select-paginated",
      name: "vanity_domain_id",
      label: "Vanity Domain",
      apiUrl: "/api/v2/admin/vanity-domains",
      offerClearBtn: true,
    },
  ];
}

export function getProofingFormFields(dataProofSchema) {
  return [
    {
      type: 'input-text',
      name: 'lab_job_id',
      label: 'Lab Job',
      label_info: 'Used for Lab job codes, numbers or IDs'
    },
    {
      type: 'select-paginated',
      name: 'proof_package_plan_id',
      label: 'Proof Package Plan',
      apiUrl: '/api/v2/admin/package-plans',
      relatedFields: ['proof_package_plan']
    },
    {
      type: 'select-paginated',
      name: 'proof_marketing_plan_id',
      label: 'Proof Marketing Plan',
      apiUrl: '/api/v2/admin/marketing-plans',
      relatedFields: ['proof_marketing']
    },
    {
      type: 'switch',
      name: 'notify_on_new_proof',
      label: 'Notify on new proof'
    },
    {
      type: 'select-paginated',
      name: 'new_proof_notification_text_template_id',
      label: 'New proof notification text template',
      apiUrl: '/api/v2/admin/templates',
      condition: (data) => data?.notify_on_new_proof === true,
      queries: [{ key: 'type', value: 'TextTemplate' }]
    },
    {
      type: 'select-paginated',
      name: 'new_proof_notification_email_template_id',
      label: 'New proof notification email template',
      apiUrl: '/api/v2/admin/templates',
      condition: (data) => data?.notify_on_new_proof === true,
      queries: [{ key: 'type', value: 'EmailTemplate' }]
    },
    {
      type: 'switch',
      name: 'notify_on_proof_image_change',
      label: 'Notify on proof image change'
    },
    {
      type: 'select-paginated',
      name: 'proof_image_change_notification_text_template_id',
      label: 'Proof image change notification text template',
      apiUrl: '/api/v2/admin/templates',
      condition: (data) => data?.notify_on_proof_image_change === true,
      queries: [{ key: 'type', value: 'TextTemplate' }]
    },
    {
      type: 'select-paginated',
      name: 'proof_image_change_notification_email_template_id',
      label: 'Proof image change notification email template',
      apiUrl: '/api/v2/admin/templates',
      condition: (data) => data?.notify_on_proof_image_change === true,
      queries: [{ key: 'type', value: 'EmailTemplate' }]
    },
    {
      type: 'datetime',
      name: 'require_proofs_at',
      label: 'Require proofs at',
      condition: () => dataProofSchema?.requires_proof_to_place_order !== true
    },
    {
      type: 'select',
      name: 'multiproof_support',
      label: 'Multiproof Support?',
      label_info: '"System Default" will fallback on Package Plan value. Other options will override Package Plan for this singular entity.',
      options: [
        {
          title: 'System Default',
          value: 'default'
        },
        {
          title: 'Yes',
          value: 'enabled'
        },
        {
          title: 'No',
          value: 'disabled'
        }
      ]
    }
  ];
}

export function getProofDetailsEditFormFields() {
  return [
    {
      type: 'textarea',
      name: 'email',
      label: 'Email',
      field_info: 'Separate multiple emails with semi-colon (;)',
      placeholder: 'Separate multiple emails with semi-colon (;)'
    },
    {
      type: 'switch',
      name: 'email_opt_in',
      label: 'Email opt in'
    },
    {
      type: 'textarea',
      name: 'phone_number',
      label: 'Phone number',
      field_info: 'Separate multiple emails with semi-colon (;)',
      placeholder: 'Separate multiple emails with semi-colon (;)'
    },
    {
      type: 'switch',
      name: 'sms_opt_in',
      label: 'SMS opt in'
    },
    {
      type: 'input-text',
      name: 'first_name',
      label: 'First name'
    },
    {
      type: 'input-text',
      name: 'last_name',
      label: 'Last name'
    },
    {
      type: 'input-text',
      name: 'teacher',
      label: 'Teacher'
    },
    {
      type: 'input-text',
      name: 'grade',
      label: 'Grade'
    },
    {
      type: 'input-text',
      name: 'group_number',
      label: 'Group number'
    },
    {
      type: 'select-paginated',
      name: 'background_id',
      label: 'Default background',
      apiUrl: '/api/v2/admin/backgrounds',
      offerClearBtn: true
    }
  ];
}
