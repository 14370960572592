const Decorators = {

    Props: (props, additionalProps = {}) => {
        const { events = {}, ...remainingProps } = props || {}

        const returnValue = {
            decoratedProps: {
                ...events,
                ...remainingProps,
                ...additionalProps,
            },
            originalProps: {
                ...props
            }
        }

        return returnValue
    },

    String: (items = []) => {
        let returnValue = []

        if (!Array.isArray(items) || items.length === 0)
            return

        items.forEach(item => {
            if (!Array.isArray(item) && item)
                return returnValue.push(item)

            if (Array.isArray(item) && item[1])
                return returnValue.push(item[0])
        })

        return returnValue.join(' ')
    },

    Object: (props = {}) => {
        let returnValue = {}

        Object.keys(props).forEach(key => {
            const item = props[key]

            if (!item === undefined || item === '')
                return

            returnValue[key] = item
        })

        return returnValue
    }
}

export {
    Decorators
}