import { useState, useEffect } from 'react'

import AccordionComponent from '../../../../components/Accordion'
import AdvancedFileUploaderComponent from '../../../../components/AdvancedFileUploader'
import ConfirmComponent from '../../../../components/Confirm'
import CopyButtonComponent from '../../../../components/CopyButton'
import FormComponent from '../../../../components/Form'
import ImageDragAndDropComponent from '../../../../components/ImageDragAndDrop'
import ModalComponent from '../../../../components/Modal'
import PaginationComponent from '../../../../components/Pagination'
import PopoverComponent from '../../../../components/Popover'
import QuickSearchComponent from '../../../../components/QuickSearch'
import SwitchComponent from '../../../../components/Switch'

import FetchHelper from '../../../../helpers/fetch'
import UtilsHelper from '../../../../helpers/utils'

import useAppContext from '../../../../hooks/useAppContext'
import useTabs from '../../../../hooks/useTabs'

import './index.css'
import { FileTypes } from '../../../../sys/constants/enums'
import { getProofDetailsEditFormFields, getProofingFormFields } from '../../providers/form-fields'
import lodash from 'lodash'
import fetchHelper from '../../../../helpers/fetch'
import SampleSchema from '../../../../assets/samples/proof-schema-format.csv'
import useFullList from '../../../../hooks/useFullList'

const ProofingPartial = ({ entityData, proofingFormFields, setProofingFormFields, marketErrors, setMarketErrors }) => {
    const [ac] = useAppContext()

    const [tabs, tab, setTab, TabsComponent] = useTabs('Proofs', ['Proofs', 'Archived'])

    const [isLoading, setIsLoading] = useState(false)

    const [errors, setErrors] = useState()
    const [errorsModal, setErrorsModal] = useState()
    const [errorsCreateImageModal, setErrorsCreateImageModal] = useState()
    const [errorsBulkEditProofs, setErrorsBulkEditProofs] = useState()

    const [proofDetailsEditFormFields] = useState(getProofDetailsEditFormFields())
    const [errorsEditProofDetails, setErrorsEditProofDetails] = useState()

    const [data, setData] = useState()
    const [dataProofs, setDataProofs] = useState()
    const [dataProofItemEdit, setDataProofItemEdit] = useState()
    const [dataProofDetailsEdit, setDataProofDetailsEdit] = useState()
    const [dataProofDelete, setDataProofDelete] = useState()
    const [dataProofsArchived, setDataProofsArchived] = useState()
    const [dataProofSearchTerm, setDataProofSearchTerm] = useState('')
    const [dataProofCreateImageModal, setDataProofCreateImageModal] = useState()
    const [dataShowImages, setDataShowImages] = useState(false)
    const [dataProofUploadCompleteCount, setDataProofUploadCompleteCount] = useState()
    const [dataProofing, setDataProofing] = useState()
    const [dataBulkEditProofs, setDataBulkEditProofs] = useState()

    const [formDataEditProofDetails, setFormDataEditProofDetails] = useState()

    const [showDeleteAllProofsModal, setShowDeleteAllProofsModal] = useState(false) // delete all proofs
    const [mode, setMode] = useState()

    const [errorsUpload, setErrorsUpload] = useState([])

    const [timerProofItemEdit, setTimerProofItemEdit] = useState()

    const [pageProofs, setPageProofs] = useState(1)
    const [pageArchived, setPageArchived] = useState(1)

    const { getFullListData } = useFullList()

    const resetProofCreateImageModalData = () => {
        setDataProofCreateImageModal()
    }

    useEffect(() => {
        setDataProofing({
            lab_job_id: entityData?.lab_job_id,
            proof_package_plan_id: entityData?.proof_package_plan_id,
            proof_marketing_plan_id: entityData?.proof_marketing_plan_id,
            notify_on_new_proof: entityData?.notify_on_new_proof,
            new_proof_notification_text_template_id: entityData?.new_proof_notification_text_template_id,
            new_proof_notification_email_template_id: entityData?.new_proof_notification_email_template_id,
            notify_on_proof_image_change: entityData?.notify_on_proof_image_change,
            proof_image_change_notification_text_template_id: entityData?.proof_image_change_notification_text_template_id,
            proof_image_change_notification_email_template_id: entityData?.proof_image_change_notification_email_template_id,
            require_proofs_at: entityData?.require_proofs_at?.replace(/(-\d{1,2}:\d{1,2})|(Z)$/, ''),
        })
    }, [])

    useEffect(() => {
        let fields = getProofingFormFields(data)
        setProofingFormFields(fields)
    }, [data?.requires_proof_to_place_order])

    useEffect(() => {
        if (tab === 'Proofs') {
            fetchProofs()
        }

        if (tab === 'Archived') {
            fetchProofsArchived()
        }
    }, [pageProofs, pageArchived, tab])

    useEffect(() => {
        setPageArchived(1)
        setPageProofs(1)

        if (tab === 'Proofs') {
            fetchProofs(dataProofSearchTerm)
        } else if (tab === 'Archived') {
            fetchProofsArchived(dataProofSearchTerm)
        }
    }, [dataProofSearchTerm])

    const fetchData = () => {
        if (tab === 'Proofs') {
            fetchProofs()
        } else if (tab === 'Archived') {
            fetchProofsArchived()
        }
    }

    const fetchProofs = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/markets/${entityData.id}/proofs?page=${pageProofs}&q=${dataProofSearchTerm}`,
        }, (res) => {
            setDataProofs(res.body)
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const fetchProofsArchived = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/markets/${entityData.id}/proofs?page=${pageProofs}&archived=1&q=${dataProofSearchTerm}`,
        }, (res) => {
            setDataProofsArchived(res.body)
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleSaveData = async () => {
        if (!dataProofing) return;

        ac.showSpinner(true)
        let [res, err] = await UtilsHelper.executePromise(
            fetchHelper({
                url: `/api/v2/admin/markets/${entityData.id}`,
                method: 'put',
                body: { ...dataProofing }
            })
        )
        ac.showSpinner(false)

        if (res?.status === 422) {
            let errors = UtilsHelper.preProcess422Errors(
                [...proofingFormFields],
                res?.body
            )
            setMarketErrors(errors)
            UtilsHelper.scrollTop()
            return;
        }

        // refresh data
        fetchData()
    }

    const handleSaveSchema = () => {
        if (!data.proof_schema || !data.proof_schema_action) {
            return
        }

        ac.showSpinner(true)

        let body = {
            proof_schema_base64: data.proof_schema.value,
            proof_schema_action: data.proof_schema_action,
            options_attributes: {
                requires_proof_to_place_order: data.requires_proof_to_place_order
            }
        }

        FetchHelper({
            url: `/api/v2/admin/markets/${entityData.id}/proofs/schema`,
            method: 'post',
            body: {
                schema: body
            }
        }, (res) => {
            ac.showSpinner(false)
            fetchData()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleDeleteProof = (field) => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/associated/proofs/${field.id}`,
            method: 'delete',
            body: {}
        }, (res) => {
            ac.showSpinner(false)
            
            refreshItem(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleDeleteProofImage = (field) => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/associated/proof-images/${field.id}`,
            method: 'delete',
            body: {}
        }, (res) => {
            ac.showSpinner(false)
            
            refreshItem(res.body)

            // close modal
            if (dataProofItemEdit) {
                setDataProofItemEdit()
            }
        }, (res) => {
            ac.showSpinner(false)
        })
    }
    
    const handleBulkEditProofs = async () => {
        let body = { proofs: dataBulkEditProofs }

        ac.showSpinner(true)
        let [res, err] = await UtilsHelper.executePromise(
            FetchHelper({ url: `/api/v2/admin/markets/${entityData.id}/proofs`, method: 'PUT', body })
        )
        ac.showSpinner(false)

        if (err) return
        if (res?.status === 422) {
            setErrorsBulkEditProofs(res.body)
            return;
        }

        if (res?.body) {
            fetchData() // refresh data
            setMode(null)
            setErrorsBulkEditProofs(null)
        }
    }

    const handleEditProofDetails = async (proofData, updatedData) => {
        let body = updatedData

        ac.showSpinner(true)
        let [res, err] = await UtilsHelper.executePromise(
            FetchHelper({ url: proofData.url, method: 'PUT', body })
        )
        ac.showSpinner(false)

        if (err) return
        if (res?.status === 422) {
            setErrorsEditProofDetails(res.body)
            return;
        }

        if (res?.body) {
            refreshItem(res.body, 'proof')
            setDataProofDetailsEdit(null)
            setFormDataEditProofDetails(null)
        }
    }

    const handleEditProofImage = (field) => {

    }

    const handleAddImage = (field) => {
        setDataProofCreateImageModal({
            proof: field
        })
    }

    const handleEditId = (field) => {

    }

    const toggleProofImageKind = async (dataProofImageItem) => {
        ac.showSpinner(true)
        let [res] = await UtilsHelper.executePromise(
            FetchHelper({
                url: dataProofImageItem.url,
                method: 'PUT',
                body: {
                    kind: dataProofImageItem.kind === 'group' ? 'individual' : 'group'
                }
            })
        )
        ac.showSpinner(false)

        if (res) refreshItem(res.body)
    }

    const handleProofImageDownload = (field) => {
        UtilsHelper.downloadImage(field.download_url, field.filename)
    }

    const handleModalPropValueChange = (value, prop) => {
        ac.showSpinner(true)

        setErrorsModal()

        FetchHelper({
            url: dataProofItemEdit.url,
            method: 'put',
            body: {
                [prop]: value
            }
        }, (res) => {
            ac.showSpinner(false)
            
            refreshItem(res.body)
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrorsModal({
                    id: dataProofItemEdit.id,
                    message: `${prop}: ${lodash.join(res.body[prop], ', ')}`,
                    fieldKey: prop
                })
            }
        })
    }

    const getProofsRowCountWithImages = (dataProofItem) => {
        let count = 0
        
        dataProofItem.images?.data?.forEach((item, index) => {
            if (item.download_url && !item.deleted_at) {
                count++
            }
        })

        return count
    }

    const getProofsRowCountNotDeleted = (dataProofItem) => {
        let count = 0
        
        dataProofItem.images?.data?.forEach((item, index) => {
            if (!item.deleted_at) {
                count++
            }
        })

        return count
    }

    const getProofsRowCountString = (dataProofItem) => {
        let total = 0

        dataProofItem.images.data.forEach((item, index) => {
            if (!item.deleted_at && !item.deleted) {
                total++
            }
        })

        return `${getProofsRowCountWithImages(dataProofItem)} / ${total}`
    }

    const getProofsRowCountTotalString = (dataProofItem) => {
        return dataProofItem.images.data.length
    }

    const getProofsRowCountClass = (dataProofItem) => {
        let countWithImages = getProofsRowCountWithImages(dataProofItem)
        let countNotDeleted = getProofsRowCountNotDeleted(dataProofItem)

        if (countWithImages === countNotDeleted && countWithImages !== 0) {
            return 'complete'
        } else if (countWithImages > 0 && countWithImages < countNotDeleted) {
            return 'incomplete'
        } else if (countWithImages === 0) {
            return 'no-images'
        }
    }

    const handleUploadSuccess = (file, response) => {
        let newState = {...dataProofs}

        if (newState.data?.length) {
            newState.data = newState.data.map((proofItem, proofIndex) => {
                if (proofItem.images?.data?.length) {
                    for (let i=0; i < proofItem.images.data.length; i++) {
                        let imageItem = proofItem.images.data[i]
    
                        if (imageItem.id === response.body.id) {
                            proofItem.images.data[i] = response.body
                        }
                    }
                }
    
                return proofItem
            })
        }

        setDataProofs(newState)
    }

    const renderProofsContainer = ({ marketID, entityData, confirmMessage, showAddButton=true, deleteIcon='bi-trash-fill' }) => {
        if (!entityData) {
            return null
        }

        return (
            <div className="proofs-container">
                {dataProofDelete ? (
                    <ConfirmComponent apiUrl={dataProofDelete.url} method='DELETE' title={confirmMessage} entityName={dataProofDelete.code} cancel={() => setDataProofDelete()} reload={false} confirm={() => {
                        setDataProofDelete()

                        if (tab === 'Proofs') {
                            fetchProofs()
                        } else {
                            fetchProofsArchived()
                        }
                    }} />
                ) : null}

                {showDeleteAllProofsModal ? (
                    <ConfirmComponent apiUrl={`/api/v2/admin/markets/${marketID}/proofs`} method='DELETE' title={'Are you sure you want to delete all proofs?'} cancel={() => setShowDeleteAllProofsModal(false)} reload={false} confirm={() => {
                        setShowDeleteAllProofsModal(false)

                        if (tab !== 'Proofs') return
                        setDataProofSearchTerm('') // reset search term
                        fetchData();
                    }} />
                ) : null}

                {entityData?.data?.length ? (
                    entityData?.data.map((dataProofItem, dataProofIndex) => {
                        return (
                            <AccordionComponent key={`proofs-list-row-${dataProofItem.id}`} open={dataShowImages}>
                                <div className={`proofs-title ${getProofsRowCountClass(dataProofItem)}`}>
                                    <span className="title">{dataProofItem.code} {getProofsRowCountWithImages(dataProofItem) > 0 ? <span className="proof-link-copy-button"><CopyButtonComponent value={dataProofItem.direct_url} showInput={false} icon="bi-link-45deg" /></span> : null}</span>
                                    <span className="count-total">
                                        <i className="bi bi-file-earmark"></i> {getProofsRowCountTotalString(dataProofItem)}
                                    </span>
                                    <span className="count">
                                        <i className="bi bi-image"></i> {getProofsRowCountString(dataProofItem)}
                                    </span>
                                    <span className="delete">
                                        <i className="bi bi-trash-fill" onClick={(e) => {
                                            e.stopPropagation()
                                            setDataProofDelete(dataProofItem)
                                        }}></i>
                                    </span>
                                </div>

                                <div className="proofs-proof">
                                    {dataProofItem.images?.data?.length ? (
                                        <>
                                            <div className='proofs-details'>
                                                <h2 className='text-base font-bold text-center'>Details</h2>
                                                <div className='details-grid'>
                                                    <p className='font-bold'>Email</p>
                                                    <p>{dataProofItem.email || 'n/a'}</p>
                                                    <p className='font-bold'>Email opt in</p>
                                                    <p>{dataProofItem.email_opt_in === true ? 'Yes' : 'No'}</p>
                                                    <p className='font-bold'>Phone number</p>
                                                    <p>{dataProofItem.phone_number || 'n/a'}</p>
                                                    <p className='font-bold'>SMS opt in</p>
                                                    <p>{dataProofItem.sms_opt_in === true ? 'Yes' : 'No'}</p>
                                                    <p className='font-bold'>First name</p>
                                                    <p>{dataProofItem.first_name || 'n/a'}</p>
                                                    <p className='font-bold'>Last name</p>
                                                    <p>{dataProofItem.last_name || 'n/a'}</p>
                                                    <p className='font-bold'>Teacher</p>
                                                    <p>{dataProofItem.teacher || 'n/a'}</p>
                                                    <p className='font-bold'>Grade</p>
                                                    <p>{dataProofItem.grade || 'n/a'}</p>
                                                    <p className='font-bold'>Group number</p>
                                                    <p>{dataProofItem.group_number || 'n/a'}</p>
                                                    <p className='font-bold'>Default Background</p>
                                                    <p>{dataProofItem.background?.code || 'n/a'}</p>
                                                    { dataProofItem.interested_parties && (
                                                        <>
                                                            <p className='font-bold'>Interested Parties</p>
                                                            <p>{dataProofItem.interested_parties}</p>
                                                        </>
                                                    )}
                                                </div>
                                                <div className='text-center mt-2 controls'>
                                                    <button className={isLoading ? 'disabled' : ''} onClick={() => {
                                                        let proofDetails = lodash.cloneDeep(dataProofItem)
                                                        proofDetails.background_id = proofDetails?.background?.id
                                                        delete proofDetails.images
                                                        setDataProofDetailsEdit(proofDetails)
                                                    }}><i className="bi bi-pencil-fill"></i></button>
                                                </div>
                                            </div>

                                            <div className="proofs-images">
                                                {showAddButton ? <div className="proofs-images-add" onClick={() => handleAddImage(dataProofItem)}><div className="proofs-images-add-inner"></div></div> : null}

                                                {dataProofItem.images.data.map((dataProofImageItem, dataProofImageIndex) => {
                                                    return (
                                                        dataProofImageItem.deleted_at ? (
                                                            <div className="proofs-image deleted" key={`proofs-list-row-image-${dataProofItem.code}-${dataProofImageIndex}`}>
                                                                {dataProofImageItem.thumbnail_url ? (
                                                                    <PopoverComponent>
                                                                        <div className="image" style={{backgroundImage: `url(${dataProofImageItem.thumbnail_url}`, opacity: 0.5}}></div>
                                                                        <div className="image-large" style={{backgroundImage: `url(${dataProofImageItem.preview_url}`}}></div>
                                                                    </PopoverComponent>
                                                                ) : (
                                                                    <div className="missing-image"></div>
                                                                )}

                                                                <div className="image-options">
                                                                    <div className="filename">
                                                                        {dataProofImageItem.filename}
                                                                    </div>

                                                                    <div className={`controls ${isLoading ? 'disabled' : ''}`}>
                                                                        <button className="delete" onClick={() => handleDeleteProofImage(dataProofImageItem)}><i className="bi bi-arrow-clockwise"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className={`proofs-image ${!dataProofImageItem.thumbnail_url ? 'deleted' : ''}`} key={`proofs-list-row-image-${dataProofItem.code}-${dataProofImageIndex}`}>
                                                                {dataProofImageItem.thumbnail_url ? (
                                                                    <PopoverComponent>
                                                                        <div className="image" style={{backgroundImage: `url(${dataProofImageItem.thumbnail_url}`}}></div>
                                                                        <div className="image-large" style={{backgroundImage: `url(${dataProofImageItem.preview_url}`}}></div>
                                                                    </PopoverComponent>
                                                                ) : (
                                                                    <div className="missing-image"></div>
                                                                )}

                                                                <div className="image-options">
                                                                    <div className="filename">
                                                                        <p>{dataProofImageItem.filename}</p>
                                                                        <p className='mt-1'>Roll: {dataProofImageItem.roll}</p>
                                                                    </div>

                                                                    <div className="controls">
                                                                        {dataProofImageItem.download_url ? <button className="upload" onClick={() => handleProofImageDownload(dataProofImageItem)}><i className="bi bi-box-arrow-down"></i></button> : null}
                                                                        <button className={isLoading ? 'disabled' : ''} onClick={() => setDataProofItemEdit(dataProofImageItem)}><i className="bi bi-pencil-fill"></i></button>
                                                                        <button className={`delete ${isLoading ? 'disabled' : ''}`} onClick={() => handleDeleteProofImage(dataProofImageItem)}><i className={'bi bi-trash-fill'}></i></button>
                                                                    </div>

                                                                    <div className='text-center mt-2'>
                                                                        <p className='text-base cursor-pointer' onClick={() => toggleProofImageKind(dataProofImageItem)}>
                                                                            <span><i className={`bi ${dataProofImageItem?.kind === 'group' ? 'bi-people-fill' : 'bi-person-fill'} mr-1`}></i></span>
                                                                            {dataProofImageItem?.kind === 'group' ? 'GROUP' : 'INDIVIDUAL'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        
                                                    )  
                                                })}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </AccordionComponent>
                        )
                    })
                ) : <span>No proofs found...</span>}
            </div>
        )
    }

    const renderProofsOptions = (type='Proofs') => {
        return (
            <div className="proofs-options">
                {mode === 'BulkEdit' && (
                    <ModalComponent handleClose={() => {setMode(null)}}
                        className={'proofs-bulk-edit'}>
                        <div>
                            <h2 className='text-base font-bold mb-4'>Bulk Edit Proofs</h2>
                            <FormComponent
                                formData={dataBulkEditProofs}
                                onChange={setDataBulkEditProofs}
                                formErrors={errorsBulkEditProofs}
                                setFormErrors={setErrorsBulkEditProofs}
                                fields={[
                                    {
                                        type: 'select-paginated',
                                        name: 'background_id',
                                        label: 'Default background',
                                        apiUrl: '/api/v2/admin/backgrounds'
                                    }
                                ]}
                                displayErrorBanner={true}
                                />

                            <button className='mt-4 w-full' onClick={() => {handleBulkEditProofs()}}>Save</button>
                        </div>
                    </ModalComponent>
                )}

                <div className="options-primary">
                    <div className="quick-search">
                        <QuickSearchComponent term={dataProofSearchTerm} setTerm={setDataProofSearchTerm} onChange={(value) => setDataProofSearchTerm(value)}/>
                    </div>
                    <div className="pagination">
                        {type === 'Proofs'
                            ? dataProofs ? <PaginationComponent currentPage={pageProofs} totalPages={dataProofs?.total_pages || 0} setPage={setPageProofs} /> : null
                            : dataProofsArchived ? <PaginationComponent currentPage={pageArchived} totalPages={dataProofsArchived?.total_pages || 0} setPage={setPageArchived} /> : null}
                    </div>
                </div>

                <div className="options-secondary">
                    <div className="show-images">
                        <i className="bi bi-card-image"></i> <SwitchComponent value={dataShowImages} onChange={(value) => setDataShowImages(value)} />
                    </div>
                    {tab === 'Proofs' && dataProofs?.data?.length > 0 &&
                        <div>
                            <button className="delete mr-6" onClick={() => {setShowDeleteAllProofsModal(true)}}>
                                <i className="bi bi-trash-fill"></i>
                            </button>

                            <button title='Bulk Edit' onClick={() => { setMode('BulkEdit') }}>
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                        </div> }
                    <div className="refresh">
                        <button onClick={() => fetchData()}><i className="bi bi-arrow-clockwise"></i></button>
                    </div>
                </div>
            </div>
        )
    }

    const filterData = (data) => {
        let filteredData = {...data}
        
        if (filteredData.data) {
            filteredData.data = filteredData.data.map((group) => {
                group.images.data.sort((_a, _b) => {
                    if (_a.download_url && !_a.deleted_at) {
                        return -1
                    } else {
                        return 1
                    }
                })
        
                return group
            })
        }

        return filteredData
    }

    const refreshItem = (data, type = 'image') => {
        let newState = {}

        if (tab === 'Proofs') {
            newState = {...dataProofs}
        } else {
            newState = {...dataProofsArchived}
        }

        // @TODO: get model from api, not previous state
        newState.data = newState.data.map((item, index) => {
            if (type === 'image')
                item.images.data = item.images.data.map((imageItem, imageIndex) => {
                    if (imageItem.id === data.id) {
                        return data
                    } else {
                        return imageItem
                    }
                })
            else if (type === 'proof')
                return item.id === data.id ? data : item

            return item
        })

        if (tab === 'Proofs') {
            setDataProofs(newState)
        } else {
            setDataProofsArchived(newState)
        }
    }

    const appendItem = (data, proofId='40126148') => {
        let newState = {}

        if (tab === 'Proofs') {
            newState = {...dataProofs}
        } else {
            newState = {...dataProofsArchived}
        }

        // @TODO: get model from api, not previous state
        newState.data = newState.data.map((item, index) => {
            if (item.id === proofId) {
                item.images.data.unshift(data)
            }

            return item
        })

        if (tab === 'Proofs') {
            setDataProofs(newState)
        } else {
            setDataProofsArchived(newState)
        }
    }

    const downloadBGList = async () => {
        ac.showSpinner(true)
        
        let backgroundsListData = await getFullListData(`/api/v2/admin/backgrounds`, true)
        
        // --- prepare csv data to download
        let columns = [
            { title: 'Internal ID', key: 'id' },
            { title: 'Code', key: 'code' },
            { title: 'Name', key: 'name' }
        ]
        let dataArray = UtilsHelper.tableDataToCSVCompatibleArray(
            { columns, data: backgroundsListData?.data },
            true
        )
        let csvData = UtilsHelper.arrayToCsv(dataArray)
        UtilsHelper.exportToCsv(csvData, 'backgrounds-list')
        ac.showSpinner(false)
    }

    const dataPopulated = dataProofs?.data || dataProofsArchived?.data

    return (
        <div className={`proofing-partial-container`}>
            <div className="proofing-message">
                Linking to multiple codes can be accomplished by using a comma separated list of codes in the url as follows:<br/><br/>
                Single Code: <code>{entityData.sale_url}?proof_code=&lt;code&gt;</code><br/>
                Multiple Codes: <code>{entityData.sale_url}?proof_code=&lt;code&gt;,&lt;code&gt;,&lt;code&gt;</code>
            </div>

            <div className="proofing-partial-inner">
                {dataProofing && 
                    <div className='proofing-controls'>
                        <FormComponent formData={dataProofing} onChange={setDataProofing} displayErrorBanner={true}
                            formErrors={marketErrors} setFormErrors={setMarketErrors} 
                            fields={proofingFormFields}
                            />

                        <button onClick={handleSaveData}>Save</button>
                    </div>
                }

                <div className="proofing-controls">
                    <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                        {
                            type: 'input-file',
                            fileType: 'csv',
                            name: 'proof_schema',
                            label: 'Proof Schema',
                            acceptedFileTypes: FileTypes.CSV,
                            label_info: 'CSV file must have columns named "Code" or "WebPassword" for the proof code and "Filename" or "New Image File Name" for the file.',
                            inputDescription: (
                                <>
                                    <div className='flex mt-2'>
                                        <a className='link' href={SampleSchema} download='proof-schema-format.csv'>Download Sample File</a>
                                        <span className='mx-2'>|</span>
                                        <a className='link' onClick={downloadBGList}>Download Backgrounds List</a>
                                    </div>
                                </>
                            )
                        },
                        {
                            type: 'radio',
                            label: 'Upload Action',
                            name: 'proof_schema_action',
                            value: 'replace',
                            options: [
                                {
                                    value: 'replace',
                                    label: 'Replace schema?',
                                },
                                {
                                    value: 'append',
                                    label: 'Append/merge',
                                }
                            ]
                        },
                        {
                            type: 'switch',
                            label: 'Requires proof to place order',
                            name: 'requires_proof_to_place_order'
                        }
                    ]} />

                    <button onClick={handleSaveSchema}>Save Schema</button>
                </div>

                {dataProofs?.attached ? (
                    <div className="proofing-uploader">
                        {dataProofUploadCompleteCount ? (
                            <ModalComponent handleClose={() => {
                                    setDataProofUploadCompleteCount()
                                }}>
                                <div className="proofing-upload-complete global-modal">
                                    <div className="title">
                                        Upload Complete
                                    </div>

                                    <div className="image-drop">
                                        Successfully uploaded <span className="count">{dataProofUploadCompleteCount}</span> {dataProofUploadCompleteCount === 1 ? 'image' : 'images'}.
                                    </div>
                                </div>
                            </ModalComponent>
                        ) : null}

                        
                        <div className="uploader-container">
                            <AdvancedFileUploaderComponent endpoint={`/api/v2/admin/markets/${entityData.id}/proofs/upload`} onUpload={() => {
                                setIsLoading(true)
                            }} onUploadSuccess={(result, response) => {
                                handleUploadSuccess(result, response)
                            }} onUploadError={(result, error) => {
                                setErrorsUpload([...errorsUpload, `${result.name} - ${error}`])
                            }} onComplete={(result) => {
                                const resultSuccessfulCount = result.successful.length
                                
                                if (resultSuccessfulCount > 0) {
                                    setDataProofUploadCompleteCount(resultSuccessfulCount)
                                }

                                setIsLoading(false)
                            }} />

                            {errorsUpload.length ? (
                                <div className="errors-container">
                                    <div className="options">
                                        <button className="delete" onClick={() => setErrorsUpload([])}>Clear Errors</button>
                                    </div>
                                    <ul className="errors">
                                        {errorsUpload.map((item, index) => {
                                            return (
                                                <li key={`proofing-uploader-error-${item}`}>{item}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                        
                        <div className="proofing-proofs-section">
                            {dataProofCreateImageModal ? (
                                <ModalComponent handleClose={() => {
                                    resetProofCreateImageModalData()
                                }}>
                                    <div className="proofing-uploader-modal uploader-create global-modal">
                                        <div className="title">
                                            Drag and drop new image for proof: <strong>{dataProofCreateImageModal.proof.code}</strong>
                                        </div>

                                        <div className="image-drop">
                                            <ImageDragAndDropComponent method="post" apiUrl={dataProofCreateImageModal.proof.images.url} onComplete={(data) => {
                                                setDataProofCreateImageModal()
                                                appendItem(data, dataProofCreateImageModal.proof.id)
                                            }}/>
                                        </div>
                                        
                                        <div className="options"></div>
                                    </div>
                                </ModalComponent>
                            ) : null}

                            {dataProofItemEdit ? (
                                <ModalComponent handleClose={() => {
                                    setDataProofItemEdit(null)
                                    setErrorsModal()
                                }}>
                                    <div className="proofing-uploader-modal uploader-edit global-modal">
                                        <div className="title">
                                            Drag and drop new image
                                        </div>

                                        <div className="image">
                                            <ImageDragAndDropComponent method="put" image={dataProofItemEdit.preview_url} apiUrl={dataProofItemEdit.url} onComplete={(data) => {
                                                refreshItem(data)
                                                setDataProofItemEdit(data)
                                                // fetchData()
                                            }}/>
                                        </div>

                                        <div className={`filename-row`}>
                                            <label>File name</label>
                                            <input 
                                                className={errorsModal?.id === dataProofItemEdit.id && errorsModal?.fieldKey === 'filename' ? 'bg-red-200' : ''}
                                                type="text" value={dataProofItemEdit.filename} onChange={(e) => {
                                                setDataProofItemEdit({...dataProofItemEdit, filename: e.target.value})

                                                clearTimeout(timerProofItemEdit)
                                                
                                                setTimerProofItemEdit(setTimeout(() => {
                                                    handleModalPropValueChange(e.target.value, 'filename')
                                                }, 250))
                                            }} />
                                        </div>

                                        <div className={`filename-row `}>
                                            <label>Roll</label>
                                            <input className={errorsModal?.id === dataProofItemEdit.id && errorsModal?.fieldKey === 'roll' ? 'bg-red-200' : ''}
                                                type="text" value={dataProofItemEdit.roll} onChange={(e) => {
                                                setDataProofItemEdit({...dataProofItemEdit, roll: e.target.value})

                                                clearTimeout(timerProofItemEdit)
                                                
                                                setTimerProofItemEdit(setTimeout(() => {
                                                    handleModalPropValueChange(e.target.value, 'roll')
                                                }, 250))
                                            }} />
                                        </div>

                                        {errorsModal?.id === dataProofItemEdit.id ? (
                                            <div className="proofing-filename-error">{errorsModal.message}</div>
                                        ) : null}

                                        <div className="options">
                                            <button className="delete" onClick={() => handleDeleteProofImage(dataProofItemEdit)}><i className="bi bi-trash"></i> Delete Image</button>
                                            <button onClick={() => handleProofImageDownload(dataProofItemEdit)}><i className="bi bi-box-arrow-down"></i> Download Image</button>
                                        </div>
                                    </div>
                                </ModalComponent>
                            ) : null}

                            {dataProofDetailsEdit &&
                                <ModalComponent handleClose={() => {
                                    setDataProofDetailsEdit(null)
                                    setFormDataEditProofDetails(null)
                                }} className={'proof-details-edit'}>
                                    <div className='edit-proof-details-container'>
                                        <h2 className='text-base font-bold mb-4'>Edit Proof Details ({dataProofDetailsEdit.code})</h2>
                                        <FormComponent
                                            formData={dataProofDetailsEdit}
                                            onChange={setFormDataEditProofDetails}
                                            formErrors={errorsEditProofDetails}
                                            setFormErrors={setErrorsEditProofDetails}
                                            fields={proofDetailsEditFormFields}
                                            displayErrorBanner={true}
                                            />

                                        <button className='mt-4 w-full' 
                                            onClick={() => handleEditProofDetails(dataProofDetailsEdit, formDataEditProofDetails)}>Save</button>
                                    </div>
                                </ModalComponent>}

                            {dataPopulated && <TabsComponent tab={tab} tabs={tabs} setTab={setTab} />}

                            {dataPopulated && renderProofsOptions(tab)}

                            {tab === 'Archived' ? (
                                dataProofsArchived ? renderProofsContainer({ marketID: entityData.id, entityData: filterData(dataProofsArchived), confirmMessage: 'Unarchive the following proof?', showAddButton: false }) : null
                            ) : null}

                            {tab === 'Proofs' ? (
                                dataProofs ? renderProofsContainer({ marketID: entityData.id, entityData: filterData(dataProofs), confirmMessage: 'Archive the following proof?' }) : null
                            ) : null}

                            {dataPopulated && renderProofsOptions(tab)}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default ProofingPartial