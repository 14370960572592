import DropdownTextCollection from "../DropdownTextCollection";
import "./index.css";

const EmailTags = ({ onSelect = () => {} }) => {
  let emailSubstitutionOptions = [
    {
      type: "group",
      title: "Customer Service",
      list: [
        { title: "Email", value: "%{customer_service_email}" },
        { title: "Phone Number", value: "%{customer_service_phone_number}" },
      ],
    },
    {
      type: "group",
      title: "Studio",
      list: [{ title: "Logo", value: "%{studio_logo}" }],
    },
    {
      type: "group",
      title: "Market",
      list: [
        { value: "%{market_name}", title: "Name" },
        { value: "%{market_url}", title: "Short URL" },
        { value: "%{market_street_address}", title: "Street Address" },
        { value: "%{market_city}", title: "City" },
        { value: "%{market_state_province}", title: "State/Province" },
        { value: "%{market_postal_code}", title: "Postal Code" },
        { value: "%{market_logo}", title: "Logo" },
        { value: "%{market_phone_number}", title: "Ph #" },
        { value: "%{market_website}", title: "Website" },
        {
          value: "%{market_special_instructions}",
          title: "Special Instructions",
        },
        { value: "%{market_important_dates}", title: "Important Dates" },
        { value: "%{market_coupons}", title: "Emailable Promotional Coupons" },
      ],
    },
    {
      type: "group",
      title: "Scheduling",
      list: [
        { value: "%{scheduling_policy}", title: "Policy" },
        { value: "%{scheduling_extra_text}", title: "Extra Text" },
      ],
    },
    {
      type: "group",
      title: "Customer",
      list: [
        { value: "%{customer_first_name}", title: "First Name" },
        { value: "%{customer_last_name}", title: "Last Name" },
      ],
    },
    {
      type: "group",
      title: "Order",
      list: [
        { value: "%{confirmation_number}", title: "Confirmation Number" },
        { value: "%{confirmation_url}", title: "Confirmation URL" },
        { value: "%{packages_ordered}", title: "Packages Ordered" },
        { value: "%{addons_ordered}", title: "Addons Ordered" },
        { value: "%{poses_selected}", title: "Poses Selected" },
        { value: "%{sheets_selected}", title: "Sheets Selected" },
        { value: "%{backgrounds_selected}", title: "Backgrounds Selected" },
        { value: "%{donation_charity_name}", title: "Charity Name" },
        { value: "%{donation_amount}", title: "Donation Amount" },
        { value: "%{custom_fields}", title: "Custom Fields" },
      ],
    },
    {
      type: "group",
      title: "Barcode",
      list: [
        { value: "%{barcode}", title: "Barcode" },
        { value: "%{barcode_text}", title: "Barcode Text" },
        { value: "%{email_barcode}", title: "Email Barcode" },
        { value: "%{qr_barcode}", title: "Data QR Code (if configured)" },
      ],
    },
    {
      type: "group",
      title: "Reservation",
      list: [
        { value: "%{reservation_date}", title: "Date" },
        { value: "%{reservation_slot_name}", title: "Slot Name" },
        { value: "%{reservation_eta}", title: "ETA (Hours)" },
        { value: "%{reservation_eta_days}", title: "ETA (Days)" },
        { value: "%{reschedule_url}", title: "Reschedule URL" },
      ],
    },
    {
      type: "group",
      title: "Marketing Instructions",
      list: [
        {
          value: "%{marketing_instructions}",
          title: "Instructions from this Plan",
        },
      ],
    },
    {
      type: "group",
      title: "Portable North Pole (if configured)",
      list: [
        { value: "%{pnp_promo_code}", title: "Promo Code" },
        { value: "%{pnp_promo_code_url}", title: "Promo Code URL" },
        { value: "%{pnp_marketing_image_url}", title: "Marketing Image URL" },
      ],
    },
  ];

  return (
    <div className="email-tags-container">
        <DropdownTextCollection
            options={emailSubstitutionOptions} onSelect={onSelect} />
    </div>
  )
};

export default EmailTags;
