import PropTypes from 'prop-types'

import './index.css'

const ParagraphComponent = ({ type, children, config={} }) => {
    return (
        <p className={`ui-container--paragraph ${config.classNames || ''}`}>
            {children}
        </p>
    )
}

ParagraphComponent.propTypes = {
    config: PropTypes.shape({
        classNames: PropTypes.string,
    }),
}

export { ParagraphComponent }