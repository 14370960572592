import { useEffect, useRef } from 'react'

import './Modal.css'

const Modal = ({ children, handleClose, disableClose, onMount, className }) => {
  const modalRef = useRef(null)

  useEffect(() => {
    if (modalRef.current && onMount) {
      onMount(modalRef)
    }
  }, [modalRef])

  return (
    <div className={`modal-container ${className}`}>
      <div className="modal-display">
        {!disableClose ? <div className="modal-close" onClick={handleClose}><i className="bi bi-x"></i></div> : null}

        <div className="modal-display-contents scroll-invisible" ref={modalRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
