import { useState, useEffect, useCallback } from 'react'

import ControlBar from '../../components/ControlBar'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'
import RouteHelper from '../../helpers/route'
import SelectPaginated from '../../components/SelectPaginated'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useFetch from '../../hooks/useFetch'
import useNotices from '../../hooks/useNotices'
import FieldSelectorComponent from '../../components/FieldSelector'

import './edit.css'
import { useTabs } from '../../hooks'
import { getGeneralFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import { useParams } from 'react-router-dom'

const AppointmentProfilesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const { FetchHelper } = useFetch()

    const [NoticeComponent, notices] = useNotices('Scheduling/AppointmentProfiles/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain] = useState()
    const [dataAppointmentSlots, setDataAppointmentSlots] = useState([])
    const [dataReverseAdjustmentDirection, setDataReverseAdjustmentDirection] = useState(false)
    const [dataTimeAdjustment, setDataTimeAdjustment] = useState(0)
    const [doesPageLiveUpdates, setDoesPageLiveUpdates] = useState(false)

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('General', ['General', 'Appointment Slots'])
    
    const setDataTimeAdjustmentCallback = useCallback((value) => {
        setDataTimeAdjustment(value)
    }, [])

    const setDataReverseAdjustmentDirectionCallback = useCallback((value) => {
        setDataReverseAdjustmentDirection(value)
    }, [])

    const [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFields(dataMain, dataTimeAdjustment, setDataTimeAdjustmentCallback, dataReverseAdjustmentDirection, setDataReverseAdjustmentDirectionCallback))

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getGeneralFormFields(dataMain, dataTimeAdjustment, setDataTimeAdjustmentCallback, dataReverseAdjustmentDirection, setDataReverseAdjustmentDirectionCallback);
        setGeneralFormFields(fields)
    }, [dataMain, dataTimeAdjustment, setDataTimeAdjustmentCallback, dataReverseAdjustmentDirection, setDataReverseAdjustmentDirectionCallback])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'General',
                forms: [{ id: 'Main', fields: generalFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        switch(tab) {
            case 'Appointment Slots':
                setDoesPageLiveUpdates(true)
                break;

            default:
                setDoesPageLiveUpdates(false)
        }
    }, [tab])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = { appointment_profile: { ...dataMain } }

        if (dataMain.hide_slot_for_current_time) {
            body.appointment_profile.time_adjustment = dataTimeAdjustment
            body.appointment_profile.reverse_adjustment_direction = dataReverseAdjustmentDirection
        }

        FetchHelper({
            url: `/api/v2/admin/appointment-profiles/${params.id}`,
            method: 'PUT',
            body: body
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/appointment-profiles')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(generalFormFields, res.body)
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/appointment-profiles/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
            setDataMain(res.body)
            setDataAppointmentSlots(res.body.appointment_slots.data)
            setDataTimeAdjustment(res.body.time_adjustment)
            setDataReverseAdjustmentDirection(res.body.reverse_adjustment_direction)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    return (
        <div className="appointment-profiles-edit-page-container page-container">
            <PageHeaderComponent title="Edit Appointment Profile" />

            {data ? (
                <>
                    <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
                    {tab === 'General' && (
                        <>
                            <NoticeComponent location="Tab/General" notices={notices} />
                            
                            <FormComponent formData={dataMain} formErrors={errors} setFormErrors={setErrors} onChange={setDataMain} displayErrorBanner={true} fields={generalFormFields} />
                        </>
                    )}
        
                    { tab === 'Appointment Slots' && (
                        <>
                            <div className='appointment-slots-container'>
                                <FieldSelectorComponent sortable={false} apiPrefix="appointment_slot" buttonTitle="Slot" 
                                    sync={true} syncURL={data.appointment_slots.url}
                                    data={dataAppointmentSlots} apiURL="/api/v2/admin/appointment-slots" option={{ showEditBtn: false }} 
                                    onChange={setDataAppointmentSlots} />
                            </div>
                        </>
                    )}

                    <ControlBar baseApiUrl="/appointment-profiles" redirectUrl="/appointment-profiles" cancelUrl="/appointment-profiles" data={data} handleSubmit={handleSubmit}
                        doesPageLiveUpdates={doesPageLiveUpdates} />
                </>
            ) : null}
        </div>
    )
}

export default AppointmentProfilesEdit
