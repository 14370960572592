import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import { useParams } from 'react-router-dom'

const BillingProfilesEdit = () => {
    const params = useParams()
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Administration/BillingProfiles/Edit')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/billing-profiles/${params.id}`
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    const handleSubmit = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/billing-profiles/${params.id}`,
            method: 'PUT',
            body: {
                billing_profile: data
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/billing-profiles')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                setErrors(res.body)
                UtilsHelper.scrollTop()
            }
        })
    }

    return (
        <div>
            <PageHeaderComponent title="Edit Billing Profile" />

            <NoticeComponent location="Edit" notices={notices} />

            {data ? (
                <>
                    <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                        {
                            type: 'input-text',
                            name: 'name',
                            label: 'Name'
                        }, {
                            type: 'input-percent',
                            name: 'base_rate',
                            label: 'Base Rate',
                            step: 0.05
                        }, {
                            type: 'input-money',
                            name: 'per_transaction_rate',
                            label: 'Per Transaction Rate',
                            step: 0.05
                        }, {
                            type: 'input-percent',
                            name: 'convenience_fee_rate',
                            label: 'Convenience Fee Rate',
                            step: 0.05
                        }
                    ]} />

                    <ControlBar baseApiUrl="/billing-profiles" redirectUrl="/billing-profiles" cancelUrl="/billing-profiles" data={data} handleSubmit={handleSubmit} />
                </>
            ) : null}
        </div>
    )
}

export default BillingProfilesEdit