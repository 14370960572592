import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import FetchHelper from '../../helpers/fetch'
import SelectPaginatedComponent from '../SelectPaginated'
import SwitchComponent from '../Switch'
import TooltipComponent from '../Tooltip'

import useAppContext from '../../hooks/useAppContext'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import './index.css'
import { Utils } from '../../helpers'
import lodash from 'lodash'

export default ({ data=[], sortable=true, onChange, apiURL, sync=false, syncURL='', apiPrefix='', buttonTitle='Field', editUrl='', options = { showEditBtn: true }, extraOptions={} }) => {
    const [ac] = useAppContext()

    const [_data, setData] = useState([])
    const [errors, setErrors] = useState({})

    const { height, width } = useWindowDimensions();

    let draggedElementIndex = null

    useEffect(() => {
        let newData = data.map((item, index) => {
            if (item.title) {
                return item
            } else {
                return {
                    title: item.name,
                    value: item.id,
                    include_bundled: item.include_bundled,
                    extraProps: item.extraProps,
                    item
                }
            }
        })

        setData(newData)
        onChange(newData)
    }, [])

    const handleDrop = (e, item, index) => {
        setErrors({})

        e.preventDefault()

        if (!sortable) {
            return
        }

        let target = 0

        if (!e.target.getAttribute('data-drag-index')) {
            target = parseInt(e.target.closest('li').getAttribute('data-drag-index'))
        } else {
            target = parseInt(e.target.getAttribute('data-drag-index'))
        }

        if (draggedElementIndex === target || _data[draggedElementIndex].value === null || item.value === null) {
            return
        }

        if (sync) {
            ac.showSpinner(true)

            let body = {
                position: target
            }

            FetchHelper({
                url: _data[draggedElementIndex].item.url,
                // relativePath: false,
                method: 'PUT',
                body: body
            }, (res) => {
                ac.showSpinner(false)
            }, (res) => {
                ac.showSpinner(false)
            })
        }

        let newFields = [..._data]
        let element = newFields[draggedElementIndex]

        newFields.splice(draggedElementIndex, 1)
        newFields.splice(target, 0, element)

        setData(newFields)
        onChange(newFields)
    }

    const handleDelete = (selectedOption, index) => {
        setErrors({})

        if (sync && selectedOption.value !== null) {
            ac.showSpinner(true)

            FetchHelper({
                url: selectedOption.item.url,
                // relativePath: false,
                method: 'DELETE'
            }, (res) => {
                ac.showSpinner(false)
            }, (res) => {
                ac.showSpinner(false)
            })
        }

        let newData = _data.filter((item, i) => index !== i)

        setData(newData)
        onChange(newData)
    }

    const handleAdd = () => {
        setErrors({})

        let newData = [..._data, {
            title: '--',
            value: null,
            item: {}
        }]

        setData(newData)
        onChange(newData)
    }

    const handleChange = async (index, prop, value, allowDups = true, isExtraProp = true, selectedItem) => {
        // --- clear error for the field
        let newErrors = { ...errors }
        if (newErrors[index]?.[prop]) {
            delete newErrors[index]?.[prop]
            setErrors(newErrors)
        }

        const getPropValue = (item) => {
            if (isExtraProp) return item.extraProps?.[prop]
            return item.item?.[prop]
        }

        const updateError = (errorMsg) => {
            let newErrors = { ...errors }
            newErrors[index] = { ...newErrors[index], [prop]: errorMsg }
            setErrors(newErrors)
        }

        // --- check for duplicates
        if (!allowDups) {
            let isDuplicate = lodash.some(
                _data,
                item => getPropValue(item) === value
            )

            if (isDuplicate) {
                updateError('has already been taken')
                return
            }
        }

        let isNewItem = !_data[index].value
        if (isExtraProp && isNewItem) {
            console.warn('In Field selector component, cannot update extras until main field value is present. Keep extra inputs disabled until main field value is present.')
            return
        }

        // --- update value on server
        let resUrl;
        if (sync) {
            let body = { 
                id: _data[index]?.item?.id,
                [prop]: value,
                position: index
            }
            if (!isExtraProp || !isNewItem) { // ensuring that the main field value is present before updating extras, meaning item is already created on server
                ac.showSpinner(true)
                let [res] = await Utils.executePromise(
                    FetchHelper({
                        url: isNewItem ? syncURL : _data[index].item.url,
                        method: isNewItem ? 'POST' : 'PUT',
                        body: body
                    })
                )
                ac.showSpinner(false)

                if (res?.status === 422 && res.body?.[prop]) {
                    updateError(res.body[prop])
                    return
                }

                resUrl = res?.body?.url
            } else {
                console.warn('In Field selector component, cannot sync extras until main field value is present.')
            }
        }

        // --- update value in state
        let updateObj = isExtraProp
            ? { extraProps: { [prop]: value } }
            : { title: selectedItem.title, value: value, item: { ...selectedItem.item, [prop]: value, url: resUrl} }
        setField(index, updateObj)
    }

    const setField = (index, field) => {
        let newState = [..._data]
        let updatedData = lodash.cloneDeep(_data[index])
        updatedData = lodash.merge(updatedData, field)
        newState[index] = updatedData

        setData(newState)
        onChange(newState)
    }

    const renderError = (index, prop) => {
        if (errors?.[index]?.[prop]) {
            return <div className="form-field-error">{errors[index][prop]}</div>
        }
    }

    const getIconByType = (item) => {
        let icon = 'bi-stars'

        switch (item.field_type) {
            case 'single_line':
                icon = 'bi-input-cursor-text'
                break;
            case 'dropdown':
                icon = 'bi-menu-button'
                break;
            case 'checkbox':
                icon = 'bi-check2-square'
                break;
            case 'date':
                icon = 'bi-calendar-day'
                break;
            case 'number':
                icon = 'bi-123'
                break;
            case 'email':
                icon = 'bi-envelope'
                break;
            case 'text':
                icon = 'bi-fonts'
            case 'children':
                icon = 'bi-person'
        }

        return (
            <>
                <TooltipComponent icon={icon} position="top">{item.field_type}</TooltipComponent> {item.required ? <TooltipComponent icon="bi-exclamation-diamond-fill" position="top">This field is required</TooltipComponent> : <i className="bi bi-exclamation-diamond-fill disabled tooltip-icon"></i>}
            </>
        )
    }

    const setIncludeBundled = (item, index, flagValue) => {
        if (sync) {
            ac.showSpinner(true)

            let body = {
                include_bundled: flagValue
            }

            FetchHelper({
                url: item.item.url,
                // relativePath: false,
                method: 'PUT',
                body: body
            }, (res) => {
                ac.showSpinner(false)
            }, (res) => {
                ac.showSpinner(false)
            })
        }

        let newFields = [..._data]
        newFields[index].include_bundled = flagValue

        setData(newFields)
        onChange(newFields)
    }

    const sortItem = (item, index, direction) => {
        let movement = direction === 'up' ? -1 : 1

        if (sync && item?.item?.url) {
            ac.showSpinner(true)

            let body = {
                position: index + movement
            }

            FetchHelper({
                url: item.item.url,
                // relativePath: false,
                method: 'PUT',
                body: body
            }, (res) => {
                ac.showSpinner(false)
            }, (res) => {
                ac.showSpinner(false)
            })
        }

        let newFields = [..._data]
        let element = newFields[index]

        newFields.splice(index, 1)
        newFields.splice(index + movement, 0, element)

        setData(newFields)
        onChange(newFields)
    }

    return (
        <div className={`selector-field-component-wrapper ${ac.isLoading ? 'disabled' : ''} ${width < 1024 ? 'mobile' : 'desktop'}`}>
            <div className="form-field-wrapper fields">
                <div className="form-label">
                    <button className="secondary" onClick={handleAdd}>Add {buttonTitle}</button>
                </div>

                <ul className="form-field drag-list" key={'field-selector-ul'}>
                    {_data.map((item, index) => {
                        let isExtrasDisabled = !(item?.item?.[apiPrefix + '_id'])
                        return (
                            <li
                                key={`form-field-fields-${(item?.value || index)}`}
                                draggable="true"
                                data-drag-index={index}
                                onDragStart={() => draggedElementIndex = index}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, item, index)}>
                                <div className={`form-field-row ${errors[index]?.[`${apiPrefix}_id`] ? 'error' : ''}`}>
                                    {apiPrefix === 'field' ? getIconByType(item.item) : null}

                                    <div className='flex-1 inputs-container'>
                                        <div className='inputs-sub-container'>
                                            <div className='field-input-container'>
                                                <SelectPaginatedComponent initialValue={item} apiUrl={apiURL} onSelect={(selectedOption) => handleChange(index, `${apiPrefix}_id`, selectedOption.value, false, false, selectedOption)} />
                                                { renderError(index, `${apiPrefix}_id`) }
                                            </div>

                                            {extraOptions.renderExtra
                                                ? extraOptions.renderExtra(item, index, isExtrasDisabled, handleChange, renderError)
                                                : null}

                                            {extraOptions.include_bundled_toggle ? (
                                                <SwitchComponent value={item.include_bundled || false} onChange={(value) => setIncludeBundled(item, index, value)} />
                                            ) : null}
                                        </div>
                                    
                                        {(sortable) && 
                                            <div className='controls-container'>
                                                {sortable ? (
                                                    <span className="field-sort">
                                                        <i onClick={() => sortItem(item, index, 'up')} className={`icon bi bi-chevron-up ${index === 0 ? 'disabled' : ''}`}></i>
                                                        <i onClick={() => sortItem(item, index, 'down')} className={`icon bi bi-chevron-down ${index === _data.length - 1 ? 'disabled' : ''}`}></i>
                                                    </span>
                                                ) : null}
                                            </div> }
                                    </div>

                                    <div className='actions-container'>
                                        {options.showEditBtn && editUrl && item.value ? (
                                            <button className="secondary"><Link to={editUrl(item.value)} target="_blank"><i className="bi bi-pencil-fill"></i></Link></button>
                                        ) : null}

                                        {options.showEditBtn && editUrl && !item.value ? (
                                            <button className="secondary disabled"><i className="bi bi-pencil-fill"></i></button>
                                        ) : null}
                                        
                                        <button className="secondary delete" onClick={() => handleDelete(item, index)}><i className="bi bi-trash3-fill"></i></button>
                                    </div>

                                </div>

                                {renderError(index)}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}