import FetchHelper from './fetch'

const APIHelper = {

      getMarketsGrouped: (setMarkets) => {
        FetchHelper({
              url: `/api/v2/admin/markets`
          }, (res) => {
              const groups = ['Santa', 'Bunny', 'Dance', 'Generic'].map((group) => {
                  return {
                      group: group,
                      options: res.body.data.filter((item) => {
                          return item.type === group
                      }).map((item) => {
                          return item = {
                              value: item.id,
                              title: item.name,
                              item: item
                          }
                      })
                  }
              })

              setMarkets(groups)
          }, (res) => {

          })
      },

      getMarkets: (callback=()=>{}) => {
        FetchHelper({
              url: `/api/v2/admin/markets`
          }, (res) => {
              const markets = ['Santa', 'Bunny', 'Dance', 'Generic'].map((group) => {
                  return {
                      group: group,
                      options: res.body.data.filter((item) => {
                          return item.type === group
                      }).map((item) => {
                          return item = {
                              value: item.id,
                              title: item.name,
                              item: item
                          }
                      })
                  }
              })

              callback({ markets })
          }, (res) => {

          })
      }

}

export default APIHelper
