import { Provider, Providers } from '../../../sys/constants/LabEnhancements';

export function getGeneralFormFields(suggestionsTags = [], isEditPage) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name',
      placeholder: 'Retouch Me, Daddy.'
    },
    {
      type: 'tags',
      name: 'tag_names',
      label: 'Tags',
      suggestions: suggestionsTags
    },
    {
      type: 'select',
      name: 'type',
      label: 'Provider',
      options: [
        { title: "", value: null },
        ...Providers
      ],
      readonly: isEditPage
    },
    {
      type: 'input-text',
      name: 'description',
      label: 'Description',
      condition: (data) => data.type === Provider.Skylab
    },
    {
      type: 'textarea',
      name: 'description',
      label: 'Description',
      condition: (data) => data.type === Provider.MarcoLab
    },
    {
      type: 'input-text',
      name: 'value',
      label: 'Value',
      placeholder: 'RETOUCHME',
      label_info: 'Lab [generated] code for enhancement',
      condition: (data) => data.type === Provider.MarcoLab
    }
  ];
}
