import { useState } from "react";
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'
import UtilsHelper from '../../helpers/utils'

import useNotices from '../../hooks/useNotices'

const PaymentAccounts = () => {
    const [NoticeComponent, notices] = useNotices('Tools/PaymentAccounts/Index')

    const [list, setList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [tags, setTags] = useState({ all: [], active: [] })
    const [cachedList, setCachedList] = useState({ data: [] })
    const [showTags, setShowTags] = useState(false)
    const baseUrl = '/payment-accounts'
    const baseApiUrl = '/api/v2/admin/payment-accounts'

    const handleSearchAfterFetch = (results) => {
        setList(results)
        setCachedList(results)
    }

    let filteredList = {
        ...cachedList,
        data: cachedList.data.map((item) => {
            item.ui_type = item.type
            item.ui_name_route = `${baseUrl}/${item.id}/edit`

            return item
        })
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="Payment Accounts" actions={[{ title: 'New Payment Account', route: '/payment-accounts/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="Tab/List" notices={notices} />
            <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                columns={[
                    {
                        key: 'name',
                        title: 'Name',
                        type: 'url_route',
                        route: 'ui_name_route',
                        hideTitleOnMobile: true
                    },
                    {
                        key: 'ui_type',
                        title: 'Type'
                    }
                ]} />
        </div>
    )
}
export default PaymentAccounts
