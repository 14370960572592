import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'

const AppointmentSlotsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Scheduling/AppointmentSlots/New')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataMain, setDataMain, clearDataMain, dataMainDefault] = useDraft('Scheduling:AppointmentSlots:DataMain')

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            let startHour = parseInt(dataMain.starts_at.split(':')[0])
            let endHour = parseInt(dataMain.ends_at.split(':')[0])

            if (endHour < startHour) {
            if (!window.confirm('End time less than start time. Continue?')) {
                return
            }
            }

            ac.showSpinner(true)

            FetchHelper({
                url: `/api/v2/admin/appointment-slots/`,
                method: 'POST',
                body: {
                    appointment_slot: dataMain
                }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className="appointment-slots-new-page-container page-container">
            <PageHeaderComponent title="New Appointment Slot" />
            <NoticeComponent location="Main" notices={notices} />
            <FormComponent formData={dataMain} formErrors={errors} onChange={setDataMain} displayErrorBanner={true} fields={[{
                    type: 'input-text',
                    name: 'name',
                    label: 'Name'
                },{
                    type: 'input-text',
                    name: 'display_name',
                    label: 'Display Name'
                },{
                    type: 'input-text',
                    name: 'capacity',
                    label: 'Capacity'
                },{
                    type: 'time',
                    name: 'starts_at',
                    label: 'Start Time'
                },{
                    type: 'time',
                    name: 'ends_at',
                    label: 'End Time'
                },{
                    type: 'input-money',
                    name: 'fee',
                    label: 'Fee'
                }]} />

                <ControlBar handleSubmit={handleSubmit} cancelUrl="/appointment-slots" handleAfterSubmit={() => RouteHelper.redirect('/appointment-slots')} handleAfterClear={() => {
                    RouteHelper.reload()
                }} dataItems={[
                    [dataMain, clearDataMain, dataMainDefault]
                ]}  />
        </div>
    )
}

export default AppointmentSlotsNew
