import { useContext } from 'react'
import { AppContext } from '../../../AppContext'

const useAppContext = () => {
    const context = useContext(AppContext)

    return {
        _ac: context
    }
}

export {
    useAppContext
}