import React, { useEffect } from 'react'

import { UI } from '../../../UI'

import PropTypes from 'prop-types'

import './index.css'

const SelectComponent = ({ name, value, options=[], events={}, eventsDOM={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        value: value,
        focused: false
    })

    const _inputRef = React.useRef(null)

    useEffect(() => {
        if (!_inputRef.current)
            return

        const handleUnfocus = () => {
            _state.set('focused', false)
        }

        _inputRef.current.addEventListener('blur', handleUnfocus)

        return () => {
            _inputRef.current && _inputRef.current.removeEventListener('blur', handleUnfocus)
        }
    }, [_inputRef.current])

    const handleFocus = (e) => {
        _state.set('focused', true)
    }

    const handleChange = (e) => {
        const value = e.target.value
        
        _state.set('value', value)

        if (events.handleChange)
            events.handleChange(value)
    }

    const getSelectedOptionTitle = () => {
        let selectedOption = {}

        options.forEach((item) => {
            if (item.value === _state.get('value')) {
                selectedOption = item
            }
        })

        return selectedOption.title
    }

    const decorateEvents = () => {
        let eventsConfig = {...eventsDOM}

        eventsConfig.onFocus = handleFocus

        delete eventsConfig.onChange

        return eventsConfig
    }

    let _unselectedTitle = config.title || '--'

    const _containerClassNames = UI.Decorators.String([
        ['ui-action--focused', _state.get('focused')],
        ['ui-action--error', config.hasError],
        config.classNames
    ])

    return (
        <UI.Layout name="select" type="container" config={{ hasEventBoundary: true, classNames: _containerClassNames }}>
            <div className="ui--selectLabelWrapper">
                <div className="ui--selectTitle" key={`ui-key--selectTitle-${_state.get('value')}`}>
                    {getSelectedOptionTitle() || _unselectedTitle}
                </div>

                <div className="ui--selectLabelIcon">
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
            </div>

            <select name={name} value={_state.get('value')} onChange={handleChange} {...decorateEvents()} ref={_inputRef}>
                <option>{_unselectedTitle}</option>

                {options.map((item, index) => {
                    return (
                        <option value={item.value} key={`select-component-${item.title}-${index}`}>{item.title}</option>
                    )
                })}
            </select>
        </UI.Layout>
    )
}

SelectComponent.propTypes = {
    name: PropTypes.string.isRequired,
    
    value: PropTypes.any.isRequired,

    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
        })
    ).isRequired,
    
    events: PropTypes.shape({
        onChange: PropTypes.func
    }),
    
    config: PropTypes.shape({
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        classNames: PropTypes.string,
        title: PropTypes.string
    })
}

export { SelectComponent }