import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { List, PageHeader, Search, Tags } from '../../components';
import { Utils } from '../../helpers';
import { useAppContext, useFetch, useNotices } from '../../hooks';
import './index.css';
import dayjs from 'dayjs';

const baseUrl = '/labs';
const baseApiUrl = '/api/v2/admin/labs';

const Labs = () => {
  const [ac] = useAppContext();
  const [NoticeComponent, notices] = useNotices('Administration/Labs/Index');
  const { FetchHelper } = useFetch();

  const [page, setPage] = useState(1);
  const [cachedList, setCachedList] = useState({ data: [] });
  const [showTags, setShowTags] = useState(false);
  const [tags, setTags] = useState({ all: [], active: [] });
  const [filteredList, setFilteredList] = useState({ data: [] });

  const handleSearchAfterFetch = (results) => {
    setCachedList(results);
  };

  useEffect(() => {
    let filteredList = {
      ...cachedList,
      data: cachedList.data.map((item) => {
        item.ui_name_route = `${baseUrl}/${item.id}/edit`;
        return item;
      })
    };

    setFilteredList(filteredList);
  }, [cachedList]);

  const toggleLab = async (lab) => {
    if (!lab || !lab.url) return;

    let options = {
      url: lab.url,
      method: 'DELETE',
    };

    // --- API call
    ac.showSpinner(true);
    await Utils.executePromise(FetchHelper(options));
    ac.showSpinner(false);

    // --- update local list
    let newList = lodash
      .chain(cachedList.data)
      .cloneDeep()
      .map((it) => {
        if (it.id === lab.id) it.deleted_at = lab.deleted_at ? null : dayjs().valueOf();
        return it;
      })
      .value();
    setCachedList({ ...cachedList, data: newList });
  };

  return (
    <div>
      <PageHeader
        title="Labs"
        actions={[{ title: 'New Lab', route: '/labs/new' }]}
      />
      <Search
        apiUrl={baseApiUrl}
        page={page}
        setPage={setPage}
        afterFetch={handleSearchAfterFetch}
        showTags={showTags}
        setShowTags={setShowTags}
        tags={tags}
      />
      <Tags
        apiUrl={`${baseApiUrl}/tags`}
        showTags={showTags}
        onSelect={(activeTags) => setTags(activeTags)}
      />
      <NoticeComponent location="List" notices={notices} />
      {filteredList && (
        <List
          list={filteredList}
          setPage={setPage}
          baseUrl={baseUrl}
          baseApiUrl={baseApiUrl}
          columns={[
            {
              title: 'Name',
              key: 'name',
              type: 'url_route',
              route: 'ui_name_route',
              hideTitleOnMobile: true
            }
          ]}
          deleteEnabled={false}
          additionalDropdownOptions={[
            {
              title: 'Toggle Active State',
              icon: (item) => {
                return !item.deleted_at ? 'bi-toggle-on' : 'bi-toggle-off';
              },
              type: 'callback',
              callback: (item) => {
                toggleLab(item);
              }
            }
          ]}
        />
      )}
    </div>
  );
};

export default Labs;
