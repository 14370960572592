import { useState, useEffect } from 'react'
import PageHeaderComponent from '../../components/PageHeader'
import SearchComponent from '../../components/Search'
import TagsComponent from '../../components/Tags'
import ListComponent from '../../components/List'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'

import './index.css'

const Studios = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Administration/Studios/Index')

    const [cachedList, setCachedList] = useState({ data: [] })
    const [page, setPage] = useState(1)
    const [showTags, setShowTags] = useState(false)
    const [tags, setTags] = useState({ all: [], active: [] })
    const baseUrl = '/studios'
    const baseApiUrl = '/api/v2/admin/studios'
    const [filteredList, setFilteredList] = useState({ data: [] })
    const [fetchFlag, setFetchFlag] = useState(false)

    const handleSearchAfterFetch = (results) => {
        setCachedList(results)
    }

    const renderUiName = (name, deletedAt) => {
        let statusIcon;
        if (deletedAt) {
            statusIcon = <><span className="mr-2 studio-disabled" title="Disabled"><i className="bi bi-x-circle-fill"></i></span></>
        } else {
            statusIcon = <><span className="mr-2 studio-enabled" title="Enabled"><i className="bi bi-check-circle-fill"></i></span></>
        }

        return <>{statusIcon} {name}</>
    }

    useEffect(() => {
        let filteredList = {
            ...cachedList,
            data: cachedList.data.map((item) => {
                item.past_due = item.past_due ? <span><i className="bi bi-flag-fill"></i></span> : ''
                item.ui_name_route = `${baseUrl}/${item.id}/edit`
                item.ui_name = renderUiName(item.name, item.deleted_at)

                return item
            })
        }

        setFilteredList(filteredList)
    }, [cachedList])

    useEffect(() => {
        if (fetchFlag) {
            FetchHelper({
                url: `${baseApiUrl}?page=${page}`
            }, (res) => {
                setCachedList(res.body)
                setFetchFlag(false)
            })
        }
    }, [fetchFlag])

    const toggleStudioStatus = (item, type) => {
        ac.showSpinner(true)

        let options = {
            url: `${baseApiUrl}/${item.id}`
        }

        if (type === 'status') {
            options = {
                ...options,
                method: 'DELETE'
            }
        } else {
            options = {
                ...options,
                method: 'PUT',
                body: {
                    past_due: !item.past_due
                }
            }
        }

        FetchHelper(options, (res) => {
            ac.showSpinner(false)
            setFetchFlag(true)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    return (
        <div className='studios-container'>
            <PageHeaderComponent title="Studios" actions={[{ title: 'New Studio', route: '/studios/new' }]} />
            <SearchComponent apiUrl={baseApiUrl} page={page} setPage={setPage} afterFetch={handleSearchAfterFetch} showTags={showTags} setShowTags={setShowTags} tags={tags} />
            <TagsComponent apiUrl={`${baseApiUrl}/tags`} showTags={showTags} onSelect={(activeTags) => setTags(activeTags)} />
            <NoticeComponent location="List" notices={notices} />
            {filteredList &&
                <div className={`${fetchFlag ? 'disabled' : ''}`}>
                    <ListComponent list={filteredList} setPage={setPage} baseUrl={baseUrl} baseApiUrl={baseApiUrl}
                        columns={[
                            {
                                key: 'ui_name',
                                title: 'Name',
                                type: 'url_route',
                                route: 'ui_name_route',
                                hideTitleOnMobile: true
                            },
                            {
                                title: 'Past Due',
                                key: 'past_due'
                            }
                        ]}
                        additionalDropdownOptions={[
                            {
                                title: 'Toggle Past Due',
                                icon: 'bi-flag-fill',
                                type: 'callback',
                                callback: (item) => {
                                    toggleStudioStatus(item, 'past_due') 
                                }
                            },
                            {
                                title: 'Toggle Status',
                                icon: 'bi-toggles',
                                type: 'callback',
                                callback: (item) => {
                                    toggleStudioStatus(item, 'status') 
                                }
                            }
                        ]}
                        />
                </div>
            }
        </div>
    )
}

export default Studios