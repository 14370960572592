import {useState} from 'react'
import {useFloating, offset} from '@floating-ui/react-dom';

import './index.css'

export default ({ children, position='top', offsetPx=0 }) => {
    const [show, setShow] = useState(false)

    const {x, y, reference, floating, strategy} = useFloating({
        placement: position,
        middleware: [offset(offsetPx)]
    });

    return (
        <>
            <div className="popover-menu" ref={reference} onMouseMove={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                {children[0]}
            </div>
            {show ? (
                <div className="popover-menu-contents" ref={floating} style={{ position: strategy, top: y ?? '', left: x ?? '', zIndex: 2000 }}>
                    {children[1]}
                </div>
            ) : null}
        </>
    )
}