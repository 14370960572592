import { useRef } from 'react'

import PropTypes from 'prop-types'

import { ButtonComponent } from '../../Primitive'

import { UI } from '../../../UI'

import './index.css'

const FileComponent = ({ events={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        upload: null
    })

    const _fileInputRef = useRef(null)

    function handleFileSelect(event) {
        const selectedFile = event.target.files[0]

        _state.set('upload', selectedFile)

        convertFileToBase64(selectedFile)
            .then(data => {
                events.handleChange({
                    name: selectedFile.name,
                    data: data
                })
            })
    }

    function handleDrop(e) {
        e.preventDefault()
        
        _state.set('upload', e.dataTransfer.files[0])
    }

    function handleDragOver(e) {
        e.preventDefault()
    }

    function handleRemove() {
        _state.set('upload', null)

        _fileInputRef.current = null
    }

    function getFileSize(fileSizeInBytes) {
        const kiloBytes = Math.round(fileSizeInBytes / 1024)

        if (kiloBytes < 1024) {
            return `${kiloBytes} KB`
        }

        const megaBytes = (kiloBytes / 1024).toFixed(1)

        return `${megaBytes} MB`
    }

    function getFilePreview(file) {
        if (!file || !file.type) {
            return null
        }

        if (file.type.includes('image')) {
            return <img src={URL.createObjectURL(file)} alt={file.name} />
        }

        return <p>{file.name}</p>
    }

    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const _upload = _state.get('upload')

    return (
        <UI.Layout name="file" type="container" config={{ hasClickBoundary: true }} >
            {_upload ? (
                <UI.Layout name="fileDetails" identifier={`${_upload.name}`}>
                    <div className="ui--filePreview">
                        {getFilePreview(_upload)}
                    </div>

                    <div className="ui--fileDetails text-xs">
                        <span className="ui--fileDetailsFilename">
                            {_upload.name}
                        </span>

                        <span className="ui--fileDetailsFilesize">
                            {getFileSize(_upload.size)}
                        </span>

                        <span className="ui--fileDetailsDelete">
                            <ButtonComponent events={{
                               handleClick: handleRemove
                            }} config={{
                                palette: 'red',
                                size: 'small'
                            }}>
                                <span className="bi bi-trash-fill"></span>
                            </ButtonComponent>
                        </span>
                    </div>
                </UI.Layout>
            ) : (
                <UI.Layout name="fileUpload" eventsDOM={{ onDrop: handleDrop, onDragOver: handleDragOver }}>
                    <div className="ui--fileUploadMessage">
                        <span className="bi bi-cloud-arrow-up"></span>
                    </div>
                    
                    <div className="ui--fileUploadOptions">
                        <input className="ui--fileUploadInput" type="file" ref={_fileInputRef} onChange={handleFileSelect} />

                        <ButtonComponent events={{
                            handleClick: () => _fileInputRef.current.click()
                        }} config={{
                            type: 'primary',
                            size: 'small'
                        }}>
                            Browse
                        </ButtonComponent>
                    </div>
                </UI.Layout>
            )}
        </UI.Layout>
    )
}

FileComponent.propTypes = {
    events: PropTypes.shape({
        handleChange: PropTypes.func.isRequired
    }),

    config: PropTypes.shape({
        type: PropTypes.string,
        size: PropTypes.string
    })
}

export { FileComponent }
