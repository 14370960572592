export const FetchAbortReason = Object.freeze({
  PAGE_TERMINATED: "PAGE_TERMINATED",
});

export const FileTypes = Object.freeze({
  CSV: "text/csv",
  IMAGES: "image/*",
  CSS: "text/css",
});

export const HTMLEditorMode = Object.freeze({
  NORMAL: "normal",
  MODAL: "modal",
});

export const UserPermission = Object.freeze({
  ORDER_PROCESS: 'action_order_process',
  ORDER_LOCK_MGMT: 'order_lock_management',
  REFUNDING: 'refunding',
  ORDER_ACCESS: 'order_access'
})

export const OrderStatus = Object.freeze({
  PENDING: 'pending',
  PROCESSED: 'processed',
  PAID: 'paid',
  COMPLETED: 'completed',
  REFUNDED: 'refunded',
  RESERVATION_LOCKED: 'reservation_locked',
  NO_SHOW: 'no_show',
  CHECKED_IN: 'checked_in',
})

export const OrderStatusAction = Object.freeze({
  CHECK_IN: 'check_in',
  UN_CHECK_IN: 'uncheck_in',
  NO_SHOW: 'no_show',
  UN_NO_SHOW: 'unno_show',
})