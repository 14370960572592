import UtilsHelper from '../helpers/utils'

import './Errors.css'

const ErrorsComponent = ({ errors }) => {
    if (!errors)
        return null

    if (Array.isArray(errors) && errors.length > 0) {
        return (
            <div className="errors-container">
                {UtilsHelper.formatErrorsIntoList(errors)}
            </div>
        )
    }
    
    const keys = Object.keys(errors)

    if (keys.length) {
        return (
            <ul className="errors-container">
                {keys.map((k, i) => {
                    return (
                        <li key={`errors-component-error-item-${i}`}>
                            <em>{k}</em> {errors[k].join(', ')}
                        </li>
                    )
                })}
            </ul>
        )
    }

    return null
}

export default ErrorsComponent
