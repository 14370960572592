import { useState } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useDraft from '../../hooks/useDraft'

const BillingProfilesNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Administration/BillingProfiles/New')

    const [data, setData, clearData, dataDefault] = useDraft('Administration:BillingProfiles:Data', {
        name: '',
        base_rate: '0.0',
        per_transaction_rate: '0.0',
        convenience_fee_rate: '0.0'
    })
    const [errors, setErrors] = useState()

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            FetchHelper({
                url: `/api/v2/admin/billing-profiles`,
                method: 'POST',
                body: {
                    billing_profile: data
                }
            }, (res) => {
                ac.showSpinner(false)
                
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div>
            <PageHeaderComponent title="New Billing Profile" />
            <NoticeComponent location="New" notices={notices} />
            <FormComponent formData={data} formErrors={errors} onChange={setData} displayErrorBanner={true} fields={[
                {
                    type: 'input-text',
                    name: 'name',
                    label: 'Name'
                }, {
                    type: 'input-percent',
                    name: 'base_rate',
                    label: 'Base Rate',
                    step: 0.05
                }, {
                    type: 'input-money',
                    name: 'per_transaction_rate',
                    label: 'Per Transaction Rate',
                    step: 0.05
                }, {
                    type: 'input-percent',
                    name: 'convenience_fee_rate',
                    label: 'Convenience Fee Rate',
                    step: 0.05
                }
            ]} />

            <ControlBar handleSubmit={handleSubmit} cancelUrl="/billing-profiles" handleAfterSubmit={() => RouteHelper.redirect('/billing-profiles')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault]
            ]} />
        </div>
    )
}

export default BillingProfilesNew