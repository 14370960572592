import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import { DEFAULT_SEARCH_RESULTS } from '../../sys/constants/MarketGroups'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './edit.css'
import { getCustomCodeFormFields, getGeneralFormFields, getImagesFormFields, getServicesFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

const MarketGroupsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Markets/MarketGroups/New')

    const [data, setData, clearData, dataDefault] = useDraft('Markets:MarketGroups:Data')
    const [errors, setErrors] = useState()
    const [colorScheme, setColorScheme] = useState([])
    const dSearchRadius = DEFAULT_SEARCH_RESULTS

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('General', ['General', 'Services', 'Images', 'Custom Code'])

    const [generalFormFields, setGeneralFormFields] = useState(getGeneralFormFields())
    const [servicesFormFields] = useState(getServicesFormFields())
    const [imagesFormFields] = useState(getImagesFormFields())
    const [customCodeFormFields] = useState(getCustomCodeFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let fields = getGeneralFormFields(dSearchRadius, colorScheme)
        setGeneralFormFields(fields)
    }, [dSearchRadius, colorScheme])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'General',
                forms: [{ id: 'Main', fields: generalFormFields }]
            },
            {
                tab: 'Services',
                forms: [{ id: 'Services', fields: servicesFormFields }]
            },
            {
                tab: 'Images',
                forms: [{ id: 'Images', fields: imagesFormFields }]
            },
            {
                tab: 'Custom Code',
                forms: [{ id: 'Custom Code', fields: customCodeFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/color-schemes`
        }, (res) => {
            ac.showSpinner(false)

            const colors = [{ id: null, title: 'System Default', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: item.id,
                    title: item.name,
                    value: item.id
                }
                colors.push(option);
            })
            setColorScheme(colors)
        }, (res) => {
            ac.showSpinner(false)
        })

    }, [])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = data

            let images = {}
            const images_attributes = ["favicon", "zip_splash_xs", "zip_splash_sm", "zip_splash_md", "zip_splash_lg"]

            for (var i = 0; i < 5; i++) {
                const img = data[images_attributes[i]]

                if (img) {
                    images = {
                        ...images,
                        [images_attributes[i] + "_base64"]: img.value
                    }

                    delete body[images_attributes[i]]
                }

            }

            body = {
                ...body,
                ...images
            }

            delete body.body_code
            delete body.head_code

            FetchHelper({
                url: `/api/v2/admin/market-groups`,
                method: 'POST',
                body: {
                    market_group: body
                }
            }, (res) => {
                ac.showSpinner(false)

                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }

                reject()
            })
        })
    }

    return (
        <div className="market-group-page-container page-container">
            <PageHeaderComponent title="New Market Group" />
            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            <div className="tab-content">
                <div className={`page-form-container ${tab === 'General' ? 'show' : ''}`}>
                    <NoticeComponent location="Tab/General" notices={notices} />
                    <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={(d) => setData({...data, ...d})} displayErrorBanner={true} fields={generalFormFields} />
                </div>
                <div className={`page-form-container ${tab === 'Services' ? 'show' : ''}`}>
                    <NoticeComponent location="Tab/Services" notices={notices} />
                    <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={(d) => setData({...data, ...d})} displayErrorBanner={true} fields={servicesFormFields} />
                </div>
                <div className={`page-form-container ${tab === 'Images' ? 'show' : ''}`}>
                    <NoticeComponent location="Tab/Images" notices={notices} />
                    <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={(d) => setData({...data, ...d})} displayErrorBanner={true} fields={imagesFormFields} />
                </div>
                <div className={`page-form-container ${tab === 'Custom Code' ? 'show' : ''}`}>
                    <NoticeComponent location="Tab/CustomCode" notices={notices} />
                    <FormComponent formData={data} formErrors={errors} setFormErrors={setErrors} onChange={(d) => setData({...data, ...d})} displayErrorBanner={true} fields={customCodeFormFields} />
                </div>
            </div>
            <ControlBar handleSubmit={handleSubmit} cancelUrl="/market-groups" handleAfterSubmit={() => RouteHelper.redirect('/market-groups')} handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [data, clearData, dataDefault]
            ]} />
        </div>
    )
}

export default MarketGroupsNew
