export function getGeneralFormFields(dataGeneral) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name',
      placeholder: 'Lovely Labs, LLC.'
    },
    {
      type: 'input-file',
      name: 'logo_base64',
      label: 'Logo',
      imageUrl: dataGeneral?.logo_base64,
      relatedFields: ['logo']
    }
  ];
}

export function getStudiosFormFields(availableStudios = [], dataStudios) {
  return [
    {
      type: 'two-multiselect',
      name: 'studio_ids',
      label: 'Studios',
      list_labels: ['Available Studios', 'Studios Selected'],
      selectable: availableStudios,
      selected: dataStudios?.studio_ids || []
    }
  ];
}
