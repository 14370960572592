import {useState, useContext} from 'react'

import ConfirmComponent from "../Confirm"
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import lodash from 'lodash'

import './index.css'

const ControlBar = ({ baseApiUrl, redirectUrl, cancelUrl, data={}, disabled = false, enableCopy=true, modalProp='name', enableDelete=true, submitButtonTitle, handleSubmit, dataItems, isOutOfEditFlow, doesPageLiveUpdates, handleAfterClear=()=>{}, handleAfterSubmit=()=>{} }) => {
    const [ac] = useAppContext()

    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('delete')

    const handleSecondaryClick = (type) => {
        setModalType(type)
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const hasData = () => {
        return Object.keys(data).length
    }

    const clearAllItems = (afterClear=true) => {
        dataItems?.forEach(dataItem => {
            dataItem[1]()
        })

        if (afterClear)
            handleAfterClear()
    }

    const renderClearItemsButton = () => {
        let countChanges = 0

        if (dataItems && dataItems.length) {
            dataItems.forEach(dataItem => {
                let data = dataItem[0]
                let dataDefault = dataItem[2]

                if (!data || !dataDefault)
                    return

                // @TODO: address tab switching issues with pickers 
                if (typeof data === 'string' && data !== dataDefault) {
                    countChanges++
                    return
                }

                if(Array.isArray(data) && data.length !== dataDefault.length) {
                    countChanges++
                    return
                }

                if (data) {
                    for (let prop in data) {
                        const p1 = data[prop]
                        const p2 = dataDefault[prop]

                        if(!lodash.isEqual(p1, p2)) {
                            countChanges++
                        }
                    }
                }
            })
        }

        if (countChanges === 0) {
            return null
        } else {
            return <button disabled={ac.isLoading || ac.showProgress} className="delete" onClick={clearAllItems}>Clear ({countChanges}) {countChanges === 1 ? 'Change' : 'Changes'}</button>
        }
    }

    const handleSubmitClick = async () => {
        try {
            await handleSubmit()
                .then(() => {
                    clearAllItems(false)
                    handleAfterSubmit()
                })
                .catch(() => {
                    
                })
        } catch (e) {}
    }

    const handleCancelRedirect = () => {
        RouteHelper.redirect(cancelUrl)
    }

    return (
        <div className="control-bar-component-container">
            {isOutOfEditFlow ? (
                <div className="out-of-edit-flow-banner">
                    Remember to update if you have pending changes in another tab.
                </div>
            ) : null}

            { doesPageLiveUpdates && (
                <div className='ui-help-snippet--container'>
                    This page is updated on the fly/live. No need to click "update" button to save changes.
                </div>
            )}

            <div className="controls-container">
                <div className="primary-controls">
                    <button disabled={ac.isLoading || ac.showProgress || doesPageLiveUpdates || disabled} onClick={handleSubmitClick}>{submitButtonTitle ? submitButtonTitle : (hasData() ? 'Update' : 'Create')}</button>
                    {cancelUrl ? <button disabled={ac.isLoading || ac.showProgress || disabled} onClick={handleCancelRedirect} className="secondary">Cancel</button> : null}
                    {renderClearItemsButton()}
                </div>

                {hasData() ? (
                    <div className="secondary-controls">
                        {enableCopy && <button onClick={() => handleSecondaryClick('copy')} className="secondary">Copy</button> || null}
                        {enableDelete && <button onClick={() => handleSecondaryClick('delete')} className="delete">Delete</button> || null}
                    </div>
                ) : null}
            </div>

            {modalType === 'copy' && showModal && <ConfirmComponent apiUrl={`/api/v2/admin${baseApiUrl}/${data.id}/copy`} method='GET' title={`Copy the following?`} entityName={data[modalProp]} confirm={() => RouteHelper.redirect(redirectUrl)} cancel={handleCloseModal} reload={false} /> || null}
            {modalType === 'delete' && showModal && <ConfirmComponent apiUrl={`/api/v2/admin${baseApiUrl}/${data.id}`} method='DELETE' title={`Delete the following?`} entityName={data[modalProp]} confirm={() => RouteHelper.redirect(redirectUrl)} cancel={handleCloseModal} reload={false} /> || null}
        </div>
    )
}

export default ControlBar