import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const MenuComponent = ({ items }) => {
    if (!items)
        return

    return (
        <UI.Layout name="menu" type="container" config={{ hasClickBoundary: true }}>
            <ul className="ui--menuList">
                {items.map(item => {
                    return (
                        <li key={`ui-key--menuListItem--${item.title}`} className={`${item.palette ? `ui--menuListItem--palette--${item.palette}` : ''}`} onClick={() => item.callback()}>{item.title}</li>
                    )
                })}
            </ul>
        </UI.Layout>
    )
}

MenuComponent.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            callback: PropTypes.func
        })
    )
}

export { MenuComponent }