import UtilsHelper from './utils'

const RouteHelper = {}

RouteHelper.getOrigin = () => {
    return window.location.origin
}

RouteHelper.getPathname = () => {
    return window.location.pathname
}

RouteHelper.redirect = (route, newWindow=false) => {
    if (!newWindow) {
        window.location = `${RouteHelper.getOrigin()}${route}`
    } else {
        window.open(`${RouteHelper.getOrigin()}${route}`, '_blank')
    }
}

RouteHelper.redirectToRoot = () => {
    window.location = RouteHelper.getOrigin()
}

RouteHelper.reload = () => {
    window.location.reload()
}

RouteHelper.getCurrentRoute = () => {
    return RouteHelper.getPathname()
}

RouteHelper.isPublicRoute = () => {
    return /^\/login|^\/$/.test(RouteHelper.getPathname())
}

RouteHelper.storeCurrentURL = () => {
    if (!/^\/login|^\/$/.test(RouteHelper.getPathname())) {
        UtilsHelper.setLocalStorage('UI:LastURL', RouteHelper.getPathname())
    }
}

RouteHelper.clearCurrentURL = () => {
    localStorage.removeItem('UI:LastURL')
}

RouteHelper.getQueryParams = () => {
    let processedParams = {}

    if (!window.location.search)
        return processedParams

    let paramsPairs = window.location.search.slice(1).split('&')

    paramsPairs.forEach((paramPair, paramPairIndex) => {
        let [k, v] = paramPair.split('=')
        let value = decodeURI(v)

        if (/^(true|false)$/.test(value)) {
            value = Boolean(value)
        } else if (/^\d+$/.test(value)) {
            value = Number(value)
        }

        processedParams[k] = value
    })

    return processedParams
}

RouteHelper.setQueryParams = (key, value) => {
    if (!key) {
        window.location.search = ''
    }

    const params = new URLSearchParams({
        [key]: encodeURI(value)
    })

    if (window.history.pushState) {
        const url = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString()

        window.history.pushState({}, '', '?' + params.toString())
    }
}

export default RouteHelper