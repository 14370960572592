import {useState, useEffect} from 'react'

import './index.css'

const QuickSearch = ({ term='', setTerm=()=>{}, onChange=()=>{} }) => {
    const [dataInput, setDataInput] = useState(term)
    
    const [timerInput, setTimerInput] = useState()

    useEffect(() => {
        setDataInput(term)
    }, [term])

    return (
        <div className="quick-search-container">
            {dataInput ? <i className="bi bi-x-circle-fill" onClick={() => setTerm('')}></i> : null}

            <input type="text" value={dataInput} placeholder="Search..." onChange={(e) => {
                const value = e.target.value

                clearTimeout(timerInput)
                setDataInput(value)

                setTimerInput(setTimeout(() => {
                    onChange(value)
                }, 450))
             }} />
        </div>
    )
}

export default QuickSearch