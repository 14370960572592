import './index.css'

const PageSection = ({ children, className='' }) => {
    return (
        <div className={`ui-l-pageSection ${className}`}>
            {children}
        </div>
    )
}

export { PageSection }