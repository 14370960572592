import {useEffect, useState} from 'react'

import dayjs from 'dayjs'

import { MONTHS } from '../../sys/constants/Months'

import './index.css'

const DateSelector = ({ date, onChange }) => {
    const [_date, _setDate] = useState(date)
    const [_uniqueIdentifier, _setUniqueIdentifier] = useState(new Date().getTime())
    const [_formYear, _setFormYear] = useState(dayjs(date).year())
    const [_formMonth, _setFormMonth] = useState(dayjs(date).month())
    const [_formDay, _setFormDay] = useState(dayjs(date).date())
    const [_formYears, _setFormYears] = useState([dayjs(date).year(), dayjs(date).add(1, 'year').format('YYYY')])
    const [_days, _setDays] = useState(dayjs().daysInMonth())

    useEffect(() => {
        let date = dayjs()
        let years = []

        for (let i=0; i < 2; i++) {
            years.push(date.add(i, 'year').format('YYYY'))
        }

        _setFormYears(years)
    }, [])

    useEffect(() => {
        let date = dayjs().month(_formMonth)

        _setDays(date.daysInMonth())
    }, [_formMonth])

    useEffect(() => {
        let date = dayjs()

        date = date.month(_formMonth).date(_formDay).year(_formYear)

        onChange(date.format('YYYY-MM-DD'))
    }, [_formMonth, _formDay, _formYear])

    return (
        <div className="date-selector-container">
            <select value={_formMonth} onChange={(e) => {
                _setFormMonth(e.target.value)
            }}>
                {MONTHS.map((item, index) => {
                    return (
                        <option value={index} key={`date-selector-component-month-${_uniqueIdentifier}-${index}`}>{item}</option>
                    )
                })}
            </select>
            <select value={_formDay} onChange={(e) => {
                _setFormDay(e.target.value)
            }}>
                {_days ? Array(_days).fill(0).map((item, index) => {
                    return (
                        <option value={index+1} key={`date-selector-component-days-${_uniqueIdentifier}-${index+1}`}>{index+1}</option>
                    )
                }) : null}
            </select>
            <select value={_formYear} onChange={(e) => {
                _setFormYear(e.target.value)
            }}>
                {_formYears.map((item, index) => {
                    return (
                        <option value={item} key={`date-selector-component-years-${_uniqueIdentifier}-${index}`}>{item}</option>
                    )
                })}
            </select>
        </div>
    )
}

export default DateSelector