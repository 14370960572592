import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { InputComponent } from '../../Primitive'

// import './index.css'

const FormCreditCardNumberComponent = ({ name, value, errors={}, config={}, events={} }) => {
    const handleChange = (fieldValue) => {
        if (events.handleChange)
            events.handleChange(fieldValue)
    }

    const valueFilter = (fieldValue) => {
        return fieldValue.replace(/[^\d]/g, '')
    }

    const displayFilter = (fieldValue) => {
        if (!fieldValue)
            return

        const maxLength = 16;
        const maskedLength = 12;
        const maskedNumber = fieldValue.slice(0, maskedLength);
        const visibleNumber = fieldValue.slice(maskedLength, maxLength);
        const visibleLength = visibleNumber.length;
        const maskedDigits = '*'.repeat(maskedLength);
        const formattedDigits = visibleNumber.split('').map((digit, index) => {
            if (index === 0) {
            return digit;
            } else if (index % 4 === 0) {
            return ` ${digit}`;
            } else {
            return digit;
            }
        }).join('');

        return `${maskedDigits}${formattedDigits}`;
    }
      

    return (
        <InputComponent name={name} type="text" value={value || ''} events={{ handleChange: handleChange }} config={{ cell: <i className="bi bi-credit-card-2-front"></i>, displayFilter: displayFilter, valueFilter: valueFilter, maxLength: 16, hasError: config.hasError }} />
    )
}

FormCreditCardNumberComponent.propTypes = {
    name: PropTypes.string.isRequired,

    errors: PropTypes.object,

    config: PropTypes.shape({
        classNames: PropTypes.string,
        displayFilter: PropTypes.func
    }),

    events: PropTypes.shape({
        onMount: PropTypes.func
    }),
}

export { FormCreditCardNumberComponent }
