import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { EntityCardComponent } from '..'

import './index.css'

const EntityStackComponent = ({ items=[], type, events={} }) => {
    const handleClick = (item) => {
        if (events.handleClick)
            events.handleClick(item)
    }

    const _entityStackListHTML = items.slice(0, 5).map((item, index) => {
        return (
            <li key={`ui-key--entityStackListItem--${item.id}-${item.image_url}-${index}`} className="ui--entityStackListItem" onClick={() => handleClick(item)}>
                <EntityCardComponent item={item} type={type} stacked={true} />
            </li>
        )
    })

    return (
        <UI.Layout name="entityStack" type="container" config={{ hasClickBoundary: true }}>
            <ul className="ui--entityStackList">
                {items.length > 0 ? _entityStackListHTML : (
                    <li className="ui--entityStackListItem ui--entityStackListItem--add" onClick={() => handleClick()}>
                        <i className="bi bi-plus-circle-dotted"></i>
                    </li>
                )}
            </ul>
        </UI.Layout>
    )
}

EntityStackComponent.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),

    events: PropTypes.shape({
        handleClick: PropTypes.func
    })
}

export { EntityStackComponent }