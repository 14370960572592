import './index.css'

const MessageComponent = ({ message }) => {
    return (
        <div className="message-container">
            {message}
        </div>
    )
}

export default MessageComponent