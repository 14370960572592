import {useState, useEffect, useRef} from 'react'

import './index.css'

const DropdownText = ({ options={}, onClick=()=>{} }) => {
    const [open, setOpen] = useState(false)

    const ref = useRef(null)

    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [ref])

    return (
        <div className="dropdown-text-container" ref={ref}>
            <button className="dropdown-text-button" onClick={() => setOpen(!open)}>
                {options.title} <i className="bi bi-chevron-down"></i>
            </button>

            <ul className={`dropdown-text-options ${open ? 'open' : 'closed'}`}>
                {options.list.map((listItem, listIndex) => {
                    return (
                        <li key={`dropdown-text-option-${listItem.value}-${listIndex}`} onClick={() => {
                            setOpen(false)
                            onClick(listItem.value)
                        }}>
                            {listItem.title}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default DropdownText