import {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import AssetUploader from '../../componentsV2/Integrated/AssetUploader'
import FetchHelper from '../../helpers/fetch'
import FieldSelectorComponent from '../FieldSelector'
import FormComponent from '../Form'
import { ModalComponent } from '../../componentsV2/Complex/Modal'
import PopoverComponent from '../Popover'
import ProjectorComponent from '../Projector'
import RouteHelper from '../../helpers/route'
import SelectPaginatedComponent from '../SelectPaginated'
import SwitchComponent from '../Switch'
import Tooltip from '../Tooltip'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import { useSimpleState } from '../../UI/hooks'

import './index.css'

const requiresProofOptions = Object.freeze([
    { id: 0, title: 'No', value: 'no' },
    { id: 1, title: 'Any', value: 'any' },
    { id: 2, title: 'Individual Shot', value: 'individual_shot' },
    { id: 3, title: 'Group Shot', value: 'group_shot' }
])

export default ({ data=[], apiUrl, sync=false, onChange, columns, entityParent, entityType='Package', withSelectors={} }) => {
    const [ac] = useAppContext()

    const [_data, setData] = useState([])
    const [formData, setFormData] = useState()
    const [dataModal, setDataModal] = useState()
    const [dataAddonsModal, setDataAddonsModal] = useState()
    const [dataCouponCodes, setDataCouponCodes] = useState([])
    const [errors, setErrors] = useState({})
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showEditAddonsModal, setShowEditAddonsModal] = useState(false)
    const [optionsCategories, setOptionsCategories] = useState([])

    const {_state: _dataModalImageOverride} = useSimpleState()

    const prefix = entityParent === 'Package Plan' ? 'package_plan' : 'package'
    const entity = entityType === 'Package' ? 'package' : 'addon'

    const routePrefix = entityType === 'Addon' ? 'addons' : 'packages'
    const buttonTitle = entityType === 'Addon' ? 'Addon' : 'Package'

    const selectorFlags = {
        backgrounds: false,
        poses: false,
        proofs: false,
        sheets: false,

        ...withSelectors
    }

    useEffect(() => {
        let newData = data.map((item, index) => {
            return item
        })

        handleImageOverrideReset()
        setDataCouponCodes([])
        setData(newData)
        onChange(newData)

        FetchHelper({
            url: '/api/v2/admin/categories'
        }, (res) => {
            let categories = [{ id: 0, title: 'Select', value: "null" }];
            res.body.data.map((item, i) => {
                const option = {
                    id: i+1,
                    title: item.name,
                    value: item.id
                }
                categories.push(option)
            })
            setOptionsCategories(categories)
        }, (res) => {
        })
    }, [])

    const handleImageOverrideReset = () => {
        let imageOptions = [{
            type: 'Main Image Override',
            id: 'image_override',
            externalUrl: '',
            note: 'Upload image or enter URL',
            attachedImage: {
                data: '',
                filename: ''
            },
            assetSource: '0'
        }]

        if (entity === 'package')
            imageOptions.push({
                type: 'Mobile Image Override',
                id: 'mobile_image_override',
                externalUrl: '',
                note: 'Upload image or enter URL',
                attachedImage: {
                    data: '',
                    filename: ''
                },
                assetSource: '0'
            })

        _dataModalImageOverride.set(imageOptions)
    }

    const handleDeleteAddon = (item) => {
        setErrors({})

        if (sync && item.value !== null) {
            ac.showSpinner(true)

            FetchHelper({
                url: item.url,
                // relativePath: false,
                method: 'DELETE'
            }, (res) => {
                ac.showSpinner(false)
                refreshList()
            }, (res) => {
                ac.showSpinner(false)
            })
        } else {
            let newState = _data.filter(e => {
                return e.item.value !== item.item.value
            })

            setData(newState)
            onChange(newState)
        }
    }

    const refreshList = () => {
        ac.showSpinner(true)

        FetchHelper({
            url: apiUrl,
            // relativePath: false,
            method: 'GET'
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body.data)
            onChange(res.body.data)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleShowEditModal = (item) => {
        setFormData(item)
        setShowEditModal(true)
    }

    const handleShowEditAddonsModal = (item) => {
        setDataAddonsModal(item)
        setShowEditAddonsModal(true)
    }

    const handleCloseEditAddonsModal = () => {
        setShowEditAddonsModal(false)
        handleImageOverrideReset()
        setDataCouponCodes([])
    }

    const handleShowAddModal = () => {
        setShowAddModal(true)
    }

    const handleCloseAddModal = () => {
        setShowAddModal(false)
        handleImageOverrideReset()
        setDataCouponCodes([])
    }

    const handleCloseModal = () => {
        setErrors({})
        handleImageOverrideReset()
        setDataCouponCodes([])
        setShowAddModal(false)
        setShowEditModal(false)
    }

    const handleAddAddon = () => {
        setErrors({})

        let key = entity === 'package' ? 'package_id' : 'addon_id'

        if (sync) {
            ac.showSpinner(true)

            let body = {...dataModal}
            if (body.requires_proof !== null && body.requires_proof !== undefined)
                body.requires_proof = body.requires_proof
            const ids = []

            body[key].forEach((item) => {
                ids.push(item.value)
            })

            body[key] = ids

            _dataModalImageOverride.get().forEach((item, index) => {
                if (!item?.attachedImage?.data && !item?.externalUrl)
                    return

                body[`${item.id}_attributes`] = {
                    data_base64: /^data/.test(item?.attachedImage?.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                    data_url: item?.externalUrl,
                    data_source: item.assetSource === '0' ? 'local' : 'remote'
                }
            })

            if (dataCouponCodes) {
                body.item_coupon_codes_attributes = dataCouponCodes.map((item, idx) => {
                    if (!item.value) return

                    return {
                        coupon_code_id: item.value,
                        include_bundled: item.include_bundled
                    }
                }).filter((_) => _)
            }

            FetchHelper({
                url: apiUrl,
                method: 'POST',
                body: {
                    [`${prefix}_${entity}`]: body
                }
            }, (res) => {
                ac.showSpinner(false)

                handleCloseModal()
                refreshList()
            }, (res) => {
                ac.showSpinner(false)
            })
        } else {
            let body = {...dataModal}
            let bodies = []

            const ids = body[key]

            delete body[key]

            _dataModalImageOverride.get().forEach((item, index) => {
                if (!item?.attachedImage?.data && !item?.externalUrl)
                    return

                body[`${item.id}_attributes`] = {
                    data_base64: /^data/.test(item?.attachedImage?.data) ? item.attachedImage.data.replace(/^data:[a-z]+\/[a-z\.\+\-]+;base64,/, '') : null,
                    data_url: item?.externalUrl,
                    data_source: item.assetSource === '0' ? 'local' : 'remote'
                }
            })

            if (dataCouponCodes) {
                body.item_coupon_codes_attributes = dataCouponCodes.map((item, idx) => {
                    if (!item.value) return

                    return {
                        coupon_code_id: item.value,
                        include_bundled: item.include_bundled
                    }
                }).filter((_) => _)
            }

            ids.forEach((item) => {
                bodies.push({
                    ...body,
                    item: item,
                    [key]: item.value
                })
            })

            let newData = [..._data, ...bodies]

            setData(newData)
            onChange(newData)
            handleCloseModal()
        }
    }

    const handleEditAddon = () => {
        ac.showSpinner(true)

        let body = {...dataModal}
        if (body.requires_proof !== null && body.requires_proof !== undefined)
            body.requires_proof = body.requires_proof

        FetchHelper({
            url: formData.url,
            // relativePath: false,
            method: 'PUT',
            body: {
                [`${prefix}_${entity}`]: body
            }
        }, (res) => {
            ac.showSpinner(false)

            handleCloseModal()
            refreshList()
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleDrop = (e, item, index, section) => {
        setErrors({})

        let dataReference = (section === 'emphasized') ? filteredDataEmphasized : filteredDataRegular

        e.preventDefault()

        let target = 0

        if (!e.target.getAttribute('data-drag-index')) {
            target = parseInt(e.target.closest('li').getAttribute('data-drag-index'))
        } else {
            target = parseInt(e.target.getAttribute('data-drag-index'))
        }

        if (draggedElementIndex === target) {
            return
        }

        if (sync) {
            ac.showSpinner(true)

            let body = {
                position: target - ((section === 'emphasized') ? filteredDataEmpasizedOffset : filteredDataRegularOffset)
            }

            FetchHelper({
                url: dataReference[draggedElementIndex].url,
                // relativePath: false,
                method: 'PUT',
                body: body
            }, (res) => {
                ac.showSpinner(false)
            }, (res) => {
                ac.showSpinner(false)
            })
        }

        let newFields = [...dataReference]
        let element = newFields[draggedElementIndex]

        newFields.splice(draggedElementIndex, 1)
        newFields.splice(target, 0, element)

        if (section === 'emphasized') {
            setData([...newFields, ...filteredDataRegular])
            onChange([...newFields, ...filteredDataRegular])
        } else {
            setData([...newFields, ...filteredDataEmphasized])
            onChange([...newFields, ...filteredDataEmphasized])
        }
    }

    const renderHeaders = () => {
        return (
            <ul className="row columns">
                {columns.map((column, index) => {
                    <li key={`field-multiselector-header-${index}`}>{column.title}</li>
                })}
            </ul>
        )
    }

    const handleRenderBox = (item) => {
        if (entityType === 'Addon') {
            return item.cascade_addons?.data?.length > 0 ? '' : 'disabled'
        } else {
            return item.addons?.data?.length > 0 ? '' : 'disabled'
        }
    }

    const handlePackagesAddonEdit = (prop, index, value) => {
        ac.showSpinner(true)

        let newState = {...dataAddonsModal}

        newState.addons.data[index][prop] = value

        setDataAddonsModal(newState)

        FetchHelper({
            url: newState.addons.data[index].url,
            // relativePath: false,
            method: 'PUT',
            body: {
                package_plan_package_addon: {
                    [prop]: value
                }
            }
        }, (res) => {
            ac.showSpinner(false)
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    let filteredDataEmpasizedOffset = 0
    let filteredDataEmphasized = _data.filter(itm => {
        let a = itm?.item?.item ? itm.item.item : itm

        if (a.emphasized && a.bundled)
            filteredDataEmpasizedOffset += 1

        return a.emphasized
    })

    let filteredDataRegularOffset = 0
    let filteredDataRegular = _data.filter(itm => {
        let a = itm?.item?.item ? itm.item.item : itm

        if (!a.emphasized && a.bundled)
            filteredDataRegularOffset += 1

        return !a.emphasized
    })

    let draggedElementIndex = null

    return (
        <div className={`field-multiselector-container scroll-invisible ${ac.isLoading ? 'disabled' : ''}`}>
            <div className="field-multiselector-container-inner">
            <div className="row header-controls">
                <button className="secondary" onClick={handleShowAddModal}>Attach {buttonTitle}</button>
            </div>

            {renderHeaders()}

            <ul className="rows">
                {entity !== 'package' ? <h2>Emphasized</h2> : null}

                {entity !== 'package' && filteredDataEmphasized.length > 0 ? (<li><div className="titles"><span className="name">Name</span> <span className="code">Code</span> <span className="price">Price</span></div></li>) : null}

                {filteredDataEmphasized.length > 0 ? filteredDataEmphasized.map((itm, index) => {
                    let section = 'emphasized'
                    let item = itm
                    let uiItem = item?.item?.item ? item.item.item : item

                    if (item.bundled) {
                        return (
                            <li key={`field-multiselector-bundled-${index}`}>
                                <div className="info">
                                    <i className="bi bi-arrow-down-up disabled"></i> <div className="flex"><span className="name">{uiItem.name}</span> <span className="code">{uiItem.code}</span> <span>{UtilsHelper.formatDollar(uiItem.price_override || uiItem.value)}</span></div>
                                </div>

                                <div className="controls">
                                <button onClick={() => RouteHelper.redirect(`/${routePrefix}/${item[`${entity}_id`]}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                    {entityParent === 'Package Plan' ? (
                                        <button className={`secondary ${handleRenderBox(item)}`} onClick={() => handleShowEditAddonsModal(item)}><i className="bi bi-boxes"></i></button>
                                    ) : null}
                                    {sync ? (
                                        <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                    ) : null}
                                    <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                </div>
                            </li>
                        )
                    } else {
                        return (
                            <li key={`field-multiselector-bundled-emphasized-${index}`}
                                draggable="true"
                                data-drag-index={index}
                                onDragStart={() => draggedElementIndex = index}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, item, index, section)}>
                                    <div className="info">
                                        <i className="bi bi-arrow-down-up"></i> <div className="flex"><span className="name">{uiItem.name}</span> <span className="code">{uiItem.code}</span> <span>{UtilsHelper.formatDollar(uiItem.price_override || uiItem.value)}</span></div>
                                    </div>

                                    <div className="controls">
                                        <button onClick={() => RouteHelper.redirect(`/${routePrefix}/${item[`${entity}_id`]}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                        {entityParent === 'Package Plan' ? (
                                            <button className={`secondary ${handleRenderBox(item)}`} onClick={() => handleShowEditAddonsModal(item)}><i className="bi bi-boxes"></i></button>
                                        ) : null}
                                        {sync ? (
                                            <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                        ) : null}
                                        <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                    </div>
                                </li>
                        )
                    }
                }) : entity !== 'package' ? <p>No emphasized addons currently attached to this {entityParent.toLowerCase()}</p> : null}
            </ul>

            <ul className="rows">
                {entity !== 'package' ? <h2>Regular</h2> : null}

                {filteredDataRegular.length > 0 ? (<li><div className="titles"><span className="name">Name</span> <span className="code">Code</span> <span className="price">Price</span></div></li>) : null}

                {filteredDataRegular.length > 0 ? filteredDataRegular.map((itm, index) => {
                    let section = 'regular'
                    let item = itm
                    let uiItem = item?.item?.item ? item.item.item : item

                    if (item.bundled) {
                        return (
                            <li key={`field-multiselector-bundled-regular-${index}`}>
                                <div className="info">
                                    <i className="bi bi-arrow-down-up disabled"></i> <div className="flex"><span className="name">{uiItem.name}</span> <span className="code">{uiItem.code}</span> <span>{UtilsHelper.formatDollar(uiItem.price_override || uiItem.value)}</span></div>
                                </div>

                                <div className="controls">
                                    <button onClick={() => RouteHelper.redirect(`/${routePrefix}/${item[`${entity}_id`]}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                    {entityParent === 'Package Plan' ? (
                                        <button className={`secondary ${handleRenderBox(item)}`} onClick={() => handleShowEditAddonsModal(item)}><i className="bi bi-boxes"></i></button>
                                    ) : null}
                                    {sync ? (
                                        <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                    ) : null}
                                    <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                </div>
                            </li>
                        )
                    } else {
                        return (
                            <li key={`field-multiselector-bundled-emphasized-${index}`}
                                draggable="true"
                                data-drag-index={index}
                                onDragStart={() => draggedElementIndex = index}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, item, index, section)}>
                                    <div className="info">
                                        <i className="bi bi-arrow-down-up"></i> <div className="flex"><span className="name">{uiItem.name}</span> <span className="code">{uiItem.code}</span> <span>{UtilsHelper.formatDollar(uiItem.price_override || uiItem.value)}</span></div>
                                    </div>

                                    <div className="controls">
                                        <button onClick={() => RouteHelper.redirect(`/${routePrefix}/${item[`${entity}_id`]}/edit`, true)}><i className="bi bi-pencil-square"></i></button>
                                        {entityParent === 'Package Plan' ? (
                                            <button className={`secondary ${handleRenderBox(item)}`} onClick={() => handleShowEditAddonsModal(item)}><i className="bi bi-boxes"></i></button>
                                        ) : null}
                                        {sync ? (
                                            <button className="secondary" onClick={() => handleShowEditModal(item)}><i className="bi bi-pencil-fill"></i></button>
                                        ) : null}
                                        <button className="delete" onClick={() => handleDeleteAddon(item)}><i className="bi bi-trash-fill"></i></button>
                                    </div>
                                </li>
                        )
                    }
                }) : <p>No {routePrefix === 'addons' ? 'regular ' : ''}{routePrefix} currently attached to this {entityParent.toLowerCase()}</p>}
            </ul>

            {showAddModal ? (
                <ModalComponent events={{ handleClose: handleCloseAddModal }}>
                    <div className="ui-fieldMultiselectorModal">
                        <div className="global-modal">
                            {entity === 'addon' ?
                                <>
                                    <FormComponent type="stacked" formErrors={errors} onChange={setDataModal} displayErrorBanner={true} fields={[{
                                        type: 'entity-selector',
                                        name: 'addon_id',
                                        label: 'Addon',
                                        field_info: '* represents emphasized addons',
                                        entityType: 'addons',
                                        entityTitleFilter: item => {
                                            return item.emphasized ? `* ${item.name}` : item.name
                                        },
                                    },{
                                        type: 'switch',
                                        name: 'bundled',
                                        label: 'Bundled?',
                                    },{
                                        type: 'switch',
                                        name: 'required',
                                        label: 'Required?',
                                    },{
                                        type: 'switch',
                                        name: 'enhanceable',
                                        label: 'Enhanceable?',
                                        condition: () => entityParent === 'Package Plan'
                                    },{
                                        type: 'input-number',
                                        name: 'limit_per',
                                        label: 'Limit per Package',
                                        min: 0,
                                        condition: (data) => !data.bundled
                                    },{
                                        type: 'switch',
                                        name: 'notify',
                                        label: 'Notify on Purchase?',
                                        condition: (data) => entityParent === 'Package Plan'
                                    },{
                                        type: 'select',
                                        name: 'category_id',
                                        label: 'Tab',
                                        options: optionsCategories,
                                        condition: (data) => entityParent === 'Package Plan'
                                    },{
                                        type: 'select',
                                        name: 'requires_proof',
                                        label: 'Requires Proof?',
                                        options: requiresProofOptions,
                                        defaultValue: 0,
                                        condition: (data) => entityParent === 'Package Plan' && selectorFlags.proofs
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_individual',
                                        label: 'Pose count individual',
                                        min: 0,
                                        condition: (data) => entityParent === 'Package Plan' && selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_couple',
                                        label: 'Pose count couple',
                                        min: 0,
                                        condition: (data) => entityParent === 'Package Plan' && selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_any',
                                        label: 'Pose count any',
                                        min: 0,
                                        condition: (data) => entityParent === 'Package Plan' && selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'background_count',
                                        label: 'Background count',
                                        min: 0,
                                        condition: (data) => entityParent === 'Package Plan' && selectorFlags.backgrounds
                                    },{
                                        type: 'input-number',
                                        name: 'sheet_count',
                                        label: 'Sheet count',
                                        min: 0,
                                        condition: (data) => entityParent === 'Package Plan' && selectorFlags.sheets
                                    },{
                                        type: 'input-text',
                                        name: 'price_override',
                                        label: 'Price Override',
                                        field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                                    }]} />

                                    {entityParent === 'Package Plan' ?
                                        <div className="image-override">
                                            <AssetUploader list={_dataModalImageOverride.get()} events={{ handleChange: (value) => _dataModalImageOverride.set(value) }} />
                                        </div>
                                    : null}
                                </>
                            : null}

                            {entity === 'package' ?
                                <>
                                    <FormComponent type="stacked" formErrors={errors} onChange={setDataModal} displayErrorBanner={true} fields={[{
                                        type: 'entity-selector',
                                        name: 'package_id',
                                        label: 'Package',
                                        entityType: 'packages'
                                    },{
                                        type: 'switch',
                                        name: 'display_image',
                                        label: 'Display Image?',
                                        value: true
                                    },{
                                        type: 'switch',
                                        name: 'enhanceable',
                                        label: 'Enhanceable?',
                                        condition: () => entityParent === 'Package Plan'
                                    },{
                                        type: 'input-number',
                                        name: 'limit_per',
                                        label: 'Limit per Order',
                                        field_info: '0 will allow for an unlimited quantity. setting this will override the top level item preference',
                                        min: 0
                                    },{
                                        type: 'switch',
                                        name: 'notify',
                                        label: 'Notify on Purchase?'
                                    },{
                                        type: 'select',
                                        name: 'requires_proof',
                                        label: 'Requires Proof?',
                                        options: requiresProofOptions,
                                        defaultValue: 0,
                                        condition: (data) => entityParent === 'Package Plan' && selectorFlags.proofs
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_individual',
                                        label: 'Pose count individual',
                                        min: 0,
                                        condition: (data) => selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_couple',
                                        label: 'Pose count couple',
                                        min: 0,
                                        condition: (data) => selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_any',
                                        label: 'Pose count any',
                                        min: 0,
                                        condition: (data) => selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'background_count',
                                        label: 'Background count',
                                        min: 0,
                                        condition: (data) => selectorFlags.backgrounds
                                    },{
                                        type: 'input-number',
                                        name: 'sheet_count',
                                        label: 'Sheet count',
                                        min: 0,
                                        condition: (data) => selectorFlags.sheets
                                    },{
                                        type: 'input-text',
                                        name: 'price_override',
                                        label: 'Price Override',
                                        field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                                    }]} />

                                    <>
                                        <div className="image-override">
                                            <AssetUploader list={_dataModalImageOverride.get()} events={{ handleChange: (value) => _dataModalImageOverride.set(value) }} />
                                        </div>

                                        <hr />

                                        <FieldSelectorComponent data={dataCouponCodes} apiURL="/api/v2/admin/coupon-codes" sync={sync} sortable={false} buttonTitle="Coupon Code" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} extraOptions={{ include_bundled_toggle: true }} />
                                    </>
                                </>
                            : null}
                        </div>
                    </div>

                    <div className="ui--controls">
                        <button onClick={handleAddAddon} disabled={dataModal?.package_id?.length == 0}>Save</button> <button className="secondary" onClick={handleCloseAddModal}>Cancel</button>
                    </div>
                </ModalComponent>
            ) : null}

            {showEditAddonsModal ? (
                <ModalComponent events={{ handleClose: handleCloseEditAddonsModal }}>
                    <div className="ui-fieldMultiselectorModal">
                        {entity === 'addon' ? (
                            <ul className="field-multiselector-cascade-addons">
                                {dataAddonsModal.cascade_addons.data.map((item, index) => {
                                    return <li key={`field-multiselector-cascade-addons-${index}`}><em>{item.name}</em> <button onClick={() => RouteHelper.redirect(`/addons/${item.addon_id}/edit`, true)}><i className="bi bi-pencil-square"></i></button></li>
                                })}
                            </ul>
                        ) : null}

                        {entity === 'package' ? (
                            <>
                                <div className="addon-title">
                                    <strong>Package</strong>: {dataAddonsModal.name}
                                </div>

                                <ul className="field-multiselector-package-addons">
                                    {dataAddonsModal.addons.data.map((item, index) => {
                                        return <li key={`field-multiselector-package-addons-${index}`}>
                                            <div className="title"><em>{item.name}</em> <button onClick={() => RouteHelper.redirect(`/addons/${item.addon_id}/edit`, true)}><i className="bi bi-pencil-square"></i></button></div>
                                            <ul className="field-multiselector-addons-list-form scroll-invisible">
                                                {item.pose_count_any != 0 || selectorFlags.poses ? (
                                                    <li>
                                                        <span>Any Pose(s)</span>
                                                        <input className="field" type="number" value={item.pose_count_any} min="1" onChange={(e) => handlePackagesAddonEdit('pose_count_any', index, e.target.value) } />
                                                    </li>
                                                ) : null}
                                                {item.pose_count_individual != 0 || selectorFlags.poses ? (
                                                    <li>
                                                        <span>Individual Pose(s)</span>
                                                        <input className="field" type="number" value={item.pose_count_individual} onChange={(e) => handlePackagesAddonEdit('pose_count_individual', index, e.target.value) } />
                                                    </li>
                                                ) : null}
                                                {item.pose_count_couple != 0 || selectorFlags.poses ? (
                                                    <li>
                                                        <span>Couple Pose(s)</span>
                                                        <input className="field" type="number" value={item.pose_count_couple} onChange={(e) => handlePackagesAddonEdit('pose_count_couple', index, e.target.value) } />
                                                    </li>
                                                ) : null}
                                                {item.sheet_count != 0 || selectorFlags.sheets ? (
                                                    <li>
                                                        <span>Sheet(s)</span>
                                                        <input className="field" type="number" value={item.sheet_count} onChange={(e) => handlePackagesAddonEdit('sheet_count', index, e.target.value) } />
                                                    </li>
                                                ) : null}
                                                {item.background_count != 0 || selectorFlags.backgrounds ? (
                                                    <li>
                                                        <span>Background(s)</span>
                                                        <input className="field" type="number" value={item.background_count} onChange={(e) => handlePackagesAddonEdit('background_count', index, e.target.value) } />
                                                    </li>
                                                ) : null}
                                                {item.requires_proof != 0 || selectorFlags.proofs ? (
                                                    <li>
                                                        <span>Proof?</span>
                                                        {<select name={'requires_proof'} value={item.requires_proof || ''} onChange={(e) => handlePackagesAddonEdit('requires_proof', index, e.target.value)}>
                                                            {requiresProofOptions.map((item, idx) => {
                                                                return <option key={`${idx}-${item.value}`} value={item.value}>{item.title}</option>
                                                            })}
                                                        </select>}
                                                    </li>
                                                ) : null}
                                                <li>
                                                    <span>Tab</span>
                                                    {item.emphasized ? (
                                                        <PopoverComponent position="top" offsetPx={0}>
                                                            <select className="field disabled">
                                                                <option>Emphasized</option>
                                                            </select>
                                                            <div className="popover-default">
                                                                This addon is emphasized
                                                            </div>
                                                        </PopoverComponent>
                                                    ) : (
                                                        <select className="field" value={item.category_id || ''} onChange={(e) => handlePackagesAddonEdit('category_id', index, e.target.value) } >
                                                            {optionsCategories.map((itemCategory, indexCategory) => {
                                                                return <option key={itemCategory.value} value={itemCategory.value}>{itemCategory.title}</option>
                                                            })}
                                                        </select>
                                                    )}
                                                </li>
                                                <li>
                                                    <span>Notify?</span>
                                                    {<SwitchComponent value={item.notify} checked={item.notify} onChange={(e) => handlePackagesAddonEdit('notify', index, !Boolean(item.notify))} />}
                                                </li>
                                            </ul>
                                        </li>
                                    })}
                                </ul>
                            </>
                        ) : null}
                    </div>
                </ModalComponent>
            ) : null}

            {showEditModal ? (
                <ModalComponent events={{ handleClose: handleCloseModal }}>
                    <div className="ui-fieldMultiselectorModal">
                        {/* <ProjectorComponent url={formData.image.image_url} /> */}

                        <div className="addon-title">
                            <strong>{buttonTitle}</strong>: {formData.name}
                        </div>

                        {entityParent === 'Package Plan' ?
                            <>
                                <div className="modal-form">
                                    <FormComponent formData={formData} formErrors={errors} onChange={setDataModal} displayErrorBanner={true} type="stacked" fields={[{
                                        type: 'switch',
                                        name: 'bundled',
                                        label: 'Bundled?',
                                        condition: (data) => entity === 'addon'
                                    },{
                                        type: 'switch',
                                        name: 'required',
                                        label: 'Required?',
                                        condition: (data) => entity === 'addon'
                                    },{
                                        type: 'switch',
                                        name: 'display_image',
                                        label: 'Display Image?',
                                        value: true
                                    },{
                                        type: 'switch',
                                        name: 'enhanceable',
                                        label: 'Enhanceable?'
                                    },{
                                        type: 'input-number',
                                        name: 'limit_per',
                                        label: entity == 'addon' ? 'Limit per Package' : 'Limit per Order',
                                        condition: (data) => !data.bundled
                                    },{
                                        type: 'switch',
                                        name: 'notify',
                                        label: 'Notify on Purchase?'
                                    },{
                                        type: 'select',
                                        name: 'category_id',
                                        label: 'Tab',
                                        options: optionsCategories,
                                        condition: (data) => entity === 'addon'
                                    },{
                                        type: 'select',
                                        name: 'requires_proof',
                                        label: 'Requires Proof?',
                                        options: requiresProofOptions,
                                        defaultValue: 0,
                                        condition: (data) => selectorFlags.proofs
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_individual',
                                        label: 'Pose count individual',
                                        min: 0,
                                        condition: (data) => data.pose_count_individual != 0 || selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_couple',
                                        label: 'Pose count couple',
                                        min: 0,
                                        condition: (data) => data.pose_count_couple != 0 || selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'pose_count_any',
                                        label: 'Pose count any',
                                        min: 0,
                                        condition: (data) => data.pose_count_any != 0 || selectorFlags.poses
                                    },{
                                        type: 'input-number',
                                        name: 'background_count',
                                        label: 'Background count',
                                        min: 0,
                                        condition: (data) => data.background_count != 0 || selectorFlags.backgrounds
                                    },{
                                        type: 'input-number',
                                        name: 'sheet_count',
                                        label: 'Sheet count',
                                        min: 0,
                                        condition: (data) => data.sheet_count != 0 || selectorFlags.sheets
                                    },{
                                        type: 'input-text',
                                        name: 'price_override',
                                        label: 'Price Override',
                                        field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                                    }]} />
                                </div>

                                <div className="image-override">
                                    <AssetUploader list={_dataModalImageOverride.get()} events={{ handleChange: (value) => _dataModalImageOverride.set(value) }} />
                                </div>

                            {entity === 'package' ? (
                                <>
                                    <hr />

                                    <FieldSelectorComponent data={formData.coupon_codes.data} apiURL="/api/v2/admin/coupon-codes" apiPrefix="coupon_code" syncURL={formData.coupon_codes.url} sync={sync} sortable={false} buttonTitle="Coupon Code" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} extraOptions={{ include_bundled_toggle: true }} />
                                </>
                            ) : null}
                            </>
                        : null}

                        {entityParent === 'Package' ?
                            <FormComponent formData={formData} formErrors={errors} onChange={setDataModal} displayErrorBanner={true} fields={[{
                                type: 'switch',
                                name: 'bundled',
                                label: 'Bundled?',
                            },{
                                type: 'switch',
                                name: 'required',
                                label: 'Required?',
                            }, {
                                type: 'input-number',
                                name: 'limit_per',
                                label: 'Limit per Package',
                                condition: (data) => !data.bundled
                            },{
                                type: 'input-text',
                                name: 'price_override',
                                label: 'Price Override',
                                field_info: 'If this field contains a value, it will take precendence over the default price of the product. Only an empty field will not override the price.'
                            }]} />
                        : null}

                        <div className="ui--controls">
                            <button onClick={handleEditAddon} disabled={dataModal?.addon_id?.length == 0}>Save</button> <button className="secondary" onClick={() => setShowEditModal(false)}>Cancel</button>
                        </div>
                    </div>
                </ModalComponent>
            ) : null}
        </div></div>
    )
}