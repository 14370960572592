
import React, { useEffect, useContext } from 'react'

import {OrderContext} from '../../OrderContext'

import PropTypes from 'prop-types'

import { UI } from '../../../../UI'

import {
    EntitySelectorModalComponent,
    EntityStackComponent,
    PlaceholderGenericSpinnerComponent,
    StatusIndicatorComponent
} from '../../../../componentsV2/Integrated'

import { Fetch, Utils } from '../../../../helpers'

import './index.css'
import RouteHelper from '../../../../helpers/route'

const PackageDetailsEntitySelector = ({ entity, title, type, studioId, events={}, config={} }) => {
    const ac = useContext(OrderContext)

    const {_state} = UI.useSimpleState({
        selections: [],
        available: [],
    })

    const {_state: _dependencies} = UI.useSimpleState({
        selections: false,
        available: false
    })

    const {_state: _toggles} = UI.useSimpleState({
        modal: false,
        syncing: false
    })

    const _mountedRef = React.useRef(null)
    const _syncCountRef = React.useRef(0)
    const _syncGetSelectionsTimer = React.useRef(null)

    const _selected = React.useRef([])
    const _available = React.useRef([])

    useEffect(() => {
        if (_mountedRef && !_mountedRef.current)
            _mountedRef.current = true
        
        syncGetSelections(null, 'init')

        return () => {
            window.clearTimeout(_syncGetSelectionsTimer?.current)
        }
    }, [])
    
    const incrementSyncCounter = () => {
        _syncCountRef.current = _syncCountRef.current + 1
    }

    const decrementSyncCounter = () => {
        _syncCountRef.current = _syncCountRef.current - 1

        if (_syncCountRef.current < 0)
            _syncCountRef.current = 0
    }

    const setSyncState = (item, syncState) => {
        let newSelections = [..._selected.current]

        newSelections = newSelections.map(selection => {
            if (selection === item) {
                selection._ui_action_sync = syncState
            }

            return selection
        })

        _selected.current = newSelections

        return item
    }

    const turnSyncingFlagOn = () => {
        _toggles.set('syncing', true)
    }

    const turnSyncingFlagOff = () => {
        decrementSyncCounter()

        if (_syncCountRef?.current === 0) {
            _toggles.set('syncing', false)
        }
    }

    const handleChange = () => {
        if (events.handleChange)
            events.handleChange(_selected.current)
    }

    const handleClose = () => {
        _toggles.set('modal', false)
    }

    const addPlacholderForItem = (item) => {
        let newItems = [..._selected.current]

        item._ui_action_uuid = Utils.generateUUID()

        newItems.push({
            _ui_action_sync: 'post',
            _ui_action_uuid: item._ui_action_uuid
        })

        _selected.current = newItems

        return item
    }

    const removeSelectedItem = (item) => {
        let newItems = [..._selected.current]

        newItems = newItems.filter(newItem => item !== newItem)

        _selected.current = newItems
    }

    const updateSelectedItem = (item, selectedItem) => {
        let newItems = [..._selected.current]

        newItems = newItems.map(newItem => {
            if (newItem === item) {
                return selectedItem
            } else {
                return newItem
            }
        })

        _selected.current = newItems
    }

    const postSelectedItem = (item) => {
        syncGetSelections(item, 'post')
    }

    const syncGetSelection = (item) => {
        turnSyncingFlagOn()
        incrementSyncCounter()

        Fetch({
            url: entity.url,
            headers: {
                'x-studio-id': studioId
            },
            method: 'GET'
        }, (res) => {
            const newItem = res.body.data.filter(bodyItem => bodyItem.id === item.id)

            _selected.current = _selected.current.map(selectedItem => {
                if (selectedItem._ui_action_uuid === item._ui_action_uuid) {
                    return newItem[0]
                } else {
                    return selectedItem
                }
            })

            turnSyncingFlagOff()
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const syncGetSelections = (item, afterSyncType) => {
        window.clearTimeout(_syncGetSelectionsTimer?.current)

        _syncGetSelectionsTimer.current = window.setTimeout(() => {
                turnSyncingFlagOn()
                incrementSyncCounter()

                Fetch({
                    url: entity.url,
                    headers: {
                        'x-studio-id': studioId
                    },
                    method: 'GET'
                }, (res) => {
                    let body = res.body.data

                    if (afterSyncType === 'init')
                        _selected.current = body

                    if (afterSyncType === 'post')
                        syncGetSelection(item)

                    _dependencies.set('selections', true)
                    _state.set('entity', res.body)

                    handleChange(body)
                    turnSyncingFlagOff()
                }, (res) => {
                    turnSyncingFlagOff()
                })
        }, 750)
    }

    const syncGetAvailable = () => {
        const url = /^(field|package)$/.test(type) ? entity.alternatives_url : entity.available_url
        
        _available.current = []

        turnSyncingFlagOn()
        incrementSyncCounter()

        Fetch({
            url: url,
            headers: {
                'x-studio-id': studioId
            }
        }, (res) => {
            _available.current = res.body.data
            _dependencies.set('available', true)

            turnSyncingFlagOff()
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const syncPostSelection = (item) => {
        turnSyncingFlagOn()
        incrementSyncCounter()
        
        let newItem = addPlacholderForItem(item)

        Fetch({
            url: entity.url,
            method: 'POST',
            headers: {
                'x-studio-id': studioId
            },
            body: {
                [`${type}`]: {
                    [`${type}_id`]: item[`${type}_id`]
                }
            }
        }, (res) => {
            let newBody = {...res.body}

            newBody._ui_action_uuid = item._ui_action_uuid

            postSelectedItem(newBody)
            turnSyncingFlagOff()

            if (type === 'addon') {
                RouteHelper.reload()
            }
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const syncPutSelection = (item, prevItem) => {
        turnSyncingFlagOn()
        incrementSyncCounter()

        let newItem = setSyncState(prevItem, 'put')

        Fetch({
            url: prevItem.url,
            method: 'PUT',
            headers: {
                'x-studio-id': studioId
            },
            body: {
                [type]: {
                    [`${type}_id`]: item[`${type}_id`]
                }
            }
        }, (res) => {
            updateSelectedItem(newItem, res.body)
            turnSyncingFlagOff()

            if (type === 'addon') {
                RouteHelper.reload()
            }
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const syncDeleteEntity = (item) => {
        let newItem = setSyncState(item, 'delete')

        turnSyncingFlagOn()
        incrementSyncCounter()

        Fetch({
            url: item.url,
            body: {
                [type]: {
                    [`${type}_id`]: item.id
                }
            },
            headers: {
                'x-studio-id': studioId
            },
            method: 'DELETE'
        }, (res) => {
            removeSelectedItem(newItem)
            handleChange()
            turnSyncingFlagOff()

            if (type === 'addon') {
                RouteHelper.reload()
            }
        }, (res) => {
            turnSyncingFlagOff()
        })
    }

    const renderStatusIndicator = () => {
        const entity = _state.get('entity')

        if (!entity || type === 'addon')
            return

        const isComplete = entity.total_required === _selected?.current?.length

        return (
            <StatusIndicatorComponent status={isComplete ? 'Complete' : 'Incomplete'} config={{ palette: isComplete ? 'default' : 'red' }} />
        )
    }

    const getSelectedAndTotalCount = () => {
        return `(${_selected.current.length}${entity.total_required ? `/${entity.total_required}` : ''})`
    }

    if (!entity)
        return

    return (
        <UI.Layout
            name="entitySelector" type="container"
            configPlaceholder={{
                dependencies: [_dependencies.get('selections')],
                placeholder: () => <PlaceholderGenericSpinnerComponent />
            }}>
            <em className="title">{title} {getSelectedAndTotalCount()}</em>

            <EntityStackComponent items={_selected.current} type={type} events={{ handleClick: () => _toggles.set('modal', true) }} />

            {renderStatusIndicator()}

            {_toggles.get('modal') && (
                <EntitySelectorModalComponent
                    type={type}
                    selected={_selected.current}
                    totalRequired={entity.total_required}
                    collection={_available.current}
                    config={{
                        isSyncing: _toggles.get('syncing'),
                    }}
                    events={{
                        handleClose: handleClose,
                        handleDelete: syncDeleteEntity,
                        handleGetAvailable: syncGetAvailable, 
                        handleReplaceSelection: syncPutSelection,
                        handleAddNewSelection: syncPostSelection
                }} />
            )}
        </UI.Layout>
    )
}

PackageDetailsEntitySelector.propTypes = {
    item: PropTypes.object,

    type: PropTypes.oneOf(['addon', 'background', 'sheet', 'pose', 'proof']),
    
    events: PropTypes.shape({
        handleChange: PropTypes.func
    }),

    config: PropTypes.shape({
        enableDelete: PropTypes.bool
    })
}
export { PackageDetailsEntitySelector }