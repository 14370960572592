import { useState, useEffect } from 'react'

import FetchHelper from '../../helpers/fetch'
import PaginationComponent from '../Pagination'

import useAppContext from '../../hooks/useAppContext'
import useTabs from '../../hooks/useTabs'

import './index.css'

const TwoSidedMultiselectPaginated = ({ apiUrl, apiUrlGroups, queryParams='', value={}, onChange=()=>{}, renderTitle, tabOptions, itemType='markets' }) => {
    const [ac] = useAppContext()

    const [tabs, tab, setTab, TabsComponent] = useTabs(tabOptions[0], tabOptions)

    const [rightOptions, setRightOptions] = useState(value.data || [])
    const [page, setPage] = useState(1)
    const [list, setList] = useState({ data: [] })
    const [isLoading, setIsLoading] = useState(false)
    const [selectedGroups, setSelectedGroups] = useState([])

    const [listGroups, setListGroups] = useState({ data: [] })
    const [pageGroups, setPageGroups] = useState(1)

    const [batchComplete, setBatchComplete] = useState(false)

    useEffect(() => {
        if (apiUrl) {
            setIsLoading(true)

            FetchHelper({
                url: `${apiUrl}?page=${page}${queryParams ? `&${queryParams}` : ''}`
            }, (res) => {
                setList(res.body)
                setIsLoading(false)
            }, (res) => {
                setIsLoading(false)
            })
        }
    }, [page])

    useEffect(() => {
        if (apiUrlGroups) {
            setIsLoading(true)

            FetchHelper({
                url: `${apiUrlGroups}?page=${pageGroups}`
            }, (res) => {
                setListGroups(res.body)
                setIsLoading(false)
            }, (res) => {
                setIsLoading(false)
            })
        }
    }, [pageGroups])

    const setRightOptionsByBatch = (selected) => {
        setSelectedGroups(selected)

        if (batchComplete) {
            filterMarketsBatch()
        } else {
            fetchMarketsBatch()
        }
    }

    // Batch

    const [batchList, setBatchList] = useState({ data: [] })
    const [batchPage, setBatchPage] = useState(1)

    const fetchMarketsBatch = () => {
        return FetchHelper({
            url: apiUrl
        }, (res) => {
            setBatchList({ data: res.body.data })

            if (res.body.total_pages > batchPage) {
                setBatchPage(batchPage + 1)
            }
        }, (res) => {

        })
    }

    useEffect(() => {
        if (batchList.data.length) {
            setIsLoading(true)

            FetchHelper({
                url: `${apiUrl}?page=${batchPage}`
            }, (res) => {
                const resList = { data: batchList.data.concat(res.body.data) }

                setList(resList)
                // onChange(resList.data)

                if (res.body.total_pages > batchPage) {
                    setBatchPage(batchPage + 1)
                } else {
                    setIsLoading(false)
                    setBatchComplete(true)
                }
            }, (res) => {
                setIsLoading(false)
            })
        }
    }, [batchPage])

    useEffect(() => {
        if (batchComplete) {
            filterMarketsBatch()
        }
    }, [batchComplete])

    const filterMarketsBatch = () => {
        const selected = [...selectedGroups]

        let markets = [...rightOptions]
        let listGroupsIds = listGroups.data.map(item => item.id)

        for (let i=0; i < batchList.data.length; i++) {
            let item = batchList.data[i]
            let exists = false

            for (let ii=0; ii < markets.length; ii++) {
                let marketItem = markets[ii]

                if (marketItem.id === item.id) {
                    exists = true
                    break
                }
            }

            if (!exists && selected.indexOf(item.market_group_id)) {
                markets.push(item)
            }
        }

        setRightOptions(markets)
        onChange(markets)
    }

    return (
        <div className="two-sided-multiselect-paginated-container">
            <div className="left-options">
                {apiUrlGroups && <TabsComponent tabs={tabs} tab={tab} setTab={setTab} />}
            </div>

            <div className="multiple-select-sections">
                <div className="multiple-select-section left">  
                    {tab === tabs[0] ? (
                        <>
                            {isLoading ? (
                                <select multiple="multiple" className="loading"></select>
                            ) : (
                                <select multiple="multiple" value={list.data} onChange={(e) => {
                                    let options = e.target.options
                                    let value = []

                                    for (let i = 0, l = options.length; i < l; i++) {
                                        if (options[i].selected && rightOptions.indexOf(list.data[i]) === -1) {
                                            value.push(list.data[i])
                                        }
                                    }

                                    let val = [...rightOptions, ...value]

                                    setRightOptions(val)
                                    onChange(val)
                                }} className="select-multiple">
                                    {list.data.map((item, index) => {
                                        let selected = false

                                        for (let i=0; i < rightOptions.length; i++) {
                                            if (rightOptions[i].id === item.id) {
                                                selected = true
                                                break
                                            }
                                        }

                                        return (
                                            <option key={`two-sided-multiselect-paginated-sections-left-market-${item.id}`} disabled={selected} value={item.id}>
                                                {renderTitle ? renderTitle(item) : item.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            )}
                            <PaginationComponent total={list.data.length} currentPage={page} totalPages={list.total_pages} setPage={setPage} />
                        </>
                    ) : null}

                    {tab === tabs[1] ? (
                        <>
                            {isLoading ? (
                                <select multiple="multiple" className="loading"></select>
                            ) : (
                                <select multiple="multiple" value={listGroups.data} onChange={(e) => {
                                    let options = e.target.options
                                    let value = []

                                    for (let i = 0, l = options.length; i < l; i++) {
                                        if (options[i].selected) {
                                            value.push(listGroups.data[i].id)
                                        }
                                    }

                                    setRightOptionsByBatch(value)
                                }} className="select-multiple">
                                    {listGroups.data.map((item, index) => {
                                        return (
                                            <option key={`two-sided-multiselect-paginated-sections-left-market-group-${item.id}`} value={item.id}>
                                                {item.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            )}
                            <PaginationComponent total={listGroups.data.length} currentPage={pageGroups} totalPages={listGroups.total_pages} setPage={setPageGroups} />
                        </>
                    ) : null}
                </div>

                <div className="multiple-select-section right">
                    <select multiple="multiple" value={rightOptions} onChange={(e) => {
                        let options = e.target.options
                        let val = [...rightOptions].filter((item, index) => {
                            return !(options[index].selected)
                        })

                        setRightOptions(val)
                        onChange(val)
                    }} className="select-multiple">
                        {rightOptions.map((item, index) => {
                            return (
                                <option key={`two-sided-multiselect-paginated-sections-right-${item.id}`} value={item.id}>
                                    {renderTitle ? renderTitle(item) : item.name}
                                </option>
                            )
                        })}
                    </select>

                    <div className="right-options">
                        {rightOptions.length} / {list.total || 0} {itemType} selected
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TwoSidedMultiselectPaginated
