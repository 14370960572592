import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FieldSelectorComponent from '../../components/FieldSelector'
import FieldMultiselectorComponent from '../../components/FieldMultiselector'
import FormComponent from '../../components/Form'
import PickerComponent from '../../components/Picker'
import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useDraft from '../../hooks/useDraft'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'

import './edit.css'
import { getCharityFormFields, getHelperFormFields, getPlanFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'
import lodash from 'lodash'

const PackagePlansNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('PSM/PackagePlans/New')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [dataPlan, setDataPlan, clearDataPlan, dataPlanDefault] = useDraft('PSM:PackagePlans:DataPlan')
    const [dataHelper, setDataHelper, clearDataHelper, dataHelperDefault] = useDraft('PSM:PackagePlans:DataHelper')
    const [dataCharity, setDataCharity, clearDataCharity, dataCharityDefault] = useDraft('PSM:PackagePlans:DataCharity')
    const [dataPackages, setDataPackages, clearDataPackages, dataPackagesDefault] = useDraft('PSM:PackagePlans:DataPackages')
    const [dataAddons, setDataAddons, clearDataAddons, dataAddonsDefault] = useDraft('PSM:PackagePlans:DataAddons')
    const [dataEnhancements, setDataEnhancements, clearDataEnhancements, dataEnhancementsDefault] = useDraft('PSM:PackagePlans:DataEnhancements')
    const [dataCouponCodes, setDataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault] = useDraft('PSM:PackagePlans:DataCouponCodes')
    const [dataPoses, setDataPoses, clearDataPoses, dataPosesDefault] = useDraft('PSM:PackagePlans:DataPoses', [])
    const [dataBackgrounds, setDataBackgrounds, clearDataBackgrounds, dataBackgroundsDefault] = useDraft('PSM:PackagePlans:DataBackgrounds', [])
    const [dataSheets, setDataSheets, clearDataSheets, dataSheetsDefault] = useDraft('PSM:PackagePlans:DataSheets', [])
    const [dataFields, setDataFields, clearDataFields, dataFieldsDefault] = useDraft('PSM:PackagePlans:DataFields')

    const [suggestionsBarcodeFields, setSuggestionsBarcodeFields] = useState([])
    const [suggestionsTags, setSuggestionsTags] = useState([])

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Plan', ['Plan', 'Poses', 'Backgrounds', 'Sheets', 'Packages', 'Global Addons', 'Enhancements', 'Coupon Codes', 'Fields', 'Charity', 'Helper'])

    const [planFormFields, setPlanFormFields] = useState(getPlanFormFields())
    const [charityFormFields] = useState(getCharityFormFields())
    const [helperFormFields] = useState(getHelperFormFields())

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        let barcodeFieldsOptions = suggestionsBarcodeFields.concat(
          (dataFields || []).map((item) => {
            return {
              value: item.value,
              title: item.title
            };
          })
        );
        let fields = getPlanFormFields(suggestionsTags, barcodeFieldsOptions);
        setPlanFormFields(fields);
    }, [suggestionsTags, suggestionsBarcodeFields, dataFields])

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Plan',
                forms: [{ id: 'Main', fields: planFormFields }]
            },
            {
                tab: 'Charity',
                forms: [{ id: 'Charity', fields: charityFormFields }]
            },
            {
                tab: 'Helper',
                forms: [{ id: 'Helper', fields: helperFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    const handleSubmit = () => {
        return new Promise((resolve, reject) => {
            ac.showSpinner(true)

            let body = {
                ...dataPlan,
                ...dataHelper,
                package_plan_poses_attributes: dataPoses.map((item) => ({ pose_id: item.id })),
                package_plan_backgrounds_attributes: dataBackgrounds.map((item) => ({ background_id: item.id })),
                package_plan_sheets_attributes: dataSheets.map((item) => ({ sheet_id: item.id })),
            }

            if (dataEnhancements) {
                body.package_plan_enhancements_attributes = dataEnhancements
                    .filter(item => item?.id)
                    .map(item => {
                        return { enhancement_id: item.id }
                    })
            }

            if (dataCouponCodes) {
                body.item_coupon_codes_attributes = dataCouponCodes.map(item => {
                    return { coupon_code_id: item.item.id }
                })
            }

            if (dataCharity) {
                body.package_plan_charity_attributes = dataCharity
            }

            if (dataFields) {
                body.custom_fields_attributes = dataFields.map(item => {
                    return { field_id: item.item.id }
                })
            }

            if (dataAddons) {
                body.addons_attributes = lodash.cloneDeep(dataAddons).map(addon => {
                    let _addon = addon

                    delete _addon['item']

                    return _addon
                })
            }

            if (dataPackages) {
                body.packages_attributes = lodash.cloneDeep(dataPackages).map(pkg => {
                    let _pkg = pkg

                    delete _pkg['item']

                    return _pkg
                })
            }

            body.tag_names = body.tags
            delete body.tags

            FetchHelper({
                url: `/api/v2/admin/package-plans`,
                method: 'POST',
                body: {
                    package_plan: body
                }
            }, (res) => {
                ac.showSpinner(false)
                RouteHelper.redirect('/package-plans')
                resolve()
            }, (res) => {
                ac.showSpinner(false)

                if (res.status === 422) {
                    setErrors(res.body)
                    UtilsHelper.scrollTop()
                }
                reject()
            })
        })
    }

    useEffect(() => {
        FetchHelper({
            url: '/api/v2/admin/addons/tags'
        }, (res) => {
            setSuggestionsTags(res.body)
        }, (res) => {

        })

        setSuggestionsBarcodeFields([
            {
                title: 'Confirmation # (Default)',
                value: 'fastpass'
            },
            {
                title: 'Email',
                value: 'email'
            }
        ])
    }, [])

    return (
        <div className="package-plans-edit-page-container page-container">
            <PageHeaderComponent title="New Package Plan" />

            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />

            {tab === 'Plan' ?
                <>
                    <NoticeComponent location="Tab/Plan" notices={notices} />

                    <FormComponent formData={dataPlan} formErrors={errors} setFormErrors={setErrors} onChange={setDataPlan} displayErrorBanner={true} fields={planFormFields} />
                </>
            : null}

            {tab === 'Charity' ?
                <>
                    <NoticeComponent location="Tab/Charity" notices={notices} />

                    <FormComponent formData={dataCharity} formErrors={errors} setFormErrors={setErrors} onChange={setDataCharity} displayErrorBanner={true} fields={charityFormFields} />
                </>
            : null}

            {tab === 'Helper' ?
                <>
                    <NoticeComponent location="Tab/Helper" notices={notices} />

                    <FormComponent formData={dataHelper} formErrors={errors} setFormErrors={setErrors} onChange={setDataHelper} displayErrorBanner={true} fields={helperFormFields} />
                </>
            : null}

            {tab === 'Packages' ?
                <>
                    <NoticeComponent location="Tab/Packages" notices={notices} />

                    <FieldMultiselectorComponent data={dataPackages} entityParent="Package Plan" entityType="Package" onChange={(data) => { setDataPackages(data) }} columns={[
                        {
                            'title': 'Name'
                        },
                        {
                            'title': 'Price'
                        }
                    ]} withSelectors={{ backgrounds: dataBackgrounds.length != 0, poses: dataPoses.length != 0, sheets: dataSheets.length != 0, proofs: ac.AuthHelper.checkFeature('ProofingService') }} />
                </>
            : null}

            {tab === 'Global Addons' ?
                <>
                    {/* <div className="page-message">
                        <i className="bi bi-info-circle"></i> <span>Addons shown below will be offered regardless of what package is in cart, and even if the customer chooses the a la carte option.</span>
                    </div> */}

                    <NoticeComponent location="Tab/GlobalAddons" notices={notices} />

                    <FieldMultiselectorComponent data={dataAddons} entityParent="Package Plan" entityType="Addon" onChange={(data) => { setDataAddons(data) }} columns={[
                        {
                            'title': 'Name'
                        },
                        {
                            'title': 'Price'
                        }
                    ]} withSelectors={{ backgrounds: dataBackgrounds.length != 0, poses: dataPoses.length != 0, sheets: dataSheets.length != 0, proofs: ac.AuthHelper.checkFeature('ProofingService') }} />
                </>
            : null}

            {tab === 'Enhancements' && 
                <>
                    <NoticeComponent location="Tab/Enhancements" notices={notices} />

                    <FieldSelectorComponent data={dataEnhancements} apiURL="/api/v2/admin/enhancements" sync={false} sortable={false} buttonTitle="Enhancement" editUrl={(id) => `/enhancements/${id}/edit`} onChange={(data) => setDataEnhancements(data)} />
                </>}

            {tab === 'Coupon Codes' ?
                <>
                    <NoticeComponent location="Tab/CouponCodes" notices={notices} />

                    <div className={`page-form-container ${tab === 'Coupon Codes' ? 'show' : ''}`}>
                        <FieldSelectorComponent data={dataCouponCodes} apiURL="/api/v2/admin/coupon-codes" sync={false} sortable={false} buttonTitle="Coupon Code" editUrl={(id) => `/coupon-codes/${id}/edit`} onChange={(data) => setDataCouponCodes(data)} />
                    </div>
                </>
            : null}

            {tab === 'Poses' ?
                <>
                    <NoticeComponent location="Tab/Poses" notices={notices} />

                    <div className="package-plans-poses-container page-subsection-container">
                        <PickerComponent sync={false} value={dataPoses} onChange={setDataPoses} type="pose" />
                    </div>
                </>
            : null}

            {tab === 'Backgrounds' ?
                <>
                    <NoticeComponent location="Tab/Backgrounds" notices={notices} />

                    <div className="package-plans-backgrounds-container page-subsection-container">
                        <PickerComponent sync={false} value={dataBackgrounds} onChange={setDataBackgrounds} type="background" />
                    </div>
                </>
            : null}

            {tab === 'Sheets' ?
                <>
                    <NoticeComponent location="Tab/Sheets" notices={notices} />

                    <div className="package-plans-sheets-container page-subsection-container">
                        <PickerComponent sync={false} value={dataSheets} onChange={setDataSheets} type="sheet" />
                    </div>
                </>
            : null}

            {tab === 'Fields' ?
                <>
                    <NoticeComponent location="Tab/Fields" notices={notices} />

                    <div className={`page-form-container`}>
                        <FieldSelectorComponent data={dataFields} apiURL="/api/v2/admin/fields" sync={false} buttonTitle="Field" editUrl={(id) => `/fields/${id}/edit`} onChange={setDataFields} />
                    </div>
                </>
            : null}

            <ControlBar handleSubmit={handleSubmit} cancelUrl='/package-plans' handleAfterClear={() => {
                RouteHelper.reload()
            }} dataItems={[
                [dataPlan, clearDataPlan, dataPlanDefault],
                [dataCharity, clearDataCharity, dataCharityDefault],
                [dataHelper, clearDataHelper, dataHelperDefault],
                [dataPackages, clearDataPackages, dataPackagesDefault],
                [dataAddons, clearDataAddons, dataAddonsDefault],
                [dataEnhancements, clearDataEnhancements, dataEnhancementsDefault],
                [dataCouponCodes, clearDataCouponCodes, dataCouponCodesDefault],
                [dataPoses, clearDataPoses, dataPosesDefault],
                [dataBackgrounds, clearDataBackgrounds, dataBackgroundsDefault],
                [dataSheets, clearDataSheets, dataSheetsDefault],
                [dataFields, clearDataFields, dataFieldsDefault],
            ]} />
        </div>
    )
}

export default PackagePlansNew
