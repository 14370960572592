import {useState, useEffect} from 'react'

import PageHeaderComponent from '../../components/PageHeader'

import './index.css'

const MainPage = () => {
    return (
        <div className="main-page-container">
            <PageHeaderComponent title="Dashboard" />
        </div>
    )
}

export default MainPage
