import { useState, useEffect } from 'react'

import ControlBar from '../../components/ControlBar'
import PageHeaderComponent from '../../components/PageHeader'
import FormComponent from '../../components/Form'

import FetchHelper from '../../helpers/fetch'
import UtilsHelper from '../../helpers/utils'
import RouteHelper from '../../helpers/route'

import { SUPPORTED_PAYMENT_GATEWAYS, PAYMENT_ACCOUNT_ATTR_TRANSLATIONS } from '../../sys/constants/PaymentAccount'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'
import { getApplePayFormFields, getPaymentInfoFormFields, getPaymentTypeFormFields } from './providers/form-fields'
import useIsFirstRender from '../../hooks/useIsFirstRender'

export const PaymentAccountsNew = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/PaymentAccounts/New')

    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    
    const [type, setType] = useState()
    const [applePay, setApplePay] = useState()
    const PaymenGatewaysConstant = SUPPORTED_PAYMENT_GATEWAYS
    const PaymentSettingsConstant = PAYMENT_ACCOUNT_ATTR_TRANSLATIONS
    const [payments_gateways, setPayments_gateways] = useState([{ id: 0, title: "Select", value: "" }])
    const [paymentSettings, setPaymentSettings] = useState({ login: 'Login', password: 'Password', merchant_id: 'Merchant' })

    const [tabs, tab, setTab, TabsComponent, tabPropsByTab, setTabsFormsErrors] = useTabs('Payment Information', ['Payment Information', 'Payment Type', 'Apple Pay'])

    const [paymentInfoFormFields, setPaymentInfoFormFields] = useState(getPaymentInfoFormFields())
    const [paymentTypeFormFields] = useState(getPaymentTypeFormFields())
    const [applePayFormFields] = useState(getApplePayFormFields())

    useEffect(() => {
        let fields = getPaymentInfoFormFields(payments_gateways, paymentSettings)
        setPaymentInfoFormFields(fields)
    }, [payments_gateways, paymentSettings])

    const isFirstRender = useIsFirstRender()

    useEffect(() => {
        if (isFirstRender) return;

        let tabsWithFormsInfo = [
            {
                tab: 'Payment Information',
                forms: [{ id: 'Main', fields: paymentInfoFormFields }]
            },
            {
                tab: 'Payment Type',
                forms: [{ id: 'Main', fields: paymentTypeFormFields }]
            },
            {
                tab: 'Apple Pay',
                forms: [{ id: 'Main', fields: applePayFormFields }]
            }
        ]
        setTabsFormsErrors(tabsWithFormsInfo, errors)
    }, [errors])

    useEffect(() => {
        let temp = payments_gateways

        PaymenGatewaysConstant.map((item, i) => {
            let obj = {
                id: i + 1,
                title: item.name,
                value: item.value
            }
            temp.push(obj)
        })

        setPayments_gateways(temp)

    }, [])

    useEffect(() => {

        if (data && PaymentSettingsConstant[data.type]) {
            let selectedType = PaymentSettingsConstant[data.type]
            setPaymentSettings({
                ...paymentSettings,
                login: selectedType.login,
                password: selectedType.password ? selectedType.password : '',
                merchant_id: selectedType.merchant_id ? selectedType.merchant_id : false
            })
        }

    }, [data])

    const handleSubmit = () => {
        ac.showSpinner(true)

        let body = {
            ...data,
            ...type
        }

        let apple_pay = false
        let apple_pay_integration = {}

        if (!paymentSettings.merchant_id) {
            body = {
                ...body,
                merchant_id: ''
            }
        }

        if (applePay) {
            apple_pay = applePay.apple_pay

            apple_pay_integration = {
                merchant_identifier: applePay.merchant_identifier
            }

            for (var key in applePay) {
                const file = applePay[key]
                if (file && (file.value !== "") && ((key === "merchant_key") || (key === "merchant_certificate"))) {
                    apple_pay_integration = {
                        ...apple_pay_integration,
                        [key + '_base64']: file.value
                    }
                }
            }
        } else {
            apple_pay_integration = {
                merchant_identifier: ""
            }
        }

        body = {
            ...body,
            apple_pay: apple_pay,
            apple_pay_integration_attributes: apple_pay_integration
        }

        FetchHelper({
            url: `/api/v2/admin/payment-accounts`,
            method: 'POST',
            body: {
                payment_account: body
            }
        }, (res) => {
            ac.showSpinner(false)
            RouteHelper.redirect('/payment-accounts')
        }, (res) => {
            ac.showSpinner(false)

            if (res.status === 422) {
                let errors = UtilsHelper.preProcess422Errors(
                    [...paymentInfoFormFields, ...paymentTypeFormFields, ...applePayFormFields],
                    res.body
                )
                setErrors(errors)
                UtilsHelper.scrollTop()
            }
        })
    }

    const onChangeTab = (tab) => {
        switch (tab) {
            case 'Payment Information':
                return (
                    <>
                        <NoticeComponent location="Tab/PaymentInformation" notices={notices} />
                        <FormComponent tab={tab} formData={data} formErrors={errors} setFormErrors={setErrors} onChange={setData} displayErrorBanner={true} fields={paymentInfoFormFields} />
                    </>
                )
            case 'Payment Type':
                return (
                    <>
                        <NoticeComponent location="Tab/PaymentType" notices={notices} />
                        <FormComponent tab={tab} formData={type} formErrors={errors} setFormErrors={setErrors} onChange={setType} displayErrorBanner={true} fields={paymentTypeFormFields} />
                    </>
                )
            case 'Apple Pay':
                return (
                    <>
                        <NoticeComponent location="Tab/ApplePay" notices={notices} />
                        <FormComponent tab={tab} formData={applePay} formErrors={errors} setFormErrors={setErrors} onChange={setApplePay} displayErrorBanner={true} fields={applePayFormFields} />
                    </>
                )
            default:
                break
        }
    }

    return (
        <div className="page-container">
            <PageHeaderComponent title="New Payment Account" />
            <TabsComponent tabs={tabs} tab={tab} setTab={setTab} tabPropsByTab={tabPropsByTab} />
            <div className="tab-content">
                {onChangeTab(tab)}
            </div>
            <ControlBar baseApiUrl="/payment-accounts" redirectUrl="/payment-accounts" cancelUrl="/payment-accounts" handleSubmit={handleSubmit} />
        </div>
    )
}
export default PaymentAccountsNew
