import { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import './index.css'

const InputComponent = ({ name, type, value, events={}, eventsDOM={}, config={} }) => {
    const {_state} = UI.useSimpleState({
        input: value,
        focused: false
    })

    const _inputRef = useRef(null)

    useEffect(() => {
        if (!_inputRef.current)
            return

        const handleUnfocus = () => {
            _state.set('focused', false)
        }

        _inputRef.current.addEventListener('blur', handleUnfocus)

        return () => {
            _inputRef.current && _inputRef.current.removeEventListener('blur', handleUnfocus)
        }
    }, [_inputRef.current])

    const handleClick = () => {
        _state.set('focused', true)
        _inputRef.current.focus()
    }

    const handleFocus = (e) => {
        _state.set('focused', true)
    }

    const handleChange = (e) => {
        let value = e.target.value

        if (config.valueFilter) {
            value = config.valueFilter(value)
        }

        _state.set('input', value)
        _state.set('focused', true)

        if (events.handleChange)
            events.handleChange(value)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Escape')
            _inputRef.current.blur()
    }

    const decorateEventsDOM = () => {
        let eventsConfig = {...eventsDOM}

        delete eventsConfig.onChange

        if (config.enableKeyUpBlur) {
            eventsConfig.onKeyUp = handleKeyUp
        }

        return eventsConfig
    }

    const renderInput = () => {
        return (
            <div className="ui--input" onClick={handleClick}>
                <input
                    name={name}
                    type={type}
                    ref={_inputRef}
                    value={_state.get('input') || ''}
                    autoFocus={config.autoFocus}
                    disabled={config.disabled}
                    readOnly={config.readOnly}
                    placeholder={config.placeholder}
                    maxLength={config.maxLength}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    {...decorateEventsDOM()} />
                {!_state.get('focused') && (
                    <span className="ui--inputDisplay">
                        {config.displayFilter && config.displayFilter(_state.get('input')) || _state.get('input')}
                    </span>
                )}
            </div>
        )
    }
    
    let _containerClassNames = UI.Decorators.String([
        ['ui-action--focused', _state.get('focused')],
        config.state === 'error' ? 'ui-action--state--error' : config.state === 'success' ? 'ui-action--state--success' : null,
        config.classNames
    ])

    return (
        <UI.Layout name="input" type="container" config={{ classNames: _containerClassNames }}>
            {config.cell && (
                <div className="ui--inputCell">
                    {config.cell}
                </div>
            )}
            
            {renderInput()}
        </UI.Layout>
    )
}

InputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    
    type: PropTypes.string.isRequired,
    
    events: PropTypes.shape({
        handleChange: PropTypes.func
    }),

    eventsDOM: PropTypes.object,
    
    config: PropTypes.shape({
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
        autoFocus: PropTypes.bool,
        enableKeyUpBlur: PropTypes.bool,
        classNames: PropTypes.string,
        placeholder: PropTypes.string,
        maxLength: PropTypes.number,
        cell: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
        hasError: PropTypes.bool,
        displayFilter: PropTypes.func,
        valueFilter: PropTypes.func
    })
}

export { InputComponent }