import {useState, useEffect, useRef} from 'react'

import './Pagination.css'

const PaginationComponent = ({ total=0, currentPage=1, totalPages=1, setPage, radius=5 }) => {
    const [jumpToPage, setJumpToPage] = useState(1)
    const [showJumpTo, setShowJumpTo] = useState(false)

    const ref = useRef(null)

    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShowJumpTo(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [ref])

    let pageSet = [currentPage]
    let counter = 1

    while ((currentPage - counter) > 1 && counter < radius) {
        pageSet.unshift(currentPage - counter)

        counter++
    }

    counter = 1

    while ((currentPage + counter) < totalPages && counter < radius) {
        pageSet.push(currentPage + counter)

        counter++
    }

    return (
        <div className="pagination-container" onClick={(e) => e.stopPropagation()}>
            <ul>
                {currentPage !== 1 && (
                    <li onClick={() => setPage(currentPage - 1)}><i className="bi bi-chevron-left"></i></li>
                ) || null}

                {pageSet[0] !== 1 && (
                    <>
                        <li onClick={() => setPage(1)}>1</li>
                        {pageSet[0] !== 2 && (<li><i className="bi bi-three-dots"></i></li>) || null}
                    </>
                ) || null}

                {pageSet.map((page) => {
                    return <li key={`pagination-${page}`} className={`pagination-page ${page === currentPage ? 'action-active' : ''}`} onClick={() => setPage(page)}>{page}</li>
                })}

                {pageSet[pageSet.length - 1] !== totalPages && (
                    <>
                        {pageSet[pageSet.length - 1] !== totalPages - 1 && (<li><i className="bi bi-three-dots"></i></li>) || null}
                        <li onClick={() => setPage(totalPages)}>{totalPages}</li>
                    </>
                ) || null}

                {totalPages > 1 ? (
                    <li>
                        <i className="bi bi-caret-right-square" onClick={() => setShowJumpTo(!showJumpTo)}></i>
                        {showJumpTo ? (
                            <div className="jump-to" ref={ref}>
                                <input type="number" value={jumpToPage} min="1" max={totalPages} step="1" onChange={(e) => {
                                    let val = parseInt(e.target.value)

                                    if (isNaN(val)) return
                                    
                                    setJumpToPage(val > totalPages || val < 1 ? 1 : val)
                                }} /> <i className="bi bi-arrow-right-circle-fill" onClick={() => {
                                    setPage(jumpToPage)
                                    setShowJumpTo(false)
                                }}></i>
                            </div>
                        ) : null}
                    </li>
                ) : null}

                {currentPage < totalPages && (
                    <li onClick={() => setPage(currentPage + 1)}><i className="bi bi-chevron-right"></i></li>
                ) || null}
            </ul>
        </div>
    )
}

export default PaginationComponent
