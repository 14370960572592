import { UI } from "../../../../UI"

import './index.css'

const OrderPlaceholder = ({ name, children, identifier }) => {
    return (
        <UI.Layout name={name} identifier={identifier} config={{ hasEventBoundary: true, classNames: !children && 'ui-style--loading'  }}>
            {children ? children : null}
        </UI.Layout>
    )
}

export {
    OrderPlaceholder
}