import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { UI } from '../../../UI'

import { InputComponent } from '../../Primitive'

import './index.css'

const FormFieldMoneyComponent = ({ name, value, errors={}, config={}, events={} }) => {
    const {_state} = UI.useSimpleState({
        input: value
    })

    const valueFilter = (fieldValue) => {
        const filteredInput = fieldValue.replace(/[^\d]/g, '');

        if (filteredInput === '')
            return ''
      
        const decimalIndex = filteredInput.length - 2
        const formattedInput = filteredInput.slice(0, decimalIndex) + '.' + filteredInput.slice(decimalIndex)

        return formattedInput
    }

    const handleChange = (fieldValue) => {
        _state.set('input', fieldValue)

        if (events.handleChange)
            events.handleChange(fieldValue)
    }

    return (
        <InputComponent name={name} type="text" value={_state.get('input')} events={{ handleChange: handleChange }} config={{ cell: <i className="bi bi-currency-dollar"></i>, maxLength: 20, valueFilter: valueFilter, hasError: config.hasError }} />
    )
}

FormFieldMoneyComponent.propTypes = {
    name: PropTypes.string.isRequired,

    errors: PropTypes.object,

    config: PropTypes.shape({
        classNames: PropTypes.string,
        displayFilter: PropTypes.func
    }),

    events: PropTypes.shape({
        onMount: PropTypes.func
    }),
}

export { FormFieldMoneyComponent }
